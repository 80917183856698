import axios from 'axios';
class NetworkClient {
  constructor() {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning': 'true'
    };
    let service = axios.create({
      headers,
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);

    service.interceptors.request.use(async config => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    this.service = service;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = async (error) => {
    return Promise.reject(error);
  };

  get(path, headers) {
    return this.service
      .get(path, {
        headers: headers,
      })
      .then(response => response);
  }

  patch(path, payload, headers) {
    return this.service
      .request({
        method: 'PATCH',
        url: path,
        responseType: 'json',
        data: payload,
        headers: headers,
      })
      .then(response => response);
  }

  post(path, payload, headers) {
    return this.service
      .request({
        method: 'POST',
        url: path,
        responseType: 'json',
        data: payload,
        headers: headers,
      })
      .then(response => response);
  }
  delete(path, payload, headers) {
    return this.service
      .request({
        method: 'DELETE',
        url: path,
        responseType: 'json',
        data: payload,
        headers: headers,
      })
      .then(response => response);
  }
  put(path, payload, headers) {
    return this.service
      .request({
        method: 'PUT',
        url: path,
        responseType: 'json',
        data: payload,
        headers: headers,
      })
      .then(response => response);
  }
}

export default new NetworkClient();
