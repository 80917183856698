import React, { useEffect, useState } from 'react';
import ApexCharts from 'apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { ActionGetDashboardData } from '../../store/Actions/CommonAction';
import { CommonHeadingTxt } from '../Text/Text';
import { Button } from 'antd';

const ActivityChart = () => {
    const { getDashboardData } = useSelector(state => state.Common);
    console.log('chk10', getDashboardData?.salesReport);
    const dispatch = useDispatch();
    const [chartData, setChartData] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState("Daily");

    useEffect(() => {
        dispatch(ActionGetDashboardData({ type: selectedPeriod }));
    }, [dispatch]);

    useEffect(() => {
        if (!getDashboardData) return;

        const xAxisCategories = Array.from(
            new Set(
                getDashboardData?.salesReport?.appointment
                    .concat(getDashboardData?.salesReport?.treatment)
                    .concat(getDashboardData?.salesReport?.solution)
                    .concat(getDashboardData?.salesReport?.service)
                    .concat(getDashboardData?.salesReport?.retreat)
                    .map(entry => {
                        console.log('chk30', entry);
                        if (selectedPeriod === "Monthly") {

                            return { date: entry._id, xData: new Date(entry._id).toLocaleString('default', { month: 'short' }) };
                        } else if (selectedPeriod === "Yearly") {
                            return { date: entry._id, xData: new Date(entry._id).getFullYear() };
                        } else {
                            return { date: entry._id, xData: entry._id };
                        }
                    })
            )
        );

        const yAxisDataObj = {
            appointment: getDashboardData?.salesReport?.appointment?.map(entry => entry?.totalPaidAmount),
            treatment: getDashboardData?.salesReport?.treatment?.map(entry => entry?.totalPaidAmount),
            solution: getDashboardData?.salesReport?.solution?.map(entry => entry?.totalPaidAmount),
            service: getDashboardData?.salesReport?.service?.map(entry => entry?.totalPaidAmount),
            retreat: getDashboardData?.salesReport?.retreat?.map(entry => entry?.totalPaidAmount)

        };

        setChartData({
            categories: xAxisCategories.map(e => e.xData).filter((value, index, self) => self.indexOf(value) === index),
            series: [
                { name: 'Appointment', color: '#4B0082', data: yAxisDataObj?.appointment },
                { name: 'Treatment', color: '#2E4053', data: yAxisDataObj?.treatment },
                { name: 'Detox-Program', color: '#2E37A4', data: yAxisDataObj?.solution },
                { name: 'Medi-Daycare', color: '#000080', data: yAxisDataObj?.service },
                { name: 'Retreat', color: '#6F8FAF', data: yAxisDataObj?.retreat }
            ]

        });
    }, [getDashboardData, selectedPeriod]);
    // console.warn(chartData, "chartData");

    useEffect(() => {
        if (!chartData) return;

        const chartOptions = {
            chart: {
                height: 230,
                type: 'bar',
                stacked: false,
                toolbar: { show: false },
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0,
                        },
                    },
                },
            ],
            plotOptions: {
                bar: { horizontal: false, columnWidth: '50%' },
            },
            dataLabels: { enabled: false },
            stroke: { show: true, width: 6, colors: ['transparent'] },
            series: chartData.series,
            xaxis: { categories: chartData.categories },
            yaxis: { title: { text: 'Total Paid Amount' } },
        };

        if (document.querySelector('#activity-chart')) {
            const chart = new ApexCharts(document.querySelector('#activity-chart'), chartOptions);
            chart.render();
        }
    }, [chartData]);

    console.log('chk102', chartData);
    const handlePeriodChange = (period) => {
        const query = period !== "Daily" ? `?type=${period}` : "";
        setSelectedPeriod(period);
        dispatch(ActionGetDashboardData({ Query: query }));
    };
    return (
        <div className="row">
            <div className="col-12 col-md-12">
                <div className="card h-100 mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <CommonHeadingTxt Text="Sales Report" />
                        <div className="form-group mb-0">
                            {["Daily", "Monthly", "Yearly"].map(period => (
                                <Button
                                    key={period}
                                    type="primary"
                                    className="me-3"
                                    style={{ backgroundColor: '#2e37a4' }}
                                    onClick={() => handlePeriodChange(period)}
                                >
                                    {period}
                                </Button>
                            ))}
                        </div>
                    </div>
                    <div id="activity-chart"></div>
                </div>
            </div>
        </div>
    );
};

export default ActivityChart;
