import { call, put, takeEvery } from 'redux-saga/effects';
import { CREATE_PERMISSIONS, GET_PAGINATED_PERMISSIONS_LIST, GET_PERMISSIONS_LIST } from '../storeTypes';
import { CreatePermissionApi, GetPaginatedPermissionApi, GetPermissionApi } from '../../utils/api';
import { GetPaginatedPermissionSuccess, GetPermissionListSuccess } from '../Actions/PermissionsAction';

const File = 'PermissionReducer '

function* getPermissionSaga(action) {
    try {
        const response = yield call(GetPermissionApi, action.payload);
        if (response?.status === 200) {
            yield put(GetPermissionListSuccess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'getPermissionSaga Error :- ', error);
    }
}
function* CreatePermissionSaga(action) {
    try {
        const response = yield call(CreatePermissionApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'CreatePermissionSaga Error :- ', error);
    }
}
function* getPaginatedPermissionSaga(action) {
    try {
        const response = yield call(GetPaginatedPermissionApi, action.payload);
        if (response?.status === 200) {
            yield put(GetPaginatedPermissionSuccess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'getPaginatedPermissionSaga Error :- ', error);
    }
}
export function* PermissionSaga() {
    [
        yield takeEvery(GET_PERMISSIONS_LIST, getPermissionSaga),
        yield takeEvery(CREATE_PERMISSIONS, CreatePermissionSaga),
        yield takeEvery(GET_PAGINATED_PERMISSIONS_LIST, getPaginatedPermissionSaga),
    ];
}
