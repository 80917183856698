import { GET_ALL_MEDICAL_DETAILS, GET_ALL_MEDICAL_DETAILS_SUCCESS, GET_MEDICAL_DETAILS_BY_ID, GET_MEDICAL_DETAILS_BY_ID_SUCCESS, UPDATE_MEDICAL_DETAILS } from "../storeTypes";

const INIT_STATE = {
    loader: false,
    medicalStore: [],
}

function MedicalStoreReducer(state = INIT_STATE, action) {

    switch (action.type) {

        case GET_ALL_MEDICAL_DETAILS: {
            return {
                ...state,
                medicalStore: [],
                loader: true,
            }
        }

        case GET_ALL_MEDICAL_DETAILS_SUCCESS: {
            return {
                ...state,
                loader: false,
                medicalStore: action.payload,
            }
        }

        case UPDATE_MEDICAL_DETAILS: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_MEDICAL_DETAILS_BY_ID: {
            return {
                ...state,
                medicalStore: [],
                loader: true,
            };
        }
        case GET_MEDICAL_DETAILS_BY_ID_SUCCESS: {
            return {
                ...state,
                loader: false,
                medicalStore: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}

export default MedicalStoreReducer;