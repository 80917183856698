import { CREATE_FAQ, CREATE_FAQ_SUCCESS, GET_ALL_FAQ, GET_ALL_FAQ_SUCCESS } from "../storeTypes";


export const CreateFaq = (payload, callBack) => {
    return {
        type: CREATE_FAQ,
        payload,
        callBack
    };
};
export const CreateFaqSuccess = (payload) => {
    return {
        type: CREATE_FAQ_SUCCESS,
        payload
    };
};
export const GetAllFaq = (payload) => {
    return {
        type: GET_ALL_FAQ,
        payload,
    };
};
export const GetAllFaqSuccess = (payload) => {
    return {
        type: GET_ALL_FAQ_SUCCESS,
        payload
    };
};