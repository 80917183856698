import React, { useEffect } from 'react'
import Sidebar from '../../../../components/Sidebar'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import Header from '../../../../components/Header'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { CaretakerFoodPriceSchema } from '../../../../utils/FormValidation'
import { InputFromUser } from '../../../../components/Input/input'
import { getAdminuUerDetails } from '../../../../store/Actions/AuthActions'
import { ActionCreateByUrl, ActionUpdateById } from '../../../../store/Actions/CommonAction'

const AddEditFoodprice = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const { userDetails } = useSelector(state => state?.auth);
    const userId = userDetails?.[0]?._id;
    const searchParams = url.searchParams;
    const updateValue = searchParams.get("update");
    const idValue = searchParams.get("id");
    const priceValue = searchParams.get("price");
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/caretakerfoodprice-list">Food Price</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Add Food Price</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue}
                                                initialValues={{
                                                    price: priceValue || "",
                                                }}
                                                validationSchema={CaretakerFoodPriceSchema}
                                                onSubmit={(values, { setSubmitting, setErrors }) => {
                                                    if (!updateValue) {
                                                        dispatch(ActionCreateByUrl({ Query: 'admin/caretacker-foodprice', obj: values }, (Res) => {
                                                            setSubmitting(false);
                                                            if (Res?.statusCode === 200) {
                                                                window.location.href = `/caretakerfoodprice-list?page=1`;
                                                            } else if (Res?.statusCode === 400) {
                                                                setErrors(Res?.data?.errors);
                                                            } else {
                                                                // window.location.reload();
                                                            }
                                                        }))
                                                    } else {

                                                        dispatch(ActionUpdateById({ Query: `admin/caretacker-foodprice/${idValue}`, obj: values }
                                                            , (Res) => {
                                                                setSubmitting(false);
                                                                if (Res?.statusCode === 200) {
                                                                    window.location.href = `/caretakerfoodprice-list?page=1`;
                                                                } else if (Res?.statusCode === 400) {
                                                                    setErrors(Res?.data?.errors);
                                                                } else {
                                                                    window.location.reload();
                                                                }
                                                            }))

                                                    }
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    // setFieldValue,
                                                    isSubmitting,
                                                }) => {
                                                    return (
                                                        <form onSubmit={handleSubmit}>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-heading">
                                                                        <h4>Add FoodPrice Data</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <InputFromUser
                                                                    placeholder={"Enter Price"}
                                                                    type="number"
                                                                    name="price"
                                                                    onChange={handleChange}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values.price}
                                                                    label="Price"
                                                                    error={errors?.price}
                                                                    touched={touched.price}
                                                                />
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <div className="doctor-submit text-end">
                                                                    <button
                                                                        type="submit"
                                                                        onClick={() => {
                                                                            handleSubmit()
                                                                        }}
                                                                        className="btn btn-primary submit-form me-2"
                                                                        disabled={isSubmitting}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary cancel-form"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    )
                                                }}


                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default AddEditFoodprice