import { call, put, takeEvery } from 'redux-saga/effects';
import { ConsultationMasterDataApi, CreateConsultationMedicalDetailsApi, GetAppoinmentApi, getAdminUserApi, getAllConsultationApi, getDrAvailabilityApi, getDrSlotAvailabilityApi } from '../../utils/api';
import { CONSULTATION_MASTERDATA, CREATE_MEDICAL_DETAILS, GET_ADMIN_USER, GET_ALL_APPOINMENT, GET_ALL_CONSULTATION, GET_DR_AVAILABILITY, GET_DR_SLOT_AVAILABILITY } from '../storeTypes';
import { ConsultationMasterDataSuccess, getAdminUserSuccess, getAllAppoinmentSuccess, getAllConsultationSuccess, getDrAvailabilitySuccess, getDrSlotAvailabilitySuccess } from '../Actions/AppointmentAction';
import { handleApiResponse } from '../../utils/Funcations';

const File = 'AppointmentSaga '

function* getAllConsultationSaga(action) {
    try {
        const response = yield call(getAllConsultationApi, action.payload);
        if (response?.status === 200) {
            yield put(getAllConsultationSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getAllConsultationSaga Error :- ', error);
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
    }
}
function* getAllAppoinmentSaga(action) {
    try {
        const response = yield call(GetAppoinmentApi, action.payload);
        if (response?.status === 200) {
            yield put(getAllAppoinmentSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getAllConsultationSaga Error :- ', error);
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
    }
}
function* getAdminUserSaga(action) {
    try {
        const response = yield call(getAdminUserApi, action.payload);
        if (response?.status === 200) {
            yield put(getAdminUserSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getAdminUserSaga Error :- ', error);
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
    }
}

function* getDrAvailabilitySaga(action) {
    try {
        const response = yield call(getDrAvailabilityApi, action.payload);
        if (response?.status === 200) {
            yield put(getDrAvailabilitySuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getDrAvailabilitySaga Error :- ', error);
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}

function* getDrSlotAvailabilitySaga(action) {
    try {
        const response = yield call(getDrSlotAvailabilityApi, action.payload);
        if (response?.status === 200) {
            yield put(getDrSlotAvailabilitySuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getDrSlotAvailabilitySaga Error :- ', error);
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}

function* ConsultationMasterDataSaga(action) {
    try {
        const response = yield call(ConsultationMasterDataApi, action.payload);
        if (response?.status === 200) {
            yield put(ConsultationMasterDataSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'ConsultationMasterDataSaga Error :- ', error);
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}


function* CreateConsultationMedicalDetailsSaga(action) {
    try {
        const response = yield call(CreateConsultationMedicalDetailsApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data)
        }
    } catch (error) {
        console.log(File + 'ConsultationMasterDataSaga Error :- ', error);
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}



export function* AppointmentSaga() {
    [
        yield takeEvery(GET_ALL_CONSULTATION, getAllConsultationSaga),
        yield takeEvery(GET_ADMIN_USER, getAdminUserSaga),
        yield takeEvery(GET_ALL_APPOINMENT, getAllAppoinmentSaga),
        yield takeEvery(GET_DR_AVAILABILITY, getDrAvailabilitySaga),
        yield takeEvery(GET_DR_AVAILABILITY, getDrAvailabilitySaga),
        yield takeEvery(GET_DR_SLOT_AVAILABILITY, getDrSlotAvailabilitySaga),
        yield takeEvery(CONSULTATION_MASTERDATA, ConsultationMasterDataSaga),
        yield takeEvery(CREATE_MEDICAL_DETAILS, CreateConsultationMedicalDetailsSaga),
    ];
}
