/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { RoomValidationSchema } from "../../../../utils/FormValidation";
import { useDispatch, useSelector } from "react-redux";
import { InputFromUser, TextEditor } from "../../../../components/Input/input";
import { ActionCreateRoom, ActionGetRoomByID } from "../../../../store/Actions/MasterDataAction";
import { Divider } from "antd";
import UploadImage from "../../../../components/Upload/Upload";
import { ExpendableTable } from "../../../../components/Table/expendableTable";
import { ActionUpdateById, ActionUploadImage } from "../../../../store/Actions/CommonAction";
import { DOCS_URL } from "../../../../utils/globals";

const Add_Rooms = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const updateValue = searchParams.get("update");
    const pageValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const idValue = searchParams.get("id");
    useEffect(() => {
        if (idValue) {
            dispatch(ActionGetRoomByID({ Query: `/${idValue}` }));
        }
    }, [idValue, dispatch])
    const { room } = useSelector(state => state?.MasterData);
    const languages = ['en', 'hi', 'mr'];
    const sections = [
        { key: 'roomName', label: 'Room Name' },
    ];

    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/room">Rooms</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <Link to="/room">Rooms List</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Rooms</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue || false}
                                                initialValues={{
                                                    roomType: room?.roomType || "",
                                                    description: {
                                                        en: room?.description?.en || "",
                                                        hi: room?.description?.hi || "",
                                                        mr: room?.description?.mr || ""
                                                    },
                                                    shortDescription: {
                                                        en: room?.shortDescription?.en || "",
                                                        hi: room?.shortDescription?.hi || "",
                                                        mr: room?.shortDescription?.mr || ""
                                                    },
                                                    roomNo: room?.roomNo || "",
                                                    capcity: room?.capcity || "",
                                                    roomName: room?.roomName || {
                                                        en: "",
                                                        hi: "",
                                                        mr: ""
                                                    },
                                                    amenities: {
                                                        en: room?.amenities?.en || "",
                                                        hi: room?.amenities?.hi || "",
                                                        mr: room?.amenities?.mr || ""
                                                    },
                                                    statics: room?.statics || [{ "data": '', "name": 'Bed Rooms' },
                                                    { "data": '', "name": 'Max  Guest' },
                                                    { "data": '', "name": 'Square feet area' },
                                                    { "data": '', "name": 'Lorem ipsum dolor sit' },],
                                                    price: room?.price || "",
                                                    sharedPrice: room?.sharedPrice || "",
                                                    extraBedCapacity: room?.extraBedCapacity || "",
                                                    ratings: {
                                                        mainRating: room?.ratings?.mainRating || "",
                                                        mainRatingName: room?.ratings?.mainRatingName || "",
                                                        subRatings: [
                                                            {
                                                                name: room?.ratings?.subRatings?.[0]?.name || "",
                                                                rating: room?.ratings?.subRatings?.[0]?.rating || '',
                                                            }
                                                        ]
                                                    },
                                                    roomImages: room?.roomImages || [],
                                                    videoIframes: room?.videoIframes || [],
                                                }}
                                                validationSchema={RoomValidationSchema}
                                                onSubmit={(values, { setSubmitting, setErrors }) => {
                                                    if (updateValue === 'false') {
                                                        dispatch(ActionCreateRoom(values,
                                                            (Res) => {
                                                                setSubmitting(false);
                                                                if (Res?.statusCode === 200) {
                                                                    window.location.href = `/room?page=1`;
                                                                } else if (Res?.statusCode === 400) {
                                                                    setErrors(Res?.data?.errors);
                                                                } else {
                                                                    window.location.reload();
                                                                }
                                                            }))
                                                    } else {
                                                        dispatch(ActionUpdateById({
                                                            Query: `admin/rooms/${idValue}`,
                                                            obj: values
                                                        }, (Res) => {
                                                            setSubmitting(false);
                                                            if (Res?.statusCode === 200) {
                                                                window.location.href = `/room?page=1`;
                                                            } else if (Res?.statusCode === 400) {
                                                                setErrors(Res?.data?.errors);
                                                            } else {
                                                                window.location.reload();
                                                            }
                                                        }))
                                                    }
                                                }}

                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldTouched,
                                                    setFieldValue,
                                                    isSubmitting,
                                                }) => {
                                                    return (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-heading">
                                                                        <h4>Add Rooms Data</h4>
                                                                    </div>
                                                                </div>

                                                                <InputFromUser
                                                                    placeholder={"Room Type"}
                                                                    type="text"
                                                                    name="roomType"
                                                                    onChange={handleChange}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values.roomType}
                                                                    label="Room Type"
                                                                    error={errors?.roomType}
                                                                    touched={touched.roomType}
                                                                />
                                                                <InputFromUser
                                                                    placeholder={"Room No."}
                                                                    type="text"
                                                                    name="roomNo"
                                                                    onChange={handleChange}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values.roomNo}
                                                                    label="Room No."
                                                                    error={errors?.roomNo}
                                                                    touched={touched.roomNo}
                                                                />

                                                                <InputFromUser
                                                                    placeholder={"Capacity"}
                                                                    type="text"
                                                                    name="capcity"
                                                                    onChange={handleChange}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values.capcity}
                                                                    label="Capacity"
                                                                    error={errors?.capcity}
                                                                    touched={touched.capcity}
                                                                />

                                                                <InputFromUser
                                                                    placeholder={"Pasbasic roomt Your YouTube Video Link"}
                                                                    type="url"
                                                                    name="videoIframes"
                                                                    onChange={(v) => setFieldValue("videoIframes", [v?.target?.value], true)}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values.videoIframes?.[0]}
                                                                    label="Iframe Link"
                                                                    error={errors?.videoIframes}
                                                                    touched={touched.videoIframes}
                                                                />
                                                                <div className="d-flex justify-content-evenly align-items-center col">
                                                                    <label className="col-form-label">
                                                                        {"Upload Room Banner :-"}
                                                                    </label>
                                                                    <div>
                                                                        <UploadImage
                                                                            className={"img-fluid"}
                                                                            fileList={values?.roomImages?.map((val, ind) => ({
                                                                                uid: ind + 'roomImages',
                                                                                name: (DOCS_URL + values?.roomImages?.[ind]).split('/')?.[(DOCS_URL + values?.roomImages?.[ind]).split('/').length - 1] || '',
                                                                                status: 'done',
                                                                                url: DOCS_URL + values?.roomImages?.[ind],
                                                                            }))}
                                                                            handleChange={(v) => {
                                                                                if (v.file && v.file.status === 'uploading') {
                                                                                    const formData = new FormData();
                                                                                    formData.append("isRoom", true);
                                                                                    formData.append("attachment", v?.file?.originFileObj);
                                                                                    dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                        if (typeof res === 'object') {
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: 'roomImages',
                                                                                                    value: [...(values?.roomImages || []), res[0]],
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    }))
                                                                                } else if (v.file && v.file.status === 'removed') {
                                                                                    const filteredFiles = values?.roomImages.filter((url) => (DOCS_URL + url) != v?.file?.url);
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'roomImages',
                                                                                            value: filteredFiles,
                                                                                        },
                                                                                    });
                                                                                }
                                                                            }}
                                                                            UploadImagelimit={100} />
                                                                    </div>
                                                                </div>
                                                                <ExpendableTable
                                                                    Title={"Room Details"}
                                                                    emptyDataForCreatingField={{ "data": '', "name": '' }}
                                                                    Data={values?.statics}
                                                                    Columns={[
                                                                        { name: 'name', type: 'text', label: 'Title', idDisable: true },
                                                                        { name: 'data', type: 'number', label: 'Number' },
                                                                    ]}
                                                                    onChange={(v) => setFieldValue("statics", v, true)}
                                                                    onBlur={(v) => setFieldTouched("statics", v, true)}
                                                                    errors={errors?.statics}
                                                                    touched={touched?.statics}
                                                                />

                                                                {sections?.map((section) => (
                                                                    <div key={section?.key}>
                                                                        <div className="col-lg-12">
                                                                            <div className="form-heading">
                                                                                {/* <h5>{section.label}</h5> */}
                                                                                <Divider style={{ fontWeight: "600" }}>{section?.label}</Divider>
                                                                            </div>
                                                                        </div>
                                                                        {languages?.map((lang) => (
                                                                            <div key={`${section?.key}-${lang}`} className="language-form">
                                                                                <InputFromUser
                                                                                    placeholder={`${lang === 'en' ? 'English' : lang === 'hi' ? 'Hindi' : 'Marathi'}`}
                                                                                    type="text"
                                                                                    name={section?.key || ''}
                                                                                    onChange={(v) => setFieldValue(section?.key, { ...values?.[section?.key], [lang]: v?.target?.value }, true)}
                                                                                    FormGroupClassName="col-xl-12 col-md-12"
                                                                                    LabelClassName="col-form-label"
                                                                                    onBlur={handleBlur}
                                                                                    value={values[section?.key][lang]}
                                                                                    label={`${section?.label} in ${lang === 'en' ? 'English' : lang === 'hi' ? 'Hindi' : 'Marathi'}`}
                                                                                    error={errors?.[section?.key]?.[lang] || ''}
                                                                                    touched={touched[section?.key]?.[lang] || false}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                                <div className="row mt-4">
                                                                    <Divider style={{ fontWeight: "600", marginBottom: 30 }}>Short Descerpition</Divider>
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Enter Description"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="Short Description"
                                                                        value={values?.shortDescription?.en}
                                                                        name="ShortDescription-en"
                                                                        onChange={(v) => {
                                                                            const UpdateValue = values?.shortDescription
                                                                            UpdateValue.en = v?.target?.value
                                                                            setFieldValue("shortDescription", UpdateValue, true)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.shortDescription?.en}
                                                                        touched={touched?.shortDescription?.en}
                                                                    />
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Marathi"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="Short Description (*Marathi)"
                                                                        name="ShortDescription-mr"
                                                                        value={values?.shortDescription?.mr}
                                                                        onChange={(v) => {
                                                                            const UpdateValue = values?.shortDescription
                                                                            UpdateValue.mr = v?.target?.value
                                                                            setFieldValue("shortDescription", UpdateValue, true)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.shortDescription?.mr}
                                                                        touched={touched?.shortDescription?.mr}
                                                                    />
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Hindi"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="Short Description (*Hindi)"
                                                                        value={values?.shortDescription?.hi}
                                                                        name="ShortDescription-hi"
                                                                        onChange={(v) => {
                                                                            const UpdateValue = values?.shortDescription
                                                                            UpdateValue.hi = v?.target?.value
                                                                            setFieldValue("shortDescription", UpdateValue, true)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.shortDescription?.hi}
                                                                        touched={touched?.shortDescription?.hi}
                                                                    />
                                                                </div>

                                                                <div className="row">
                                                                    <div className="my-4" >
                                                                        <Divider style={{ fontWeight: "600" }}>Descerpition</Divider></div>
                                                                    <div id="editor" >
                                                                        <div className="mt-3">Description in English</div>
                                                                        <TextEditor
                                                                            name="Description-en"
                                                                            value={values?.description?.en}
                                                                            errorMessage={errors?.description?.en}
                                                                            onBlur={handleBlur}
                                                                            onChange={(v, editor) => {
                                                                                const UpdateValue = values?.description
                                                                                UpdateValue.en = editor.getData()
                                                                                setFieldValue("description", UpdateValue, true)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div id="editor" >
                                                                        <div className="mt-3">Description in Hindi</div>
                                                                        <TextEditor
                                                                            name="Description-hi"
                                                                            value={values?.description?.hi}
                                                                            errorMessage={errors?.description?.hi}
                                                                            // onBlur={handleBlur}
                                                                            onChange={(v, editor) => {
                                                                                const UpdateValue = values?.description
                                                                                UpdateValue.hi = editor.getData()
                                                                                setFieldValue("description", UpdateValue, true)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div id="editor" >
                                                                        <div className="mt-3">Description in Marathi</div>
                                                                        <TextEditor
                                                                            name="description-mr"
                                                                            value={values?.description?.mr}
                                                                            errorMessage={errors?.description?.mr}
                                                                            // onBlur={handleBlur}
                                                                            onChange={(v, editor) => {
                                                                                const UpdateValue = values?.description
                                                                                UpdateValue.mr = editor.getData()
                                                                                setFieldValue("description", UpdateValue, true)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>


                                                                <div className="row">
                                                                    <div className="my-4" >
                                                                        <Divider style={{ fontWeight: "600" }}>Amenities</Divider></div>
                                                                    <div id="editor" >
                                                                        <div className="mt-3">Amenities in English</div>
                                                                        <TextEditor
                                                                            name="amenities-en"
                                                                            value={values?.amenities?.en}
                                                                            errorMessage={errors?.amenities?.en}
                                                                            // onBlur={handleBlur}
                                                                            onChange={(v, editor) => {
                                                                                const UpdateValue = values?.amenities
                                                                                UpdateValue.en = editor.getData()
                                                                                setFieldValue("amenities", UpdateValue, true)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div id="editor" >
                                                                        <div className="mt-3">Amenities in Hindi</div>
                                                                        <TextEditor
                                                                            name="amenities-hi"
                                                                            value={values?.amenities?.hi}
                                                                            errorMessage={errors?.amenities?.hi}
                                                                            // onBlur={handleBlur}
                                                                            onChange={(v, editor) => {
                                                                                const UpdateValue = values?.amenities
                                                                                UpdateValue.hi = editor.getData()
                                                                                setFieldValue("amenities", UpdateValue, true)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div id="editor" >
                                                                        <div className="mt-3">Amenities in Marathi</div>
                                                                        <TextEditor
                                                                            name="Description-mr"
                                                                            value={values?.amenities?.mr}
                                                                            errorMessage={errors?.amenities?.mr}
                                                                            // onBlur={handleBlur}
                                                                            onChange={(v, editor) => {
                                                                                const UpdateValue = values?.amenities
                                                                                UpdateValue.mr = editor.getData()
                                                                                setFieldValue("amenities", UpdateValue, true)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <InputFromUser
                                                                    placeholder={"Price"}
                                                                    type="text"
                                                                    name="price"
                                                                    onChange={handleChange}
                                                                    FormGroupClassName={"col-xl-6 col-md-12 mt-2"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values?.price}
                                                                    label="Price"
                                                                    error={errors?.price}
                                                                    touched={touched?.price}
                                                                />

                                                                <InputFromUser
                                                                    placeholder={"Shared Price"}
                                                                    type="text"
                                                                    name="sharedPrice"
                                                                    onChange={handleChange}
                                                                    FormGroupClassName={"col-xl-6 col-md-12 mt-2"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values.sharedPrice}
                                                                    label="Shared Price"
                                                                    error={errors?.sharedPrice}
                                                                    touched={touched.sharedPrice}
                                                                />

                                                                <ExpendableTable
                                                                    Title={"Ratings"}
                                                                    emptyDataForCreatingField={{ "name": '', "rating": '' }}
                                                                    Data={values?.ratings?.subRatings}
                                                                    Columns={[
                                                                        { name: 'name', type: 'text', label: 'Title' },
                                                                        { name: 'rating', type: 'number', label: 'Rating', placeholder: 'Out Of 5' },
                                                                        { name: 'Action', type: 'action', label: 'Icon' },
                                                                    ]}
                                                                    onChange={(v) => setFieldValue("ratings", { ...values?.ratings, subRatings: v }, true)}
                                                                    onBlur={(v) => setFieldTouched("ratings", { ...touched?.ratings, subRatings: v }, true)}
                                                                    errors={errors?.ratings?.subRatings}
                                                                    touched={touched?.ratings?.subRatings}
                                                                    HeaderComponent={<div className="row">
                                                                        <InputFromUser
                                                                            placeholder={`Enter Main Rating`}
                                                                            type="number"
                                                                            name={`mainRating`}
                                                                            onChange={(v) => setFieldValue("ratings", { ...values?.ratings, mainRating: v?.target?.value }, true)}
                                                                            FormGroupClassName="col-xl-12 col-md-12"
                                                                            LabelClassName="col-form-label"
                                                                            onBlur={(v) => setFieldTouched("ratings", { ...touched?.ratings, mainRating: true }, true)}
                                                                            value={values?.ratings?.mainRating}
                                                                            label={`Main Rating`}
                                                                            error={errors?.ratings?.mainRating || ''}
                                                                            touched={touched?.ratings?.mainRating || false}
                                                                        />
                                                                        <InputFromUser
                                                                            placeholder={`Enter Main Rating Name`}
                                                                            type="text"
                                                                            name={`mainRatingName`}
                                                                            onChange={(v) => setFieldValue("ratings", { ...values?.ratings, mainRatingName: v?.target?.value }, true)}
                                                                            FormGroupClassName="col-xl-12 col-md-12"
                                                                            LabelClassName="col-form-label"
                                                                            onBlur={(v) => setFieldTouched("ratings", { ...touched?.ratings, mainRatingName: true }, true)}
                                                                            value={values?.ratings?.mainRatingName}
                                                                            label={`Main Rating Name`}
                                                                            error={errors?.ratings?.mainRatingName || ''}
                                                                            touched={touched?.ratings?.mainRatingName || false}
                                                                        />
                                                                    </div>}
                                                                />



                                                                <div className="col-12 mt-4">
                                                                    <div className="doctor-submit text-end">
                                                                        <button
                                                                            type="submit"
                                                                            onClick={() => {
                                                                                handleSubmit()
                                                                            }}
                                                                            className="btn btn-primary submit-form me-2"
                                                                            disabled={isSubmitting}
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary cancel-form"
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Formik>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div >
            </>
        </>
    );
};

export default Add_Rooms;