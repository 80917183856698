/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import moment from 'moment';
import { CustomTable } from "../../components/Table";
import { InputFromUser } from "../../components/Input/input";
import { Button, Divider, Modal } from "antd";
import { ListItems } from "../../components/Card/Cards";
import { Options } from "../../utils/Data";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ActionGetPatientPlanList } from "../../store/Actions/AppConfigAction";
import { ExpendableTable } from "../../components/Table/expendableTable";
import { printDiv } from "../../utils/Funcations";
import { ActionUpdateById } from "../../store/Actions/CommonAction";
const NameHeading = () => {
    return (
        <div style={{}}>
            <h2 style={{ fontFamily: 'sans-serif' }}>〘 Prakruti Ayurvedic Health Center & Hospital 〙</h2>
            <h4 style={{ fontFamily: 'fantasy', textAlign: 'center' }}>A Project By Prakruti Ayurvedic Health Resort ,Satara</h4>
        </div>)
}
function isEmpty(value) {
    return (
        value === undefined ||
        value === null ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'object' && Object.keys(value).length === 0)
    );
}



const DrRoundFormInit = {
    fastingSugarLevel: "",
    PPSugarLevel: "",
    randomSugarLevel: "",
    BPMorning: "",
    BPEvening: "",
    sparshaSahatva: [{ "Yakrut": [] }, { "Pleeha": [] }, { "Vaam Vruka": [] }, { "Apan Kaksha": [] }],
    naad: [],
    yakrutNada: [],
    plihaNada: [],
    Mala: [],
    Mutra: [],
    jivha: [],
}
const IndorCase = () => {
    const dispatch = useDispatch();
    const { patientPlanList, loader } = useSelector(state => state?.appConfig);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const patientPlanId = patientPlanList?.[0]?._id;
    const treatmentSlotId = searchParams.get("slotId");
    const [newPatientPlanList, setNewPatientPlanList] = useState([])
    const solutionSlotId = searchParams.get("solutionslotId");
    const [currentIndex, setCurrentIndex] = useState(1)
    const todayDate = moment().format('YYYY-MM-DD')
    const PatientPlanDummy = Object.assign({}, patientPlanList?.[0]?.serviceDetails)
    const [patientPlanData, setPatientPlanData] = useState({ dataSource: [], UpdatedArraylist: [], IntitalDate: [], bookedDate: [], OldTime: {} })
    const [isModel, SetModel] = useState({ date: null, open: false })
    let Dates = Object.assign([], patientPlanData?.bookedDate)
    function processObjectsInPairs(startIndex) {
        let endIndex = startIndex + 2;
        if (endIndex <= patientPlanData?.IntitalDate?.length) {
            let pair = patientPlanData?.IntitalDate?.slice(startIndex, endIndex);
            setCurrentIndex(startIndex + 1)
            setPatientPlanData({ ...patientPlanData, bookedDate: pair })
        } else {
            let pair = patientPlanData?.IntitalDate?.slice(0, 2);
            setCurrentIndex(1)
            setPatientPlanData({ ...patientPlanData, bookedDate: pair })
        }
    }
    const medicineInit = {
        "medicineName": "",
        "days": null,
        "whenToTake": [],
        'anupan': '',
        'howMuch': '',
        'date': todayDate || ''
    }
    function getDatesByServiceName(serviceName, startTime) {
        let datesObj = {};
        Object.keys(PatientPlanDummy).forEach((dates) => {
            const FilterList = PatientPlanDummy[dates]?.filter(
                (filter) => filter?.startTime === startTime && filter?.serviceName === serviceName
            );
            datesObj = {
                ...datesObj,
                [dates]: {
                    "totalMinutes": (FilterList?.[0]?.totalMinutes && FilterList?.[0]?.totalMinutes) || '0',
                    "medicine": FilterList?.[0]?.medicine,
                    "medicineValue": FilterList?.[0]?.medicineValue
                }
            };
        });
        return datesObj;
    }

    function getService(array) {
        const updatedArray = array.map((v) => {
            const DatesObj = getDatesByServiceName(v?.serviceName, v?.startTime);
            return { ...v, ...DatesObj };
        });
        return updatedArray;
    }

    const dateColumn = patientPlanData?.bookedDate?.slice(0, 2)?.map((value, index) => {
        return {
            title: () => {
                return (
                    <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                        <span>{value}</span>
                        <div>
                            {todayDate === value && <Button size="large" type="link" onClick={() => SetModel({ date: value, open: true })}>• • •</Button>}
                            {(index === 1) ? <Button type="dashed"
                                onClick={() => processObjectsInPairs(currentIndex)}
                            >{(currentIndex + 1 >= Dates?.length) ? "Previous" : "Next"}</Button> : null}
                        </div>
                    </div>)
            },
            dataIndex: value,
            render: (serviceDetails, record, key) => {
                const drp = (serviceDetails?.medicineValue || serviceDetails?.medicine?.[0])
                return (<div className="d-flex justify-content-center">
                    <InputFromUser
                        placeholder={"Select treatment"}
                        type="singleSelect"
                        name="value.en"
                        onChange={(v) => {
                            const matchedObj = patientPlanData.dataSource.map((dataSource) => {
                                if (dataSource.serviceName === record.serviceName && dataSource.startTime === record.startTime) {
                                    dataSource[value] = { ...serviceDetails, medicineValue: v }
                                    return dataSource
                                } else {
                                    return dataSource
                                }
                            })
                            setPatientPlanData({ ...patientPlanData, dataSource: matchedObj })
                        }}
                        FormGroupClassName={"col col-xl-6 col-md-12"}
                        LabelClassName={"col-form-label"}
                        onBlur={undefined}
                        value={drp}
                        InputContainer={""}
                        label=""
                        options={[...(serviceDetails?.medicine || []), 'On Hold', 'Not Started'] || ''}
                        error={""}
                        touched={false}
                    />
                </div>
                )
            }
        }
    }) || {}
    const column = [
        {
            title: patientPlanList?.[0]?.treatmentName || '',
            dataIndex: "treatmentName ",
            children: [
                {
                    title: "Treatment Time",
                    dataIndex: "treatmentTime",
                    render: (text, record, key) => {
                        return (
                            <span>{record.startTime + " To " + record.endTime}</span>
                        )
                    },
                }, {
                    title: "Treatment Name",
                    dataIndex: "serviceName",

                }, {
                    title: "Amladharmi",
                    dataIndex: "treatmentName",
                    children: [
                        ...dateColumn],
                }]
        }]
    function CombineDataFun() {
        const transformedData = {};
        patientPlanData?.dataSource?.forEach(item => {
            Object.keys(item).forEach(key => {
                if (key.match(/^\d{4}-\d{2}-\d{2}$/)) {
                    if (!transformedData[key]) {
                        transformedData[key] = [];
                    }
                    transformedData[key].push({
                        "serviceName": item.serviceName,
                        "startTime": item.startTime,
                        "endTime": item.endTime,
                        "totalMinutes": ((item[key].totalMinutes) && item[key].totalMinutes) || "0",
                        "medicine": item[key].medicine,
                        "medicineValue": ((item[key].medicineValue) && item[key].medicineValue) || item[key].medicine?.[0],
                        // "_id": item._id
                    });
                }
            });
        });
        let planDetails = patientPlanList?.[0];
        let updatedPlanDetails = { ...planDetails, serviceDetails: transformedData };
        return updatedPlanDetails;
    }
    useEffect(() => {
        if (PatientPlanDummy) {
            const ListOfService = Object.assign({}, PatientPlanDummy)
            const OldTime = Object.keys(patientPlanList?.[0]?.doctorRoundForm || {})?.includes(todayDate) && patientPlanList?.[0]?.doctorRoundForm?.[todayDate]
            const TempData = Object.values(ListOfService || {}) || []
            const ServiceList = getService(TempData?.[0] || [])
            setPatientPlanData({
                ...patientPlanData,
                bookedDate: Object.keys(ListOfService || {}),
                IntitalDate: Object.keys(ListOfService || {}),
                dataSource: ServiceList,
                OldTime: OldTime
            })
            setNewPatientPlanList(patientPlanList?.[0])
        }
    }, [patientPlanList])
    useEffect(() => {
        const slotId = solutionSlotId;
        const queryParam = slotId ? `solutionId=${solutionSlotId}` : `treatmentId=${treatmentSlotId}`;
        dispatch(ActionGetPatientPlanList({ Query: `?${queryParam}` }));
    }, [dispatch, treatmentSlotId, solutionSlotId]);


    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div className="page-wrapper">
                    <div className="d-flex justify-content-end mt-4 me-5">
                        <Button
                            type="primary"
                            className="text-left"
                            onClick={() => {
                                printDiv('printableArea')
                            }}
                        >Print Form</Button>
                    </div>
                    <Formik
                        initialValues={{
                            notes: [" "],
                            medicines: [medicineInit],
                            doctorRoundForm: {}
                        }}
                        // validationSchema={}
                        onSubmit={(values, { setErrors, setSubmitting }) => {
                            const updatedArray = CombineDataFun()
                            const submitInfo = { ...updatedArray, ...values }
                            let medicinesObject = {};
                            submitInfo?.medicines.forEach(medicine => {
                                let date = medicine.date;
                                if (date) {
                                    if (!medicinesObject[date]) {
                                        medicinesObject[date] = [];
                                    }
                                    delete medicine.date;
                                    medicinesObject[date].push(medicine);
                                }
                            });
                            let doctorRoundFormCopy = Object.assign({}, patientPlanList?.[0]?.doctorRoundForm)

                            let doctorRoundForm = [submitInfo?.doctorRoundForm] || {}

                            function addToForm(date, entry) {
                                if (doctorRoundFormCopy[date]) {
                                    doctorRoundFormCopy[date].push(entry);
                                } else {
                                    doctorRoundFormCopy[date] = [entry];
                                }
                            }

                            if (Object.keys(submitInfo?.doctorRoundForm).includes(todayDate) && !isEmpty(patientPlanData?.OldTime)) {
                                if (submitInfo && submitInfo.doctorRoundForm && todayDate) {
                                    addToForm(todayDate, submitInfo?.doctorRoundForm[todayDate]);
                                }
                                doctorRoundForm = doctorRoundFormCopy
                            }

                            const FinalValue = {
                                "serviceDetails": submitInfo?.serviceDetails,
                                "doctorRoundForm": doctorRoundForm,
                                "medicine": medicinesObject,
                                "notes": submitInfo?.notes,
                            }


                            if (isEmpty(doctorRoundForm)) {
                                delete FinalValue.doctorRoundForm;
                            }

                            if (isEmpty(medicinesObject)) {
                                delete FinalValue.medicine;
                            }

                            if (isEmpty((submitInfo?.notes?.[0] === '') ? null : submitInfo?.notes)) {
                                delete FinalValue.notes;
                            }
                            setTimeout(() => {
                                dispatch(ActionUpdateById({ Query: `admin/patient-plan/${patientPlanId}`, obj: FinalValue }
                                    , (Respons) => {
                                        if (Respons?.statusCode === 200) {
                                            console.log('200');
                                        } else if (Respons?.statusCode === 400) {
                                            setErrors(Respons?.data?.errors);
                                        }
                                        setSubmitting(false);
                                    }));
                            }, 1000);



                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setValues,
                            setSubmitting,
                            isSubmitting,
                            /* and other goodies */
                        }) => {
                            function doctorRoundFormFill(value, key) {
                                let oldValue = values?.doctorRoundForm
                                if (isModel?.date?.match(/^\d{4}-\d{2}-\d{2}$/)) {
                                    if (!oldValue[isModel?.date]) {
                                        oldValue[isModel?.date] = { ...DrRoundFormInit, [key]: value };
                                    } else if (Object.keys(values?.doctorRoundForm).includes(isModel?.date)) {
                                        oldValue[isModel?.date] = { ...oldValue[isModel?.date], [key]: value }
                                    }
                                }
                                setFieldValue('doctorRoundForm', oldValue, true);
                            }
                            return (
                                <div className="content" >
                                    <div className="card-box profile-header mb-5" id="printableArea">
                                        <div className="row">
                                            <div className="">
                                                <div className="d-flex justify-content-center my-5">
                                                    {NameHeading()}
                                                </div>
                                                <div className="profile-view row">
                                                    {/* <Card style={{ borderWidth: 0, borderColor: 'red', margin: 5 }} className={"col-md-5 col-xxl-3"} > */}
                                                    {/* </Card> */}
                                                    <div className="row">
                                                        <div className="col">{newPatientPlanList?.customerId?.name && ListItems({ title: 'Name', value: newPatientPlanList?.customerId?.name })}</div>
                                                        <div className="col">{newPatientPlanList?.customerId?.email && ListItems({ title: 'Email', value: newPatientPlanList?.customerId?.email })}</div>
                                                        <div className="col">{newPatientPlanList?.customerId?.age && ListItems({ title: 'Age', value: newPatientPlanList?.customerId?.age })}</div>
                                                        <div className="col">{newPatientPlanList?.customerId?.phoneNo && ListItems({ title: 'Phone No', value: newPatientPlanList?.customerId?.phoneNo })}</div>
                                                        <div className="col">{newPatientPlanList?.customerId?.gender && ListItems({ title: 'Gender', value: newPatientPlanList?.customerId?.gender })}</div>
                                                        <div className="col">{newPatientPlanList?.customerId?.birthDate && ListItems({ title: 'Birth Date', value: newPatientPlanList?.customerId?.birthDate && moment(newPatientPlanList?.customerId?.birthDate)?.format('DD-MM-YYYY') })}</div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col">{newPatientPlanList?.treatmentName && ListItems({ title: 'TreatmentName', value: newPatientPlanList?.treatmentName })}</div>
                                                        <div className="col">{newPatientPlanList?.customerId?.roomDetails && ListItems({ title: 'Room Details', value: newPatientPlanList?.[0]?.additionalInfo?.roomDetails.map((v, i) => v.roomType + " , ") })}</div>
                                                        <div className="col">{newPatientPlanList?.customerId?.roomDetails && ListItems({ title: 'Patient Details', value: newPatientPlanList?.[0]?.additionalInfo?.roomDetails.map((v, i) => v.name + ' , ') })}</div>
                                                        <div className="col">{newPatientPlanList?.customerId?.roomDetails && ListItems({ title: 'Total Days', value: newPatientPlanList?.[0]?.additionalInfo?.totalDays })}</div>
                                                        <div className="col">{newPatientPlanList?.customerId?.roomDetails && ListItems({ title: 'CheckIn Date', value: newPatientPlanList?.[0]?.additionalInfo?.startDate })}</div>
                                                        <div className="col">{newPatientPlanList?.customerId?.roomDetails && ListItems({ title: 'CheckOut Date', value: newPatientPlanList?.[0]?.additionalInfo?.endDate })}</div>
                                                        <div className="col">{newPatientPlanList?.customerId?.roomDetails && ListItems({ title: 'Care Taker Count', value: newPatientPlanList?.[0]?.additionalInfo?.careTakerCount })}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Modal
                                            open={isModel?.open}
                                            closable={true}
                                            forceRender={true}
                                            zIndex={1040}
                                            styles={{
                                                body: {
                                                    overflow: "hidden",
                                                },
                                                mask: {
                                                    backdropFilter: 'blur(10px)',
                                                },
                                                content: {
                                                    borderRadius: 40,
                                                },
                                            }}
                                            okText={'Submit'}
                                            title={"Doctor Round Form --" + isModel?.date}
                                            footer={false}
                                            onCancel={() => SetModel({ date: null, open: false })}
                                        >
                                            <div className="row">
                                                <div className="d-flex justify-content-center my-5">
                                                    {NameHeading()}
                                                </div>
                                                <InputFromUser
                                                    placeholder={"Enter Your Time"}
                                                    type="singleSelect"
                                                    name="time"
                                                    FormControlClassName={'form-control'}
                                                    FormGroupClassName={"col-xl-3 col-md-8"}
                                                    LabelClassName={"col-form-label"}
                                                    onBlur={undefined}
                                                    label="Time"
                                                    onChange={(v) => doctorRoundFormFill(v, 'time')}
                                                    value={values?.doctorRoundForm?.[isModel?.date]?.time}
                                                    options={["morning", "evening"]}
                                                    error={errors.time}
                                                    touched={touched.time}
                                                />
                                                <InputFromUser
                                                    label="Fasting Sugar Level"
                                                    placeholder="Enter Fasting Sugar Level"
                                                    type="text"
                                                    name="fastingSugarLevel"
                                                    onBlur={() => {
                                                        undefined
                                                    }}
                                                    FormControlClassName={'form-control'}
                                                    FormGroupClassName={"col-xl-3 col-md-8"}
                                                    LabelClassName={"col-form-label"}
                                                    onChange={(v) => doctorRoundFormFill(v.target.value, 'fastingSugarLevel')}
                                                    value={values?.doctorRoundForm?.[isModel?.date]?.fastingSugarLevel}
                                                    error={errors.fastingSugarLevel}
                                                    touched={touched.fastingSugarLevel}
                                                />
                                                <InputFromUser
                                                    label="PP Sugar Level"
                                                    placeholder="Enter PP Sugar Level"
                                                    type="text"
                                                    name="PPSugarLevel"
                                                    onBlur={() => {
                                                        undefined
                                                    }}
                                                    FormControlClassName={'form-control'}
                                                    FormGroupClassName={"col-xl-3 col-md-8"}
                                                    LabelClassName={"col-form-label"}
                                                    onChange={(v) => doctorRoundFormFill(v.target.value, 'PPSugarLevel')}
                                                    value={values?.doctorRoundForm?.[isModel?.date]?.PPSugarLevel}
                                                    error={errors.PPSugarLevel}
                                                    touched={touched.PPSugarLevel}
                                                />
                                                <InputFromUser
                                                    label="Random Sugar Level"
                                                    placeholder="Enter Random Sugar Level"
                                                    type="text"
                                                    name="randomSugarLevel"
                                                    onBlur={() => {
                                                        undefined
                                                    }}
                                                    FormControlClassName={'form-control'}
                                                    FormGroupClassName={"col-xl-3 col-md-8"}
                                                    LabelClassName={"col-form-label"}
                                                    onChange={(v) => doctorRoundFormFill(v.target.value, 'randomSugarLevel')}
                                                    value={values?.doctorRoundForm?.[isModel?.date]?.randomSugarLevel}
                                                    error={errors.randomSugarLevel}
                                                    touched={touched.randomSugarLevel}
                                                />
                                                <InputFromUser
                                                    label="BP Morning"
                                                    placeholder="Enter BP Morning"
                                                    type="text"
                                                    name="BPMorning"
                                                    onBlur={() => {
                                                        undefined
                                                    }}
                                                    FormControlClassName={'form-control'}
                                                    FormGroupClassName={"col-xl-3 col-md-8"}
                                                    LabelClassName={"col-form-label"}
                                                    onChange={(v) => doctorRoundFormFill(v.target.value, 'BPMorning')}
                                                    value={values?.doctorRoundForm?.[isModel?.date]?.BPMorning}
                                                    error={errors.BPMorning}
                                                    touched={touched.BPMorning}
                                                />
                                                <InputFromUser
                                                    label="BP Evening"
                                                    placeholder="Enter BP Evening"
                                                    type="text"
                                                    name="BPEvening"
                                                    onBlur={() => {
                                                        undefined
                                                    }}
                                                    FormControlClassName={'form-control'}
                                                    FormGroupClassName={"col-xl-3 col-md-8"}
                                                    LabelClassName={"col-form-label"}
                                                    onChange={(v) => doctorRoundFormFill(v.target.value, 'BPEvening')}
                                                    value={values?.doctorRoundForm?.[isModel?.date]?.BPEvening}
                                                    error={errors.BPEvening}
                                                    touched={touched.BPEvening}
                                                />
                                                <InputFromUser
                                                    placeholder={"Enter Your Nadi"}
                                                    type="multiple"
                                                    name="naad"
                                                    FormControlClassName={'form-control'}
                                                    FormGroupClassName={"col-xl-3 col-md-8"}
                                                    LabelClassName={"col-form-label"}
                                                    onBlur={undefined}
                                                    label="Nadi"
                                                    onChange={(v) => doctorRoundFormFill(v, 'naad')}
                                                    value={values?.doctorRoundForm?.[isModel?.date]?.naad}
                                                    options={Options.Nadi}
                                                    error={errors.naad}
                                                    touched={touched.naad}
                                                />
                                                <InputFromUser
                                                    label="Yakrut Nada"
                                                    type="multiple"
                                                    name="yakrutNada"
                                                    onBlur={() => {
                                                        undefined
                                                    }}
                                                    placeholder={"Enter Your Yakrut Nada"}
                                                    FormControlClassName={'form-control'}
                                                    FormGroupClassName={"col-xl-3 col-md-8"}
                                                    LabelClassName={"col-form-label"}
                                                    onChange={(v) => doctorRoundFormFill(v, 'yakrutNada')}
                                                    value={values?.doctorRoundForm?.[isModel?.date]?.yakrutNada}
                                                    options={Options.Nada.Yakrut}
                                                    error={errors.yakrutNada}
                                                    touched={touched.yakrutNada}
                                                />
                                                <InputFromUser
                                                    label="Pliha Nada"
                                                    placeholder="Enter Pliha Nada"
                                                    type="multiple"
                                                    name="plihaNada"
                                                    onBlur={() => {
                                                        undefined
                                                    }}
                                                    FormControlClassName={'form-control'}
                                                    FormGroupClassName={"col-xl-3 col-md-8"}
                                                    LabelClassName={"col-form-label"}
                                                    onChange={(v) => doctorRoundFormFill(v, 'plihaNada')}
                                                    value={values?.doctorRoundForm?.[isModel?.date]?.plihaNada}
                                                    options={Options.Pleeha}
                                                    error={errors.plihaNada}
                                                    touched={touched.plihaNada}
                                                />
                                                {values?.sparshaSahatva?.map((item, i) => {
                                                    let NewValue = { ...values };
                                                    const NameV = Object.keys(item)?.[0];
                                                    return (
                                                        <InputFromUser
                                                            type="multiple"
                                                            key={i + 'drRound'}
                                                            label={Object.keys(item)?.[0] + " SparshaSahatva"}
                                                            FormControlClassName={'form-control'}
                                                            FormGroupClassName={"col-xl-3 col-md-8"}
                                                            LabelClassName={"col-form-label"}
                                                            name={'sparshaSahatva'}
                                                            onChange={(value) => {
                                                                NewValue.sparshaSahatva[i] = {
                                                                    [NameV]: value
                                                                };
                                                                doctorRoundFormFill(NewValue.sparshaSahatva, 'sparshaSahatva')
                                                            }}
                                                            onBlur={(value) => { }}
                                                            error={typeof errors.sparshaSahatva === "string" ? errors.sparshaSahatva :
                                                                errors.sparshaSahatva?.find((e) => e?.[Object.keys(item)?.[0]])?.[Object.keys(item)?.[0]] || ""}
                                                            value={item?.[NameV]}
                                                            options={NameV === 'Yakrut' ? Options.SparshasahatvaSubOptions.Yakrut :
                                                                NameV === 'Pleeha' ? Options.SparshasahatvaSubOptions.Pleeha :
                                                                    NameV === 'Dakshi Vruka' ? Options.SparshasahatvaSubOptions.DakshiVruka :
                                                                        NameV === 'Vaam Vruka' ? Options.SparshasahatvaSubOptions.VaamVruka :
                                                                            NameV === 'Apan Kaksha' ? Options.SparshasahatvaSubOptions.ApanKaksha : []}
                                                            touched={true}
                                                            placeholder={`Enter Your ${NameV}`} />

                                                    )
                                                })}
                                                <InputFromUser
                                                    label="Mala"
                                                    placeholder="Enter Mala"
                                                    type="multiple"
                                                    name="Mala"
                                                    onBlur={() => {
                                                        undefined
                                                    }}
                                                    FormControlClassName={'form-control'}
                                                    FormGroupClassName={"col-xl-3 col-md-8"}
                                                    LabelClassName={"col-form-label"}
                                                    onChange={(v) => doctorRoundFormFill(v, 'Mala')}
                                                    value={values.Mala}
                                                    options={['mala0', 'mala1', 'mala2', 'mala3', 'mala4',]}
                                                    error={errors.Mala}
                                                    touched={touched.Mala}
                                                />
                                                <InputFromUser
                                                    label="Mutra"
                                                    placeholder="Enter Mutra"
                                                    type="multiple"
                                                    name="Mutra"
                                                    onBlur={() => { undefined }}
                                                    FormControlClassName={'form-control'}
                                                    FormGroupClassName={"col-xl-3 col-md-8"}
                                                    LabelClassName={"col-form-label"}
                                                    onChange={(v) => doctorRoundFormFill(v, 'Mutra')}
                                                    value={values.Mutra}
                                                    options={['mutra0', 'mutra1', 'mutra2', 'mutra3', 'mutra4',]}
                                                    error={errors.Mutra}
                                                    touched={touched.Mutra}
                                                />
                                                <InputFromUser
                                                    label="Jivha"
                                                    placeholder="Enter Jivha"
                                                    type="multiple"
                                                    name="jivha"
                                                    onBlur={() => {
                                                        undefined
                                                    }}
                                                    FormControlClassName={'form-control'}
                                                    FormGroupClassName={"col-xl-3 col-md-8"}
                                                    LabelClassName={"col-form-label"}
                                                    onChange={(v) => doctorRoundFormFill(v, 'jivha')}
                                                    value={values.jivha}
                                                    options={Options.Jivha}
                                                    error={errors.jivha}
                                                    touched={touched.jivha}
                                                />
                                                <div className="d-flex justify-content-end mb-4">
                                                    <Button
                                                        type="default"
                                                        className="text-left me-1"
                                                        onClick={() => { SetModel({ date: null, open: false }) }}>
                                                        Cancel</Button>
                                                    <Button
                                                        type="primary"
                                                        className="text-left"
                                                        onClick={() => {
                                                            SetModel({ date: null, open: false })
                                                        }}
                                                    >Submit</Button>
                                                </div>
                                            </div>
                                        </Modal >
                                        <div className="card-box">
                                            <CustomTable
                                                column={column}
                                                bordered={true}
                                                // loader={loader}
                                                dataSource={patientPlanData?.dataSource}
                                                CurrentPageNum={1}
                                                defaultPageSize={20}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <Divider>
                                                            <div className="m-3">
                                                                <h3 className="">∬ Notes ∬</h3>
                                                            </div></Divider>
                                                        {values?.notes?.map((note, index) => (
                                                            <div key={index}>
                                                                <textarea
                                                                    rows={4}
                                                                    cols={5}
                                                                    className="form-control my-2"
                                                                    placeholder="Enter Notes"
                                                                    value={note}
                                                                    onBlur={(e) => undefined}
                                                                    onChange={(e) => {
                                                                        const newNotes = [...values.notes];
                                                                        newNotes[index] = e.target.value;
                                                                        setFieldValue("notes", newNotes, true);
                                                                    }} />
                                                            </div>
                                                        ))}
                                                        <div className="row">
                                                            <Button
                                                                onClick={() => { setFieldValue("notes", [...(values.notes || []), ""], true); }}
                                                                className="col-2 m-3">
                                                                Add Notes
                                                            </Button>
                                                            {values.notes && values.notes.length > 1 && <Button
                                                                onClick={() => {
                                                                    if (values.notes && values.notes.length > 1) {
                                                                        let updatedNotes = [...values.notes];
                                                                        updatedNotes.pop();
                                                                        setFieldValue("notes", updatedNotes, true);
                                                                    }
                                                                }}
                                                                className="col-2 m-3">
                                                                Remove Last Notes
                                                            </Button>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card p-3">
                                            <ExpendableTable
                                                Title={"Medicines"}
                                                emptyDataForCreatingField={medicineInit}
                                                Data={values?.medicines || []}
                                                Columns={[
                                                    { name: 'medicineName', type: 'medicinePicker', label: 'Medicine Name' },
                                                    { name: 'whenToTake', type: 'multiple', label: 'when to Take', options: ["On Hold", ...Options.WhenToTakeMedicine] },
                                                    { name: 'howMuch', type: 'number', label: 'Quantity' },
                                                    { name: 'course', type: 'singleSelect', label: 'Course Duration', options: Options.CourseDuration },
                                                    { name: 'anupan', type: 'singleSelect', label: 'Anupan', options: Options.Anupana },
                                                    { name: 'days', type: 'number', label: 'Days' },
                                                    { name: 'date', type: 'singleSelect', label: 'Date', options: Dates?.map((v, index) => { return v }) || [] },
                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                ]}
                                                onChange={(v) => {
                                                    setFieldValue("medicines", v, true);
                                                }}
                                                onBlur={(v) => setFieldTouched("medicines", v, true)}
                                                errors={errors?.medicines}
                                                touched={touched?.medicines}
                                            />
                                            <div className="d-flex justify-content-end mb-4">
                                                <Button
                                                    type="primary"
                                                    className="text-left"
                                                    onClick={() => { handleSubmit() }}
                                                >
                                                    Save Plan</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }}</Formik>
                </div>
            </>
        </>
    );
};

export default IndorCase;
