import { GET_ALL_MEDICAL_HISTORY, GET_ALL_MEDICAL_HISTORY_SUCCESS } from "../storeTypes";


const INIT_STATE = {
    loader: false,
    medicalHistory: [],
}

function MedicalHistoryReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case GET_ALL_MEDICAL_HISTORY: {
            return {
                ...state,
                medicalHistory: [],
                loader: true
            }
        }

        case GET_ALL_MEDICAL_HISTORY_SUCCESS: {
            return {
                ...state,
                loader: false,
                medicalHistory: action.payload,
            }
        }

        default: {
            return state;
        }

    }


}

export default MedicalHistoryReducer;