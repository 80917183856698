/* eslint-disable no-unused-vars */
import Sidebar from "../../../components/Sidebar";
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import { Button } from "antd";
import { Formik } from "formik";
import { InputFromUser } from "../../../components/Input/input";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetAdminUserDetailsList, getAdminuUerDetails } from "../../../store/Actions/AuthActions";
import { AddLeaveValidationSchema } from "../../../utils/FormValidation";
import { ActionCreateByUrl } from "../../../store/Actions/CommonAction";

const AddLeave = () => {
    const dispatch = useDispatch();
    const { userDetailList } = useSelector(state => state?.auth);
    const { userDetails } = useSelector(state => state?.auth);
    const userId = userDetails?.[0]?._id
    useEffect(() => {
        dispatch(getAdminuUerDetails())
        dispatch(GetAdminUserDetailsList())
    }, [])
    // console.log(userDetailList);

    const calculateDays = (fromDate, toDate) => {
        const start = new Date(fromDate);
        const end = new Date(toDate);
        const timeDifference = end.getTime() - start.getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        return Math.ceil(daysDifference);

    };


    return (
        <>
            <div className="main-wrapper">
                <Header />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="#">Staffs </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Add Leave Request</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* /Page Header */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Formik
                                            initialValues={{
                                                leaveType: "",
                                                fromDate: "",
                                                toDate: "",
                                                adminId: "",
                                                adminName: "",
                                                adminType: "",
                                                reason: "",
                                                noOfDays: 0,
                                                status: "Pending",
                                                isHalfDay: "",
                                                startTime: "",
                                                endTime: "",
                                            }}
                                            validationSchema={AddLeaveValidationSchema}
                                            onSubmit={(values, { setErrors, setSubmitting }) => {
                                                const AdminInfo = userDetailList?.[0]?.find(v => v?.name === values?.adminName)
                                                // if (values.noOfDays > 21) {
                                                //     setErrors({ noOfDays: "Maximum allowed days exceeded (21 days)." });
                                                //     setSubmitting(false);
                                                //     return;
                                                // }

                                                dispatch(ActionCreateByUrl({
                                                    Query: 'admin/leaves', obj: {
                                                        ...values,
                                                        isHalfDay: values?.isHalfDay === "Half day" ? true : false,
                                                        adminId: AdminInfo?.id,
                                                        adminName: AdminInfo?.name,
                                                        adminType: AdminInfo?.type,
                                                        createdBy: userId
                                                    }
                                                }, (Res) => {
                                                    setSubmitting(false);
                                                    if (Res?.statusCode === 200) {
                                                        window.location.href = `/leaves`;
                                                    } else if (Res?.statusCode === 400) {
                                                        setErrors(Res?.data?.errors);
                                                    } else {
                                                        // window.location.reload();
                                                    }
                                                }))
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                setValues,
                                                isSubmitting
                                            }) => {

                                                const handleDateChange = (fieldName, dateValue) => {
                                                    const updatedValues = {
                                                        ...values,
                                                        [fieldName]: dateValue,
                                                    };

                                                    if (fieldName === 'fromDate' || fieldName === 'toDate') {
                                                        updatedValues.noOfDays = calculateDays(updatedValues.fromDate, updatedValues.toDate);
                                                    }

                                                    setValues(updatedValues);
                                                };

                                                // console.log("values", values);
                                                return (
                                                    <div>
                                                        <div className="row">
                                                            <InputFromUser
                                                                label="Leave Type"
                                                                type="singleSelect"
                                                                name="leaveType"
                                                                onChange={(value) => {
                                                                    handleChange({
                                                                        target: {
                                                                            name: 'leaveType',
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                onBlur={(value) => {
                                                                    handleBlur({
                                                                        target: {
                                                                            name: 'leaveType',
                                                                            value: true,
                                                                        },
                                                                    });
                                                                }}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values?.leaveType}
                                                                error={errors?.leaveType}
                                                                options={['Casual Leave', "Sick Leave", "Leave Without Pay"]}
                                                                touched={touched.leaveType}
                                                                placeholder={'Leave Type'}

                                                            />

                                                            <InputFromUser
                                                                label="Admin type"
                                                                type="singleSelect"
                                                                name="adminId"
                                                                onChange={(value) => {
                                                                    handleChange({
                                                                        target: {
                                                                            name: 'adminName',
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                onBlur={(value) => {
                                                                    handleBlur({
                                                                        target: {
                                                                            name: 'adminName',
                                                                            value: true,
                                                                        },
                                                                    });
                                                                }}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values.adminName}
                                                                error={errors.adminName}
                                                                touched={touched.adminName}
                                                                options={userDetailList?.[0]?.map(v => v?.name) || []}
                                                                placeholder={'Admin ID'}
                                                            />
                                                            <InputFromUser
                                                                label="from"
                                                                type="date"
                                                                name="fromDate"
                                                                onChange={(value) => {
                                                                    // console.log(value);
                                                                    handleDateChange('fromDate', value)
                                                                }}
                                                                onBlur={(value) => {
                                                                    handleBlur({
                                                                        target: {
                                                                            name: 'fromDate',
                                                                            value: true,
                                                                        },
                                                                    });
                                                                }}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values.fromDate}
                                                                error={errors.fromDate}
                                                                touched={touched.fromDate}
                                                                placeholder={'Select From Date '}
                                                            />

                                                            <InputFromUser
                                                                label="to"
                                                                type="date"
                                                                name="toDate"
                                                                onChange={(value) => handleDateChange('toDate', value)}
                                                                onBlur={(value) => {
                                                                    handleBlur({
                                                                        target: {
                                                                            name: 'toDate',
                                                                            value: true,
                                                                        },
                                                                    });
                                                                }}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values.toDate}
                                                                error={errors.toDate}
                                                                touched={touched.toDate}
                                                                placeholder={'Select To Date '}
                                                            />
                                                            <InputFromUser
                                                                label="No Of Days"
                                                                type="number"
                                                                name="noOfDays"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values.noOfDays}
                                                                error={errors.noOfDays}
                                                                touched={touched.noOfDays}
                                                                placeholder={'Enter No Of Days'}
                                                                disabled
                                                            />

                                                            <InputFromUser
                                                                label="Select Day"
                                                                type="radio"
                                                                name="isHalfDay"
                                                                onChange={(value) => {
                                                                    handleChange({
                                                                        target: {
                                                                            name: 'isHalfDay',
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                onBlur={(value) => {
                                                                    handleBlur({
                                                                        target: {
                                                                            name: 'isHalfDay',
                                                                            value: true,
                                                                        },
                                                                    });
                                                                }}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values.isHalfDay}
                                                                selectedValue={values.isHalfDay}
                                                                error={errors.isHalfDay}
                                                                touched={touched.isHalfDay}
                                                                options={["full day", "Half day", "Long Holiday"].map(option => ({
                                                                    label: option,
                                                                    value: option,
                                                                }))}
                                                                placeholder={'Status'}
                                                            />
                                                            <InputFromUser
                                                                label="Start Time"
                                                                type="TimePicker"
                                                                name="startTime"
                                                                onChange={(value) => {
                                                                    handleChange({
                                                                        target: {
                                                                            name: 'startTime',
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                onBlur={(value) => {
                                                                    handleBlur({
                                                                        target: {
                                                                            name: 'startTime',
                                                                            value: true,
                                                                        },
                                                                    });
                                                                }}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values.startTime}
                                                                error={errors.startTime}
                                                                touched={touched.startTime}
                                                                placeholder={'Select Time '}
                                                            />

                                                            <InputFromUser
                                                                label="End Time"
                                                                type="TimePicker"
                                                                name="endTime"
                                                                onChange={(value) => {
                                                                    handleChange({
                                                                        target: {
                                                                            name: 'endTime',
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                onBlur={(value) => {
                                                                    handleBlur({
                                                                        target: {
                                                                            name: 'endTime',
                                                                            value: true,
                                                                        },
                                                                    });
                                                                }}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values.endTime}
                                                                error={errors.endTime}
                                                                touched={touched.endTime}
                                                                placeholder={'Select Time '}
                                                            />
                                                            <InputFromUser
                                                                label="Reason for Leave"
                                                                type="text"
                                                                name="reason"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values.reason}
                                                                error={errors.reason}
                                                                touched={touched.reason}
                                                                placeholder={'Reason for Leave'}
                                                            />
                                                            {/* <InputFromUser
                                                                label="status"
                                                                type="singleSelect"
                                                                name="status"
                                                                onChange={(value) => {
                                                                    handleChange({
                                                                        target: {
                                                                            name: 'status',
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                onBlur={(value) => {
                                                                    handleBlur({
                                                                        target: {
                                                                            name: 'status',
                                                                            value: true,
                                                                        },
                                                                    });
                                                                }}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values.status}
                                                                error={errors.status}
                                                                touched={touched.status}
                                                                options={["Approved", "Cancelled", "Pending"]}
                                                                placeholder={'Status'}
                                                            /> */}
                                                            <div className="col-12 mt-5">
                                                                <div className="text-end">
                                                                    <Button
                                                                        onClick={() => {
                                                                            handleSubmit()
                                                                        }}
                                                                        type="primary"
                                                                        disabled={isSubmitting}
                                                                        className="me-2"
                                                                    >
                                                                        Submit
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default AddLeave
