/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import { DoctorScheduleColum } from "../../../utils/TabelData";
import Calender from "../../../components/Calander/Calander";
import { PatientProfileCard } from "../../../components/Card/Cards";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { CustomTable } from "../../../components/Table";
import { CommonHeadingTxt } from "../../../components/Text/Text";
import TimelineCard from "../../../components/TimeLine";
import { StaffProfileIcon } from "../../../components/imagepath";
import { GetStaffProfileAction } from "../../../store/Actions/StaffAction";
import Loader from "../../../components/loader";
import { Button } from "antd";
import moment from 'moment';
import CalanderModal from "../../../components/Modal/CalanderModal";
import { DOCS_URL } from "../../../utils/globals";
// import AddEducationModal from "../../../components/Modal/AddEducationModal";

const StaffProfile = () => {
    const dispatch = useDispatch()
    const { id } = useParams();
    const [modal, SetModal] = useState(false);
    const { StaffProfile, loader } = useSelector(state => state?.Staff);
    useEffect(() => {
        if (id) {
            dispatch(GetStaffProfileAction({ Query: id }))
        }
    }, [])
    const educationColumn = [
        {
            title: "Degree",
            dataIndex: "education",

        },
    ]
    const experienceColumn = [
        {
            title: "Experience",
            dataIndex: "expierence",
            render: (text,) => (
                <span>{StaffProfile?.[0]?.expierence} Years</span>
            ),
        },
    ]
    const positionColumn = [
        {
            title: "Position",
            dataIndex: "position",

        },
    ]
    const IsTherapist = StaffProfile?.[0]?.type === "Therapist"
    const IsDoctor = StaffProfile?.[0]?.type === "Doctor"
    return (typeof StaffProfile?.[0] === 'undefined') ?
        <Loader IsModal={true} />
        : (
            <>
                <Loader IsModal={loader} />
                <Header />
                <Sidebar />
                <>
                    <div className="page-wrapper">
                        <div className="content">
                            <div className="row">
                                <div className="col-sm-7 col-6">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/staff-list?page=1">Staff</Link></li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/staff-list?page=1">Staff List</Link></li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Staff Profile</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-box profile-header">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="profile-view">
                                            <div className="profile-img-wrap">
                                                <div className="profile-img">
                                                    {/* <Link to="#"> */}
                                                    <img className="avatar"
                                                        style={{ width: "100%", height: "100%" }}
                                                        src={DOCS_URL + StaffProfile?.[0]?.profileImage?.[0] || StaffProfileIcon}
                                                        alt={StaffProfile?.[0]?.name} />
                                                    {/* </Link> */}
                                                </div>
                                            </div>
                                            <div className="profile-basic">
                                                <div className="row">
                                                    <div className="col-md-5 col-xxl-3">
                                                        <div className="profile-info-left">
                                                            <h3 className="user-name m-t-0 mb-0">{StaffProfile?.[0]?.name || '---'}</h3>
                                                            <small className="text-muted">{'---'}</small>
                                                            {/* <div className="staff-id mt-md-5 mt-3">Employee ID : {StaffProfile?.[0]?.phoneNo}</div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="personal-info">
                                                            <li>
                                                                <span className="title">Phone:</span>
                                                                <span className="text"><Link to>{StaffProfile?.[0]?.phoneNo || '---'}</Link></span>
                                                            </li>
                                                            <li>
                                                                <span className="title">Email:</span>
                                                                <span className="text"><Link to>{StaffProfile?.[0]?.email || '---'}</Link></span>
                                                            </li>
                                                            <li>
                                                                <span className="title">Birthday:</span>
                                                                <span className="text">{(StaffProfile?.[0]?.dateOfBirth && moment(StaffProfile?.[0]?.dateOfBirth).format('DD/MM/YYYY') || '---')}</span>
                                                            </li>
                                                            {/* <li>
                                                            <span className="title">Address:</span>
                                                            <span className="text">{StaffProfile?.[0]?.address || ''}</span>
                                                        </li> */}
                                                            <li>
                                                                <span className="title">Gender:</span>
                                                                <span className="text">{StaffProfile?.[0]?.gender || '---'}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-3 align-items-center mt-4">
                                                        <Link to={`/staff-list/staff-profile/edit-profile/${id}`}
                                                            className="btn btn-primary btn-rounded">Edit Profile
                                                            <i className="ms-2 fa fa-plus" />
                                                            {/* <PrimaryNoteBorderBtn style={{ background: "#023E1A", color: "#FFF", borderRadius: 30, fontSize: 15 }} Text="Edit Profile" Icons={
                                                            <i data-feather="plus-circle" className="pe-1">
                                                                <FeatherIcon icon="plus-circle" />
                                                            </i>} /> */}
                                                        </Link>
                                                    </div>
                                                    <div className="d-flex">
                                                        <PatientProfileCard />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row my-md-4 my-5">
                                {IsDoctor && (
                                    <div className="col-12 col-md-12  col-xl-7">
                                        <div className="card h-100 mb-3">
                                            <div className="row align-items-center py-4">
                                                <div className="col-md-9">
                                                    <CommonHeadingTxt Text="Education" />
                                                </div>
                                                <div className="col-md-3">
                                                    <Link
                                                        to={`/staff-list/staff-profile/add-education/${id}?page=1`}
                                                        className="btn btn-primary btn-rounded">
                                                        Add Education
                                                        <i className="ms-2 fa fa-plus" />

                                                    </Link>
                                                </div>
                                            </div>

                                            <CustomTable
                                                column={educationColumn}
                                                dataSource={StaffProfile}
                                                CurrentPageNum={1} />

                                            <div className="mt-3">
                                                <CommonHeadingTxt Text="Experience of Doctor" />
                                                <CustomTable
                                                    column={experienceColumn}
                                                    dataSource={StaffProfile}
                                                    CurrentPageNum={1}

                                                />
                                            </div>

                                            <div className="mt-3">
                                                <CommonHeadingTxt Text="Position at Prakruti" />
                                                <CustomTable
                                                    column={positionColumn}
                                                    dataSource={StaffProfile}
                                                    CurrentPageNum={1}

                                                />
                                            </div>

                                        </div>
                                    </div>
                                )}
                                <div className="col-12 col-lg-12 col-xl-5 d-flex">
                                    <TimelineCard />
                                </div>
                            </div>

                            <div className="row my-md-4 my-5">
                                {!(IsDoctor) && (
                                    <div className={`col-12 col-md-12  ${IsTherapist ? 'col-xl-12' : 'col-xl-8'}`}>
                                        <Calender />
                                    </div>)}
                                {!(IsTherapist) && (
                                    <div className="col-12 col-md-12  col-xl-7">
                                        <div className="card mb-3">
                                            <CommonHeadingTxt Text="Schedule"
                                                Children={
                                                    <Button
                                                        type="link"
                                                        onClick={() => SetModal(true)}
                                                        className="patient-views float-end"
                                                    >
                                                        Enter Your Schedule
                                                    </Button>
                                                }
                                            />
                                            <CustomTable
                                                column={DoctorScheduleColum}
                                                dataSource={StaffProfile?.[0]?.schedule}
                                                CurrentPageNum={1} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <CalanderModal IsOpen={modal} Isclose={() => SetModal(false)} staffId={id} value={StaffProfile?.[0]?.schedule} />
                            {/* <AddEducationModal IsOpen={modal} Isclose={() => SetModal(false)} staffId={id} value={StaffProfile?.[0]?.schedule} /> */}
                        </div>
                    </div>

                </>
            </>

        )
};

export default StaffProfile;
