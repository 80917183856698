import Sidebar from "../../../components/Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { InputFromUser } from "../../../components/Input/input";
import Header from "../../../components/Header";
import { RadioButton, SingleMultiSelectDropDown } from "../../../components/Input/InputText";
import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { GetAllTreatment } from "../../../store/Actions/ServiceAction";

const initialFormValues = {
    date: '',
    startTime: '',
    phoneNo: '',
    email: '',
    customerName: '',
    gender: '',
    treatmentName: [],
}

const Add_TreatmentSlot = () => {

    const [formValues, setFormValues] = useState([initialFormValues]);
    const [selectedTreatments, setSelectedSTreatments] = useState([[]]);
    // const [treatmentLists, setTreatmentLists] = useState([]);
    const handleTreatmentSelection = (guestIndex, selected) => {
        const updatedSelectedTreatment = [...selectedTreatments];
        updatedSelectedTreatment[guestIndex] = selected;
        setSelectedSTreatments(updatedSelectedTreatment);
    };

    const addGuestForm = () => {
        setFormValues([...formValues, initialFormValues]);
        setSelectedSTreatments([...selectedTreatments, []]);
    };

    const removeGuestForm = (indexToRemove) => {
        const updatedForms = formValues.filter((_, index) => index !== indexToRemove);
        setFormValues(updatedForms);

        const updatedSelectedTreatment = selectedTreatments.filter((_, index) => index !== indexToRemove);
        setSelectedSTreatments(updatedSelectedTreatment);
    };

    const calculateTotalPrice = () => {
        let total = 0;

        formValues.forEach((guestValues, index) => {
            let totalPricePerGuest = 0;

            selectedTreatments[index]?.forEach(selectedTreatment => {
                treatMentList.forEach(treatmentItem => {
                    const foundTreatment = treatmentItem.docs.find(item => item.name === selectedTreatment);
                    if (foundTreatment) {
                        const perDayPrice = foundTreatment.perDayPrice;
                        totalPricePerGuest += Number(perDayPrice);
                    }
                });
            });

            total += totalPricePerGuest;
        });

        return total;
    };

    const { treatMentList } = useSelector(state => state?.Service);

    const dispatch = useDispatch();
    const params = queryString.parse(location.search);

    useEffect(() => {
        dispatch(GetAllTreatment({ Query: `?page=${params?.page || 1}` }));
    }, []);

    useEffect(() => {
        if (treatMentList && treatMentList.length > 0) {
            // const lists = treatMentList.map(treatment => treatment.docs);
            // setTreatmentLists(lists);
        }
    }, [treatMentList]);

    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/treatment-slot">Treatment Booking</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Treatment Slot Booking</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            {formValues.map((values, index) => (
                                                <React.Fragment key={index}>
                                                    {index !== 0 && <Divider style={{ fontWeight: "600" }}>Other Guest Details</Divider>}
                                                    <Formik
                                                        initialValues={{
                                                            date: '',
                                                            startTime: '',
                                                            phoneNo: '',
                                                            email: '',
                                                            customerName: '',
                                                            gender: '',
                                                            treatmentName: [],
                                                        }}
                                                        // validationSchema={ServiceSlotValidationSchema}
                                                        onSubmit={(values, { setSubmitting }) => {
                                                            setSubmitting(false);
                                                        }}
                                                    // onSubmit={(values, { setSubmitting, setErrors }) => {
                                                    //     dispatch(ActionUpdateServiceSlot({
                                                    //         Query: "/" + idValue,
                                                    //         obj: {
                                                    //             "customerName": values.customerName,
                                                    //             "treatmentName": values.treatmentName,
                                                    //             // "date": values.date,
                                                    //             "startTime": values.startTime,
                                                    //             "parentCustomerName": values.parentCustomerName,
                                                    //         }
                                                    //     }, (Res) => {
                                                    //         setSubmitting(false);
                                                    //         if (Res?.statusCode === 200) {
                                                    //             window.location.href = `/treatment-slot?page=${pageValue}`;
                                                    //         } else if (Res?.statusCode === 400) {
                                                    //             setErrors(Res?.data?.errors);
                                                    //         } else {
                                                    //             window.location.reload();
                                                    //         }
                                                    //     }))
                                                    // }}
                                                    >
                                                        {({
                                                            values,
                                                            errors,
                                                            touched,
                                                            handleChange,
                                                            handleBlur,
                                                            handleSubmit,
                                                            setFieldValue,
                                                            isSubmitting,
                                                        }) => {
                                                            return (
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-heading">
                                                                                <h4>Add Treatment Slots Manually</h4>
                                                                            </div>
                                                                        </div>

                                                                        <InputFromUser
                                                                            label="Date"
                                                                            type="date"
                                                                            name="date"
                                                                            onChange={(v) => {
                                                                                setFieldValue("date", v, true)
                                                                            }} onBlur={handleBlur}
                                                                            FormControlClassName={'form-control'}
                                                                            FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                            LabelClassName={"col-form-label"}
                                                                            value={values?.date}
                                                                            error={errors.date}
                                                                            touched={touched.date}
                                                                            placeholder={'Enter date'}
                                                                        />

                                                                        <InputFromUser
                                                                            placeholder={"Arrival Time"}
                                                                            type="text"
                                                                            name="startTime"
                                                                            onChange={handleChange}
                                                                            FormGroupClassName={"col-xl-6 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            onBlur={handleBlur}
                                                                            value={values?.startTime}
                                                                            label="Arrival Time"
                                                                            error={errors?.startTime}
                                                                            touched={touched.startTime}
                                                                        />

                                                                        <InputFromUser
                                                                            placeholder={"Phone No."}
                                                                            type="text"
                                                                            name="phoneNo"
                                                                            onChange={handleChange}
                                                                            FormGroupClassName={"col-xl-6 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            onBlur={handleBlur}
                                                                            value={values?.phoneNo}
                                                                            label="Phone No."
                                                                            error={errors?.phoneNo}
                                                                            touched={touched.phoneNo}
                                                                        />

                                                                        <InputFromUser
                                                                            placeholder={"Email"}
                                                                            type="email"
                                                                            name="email"
                                                                            onChange={handleChange}
                                                                            FormGroupClassName={"col-xl-6 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            onBlur={handleBlur}
                                                                            value={values?.email}
                                                                            label="Email"
                                                                            error={errors?.email}
                                                                            touched={touched.email}
                                                                        />

                                                                        <InputFromUser
                                                                            placeholder={"Customer Name"}
                                                                            type="text"
                                                                            name="customerName"
                                                                            onChange={handleChange}
                                                                            FormGroupClassName={"col-xl-6 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            onBlur={handleBlur}
                                                                            value={values?.customerName}
                                                                            label="Customer Name"
                                                                            error={errors?.customerName}
                                                                            touched={touched.customerName}
                                                                        />

                                                                        < RadioButton label={"Gender"} options={[
                                                                            { label: 'Male', value: 'Male' },
                                                                            { label: 'Female', value: 'Female' },
                                                                            { label: 'Others', value: 'Others' },
                                                                        ]}
                                                                            onBlur={() => handleBlur({
                                                                                target: {
                                                                                    name: 'gender',
                                                                                    value: true,
                                                                                },
                                                                            })} onChange={(v) => handleChange({
                                                                                target: {
                                                                                    name: 'gender',
                                                                                    value: v,
                                                                                },
                                                                            })}
                                                                            error={errors.gender}
                                                                            selectedValue={values?.gender}
                                                                            touched={touched.gender}

                                                                        />

                                                                        <SingleMultiSelectDropDown
                                                                            label="Treatments"
                                                                            type="treatmentName"
                                                                            Showlabel={true}
                                                                            mode={'multiple'}
                                                                            name="treatmentName"
                                                                            onChange={(v) => {
                                                                                setFieldValue("treatmentName", v, true);
                                                                                handleTreatmentSelection(index, v);
                                                                            }}
                                                                            FormControlClassName={'form-control'}
                                                                            FormGroupClassName={"col-xl-6 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            value={values?.treatmentName}
                                                                            error={errors?.treatmentName}
                                                                            touched={touched?.treatmentName}
                                                                            loading={false}
                                                                            options={
                                                                                treatMentList && treatMentList.length > 0 && treatMentList[0]?.docs
                                                                                    ? treatMentList[0]?.docs?.map(item => item?.name)
                                                                                    : []
                                                                            }
                                                                            placeholder={'Enter Treatments'}
                                                                        />

                                                                        <div className="col-12 mt-4">
                                                                            <div className="doctor-submit text-end mt-3">
                                                                                {index === 0 ? (
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={addGuestForm}
                                                                                        className="btn btn-primary submit-form me-2"
                                                                                    >
                                                                                        Add More Guest
                                                                                    </button>
                                                                                ) : (
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => removeGuestForm(index)}
                                                                                        className="btn btn-danger submit-form me-2"
                                                                                    >
                                                                                        Remove Guest
                                                                                    </button>
                                                                                )}
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary submit-form"
                                                                                    disabled={isSubmitting}
                                                                                    onClick={() => handleSubmit()}
                                                                                >
                                                                                    Submit
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }}
                                                    </Formik>
                                                </React.Fragment>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <h2 className="mb-3">Total Summary</h2>
                                            <Divider />
                                            {formValues.map((values, index) => (
                                                <>
                                                    <div key={index}>
                                                        <h3>Guest {index + 1}</h3>
                                                        <div className="d-flex justify-content-between py-3">
                                                            <div>
                                                                <h4>Selected Treatments</h4>
                                                            </div>
                                                            <div>
                                                                <h4>Price</h4>
                                                            </div>
                                                        </div>
                                                        {selectedTreatments[index]?.map((selectedTreatment, treatmentIndex) => {
                                                            let perDayPrice = 'Price not available';

                                                            treatMentList?.forEach(treatmentItem => {
                                                                const foundTreatment = treatmentItem.docs.find(item => item.name === selectedTreatment);
                                                                if (foundTreatment) {
                                                                    perDayPrice = foundTreatment.perDayPrice;
                                                                }
                                                            });

                                                            return (
                                                                <div key={treatmentIndex} className="d-flex justify-content-between">
                                                                    <div>
                                                                        <p>{selectedTreatment}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p>{perDayPrice}</p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}

                                                        <Divider />
                                                    </div>
                                                </>
                                            ))}
                                            <div className="d-flex justify-content-between py-3">
                                                <div>
                                                    <h3>Total Price: </h3>
                                                </div>
                                                <div>
                                                    <h3>₹{calculateTotalPrice()}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div >
                </div >
            </>
        </>
    );
};

export default Add_TreatmentSlot;