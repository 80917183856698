import { call, put, takeEvery } from 'redux-saga/effects';
import { CREATE_ROLES, GET_ROLE_LIST, GET_ROLE_LIST_PAGINATION } from '../storeTypes';
import { CreateRoleApi, GetRoleListApi } from '../../utils/api';
import { GetRoleListSuccess, GetRoleListWithPaginationSuccess } from '../Actions/RolesAction';

const File = 'RoleSaga '

function* GetRoleListSaga(action) {
    try {
        const response = yield call(GetRoleListApi, action.payload);
        if (response?.status === 200) {
            yield put(GetRoleListSuccess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'GetRoleListSaga Error :- ', error);
    }
}
function* GetRoleListWithPaginationSaga(action) {
    try {
        const response = yield call(GetRoleListApi, action.payload);
        if (response?.status === 200) {
            yield put(GetRoleListWithPaginationSuccess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'GetRoleListWithPaginationSaga Error :- ', error);
    }
}
function* CreateRoleSaga(action) {
    try {
        const response = yield call(CreateRoleApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'CreateRoleSaga Error :- ', error);
    }
}

export function* RoleSaga() {
    [
        yield takeEvery(GET_ROLE_LIST, GetRoleListSaga),
        yield takeEvery(GET_ROLE_LIST_PAGINATION, GetRoleListWithPaginationSaga),
        yield takeEvery(CREATE_ROLES, CreateRoleSaga),
    ];
}
