/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */

import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { InputFromUser } from './input';
import { isNullOrUndef } from '../../utils/Funcations';


const TableColumnSearch1 = ({ dataIndex, onChange, queryValue, type }) => {
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, dataIndex) => {
        onChange({ ...queryValue, [dataIndex]: selectedKeys?.[0] })
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        onChange({ ...queryValue, [dataIndex]: '' })
    }
    return {
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
            filters
        }) => (
            <div
                style={{
                    padding: type === 'Dropdown' ? 6 : 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                {type === 'Dropdown' ?
                    <InputFromUser
                        label=""
                        type="singleSelect"
                        FormGroupClassName={"m-0 p-0"}
                        LabelClassName={"m-0 p-0"}
                        name="selectedLang"
                        onChange={(value) => { setSelectedKeys(value ? [value] : []) }}
                        FormControlClassName={'form-control'}
                        onBlur={undefined}
                        mode={''}
                        ComponentStyle={{ height: '5%', width: '100%', margin: 0 }}
                        error={undefined}
                        value={selectedKeys[0] || !isNullOrUndef(queryValue?.[dataIndex]) && queryValue?.[dataIndex] || []}
                        options={filters || []}
                        touched={undefined}
                        placeholder={`Select ${dataIndex}`} />
                    :
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0] || queryValue?.[dataIndex]}
                        onChange={(e) =>
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        onPressEnter={() => handleSearch(selectedKeys, dataIndex)}
                        style={{
                            marginBottom: 8,
                            display: "block",
                        }}
                    />}
                <Space>
                    <Button
                        htmlType='submit'
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        htmlType='button'
                        onClick={() => {
                            clearFilters && handleReset(clearFilters);
                            confirm({
                                closeDropdown: false,
                            });
                            onChange({ ...queryValue, [dataIndex]: '' })
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        htmlType='button'
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "blue" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        filtered: !isNullOrUndef(queryValue?.[dataIndex])
    };
};
TableColumnSearch1.propTypes = {
    onChange: PropTypes.func.isRequired,
    dataIndex: PropTypes.string,
    queryValue: PropTypes.any,
    type: PropTypes.string,
    filtered: PropTypes.bool,
};

export default TableColumnSearch1;
