import { notification } from "antd";
import { useEffect, useState } from "react";
import { DOCS_URL } from "./globals";
import { icon1, pdf } from '../components/imagepath';




export function trimText(text, maxLength) {
    if (text?.length <= maxLength) {
        return text;
    }
    return text?.slice(0, maxLength - 3) + "...";
}


export const ResponsiveComponent = () => {
    const [screenSize, setScreenSize] = useState('');
    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 480) {
                setScreenSize('phone');
            }
            else if (width > 480 && width <= 768) {
                setScreenSize('tablet');
            }
            else if (width > 1150 && width <= 1440) {
                setScreenSize('laptop_l');
            }
            else if (width > 768 && width <= 1150) {
                setScreenSize('desktop_half');
            }
            else if (width > 1150 && width <= 2560) {
                setScreenSize('4k_display');
            } else {
                setScreenSize('desktop');
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return screenSize
};


export function printDiv(divId) {
    var printContents = document.getElementById(divId).innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
}

export const handleApiResponse = (status, response, Callback, isLogout = false, ShowToast = true) => {
    if (status) {
        switch (status) {
            case 200:
            case 201:
                if (ShowToast) {
                    notification.success({
                        message: response.data?.title || response?.message || 'done',
                        description: '',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                }
                Callback(response);
                console.log(("%c" + '✅' + (response.data?.title || response?.message || '200/201')),
                    "font-size:10px; background:green; color:#581845; padding:10px; border-radius: 15px; ");
                break;
            case 204:
                if (ShowToast) {
                    notification.success({
                        message: response.message || '204',
                        description: '',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                }
                Callback(response);
                console.log(("%c" + '✅' + (response.data?.title || response?.message || '204')),
                    "font-size:10px; background:green; color:#581845; padding:10px; border-radius: 15px; ");

                break;
            case 400:
            case 404:
            case 500:


                if (ShowToast) {
                    notification.error({
                        message: response.message || '500',
                        description: '',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                }
                Callback(response);
                console.log(("%c" + '🚨' + (response.data?.title || response?.message || '400/404/500')),
                    "font-size:10px; background:red; color:black; padding:10px; border-radius: 15px; font-weight:200;");

                break;
            case 401:

                if (isLogout) {
                    localStorage.removeItem('token');
                    window.location.reload();
                } else {
                    Callback(response);
                }
                console.log(("%c" + '🚫', response.data?.title || response?.message || '401'),
                    "font-size:10px; background:red; color:black; padding:10px; border-radius: 15px; font-weight:200;");
                break;
            case 429:
                notification.error({
                    message: 'Too many requests, please try again later.',
                    description: '',
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });

                break;
            default:
                console.log('❗ 🚧', response.data?.title || response?.message || 'Unhandled response status:', response.status);
                break;
        }
    } else {
        console.log('❗ 🚧', 'Invalid or missing status in response:', response);
    }
};

export const generateQueryString = (...keyValuePairs) => {
    const queryParams = {};
    keyValuePairs.forEach(([key, value]) => {
        if (key !== '' && key !== null && key !== undefined && key !== false && value !== '' && value !== null && value !== undefined && value !== false) {
            queryParams[key] = value;
        }
    });
    const queryString = Object.keys(queryParams)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join('&');
    return queryString ? "?" + queryString : '';
};
// USE LIKE THIS
// const queryString1 = generateQueryString(['page', '1'], ['value', 'value'], ['appconfig', '']);


export function calculateAge(birthdate) {
    // Step 1: Convert the birthdate string to a Date object
    const birthDateObject = new Date(birthdate);
    // Step 2: Get the current date
    const currentDate = new Date();
    // Step 3: Calculate the difference between the current date and the birthdate
    const timeDifference = currentDate - birthDateObject;
    // Step 4: Extract the years from the difference
    const ageInMilliseconds = timeDifference;
    const ageInSeconds = ageInMilliseconds / 1000;
    const ageInMinutes = ageInSeconds / 60;
    const ageInHours = ageInMinutes / 60;
    const ageInDays = ageInHours / 24;
    const ageInYears = ageInDays / 365.25;
    // Round down to get the whole number of years
    const age = Math.floor(ageInYears);
    return age;
}
export function colorLog(message, color = "black") {



    console.log("%c" + message, "color:" + color);
}

export const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


export function QueryParams() {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    return {
        page: searchParams.get("page") || null,
        customerName: searchParams.get("customerName") || null,
        doctorName: searchParams.get("doctorName") || null,
        phoneNo: searchParams.get("phoneNo") || null,
        registrationNo: searchParams.get("registrationNo") || null,
        consultationType: searchParams.get("consultationType") || null,
        key: searchParams.get("key") || null,
    }
}
// export function QueryGenerator(Key) {

//     const dataIndex = Key?.dataIndex
//     const queryString1 = generateQueryString(
//         ['page', dataIndex === 'page' ? Key?.Key : parseInt(page) || 1],
//         ['phoneNo', dataIndex === 'phoneNo' ? Key?.Key : phoneNo || ''],
//         ['customerName', dataIndex === 'customerName' ? Key?.Key : customerName || ''],
//         ['doctorName', dataIndex === 'doctorName' ? Key?.Key : doctorName || ''],
//         ['consultationType', dataIndex === 'consultationType' ? Key?.Key : consultationType || ''],
//         ['registrationNo', dataIndex === 'registrationNo' ? Key?.Key : registrationNo || ''],
//         ['Key', dataIndex === 'Key' ? Key?.Key : key || '']
//     );
//     return queryString1
// }

export function isNullOrUndef(value) {
    return value === null || value === undefined || value === ''
}

export function ShowFileInTab(url, filename) {
    const link = document.createElement('a');
    link.href = DOCS_URL + url;
    link.target = '_blank';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


export function downloadFile(url) {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const filename = url.substring(url.lastIndexOf("/") + 1);
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(error => {
            console.error('Error downloading file:', error);
        });
}



export const FilesIconByType = (fileType) => {
    return <img src={fileType === 'pdf' ?
        icon1 : fileType === 'png' ?
            icon1 : fileType === 'jpeg' ?
                icon1 : fileType === 'pdf' ?
                    pdf : icon1} alt="#" />

}

export function calculatePercentage(totalAmount, paidAmount) {
    totalAmount = parseFloat(totalAmount);
    paidAmount = parseFloat(paidAmount);
    if (isNullOrUndef(totalAmount) || isNullOrUndef(paidAmount)) {
        return "Invalid input. Please enter valid numbers.";
    }
    let percentage = (paidAmount / totalAmount) * 100;
    let truncatedPercentage = Math.trunc(percentage * 100) / 100; // Truncate to 2 decimal places

    return Number.isInteger(truncatedPercentage) ? truncatedPercentage : truncatedPercentage.toFixed(2);

}
export function calculatePercentageOfNumber(number, percentage) {
    number = parseFloat(number);
    if (isNullOrUndef(number)) {
        return "Invalid input. Please enter a valid number.";
    }
    let result = number - (number * (percentage / 100));
    return Math.round(result); // Round to the nearest integer
}

export function getStatus(Status) {
    if (!Status) {
        return "error";
    }

    switch (Status) {
        case 'Failed':
            return "error";
        case 'Initialize':
            return "processing";
        case 'Pending':
            return "warning";
        default:
            return "success";
    }
}