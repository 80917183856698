import {
    GET_ALL_SERVICE_SLOT,
    GET_ALL_SERVICE_SLOT_SUCCESS,
    GET_SERVICE_SLOT_BY_ID,
    GET_SERVICE_SLOT_BY_ID_SUCCESS,
    UPDATE_SERVICE_SLOT,
} from "../storeTypes";

const INIT_STATE = {
    loader: false,
    ServiceSlot: [],
};

function serviceSlotReducer(state = INIT_STATE, action) {

    switch (action.type) {

        case GET_ALL_SERVICE_SLOT: {
            return {
                ...state,
                ServiceSlot: [],
                loader: true,
            };
        }
        case GET_ALL_SERVICE_SLOT_SUCCESS: {
            return {
                ...state,
                loader: false,
                ServiceSlot: action?.payload
            }
        }
        case UPDATE_SERVICE_SLOT: {
            return {
                ...state,
                loader: true,
            }
        }
        case GET_SERVICE_SLOT_BY_ID: {
            return {
                ...state,
                ServiceSlot: [],
                loader: true,
            };
        }
        case GET_SERVICE_SLOT_BY_ID_SUCCESS: {
            return {
                ...state,
                loader: false,
                ServiceSlot: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}

export default serviceSlotReducer;
