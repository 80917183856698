import {
    CREATE_DETOX_RETREAT,
    CREATE_DETOX_RETREAT_SUCCESS,
    CREATE_DO_AND_DONTS,
    CREATE_EXERCISE,
    CREATE_MEDICINE, CREATE_MEMBERSHIP, CREATE_ROOM,
    CREATE_VIRECHAN,
    DELETE_DO_AND_DONTS,
    DELETE_EXERCISE,
    DELETE_MEDICINE, DELETE_MEMBERSHIP, DELETE_ROOM,
    DELETE_VIRECHAN,
    DO_AND_DONTS_LIST_SUCCESS,
    EXERCISE_LIST_SUCCESS,
    GET_ALL_DETOX_RETREAT,
    GET_ALL_DETOX_RETREAT_SUCCESS, GET_ALL_MEMBERSHIP_LIST,
    GET_ALL_MEMBERSHIP_LIST_SUCCESS,
    GET_ALL_MEMBER_VERIFY_LIST,
    GET_ALL_MEMBER_VERIFY_LIST_SUCCESS,
    GET_DO_AND_DONTS_LIST,
    GET_EXERCISE_LIST,
    GET_MEDICINE_BY_ID,
    GET_MEDICINE_BY_ID_SUCCESS,
    GET_MEDICINE_LIST,
    GET_MEDICINE_LIST_SUCCESS,
    GET_MEMBERSHIP_LIST,
    GET_ROOM_BY_ID,
    GET_ROOM_BY_ID_SUCCESS,
    GET_ROOM_BY_ROOM_TYPE,
    GET_ROOM_BY_ROOM_TYPE_SUCCESS,
    GET_ROOM_COUNT,
    GET_ROOM_COUNT_SUCCESS,
    GET_ROOM_DASHBOARD_DATA,
    GET_ROOM_DASHBOARD_DATA_SUCCESS,
    GET_ROOM_LIST,
    GET_ROOM_LIST_SUCCESS,
    GET_ROOM_SLOT,
    GET_ROOM_SLOT_BY_ID,
    GET_ROOM_SLOT_BY_ID_SUCCESS,
    GET_ROOM_SLOT_SUCCESS,
    GET_THERAPIST_COUNT,
    GET_THERAPIST_COUNT_SUCCESS,
    GET_VIRECHAN_LIST,
    MEMBERSHIP_LIST_SUCCESS,
    UPDATE_CUSTOMER_MEMBERSHIP,
    UPDATE_CUSTOMER_MEMBERSHIP_SUCCESS,
    UPDATE_DO_AND_DONTS,
    UPDATE_EXERCISE,
    UPDATE_MEDICINE, UPDATE_MEMBERSHIP, UPDATE_ROOM,
    UPDATE_VIRECHAN,
    VIRECHAN_LIST_SUCCESS
} from "../storeTypes";

//Do and Donts

export const ActionGetDoAndDontsList = (payload) => {
    return {
        type: GET_DO_AND_DONTS_LIST,
        payload
    };
};
export const ActionDoAndDontsListSuccess = (payload) => {
    return {
        type: DO_AND_DONTS_LIST_SUCCESS,
        payload
    };
};

export const ActionCreateDoAndDonts = (payload, callBack) => {
    return {
        type: CREATE_DO_AND_DONTS,
        payload,
        callBack
    };
};

export const ActionUpdateDoAndDonts = (payload, callBack) => {
    return {
        type: UPDATE_DO_AND_DONTS,
        payload,
        callBack
    };
};
export const ActionDeleteDoAndDonts = (payload, callBack) => {
    return {
        type: DELETE_DO_AND_DONTS,
        payload,
        callBack
    };
};

//Exercise

export const ActionGetExerciseList = (payload) => {
    return {
        type: GET_EXERCISE_LIST,
        payload
    };
};
export const ActionExerciseListSuccess = (payload) => {
    return {
        type: EXERCISE_LIST_SUCCESS,
        payload
    };
};

export const ActionCreateExercise = (payload, callBack) => {
    return {
        type: CREATE_EXERCISE,
        payload,
        callBack
    };
};

export const ActionUpdateExercise = (payload, callBack) => {
    return {
        type: UPDATE_EXERCISE,
        payload,
        callBack
    };
};
export const ActionDeleteExercise = (payload, callBack) => {
    return {
        type: DELETE_EXERCISE,
        payload,
        callBack
    };
};

// Virechan

export const ActionGetVirechanList = (payload) => {
    return {
        type: GET_VIRECHAN_LIST,
        payload
    };
};
export const ActionVirechanListSuccess = (payload) => {
    return {
        type: VIRECHAN_LIST_SUCCESS,
        payload
    };
};

export const ActionCreateVirechan = (payload, callBack) => {
    return {
        type: CREATE_VIRECHAN,
        payload,
        callBack
    };
};

export const ActionUpdateVirechan = (payload, callBack) => {
    return {
        type: UPDATE_VIRECHAN,
        payload,
        callBack
    };
};
export const ActionDeleteVirechan = (payload, callBack) => {
    return {
        type: DELETE_VIRECHAN,
        payload,
        callBack
    };
};

// Member Ship
export const ActionGetMemberShipList = (payload) => {
    return {
        type: GET_MEMBERSHIP_LIST,
        payload
    };
};
export const ActionMemberShipListSuccess = (payload) => {
    return {
        type: MEMBERSHIP_LIST_SUCCESS,
        payload
    };
};
export const getAllMemberShip = (payload) => {
    return {
        type: GET_ALL_MEMBERSHIP_LIST,
        payload,
    };
};
export const getAllMemberShipSuccess = (payload) => {
    return {
        type: GET_ALL_MEMBERSHIP_LIST_SUCCESS,
        payload,
    };
};
export const getAllMemberShipVerifyList = (payload) => {
    return {
        type: GET_ALL_MEMBER_VERIFY_LIST,
        payload,
    };
};
export const getAllMemberShipVerifyListSuccess = (payload) => {
    return {
        type: GET_ALL_MEMBER_VERIFY_LIST_SUCCESS,
        payload,
    };
};
export const ActionCreateMemberShip = (payload, callBack) => {
    return {
        type: CREATE_MEMBERSHIP,
        payload,
        callBack
    };
};

export const ActionUpdateMemberShip = (payload, callBack) => {
    return {
        type: UPDATE_MEMBERSHIP,
        payload,
        callBack
    };
};
export const ActionDeleteMemberShip = (payload, callBack) => {
    return {
        type: DELETE_MEMBERSHIP,
        payload,
        callBack
    };
};
export const ActionUpdateCustomerMembership = (payload) => {
    return {
        type: UPDATE_CUSTOMER_MEMBERSHIP,
        payload
    };
};
export const ActionUpdateCustomerMembershipSuccess = (payload) => {
    return {
        type: UPDATE_CUSTOMER_MEMBERSHIP_SUCCESS,
        payload
    };
};

// Medicine

export const ActionGetMedicineList = (payload) => {
    return {
        type: GET_MEDICINE_LIST,
        payload
    };
};
export const ActionMedicineListSuccess = (payload) => {
    return {
        type: GET_MEDICINE_LIST_SUCCESS,
        payload
    };
};

export const ActionCreateMedicine = (payload, callBack) => {
    return {
        type: CREATE_MEDICINE,
        payload,
        callBack
    };
};

export const ActionUpdateMedicine = (payload, callBack) => {
    return {
        type: UPDATE_MEDICINE,
        payload,
        callBack
    };
};

export const ActionGetMedicineByID = (payload, callBack) => {
    return {
        type: GET_MEDICINE_BY_ID,
        payload,
        callBack
    };
};

export const ActionGetMedicineByIDSuccess = (payload) => {
    return {
        type: GET_MEDICINE_BY_ID_SUCCESS,
        payload
    };
};

export const ActionDeleteMedicine = (payload, callBack) => {
    return {
        type: DELETE_MEDICINE,
        payload,
        callBack
    };
};

// Rooms

export const ActionGetRoomList = (payload) => {
    return {
        type: GET_ROOM_LIST,
        payload
    };
};
export const ActionRoomListSuccess = (payload) => {
    return {
        type: GET_ROOM_LIST_SUCCESS,
        payload
    };
};

export const ActionCreateRoom = (payload, callBack) => {
    return {
        type: CREATE_ROOM,
        payload,
        callBack
    };
};

export const ActionUpdateRoom = (payload, callBack) => {
    return {
        type: UPDATE_ROOM,
        payload,
        callBack
    };
};

export const ActionGetRoomByID = (payload, callBack) => {
    return {
        type: GET_ROOM_BY_ID,
        payload,
        callBack
    };
};

export const ActionGetRoomByIDSuccess = (payload) => {
    return {
        type: GET_ROOM_BY_ID_SUCCESS,
        payload
    };
};

export const ActionDeleteRoom = (payload, callBack) => {
    return {
        type: DELETE_ROOM,
        payload,
        callBack
    };
};

export const ActionGetTherapistCount = (payload, callBack) => {
    return {
        type: GET_THERAPIST_COUNT,
        payload,
        callBack
    };
};

export const ActionGetTherapistCountSuccess = (payload) => {
    return {
        type: GET_THERAPIST_COUNT_SUCCESS,
        payload
    };
};

export const ActionGetRoomCount = (payload, callBack) => {
    return {
        type: GET_ROOM_COUNT,
        payload,
        callBack
    };
};
export const ActionGetRoomCountSuccess = (payload) => {
    return {
        type: GET_ROOM_COUNT_SUCCESS,
        payload
    };
};
export const ActionGetRoomDashboardData = (payload) => {
    return {
        type: GET_ROOM_DASHBOARD_DATA,
        payload,
    }
};

export const ActionGetRoomDashboardDataSuccess = (payload) => {
    return {
        type: GET_ROOM_DASHBOARD_DATA_SUCCESS,
        payload,
    }
};

export const ActionGetRoomSlot = (payload) => {
    return {
        type: GET_ROOM_SLOT,
        payload
    };
};
export const ActionGetRoomSlotSuccess = (payload) => {
    return {
        type: GET_ROOM_SLOT_SUCCESS,
        payload
    };
};
export const ActionGetRoomSlotById = (payload) => {
    return {
        type: GET_ROOM_SLOT_BY_ID,
        payload
    };
};
export const ActionGetRoomSlotByIdSuccess = (payload) => {
    return {
        type: GET_ROOM_SLOT_BY_ID_SUCCESS,
        payload
    };
};
export const ActionGetRoomByRoomType = (payload) => {
    return {
        type: GET_ROOM_BY_ROOM_TYPE,
        payload
    };
};
export const ActionGetRoomByRoomTypeSuccess = (payload) => {
    return {
        type: GET_ROOM_BY_ROOM_TYPE_SUCCESS,
        payload
    };
};
export const ActionGetDetoxRetreat = (payload) => {
    return {
        type: GET_ALL_DETOX_RETREAT,
        payload
    };
};
export const ActionGetDetoxRetreatSuccess = (payload) => {
    return {
        type: GET_ALL_DETOX_RETREAT_SUCCESS,
        payload
    };
};

export const ActionCreateDetoxRetreat = (payload, callBack) => {
    return {
        type: CREATE_DETOX_RETREAT,
        payload,
        callBack
    };
};
export const ActionCreateDetoxRetreatSuccess = (payload) => {
    return {
        type: CREATE_DETOX_RETREAT_SUCCESS,
        payload
    };
};