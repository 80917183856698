/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */

import { useEffect } from 'react';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { InputFromUser } from '../../components/Input/input';
import { getAdminuUerDetails } from '../../store/Actions/AuthActions';
import { CreatePatientTestimonial, GetAllPatientTestimonial } from '../../store/Actions/TestimonialLinkAction';
import { SingleMultiSelectDropDown } from '../../components/Input/InputText';
import { ActionUpdateById, ActionUploadImage } from '../../store/Actions/CommonAction';
import UploadImage from '../../components/Upload/Upload';
import { DOCS_URL } from '../../utils/globals';
import { ConsultationMasterDataAction } from '../../store/Actions/AppointmentAction';

const AddEditPatientTestimonial = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const { userDetails } = useSelector(state => state?.auth);
    const { PatientTestimonialList } = useSelector(state => state?.TestimonialLink);
    // const { Treatmentservicelist } = useSelector(state => state?.PrakrutiStandard);
    // const TreatmentList = Treatmentservicelist?.[0]?.treatment;
    const userId = userDetails?.[0]?._id
    const idValue = searchParams.get("id");
    const page = searchParams.get("page");
    const updateValue = searchParams.get("update");
    const { ConsultationMasterData, } = useSelector(state => state?.Appointment);

    useEffect(() => {
        if (idValue) {
            dispatch(GetAllPatientTestimonial({ Query: '/' + idValue }));
        }

    }, [idValue])

    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])
    useEffect(() => {
        dispatch(ConsultationMasterDataAction({ Query: '' }))
    }, [])
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to={`/patient-testimonial-list?page=1`}>Patient Testimonial</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Patient Testimonial</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue || false}
                                                initialValues={{
                                                    "orderIndex": PatientTestimonialList?.orderIndex || "",
                                                    "age": PatientTestimonialList?.age,
                                                    "treatment": PatientTestimonialList?.treatment && PatientTestimonialList?.treatment?.map((v, k) => (v.treatmentName)) || [],
                                                    "rating": PatientTestimonialList?.rating || "",
                                                    "description": PatientTestimonialList?.description || "",
                                                    patientName: PatientTestimonialList?.patientName,
                                                    "docs": PatientTestimonialList?.docs || []
                                                }}
                                                // validationSchema={CelebrityValidationSchema}
                                                onSubmit={(values, { setErrors, setSubmitting }) => {

                                                    const matchedArray = [];

                                                    ConsultationMasterData?.treatments?.forEach(itemA => {
                                                        if (values?.treatment.includes(itemA.name)) {
                                                            matchedArray.push({ treatmentName: itemA.name, treatmentId: itemA.id });
                                                        }
                                                    });

                                                    if (updateValue && idValue != null) {
                                                        dispatch(ActionUpdateById({ Query: `admin/patient-testimonial/${idValue}`, obj: { ...values, treatment: matchedArray } }
                                                            , (Res) => {
                                                                if (Res?.statusCode === 200) {
                                                                    window.location.href = `/patient-testimonial-list?page=${page}`
                                                                } else if (Res?.statusCode === 400) {
                                                                    setErrors(Res?.data?.errors);
                                                                }
                                                                setSubmitting(false);
                                                            }));
                                                    } else {
                                                        dispatch(CreatePatientTestimonial({ ...values, treatment: matchedArray }, (Response) => {
                                                            if (Response?.statusCode === 200) {
                                                                window.location.href = `/patient-testimonial-list?page=${page}`
                                                            }
                                                            setSubmitting(false);
                                                        }))
                                                    }
                                                }}

                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue,
                                                    isSubmitting,
                                                }) => {
                                                    return (
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="form-heading">
                                                                    <h4>Add Patient Testimonial Data</h4>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-4">

                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Order Index"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Order Index"
                                                                    value={values?.orderIndex}
                                                                    name="orderIndex"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.orderIndex}
                                                                    touched={touched?.orderIndex}
                                                                />

                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Patient Name"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Patient Name"
                                                                    value={values?.patientName}
                                                                    name="patientName"
                                                                    onChange={(v) => setFieldValue("patientName", v?.target?.value, true)}

                                                                    onBlur={handleBlur}
                                                                    error={errors?.patientName}
                                                                    touched={touched?.patientName}
                                                                />

                                                            </div>

                                                            <div className="row mt-4">

                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Age of Patient"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Age of Patient"
                                                                    value={values?.age}
                                                                    name="age"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.age}
                                                                    touched={touched?.age}
                                                                />

                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Review Description"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Review Description"
                                                                    value={values?.description}
                                                                    name="description"
                                                                    onChange={(v) => setFieldValue("description", v?.target?.value, true)}

                                                                    onBlur={handleBlur}
                                                                    error={errors?.description}
                                                                    touched={touched?.description}
                                                                />

                                                            </div>

                                                            <div className="row mt-4">
                                                                <InputFromUser
                                                                    placeholder={"Post Rating"}
                                                                    type="url"
                                                                    name="rating"
                                                                    onChange={(v) => setFieldValue("rating", v?.target?.value, true)}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values?.rating}
                                                                    label="Rating"
                                                                    error={errors?.rating}
                                                                    touched={touched.rating}
                                                                />

                                                                <SingleMultiSelectDropDown
                                                                    label=" Treatment Name"
                                                                    type="text"
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    name="treatment"
                                                                    mode={"multiple"}
                                                                    onChange={(Selected) => {

                                                                        setFieldValue("treatment", Selected, true)
                                                                        // setFieldValue('treatmentName', selectedTreatmentName);
                                                                        // const selectedTreatments =
                                                                        // setFieldValue('treatmentId', );
                                                                    }}
                                                                    FormControlClassName={'form-control'}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.treatment}
                                                                    value={values?.treatment}
                                                                    options={ConsultationMasterData?.treatments && ConsultationMasterData?.treatments?.map((v) => v.name) || []}
                                                                    touched={touched?.treatment}
                                                                    placeholder={'Select Your Treatment'} />

                                                            </div>

                                                            <div>
                                                                <UploadImage
                                                                    className={"img-fluid"}
                                                                    fileList={!(values.docs.length === 0) ? values.docs.map((val, ind) => ({
                                                                        uid: ind + 'docs',
                                                                        name: (DOCS_URL + values?.docs?.[ind]).split('/')?.[(DOCS_URL + values?.docs?.[ind]).split('/').length - 1],
                                                                        status: 'done',
                                                                        url: DOCS_URL + values?.docs?.[ind],
                                                                    })) : []}

                                                                    handleChange={(v) => {
                                                                        if (v.file && v.file.status === 'uploading') {
                                                                            const formData = new FormData();
                                                                            formData.append("isCelebrity", true);
                                                                            formData.append("attachment", v?.file?.originFileObj);
                                                                            dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                if (typeof res === 'object') {
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'docs',
                                                                                            value: [...(values?.docs || []), res[0]],
                                                                                        },
                                                                                    });
                                                                                }
                                                                            }))
                                                                        } else if (v.file && v.file.status === 'removed') {
                                                                            const filteredFiles = values?.docs.filter((url) => (DOCS_URL + url) != v?.file?.url);
                                                                            handleChange({
                                                                                target: {
                                                                                    name: 'docs',
                                                                                    value: filteredFiles,
                                                                                },
                                                                            });
                                                                        }
                                                                    }}
                                                                    UploadImagelimit={7} />
                                                            </div>

                                                            <div className="col-12 mt-4">
                                                                <div className="doctor-submit text-end">
                                                                    <button
                                                                        type="submit"
                                                                        onClick={() => {
                                                                            handleSubmit()
                                                                        }}
                                                                        className="btn btn-primary submit-form me-2"
                                                                        disabled={isSubmitting}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary cancel-form"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Formik>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div>

            </>

        </>
    )
}

export default AddEditPatientTestimonial