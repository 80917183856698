/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { CustomTable } from "../../components/Table";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
    plusicon,
    refreshicon
} from "../../components/imagepath";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { GetHolidayListAction } from "../../store/Actions/StaffAction";
import moment from 'moment';
import { Button, Modal, Popover } from "antd";
import { ActionDeleteById } from "../../store/Actions/CommonAction";
import { matchPermissions } from "../../utils/Permission";
const { confirm } = Modal;
const Holiday = () => {
    const pageSize = 10;
    const dispatch = useDispatch();
    const [Refresh, setRefresh] = useState(false);
    const { holidayList, loader } = useSelector(state => state?.Staff);
    const { userDetails } = useSelector(state => state?.auth);
    const params = queryString.parse(location.search);

    useEffect(() => {
        dispatch(GetHolidayListAction({ Query: `?page=${params?.page || 1}`, }));
        setRefresh(false);
    }, [Refresh])

    const EditList = (searchTerm) => {
        window.location.href = `/holidays/add-holiday?update=true&id=${searchTerm}&page=${parseInt(params?.page)}`
    }
    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.festivalName} Holiday?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteById({ Query: `admin/holiday/${item?.id}` }, (Res) => {
                    if (Res?.statusCode === 200) {
                        setRefresh(true);
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };

    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('holiday.update');
    const canDelete = matchPermissionsForUser('holiday.delete');
    const canCerate = matchPermissionsForUser('holiday.create');
    const content = (item) => (
        <div className="">
            {canUpdate &&
                <Button className="m-2" onClick={() => EditList(item?.id)}>Edit</Button>
            }
            {canDelete &&
                <Button onClick={() => showDeleteConfirm(item)}>Delete</Button>
            }
        </div>
    );
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Festival Name",
            dataIndex: "festivalName",
            sorter: (a, b) => a.festivalName.length - b.festivalName.length,
        },
        {
            title: "Description",
            dataIndex: "description",
            sorter: (a, b) => a.description.length - b.description.length,
        },
        {
            title: "Day",
            dataIndex: "day",
        }, {
            title: "Date",
            dataIndex: "date",
            render: (text, record, index) => {
                return <span>{(text && moment(text).format("DD-MM-YYYY") || '---')}</span>;
            },
        },
        {
            title: !canUpdate && !canDelete ? null : "Action",
            dataIndex: "festivalName",
            key: "key",
            render: (text, record, num) => {
                if (!canUpdate && !canDelete) {
                    return null
                }
                else {
                    return (
                        <Popover placement="left" title={text} content={() => content(record)} >
                            <Button type="dashed">• • •</Button>
                        </Popover>
                    )
                }
            },
        },
    ]
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="expenses.html">Holiday</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Holiday List</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Holiday List</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">
                                                                    {canCerate &&
                                                                        <Link
                                                                            to={`/holidays/add-holiday?update=false&&page=${params?.page || 1}`}
                                                                            className="btn btn-primary add-pluss ms-2"
                                                                        >
                                                                            <img src={plusicon} alt="#" />
                                                                        </Link>
                                                                    }

                                                                    <Link
                                                                        onClick={() => window.location.reload()}
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <CustomTable
                                                    CurrentPageNum={holidayList?.[0]?.page}
                                                    OnChange={(v) => {
                                                        var newp = params
                                                        newp.page = v
                                                        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
                                                    }}
                                                    TotalPageList={holidayList?.[0]?.totalDocs}
                                                    column={columns}
                                                    dataSource={holidayList?.[0] ? holidayList?.[0]?.docs : []}
                                                    dataSourceLength={holidayList?.[0]?.docs?.length}
                                                    pageSize={10}
                                                    loader={loader}
                                                    ShowHeader={true}
                                                    Header={<div className="staff-search-table">

                                                    </div>}
                                                    key={"hi"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
};

export default Holiday;
