import { Table } from "antd";
import { onShowSizeChange } from "../Pagination";
import PropTypes from 'prop-types';
import { ResponsiveComponent } from "../../utils/Funcations";

export const CustomTable = ({
    // pageSize = 10,
    loader = false,
    ShowHeader = false,
    ShowFooter = false,
    bordered = false,
    column = [],
    Header = <span>Header</span>,
    Footer = <span>Footer</span>,
    dataSource = [],
    OnChange = () => { },
    TotalPageList = 0,
    defaultPageSize = 10,
    CurrentPageNum = 0,
    showPagination = true,
    className
}) => {
    const Size = ResponsiveComponent()
    return (
        <>
            {ShowHeader && Header}
            <div className="table-responsive">
                <Table
                    loading={loader}
                    bordered={bordered}
                    className={`table ${className}`}
                    pagination={showPagination ? {
                        defaultCurrent: CurrentPageNum,
                        current: CurrentPageNum,
                        onChange: (p) => OnChange(p),
                        total: TotalPageList,
                        showTotal: (total, range) => (Size === "phone" || Size === "tablet") ? "" : `Showing ${range[0]}  to ${range[1]} of ${TotalPageList} entries`,
                        showQuickJumper: true,
                        showSizeChanger: false,
                        defaultPageSize: defaultPageSize,
                        onShowSizeChange: onShowSizeChange,
                        hideOnSinglePage: true,
                        responsive: true,
                    } : false}
                    expandable={true}
                    columns={column}
                    dataSource={dataSource} />
            </div>
            {ShowFooter && Footer}
        </>)
}
CustomTable.propTypes = {
    pageSize: PropTypes.number,
    loader: PropTypes.bool,
    ShowHeader: PropTypes.bool,
    bordered: PropTypes.bool,
    ShowFooter: PropTypes.bool,
    column: PropTypes.array,
    dataSource: PropTypes.array,
    OnChange: PropTypes.func,
    TotalPageList: PropTypes.number,
    defaultPageSize: PropTypes.number,
    dataSourceLength: PropTypes.number,
    CurrentPageNum: PropTypes.number,
    Header: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    Footer: PropTypes.node,
    showPagination: PropTypes.bool
};
