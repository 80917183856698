/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { plusicon, refreshicon } from "../../components/imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import moment from 'moment';
import { CustomTable } from "../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { ActionGetLeavesList } from "../../store/Actions/StaffAction";
import { getAdminuUerDetails } from "../../store/Actions/AuthActions";
import { ActionUpdateById } from "../../store/Actions/CommonAction";
import { matchPermissions } from "../../utils/Permission";

const Leave = () => {
    const dispatch = useDispatch();
    const { userDetails } = useSelector(state => state?.auth);
    const userId = userDetails?.[0]?._id;
    const pageSize = 10;
    const [Refresh, setRefresh] = useState(false);
    const params = queryString.parse(location.search);

    const { leavesList, loader } = useSelector(state => state?.Staff);


    useEffect(() => {
        dispatch(ActionGetLeavesList({ Query: `?page=${params?.page || 1}`, }));
        setRefresh(false)
    }, [Refresh])
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])

    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('leave.update');
    const canCerate = matchPermissionsForUser('leave.create');
    const statusOptions = [

        { status: "Pending", className: "status-orange" },
        { status: "Approved", className: "status-green" },
        { status: "Cancelled", className: "status-pink" },
    ];
    const handleDropdownChange = (id, status) => {
        dispatch(ActionUpdateById({ Query: `admin/leaves/${id}`, obj: { "status": status } },


            (response) => {
                // console.log('response', response);
                if (response && response.statusCode === 200) {
                    setRefresh(true)
                }
            }))
    };
    const renderStatusDropdown = (id, text) => {
        return (
            <React.Fragment>
                {canUpdate &&
                    <Dropdown onSelect={(status) => handleDropdownChange(id, status)} >
                        {statusOptions.map((option) => (
                            <React.Fragment key={option.status}>
                                {text === option.status && (
                                    <span className={`custom1-badge ${option.className}`}>
                                        {text}
                                        <Dropdown.Toggle variant="" disabled={!(text === 'Pending')} />
                                        <Dropdown.Menu >
                                            {statusOptions.map((item) => (
                                                <Dropdown.Item key={item.status} eventKey={item.status} >
                                                    {item.status}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </span>
                                )}
                            </React.Fragment>
                        ))}
                    </Dropdown>
                }
            </React.Fragment>

        );
    };

    const columns = [
        {
            title: "EmployeeName",
            dataIndex: "adminName",
            sorter: (a, b) => a.EmployeeName.length - b.EmployeeName.length,
        },
        {
            title: "LeaveType",
            dataIndex: "leaveType",
            sorter: (a, b) => a.LeaveType.length - b.LeaveType.length,
        },
        {
            title: "From",
            dataIndex: "fromDate",
            sorter: (a, b) => a.From.length - b.From.length,
            render: (text, record) => { return (<div>{(text && moment(text).format('DD/MM/YYYY') || '---')}</div>) },

        },
        {
            title: "To",
            dataIndex: "toDate",
            sorter: (a, b) => a.To.length - b.To.length,
            render: (text, record) => { return (<div>{(text && moment(text).format('DD/MM/YYYY') || '---')}</div>) },
        },
        {
            title: "Noofdays",
            dataIndex: "noOfDays",
            sorter: (a, b) => a.Noofdays.length - b.Noofdays.length,
        },

        {
            title: "Reason",
            dataIndex: "reason",
            sorter: (a, b) => a.Reason.length - b.Reason.length,
        },
        {
            title: !canUpdate ? null : 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                return renderStatusDropdown(record._id, text)
            }

        },
    ];

    return (
        <>
            <div className="main-wrapper">
                <Header />
                <Sidebar id='menu-item3' id1='menu-items3' activeClassName='leaves' />
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/add-leave">Staffs </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Leave Request</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* /Page Header */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card card-table show-entire">
                                    <div className="card-body">
                                        {/* Table Header */}
                                        <div className="page-table-header mb-2">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="doctor-table-blk">
                                                        <h3>Leave Request</h3>
                                                        <div className="doctor-search-blk">
                                                            <div className="add-group">
                                                                {canCerate &&
                                                                    <Link
                                                                        to="/add-leave"
                                                                        className="btn btn-primary add-pluss ms-2">
                                                                        <img src={plusicon} alt="#" />
                                                                    </Link>
                                                                }

                                                                <Link
                                                                    onClick={() => window.location.reload()}
                                                                    className="btn btn-primary doctor-refresh ms-2">
                                                                    <img src={refreshicon} alt="#" />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-auto text-end float-end ms-auto download-grp">
                                                    <Link to="#" className=" me-2">
                                                        <img src={pdficon} alt="#" />
                                                    </Link>
                                                    <Link to="#" className=" me-2">
                                                        <img src={pdficon2} alt="#" />
                                                    </Link>
                                                    <Link to="#" className=" me-2">
                                                        <img src={pdficon3} alt="#" />
                                                    </Link>
                                                    <Link to="#">
                                                        <img src={pdficon4} alt="#" />
                                                    </Link>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <CustomTable
                                                CurrentPageNum={leavesList?.[0]?.page}
                                                OnChange={(v) => {
                                                    var newp = params
                                                    newp.page = v
                                                    window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
                                                }}
                                                TotalPageList={leavesList?.[0]?.totalDocs}
                                                column={columns}
                                                dataSource={leavesList?.[0]?.docs}
                                                dataSourceLength={leavesList?.[0]?.docs?.length}
                                                pageSize={10}
                                                loader={loader}
                                                ShowHeader={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <div
                    id="delete_patient"
                    className="modal fade delete-modal"
                    role="dialog"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <img src={imagesend} alt="#" width={50} height={46} />
                                <h3>Are you sure want to delete this ?</h3>
                                <div className="m-t-20">
                                    {" "}
                                    <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                                        Close
                                    </Link>
                                    <button type="submit" className="btn btn-danger">
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default Leave;
