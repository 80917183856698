import { call, put, takeEvery } from 'redux-saga/effects';
import { CREATE_APPCONFIG, GET_APPCONFIG_LIST, UPDATE_APPCONFIG, DELETE_APPCONFIG, GET_PATIENT_PLAN_LIST, GET_DISCHARGE_FORM } from '../storeTypes';
import { CreateAppconfigApi, DeleteAppconfigApi, GetAppconfigApi, GetPatientDischargeApi, GetPatientPlanApi, UpdateAppconfigApi } from '../../utils/api';
import { ActionAppConfigListSuccess, ActionGetPatientDischargeFormSuccess, ActionGetPatientPlanSuccess } from '../Actions/AppConfigAction';
import { handleApiResponse } from '../../utils/Funcations';

const File = 'appConfigSaga'
function* GetAppConfigSaga(action) {
  try {
    const response = yield call(GetAppconfigApi, action.payload);
    if (response?.status == 200) {
      yield put(ActionAppConfigListSuccess(response?.data?.data));
    }
  } catch (error) {
    handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
    console.log(File + '/GetAppConfigSaga Error :- ', error);
  }
}
function* GetPatientPlanSaga(action) {
  try {
    const response = yield call(GetPatientPlanApi, action.payload);
    if (response?.status == 200) {
      yield put(ActionGetPatientPlanSuccess(response?.data?.data));
    }
  } catch (error) {
    handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
    console.log(File + '/GetPatientPlanSaga Error :- ', error);
  }
}

function* GetPatientDischargeSaga(action) {
  try {
    const response = yield call(GetPatientDischargeApi, action.payload);
    if (response?.status == 200) {
      yield put(ActionGetPatientDischargeFormSuccess(response?.data?.data));
    }
  } catch (error) {
    handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
    console.log(File + '/GetPatientDischargeSaga Error :- ', error);
  }
}
function* CreateAppConfigSaga(action) {
  try {
    const response = yield call(CreateAppconfigApi, action.payload);
    if (response?.status === 200) {
      action?.callBack(response?.data);
    }
  } catch (error) {
    handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
    console.log(File + '/CreateAppConfigSaga Error :- ', error);
  }
}
function* UpdateAppConfigSaga(action) {
  try {
    const response = yield call(UpdateAppconfigApi, action.payload);
    if (response?.status === 200) {
      handleApiResponse(response.status, response?.data, action?.callBack, true)
    }
  } catch (error) {
    handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
    console.log(File + '/UpdateAppConfigSaga Error :- ', error);
  }
}
function* DeleteAppConfigSaga(action) {
  try {
    const response = yield call(DeleteAppconfigApi, action.payload);
    if (response?.status === 200) {
      handleApiResponse(response.status, response?.data, action?.callBack, true)
    }
  } catch (error) {
    handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
    console.log(File + 'DeleteAppConfigSaga Error :- ', error);
  }
}

export function* appConfigSaga() {
  [
    yield takeEvery(GET_APPCONFIG_LIST, GetAppConfigSaga),
    yield takeEvery(CREATE_APPCONFIG, CreateAppConfigSaga),
    yield takeEvery(UPDATE_APPCONFIG, UpdateAppConfigSaga),
    yield takeEvery(GET_PATIENT_PLAN_LIST, GetPatientPlanSaga),
    yield takeEvery(GET_DISCHARGE_FORM, GetPatientDischargeSaga),
    yield takeEvery(DELETE_APPCONFIG, DeleteAppConfigSaga),
  ];
}
