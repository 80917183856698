/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Link, useParams } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import { FormInput, RadioButton, SingleMultiSelectDropDown } from "../../../../components/Input/InputText";
import { EditProfileValidationSchema } from "../../../../utils/FormValidation";
import { GetStaffProfileAction, UpdateStaffProfileAction } from "../../../../store/Actions/StaffAction";
import { ActionUploadImage } from "../../../../store/Actions/CommonAction";
import { InputFromUser } from "../../../../components/Input/input";
import dayjs from 'dayjs';
import { DOCS_URL } from "../../../../utils/globals";
import UploadImage from "../../../../components/Upload/Upload";
import { isNullOrUndef } from "../../../../utils/Funcations";

const EditStaffProfile = () => {
  const dispatch = useDispatch();
  const { StaffProfile, loader } = useSelector(state => state?.Staff);
  const [selectedFile, setSelectedFile] = useState(null);
  const { id } = useParams();
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedFile(file);

  };
  useEffect(() => {
    dispatch(GetStaffProfileAction({ Query: id }))
  }, [])
  return (
    <div>
      <Header />
      <Sidebar id="menu-item1" id1="menu-items1" activeClassName="add-staff" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/staff-list?page=1">Staff</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/staff-list?page=1">Staff List</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={`/staff-list/staff-profile/${id}?page=1`}>Staff Profile</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Profile</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">

                    <Formik
                      enableReinitialize
                      initialValues={{
                        name: StaffProfile?.[0]?.name || "",
                        phoneNo: StaffProfile?.[0]?.phoneNo || "",
                        dateOfBirth: (StaffProfile?.[0]?.dateOfBirth && dayjs(StaffProfile?.[0]?.dateOfBirth) || null) || null,
                        weeklyOfDay: StaffProfile?.[0]?.weeklyOfDay || "",
                        gender: StaffProfile?.[0]?.gender || "",
                        email: StaffProfile?.[0]?.email || "",
                        profileImage: StaffProfile?.[0]?.profileImage || "",
                        shortDescription: StaffProfile?.[0]?.shortDescription || "",
                        // address: StaffProfile?.[0]?.address || "",
                      }}
                      validationSchema={EditProfileValidationSchema}
                      onSubmit={(values, { setErrors, setSubmitting }) => {
                        dispatch(UpdateStaffProfileAction({
                          Query: id, obj: values
                        }, (Res) => {
                          if (Res?.statusCode === 200) {
                            window.location.href = `/staff-list/staff-profile/${id}`
                          } else if (Res?.statusCode === 400) {
                            setErrors(Res?.data?.errors);
                          }
                          setSubmitting(false);
                        }))
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setValues,
                        setSubmitting,
                        isSubmitting,
                        /* and other goodies */
                      }) => {
                        return (
                          <Form>

                            <div className="row">
                              <div className="text-center">
                                <UploadImage
                                  className={"img-fluid"}
                                  fileList={!isNullOrUndef(values?.profileImage?.[0]) && [{
                                    uid: 1 + values?.profileImage,
                                    name: values?.profileImage,
                                    status: 'done',
                                    url: DOCS_URL + values?.profileImage?.[0],
                                  }] || []}
                                  handleChange={(v) => {
                                    if (v.file && v.file.status === 'uploading') {
                                      const formData = new FormData();
                                      formData.append("isAdminUser", true);
                                      formData.append("attachment", v?.file?.originFileObj);
                                      dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                        if (typeof res === 'object') {
                                          handleChange({
                                            target: {
                                              name: 'profileImage',
                                              value: [res?.[0]],
                                            },
                                          });
                                        }
                                      }))
                                    } else if (v.file && v.file.status === 'removed') {
                                      handleChange({
                                        target: {
                                          name: 'profileImage',
                                          value: '',
                                        },
                                      });
                                    }
                                  }}
                                  UploadImagelimit={1} />
                              </div>
                              {/* </div> */}



                              <FormInput
                                label="Name"
                                type="name"
                                FormGroupClassName={"col-xl-6 col-md-12"}
                                LabelClassName={"col-form-label"}
                                name="name"
                                onChange={handleChange}
                                FormControlClassName={'form-control '}
                                onBlur={handleBlur}
                                error={errors.name}
                                value={values.name}
                                touched={touched.name}
                                placeholder={'Enter Your name'}
                              />
                              <FormInput
                                label="Phone No"
                                type="phoneNo"
                                FormGroupClassName={"col-xl-6 col-md-12"}
                                LabelClassName={"col-form-label"}
                                name="phoneNo"
                                onChange={handleChange}
                                FormControlClassName={'form-control '}
                                onBlur={handleBlur}
                                error={errors.phoneNo}
                                value={values.phoneNo}
                                touched={touched.phoneNo}
                                placeholder={'Enter Your phoneNo'}
                              />

                              <FormInput
                                label="email"
                                type="email"
                                FormGroupClassName={"col-xl-6 col-md-12"}
                                LabelClassName={"col-form-label"}
                                name="email"
                                onChange={handleChange}
                                FormControlClassName={'form-control '}
                                onBlur={handleBlur}
                                error={errors.email}
                                value={values.email}
                                touched={touched.email}
                                placeholder={'Enter Your email'}
                              />
                              <InputFromUser
                                label="Birthdate"
                                type="date"
                                name="dateOfBirth"
                                onChange={(value) => {
                                  handleChange({
                                    target: {
                                      name: 'dateOfBirth',
                                      value: value,
                                    },
                                  });
                                }}
                                onBlur={(value) => {
                                  handleBlur({
                                    target: {
                                      name: 'dateOfBirth',
                                      value: true,
                                    },
                                  });
                                }}
                                FormControlClassName={'form-control'}
                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                LabelClassName={"col-form-label"}
                                value={values.dateOfBirth}
                                error={errors.dateOfBirth}
                                touched={touched.dateOfBirth}
                                placeholder={'Select From Date '}
                              />


                              {/* 
                              <FormInput
                                label="Address"
                                type="address"
                                FormGroupClassName={"col-xl-6 col-md-12"}
                                LabelClassName={"col-form-label"}
                                name="address"
                                onChange={handleChange}
                                FormControlClassName={'form-control '}
                                onBlur={handleBlur}
                                // error={errors.address}
                                value={values.address}
                                touched={touched.address}
                                placeholder={'Enter Your Address'}
                              /> */}

                              <SingleMultiSelectDropDown
                                label="Weekly Of Day"
                                mode=""
                                FormGroupClassName={"col-xl-6 col-md-12"}
                                LabelClassName={"col-form-label"}
                                name="weeklyOfDay"
                                onChange={(value) => {
                                  handleChange({
                                    target: {
                                      name: 'weeklyOfDay',
                                      value,
                                    },
                                  });
                                }}
                                onBlur={(value) => {
                                  handleBlur({
                                    target: {
                                      name: 'weeklyOfDay',
                                      value: true,
                                    },
                                  });
                                }}
                                FormControlClassName={'form-control'}
                                error={errors.weeklyOfDay}
                                value={values.weeklyOfDay}
                                options={["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]}
                                touched={touched.weeklyOfDay}
                                placeholder={'Enter Your Weekly Of Day'} />
                              <RadioButton label={"Gender"} options={[
                                { label: 'Male', value: 'Male' },
                                { label: 'Female', value: 'Female' },
                              ]}
                                onBlur={(v) => handleBlur({
                                  target: {
                                    name: 'gender',
                                    value: true,
                                  },
                                })}
                                onChange={(v) => handleChange({
                                  target: {
                                    name: 'gender',
                                    value: v,
                                  },
                                })}
                                error={errors.gender}
                                selectedValue={values.gender}
                                touched={touched.gender}

                              />
                              <FormInput
                                label="Short Description"
                                type="text"
                                FormGroupClassName={"col-xl-6 col-md-12"}
                                LabelClassName={"col-form-label"}
                                name="shortDescription"
                                onChange={handleChange}
                                FormControlClassName={'form-control '}
                                onBlur={handleBlur}
                                error={errors.shortDescription}
                                value={values.shortDescription}
                                touched={touched.shortDescription}
                                placeholder={'Enter Your Description'}
                              />
                              <div className="col-12 mt-5">
                                <div className="text-end">
                                  <Button
                                    onClick={() => {
                                      handleSubmit()
                                    }}
                                    type="primary"
                                    disabled={isSubmitting}
                                    className="me-2"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>)
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div >
  );
};

export default EditStaffProfile;
