/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { GetAllsolution } from "../../store/Actions/ServiceAction";
import { ResponsiveComponent, generateQueryString, trimText } from "../../utils/Funcations";
import { Button, Modal, Popover } from "antd";
import { ActionDeleteById } from "../../store/Actions/CommonAction";
import { matchPermissions } from "../../utils/Permission";
import { TableListPageCom } from "../../components/TableListPageCom";
import { getAdminuUerDetails } from "../../store/Actions/AuthActions";
import { DOCS_URL } from "../../utils/globals";
const Solutionlist = () => {
    const { confirm } = Modal;
    const Size = ResponsiveComponent()
    const pageSize = 10;
    const dispatch = useDispatch();

    const { solutionList, loader } = useSelector(state => state?.Service);
    const { userDetails } = useSelector(state => state?.auth);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const page = searchParams.get("page");
    const SolutionName = searchParams.get("SolutionName");
    const userId = userDetails?.[0]?._id

    const Final = generateQueryString(['page', page || 1],
        ['name', SolutionName],
    );

    const params = queryString.parse(location.search);
    useEffect(() => {
        dispatch(GetAllsolution({ Query: Final, }));
    }, [])
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('solution.update');
    const canDelete = matchPermissionsForUser('solution.delete');
    // const canCerate = matchPermissionsForUser('solution.create');
    const content = (item) => (
        <div className="row col">
            {canUpdate &&
                <Button className="col m-2" onClick={() => { window.location.href = `/add-solution?update=true&&page=${1}&&id=${item?.id}` }}>Edit Solution</Button>
            }
            {canDelete &&
                <Button className="col m-2" onClick={() => showDeleteConfirm(item)}>Delete Solution</Button>

            }
        </div>
    );
    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.name} solution?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteById({ Query: `admin/solution/${item?._id}` }, (Res) => {
                    if (Res?.statusCode === 200) {
                        //setRefresh(true)
                        window.location.reload()
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Solution Name",
            dataIndex: "name",

        },
        {
            title: "Solution Descriptions",
            dataIndex: "shortDescription",
            render: (text, record) => (
                <div className="app-config-boldText">
                    {trimText(record.shortDescription?.en, Size === "phone" || Size === "tablet" ? 20 : 50)}
                </div>
            ),
        },
        {
            title: "Solution rating",
            dataIndex: "rating",

        },
        {
            title: "Per Day Price",
            dataIndex: "perDayPrice",

        },
        {
            title: "Image",
            dataIndex: "listingViewImage",
            render: (text, record) => (
                <img src={DOCS_URL + record.listingViewImage} alt={record.name} style={{ width: 60, height: 60 }} />
            ),
        },
        {
            title: !canUpdate && !canUpdate ? null : "Action",
            dataIndex: "roleName",
            render: (text, record,) => {
                if (!canUpdate && !canUpdate) {
                    return null
                } else {
                    return <Popover placement="top" title={text} content={() => content(record)}>
                        <Button type="text">• • •</Button>
                    </Popover>
                }
            },
        },
    ]
    const BreadcrumbList = [{
        name: 'Detox Programs',
        linkTo: `/add-solution?page=${params?.page || 1}`
    },
    {
        name: 'Detox Programs List',
    }
    ]
    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={solutionList?.[0]?.docs}
            TableColumn={columns}
            ShowAddButton={true}
            Iscreate="solution.create"
            CreateButtonLink={`/add-solution?update=false&&page=${params?.page || 1}`}
            PageMainTitle="Detox Programs List"
            CurrentPage={solutionList?.[0]?.page || 1}
            Tableloader={loader}
            TotalPageList={solutionList?.[0]?.totalDocs}
            dataSourceLength={solutionList?.[0]?.docs?.length}
            handlePageChange={(v) => {
                var newp = params
                newp.page = v
                window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
            }}
        />

    )
};

export default Solutionlist;
