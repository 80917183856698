import React, { useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
// import CountUp from "react-countup/build/CountUp";
import { DashboardCard } from "../../components/Card/Cards";
import { useDispatch, useSelector } from "react-redux";
import { ActionGetDashboardData } from "../../store/Actions/CommonAction";
import { CustomTable } from "../../components/Table";
import moment from 'moment';
import ActivityChart from "../../components/Chart/ActivityChart";
import { CommonHeadingTxt } from "../../components/Text/Text";
import DonutChart from "../../components/Chart/DonutChart";
import { Check, X } from "feather-icons-react/build/IconComponents";

const Dashboard = () => {
    const { getDashboardData } = useSelector(state => state?.Common);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(ActionGetDashboardData())
    }, [])
    const seriesData = getDashboardData?.paymentType?.map((payment) => payment?.totalPaidAmount)
    const lablesData = getDashboardData?.paymentType?.map((payment) => payment?._id)

    const column = [
        {
            title: "Doctor Name",
            dataIndex: "name",

        },

        {
            title: " Total Appointments",
            dataIndex: "appointmentsCount",

        },
    ]
    const availableTherapistColumn = [
        {
            title: "Therapist Name",
            dataIndex: "name",

        },

        {
            title: " Therapist Counts",
            dataIndex: "therapistCount",

        },
    ]
    const therapistSchedule = [
        {
            title: "Therapist Name",
            dataIndex: "therapist",
            render: (text) => text?.name
        },

        {
            title: " Shift",
            dataIndex: "title",

        },
    ]
    const doctorLeaveColumn = [
        {
            title: "Doctor Name",
            dataIndex: "adminName",
            render: (text, record) => {
                if (record.adminType === "Doctor") {
                    return text;
                }
                return null;
            }
        },

        {
            title: " From",
            dataIndex: "fromDate",
            render: (text) => { return (<div>{(text && moment(text).format('YYYY-MM-DD') || '---')}</div>) },

        },
        {
            title: " To",
            dataIndex: "toDate",
            render: (text) => { return (<div>{(text && moment(text).format('YYYY-MM-DD') || '---')}</div>) },

        },
        {
            title: " Total Days",
            dataIndex: "noOfDays",

        },
    ]
    const enrollPatient = [
        {
            title: " Patient Name",
            dataIndex: "name",

        },
        {
            title: "Gender",
            dataIndex: "gender",

        },
        {
            title: "Email",
            dataIndex: "email",

        },
        {
            title: "Phone No",
            dataIndex: "phoneNo",

        },
        {
            title: "BirthDate",
            dataIndex: "birthDate",
            render: (text) => { return (<div>{(text && moment(text).format('YYYY-MM-DD') || '---')}</div>) },

        },
        {
            title: "Registration No",
            dataIndex: "registrationNo",

        },
        {
            title: "Old Case Paper No",
            dataIndex: "oldCasePaperNo",

        },

    ]
    const checkIncolumns = [
        {
            title: "Name",
            dataIndex: "customerName",
        },
        {
            title: "Room Type",
            dataIndex: "roomType",
        },
        {
            title: "Room No.",
            dataIndex: "roomId",
            render: (text) => text?.roomNo,
        },
        {
            title: "Phone",
            dataIndex: "customerId",
            render: (text) => text?.phoneNo,
        },
        {
            title: "Is Shared",
            dataIndex: "isShared",
            render: (text, record) => {
                if (record.isShared) {
                    return <Check color="green" size={24} />;
                } else {
                    return <X color="red" size={24} />;
                }
            },
        },
        {
            title: "Is Private",
            dataIndex: "isPrivate",
            render: (text, record) => {
                if (record.isPrivate) {
                    return <Check color="green" size={24} />;
                } else {
                    return <X color="red" size={24} />;
                }
            },
        },
        {
            title: "Check In",
            dataIndex: "checkIn",
            render: (text, record) => {
                const checkInDate = new Date(record?.checkInDate);
                return checkInDate.toISOString().split('T')[0];
            },
        },
        {
            title: "Check Out",
            dataIndex: "checkOut",
            render: (text, record) => {
                const checkOutDate = new Date(record?.checkOut);
                return checkOutDate.toISOString().split('T')[0];
            },
        },
    ]
    const inHouseData = [
        ...getDashboardData?.inHouseCounts?.[0]?.service || [],
        ...getDashboardData?.inHouseCounts?.[0]?.treatment || [],
        ...getDashboardData?.inHouseCounts?.[0]?.solution || [],
        ...getDashboardData?.inHouseCounts?.[0]?.programme || [],
        ...getDashboardData?.inHouseCounts?.[0]?.retreat || [],

    ];
    const inHouseCounts = [
        {
            title: "Patient Name",
            dataIndex: "customerName",
        },
        {
            title: "Gender",
            dataIndex: "customerId",
            render: (text) => text?.gender
        },
        {
            title: "Phone",
            dataIndex: "phoneNo",

        },
        {
            title: "Check In",
            dataIndex: "startDate",

        },
        {
            title: "Check Out",
            dataIndex: "endDate",

        },
        {
            title: "Treatment",
            dataIndex: "treatmentName",
            render: (text, record, index) => {
                return inHouseData[index]?.treatmentData?.[0]?.treatmentName || "-"
            },
        },
        {
            title: "Detox-Programme",
            dataIndex: "solutionName",
            render: (text, record, index) => {
                return inHouseData[index]?.solutionData?.[0]?.solutionName || "-"
            },
        },
        {
            title: "Medi Day-care",
            dataIndex: "serviceName",
            render: (text, record, index) => {
                return inHouseData[index]?.serviceData?.map((service) => service?.name).join(",") || "-"
            },
        },
        {
            title: "Programme",
            dataIndex: "programmeName",
            render: (text, record, index) => {
                return inHouseData[index]?.programmeData?.[0]?.programmeName || "-"
            },
        },
        {
            title: "Retreat",
            dataIndex: "detoxRetreatName",
            render: (text, record, index) => {
                return inHouseData[index]?.detoxRetreatData?.[0]?.detoxRetreatName || "-"
            },
        },
        {
            title: "Doctor Name",
            dataIndex: "doctorName",

        },

    ]
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div className="page-wrapper">
                    <div className="p-4 mt-3">
                        <div className="row justify-content-between align-items-center mb-3" >
                            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search here"
                                />
                            </div>
                            {/* </div> */}
                        </div>
                        <div className="row ">
                            {getDashboardData?.counts?.map((item, index) => {
                                return (
                                    <React.Fragment key={`${item.name}-${index}`}>
                                        <DashboardCard
                                            Title={'Appointments'}
                                            key={index}
                                            delay={0.4}
                                            duration={0.6}
                                            Counts={item?.appointment} />
                                        <DashboardCard
                                            delay={0.4}
                                            duration={0.6}
                                            Title={'Detox Retreat'}
                                            key={index}
                                            Counts={item?.detoxRetreat} />
                                        <DashboardCard
                                            Title={'Revenue'}
                                            key={index}
                                            delay={0.4}
                                            duration={0.6}
                                            Counts={item?.revenue} />
                                        <DashboardCard
                                            Title={'Medi Day Care Rituals'}
                                            delay={0.4}
                                            duration={0.6}
                                            key={index}
                                            Counts={item?.service} />
                                        <DashboardCard
                                            Title={'Detox Program'}
                                            delay={0.4}
                                            duration={0.6}
                                            key={index}
                                            Counts={item?.solution} />
                                        <DashboardCard
                                            Title={'Treatment'}
                                            delay={0.4}
                                            duration={0.6}
                                            key={index}
                                            Counts={item?.treatment} />
                                    </React.Fragment>
                                )
                            })}

                        </div>
                        <ActivityChart />

                        <div className="row mt-4">
                            <div className="col-12 col-md-12  col-xl-4 ">
                                <div className="card h-100 mb-3">
                                    <CommonHeadingTxt Text="Available Doctor List" />
                                    <CustomTable
                                        column={column}
                                        dataSource={getDashboardData?.availableDoctorList}
                                        CurrentPageNum={1}

                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12  col-xl-8 ">
                                <div className="card h-100 mb-3">
                                    <CommonHeadingTxt Text="Enroll Patient List" />
                                    <CustomTable
                                        column={enrollPatient}
                                        dataSource={getDashboardData?.enrollPatients}
                                        CurrentPageNum={1}
                                        showPagination={false}
                                    />
                                </div>
                            </div>
                            {/* <DashboardChart /> */}
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 col-md-12  col-xl-6 ">
                                <div className="card h-100 mb-3">
                                    <CommonHeadingTxt Text="Available Therapist List" />
                                    <CustomTable
                                        column={availableTherapistColumn}
                                        dataSource={getDashboardData?.availableTherapistList}
                                        CurrentPageNum={1}
                                        showPagination={false}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12  col-xl-6 ">
                                <div className="card h-100 mb-3">
                                    <CommonHeadingTxt Text="Therapist Schedule" />
                                    <CustomTable
                                        column={therapistSchedule}
                                        dataSource={getDashboardData?.therapistSchedule}
                                        CurrentPageNum={1}
                                        showPagination={false}
                                    />
                                </div>
                            </div>
                            {/* <DashboardChart /> */}
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 col-md-12  col-xl-7 ">
                                <div className="card h-100 mb-3">
                                    <CommonHeadingTxt Text="Doctor On leave Count" />
                                    <CustomTable
                                        column={doctorLeaveColumn}
                                        dataSource={getDashboardData?.doctorsOnLeaveCount}
                                        CurrentPageNum={1}

                                    />
                                </div>
                            </div>

                            <div className="col-12 col-md-12  col-xl-5 d-flex " style={{ alignItems: "flex-end", }}>
                                <div className="card ">
                                    <div className="card-body ">
                                        <div className="chart-title">
                                            <h4>Payment Type</h4>
                                        </div>
                                        <div id="donut-chart-dash" className="chart-user-icon">
                                            <DonutChart
                                                series={seriesData}
                                                lables={lablesData}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <DashboardChart /> */}
                        </div>
                        <div className="row mt-4">
                            <div className="col-12  ">
                                <div className="card h-100 mb-3">
                                    <CommonHeadingTxt Text="Today’s Check In" />
                                    <CustomTable
                                        column={checkIncolumns}
                                        dataSource={getDashboardData?.checkIns}
                                        CurrentPageNum={1}

                                    />
                                </div>
                            </div>
                            <div className="col-12 mt-4 ">
                                <div className="card h-100 mb-3">
                                    <CommonHeadingTxt Text="In House Count" />
                                    <CustomTable
                                        column={inHouseCounts}
                                        dataSource={inHouseData}
                                        CurrentPageNum={1}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        </>

    )
};

export default Dashboard;
