/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import { Button } from "antd";
import { AddRolesValidationSchema } from "../../../utils/FormValidation";
import { Form, Formik } from "formik";
import { FormInput } from "../../../components/Input/InputText";
import PermissionsTable from "../PermissionsTable";
import { ActionCreateRoles, GetRoleListWithPaginationAction } from "../../../store/Actions/RolesAction";
import { useDispatch, useSelector } from "react-redux";
import { GetPermissionListAction } from "../../../store/Actions/PermissionsAction";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
    plusicon,
    refreshicon
} from "../../../components/imagepath";
import { ActionUpdateById } from "../../../store/Actions/CommonAction";
import { matchPermissions } from "../../../utils/Permission";





const CreateRoles = () => {
    const dispatch = useDispatch();
    const { PermissionsList, loader } = useSelector(state => state?.Permission);
    const { RoleList_Pagination } = useSelector(state => state?.Role);
    const { userDetails } = useSelector(state => state?.auth);
    const [Refresh, setRefresh] = useState(false);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const pageValue = searchParams.get("page");
    const updateValue = searchParams.get("update");
    const idValue = searchParams.get("id");
    useEffect(() => {
        dispatch(GetPermissionListAction({ Query: '' }))
        if (updateValue && idValue != null) {
            dispatch(GetRoleListWithPaginationAction({ Query: '/' + idValue }))
        }
        setRefresh(false)
    }, [Refresh])
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('role.update');
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/roles">Roles</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <Link to="/roles">Roles list</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Roles</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Add Roles</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">
                                                                    <Link
                                                                        to="/roles/create-roles"
                                                                        className="btn btn-primary add-pluss ms-2"
                                                                    >
                                                                        <img src={plusicon} alt="#" />
                                                                    </Link>
                                                                    <Link
                                                                        onClick={() => window.location.reload()}
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card p-4">
                                                        <div className="card-body">
                                                            <Formik
                                                                enableReinitialize={updateValue || false}
                                                                initialValues={{
                                                                    roleName: RoleList_Pagination?.[0]?.roleName || "",
                                                                    description: RoleList_Pagination?.[0]?.description || "",
                                                                    permission: RoleList_Pagination?.[0]?.permission || [],
                                                                }}
                                                                validationSchema={AddRolesValidationSchema}
                                                                onSubmit={(values, { setErrors, setSubmitting }) => {
                                                                    if (updateValue && idValue != null) {
                                                                        dispatch(ActionUpdateById({ Query: `admin/roles/${idValue}`, obj: values }
                                                                            , (Res) => {
                                                                                if (Res?.statusCode === 200) {
                                                                                    window.location.href = `/roles`
                                                                                } else if (Res?.statusCode === 400) {
                                                                                    setErrors(Res?.data?.errors);
                                                                                }
                                                                            }));
                                                                        setSubmitting(false);
                                                                    } else {

                                                                        dispatch(ActionCreateRoles({ Query: '', obj: values }
                                                                            , (Res) => {
                                                                                if (Res?.statusCode === 200) {
                                                                                    window.location.href = `/roles`
                                                                                } else if (Res?.statusCode === 400) {
                                                                                    setErrors(Res?.data?.errors);
                                                                                }
                                                                            }));
                                                                        setSubmitting(false);
                                                                    }
                                                                }}
                                                            >
                                                                {({
                                                                    values,
                                                                    errors,
                                                                    touched,
                                                                    setFieldValue,
                                                                    setFieldTouched,
                                                                    handleChange,
                                                                    handleBlur,
                                                                    handleSubmit,
                                                                    setValues,
                                                                    setSubmitting,
                                                                    isSubmitting,
                                                                    /* and other goodies */
                                                                }) => {
                                                                    return (
                                                                        <Form>
                                                                            <FormInput
                                                                                label="Role Name"
                                                                                type="text"
                                                                                FormGroupClassName={"col-xl-6 col-md-12"}
                                                                                LabelClassName={"col-form-label"}
                                                                                name="roleName"
                                                                                onChange={handleChange}
                                                                                FormControlClassName={'form-control '}
                                                                                onBlur={handleBlur}
                                                                                error={errors.roleName}
                                                                                value={values.roleName}
                                                                                touched={touched.roleName}
                                                                                placeholder={'Enter Role Name'}
                                                                            />
                                                                            <FormInput
                                                                                label="Description"
                                                                                type="text"
                                                                                FormGroupClassName={"col-xl-6 col-md-12"}
                                                                                LabelClassName={"col-form-label"}
                                                                                name="description"
                                                                                onChange={handleChange}
                                                                                FormControlClassName={'form-control '}
                                                                                onBlur={handleBlur}
                                                                                error={errors.description}
                                                                                value={values.description}
                                                                                touched={touched.description}
                                                                                placeholder={'Enter Role Name'}
                                                                            />
                                                                            <div className="table-responsive">
                                                                                <PermissionsTable
                                                                                    data={PermissionsList?.[0] ? PermissionsList?.[0] : []}
                                                                                    value={values?.permission}
                                                                                    OnChange={(v) => setFieldValue('permission', v, true)}
                                                                                    error={errors.permission}
                                                                                    touched={true}

                                                                                />
                                                                                <div className="col-12 mt-5">
                                                                                    <div className="text-end">
                                                                                        <Button
                                                                                            onClick={() => {
                                                                                                handleSubmit()
                                                                                            }}
                                                                                            type="primary"
                                                                                            // disabled={isSubmitting}
                                                                                            className="me-2"
                                                                                        >
                                                                                            Submit
                                                                                        </Button>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Form>)
                                                                }}
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>

    )
};

export default CreateRoles;
