import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateQueryString } from '../../utils/Funcations';
import queryString from 'query-string';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { GetAllProgramme } from '../../store/Actions/ServiceAction';
import { getAdminuUerDetails } from '../../store/Actions/AuthActions';
import { matchPermissions } from '../../utils/Permission';
import { TableListPageCom } from '../../components/TableListPageCom';
import { Button, Modal, Popover } from 'antd';
import { ActionDeleteById } from '../../store/Actions/CommonAction';
import { DOCS_URL } from '../../utils/globals';

const ProgrammeList = () => {
    const { confirm } = Modal;

    const pageSize = 10;
    // const Size = ResponsiveComponent()
    const dispatch = useDispatch();
    const { programmeList, loader } = useSelector(state => state?.Service);

    const { userDetails } = useSelector(state => state?.auth);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const page = searchParams.get("page");
    const userId = userDetails?.[0]?._id;

    const ProgrammeName = searchParams.get("ProgrammeName");
    // const sorting = 'createdAt:-1';
    const Final = generateQueryString(['page', page || 1],
        ['name', ProgrammeName],
    );
    const params = queryString.parse(location.search);
    useEffect(() => {
        dispatch(GetAllProgramme({ Query: Final, }));
    }, [])
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('programme.update');
    const canDelete = matchPermissionsForUser('programme.delete');
    const content = (item) => (
        <div className="row col">
            {canUpdate &&
                <Button className="col m-2" onClick={() => { window.location.href = `/add-programme?update=true&&page=${1}&&id=${item?.id}` }} >Edit Programme</Button>
            }
            {canDelete &&
                <Button className="col m-2" onClick={() => showDeleteConfirm(item)}>Delete Programme</Button>
            }
        </div>
    );

    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.name?.en} programme?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteById({ Query: `admin/programme/${item?._id}` }, (Res) => {
                    if (Res?.statusCode === 200) {
                        //setRefresh(true)
                        window.location.reload()
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Programme Name",
            dataIndex: ["name", "en"],
            render: (text) => <span>{text}</span>,
        },
        {
            title: "Programme Descriptions",
            dataIndex: ["shortDescription", "en"],
            render: (text) => <span>{text}</span>,
        },
        {
            title: "Per day Price",
            dataIndex: "perDayPrice",

        },
        {
            title: "Programme rating",
            dataIndex: "rating",
            //...TableColumnSearch({ dataIndex: 'name', setSearchText, setSearchedColumn, handleReset, handleSearch }),
            //sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Image",
            dataIndex: "listingViewImage",
            render: (text, record) => (
                <img src={DOCS_URL + record.listingViewImage} alt={record.name} style={{ width: 60, height: 60 }} />
            ),
        },
        {
            title: !canUpdate && !canDelete ? null : "Action",
            dataIndex: "roleName",
            render: (text, record) => {
                if (!canUpdate && !canDelete) {
                    return null
                } else {
                    return <Popover placement="top" title={text} content={() => content(record)} >
                        <Button type="text">• • •</Button>
                    </Popover>
                }
            },
        },
    ]
    const BreadcrumbList = [{
        name: 'Programme',
        linkTo: `/programme-list?page=${params?.page || 1}`
    },
    {
        name: 'Programme List',
    }
    ]
    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={programmeList?.[0]?.docs}
            TableColumn={columns}
            ShowAddButton={true}
            Iscreate="programme.create"
            CreateButtonLink={`/add-programme?update=false&&page=${params?.page || 1}`}
            PageMainTitle="Programme List"
            CurrentPage={programmeList?.[0]?.page || 1}
            Tableloader={loader}
            TotalPageList={programmeList?.[0]?.totalDocs}
            dataSourceLength={programmeList?.[0]?.docs?.length}
            handlePageChange={(v) => {
                var newp = params
                newp.page = v
                window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
            }}
        />
    )
}

export default ProgrammeList