import {
    CREATE_PATIENT_TESTIMONIAL,
    CREATE_PATIENT_TESTIMONIAL_SUCCESS,
    CREATE_TESTIMONIAL_LINK,
    CREATE_TESTIMONIAL_LINK_SUCCESS,
    GET_ALL_PATIENT_TESTIMONIAL,
    GET_ALL_PATIENT_TESTIMONIAL_SUCCESS,
    GET_ALL_TESTIMONIAL_LINK,
    GET_ALL_TESTIMONIAL_LINK_SUCCESS,
} from "../storeTypes";

export const CreateTestimonialLink = (payload, callBack) => {
    return {
        type: CREATE_TESTIMONIAL_LINK,
        payload,
        callBack
    };
};
export const CreateTestimonialLinkSuccess = (payload) => {
    return {
        type: CREATE_TESTIMONIAL_LINK_SUCCESS,
        payload
    };
};
export const GetAllTestimonialLink = (payload) => {
    return {
        type: GET_ALL_TESTIMONIAL_LINK,
        payload,
    };
};
export const GetAllTestimonialLinkSuccess = (payload) => {
    return {
        type: GET_ALL_TESTIMONIAL_LINK_SUCCESS,
        payload
    };
};

// ----------------------------------PATIENT TESTIMONIAL-------------------------------------------------------------

export const CreatePatientTestimonial = (payload, callBack) => {
    return {
        type: CREATE_PATIENT_TESTIMONIAL,
        payload,
        callBack
    };
};
export const CreatePatientTestimonialSuccess = (payload) => {
    return {
        type: CREATE_PATIENT_TESTIMONIAL_SUCCESS,
        payload
    };
};
export const GetAllPatientTestimonial = (payload) => {
    return {
        type: GET_ALL_PATIENT_TESTIMONIAL,
        payload,
    };
};
export const GetAllPatientTestimonialSuccess = (payload) => {
    return {
        type: GET_ALL_PATIENT_TESTIMONIAL_SUCCESS,
        payload
    };
};