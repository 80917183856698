import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { PrakurtiLogoWhite } from "../../components/imagepath";
import { Formik } from "formik";
import { ActionCreateByUrl } from "../../store/Actions/CommonAction";
import { InputFromUser } from "../../components/Input/input";
import { ResetPasswordSchema } from "../../utils/FormValidation";

const ResetPassword = () => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  const Token = searchParams.get("token");
  const dispatch = useDispatch();
  return (
    <>
      {/* Main Wrapper */}
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">

              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/admin-dashboard">
                          <img src={PrakurtiLogoWhite} alt="#" />
                        </Link>
                      </div>
                      <h2>Reset Password</h2>
                      <Formik
                        initialValues={{ password: '', confirmPassword: '' }}
                        validationSchema={ResetPasswordSchema}
                        onSubmit={(values, { setErrors, setSubmitting }) => {
                          dispatch(ActionCreateByUrl({
                            Query: `adminUser/reset_password?token=${Token}`, obj: {
                              'password': values?.password
                            }
                          }, (response) => {
                            if (response?.statusCode == 400) {
                              setErrors(response.data.errors);
                            } else if (response?.statusCode == 401) {
                              setErrors(response.data.errors);
                            } else if (response?.statusCode == 200) {
                              window.location.href = "/"
                            }
                          }))
                          setSubmitting(false)
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <InputFromUser
                              label="Password"
                              name="password"
                              type="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.password}
                              value={values.password}
                              touched={touched.password}
                              placeholder={'Enter Your Password'}
                            />
                            <InputFromUser
                              label="Confirm Password"
                              name="confirmPassword"
                              type="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.confirmPassword}
                              value={values.confirmPassword}
                              touched={touched.confirmPassword}
                              placeholder={'Enter Your confirmPassword'}
                            />

                            <div className="form-group login-btn" >
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                disabled={isSubmitting}
                              >
                                Reset Password
                              </button>
                            </div>

                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>
      </div>
    </>
  );
};


export default ResetPassword;
