import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ActionGetProgrammeBooking, ActionUpdateProgrammeSlot } from '../../../store/Actions/ServiceAction';
import { TableListPageCom } from '../../../components/TableListPageCom';
import { generateQueryString } from '../../../utils/Funcations';
import TableColumnSearch1 from "../../../components/Input/TableColumnSearch1";
import { Button, DatePicker } from 'antd';
import { InputFromUser } from '../../../components/Input/input';
import { matchPermissions } from '../../../utils/Permission';

const ProgramSlotBooking = () => {
    const pageSize = 10;
    const dispatch = useDispatch();
    const { programmeBooking, loader } = useSelector(state => state?.Service);
    const { userDetails } = useSelector(state => state?.auth);
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('programme-slot.update');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortOn, setSortOn] = useState('date');
    const [currentView, setCurrentView] = useState(localStorage.getItem('currentView'));
    const [Refresh, setRefresh] = useState(false);

    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const location = useLocation();
    const pageParams = searchParams.get("page");
    const customerNameParams = searchParams.get("customerName");
    const programmeNameParams = searchParams.get("programmeName");
    const params = queryString.parse(location.search);
    const [Query, setQuery] = useState({
        'page': pageParams,
        'programmeName': programmeNameParams,
        'customerName': customerNameParams,
        'startDate': params.startDate || '',
        'endDate': params.endDate || '',
    },);
    const Filters = generateQueryString(
        ['page', (Query.page || 1)],
        ['programmeName', (Query.programmeName)],
        ['customerName', Query.customerName],
        ['startDate', Query.startDate],
        ['endDate', Query.endDate],
        ['sort', sortOrder],
        ['sortOn', sortOn],
        ['status', currentView],
    )
    const handleResetFilters = () => {
        setQuery({
            ...Query,
            startDate: '',
            endDate: '',
        });
    };
    useEffect(() => {
        dispatch(ActionGetProgrammeBooking({ Query: Filters, }));
        window.history.replaceState(null, '', Filters);
    }, [Filters, Query.startDate, Query.endDate, Refresh])

    const handleViewChange = (view) => {
        setCurrentView(view);
        localStorage.setItem('currentView', view)
        //   setSortOrder(view === 'Upcoming' ? 'asc' : 'desc');
        //   setSortOn(view === 'Upcoming' ? 'date' : 'date');
        // };

        switch (view) {
            case 'Upcoming':
                setSortOrder('asc');
                setSortOn('date');
                break;
            case 'Completed':
                setSortOrder('desc');
                setSortOn('date');
                break;
            case 'Cancelled':
            case 'InProgress':
                setSortOrder('asc');
                setSortOn('date');
                break;
            default:
                setSortOrder('asc');
                setSortOn('date');
                break;
        }
    };
    const handleStatusUpdate = (recordId, newStatus) => {
        dispatch(ActionUpdateProgrammeSlot({
            Query: recordId, obj: {
                "status": newStatus,
            }

        }, () => setRefresh(true)));
    };
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            key: "Sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Customer Name",
            dataIndex: "customerName",
            render: (text, record) => (
                <Link to={`/order-history-overview/${record?.orderId}`}>
                    {record?.customerName}
                </Link>
            ),
            ...TableColumnSearch1({
                dataIndex: 'customerName',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
        },
        {
            title: "Phone No.",
            dataIndex: "phoneNo",
        },
        {
            title: "Programme Name",
            dataIndex: "programmeData",
            render: (text, record) => (
                <span>{record?.programmeData?.[0].programmeName}</span>
            ),

        },
        {
            title: "Total Days",
            dataIndex: "totalDays",
        },
        {
            title: "Date",
            dataIndex: "dateRange",
            render: (text, record) => {
                const startDate = record.startDate;
                const endDate = record.endDate;
                const formattedDateRange = `${startDate} To ${endDate}`;
                return <span>{formattedDateRange}</span>;
            }
        },
        {
            title: "Care Taker",
            dataIndex: "careTakerCount",
        },

        {
            title: "Primary Guest",
            dataIndex: "parentCustomerName",
            render: (text) => (
                <span>{text || '---'}</span>
            ),
        },
        {
            title: 'Update Programme Status',
            dataIndex: 'status',
            render: (text, record) => (
                <>
                    {canUpdate &&
                        <div style={{ marginTop: "-5" }}>

                            <InputFromUser
                                placeholder={""}
                                type="singleSelect"
                                name="status"
                                mode={''}
                                onChange={(newStatus) => handleStatusUpdate(record.id, newStatus)}
                                options={["Upcoming", "Completed", "Cancelled", "InProgress"]}
                                FormGroupClassName={"col-xl-8 col-md-12 cursor-pointer"}
                                LabelClassName={"col-form-label cursor-pointer"}
                                value={record.status}
                                disabled={record?.status === "Completed"}
                                label=""
                            />
                        </div>
                    }
                </>
            ),
        },

    ];
    const BreadcrumbList = [{
        name: 'Programme',
        linkTo: `/programme-booking`
    },
    {
        name: 'Programme-Booking',
    }
    ]
    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            LeftChild={
                <div className="col-12 col-lg-6 col-xl-6 mt-3 mb-md-0" style={{ display: 'flex', alignItems: 'center' }}>
                    <DatePicker.RangePicker
                        onChange={(dates) => {
                            setQuery({
                                ...Query,
                                startDate: dates[0]?.format('YYYY-MM-DD') || '',
                                endDate: dates[1]?.format('YYYY-MM-DD') || '',
                            });
                        }} className="w-100"
                        allowClear={false}
                    />
                    <Button type="link" onClick={handleResetFilters} style={{ marginLeft: '10px' }}>Clear Filters</Button>
                </div>}
            RightChild={
                <div className={`dropdown-color col-12 col-md-4 ${currentView === 'Completed' ? 'Completed' : currentView === 'Cancelled' ? 'Cancelled' : 'Upcoming'}`}>

                    {/* <span className='text-capitalize' style={{ fontWeight: "600" }}>Service Booking Status</span> */}
                    <div style={{ marginTop: "-5", marginBottom: "-5" }}>
                        <InputFromUser
                            placeholder={""}
                            type="singleSelect"
                            name="status"
                            mode={''}
                            onChange={handleViewChange}
                            options={["Upcoming", "Completed", "Cancelled"]}
                            FormGroupClassName={"col-xl-6 col-md-12 cursor-pointer m-0 p-0"}
                            LabelClassName={"col-form-label cursor-pointer"}
                            value={currentView}
                            label="Booking Status"
                        />
                    </div>
                </div>
            }
            TableData={programmeBooking?.[0]?.docs}
            TableColumn={columns}
            ShowAddButton={false}
            PageMainTitle="Programme Booking"
            CurrentPage={parseInt(params?.page) || 1}
            Tableloader={loader}
            TotalPageList={programmeBooking?.[0]?.totalDocs}
            dataSourceLength={programmeBooking?.[0]?.docs?.length}
            handlePageChange={(v) => {
                var newp = params
                newp.page = v
                window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
            }}
        />
    )
}

export default ProgramSlotBooking