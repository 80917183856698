import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Sidebar from "../../../components/Sidebar";
import { settingicon01, } from "../../../components/imagepath";
import CountUp from "react-countup";
import Header from "../../../components/Header";
import { CommonHeadingTxt } from "../../../components/Text/Text";
import { CustomTable } from "../../../components/Table";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ActionGetRoomDashboardData } from "../../../store/Actions/MasterDataAction";
import { Check, X } from "react-feather";
import { Button, DatePicker, Spin } from "antd";
import { generateQueryString } from "../../../utils/Funcations";

const RoomDashboard = () => {
    const { roomDashboard, loader } = useSelector(state => state.MasterData);
    const dispatch = useDispatch();
    const [Refresh, setRefresh] = useState(false);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const startDateParams = searchParams.get("startDate");
    const endDateParams = searchParams.get("endDate");

    const [Query, setQuery] = useState({
        'startDate': startDateParams,
        'endDate': endDateParams,
    },);

    const Filters = generateQueryString(
        ['startDate', Query.startDate],
        ['endDate', Query.endDate],
    )
    useEffect(() => {
        dispatch(ActionGetRoomDashboardData({ Query: Filters }));
        window.history.replaceState(null, '', Filters);
        setRefresh(false);
    }, [Refresh, Filters])

    const handleDateRangeChange = (dates) => {
        setQuery({
            ...Query,
            startDate: dates[0]?.format('YYYY-MM-DD'),
            endDate: dates[1]?.format('YYYY-MM-DD'),

        })
    };
    const RoomCheckInData = roomDashboard?.[0]?.checkIns;
    const RoomCheckOutData = roomDashboard?.[0]?.checkOut;
    const TotalPrivateCount = roomDashboard?.[0]?.privateRoomSlot?.length;
    const TotalSharedCount = roomDashboard?.[0]?.sharedRoomSlot?.length;

    const columns = [
        {
            title: "Name",
            dataIndex: "customerId",
            render: (text) => text?.name,

        },
        {
            title: "Room Type",
            dataIndex: "roomId",
            render: (text) => text?.roomType,

        },
        {
            title: "Room No.",
            dataIndex: "roomId",
            render: (text) => text?.roomNo,

        },
        {
            title: "Phone",
            dataIndex: "customerId",
            render: (text) => text?.phoneNo,

        },
        {
            title: "Is Shared",
            dataIndex: "isShared",
            render: (text, record) => {
                if (record.isShared) {
                    return <Check color="green" size={24} />;
                } else {
                    return <X color="red" size={24} />;
                }
            },
        },
        {
            title: "Is Private",
            dataIndex: "isPrivate",
            render: (text, record) => {
                if (record.isPrivate) {
                    return <Check color="green" size={24} />;
                } else {
                    return <X color="red" size={24} />;
                }
            },
        },
        {
            title: "Check In",
            dataIndex: "checkIn",
            render: (text, record) => {
                const checkInDate = new Date(record?.checkInDate);
                return checkInDate.toISOString().split('T')[0];
            },

        },
        {
            title: "Check Out",
            dataIndex: "checkOut",
            render: (text, record) => {
                const checkOutDate = new Date(record?.checkOut);
                return checkOutDate.toISOString().split('T')[0];
            },
        },

    ]

    return (
        <>
            <Header />
            <Sidebar />
            <div className="room-dashoard-home">
                <div className={`page-wrapper ${loader ? 'loader-active' : ''}`}>
                    {loader && <div className="loader-overlay"></div>}
                    <div className="p-4 mt-3">
                        <div className="row justify-content-between align-items-center mb-3" >
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-6 col-xl-6 mb-3 mb-md-0">
                                    <DatePicker.RangePicker
                                        onChange={handleDateRangeChange}
                                        placeholder={['Check-in Date', 'Check-out Date']}
                                        style={{ width: '100%' }}
                                        allowClear={false}
                                    />
                                </div>

                                <div className="col-12 col-lg-2 col-xl-3">
                                    <Button type="link" onClick={() => setQuery({
                                        ...Query,
                                        startDate: null,
                                        endDate: null
                                    })}>Clear Filters</Button>
                                </div>
                                {/* {loader && <div className="col-12 col-lg-3 col-xl-3"><Spin /></div>} */}

                            </div>
                            {/* </div> */}
                        </div>
                        <div className="row ">

                            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4" >
                                <div className="dash-widget">
                                    <div className="dash-boxs comman-flex-center">
                                        <img src={settingicon01} alt="#" />
                                    </div>
                                    <div className="dash-content dash-count flex-grow-1">
                                        <h4>Total Rooms Available</h4>
                                        <h2 className="row">
                                            {" "}
                                            <CountUp delay={0.4} className="col" end={roomDashboard?.[0]?.totalCountRoom} duration={0.6} />
                                        </h2>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4" >
                                <div className="dash-widget">
                                    <div className="dash-boxs comman-flex-center">
                                        <img src={settingicon01} alt="#" />
                                    </div>
                                    <div className="dash-content dash-count flex-grow-1">
                                        <h4>Private Rooms Booked</h4>
                                        <h2 className="row">
                                            {" "}
                                            <CountUp delay={0.4} className="col" end={TotalPrivateCount} duration={0.6} />
                                        </h2>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4" >
                                <div className="dash-widget">
                                    <div className="dash-boxs comman-flex-center">
                                        <img src={settingicon01} alt="#" />
                                    </div>
                                    <div className="dash-content dash-count flex-grow-1">
                                        <h4>Shared Rooms Booked</h4>
                                        <h2 className="row">
                                            {" "}
                                            <CountUp delay={0.4} className="col" end={TotalSharedCount} duration={0.6} />
                                        </h2>

                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-12 col-md-12 col-lg-6 col-xl-3 d-flex " style={{ alignItems: "flex-end", }}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="chart-title">
                                            <h4>Room Bookings</h4>
                                        </div>
                                        <div id="donut-chart-dash" className="chart-user-icon">
                                            <DonutChart />
                                            <b> 
                                        <img src={settingicon01} alt="" />
                                            <h2 className="align-self-center display-flex">hii</h2>
                                             </b> 
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div className="row">
                            {roomDashboard?.[0]?.groupByRoomCount?.map((item, index) => (
                                <div key={index} className="col-md-6 col-sm-6 col-lg-6 col-xl-4" >
                                    <Link to={`/room-individual-data/${item._id}?startDate=${Query?.startDate}&endDate=${Query?.endDate}`} style={{ textDecoration: 'none' }}>
                                        <div className="dash-widget" style={{ border: "1px solid #2e37a4", cursor: "pointer" }} >
                                            <div className="d-flex justify-content-between">
                                                <div className="dash-content dash-count">
                                                    <h5>{item._id}</h5>
                                                    <h5>
                                                        <CountUp delay={0.4} end={item.totalCount} duration={0.6} />
                                                        <span>/{item.totalCapacity}</span>
                                                    </h5>
                                                </div>
                                                <div>
                                                    {item.genderCounts && (
                                                        <div>
                                                            <h5> Male: {item.genderCounts[0]?.Male || 0}</h5>
                                                            <h5> Female: {item.genderCounts[0]?.Female || 0}</h5>
                                                            <h5> Other: {item.genderCounts[0]?.Other || 0}</h5>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h4 className="text-success">check In: {item.checkInCount}</h4>
                                                </div>
                                                <div>
                                                    <h4 className="text-danger">check Out: {item.checkOutCount}</h4>
                                                </div>

                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-12  col-xl-12">
                                <div className="card h-100 mt-lg-0 mt-3" style={{ border: "1.5px solid green" }}>
                                    <CommonHeadingTxt Text="Today’s Check In" />
                                    <CustomTable
                                        column={columns}
                                        dataSource={RoomCheckInData}
                                        CurrentPageNum={1}
                                        defaultPageSize={20}
                                        border={true}

                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 col-md-12  col-xl-12">
                                <div className="card h-100 mt-lg-0 mt-3" style={{ border: "1.5px solid red" }}>
                                    <CommonHeadingTxt Text="Today’s Check Out" />
                                    <CustomTable
                                        column={columns}
                                        dataSource={RoomCheckOutData}
                                        CurrentPageNum={1}

                                    />
                                </div>
                            </div>
                        </div>
                        {loader && (
                            <div className="loader-container">
                                <Spin size="large" />
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </>

    )
};

export default RoomDashboard;
