/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from "react";



export const CommonHeadingTxt = ({
    Children,
    Text = '',
}) => {
    return (
        <>
            <div className="card-header align-items-center d-flex justify-content-between">
                <h4 className="card-title d-inline-block">
                    {Text}
                </h4>{" "}
                {Children}
            </div>
        </>)
}
CommonHeadingTxt.propTypes = {
    Children: PropTypes.node,
    Text: PropTypes.string,
};
