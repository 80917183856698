import { CREATE_FAQ, CREATE_FAQ_SUCCESS, GET_ALL_FAQ, GET_ALL_FAQ_SUCCESS } from "../storeTypes";


const INIT_STATE = {
    loader: false,
    Faqlist: {},

};

function faqReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case CREATE_FAQ: {
            return {
                ...state,
                loader: true,
            };
        }
        case CREATE_FAQ_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }
        case GET_ALL_FAQ: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_FAQ_SUCCESS: {
            return {
                ...state,
                loader: false,
                Faqlist: action?.payload
            };
        }

        default: {
            return state;
        }
    }

}

export default faqReducer;