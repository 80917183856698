import { call, takeEvery } from "redux-saga/effects";
import { UpdateByCustomerIdApi } from "../../utils/api";
import { UPDATE_PERSONAL_DETAILS } from "../storeTypes";

function* UpdatePersonalDetailsSaga(action) {
    try {
        const response = yield call(UpdateByCustomerIdApi, action?.payload);
        if (response?.status === 200) {
            action?.callBack(response);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log('UpdatePersonalDetailsSaga Error :- ', error);
    }
}

export function* UpdatePersonalDetailsWatchSaga() {
    [

        yield takeEvery(UPDATE_PERSONAL_DETAILS, UpdatePersonalDetailsSaga),
    ];
}