/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */

import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { Breadcrumb } from "../../../components/Card/Cards";
import { ActionGetRoomCount } from "../../../store/Actions/MasterDataAction";


const RoomCount = () => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    const currentDate = new Date();
    const dispatch = useDispatch();
    const roomCount = useSelector(state => state?.MasterData);
    const roomDetails = roomCount?.roomCount?.[0] || [];
    const { userDetails } = useSelector(state => state?.auth);
    const userId = userDetails?.[0]?._id || undefined;
    const ref = useRef();
    const [Refresh, SetRefresh] = useState(false);
    const [addneweventobj, setaddneweventobj] = useState(null);
    const [calendarEvents, setCalendarEvents] = useState([]);


    const backgroundColor = {
        "Royal Room": "#08A04B",
        "General Ward": "#FFA500",
        "Deluxe Room": "#15317E",
        "Semi Deluxe": "#F75D59",
        "Executive Room": "purple",
        "Basic Room": "#614051",

    };

    const Data = [
        {
            "bookings": [
                {
                    "startDate": "2024-01-26",
                    "endDate": "2024-01-28"
                },
                {
                    "startDate": "2024-01-26",
                    "endDate": "2024-01-27"
                }
            ],
            "totalRooms": 3,
            "_id": "General Ward"
        },
        // {
        //     "bookings": [
        //     ],
        //     "totalRooms": 4,
        //     "_id": "Royal Room"
        // },
        {
            "bookings": [
                {
                    "startDate": "2024-01-25",
                    "endDate": "2024-01-26"
                }
            ],
            "totalRooms": 2,
            "_id": "Deluxe Room"
        },
        {
            "bookings": [
                {
                    "startDate": "2024-01-31",
                    "endDate": "2024-02-02"
                },
                {
                    "startDate": "2024-01-26",
                    "endDate": "2024-01-27"
                }
            ],
            "totalRooms": 7,
            "_id": "Basic Room"
        },
        {
            "bookings": [
                {
                    "startDate": "2024-01-28",
                    "endDate": "2024-02-01"
                },
            ],
            "totalRooms": 6,
            "_id": "Semi Deluxe"
        },
        {
            "bookings": [
                {
                    "startDate": "2024-01-25",
                    "endDate": "2024-01-28"
                },
            ],
            "totalRooms": 2,
            "_id": "Executive Room"
        }
    ]

    const mapRoomsToEvents = () => {
        return Data.flatMap((room, index) => {
            const { _id, totalRooms, bookings } = room;
            const dates = bookings.map(range => ({
                startDate: new Date(range.startDate),
                endDate: new Date(range.endDate)
            }));
            // Find the maximum and minimum dates
            const maxDate = new Date(Math.max(...dates.map(date => date.endDate)));
            const minDate = new Date(Math.min(...dates.map(date => date.startDate)));
            return {
                id: `${_id}-booked-${index}`,
                title: `${_id} (Booked: ${bookings?.length}), [Available: ${totalRooms - bookings?.length}, Total: ${totalRooms}]`,
                start: minDate.toISOString().split('T')[0],
                end: maxDate.toISOString().split('T')[0],
                color: backgroundColor[_id] || "gray",
            }
        });
    };
    useEffect(() => {
        setCalendarEvents(mapRoomsToEvents());
    }, [roomDetails]);
    const calendarRef = useRef(null);
    useEffect(() => {
        let elements = Array.from(
            document.getElementsByClassName("react-datepicker-wrapper")
        );
        elements.map((element) => element.classList.add("width-100"));
    }, []);
    useEffect(() => {
        dispatch(ActionGetRoomCount())
        SetRefresh(false)
    }, [Refresh])
    const handleEventClick = (clickInfo) => {
        // console.log('Event clicked:', clickInfo);
    };

    const handleDateSelect = (selectInfo) => {
        // console.log('Date selected:', selectInfo);
    };

    return (
        <>
            <Header />
            <Sidebar />
            <div>
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <Breadcrumb page={[{
                            name: 'Master Data',
                            linkTo: '/room-count'
                        },
                        {
                            name: 'Room Count',
                            linkTo: '/room-count'
                        }
                        ]} />
                        {/* /Page Header */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card card-table show-entire">
                                    <div className="card-body">
                                        {/* Table Header */}
                                        <div className="page-table-header mb-2">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div id="calendar">
                                                                <FullCalendar
                                                                    ref={ref}
                                                                    plugins={[
                                                                        dayGridPlugin,
                                                                        timeGridPlugin,
                                                                        interactionPlugin,
                                                                    ]}
                                                                    headerToolbar={{
                                                                        left: "prev,next today",
                                                                        center: "title",
                                                                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                                                                    }}
                                                                    initialView="dayGridMonth"
                                                                    editable={true}
                                                                    selectable={true}
                                                                    selectMirror={true}
                                                                    events={calendarEvents}
                                                                    dayMaxEvents={true}
                                                                    weekends={true}
                                                                    select={handleDateSelect}
                                                                    eventClick={handleEventClick}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RoomCount;
