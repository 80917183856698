import { CREATE_SCHEDULE, GET_ALL_SCHEDULE_LIST, GET_ALL_SCHEDULE_LIST_SUCESS } from "../storeTypes";

const INIT_STATE = {
    loader: false,
    doctorScheduleList: []
};

function doctorReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case CREATE_SCHEDULE: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_SCHEDULE_LIST: {
            return {
                ...state,
                //doctorScheduleList: [],
                loader: true,
            };
        }
        case GET_ALL_SCHEDULE_LIST_SUCESS: {
            return {
                ...state,
                loader: false,
                doctorScheduleList: action?.payload
            };
        }

        default: {
            return state;
        }
    }
}

export default doctorReducer;
