import { call, put, takeEvery } from "redux-saga/effects";
import { GetAllMedicalDetailsApi, GetMedicalDetailByIdApi, UpdateMedicalDetailApi } from "../../utils/api";
import { ActionGetAllMedicalDetailSuccess, ActionGetMedicalDetailByIDSuccess } from "../Actions/MedicalStoreAction";
import { handleApiResponse } from "../../utils/Funcations";
import { GET_ALL_MEDICAL_DETAILS, GET_MEDICAL_DETAILS_BY_ID, UPDATE_MEDICAL_DETAILS } from "../storeTypes";

const File = "MedicalSaga"

function* GetAllMedicalDetailsSaga(action) {
    try {
        const response = yield call(GetAllMedicalDetailsApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetAllMedicalDetailSuccess(response?.data?.data[0]));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callback);
        console.log(File + "GetAllMedicalDetailsSaga", error);
    }
}

function* UpdateMedicalDetailsSaga(action) {
    try {
        const response = yield call(UpdateMedicalDetailApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        console.log(File + '/UpdateMedicalDetailsSaga Error:', error);

        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
    }
}

function* GetMedicalDetailsByIDSaga(action) {
    try {
        const response = yield call(GetMedicalDetailByIdApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetMedicalDetailByIDSuccess(response?.data?.data[0]));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File + '/GetMedicalDetailsByIDSaga Error:', error);
    }
}

export function* MedicalDetailsSaga() {
    yield takeEvery(GET_ALL_MEDICAL_DETAILS, GetAllMedicalDetailsSaga)
    yield takeEvery(UPDATE_MEDICAL_DETAILS, UpdateMedicalDetailsSaga)
    yield takeEvery(GET_MEDICAL_DETAILS_BY_ID, GetMedicalDetailsByIDSaga)
}