import React, { useEffect } from 'react'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminuUerDetails } from '../../store/Actions/AuthActions'
import { Formik } from 'formik'
import { Button, Divider } from 'antd'
import { InputFromUser, TextEditor } from '../../components/Input/input'
import UploadImage from '../../components/Upload/Upload'
import { DOCS_URL } from '../../utils/globals'
import { ActionCreateByUrl, ActionUpdateById, ActionUploadImage } from '../../store/Actions/CommonAction'
import { isNullOrUndef } from '../../utils/Funcations'
import { ExpendableTable } from '../../components/Table/expendableTable'
import { ProgrammeValidationSchema } from '../../utils/FormValidation'
import { GetAllProgramme } from '../../store/Actions/ServiceAction'

const AddEditProgramme = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const { userDetails } = useSelector(state => state?.auth);
    const { programmeList } = useSelector(state => state?.Service);
    const userId = userDetails?.[0]?._id;
    const idValue = searchParams.get("id");
    const updateValue = searchParams.get("update");
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])

    useEffect(() => {
        if (idValue) {
            dispatch(GetAllProgramme({ Query: '/' + idValue }));
        }
    }, [])
    return (
        <div>
            <Header />
            <Sidebar id="menu-item1" id1="menu-items1" activeClassName="add-staff" />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/programme-list?page=1">Programme </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Add Programme</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue || false}
                                                initialValues={{
                                                    name: programmeList?.[0]?.name || {
                                                        en: "",
                                                        hi: "",
                                                        mr: ""
                                                    },
                                                    description: programmeList?.[0]?.description || {
                                                        en: "",
                                                        hi: "",
                                                        mr: ""
                                                    },
                                                    shortDescription: programmeList?.[0]?.shortDescription || {
                                                        en: "",
                                                        hi: "",
                                                        mr: ""
                                                    },
                                                    rating: programmeList?.[0]?.rating || "",
                                                    successRate: programmeList?.[0]?.successRate || "",
                                                    mainTitle: programmeList?.[0]?.mainTitle || {
                                                        en: "",
                                                        hi: "",
                                                        mr: ""
                                                    },
                                                    perDayPrice: programmeList?.[0]?.perDayPrice || "",

                                                    price: programmeList?.[0]?.price || [""],
                                                    orderIndex: programmeList?.[0]?.orderIndex || "",
                                                    days: programmeList?.[0]?.days || [""],
                                                    facilities: programmeList?.[0]?.facilities || [''],
                                                    whoIsThisFor: programmeList?.[0]?.whoIsThisFor || [''],
                                                    programmeFaqs: programmeList?.[0]?.programmeFaqs || [{ "title": "", "description": "", "orderIndex": '' }],
                                                    whatWeDo: programmeList?.[0]?.whatWeDo || [{ "title": "", "description": "", "icon": '', "orderIndex": '' }],
                                                    yoga: programmeList?.[0]?.yoga || {
                                                        title: "",
                                                        value: programmeList?.[0]?.yoga?.value || [""]
                                                    },
                                                    yogaImage: programmeList?.[0]?.yogaImage || "",
                                                    benefitImage: programmeList?.[0]?.benefitImage || "",
                                                    benefits: programmeList?.[0]?.benefits || {
                                                        title: "",
                                                        value: programmeList?.[0]?.benefits?.value || ['']
                                                    },
                                                    chooseUs: programmeList?.[0]?.chooseUs || [{ "aspect": "", "otherHealthResort": '', "naturopathyCenters": '', "prakrutisAyurvedicTreatment": "", "orderIndex": "" }],
                                                    statics: programmeList?.[0]?.statics || [{ "data": '', "name": '' }],
                                                    afterCareoptions: programmeList?.[0]?.afterCareoptions || [{ "title": "", "description": "", "icon": '', "orderIndex": '' }],
                                                    afterCareTitle: programmeList?.[0]?.afterCareTitle || "",
                                                    afterCareImage: programmeList?.[0]?.afterCareImage || "",
                                                    listingViewImage: programmeList?.[0]?.listingViewImage || "",
                                                    videoIframe: programmeList?.[0]?.videoIframe?.[0] || [""],

                                                    createdBy: userId,
                                                    ourProcess: programmeList?.[0]?.ourProcess || [{ "mainTitle": "", "title": '', "description": "", "sideImage": '', "orderIndex": '' }],

                                                    dayLookTitle: programmeList?.[0]?.dayLookTitle || '',
                                                    dayLookDescription: programmeList?.[0]?.dayLookDescription || '',
                                                    dayLookMainImage: programmeList?.[0]?.dayLookMainImage || '',
                                                    dayLook: programmeList?.[0]?.dayLook || [{ "startTime": "", "endTime": '', "description": '', "orderIndex": '', "bannerImage": '' }],


                                                }}
                                                validationSchema={ProgrammeValidationSchema}
                                                onSubmit={(values, { setErrors, setSubmitting }) => {
                                                    const formattedValues = {
                                                        ...values,
                                                        videoIframe: values.videoIframe ? [values?.videoIframe] : []
                                                    };
                                                    if (updateValue && idValue != null) {

                                                        dispatch(ActionUpdateById({ Query: `admin/programme/${idValue}`, obj: formattedValues }
                                                            , (Res) => {
                                                                if (Res?.statusCode === 200) {
                                                                    window.location.href = `/programme-list?page=1`
                                                                } else if (Res?.statusCode === 400) {
                                                                    setErrors(Res?.data?.errors);
                                                                }
                                                            }));
                                                        setSubmitting(false);
                                                    } else {
                                                        //CreateTreatment
                                                        dispatch(ActionCreateByUrl({ Query: `admin/programme`, obj: formattedValues }
                                                            , (Response) => {
                                                                if (Response?.statusCode === 200) {
                                                                    window.location.href = "/programme-list?page=1"
                                                                }
                                                            }))
                                                        setSubmitting(false);
                                                    }
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    setFieldValue,
                                                    setFieldTouched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    // setValues,
                                                    // setSubmitting,
                                                    // isSubmitting,
                                                    /* and other goodies */

                                                }) => {
                                                    console.log('error', errors);
                                                    return (
                                                        <div>
                                                            <div className="row">
                                                                <div className="row">
                                                                    <Divider style={{ fontWeight: "600" }}>Programme</Divider></div>
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Your Programme Name"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Programme Name"
                                                                    name="name?.en"
                                                                    error={errors?.name?.en}
                                                                    touched={touched?.name?.en}
                                                                    value={values?.name?.en}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.name
                                                                        UpdateValue.en = v?.target?.value
                                                                        setFieldValue("name", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Marathi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Marathi"
                                                                    name="name?.mr"
                                                                    error={errors?.name?.mr}
                                                                    touched={touched?.name?.mr}
                                                                    value={values?.name?.mr}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.name
                                                                        UpdateValue.mr = v?.target?.value
                                                                        setFieldValue("name", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Hindi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Hindi"
                                                                    name="name?.hi"
                                                                    error={errors?.name?.hi}
                                                                    touched={touched?.name?.hi}
                                                                    value={values?.name?.hi}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.name
                                                                        UpdateValue.hi = v?.target?.value
                                                                        setFieldValue("name", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>


                                                            <div className="row mt-4">
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Your Programme Descriptions"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Short Description"
                                                                    name="shortDescriptionEn"
                                                                    value={values?.shortDescription?.en}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.shortDescription
                                                                        UpdateValue.en = v?.target?.value
                                                                        setFieldValue("shortDescription", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.shortDescription?.en}
                                                                    touched={touched?.shortDescription?.en}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Marathi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Marathi"
                                                                    name="shortDescriptionMarathi"
                                                                    value={values?.shortDescription?.mr}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.shortDescription
                                                                        UpdateValue.mr = v?.target?.value
                                                                        setFieldValue("shortDescription", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.shortDescription?.mr}
                                                                    touched={touched?.shortDescription?.mr}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Hindi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Hindi"
                                                                    name="shortDescriptionHindi"
                                                                    value={values?.shortDescription?.hi}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.shortDescription
                                                                        UpdateValue.hi = v?.target?.value
                                                                        setFieldValue("shortDescription", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.shortDescription?.hi}
                                                                    touched={touched?.shortDescription?.hi}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Success Rate"}
                                                                    FormControlClassName={'form-control'}
                                                                    onChange={handleChange}
                                                                    value={values?.successRate}
                                                                    onBlur={handleBlur}
                                                                    label="Success Rate"
                                                                    name="successRate"
                                                                    error={errors?.successRate}
                                                                    touched={touched?.successRate}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Programme Rating"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Programme Rating"
                                                                    name="rating"
                                                                    onChange={handleChange}
                                                                    value={values?.rating}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.rating}
                                                                    touched={touched?.rating}
                                                                />
                                                                <InputFromUser
                                                                    type={"number"}
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Per Day Price"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Per Day Price"
                                                                    name="perDayPrice"
                                                                    onChange={handleChange}
                                                                    value={values?.perDayPrice}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.perDayPrice}
                                                                    touched={touched?.perDayPrice}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Order Index"}
                                                                    FormControlClassName={'form-control'}
                                                                    onChange={handleChange}
                                                                    value={values?.orderIndex}
                                                                    onBlur={handleBlur}
                                                                    label="Order Index"
                                                                    type={"number"}
                                                                    name="orderIndex"
                                                                    error={errors?.orderIndex}
                                                                    touched={touched?.orderIndex}
                                                                />
                                                                <InputFromUser
                                                                    placeholder={"Paste Your YouTube Video Link"}
                                                                    type="url"
                                                                    name="videoIframe"
                                                                    onChange={(v) => setFieldValue("videoIframe", v?.target?.value)}
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values?.videoIframe}
                                                                    label="Iframe Link"
                                                                    error={errors?.videoIframe}
                                                                    touched={touched?.videoIframe}
                                                                />
                                                                <div className="col-xl-4 col-md-12">
                                                                    <div className="d-flex justify-content-evenly align-items-center ">
                                                                        <label className="col-form-label">
                                                                            {"Upload Programme Image:-"}
                                                                        </label>
                                                                        <div>
                                                                            <UploadImage
                                                                                className={"img-fluid"}
                                                                                fileList={!isNullOrUndef(values?.listingViewImage) && [{
                                                                                    uid: 1 + values?.listingViewImage,
                                                                                    name: values?.listingViewImage,
                                                                                    status: 'done',
                                                                                    url: DOCS_URL + values?.listingViewImage,
                                                                                }] || []}
                                                                                handleChange={(v) => {
                                                                                    if (v.file && v.file.status === 'uploading') {
                                                                                        const formData = new FormData();
                                                                                        formData.append("isProgramme", true);
                                                                                        formData.append("attachment", v?.file?.originFileObj);
                                                                                        dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                            if (typeof res === 'object') {
                                                                                                handleChange({
                                                                                                    target: {
                                                                                                        name: 'listingViewImage',
                                                                                                        value: res?.[0],
                                                                                                    },
                                                                                                });
                                                                                            }
                                                                                        }))
                                                                                    } else if (v.file && v.file.status === 'removed') {
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: 'listingViewImage',
                                                                                                value: '',
                                                                                            },
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                errorMessage={errors?.listingViewImage}
                                                                                UploadImagelimit={1} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Your mainTitle"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Main Title"
                                                                    name="mainTitle?.en"
                                                                    error={errors?.mainTitle?.en}
                                                                    touched={touched?.mainTitle?.en}
                                                                    value={values?.mainTitle?.en}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.mainTitle
                                                                        UpdateValue.en = v?.target?.value
                                                                        setFieldValue("mainTitle", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Marathi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Marathi"
                                                                    name="mainTitle.mr"
                                                                    value={values?.mainTitle?.mr}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.mainTitle
                                                                        UpdateValue.mr = v?.target?.value
                                                                        setFieldValue("mainTitle", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.mainTitle?.mr}
                                                                    touched={touched?.mainTitle?.mr}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Hindi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Hindi"
                                                                    value={values?.mainTitle?.hi}
                                                                    name="mainTitle.hi"
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.mainTitle
                                                                        UpdateValue.hi = v?.target?.value
                                                                        setFieldValue("mainTitle", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.mainTitle?.hi}
                                                                    touched={touched?.mainTitle?.hi}
                                                                />
                                                            </div>
                                                            <ExpendableTable
                                                                Title={"Programme Details Count"}
                                                                emptyDataForCreatingField={{ "data": '', "name": '' }}
                                                                Data={values?.statics}
                                                                Columns={[
                                                                    { name: 'name', type: 'text', label: 'Counts' },
                                                                    { name: 'data', type: 'text', label: 'Description' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("statics", v, true)}
                                                                onBlur={(v) => setFieldTouched("statics", v, true)}
                                                                errors={errors?.statics}
                                                                touched={touched?.statics}
                                                            />
                                                            <div className="row">
                                                                <div  >
                                                                    <Divider style={{ fontWeight: "600" }}>Facilities</Divider></div>
                                                                {values?.facilities?.map((item, index) => {
                                                                    return (
                                                                        <tr key={"facilities" + index + 'tr'}>
                                                                            <div className="d-flex">
                                                                                <td className="col-xl-6 col-md-10" key={"facilities" + index + 'td'}>
                                                                                    {
                                                                                        <InputFromUser
                                                                                            placeholder={'Enter Facilities'}
                                                                                            error={errors?.facilities}
                                                                                            value={item}
                                                                                            onChange={(v) => {
                                                                                                const updatedProgrammeFor = [...(values?.facilities || [])];
                                                                                                updatedProgrammeFor[index] = v?.target?.value;
                                                                                                setFieldValue("facilities", updatedProgrammeFor, true)
                                                                                            }}
                                                                                            touched={touched?.facilities}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                                <td className="col-2 add-remove text-end align-items-center" key={"td1" + index}>
                                                                                    <Button
                                                                                        type=""
                                                                                        className="add-btn "
                                                                                        onClick={() => {
                                                                                            const newValue = [''];
                                                                                            setFieldValue("facilities", [...values.facilities, newValue])
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                    </Button>
                                                                                    <Button
                                                                                        style={{ border: 'none' }}
                                                                                        className="remove-btn"
                                                                                        onClick={() => {
                                                                                            if (index !== 0) {
                                                                                                const updatedBenefits = [...values.facilities];
                                                                                                updatedBenefits.splice(index, 1);
                                                                                                setFieldValue("facilities", updatedBenefits);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <i className="fe fe-trash-2">
                                                                                            <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                        </i>
                                                                                    </Button>
                                                                                </td>
                                                                            </div>

                                                                        </tr>
                                                                    )

                                                                })}
                                                            </div>
                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Programme Description</Divider></div>
                                                                <div id="editor" >
                                                                    <TextEditor
                                                                        error={values?.description?.en}
                                                                        value={values?.description?.en}
                                                                        onChange={(event, editor) => {
                                                                            const UpdateValue = values?.description
                                                                            UpdateValue.en = editor.getData();
                                                                            setFieldValue("description", UpdateValue, true)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Programme Description (Hindi)</Divider></div>
                                                                <div id="editor" >
                                                                    <TextEditor
                                                                        error={values?.description?.hi}
                                                                        value={values?.description?.hi}
                                                                        onChange={(event, editor) => {
                                                                            const UpdateValue = values?.description
                                                                            UpdateValue.hi = editor.getData();
                                                                            setFieldValue("description", UpdateValue, true)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Programme Description (Marathi)</Divider></div>
                                                                <div id="editor" >
                                                                    <TextEditor
                                                                        error={values?.description?.mr}
                                                                        value={values?.description?.mr}
                                                                        onChange={(event, editor) => {
                                                                            const UpdateValue = values?.description
                                                                            UpdateValue.mr = editor.getData();
                                                                            setFieldValue("description", UpdateValue, true)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Who Is This For</Divider></div>
                                                                {values?.whoIsThisFor?.map((item, index) => {
                                                                    return (
                                                                        <tr key={"whoIsThisFor" + index + 'tr'}>
                                                                            <div className="d-flex">
                                                                                <td className="col-xl-6 col-md-10" key={"whoIsThisFor" + index + 'td'}>
                                                                                    {
                                                                                        <InputFromUser
                                                                                            placeholder={'Enter whoIsThisFor'}
                                                                                            error={errors?.whoIsThisFor}
                                                                                            value={item}
                                                                                            onChange={(v) => {
                                                                                                const updatedProgrammeFor = [...(values?.whoIsThisFor || [])];
                                                                                                updatedProgrammeFor[index] = v?.target?.value;
                                                                                                setFieldValue("whoIsThisFor", updatedProgrammeFor, true)
                                                                                            }}
                                                                                            touched={touched?.whoIsThisFor}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                                <td className="col-2 add-remove text-end align-items-center" key={"td1" + index}>
                                                                                    <Button
                                                                                        type=""
                                                                                        className="add-btn "
                                                                                        onClick={() => {
                                                                                            const newValue = [''];
                                                                                            setFieldValue("whoIsThisFor", [...values.whoIsThisFor, newValue])
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                    </Button>
                                                                                    <Button
                                                                                        style={{ border: 'none' }}
                                                                                        className="remove-btn"
                                                                                        onClick={() => {
                                                                                            if (index !== 0) {
                                                                                                const updatedBenefits = [...values.whoIsThisFor];
                                                                                                updatedBenefits.splice(index, 1);
                                                                                                setFieldValue("whoIsThisFor", updatedBenefits);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <i className="fe fe-trash-2">
                                                                                            <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                        </i>
                                                                                    </Button>
                                                                                </td>
                                                                            </div>

                                                                        </tr>
                                                                    )

                                                                })}
                                                            </div>
                                                            <ExpendableTable
                                                                Title={"Preparation And Aftercare"}
                                                                emptyDataForCreatingField={{ "title": "", "description": "", "icon": '', "orderIndex": '' }}
                                                                Data={values?.afterCareoptions}
                                                                Columns={[
                                                                    { name: 'title', type: 'text', label: 'Title' },
                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'icon', type: 'upload', label: 'Icon' },
                                                                    { name: 'Action', type: 'action', label: 'Icon' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("afterCareoptions", v, true)}
                                                                onBlur={(v) => setFieldTouched("afterCareoptions", v, true)}
                                                                errors={errors?.afterCareoptions}
                                                                touched={touched?.afterCareoptions}
                                                                UploadImageFlag={'isProgramme'}
                                                                HeaderComponent={<div className="row">
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Enter Description"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="Preparation And Aftercare Descriptions"
                                                                        name="preparationAndAftercareDescription"
                                                                        touched={touched?.afterCareTitle}
                                                                        error={errors?.afterCareTitle}
                                                                        value={values?.afterCareTitle}
                                                                        onChange={(v) => {
                                                                            const updateValue = v?.target?.value
                                                                            setFieldValue("afterCareTitle", updateValue, true)
                                                                        }}
                                                                    />
                                                                    <div className="col-xl-4 col-md-12">
                                                                        <div className="d-flex justify-content-evenly align-items-center ">
                                                                            <label className="col-form-label">
                                                                                {"Upload Preparation And After Care Image:-"}
                                                                            </label>
                                                                            <div>
                                                                                <UploadImage
                                                                                    className={"img-fluid"}
                                                                                    fileList={!isNullOrUndef(values?.afterCareImage) && [{
                                                                                        uid: 1 + values?.afterCareImage,
                                                                                        name: values?.afterCareImage,
                                                                                        status: 'done',
                                                                                        url: DOCS_URL + values?.afterCareImage,
                                                                                    }] || []}
                                                                                    handleChange={(v) => {
                                                                                        if (v.file && v.file.status === 'uploading') {
                                                                                            const formData = new FormData();
                                                                                            formData.append("isProgramme", true);
                                                                                            formData.append("attachment", v?.file?.originFileObj);
                                                                                            dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                                if (typeof res === 'object') {
                                                                                                    handleChange({
                                                                                                        target: {
                                                                                                            name: 'afterCareImage',
                                                                                                            value: res?.[0],
                                                                                                        },
                                                                                                    });
                                                                                                }
                                                                                            }))
                                                                                        } else if (v.file && v.file.status === 'removed') {
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: 'afterCareImage',
                                                                                                    value: '',
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                    //  errorMessage={errors?.listingViewImage}
                                                                                    UploadImagelimit={1} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            />

                                                            <ExpendableTable
                                                                Title={"What We Do"}
                                                                emptyDataForCreatingField={{ "title": "", "description": "", "icon": '', "orderIndex": '' }}
                                                                Data={values?.whatWeDo}
                                                                Columns={[
                                                                    { name: 'title', type: 'text', label: 'Title' },
                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'icon', type: 'upload', label: 'Icon' },
                                                                    { name: 'Action', type: 'action', label: 'Icon' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("whatWeDo", v, true)}
                                                                onBlur={(v) => setFieldTouched("whatWeDo", v, true)}
                                                                errors={errors?.whatWeDo}
                                                                UploadImageFlag={'isProgramme'}
                                                                touched={touched?.whatWeDo}
                                                            />
                                                            <ExpendableTable
                                                                Title={"Our Process"}
                                                                emptyDataForCreatingField={{ "mainTitle": "", "title": '', "description": "", "sideImage": '', "orderIndex": '' }}
                                                                Data={values?.ourProcess}
                                                                Columns={[
                                                                    { name: 'mainTitle', type: 'text', label: 'Main Title' },
                                                                    { name: 'title', type: 'text', label: 'Title' },
                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'sideImage', type: 'upload', label: 'Icon' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("ourProcess", v, true)}
                                                                onBlur={(v) => setFieldTouched("ourProcess", v, true)}
                                                                errors={errors?.ourProcess}
                                                                UploadImageFlag={'isProgramme'}
                                                                touched={touched?.ourProcess}
                                                            />
                                                            <ExpendableTable
                                                                Title={"How Your Day Look Like"}
                                                                emptyDataForCreatingField={[{ "startTime": "", "endTime": '', "description": '', "orderIndex": '', "bannerImage": '' }]}
                                                                Data={values?.dayLook}
                                                                Columns={[

                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'startTime', type: 'text', label: 'StartTime' },
                                                                    { name: 'endTime', type: 'text', label: 'EndTime' },
                                                                    { name: 'bannerImage', type: 'upload', label: 'Image' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("dayLook", v, true)}
                                                                onBlur={(v) => setFieldTouched("dayLook", v, true)}
                                                                errors={errors?.dayLook}
                                                                touched={touched?.dayLook}
                                                                HeaderComponent={<div className="row">
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-5 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Day Look Like Title"}
                                                                        FormControlClassName={'form-control '}
                                                                        value={values?.dayLookTitle}
                                                                        label="Title"
                                                                        name="dayLookTitle"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.dayLookTitle}
                                                                        touched={touched?.dayLookTitle}
                                                                    />
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-5 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        value={values?.dayLookDescription}
                                                                        placeholder={"Description"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="Description"
                                                                        name="dayLookDescription"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.dayLookDescription}
                                                                        touched={touched?.dayLookDescription}
                                                                    />
                                                                    <div className="d-flex justify-content-between align-items-center col-6 ">
                                                                        <label className="col-form-label">
                                                                            {"Upload Photo :-"}
                                                                        </label>
                                                                        <div>
                                                                            <UploadImage
                                                                                className={"img-fluid"}
                                                                                fileList={!isNullOrUndef(values?.dayLookMainImage) && [{
                                                                                    uid: 1 + values?.dayLookMainImage,
                                                                                    name: values?.dayLookMainImage,
                                                                                    status: 'done',
                                                                                    url: DOCS_URL + values?.dayLookMainImage,
                                                                                }] || []}
                                                                                handleChange={(v) => {
                                                                                    if (v.file && v.file.status === 'uploading') {
                                                                                        const formData = new FormData();
                                                                                        formData.append("isProgramme", true);
                                                                                        formData.append("attachment", v?.file?.originFileObj);
                                                                                        dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                            if (typeof res === 'object') {
                                                                                                handleChange({
                                                                                                    target: {
                                                                                                        name: 'dayLookMainImage',
                                                                                                        value: res?.[0],
                                                                                                    },
                                                                                                });
                                                                                            }
                                                                                        }))
                                                                                    } else if (v.file && v.file.status === 'removed') {
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: 'dayLookMainImage',
                                                                                                value: '',
                                                                                            },
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                errorMessage={errors?.dayLookMainImage}
                                                                                UploadImagelimit={1} />
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            />

                                                            {/* Yoga */}
                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Yoga</Divider></div>
                                                                <div className="row mt-4">
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-5 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        value={values?.yoga?.title}
                                                                        placeholder={"Title"}
                                                                        FormControlClassName={'form-control '}
                                                                        label=" Yoga Title"
                                                                        name="yoga.title"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.yoga?.title}
                                                                        touched={touched?.yoga?.title}

                                                                    />
                                                                    <div className="d-flex  align-items-center col-6 ">
                                                                        <label className="col-form-label">
                                                                            {"Upload Photo :-"}
                                                                        </label>
                                                                        <div>
                                                                            <UploadImage
                                                                                className={"img-fluid"}
                                                                                fileList={!isNullOrUndef(values?.yogaImage) && [{
                                                                                    uid: 1 + values?.yogaImage,
                                                                                    name: values?.yogaImage,
                                                                                    status: 'done',
                                                                                    url: DOCS_URL + values?.yogaImage,
                                                                                }] || []}
                                                                                handleChange={(v) => {
                                                                                    if (v.file && v.file.status === 'uploading') {
                                                                                        const formData = new FormData();
                                                                                        formData.append("isProgramme", true);
                                                                                        formData.append("attachment", v?.file?.originFileObj);
                                                                                        dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                            if (typeof res === 'object') {
                                                                                                handleChange({
                                                                                                    target: {
                                                                                                        name: 'yogaImage',
                                                                                                        value: res?.[0],
                                                                                                    },
                                                                                                });
                                                                                            }
                                                                                        }))
                                                                                    } else if (v.file && v.file.status === 'removed') {
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: 'yogaImage',
                                                                                                value: '',
                                                                                            },
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                errorMessage={errors?.yogaImage}
                                                                                UploadImagelimit={1} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="my-4" >
                                                                        <Divider style={{ fontWeight: "600" }}>Yoga Value</Divider></div>
                                                                    {values?.yoga?.value?.map((item, index) => {
                                                                        return (
                                                                            <tr key={"value" + index + 'tr'}>
                                                                                <div className="d-flex">
                                                                                    <td className="col-xl-6 col-md-10" key={"value" + index + 'td'}>
                                                                                        <InputFromUser
                                                                                            placeholder={'Enter Value'}
                                                                                            value={item}
                                                                                            onChange={(e) => {
                                                                                                const newValue = e.target.value;
                                                                                                const updatedFacilities = [...(values?.yoga?.value || [])];
                                                                                                updatedFacilities[index] = newValue;
                                                                                                setFieldValue("yoga", { ...values.yoga, value: updatedFacilities });
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="col-2 add-remove text-end align-items-center" key={"td6" + index}>
                                                                                        <Button
                                                                                            type=""
                                                                                            className="add-btn"
                                                                                            onClick={() => {
                                                                                                const defaultValue = ''; // Default value for the new item
                                                                                                setFieldValue("yoga", { ...(values?.yoga || {}), value: [...(values?.yoga?.value || []), defaultValue] })
                                                                                            }}
                                                                                        >
                                                                                            <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                        </Button>
                                                                                        <Link
                                                                                            style={{ border: 'none' }}
                                                                                            className="remove-btn"
                                                                                            onClick={() => {
                                                                                                if (index !== 0) {
                                                                                                    const updatedFacilities = [...(values?.yoga?.value || [])];
                                                                                                    updatedFacilities.splice(index, 1);
                                                                                                    setFieldValue("yoga", { ...values.yoga, value: updatedFacilities });
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            <i className="fe fe-trash-2">
                                                                                                <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                            </i>
                                                                                        </Link>
                                                                                    </td>
                                                                                </div>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>

                                                            {/* /*Benefits */}
                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Benefits</Divider></div>
                                                                <div className="row mt-4">
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-5 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        value={values?.benefits?.title}
                                                                        placeholder={"Title"}
                                                                        FormControlClassName={'form-control '}
                                                                        label=" Benefits Title"
                                                                        name="benefits.title"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.benefits?.title}
                                                                        touched={touched?.benefits?.title}

                                                                    />
                                                                    <div className="d-flex  align-items-center col-6 ">
                                                                        <label className="col-form-label">
                                                                            {"Upload Photo :-"}
                                                                        </label>
                                                                        <div>
                                                                            <UploadImage
                                                                                className={"img-fluid"}
                                                                                fileList={!isNullOrUndef(values?.benefitImage) ? [{
                                                                                    uid: 1,
                                                                                    name: values?.benefitImage,
                                                                                    status: 'done',
                                                                                    url: DOCS_URL + values?.benefitImage,
                                                                                }] : []}
                                                                                handleChange={(v) => {
                                                                                    if (v.file && v.file.status === 'uploading') {
                                                                                        const formData = new FormData();
                                                                                        formData.append("isProgramme", true);
                                                                                        formData.append("attachment", v?.file?.originFileObj);
                                                                                        dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                            if (typeof res === 'object') {
                                                                                                handleChange({
                                                                                                    target: {
                                                                                                        name: 'benefitImage',
                                                                                                        value: res?.[0],
                                                                                                    },
                                                                                                });
                                                                                            }
                                                                                        }))
                                                                                    } else if (v.file && v.file.status === 'removed') {
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: 'benefitImage',
                                                                                                value: '',
                                                                                            },
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                errorMessage={errors?.benefitImage}
                                                                                UploadImagelimit={1}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="my-4" >
                                                                        <Divider style={{ fontWeight: "600" }}>Benefit Value</Divider></div>
                                                                    {values?.benefits?.value?.map((item, index) => {
                                                                        return (
                                                                            <tr key={"value" + index + 'tr'}>
                                                                                <div className="d-flex">
                                                                                    <td className="col-xl-6 col-md-10" key={"value" + index + 'td'}>
                                                                                        {
                                                                                            <InputFromUser
                                                                                                placeholder={'Enter Value'}
                                                                                                // error={errors?.yoga}
                                                                                                value={item}
                                                                                                onChange={(e) => {
                                                                                                    const newValue = e.target.value;
                                                                                                    const updatedFacilities = [...(values?.benefits?.value || [])];
                                                                                                    updatedFacilities[index] = newValue;
                                                                                                    setFieldValue("benefits", { ...values.benefits, value: updatedFacilities });
                                                                                                }}
                                                                                            // touched={touched?.yoga?.value}
                                                                                            />
                                                                                        }
                                                                                    </td>
                                                                                    <td className="col-2 add-remove text-end align-items-center" key={"td6" + index}>
                                                                                        <Button
                                                                                            type=""
                                                                                            className="add-btn "
                                                                                            onClick={() => {
                                                                                                const newValue = '';
                                                                                                setFieldValue("benefits", { ...(values?.benefits || {}), value: [...(values?.benefits?.value || []), newValue] })
                                                                                            }}
                                                                                        >
                                                                                            <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                        </Button>
                                                                                        <Link
                                                                                            style={{ border: 'none' }}
                                                                                            className="remove-btn"
                                                                                            onClick={() => {
                                                                                                if (index !== 0) {
                                                                                                    const updatedFacilities = [...(values?.benefits?.value || [])];
                                                                                                    updatedFacilities.splice(index, 1);
                                                                                                    setFieldValue("benefits", { ...values.benefits, value: updatedFacilities });
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            <i className="fe fe-trash-2">
                                                                                                <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                            </i>
                                                                                        </Link>
                                                                                    </td>
                                                                                </div>

                                                                            </tr>
                                                                        )

                                                                    })}
                                                                </div>
                                                            </div>



                                                            <ExpendableTable
                                                                Title={"Why Choose Us"}
                                                                emptyDataForCreatingField={{ "aspect": "", "otherHealthResort": '', "naturopathyCenters": '', "prakrutisAyurvedicTreatment": "", "orderIndex": "" }}
                                                                Data={values?.chooseUs}
                                                                Columns={[
                                                                    { name: 'aspect', type: 'text', label: 'Aspect' },
                                                                    { name: 'otherHealthResort', type: 'text', label: 'OtherHealthResort' },
                                                                    { name: 'naturopathyCenters', type: 'text', label: 'Naturopathy Centers' },
                                                                    { name: 'prakrutisAyurvedicTreatment', type: 'text', label: 'Prakrutis Ayurvedic Treatment' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("chooseUs", v, true)}
                                                                onBlur={(v) => setFieldTouched("chooseUs", v, true)}
                                                                errors={errors?.chooseUs}
                                                                touched={touched?.chooseUs}
                                                            />
                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Days Details</Divider></div>
                                                                {values?.days?.map((item, index) => {
                                                                    return (
                                                                        <tr key={"days" + index + 'tr'}>
                                                                            <div className="d-flex">
                                                                                <td className="col-xl-6 col-md-10" key={"days" + index + 'td'}>
                                                                                    {
                                                                                        <InputFromUser
                                                                                            placeholder={'Enter days'}
                                                                                            value={item}
                                                                                            error={errors?.days}
                                                                                            onChange={(v) => {
                                                                                                const updatedServiceFor = [...(values?.days || [])];
                                                                                                updatedServiceFor[index] = v?.target?.value;
                                                                                                setFieldValue("days", updatedServiceFor, true)
                                                                                            }}
                                                                                            touched={touched?.days}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                                <td className="col-2 add-remove text-end align-items-center" key={"td6" + index}>
                                                                                    <Button
                                                                                        type=""
                                                                                        className="add-btn"
                                                                                        onClick={() => {
                                                                                            const newValue = [''];
                                                                                            setFieldValue("days", [...values.days, newValue])
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                    </Button>
                                                                                    <Button
                                                                                        style={{ border: 'none' }}
                                                                                        className="remove-btn"
                                                                                        onClick={() => {
                                                                                            if (index !== 0) {
                                                                                                const updatedBenefits = [...values.days];
                                                                                                updatedBenefits.splice(index, 1);
                                                                                                setFieldValue("days", updatedBenefits);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <i className="fe fe-trash-2">
                                                                                            <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                        </i>
                                                                                    </Button>
                                                                                </td>
                                                                            </div>

                                                                        </tr>
                                                                    )

                                                                })}
                                                            </div>
                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Pricing Details</Divider></div>
                                                                {values?.price?.map((item, index) => {
                                                                    return (
                                                                        <tr key={"price" + index + 'tr'}>
                                                                            <div className="d-flex">
                                                                                <td className="col-xl-6 col-md-10" key={"price" + index + 'td'}>
                                                                                    {
                                                                                        <InputFromUser
                                                                                            placeholder={'Enter price'}
                                                                                            value={item}
                                                                                            onChange={(v) => {
                                                                                                const updatedServiceFor = [...(values?.price || [])];
                                                                                                updatedServiceFor[index] = v?.target?.value;
                                                                                                setFieldValue("price", updatedServiceFor, true)
                                                                                            }}
                                                                                            touched={touched?.price}
                                                                                            error={errors?.price}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                                <td className="col-2 add-remove text-end align-items-center" key={"td6price" + index}>
                                                                                    <Button
                                                                                        type=""
                                                                                        className="add-btn"
                                                                                        onClick={() => {
                                                                                            const newValue = [''];
                                                                                            setFieldValue("price", [...values.price, newValue])
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                    </Button>
                                                                                    <Button
                                                                                        style={{ border: 'none' }}
                                                                                        className="remove-btn"
                                                                                        onClick={() => {
                                                                                            if (index !== 0) {
                                                                                                const updatedBenefits = [...values.price];
                                                                                                updatedBenefits.splice(index, 1);
                                                                                                setFieldValue("price", updatedBenefits);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <i className="fe fe-trash-2">
                                                                                            <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                        </i>
                                                                                    </Button>
                                                                                </td>
                                                                            </div>

                                                                        </tr>
                                                                    )

                                                                })}
                                                            </div>
                                                            <ExpendableTable
                                                                Title={"Programme Faqs"}
                                                                emptyDataForCreatingField={{ "title": "", "description": "", "orderIndex": '' }}
                                                                Data={values?.programmeFaqs}
                                                                Columns={[
                                                                    { name: 'title', type: 'text', label: 'Title' },
                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("programmeFaqs", v, true)}
                                                                onBlur={(v) => setFieldTouched("programmeFaqs", v, true)}
                                                                errors={errors?.programmeFaqs}
                                                                touched={touched?.programmeFaqs}
                                                            />
                                                            <div className="col-12">
                                                                <div className="text-end">
                                                                    <Button
                                                                        onClick={() => {
                                                                            handleSubmit();
                                                                        }}
                                                                        type="primary"
                                                                        // disabled={isSubmitting}
                                                                        className="pb-4"
                                                                    >Submit</Button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                }}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

export default AddEditProgramme