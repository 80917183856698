import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from "react-redux";
import initStore from "../store";
const Layout = ({ children }) => {
    const store = initStore()
    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
};
Layout.propTypes = {
    children: PropTypes.node
};
export default Layout;
