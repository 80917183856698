import { Link } from "react-router-dom";
import { Image1, Image2 } from "../components/imagepath";

export const DoctorsListcolumn = [
    {
        title: "Name",
        dataIndex: "name",
    },
    {
        title: "Department",
        dataIndex: "department",
    },
    {
        title: "Mobile No.",
        dataIndex: "mobileNo",

    },
    {
        title: "Email",
        dataIndex: "email",
    },

];
export const DoctorsListdata = [
    {
        name: 'Olivia Rhye',
        department: "Doctor",
        mobileNo: '123 456 7899',
        email: 'name@email.com',
    },
    {
        name: 'Olivia Rhye',
        department: "Doctor",
        mobileNo: '123 456 7899',
        email: 'name@email.com',
    },
    {
        name: 'Olivia Rhye',
        department: "Doctor",
        mobileNo: '123 456 7899',
        email: 'name@email.com',
    },
    {
        name: 'Olivia Rhye',
        department: "Doctor",
        mobileNo: '123 456 7899',
        email: 'name@email.com',
    },
    {
        name: 'Olivia Rhye',
        department: "Doctor",
        mobileNo: '123 456 7899',
        email: 'name@email.com',
    },
    {
        name: 'Olivia Rhye',
        department: "Doctor",
        mobileNo: '123 456 7899',
        email: 'name@email.com',
    },
    {
        name: 'Olivia Rhye',
        department: "Doctor",
        mobileNo: '123 456 7899',
        email: 'name@email.com',
    },
    {
        name: 'Olivia Rhye',
        department: "Doctor",
        mobileNo: '123 456 7899',
        email: 'name@email.com',
    },

];
export const Medicitionscolumn = [
    {
        title: "Medicine Name",
        dataIndex: "medicineName",
    },
    {
        title: "Days",
        dataIndex: "days",
        render: (text,) => (
            <span>{text}</span>
        ),

    },
    {
        title: "How much",
        dataIndex: "howMuch",
        render: (text,) => (
            <span>{text}</span>
        ),
    },
    {
        title: "Anupan",
        dataIndex: "anupan",
    },

];
export const Medicitionsdata = [
    {
        name: 'Lorem Ipsium',
        indi: <img src={Image1} />,
        status: 'Adherent',
        startDate: '20/01/2013',
    },
    {
        name: 'Lorem Ipsium',
        indi: <img src={Image2} />,
        status: 'Adherent',
        startDate: '20/01/2013',
    },
    {
        name: 'Lorem Ipsium',
        indi: <img src={Image1} />,
        status: 'Adherent',
        startDate: '20/01/2013',
    },

];

export const AppointmentHistory = [
    {
        title: "Date",
        dataIndex: "date",
    },
    {
        title: "Doctor",
        dataIndex: "doctor",
        render: (text) => (
            <span>{text.name}</span>
        ),

    },
    {
        title: "Treatment",
        dataIndex: "treatment",
        render: (text) => (
            <span>{text || '---'}</span>
        ),
    },
    {
        //title: "Action",
        dataIndex: "key",
        key: "key",
        render: () => {
            return (
                <div className="dropdown dropdown-action">
                    <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <i className="fa fa-ellipsis-v" />
                    </Link>
                    <div className="dropdown-menu dropdown-menu-end">
                        <Link
                            className="dropdown-item"
                        // to="/appoinmentlist"
                        >
                            <i className="fa-solid fa-pen-to-square m-r-5" />{" "}
                            Edit
                        </Link>
                        <Link
                            className="dropdown-item"
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_patient"
                        >
                            <i className="fa fa-trash-alt m-r-5"></i> Delete
                        </Link>
                    </div>
                </div>
            )
        },
    },

];
export const AppointmentHistorydata = [
    {
        date: '29/09/2022',
        doctor: "Dr.jenny Smith",
        treatment: 'Blood Pressure',
    },
    {
        date: '19/09/2022',
        doctor: "Andrea Lalema",
        treatment: 'Diabetes',
    },
    {
        date: '20/09/2022',
        doctor: "Dr.William Stephin",
        treatment: 'Thyroid',
    },
];

export const BookingHistory = [
    {
        title: "Name",
        dataIndex: "name",
    },
    {
        title: "Service",
        dataIndex: "service",
        render: (text,) => (
            <span>{text}</span>
        ),
    },
    {
        title: "Treatment",
        dataIndex: "treatment",
        render: (text,) => (
            <span>{text}</span>
        ),
    },
    {
        title: "Solution",
        dataIndex: "solution",
        render: (text,) => (
            <span>{text}</span>
        ),
    },
    {
        title: "Check in",
        dataIndex: "checkIn",
    },
    {
        title: "Check Out",
        dataIndex: "checkOut",

    },
    // {
    //     //title: "Action",
    //     dataIndex: "key",
    //     key: "key",
    //     render: () => {
    //         return (
    //             <div className="dropdown dropdown-action">
    //                 <Link
    //                     to="#"
    //                     className="action-icon dropdown-toggle"
    //                     data-bs-toggle="dropdown"
    //                     aria-expanded="false"
    //                 >
    //                     <i className="fa fa-ellipsis-v" />
    //                 </Link>
    //                 <div className="dropdown-menu dropdown-menu-end">
    //                     <Link
    //                         className="dropdown-item"
    //                     // to="/appoinmentlist"
    //                     >
    //                         <i className="fa-solid fa-pen-to-square m-r-5" />{" "}
    //                         Edit
    //                     </Link>
    //                     <Link
    //                         className="dropdown-item"
    //                         to="#"
    //                         data-bs-toggle="modal"
    //                         data-bs-target="#delete_patient"
    //                     >
    //                         <i className="fa fa-trash-alt m-r-5"></i> Delete
    //                     </Link>
    //                 </div>
    //             </div>
    //         )
    //     },
    // },

];
export const BookingHistorydata = [
    {
        name: "Andrea Lalema",
        service: 'Shirodhara',
        checkIn: '20/01/2013',
        checkOut: '20/01/2013',

    },
    {
        name: "Andrea Lalema",
        service: 'Panchkarma',
        checkIn: '20/01/2013',
        checkOut: '20/01/2013',

    },

];

export const EducationColum = [

    {
        title: "Degree",
        dataIndex: "degree",
        render: (text,) => (
            <span>{text}</span>
        ),
    },


];

export const EducationColumdata = [
    {
        year: "2002-2005",
        degree: 'M.D.of Medicine',
        institute: 'University of Wyoming',
        result: <button className="custom-badge status-green">
            Distinction
        </button>,

    },
    {
        year: "2002-2014",
        degree: 'MBBS',
        institute: 'Netherland Medical College',
        result: <button className="custom-badge status-green">
            Distinction
        </button>,

    },

];

export const ExperienceCoulm = [
    {
        title: "Year",
        dataIndex: "year",
    },


];
export const ExperienceColumdata = [
    {
        year: "2002-2005",
        degree: 'M.D.of Medicine',
        institute: 'University of Wyoming',
        result: <button className="custom-badge status-orange ">
            Good
        </button>,

    },
    {
        year: "2002-2014",
        degree: 'MBBS',
        institute: 'Netherland Medical College',
        result: <button className="custom-badge status-green">
            Experience
        </button>

    },

];

export const DoctorScheduleColum = [
    {
        title: "Days",
        dataIndex: "days",
    },
    {
        title: "Spent Time",
        dataIndex: "spentTime",
        render: (text,) => (
            <span>{text}</span>
        ),
    },
    {
        title: "End Time",
        dataIndex: "EndTime",
    },
    {
        title: "Type",
        dataIndex: "type",

    },

];
export const DoctorScheduleData = [
    {
        days: "2002-2005",
        spentTime: 'M.D.of Medicine',
        endTime: 'University of Wyoming',
        type: 'University of Wyoming',
    },
    {
        days: "2002-2005",
        spentTime: 'M.D.of Medicine',
        endTime: 'University of Wyoming',
        type: 'University of Wyoming',
    },

];

export const PriceTableColum = [
    {
        title: "Consultation Type",
        dataIndex: "consultationType",
    },
    {
        title: "Total Minutes",
        dataIndex: "totalMinutes",
        render: (text,) => (
            <span>{text}</span>
        ),
    },
    {
        title: "Price",
        dataIndex: "price",
    },

];
export const PriceTableData = [
    {
        consultationType: "First Consultation",
        totalMinutes: '10',
        price: '120',
    },
    {
        consultationType: "Emergency",
        totalMinutes: '20',
        price: '150',
    },

];

export const holidaysColumns = [
    {
        title: "Festival name",
        dataIndex: "festivalName",
        sorter: (a, b) => a.Title.length - b.Title.length,
    },
    {
        title: "Description",
        dataIndex: "description",
        sorter: (a, b) => a.HolidayDate.length - b.HolidayDate.length,
    },
    {
        title: "Day",
        dataIndex: "day",
        sorter: (a, b) => a.Day.length - b.Day.length,
    },
    {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => a.Description.length - b.Description.length,
    },
    {
        title: "",
        dataIndex: "FIELD8",
        render: () => (
            <>
                <div className="text-end">
                    <div className="dropdown dropdown-action">
                        <Link
                            to="#"
                            className="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="fas fa-ellipsis-v" />
                        </Link>
                        <div className="dropdown-menu dropdown-menu-end">
                            <Link className="dropdown-item" to="">
                                <i className="far fa-edit me-2" />
                                Edit
                            </Link>
                            <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#delete_patient">
                                <i className="fa fa-trash-alt m-r-5"></i> Delete</Link>
                        </div>
                    </div>
                </div>
            </>
        ),
    },
];

export const holidaysData = [
    {
        id: 1,
        FIELD1: "",
        Title: "New Year",
        HolidayDate: "01-01-2022",
        Day: "Sunday",
        Description: "Common Holiday",
        FIELD6: "",
    },
    {
        id: 2,
        FIELD1: "",
        Title: "Pongal",
        HolidayDate: "14-01-2022",
        Day: "Monday",
        Description: "Religious Holiday",
        FIELD6: "",
    },
    {
        id: 3,
        FIELD1: "",
        Title: "Pongal Holiday",
        HolidayDate: "15-01-2022",
        Day: "Monday",
        Description: "Religious Holiday",
        FIELD6: "",
    },
    {
        id: 4,
        FIELD1: "",
        Title: "Tamil New Year",
        HolidayDate: "14-04-2022",
        Day: "Tuesday",
        Description: "Religious Holiday",
        FIELD6: "",
    },
    {
        id: 5,
        FIELD1: "",
        Title: "Good Friday",
        HolidayDate: "05-01-2022",
        Day: "Friday",
        Description: "Religious Holiday",
        FIELD6: "",
    },
    {
        id: 6,
        FIELD1: "",
        Title: "May Day",
        HolidayDate: "15-01-2022",
        Day: "Wednesday",
        Description: "Common Holiday",
        FIELD6: "",
    },
    {
        id: 7,
        FIELD1: "",
        Title: "Ramzan",
        HolidayDate: "10-08-2022",
        Day: "Monday",
        Description: "Religious Holiday",
        FIELD6: "",
    },
    {
        id: 8,
        FIELD1: "",
        Title: "Independence day",
        HolidayDate: "26-01-2022",
        Day: "Friday",
        Description: "Common Holiday",
        FIELD6: "",
    },
];

export const RoomCheckinCheckout = [
    {
        title: "Name",
        dataIndex: "name",
    },
    {
        title: "Room Type",
        dataIndex: "roomType",
        render: (text,) => (
            <span>{text}</span>
        ),
    },
    {
        title: "Room No.",
        dataIndex: "roomNo",
    },
    {
        title: "Phone",
        dataIndex: "phoneNo",
    },
    {
        title: "Details",
        dataIndex: "details",
    },
    {
        title: "Time",
        dataIndex: "time",
    },
    {
        title: "Check In",
        dataIndex: "checkIn",
    },
    {
        title: "Check Out",
        dataIndex: "checkOut",
    },
    {
        title: "Status",
        dataIndex: "status",
    },

    // {
    //     //title: "Action",
    //     dataIndex: "key",
    //     key: "key",
    //     render: () => {
    //         return (
    //             <div className="dropdown dropdown-action">
    //                 <Link
    //                     to="#"
    //                     className="action-icon dropdown-toggle"
    //                     data-bs-toggle="dropdown"
    //                     aria-expanded="false"
    //                 >
    //                     <i className="fa fa-ellipsis-v" />
    //                 </Link>
    //                 <div className="dropdown-menu dropdown-menu-end">
    //                     <Link
    //                         className="dropdown-item"
    //                         to="/appoinmentlist"
    //                     >
    //                         <i className="fa-solid fa-pen-to-square m-r-5" />{" "}
    //                         Edit
    //                     </Link>
    //                     <Link
    //                         className="dropdown-item"
    //                         to="#"
    //                         data-bs-toggle="modal"
    //                         data-bs-target="#delete_patient"
    //                     >
    //                         <i className="fa fa-trash-alt m-r-5"></i> Delete
    //                     </Link>
    //                 </div>
    //             </div>
    //         )
    //     },
    // },

];
export const RoomCheckinCheckoutdata = [
    {
        name: "Andrea Lalema",
        roomType: 'Delux',
        roomNo: 'Delux1',
        phoneNo: '9876543210',
        details: 'view',
        time: '01:57',
        checkIn: '20/01/2013',
        checkOut: '20/01/2013',
        status: 'paid',

    },
    {
        name: "Andrea Lalema",
        roomType: 'Delux',
        roomNo: 'Delux1',
        phoneNo: '9876543210',
        details: 'view',
        time: '01:57',
        checkIn: '20/01/2013',
        checkOut: '20/01/2013',
        status: 'paid',

    },
    {
        name: "Andrea Lalema",
        roomType: 'Delux',
        roomNo: 'Delux1',
        phoneNo: '9876543210',
        details: 'view',
        time: '01:57',
        checkIn: '20/01/2013',
        checkOut: '20/01/2013',
        status: 'paid',

    },
    {
        name: "Andrea Lalema",
        roomType: 'Delux',
        roomNo: 'Delux1',
        phoneNo: '9876543210',
        details: 'view',
        time: '01:57',
        checkIn: '20/01/2013',
        checkOut: '20/01/2013',
        status: 'paid',

    },
    {
        name: "Andrea Lalema",
        roomType: 'Delux',
        roomNo: 'Delux1',
        phoneNo: '9876543210',
        details: 'view',
        time: '01:57',
        checkIn: '20/01/2013',
        checkOut: '20/01/2013',
        status: 'paid',

    },
    {
        name: "Andrea Lalema",
        roomType: 'Delux',
        roomNo: 'Delux1',
        phoneNo: '9876543210',
        details: 'view',
        time: '01:57',
        checkIn: '20/01/2013',
        checkOut: '20/01/2013',
        status: 'paid',

    },
    {
        name: "Andrea Lalema",
        roomType: 'Delux',
        roomNo: 'Delux1',
        phoneNo: '9876543210',
        details: 'view',
        time: '01:57',
        checkIn: '20/01/2013',
        checkOut: '20/01/2013',
        status: 'paid',

    },

];
