import React, { useEffect } from 'react'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import { CommonHeadingTxt } from '../../components/Text/Text'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ActionGetPatientPlanList } from '../../store/Actions/AppConfigAction'

const IndoorCasepaper = () => {

    const { patientPlanList } = useSelector(state => state?.appConfig);
    console.log('chk12', patientPlanList);
    const patientPlanId = patientPlanList?.[0]?._id;
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const treatmentSlotId = searchParams.get("slotId");
    const solutionSlotId = searchParams.get("solutionslotId");
    const slotId = treatmentSlotId || solutionSlotId;
    const dispatch = useDispatch();
    useEffect(() => {
        const slotId = solutionSlotId;
        const queryParam = slotId ? `solutionId=${solutionSlotId}` : `treatmentId=${treatmentSlotId}`;
        dispatch(ActionGetPatientPlanList({ Query: `?${queryParam}` }));

    }, [dispatch, treatmentSlotId, solutionSlotId]);
    return (
        <>
            <Header />
            <Sidebar />
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-7 col-6">
                            <ul className="breadcrumb">

                                <li className="breadcrumb-item active">Indoor-CasePaper</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row my-lg-4 my-5">
                        <div className="col-12 col-md-12  col-xl-6">
                            <div className="card h-100 mt-lg-0 mt-3">
                                <CommonHeadingTxt Text="Indoor CasePaper " />
                                <div><Link to={`/indor-casepaper/?slotId=${slotId}`} className='btn btn-primary ms-3'>Indoor CasePaper</Link></div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12  col-xl-6">

                            <div className="card h-100 mt-lg-0 mt-3">
                                <CommonHeadingTxt Text="Indoor CasePaper History" />
                                {/* <div><Link to={`/yoga-consultation`} className='btn btn-primary ms-3'>Discharge Form</Link></div> */}

                            </div>

                        </div>
                    </div>
                    <div className="row my-lg-4 my-5">
                        <div className="col-12 col-xl-6">
                            <div className="card h-100 mt-lg-0 mt-3">
                                <CommonHeadingTxt Text="Yoga Consultation " />
                                <div><Link to={`/yoga-consultation`} className='btn btn-primary ms-3'>Yoga Consultation</Link></div>

                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="card h-100 mt-lg-0 mt-3">
                                <CommonHeadingTxt Text="Yoga Consultation History " />
                            </div>
                        </div>
                    </div>
                    <div className="row my-lg-4 my-5">

                        <div className="col-12 col-xl-6">
                            <div className="card h-100 mt-lg-0 mt-3">
                                <CommonHeadingTxt Text="Medicine History" />
                                {/* <div><Link to={`/yoga-consultation`} className='btn btn-primary ms-3'>Discharge Form</Link></div> */}

                            </div>
                        </div>
                        <div className="col-12  col-xl-6">
                            <div className="card h-100 mt-lg-0 mt-3">
                                <CommonHeadingTxt Text="Discharge Form" />
                                <div><Link to={`/discharge-form/?patientPlanId=${patientPlanId}`} className='btn btn-primary ms-3'>Discharge Form</Link></div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default IndoorCasepaper