import Sidebar from "../../../components/Sidebar";
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import { AddHolidayValidationSchema } from "../../../utils/FormValidation";
import { Formik } from "formik";
import { InputFromUser } from "../../../components/Input/input";
import { useDispatch, useSelector } from "react-redux";
import { Options } from "../../../utils/Data";
import { ActionGetHolidayByID, ActionUpdateHoliday, CreateHolidayAction } from "../../../store/Actions/StaffAction";
import { useEffect } from "react";
import dayjs from 'dayjs';
const AddHoliday = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const pageValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const idValue = searchParams.get("id");
    useEffect(() => {
        if (idValue) {
            dispatch(ActionGetHolidayByID({ Query: `/${idValue}` }));
        }
    }, [dispatch])

    const { holidayList } = useSelector(state => state?.Staff);
    return (
        <>
            <div className="main-wrapper">
                <Header />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="#">Holiday </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/holidays">Holidays List</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Add Holidays</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* /Page Header */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                festivalName: holidayList?.festivalName || "",
                                                description: holidayList?.description || "",
                                                day: holidayList?.day || "",
                                                date: holidayList?.date ? dayjs(holidayList?.date) : null || null,
                                            }}
                                            validationSchema={AddHolidayValidationSchema}
                                            onSubmit={(values, { setErrors, setSubmitting }) => {
                                                if (!idValue) {
                                                    dispatch(CreateHolidayAction({
                                                        Query: "",
                                                        obj: {
                                                            "festivalName": values.festivalName,
                                                            "description": values.description,
                                                            "day": values.day,
                                                            "date": values.date,
                                                        }
                                                    }, (Res) => {
                                                        setSubmitting(false)
                                                        if (Res?.statusCode === 200) {
                                                            window.location.href = `/holidays?page=1`;
                                                        } else if (Res?.statusCode === 400) {
                                                            window.scrollTo({
                                                                top: 0,
                                                                behavior: 'smooth',
                                                            });
                                                            setErrors(Res?.data?.errors);
                                                        }
                                                    })
                                                    );
                                                }
                                                else {

                                                    dispatch(ActionUpdateHoliday({
                                                        Query: "/" + idValue,
                                                        obj: {
                                                            "festivalName": values.festivalName,
                                                            "description": values.description,
                                                            "day": values.day,
                                                            "date": values.date,
                                                        }
                                                    }, (Res) => {
                                                        setSubmitting(false);
                                                        if (Res?.statusCode === 200) {
                                                            window.location.href = `/holidays?page=${pageValue}`;
                                                        } else if (Res?.statusCode === 400) {
                                                            setErrors(Res?.data?.errors);
                                                        } else {
                                                            // window.location.reload();
                                                        }
                                                    }))

                                                }
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                isSubmitting,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                // setValues,
                                                setFieldValue
                                            }) => {
                                                return (
                                                    <div >
                                                        <div className="row" style={{ marginBottom: 150 }}>
                                                            <InputFromUser
                                                                label="Festival Name"
                                                                type="text"
                                                                name="festivalName"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values?.festivalName}
                                                                error={errors?.festivalName}
                                                                touched={touched.festivalName}
                                                                placeholder={'Enter Festival Name'}
                                                            />
                                                            <InputFromUser
                                                                label="Description"
                                                                type="text"
                                                                name="description"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values.description}
                                                                error={errors.description}
                                                                touched={touched.description}
                                                                placeholder={'Enter Description'}
                                                            />
                                                            <InputFromUser
                                                                label="date"
                                                                type="date"
                                                                name="date"
                                                                placement={'bottomLeft'}
                                                                onChange={(v) => {
                                                                    setFieldValue("date", v, true)
                                                                    const selectedDate = new Date(v);
                                                                    const dayOfWeek = selectedDate.toLocaleString('en-US', { weekday: 'long' });
                                                                    setFieldValue("day", dayOfWeek)
                                                                }} onBlur={handleBlur}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values.date}
                                                                error={errors.date}
                                                                touched={touched.date}
                                                                placeholder={'Enter date'}
                                                            />
                                                            <InputFromUser
                                                                label="Day"
                                                                type="singleSelect"
                                                                name="description"
                                                                onChange={(selectedValues) => {
                                                                    setFieldValue("day", selectedValues);
                                                                }}
                                                                onBlur={handleBlur}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values.day}
                                                                error={errors.day}
                                                                touched={touched.day}
                                                                options={Options.AllDayData}
                                                                placeholder={'Day'}
                                                            />
                                                            <div className="col-12" style={{ marginTop: 50 }}>
                                                                <div className="text-end">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            handleSubmit()
                                                                        }}
                                                                        className="btn btn-primary submit-form me-2"
                                                                        disabled={isSubmitting}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}

export default AddHoliday
