import { GET_APPCONFIG_LIST, APPCONFIG_LIST_SUCCESS, CREATE_APPCONFIG, UPDATE_APPCONFIG, DELETE_APPCONFIG, GET_PATIENT_PLAN_LIST, GET_PATIENT_LIST_PLAN_SUCCESS, GET_DISCHARGE_FORM, GET_DISCHARGE_FORM_SUCCESS } from "../storeTypes";

const INIT_STATE = {
    loader: false,
    appConfig: [],
    patientPlanList: [],
    patientDischarge: [],
};

function appConfigReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case GET_APPCONFIG_LIST: {
            return {
                ...state,
                appConfig: [],
                loader: true,
            };
        }
        case APPCONFIG_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                appConfig: action.payload,
            };
        }
        case GET_PATIENT_PLAN_LIST: {
            return {
                ...state,
                patientPlanList: [],
                loader: true,
            };
        }
        case GET_PATIENT_LIST_PLAN_SUCCESS: {
            return {
                ...state,
                loader: false,
                patientPlanList: action.payload,
            };
        }
        case GET_DISCHARGE_FORM: {
            return {
                ...state,
                patientDischarge: [],
                loader: true,
            };
        }
        case GET_DISCHARGE_FORM_SUCCESS: {
            return {
                ...state,
                loader: false,
                patientDischarge: action.payload,
            };
        }
        case CREATE_APPCONFIG: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_APPCONFIG: {
            return {
                ...state,
                loader: true,
            };
        }
        case DELETE_APPCONFIG: {
            return {
                ...state,
                loader: true,
            };
        }

        default: {
            return state;
        }
    }
}

export default appConfigReducer;
