/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { Button, Modal, Tag, Tooltip } from "antd";
import queryString from 'query-string';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ActionGetAllPendingOrderList } from "../../store/Actions/OrderHistoryAction";
import { calculatePercentage, generateQueryString, getStatus } from "../../utils/Funcations";
import TableColumnSearch1 from '../../components/Input/TableColumnSearch1';
import { TableListPageCom } from "../../components/TableListPageCom";
import { InputFromUser } from "../../components/Input/input";
import { PrakurtiCirculerLogo } from '../../components/imagepath';
import { PaymentStatusTag } from "../../components/Card/Cards";
import { ActionCreateByUrl } from "../../store/Actions/CommonAction";
import { useFormik } from "formik";
import { collectCashSchema } from "../../utils/FormValidation";

const PendingHistory = () => {
    const { loader, pendingOrder } = useSelector(state => state?.OrderHistory);
    const { userDetails } = useSelector(state => state?.auth);
    const [Refresh, setRefresh] = useState(false);
    const [Isloading, setIsloading] = useState(false);
    const [IsCollectModal, setCollectModal] = useState(null);
    const pageSize = 10;
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const dispatch = useDispatch();
    const location = useLocation();
    const pageParams = searchParams.get("page");
    const nameParams = searchParams.get("Name");
    const bookingForParams = searchParams.get("bookingFor");
    const paymentStatusParams = searchParams.get("paymentStatus");
    const amountParams = searchParams.get("amount");
    const params = queryString.parse(location.search);
    const [Query, setQuery] = useState({
        'page': pageParams,
        'bookingFor': bookingForParams,
        'name': nameParams,
        'paymentStatus': paymentStatusParams,
        'amount': amountParams,
    },);
    const isAppoinmentOrService = (IsCollectModal?.bookingFor === "Appointment" || IsCollectModal?.bookingFor === "Service")
    const PendingAmt = isAppoinmentOrService ?
        IsCollectModal?.amount : (IsCollectModal?.amount - IsCollectModal?.paidAmount)


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            paymentMode: '',
            updatedPaidAmount: PendingAmt || '',
            transactionId: '',
        },
        validationSchema: collectCashSchema,
        onSubmit: (values, { setFieldError, setErrors }) => {
            // console.log("*", values);
            let updatedStatus
            let TotalPaidAmt = parseInt(IsCollectModal?.paidAmount)
            let updatedPaidAmt = parseInt(values.updatedPaidAmount)
            const totalAmount = parseInt(IsCollectModal?.amount)
            updatedPaidAmt = parseInt(values.updatedPaidAmount) + TotalPaidAmt
            updatedStatus = updatedPaidAmt >= totalAmount ? 'Completed' : 'Pending'
            const Percentage = calculatePercentage(totalAmount, updatedPaidAmt)
            const payload = {
                updatedBy: IsCollectModal?.updatedBy,
                updateByName: IsCollectModal?.updateByName,
                paymentMode: values.paymentMode,
                transactionId: values?.transactionId,
                paidAmount: updatedPaidAmt,
                updatedPaidAmount: values.updatedPaidAmount,
                completedPayment: `${Percentage}%`,
                paymentStatus: updatedStatus,
            }
            dispatch(ActionCreateByUrl({ Query: `admin/order/order_update/${IsCollectModal?.orderId}`, obj: payload }
                , (Res) => {
                    if (Res?.statusCode === 200) {
                        setCollectModal(null)
                        setRefresh(true);
                    } else if (Res?.statusCode === 400) {
                        setErrors(Res?.data?.errors)
                    }
                }));
        },
    });

    const column = [
        {
            title: "Sr",
            dataIndex: "sr",
            // key: "Sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Name",
            dataIndex: "additionalInfo.name",
            render: (text, record) => (
                <Tooltip placement="topLeft" title={record?.isSimulation ? "Booked From Prakruti" : (record?.additionalInfo?.name || record?.additionalInfo?.customerName)}>
                    <div className="p-1"
                        style={record?.isSimulation ?
                            {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderColor: 'lightgray',
                                borderRadius: 20,
                                border: '3px solid lightgray',
                                borderWidth: 1
                            } :
                            {}}>
                        <Link to={`/order-history-overview/${(record?.id || record?._id)}?page=${params?.page || 1}`} className="ms-3" >
                            {(record?.additionalInfo?.name || record?.additionalInfo?.customerName)}
                        </Link >
                        {record?.isSimulation && <img
                            className="align-self-stretch"
                            src={PrakurtiCirculerLogo}
                            style={{ height: 33, width: 33 }}
                            alt="Logo"
                        />}
                    </div>
                </Tooltip>
            ),
            ...TableColumnSearch1({
                dataIndex: 'additionalInfo?.name',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
        },
        {
            title: "Booking For",
            dataIndex: "additionalInfo.gender",
            render: (text, record) => {
                return (
                    <span>{record?.bookingFor || '--'}</span>
                )
            },
            ...TableColumnSearch1({
                dataIndex: 'bookingFor',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
        },
        {
            title: "Status",
            dataIndex: "paymentStatus",
            render: (text, record, index) => {
                const paymentStatus = record?.paymentStatus
                const orderStatus = record?.orderStatus
                return (
                    <div className="row col">
                        <div>
                            <p><strong>{"Payment Status : "}</strong>
                                {paymentStatus &&
                                    <Tag color={getStatus(paymentStatus)}>
                                        {(paymentStatus) || '--'}</Tag>}
                            </p>
                            <p><strong>{"Order Status : "}</strong>
                                {orderStatus &&
                                    <Tag color={getStatus(orderStatus)}>
                                        {(orderStatus) || '--'}</Tag>}
                            </p>
                        </div>
                    </div>
                )
            },
            filters: ['Pending', 'Initialize'],
            ...TableColumnSearch1({
                type: 'Dropdown',
                dataIndex: 'paymentStatus',
                value: Query,
                onChange: (v) => {
                    setQuery(v)
                }
            }),
        },
        {
            title: "Payment Mode",
            dataIndex: "paymentMode",
            render: (text, record) => (
                <span>{record?.paymentMode || '--'}</span>
            ),
        },
        {
            title: "Order Type",
            dataIndex: "orderType",
            render: (text, record) => (
                <span>{record?.orderType || '--'}</span>
            ),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (text, record) => {
                const isAppoinmentOrService = (record?.bookingFor === "Appointment" || record?.bookingFor === "Service")
                const PendingAmt = isAppoinmentOrService ?
                    record?.amount : (record?.amount - record?.paidAmount)
                return (
                    <div className="row col">
                        <div>
                            <p><strong>{"Total  : "}</strong>
                                {record?.amount + '/-'}
                            </p>
                            <p><strong>{"Pending : "}</strong>
                                <PaymentStatusTag
                                    amount={PendingAmt}
                                    paymentStatus={'Pending'}
                                    orderStatus={record?.paymentStatus}
                                />
                            </p>
                        </div>
                    </div>
                )
            },
            ...TableColumnSearch1({
                dataIndex: 'amount',
                value: Query,
                onChange: (v) => {
                    setQuery(v)
                }
            }),
        },
        {
            title: "Arriving",
            dataIndex: "createdAt",
            render: (text, record) => {
                return (
                    <div className="row col">
                        <div>
                            <p><strong>{"Date : "}</strong>
                                {(record?.additionalInfo?.startDate && ((record?.additionalInfo?.startDate) + " To " + (record?.additionalInfo?.endDate))
                                    || (record?.additionalInfo?.bookingDate) || (record?.additionalInfo?.date)
                                )}
                            </p>
                            <p><strong>{"Time : "}</strong>
                                {(record?.additionalInfo?.startTime)}
                            </p>
                        </div>
                    </div>
                )
            },
        },
        {
            title: "Actions",
            dataIndex: "",
            render: (text, record, index) => {
                const isAppoinmentOrService = (record?.bookingFor === "Appointment" || record?.bookingFor === "Service")
                const PendingAmt = isAppoinmentOrService ?
                    record?.amount : (record?.amount - record?.paidAmount)
                return (
                    <>
                        <Button type="primary"
                            disabled={record?.additionalInfo?.completedPayment === '100%'}
                            onClick={() => {
                                formik.resetForm();
                                const CollectCashPayload = {
                                    ...record,
                                    orderId: (record?.id || record?._id),
                                    updatedBy: userDetails?.[0]?._id,
                                    updateByName: userDetails?.[0]?.name,
                                    updatedPaymentMode: '',
                                    transactionId: '',
                                    updatedPaidAmount: isAppoinmentOrService ? PendingAmt : '',
                                    completedPayment: record?.additionalInfo?.completedPayment,
                                    paymentStatus: '',
                                }
                                setCollectModal(CollectCashPayload)
                            }}>{record?.additionalInfo?.completedPayment === '100%' ? "Payment Completed" : 'Collect'}</Button>
                    </>
                )
            },
        },

    ];


    const handleSearch1 = (searchTerm) => {
        var newp = params
        newp.page = searchTerm
        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
    }

    const Filters = generateQueryString(
        ['page', (Query.page || 1)],
        ['name', (Query.nameParams)],
        ['paymentStatus', Query.paymentStatus],
        ['bookingFor', Query.bookingFor],
        ['amount', Query.amount],
    )
    useEffect(() => {
        dispatch(ActionGetAllPendingOrderList({ Query: `/orders_pending${Filters}` }));
        setRefresh(false);
        window.history.replaceState(null, '', Filters);
    }, [Filters, dispatch, Query, Refresh])

    const BreadcrumbList = [{
        name: 'Pending & History',
        linkTo: `/pending-history?page=${Query?.page || 1}`
    },
    {
        name: 'Pending & History List',
    }
    ]
    return (<>
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={pendingOrder?.docs}
            TableColumn={column}
            Filters={Filters}
            ShowAddButton={false}
            refreshOnClick={() => {
                setRefresh(true)
                Filters === ''
                setQuery({})
            }}
            PageMainTitle="Pending & History"
            CurrentPage={parseInt(params?.page) || 1}
            Tableloader={loader}
            TotalPageList={pendingOrder?.totalDocs}
            dataSourceLength={pendingOrder?.docs?.length}
            handlePageChange={(v) => handleSearch1(v)}
        />
        <Modal
            open={IsCollectModal?.orderId}
            closable={true}
            forceRender={true}
            confirmLoading={Isloading}
            zIndex={1050}
            title={IsCollectModal?.additionalInfo?.customerName}
            style={{ margin: 15, padding: 15 }}
            onOk={() => {
                formik.handleSubmit()
            }}
            styles={{
                body: {
                    overflow: "hidden",
                },
                mask: {
                    backdropFilter: 'blur(10px)',
                },
                content: {
                    borderRadius: 40,
                },
            }}
            okText={'Submit'}
            centered
            onCancel={() => {
                setCollectModal(null)
            }}
            closeIcon={true}
        >
            <div>
                <InputFromUser
                    FormGroupClassName={"col-xl-12"}
                    LabelClassName={"col-form-label"}
                    type='singleSelect'
                    FormControlClassName={'form-control '}
                    label="Payment Mode"
                    options={['Cash', 'Card', 'Cheque', 'UPI']}
                    value={formik.values?.paymentMode}
                    name="paymentMode"
                    error={formik?.errors?.paymentMode}
                    touched={formik.touched.paymentMode}
                    onBlur={() =>
                        formik.setFieldTouched('paymentMode', true)
                    }
                    onChange={(value) => {
                        formik.setFieldValue('paymentMode', value);
                    }} />
                <InputFromUser
                    FormGroupClassName={"col-xl-12"}
                    LabelClassName={"col-form-label"}
                    placeholder={"Enter Amount"}
                    disabled={isAppoinmentOrService}
                    FormControlClassName={'form-control '}
                    label={`Amount (Pending - ${PendingAmt})`}
                    type={'number'}
                    name="amount"
                    error={formik?.errors?.updatedPaidAmount}
                    value={formik.values?.updatedPaidAmount}
                    touched={formik.touched.updatedPaidAmount}
                    onBlur={() =>
                        formik.setFieldTouched('updatedPaidAmount', true)
                    }
                    onChange={(e) => {
                        formik.setFieldValue('updatedPaidAmount', e?.target?.value);
                    }}
                />

                <InputFromUser
                    FormGroupClassName={"col-xl-12"}
                    LabelClassName={"col-form-label"}
                    placeholder={"Enter Transaction ID"}
                    FormControlClassName={'form-control '}
                    label="Transaction ID"
                    type={'text'}
                    name="transactionId"
                    error={formik?.errors?.transactionId}
                    value={formik.values?.transactionId}
                    touched={formik.touched.transactionId}
                    onBlur={() =>
                        formik.setFieldTouched('transactionId', true)
                    }
                    onChange={(e) => {
                        formik.setFieldValue('transactionId', e?.target?.value);
                    }}
                />
            </div>
        </Modal>
    </>
    );
};

export default PendingHistory;