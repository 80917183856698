/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import Header from '../../../components/Header'
import Sidebar from '../../../components/Sidebar'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { Button, Divider } from 'antd'
import { InputFromUser, TextEditor } from '../../../components/Input/input'
import UploadImage from '../../../components/Upload/Upload'
import { ExpendableTable } from '../../../components/Table/expendableTable'
import { AddDetoxRetreatValidationSchema } from '../../../utils/FormValidation'
import { ActionGetDetoxRetreat } from '../../../store/Actions/MasterDataAction'
import { ActionCreateByUrl, ActionUpdateById, ActionUploadImage } from '../../../store/Actions/CommonAction'
import { DOCS_URL } from '../../../utils/globals'
import { isNullOrUndef } from '../../../utils/Funcations'
import dayjs from 'dayjs'
const AddEditDetoxRetreat = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const { detoxRetreat } = useSelector(state => state?.MasterData);
    const idValue = searchParams.get("id");
    const updateValue = searchParams.get("update");
    useEffect(() => {
        if (idValue) {
            dispatch(ActionGetDetoxRetreat({ Query: '/' + idValue }));
        }
    }, [])
    return (
        <>
            <Header />
            <Sidebar id="menu-item1" id1="menu-items1" activeClassName="add-staff" />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/add-detoxretreat?page=1">Detox Retreat </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Add Detox Retreat</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                startDate: detoxRetreat?.[0]?.startDate && dayjs(detoxRetreat?.[0]?.startDate) || null,
                                                endDate: detoxRetreat?.[0]?.endDate && dayjs(detoxRetreat?.[0]?.endDate) || null,
                                                bannerImage: detoxRetreat?.[0]?.bannerImage || '',
                                                title: detoxRetreat?.[0]?.title || '',
                                                description: detoxRetreat?.[0]?.description || '',
                                                price: detoxRetreat?.[0]?.price || '',
                                                videoLinks: detoxRetreat?.[0]?.videoLinks || '',
                                                introduction: detoxRetreat?.[0]?.introduction || [{ "decription": '', "videoLink": '', "image": '' }],
                                                wordsOfGratitude: detoxRetreat?.[0]?.wordsOfGratitude || [{ "decription": '', "videoLink": '', "image": '' }],
                                                features: detoxRetreat?.[0]?.features || [''],
                                                aboutHost: [detoxRetreat?.[0]?.aboutHost] || [{ "name": '', "description": '', "aboutHostImage": '' }],
                                                whoIsThisFor: detoxRetreat?.[0]?.whoIsThisFor || [{ "icon": "", 'title': "" }],
                                                whoIsThisForImage: detoxRetreat?.[0]?.whoIsThisForImage || '',
                                                benefits: detoxRetreat?.[0]?.benefits || [{ "title": "", "description": "", "image": '', "orderIndex": '' }],
                                                dayWisePlan: detoxRetreat?.[0]?.dayWisePlan || [{ "dayNo": '', "time": '', "task": '' }],
                                                whatIncluded: detoxRetreat?.[0]?.whatIncluded || [{ "icon": 'correct', "title": '', "orderIndex": '' }],
                                                rulesRegulations: detoxRetreat?.[0]?.rulesRegulations || [{ "icon": '', "title": '', "orderIndex": '', "description": '' }],
                                                detoxRetreatFaqs: detoxRetreat?.[0]?.detoxRetreatFaqs || [{ "title": '', "description": '', "orderIndex": '' }],

                                            }}
                                            validationSchema={AddDetoxRetreatValidationSchema}
                                            onSubmit={(values, { setErrors, setSubmitting }) => {
                                                const updatedValues = {
                                                    ...values,
                                                    aboutHost: values.aboutHost?.[0],
                                                    startDate: values.startDate.format('YYYY-MM-DD'),
                                                    endDate: values.endDate.format('YYYY-MM-DD'),
                                                };
                                                if (updateValue && idValue != null) {
                                                    dispatch(ActionUpdateById({ Query: `admin/detox-retreat/${idValue}`, obj: updatedValues }
                                                        , (Res) => {
                                                            if (Res?.statusCode === 200) {
                                                                window.location.href = `/detoxretreat-list?page=1`
                                                            } else if (Res?.statusCode === 400) {
                                                                setErrors(Res?.data?.errors);
                                                            }
                                                        }));
                                                    setSubmitting(false);
                                                } else {
                                                    //CreateTreatment
                                                    dispatch(ActionCreateByUrl({ Query: `admin/detox-retreat`, obj: updatedValues }
                                                        , (Response) => {
                                                            if (Response?.statusCode === 200) {
                                                                window.location.href = "/detoxretreat-list?page=1"
                                                            }
                                                        }))
                                                    setSubmitting(false);
                                                }
                                            }}

                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                setFieldValue,
                                                setFieldTouched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                // setValues,
                                                // setSubmitting,
                                                // isSubmitting,
                                                /* and other goodies */
                                            }) => {


                                                return (
                                                    <Form>
                                                        <div className="row">
                                                            <div className="my-4" >
                                                                <Divider style={{ fontWeight: "600" }}>Detox-Retreat</Divider>
                                                            </div>
                                                            <InputFromUser
                                                                label="Start Date"
                                                                type="date"
                                                                name="startDate"
                                                                onChange={(v) => {
                                                                    setFieldValue("startDate", v, true)
                                                                    const selectedDate = new Date(v);
                                                                    const dayOfWeek = selectedDate.toLocaleString('en-US', { weekday: 'long' });
                                                                    setFieldValue("day", dayOfWeek)
                                                                }}
                                                                onBlur={handleBlur}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-4 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values.startDate}
                                                                error={errors.startDate}
                                                                touched={touched.startDate}
                                                                placeholder={'Select Start Date '}
                                                            />
                                                            <InputFromUser
                                                                label="End Date"
                                                                type="date"
                                                                name="endDate"
                                                                onChange={(v) => {
                                                                    setFieldValue("endDate", v, true)
                                                                    const selectedDate = new Date(v);
                                                                    const dayOfWeek = selectedDate.toLocaleString('en-US', { weekday: 'long' });
                                                                    setFieldValue("day", dayOfWeek)
                                                                }}
                                                                onBlur={handleBlur}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-4 col-md-12 "}
                                                                LabelClassName={"col-form-label"}
                                                                value={values.endDate}
                                                                error={errors.endDate}
                                                                touched={touched.endDate}
                                                                placeholder={'Select End Date '}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Enter Title"}
                                                                FormControlClassName={'form-control '}
                                                                label="Title"
                                                                name="title"
                                                                onChange={handleChange}
                                                                value={values?.title}
                                                                onBlur={handleBlur}
                                                                error={errors?.title}
                                                                touched={touched?.title}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Enter Price"}
                                                                FormControlClassName={'form-control '}
                                                                label="Price"
                                                                name="price"
                                                                onChange={handleChange}
                                                                value={values?.price}
                                                                onBlur={handleBlur}
                                                                error={errors?.price}
                                                                touched={touched?.price}
                                                            />
                                                            <InputFromUser
                                                                placeholder={"Paste Your YouTube Video Link"}
                                                                type="url"
                                                                name="videoLinks"
                                                                onChange={handleChange}
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                onBlur={handleBlur}
                                                                value={values.videoLinks}
                                                                label="Iframe Link"
                                                                error={errors?.videoLinks}
                                                                touched={touched.videoLinks}
                                                            />
                                                            <div className="my-4" >
                                                                <Divider style={{ fontWeight: "600" }}> Description</Divider></div>
                                                            <TextEditor
                                                                value={values?.description}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();

                                                                    setFieldValue("description", data, true)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="row mt-4">
                                                            <UploadImage
                                                                className={"img-fluid"}
                                                                fileList={!isNullOrUndef(values?.bannerImage) && [{
                                                                    uid: 1 + values?.bannerImage,
                                                                    name: values?.bannerImage,
                                                                    status: 'done',
                                                                    url: DOCS_URL + values?.bannerImage,
                                                                }] || []}
                                                                handleChange={(v) => {
                                                                    if (v.file && v.file.status === 'uploading') {
                                                                        const formData = new FormData();
                                                                        formData.append("isDetoxRetreat", true);
                                                                        formData.append("attachment", v?.file?.originFileObj);
                                                                        dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                            if (typeof res === 'object') {
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: 'bannerImage',
                                                                                        value: res?.[0],
                                                                                    },
                                                                                });
                                                                            }
                                                                        }))
                                                                    } else if (v.file && v.file.status === 'removed') {
                                                                        handleChange({
                                                                            target: {
                                                                                name: 'bannerImage',
                                                                                value: '',
                                                                            },
                                                                        });
                                                                    }
                                                                }}
                                                                // errorMessage={errors?.bannerImage}
                                                                UploadImagelimit={1} />

                                                        </div>
                                                        <div className="row">
                                                            <div className="my-4" >
                                                                <Divider style={{ fontWeight: "600" }}>Facilities</Divider></div>
                                                            {values?.features?.map((item, index) => {
                                                                return (
                                                                    <tr key={"features" + index + 'tr'}>
                                                                        <div className="d-flex">
                                                                            <td className="col-xl-6 col-md-10" key={"features" + index + 'td'}>
                                                                                {
                                                                                    <InputFromUser
                                                                                        placeholder={'Enter Facilities'}
                                                                                        error={errors?.features}
                                                                                        value={item}
                                                                                        onChange={(v) => {
                                                                                            const updatedSolutionFor = [...(values?.features || [])];
                                                                                            updatedSolutionFor[index] = v?.target?.value;
                                                                                            setFieldValue("features", updatedSolutionFor, true)
                                                                                        }}
                                                                                        touched={touched?.features}
                                                                                    />
                                                                                }
                                                                            </td>
                                                                            <td className="col-2 add-remove text-end align-items-center" key={"td6" + index}>
                                                                                <Button
                                                                                    type=""
                                                                                    className="add-btn "
                                                                                    onClick={() => {
                                                                                        const newValue = [''];
                                                                                        setFieldValue("features", [...values.features, newValue])
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                </Button>
                                                                                <Button
                                                                                    style={{ border: 'none' }}
                                                                                    className="remove-btn"
                                                                                    onClick={() => {
                                                                                        if (index !== 0) {
                                                                                            const updatedBenefits = [...values.features];
                                                                                            updatedBenefits.splice(index, 1);
                                                                                            setFieldValue("features", updatedBenefits);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <i className="fe fe-trash-2">
                                                                                        <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                    </i>
                                                                                </Button>
                                                                            </td>
                                                                        </div>

                                                                    </tr>
                                                                )

                                                            })}

                                                            <ExpendableTable
                                                                Title={"Introduction"}
                                                                emptyDataForCreatingField={{ "decription": "", "videoLink": "", "image": '', }}
                                                                Data={values?.introduction || []}
                                                                Columns={[
                                                                    { name: 'decription', type: 'text', label: 'Description' },
                                                                    { name: 'videoLink', type: 'url', label: 'Video Link' },
                                                                    { name: 'image', type: 'upload', label: 'Image' },
                                                                    { name: 'Action', type: 'action', label: 'Icon' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("introduction", v, true)}
                                                                onBlur={(v) => setFieldTouched("introduction", v, true)}
                                                                errors={errors?.introduction}
                                                                touched={touched?.introduction}
                                                            />
                                                            <ExpendableTable
                                                                Title={"Words Of Gratitude"}
                                                                emptyDataForCreatingField={{ "decription": "", "videoLink": "", "image": '', }}
                                                                Data={values?.wordsOfGratitude || []}
                                                                Columns={[
                                                                    { name: 'decription', type: 'text', label: 'Description' },
                                                                    { name: 'videoLink', type: 'url', label: 'Video Link' },
                                                                    { name: 'image', type: 'upload', label: 'Image' },
                                                                    { name: 'Action', type: 'action', label: 'Icon' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("wordsOfGratitude", v, true)}
                                                                onBlur={(v) => setFieldTouched("wordsOfGratitude", v, true)}
                                                                errors={errors?.wordsOfGratitude}
                                                                touched={touched?.wordsOfGratitude}
                                                            />
                                                            {values?.aboutHost?.map((value, index) => {

                                                                return (
                                                                    <div key={index}>
                                                                        <div className="my-4">
                                                                            <Divider style={{ fontWeight: "600" }}>About Our Host</Divider>
                                                                        </div>
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            placeholder={"Enter Your Host Name"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Host Name"
                                                                            name={`aboutHost`}
                                                                            type={'text'}
                                                                            error={errors?.aboutHost?.[index]?.name}
                                                                            touched={touched?.aboutHost?.[index]?.name}
                                                                            value={value?.name}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`aboutHost`, [{ ...values.aboutHost?.[0], name: e.target.value }]);
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                        />
                                                                        <UploadImage
                                                                            className={"img-fluid"}
                                                                            fileList={!isNullOrUndef(value?.aboutHostImage) && [{
                                                                                uid: 1 + value?.aboutHostImage,
                                                                                name: value?.aboutHostImage,
                                                                                status: 'done',
                                                                                url: DOCS_URL + value?.aboutHostImage,
                                                                            }] || []}
                                                                            handleChange={(v) => {
                                                                                if (v.file && v.file.status === 'uploading') {
                                                                                    const formData = new FormData();
                                                                                    formData.append("isDetoxRetreat", true);
                                                                                    formData.append("attachment", v?.file?.originFileObj);
                                                                                    dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                        if (typeof res === 'object') {
                                                                                            setFieldValue(`aboutHost`, [{ ...values.aboutHost?.[0], aboutHostImage: res[0] }]);
                                                                                        }
                                                                                    }));
                                                                                } else if (v.file && v.file.status === 'removed') {
                                                                                    setFieldValue(`aboutHost`, [{ ...values.aboutHost?.[0], aboutHostImage: "" }]);
                                                                                }
                                                                            }}
                                                                            UploadImagelimit={1}
                                                                        />
                                                                        <div className="my-4">
                                                                            <Divider style={{ fontWeight: "600" }}>Host Description</Divider>
                                                                        </div>
                                                                        <div id="editor">
                                                                            <TextEditor
                                                                                value={value?.description || ''}
                                                                                onChange={(event, editor) => {
                                                                                    const data = editor.getData();

                                                                                    if (data) {
                                                                                        // setFieldValue(`aboutHost`, [{ ...values.aboutHost?.[0], description: data }]);
                                                                                        setFieldValue(`aboutHost[0].description`, data);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )


                                                            }
                                                            )}









                                                        </div>

                                                        <ExpendableTable
                                                            Title={"Who Is This For"}
                                                            emptyDataForCreatingField={{ "title": "", "icon": '', }}
                                                            Data={values?.whoIsThisFor || []}
                                                            Columns={[
                                                                { name: 'title', type: 'text', label: 'Title' },
                                                                { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                { name: 'icon', type: 'upload', label: 'Icon' },
                                                                { name: 'Action', type: 'action', label: 'Icon' },
                                                            ]}
                                                            onChange={(v) => setFieldValue("whoIsThisFor", v, true)}
                                                            onBlur={(v) => setFieldTouched("whoIsThisFor", v, true)}
                                                            errors={errors?.whoIsThisFor}
                                                            touched={touched?.whoIsThisFor}
                                                            HeaderComponent={
                                                                <UploadImage
                                                                    className={"img-fluid"}
                                                                    fileList={!isNullOrUndef(values?.whoIsThisForImage) && [{
                                                                        uid: 1 + values?.whoIsThisForImage,
                                                                        name: values?.whoIsThisForImage,
                                                                        status: 'done',
                                                                        url: DOCS_URL + values?.whoIsThisForImage,
                                                                    }] || []}
                                                                    handleChange={(v) => {
                                                                        if (v.file && v.file.status === 'uploading') {
                                                                            const formData = new FormData();
                                                                            formData.append("isDetoxRetreat", true);
                                                                            formData.append("attachment", v?.file?.originFileObj);
                                                                            dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                if (typeof res === 'object') {
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'whoIsThisForImage',
                                                                                            value: res?.[0],
                                                                                        },
                                                                                    });
                                                                                }
                                                                            }))
                                                                        } else if (v.file && v.file.status === 'removed') {
                                                                            handleChange({
                                                                                target: {
                                                                                    name: 'whoIsThisForImage',
                                                                                    value: '',
                                                                                },
                                                                            });
                                                                        }
                                                                    }}
                                                                    // errorMessage={errors?.whoIsThisForImage}
                                                                    UploadImagelimit={1} />
                                                            }
                                                        />
                                                        <ExpendableTable
                                                            Title={"Benefits"}
                                                            emptyDataForCreatingField={{ "title": "", "description": "", "image": '', "orderIndex": '' }}
                                                            Data={values?.benefits || []}
                                                            Columns={[
                                                                { name: 'title', type: 'text', label: 'Title' },
                                                                { name: 'description', type: 'text', label: 'Description' },
                                                                { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                { name: 'image', type: 'upload', label: 'Image' },
                                                                { name: 'Action', type: 'action', label: 'Icon' },
                                                            ]}
                                                            onChange={(v) => setFieldValue("benefits", v, true)}
                                                            onBlur={(v) => setFieldTouched("benefits", v, true)}
                                                            errors={errors?.benefits}
                                                            touched={touched?.benefits}
                                                        />
                                                        <ExpendableTable
                                                            Title={"Day Wise Plan"}
                                                            emptyDataForCreatingField={{ "Day No": "", "time": "", "task": '' }}
                                                            Data={values?.dayWisePlan || []}
                                                            Columns={[
                                                                { name: 'dayNo', type: 'text', label: 'Day No' },
                                                                { name: 'time', type: 'text', label: 'Time' },
                                                                { name: 'task', type: 'text', label: 'Task' },

                                                                { name: 'Action', type: 'action', label: 'Icon' },
                                                            ]}
                                                            onChange={(v) => setFieldValue("dayWisePlan", v, true)}
                                                            onBlur={(v) => setFieldTouched("dayWisePlan", v, true)}
                                                            errors={errors?.dayWisePlan}
                                                            touched={touched?.dayWisePlan}
                                                        />
                                                        <ExpendableTable
                                                            Title={"What's Included"}
                                                            emptyDataForCreatingField={{ "title": "", "icon": '', "orderIndex": '' }}
                                                            Data={values?.whatIncluded || []}
                                                            Columns={[
                                                                { name: 'title', type: 'text', label: 'Title' },
                                                                { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                { name: 'icon', type: 'upload', label: 'Icon' },
                                                                { name: 'Action', type: 'action', label: 'Icon' },
                                                            ]}
                                                            onChange={(v) => setFieldValue("whatIncluded", v, true)}
                                                            onBlur={(v) => setFieldTouched("whatIncluded", v, true)}
                                                            errors={errors?.whatIncluded}
                                                            touched={touched?.whatIncluded}
                                                        />
                                                        <ExpendableTable
                                                            Title={"Rules & Regulations"}
                                                            emptyDataForCreatingField={{ "title": "", "icon": '', "orderIndex": '', "description": '' }}
                                                            Data={values?.rulesRegulations || []}
                                                            Columns={[
                                                                { name: 'title', type: 'text', label: 'Title' },
                                                                { name: 'description', type: 'text', label: 'Description' },

                                                                { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                { name: 'icon', type: 'upload', label: 'Icon' },
                                                                { name: 'Action', type: 'action', label: 'Icon' },
                                                            ]}
                                                            onChange={(v) => setFieldValue("rulesRegulations", v, true)}
                                                            onBlur={(v) => setFieldTouched("rulesRegulations", v, true)}
                                                            errors={errors?.rulesRegulations}
                                                            touched={touched?.rulesRegulations}
                                                        />
                                                        <ExpendableTable
                                                            Title={"Faqs"}
                                                            emptyDataForCreatingField={{ "title": "", "description": '', "orderIndex": '' }}
                                                            Data={values?.detoxRetreatFaqs || []}
                                                            Columns={[
                                                                { name: 'title', type: 'text', label: 'Title' },
                                                                { name: 'description', type: 'text', label: 'description' },
                                                                { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                { name: 'Action', type: 'action', label: 'Icon' },
                                                            ]}
                                                            onChange={(v) => setFieldValue("detoxRetreatFaqs", v, true)}
                                                            onBlur={(v) => setFieldTouched("detoxRetreatFaqs", v, true)}
                                                            errors={errors?.detoxRetreatFaqs}
                                                            touched={touched?.detoxRetreatFaqs}
                                                        />
                                                        <div className="col-12">
                                                            <div className="text-end">
                                                                <Button
                                                                    onClick={() => {
                                                                        handleSubmit();
                                                                    }}
                                                                    type="primary"
                                                                    //disabled={isSubmitting}
                                                                    className="pb-4"
                                                                >Submit</Button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        </>
    )
}

export default AddEditDetoxRetreat