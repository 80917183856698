/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
// import Header from "../../../../Header";
import Sidebar from "../../../Sidebar";
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../Header";
import { ActionGetAllOrderList } from "../../../../store/Actions/OrderHistoryAction";
import { useEffect } from "react";
import { Card, Tag } from "antd";
import { CustomTable } from "../../../Table";
import moment from 'moment';
import { Breadcrumb } from "../../../Card/Cards";
import { getStatus } from "../../../../utils/Funcations";
import queryString from "query-string";

const OrderHistoryOverView = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const pageParams = searchParams.get("page");
    const params = queryString.parse(location.search);
    const { order, loader } = useSelector(state => state?.OrderHistory);

    useEffect(() => {
        if (id) {
            dispatch(ActionGetAllOrderList({ Query: `/` + id }));
        }
    }, [dispatch])

    const gridStyle = { borderWidth: 0, borderColor: 'red', margin: 5 };
    const ListItems = (title, value) => {
        return (
            <p><strong style={{ color: "black" }}>{title + ' : '}</strong>
                <a>  {value || '---'}</a></p>)
    }
    const InsideTableCard = (title, value) => {
        return (
            <Card style={{
                padding: 0,
                margin: 3,
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center',
            }}
                bodyStyle={{
                    paddingBottom: 0,
                    alignSelf: "center",
                    alignItems: 'center'
                }}
                hoverable={true} >
                <p className=""><strong>{title + ' : '}</strong>
                    {value || '---'}</p>
            </Card>
        )
    }
    const IsAppointmentBooking = order?.bookingFor === 'Appointment'
    const CardClassName = `col-md-4 col-xxl-${(IsAppointmentBooking) ? '2' : '3'}`
    const GuestColumn = [
        {
            title: "Sr",
            dataIndex: "sr",
            key: "Sr",
            render: (text, record, index) => {
                const currentPage = 1;
                const calculatedIndex = (currentPage - 1) * 10 + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        }, {
            title: "Detailes",
            dataIndex: "name",
            key: "name",
            render: (text, record, index) => {

                return (
                    <div className="row my-md-4 my-5">
                        {ListItems('Name', record?.name)}
                        {ListItems('Gender', record.gender)}
                        {ListItems('BirthDate', record.birthDate)}
                    </div>
                )
            }
        }, {
            title: "Contact",
            dataIndex: "email",
            key: "email",
            render: (text, record, index) => {
                return (
                    <div className="row my-md-4 my-5">
                        {ListItems('Email', record.email)}
                        {ListItems('PhoneNo', record.phoneNo)}
                    </div>
                )
            }
        }, {
            title: "Other",
            dataIndex: "guestbookingFor",
            key: "guestbookingFor",
            render: (text, record, index) => {
                const guestbookingFor = record.guestbookingFor
                return (
                    <div className="row my-md-4 my-5">
                        {ListItems('Booking For', record.guestbookingFor)}
                        {ListItems('Service', guestbookingFor === 'Treatment' ? record.treatmentData?.[0]?.treatmentName : record.solutionData?.[0]?.solutionName)}
                        {ListItems('Price', guestbookingFor === 'Treatment' ? record.treatmentData?.[0]?.perDayPrice : record.solutionData?.[0]?.perDayPrice)}
                    </div>
                )
            }
        },
    ]
    const RoomColumn = [
        {
            title: "Sr",
            dataIndex: "sr",
            key: "Sr",
            render: (text, record, index) => {
                const currentPage = 1;
                const calculatedIndex = (currentPage - 1) * 10 + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        }, {
            title: "Room Details",
            dataIndex: "roomType",
            key: "name",
            render: (text, record, index) => {
                return (
                    <div className="row my-md-2">
                        {InsideTableCard('Room Type', record.roomType)}
                        {InsideTableCard('Price', record.price)}
                        {InsideTableCard('Type', record.type)}
                    </div>
                )
            }
        }, {
            title: "Booked For",
            dataIndex: "email",
            key: "email",
            render: (text, record, index) => {
                return (
                    <div className="row my-md-2">
                        {ListItems('Name', record.name)}
                        {ListItems('Gender', record.gender)}
                    </div>
                )
            }
        },
    ]
    const orderHistoryColumn = [
        {
            title: "Sr",
            dataIndex: "sr",
            key: "Sr",
            render: (text, record, index) => {
                const currentPage = 1;
                const calculatedIndex = (currentPage - 1) * 10 + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        }, {
            title: "Name",
            dataIndex: "roomType",
            key: "name",
            render: (text, record, index) => {

                return (<div>{(record.additionalInfo.name || record.additionalInfo.customerName)}</div>)
            }
        }, {
            title: "Amount",
            dataIndex: "email",
            key: "email",
            render: (text, record, index) => {
                const PendingAmount = (record?.amount - record?.paidAmount)
                return (
                    <div className="row my-md-2">
                        {ListItems('Paid Amount', record?.paidAmount)}
                        {ListItems('Last Paid Amount', record?.updatedPaidAmount)}
                        {ListItems(
                            (index === 0 ? "Total Amount" : 'Pending Amount'),
                            (index === 0 ? record?.amount : PendingAmount?.toString()))}
                    </div>
                )
            }
        },
        {
            title: "Booking",
            dataIndex: "isReschedule",
            key: "paymentMode",
            render: (text, record, index) => {

                const RecordStartDate = record?.additionalInfo?.startDate
                const RecordEndDate = record?.additionalInfo?.endDate
                const RecordSerBookingDate = record?.additionalInfo?.bookingDate
                const RecordStartTime = record?.additionalInfo?.startTime
                const RecordEndTime = record?.additionalInfo?.endTime

                const RescheduledTheme = <div className="row my-md-2">
                    {ListItems('Check-In', RecordStartDate)}
                    {ListItems('Check-Out', RecordEndDate)}</div>


                const serviceTheme = <div className="row my-md-2">
                    {ListItems('Booking Date', RecordSerBookingDate)}
                    {ListItems('Check-in', RecordStartTime)}</div>
                const isAppo = (record?.bookingFor === "Appointment")
                const isServ = (record?.bookingFor === "Service")
                return (<div>
                    {text && ListItems('Rescheduled', '✔️')}{
                        text && ((isAppo ?
                            (record.additionalInfo.date + " (" + RecordStartTime + " To " + RecordEndTime + ")") :
                            isServ ? serviceTheme :
                                RescheduledTheme) || '--') ||


                        // Not For Reschedule
                        ((isAppo ?
                            (record.additionalInfo.date + " (" + RecordStartTime + " To " + RecordEndTime + ")") :
                            isServ ? serviceTheme :
                                RescheduledTheme) || 'Not Rescheduled')}
                </div>)
            }
        }, {
            title: "Transaction Id",
            dataIndex: "transactionId",
            key: "paymentMode",
            render: (text, record, index) => {
                return (<div>{record.additionalInfo.transactionId || '---'}</div>)
            }
        }, {
            title: "Completed Payment",
            dataIndex: "paymentMode",
            key: "paymentMode",
            render: (text, record, index) => {
                return (<div>{record.additionalInfo.completedPayment}</div>)
            }
        },
        {
            title: "Payment Mode",
            dataIndex: "paymentMode",
            key: "paymentMode",

        },
    ]
    const BreadcrumbList = [{
        name: 'Order & History',
        linkTo: `/order-history?page=${pageParams || 1}`
    },
    {
        name: 'Order & History List',
        linkTo: `/order-history?page=${pageParams || 1}`
    }, {
        name: 'Order & History Overview',
    },
    ]
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            <Breadcrumb page={BreadcrumbList} />
                            <div className="card-box profile-header row" style={{ justifyContent: "space-around", padding: 0 }}>
                                <Card style={gridStyle} className={CardClassName} >
                                    <Link to={`/patient-list/patient-profile?patientId=${order?.customerId}&page=${params?.page}`}>{ListItems('Name ', (order?.additionalInfo?.name || order?.additionalInfo?.customerName || '---'))}</Link>
                                    {ListItems('Email ', (order?.additionalInfo?.email || '---'))}
                                    {ListItems('Phone No ', (order?.additionalInfo?.phoneNo || '---'))}
                                    {ListItems('Gender ', (order?.additionalInfo?.gender || '---'))}
                                    {ListItems('BirthDate ', (order?.additionalInfo?.birthDate && moment(order?.additionalInfo?.birthDate).format('DD-MM-YYYY') || '---'))}
                                </Card>
                                <Card style={gridStyle} className="col-md-4 col-xxl-3">
                                    <p><strong>Amount : </strong> {(order?.amount + '/-') || '---'} </p>
                                    <p><strong>Payment Status: </strong>
                                        {order?.paymentStatus && <Tag color={getStatus(order?.paymentStatus)}>
                                            {(order?.paymentStatus) || '--'}</Tag>}</p>

                                    <p><strong>Order Status:</strong> {order?.orderStatus &&
                                        <Tag color={getStatus(order?.orderStatus)}>
                                            {(order?.orderStatus) || '--'}</Tag>}</p>

                                    {!(order?.bookingFor === 'Service') && <p><strong>Completed Payment:</strong>  {order?.paymentStatus &&
                                        <Tag color={getStatus(order?.paymentStatus)}>
                                            {(order?.additionalInfo?.completedPayment || order?.completedPayment) || '--'}</Tag>
                                    }</p>}
                                    {ListItems('Order Type ', (order?.orderType || '---'))}
                                    {ListItems('Transaction Id', (order?.transactionId || '---'))}
                                    {ListItems('Payment Mode ', (order?.paymentMode || '---'))}
                                </Card>
                                <Card style={gridStyle} className={CardClassName}>
                                    {ListItems('Booking For ', (order?.additionalInfo?.bookingFor || order?.bookingFor || '---'))}

                                    {ListItems((order?.additionalInfo?.bookingFor || order?.bookingFor || '---'), (
                                        order?.additionalInfo?.treatmentData?.[0]?.treatmentName ||
                                        order?.additionalInfo?.detoxRetreatData?.[0]?.detoxRetreatName ||
                                        order?.additionalInfo?.solutionData?.[0]?.solutionName ||
                                        order?.additionalInfo?.treatment ||
                                        order?.additionalInfo?.serviceData?.[0]?.name || '---'
                                    ))}

                                    {!(IsAppointmentBooking) && (order?.bookingFor === 'Service' ? <p><strong>Booking Date:</strong>
                                        {order?.additionalInfo?.bookingDate || '---'}</p> :
                                        <p><strong>Total Days:</strong> {order?.additionalInfo?.totalDays || '---'}</p>)}
                                    {(order?.bookingFor === 'Service') && <p><strong>Start Date:</strong>
                                        {order?.additionalInfo?.startDate || '---'}</p>}
                                    {(IsAppointmentBooking) &&
                                        ListItems('Date ', (order?.additionalInfo?.date || '---'))}

                                    {(order?.bookingFor === 'Service' || IsAppointmentBooking) ?
                                        ListItems('Start Time ', (order?.additionalInfo?.startTime || '---'))
                                        :
                                        ListItems('Check-In ', (order?.additionalInfo?.startDate || '---'))
                                    }

                                    {(order?.bookingFor === 'Service' || IsAppointmentBooking) ?
                                        ListItems('End Time ', (order?.additionalInfo?.endTime || '---'))
                                        :
                                        ListItems('Check-Out ', (order?.additionalInfo?.endDate || '---'))
                                    }
                                    {(!order?.additionalInfo?.careTakerCount === 0) && <p><strong>Caretaker Included:</strong> {"Yes (" + order?.additionalInfo?.careTakerCount + ")" || '---'}</p>}
                                </Card>
                                {(IsAppointmentBooking) && <Card style={gridStyle} className="col-md-4 col-xxl-3">
                                    {ListItems('Old Case Paper No ', (order?.additionalInfo?.oldCasePaperNo || '---'))}
                                    {ListItems('Case Paper No', (order?.additionalInfo?.registrationNo || '---'))}
                                    {ListItems('Consultation Type', (order?.additionalInfo?.consultationType || '---'))}
                                    {ListItems('Doctor Name ', (order?.additionalInfo?.doctorName || '---'))}
                                    {ListItems('Consulting For', (order?.additionalInfo?.consultingFor || '---'))}
                                </Card>}
                            </div>

                            <Card className="mt-3" title={'Order History'}>

                                <CustomTable
                                    column={orderHistoryColumn}
                                    dataSource={order?.orderHistorySchema?.filter(v => v.orderStatus != 'Initialize') || []}
                                    CurrentPageNum={1}
                                />

                            </Card>



                            {!IsAppointmentBooking && <div className="row my-md-4 my-5">
                                <Card className="col-12 col-md-12  col-xl-7" title={'Guest Details'} bodyStyle={{ padding: 0 }}>
                                    <CustomTable
                                        column={GuestColumn}
                                        dataSource={order?.additionalInfo?.guestDetails || []}
                                        CurrentPageNum={1}

                                    />
                                </Card>
                                <Card className="col-12 col-md-12  col-xl-5" title={'Room Details'}>

                                    <CustomTable
                                        column={RoomColumn}
                                        dataSource={order?.additionalInfo?.roomDetails || []}
                                        CurrentPageNum={1}

                                    />

                                </Card>

                            </div>}





                        </div >
                    </div >
                </div >
            </>
        </>
    );
};

export default OrderHistoryOverView;