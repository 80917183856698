import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminuUerDetails } from '../../store/Actions/AuthActions';
import { CreatePrakrutistandard, GetAllPrakrutistandard } from '../../store/Actions/PrakrutistandardAction';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Formik } from 'formik';
import { AddPrakrutiStandardValidationSchema } from '../../utils/FormValidation';
import { ActionUpdateById } from '../../store/Actions/CommonAction';
import { InputFromUser } from '../../components/Input/input';
import { SingleMultiSelectDropDown } from '../../components/Input/InputText';
import { Options } from '../../utils/Data';

const AddEditPrakrutistandard = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const { Prakrutistandardlist } = useSelector(state => state?.PrakrutiStandard);
    const { userDetails } = useSelector(state => state?.auth);
    const userId = userDetails?.[0]?._id;
    const idValue = searchParams.get("id");
    const updateValue = searchParams.get("update");
    useEffect(() => {
        if (idValue) {
            dispatch(GetAllPrakrutistandard({ Query: '/' + idValue }));
        }
    }, [idValue])
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/prakrutistandard-list/:slug">Prakruti Standard</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Prakruti Standard</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card p-3">
                                            <div className="card-body">
                                                <Formik
                                                    enableReinitialize={updateValue || false}
                                                    initialValues={{
                                                        name: Prakrutistandardlist?.[0]?.name || "",
                                                        months: Prakrutistandardlist?.[0]?.months || [''],

                                                    }}
                                                    validationSchema={AddPrakrutiStandardValidationSchema}
                                                    onSubmit={(values, { setErrors, setSubmitting }) => {
                                                        // console.log('submit', values);
                                                        if (updateValue && idValue != null) {
                                                            dispatch(ActionUpdateById({ Query: `admin/prakruti-standard/${idValue}`, obj: values }
                                                                , (Res) => {
                                                                    if (Res?.statusCode === 200) {
                                                                        window.location.href = `/prakrutistandard-list?page=1`
                                                                    } else if (Res?.statusCode === 400) {
                                                                        setErrors(Res?.data?.errors);
                                                                    }
                                                                    setSubmitting(false);

                                                                }));
                                                        } else {
                                                            //CreateFaq
                                                            dispatch(CreatePrakrutistandard(values, (Response) => {
                                                                if (Response?.statusCode === 200) {
                                                                    window.location.href = "/prakrutistandard-list?page=1"
                                                                }
                                                                setSubmitting(false);

                                                            }))
                                                        }
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        //setFieldValue,
                                                        isSubmitting,
                                                    }) => {

                                                        return (
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-heading">
                                                                            <h4>Add Prakruti Standard Data</h4>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mt-4">
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            placeholder={"Enter Name"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Name"
                                                                            value={values?.name}
                                                                            name="name"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            error={errors?.name}
                                                                            touched={touched?.name}
                                                                        />
                                                                        <SingleMultiSelectDropDown
                                                                            label="Months"
                                                                            type="months"
                                                                            FormGroupClassName={"col-xl-6 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            name="months"
                                                                            onChange={(value) => {
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: 'months',
                                                                                        value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            FormControlClassName={'form-control'}
                                                                            onBlur={() => {
                                                                                handleBlur({
                                                                                    target: {
                                                                                        name: 'months',
                                                                                        value: true,
                                                                                    },
                                                                                });
                                                                            }} error={errors.months}
                                                                            value={values.months}
                                                                            options={Options.AllMonthData}
                                                                            touched={touched.months}
                                                                            placeholder={'Select Your  Months'} />


                                                                    </div>


                                                                    <div className="col-12 mt-4">
                                                                        <div className="doctor-submit text-end">
                                                                            <button
                                                                                type="submit"
                                                                                onClick={() => {
                                                                                    handleSubmit()
                                                                                }}
                                                                                className="btn btn-primary submit-form me-2"
                                                                                disabled={isSubmitting}
                                                                            >
                                                                                Submit
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary cancel-form"
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }}
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default AddEditPrakrutistandard