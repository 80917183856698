/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import { Link } from 'react-router-dom';

const TimelineCard = () => {

    return (
        <>
            <div className="card flex-fill comman-shadow">
                <div className="card-header">
                    <h4 className="card-title d-inline-block">
                        Today’s Therapies
                    </h4>{" "}
                    <Link
                        // to="/appoinmentlist"
                        className="patient-views float-end"
                    >
                        Show all
                    </Link>
                </div>
                <div className="card-body">
                    <div className="teaching-card">
                        <ul className="steps-history">
                            <li>08:00</li>
                            <li>09:00</li>
                            <li>10:00</li>
                            <li>11:00</li>
                        </ul>
                        <ul className="activity-feed">
                            <li className="feed-item d-flex align-items-center">
                                <div className="dolor-activity hide-activity">
                                    <ul className="doctor-date-list mb-2">
                                        <li className="stick-line">
                                            <i className="fas fa-circle me-2" />
                                            08:00 <span>Benjamin Bruklin</span>
                                        </li>
                                        <li className="stick-line">
                                            <i className="fas fa-circle me-2" />
                                            08:00 <span>Andrea Lalema</span>
                                        </li>
                                        <li className=" dropdown ongoing-blk ">
                                            <Link
                                                to="#"
                                                className="dropdown-toggle  active-doctor"
                                                data-bs-toggle="dropdown"
                                            >
                                                <i className="fas fa-circle me-2 active-circles" />
                                                08:00 <span>Andrea Lalema</span>
                                                <span className="ongoing-drapt">
                                                    Ongoing{" "}
                                                    <i className="feather-chevron-down ms-2">
                                                        <FeatherIcon icon="chevron-down ms-2" />
                                                    </i>
                                                </span>
                                            </Link>
                                            <ul className="doctor-sub-list dropdown-menu">
                                                <li className="patient-new-list dropdown-item">
                                                    Patient<span>Marie kennedy</span>
                                                    <Link
                                                        to="#"
                                                        className="new-dot status-green"
                                                    >
                                                        <i className="fas fa-circle me-1 fa-2xs" />
                                                        New
                                                    </Link>
                                                </li>
                                                <li className="dropdown-item">
                                                    Time<span>8:30 - 9:00 (30min)</span>
                                                </li>
                                                <li className="schedule-blk mb-0 pt-2 dropdown-item">
                                                    <ul className="nav schedule-time">
                                                        <li>
                                                            <Link to="#">
                                                                {/*<img src={trashicon} alt="" />*/}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                {/*<img src={profileicon} alt="" />*/}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                {/*<img src={imgicon} alt="" />*/}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                    <Link className="btn btn-primary appoint-start">
                                                        Start Appointment
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="feed-item d-flex align-items-center">
                                <div className="dolor-activity">
                                    <ul className="doctor-date-list mb-2">
                                        <li>
                                            <i className="fas fa-circle me-2" />
                                            09:00 <span>Galaviz Lalema</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-circle me-2" />
                                            09:20 <span>Benjamin Bruklin</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-circle me-2" />
                                            09:40 <span>Jenny Smith</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="feed-item d-flex align-items-center">
                                <div className="dolor-activity">
                                    <ul className="doctor-date-list mb-2">
                                        <li>
                                            <i className="fas fa-circle me-2" />
                                            10:00 <span>Cristina Groves</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-circle me-2" />
                                            10:30 <span>Benjamin Bruklin</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="feed-item d-flex align-items-center">
                                <div className="dolor-activity">
                                    <ul className="doctor-date-list mb-2">
                                        <li>
                                            <i className="fas fa-circle me-2" />
                                            10:00 <span>Cristina Groves</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-circle me-2" />
                                            10:30 <span>Benjamin Bruklin</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="feed-item d-flex align-items-center">
                                <div className="dolor-activity">
                                    <ul className="doctor-date-list mb-2">
                                        <li>
                                            <i className="fas fa-circle me-2" />
                                            10:00 <span>Cristina Groves</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-circle me-2" />
                                            10:30 <span>Benjamin Bruklin</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="feed-item d-flex align-items-center">
                                <div className="dolor-activity">
                                    <ul className="doctor-date-list mb-2">
                                        <li>
                                            <i className="fas fa-circle me-2" />
                                            10:00 <span>Cristina Groves</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-circle me-2" />
                                            10:30 <span>Benjamin Bruklin</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>

    )
};

export default TimelineCard;
