import React from 'react'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'

const DoctorDashboard = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <>

            </>
        </>
    )
}

export default DoctorDashboard