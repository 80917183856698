/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { CustomTable } from "../../components/Table";
import { Button, Modal, Popover } from "antd";
import { RoundProfileIcon } from "../../components/Card/Cards";
import { GetRoleListWithPaginationAction } from "../../store/Actions/RolesAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
    plusicon,
    refreshicon
} from "../../components/imagepath";
import { ActionDeleteById } from "../../store/Actions/CommonAction";
import { matchPermissions } from "../../utils/Permission";
const { confirm } = Modal;

const Roles = () => {
    const [EditMembersModal, SetEditMembersModal] = useState(false)
    const dispatch = useDispatch();
    const { RoleList_Pagination, loader } = useSelector(state => state?.Role);
    const { userDetails } = useSelector(state => state?.auth);
    const [Refresh, setRefresh] = useState(false);
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('role.update');
    const canDelete = matchPermissionsForUser('role.delete');
    const canCerate = matchPermissionsForUser('role.create');
    useEffect(() => {
        dispatch(GetRoleListWithPaginationAction({ Query: '' }))
        setRefresh(false)
    }, [Refresh])
    const content = (item) => (
        <div className="row col">
            {canUpdate &&
                <Button className="col m-2" onClick={() => { window.location.href = `roles/create-roles?update=true&&page=${1}&&id=${item?.id}` }}>Edit Role</Button>
            }
            {canDelete &&
                <Button className="col m-2" onClick={() => showDeleteConfirm(item)}>Delete Role</Button>
            }
        </div>
    );
    // /roles/create-roles
    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.roleName} Role?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteById({ Query: `admin/roles/${item?.id}` }, (Res) => {
                    if (Res?.statusCode === 200) {
                        setRefresh(true)
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };


    const columns = [

        {
            title: "Roles",
            dataIndex: "roleName",

        },
        {
            title: "Users",
            dataIndex: "adminUsers",
            render: (adminUsers) => (
                <div className="row col-8" >
                    {RoleList_Pagination?.[0] && adminUsers?.map((user, index) => (
                        <div key={index} className="col-1">
                            <RoundProfileIcon username={user?.name?.split(' ')?.[1] || 'n'} firstName={user?.name?.split(' ')?.[0] || 'f'} />
                        </div>
                    ))}
                </div>
            )
        },
        {
            title: "",
            dataIndex: "roleName",
            render: (text, record,) => {
                if (!canUpdate && !canDelete) {
                    return null
                }
                else {

                    return <Popover placement="top" title={text} content={() => content(record)}>
                        <Button type="text">• • •</Button>
                    </Popover>
                }
            },
        },
    ]
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link>Roles</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Roles List</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Add Roles</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">
                                                                    {canCerate &&
                                                                        <Link
                                                                            to="/roles/create-roles"
                                                                            className="btn btn-primary add-pluss ms-2"
                                                                        >
                                                                            <img src={plusicon} alt="#" />
                                                                        </Link>
                                                                    }

                                                                    <Link
                                                                        onClick={() => window.location.reload()}
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <CustomTable
                                                    CurrentPageNum={1}
                                                    TotalPageList={1}
                                                    column={columns}
                                                    dataSource={RoleList_Pagination?.[0]?.docs ? RoleList_Pagination?.[0]?.docs : []}
                                                    dataSourceLength={5}
                                                    pageSize={10}
                                                    loader={loader}
                                                    ShowHeader={true}
                                                    Header={<div className="staff-search-table">
                                                    </div>}
                                                    key={"hi"} />

                                                <Modal
                                                    open={EditMembersModal}
                                                    closable={true}
                                                    className='ant-modal'
                                                    forceRender={true}
                                                    zIndex={900}
                                                    style={{ margin: 10 }}
                                                    onOk={() => SetEditMembersModal(false)}
                                                    styles={{
                                                        body: {
                                                            overflow: "hidden",
                                                        },
                                                        mask: {
                                                            backdropFilter: 'blur(10px)',
                                                        },
                                                        content: {
                                                            borderRadius: 40,
                                                        },
                                                    }}
                                                    okText={'Submit'}
                                                    centered
                                                    confirmLoading={false}
                                                    onCancel={() => SetEditMembersModal(false)}
                                                    closeIcon={true}
                                                >


                                                </Modal>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};
export default Roles;
