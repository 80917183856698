//service slot
import { call, put, takeEvery } from 'redux-saga/effects';
import { GetServiceSlotByIdApi, UpdateServiceSlotApi, getAllServiceSlotApi } from "../../utils/api";
import { ActionGetServiceSlotByIdSuccess, GetAllServiceSlotSuccess } from "../Actions/ServiceSlotAction";
import { GET_ALL_SERVICE_SLOT, GET_SERVICE_SLOT_BY_ID, UPDATE_SERVICE_SLOT } from "../storeTypes";
import { handleApiResponse } from '../../utils/Funcations';

const File = 'ServiceSlotSaga '
function* getAllServiceSlotSaga(action) {
    try {
        const response = yield call(getAllServiceSlotApi, action.payload);
        if (response?.status === 200) {
            yield put(GetAllServiceSlotSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getAllServiceSlotSaga Error :- ', error);
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)

    }

}

function* UpdateServiceSlotSaga(action) {
    try {
        const response = yield call(UpdateServiceSlotApi, action.payload);
        console.log("UpdateServiceSlotApi", response);
        if (response?.status === 200) {

            action?.callBack(response?.data);
        }
    } catch (error) {
        console.log(File + '/UpdateServiceSlotSaga Error:', error);

        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
    }
}

function* GetServiceSlotByIDSaga(action) {
    try {
        const response = yield call(GetServiceSlotByIdApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetServiceSlotByIdSuccess(response?.data?.data));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File + '/GetServiceSlotByIDSaga Error:', error);
    }
}

export function* ServiceSlotSaga() {
    [
        yield takeEvery(GET_ALL_SERVICE_SLOT, getAllServiceSlotSaga),
        yield takeEvery(UPDATE_SERVICE_SLOT, UpdateServiceSlotSaga),
        yield takeEvery(GET_SERVICE_SLOT_BY_ID, GetServiceSlotByIDSaga),
    ]
}