import { call, put, takeEvery } from "redux-saga/effects";
import { CreatePriceApi, DeletePriceApi, GetAllPriceApi, GetPriceByIdApi, UpdatePriceApi } from "../../utils/api";
import { ActionGetAllPriceListSuccess, ActionGetPriceByIDSuccess } from "../Actions/PriceAction";
import { handleApiResponse } from "../../utils/Funcations";
import { CREATE_PRICE, DELETE_PRICE, GET_ALL_PRICE_LIST, GET_PRICE_BY_ID, UPDATE_PRICE } from "../storeTypes";

const File = "PriceSaga"

function* GetAllPriceSaga(action) {
    try {
        const response = yield call(GetAllPriceApi, action.payload);
        console.log("GetAllPriceApi", response?.data?.data[0]);
        if (response?.status === 200) {
            yield put(ActionGetAllPriceListSuccess(response?.data?.data[0]));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callback);
        console.log(File + "GetAllPriceSagaError", error);
    }
}

function* CreatePriceSaga(action) {
    try {
        const response = yield call(CreatePriceApi, action.payload);
        if (response) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + '/CreatePriceSaga Error:', error);
    }
}

function* UpdatePriceSaga(action) {
    try {
        const response = yield call(UpdatePriceApi, action.payload);
        console.log("UpdatePriceApi", response?.data);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        console.log(File + '/UpdatePriceSaga Error:', error);

        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
    }
}

function* GetPriceByIDSaga(action) {
    try {
        const response = yield call(GetPriceByIdApi, action.payload);
        console.log("GetPriceByIdApi", response?.data?.data[0]);
        if (response?.status === 200) {
            yield put(ActionGetPriceByIDSuccess(response?.data?.data[0]));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File + '/GetPriceByIDSaga Error:', error);
    }
}

function* DeletePriceSaga(action) {
    try {
        const response = yield call(DeletePriceApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(200);
        }
    } catch (error) {
        console.log(File + '/DeletePriceSaga Error:', error);
    }
}

export function* PriceSaga() {
    yield takeEvery(GET_ALL_PRICE_LIST, GetAllPriceSaga)
    yield takeEvery(CREATE_PRICE, CreatePriceSaga)
    yield takeEvery(UPDATE_PRICE, UpdatePriceSaga)
    yield takeEvery(GET_PRICE_BY_ID, GetPriceByIDSaga)
    yield takeEvery(DELETE_PRICE, DeletePriceSaga)
}