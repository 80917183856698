import { call, put, takeEvery } from 'redux-saga/effects';
import { UpdateTreatmentSlotApi, getAllTreatmentSlotApi, getTreatmentSlotByIdApi } from "../../utils/api";
import { ActionGetTreatmentSlotByIdSuccess, GetAllTreatmentSlotSuccess } from "../Actions/TreatmentSlotAction";
import { GET_ALL_TREATMENT_SLOT, GET_TREATMENT_SLOT_BY_ID, UPDATE_TREATMENT_SLOT } from "../storeTypes";
import { handleApiResponse } from '../../utils/Funcations';

function* getAllTreatmentSlotSaga(action) {
    try {
        const response = yield call(getAllTreatmentSlotApi, action.payload);
        if (response?.status === 200) {
            yield put(GetAllTreatmentSlotSuccess(response?.data?.data));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
    }
}
function* GetTreatmentSlotByIdSaga(action) {
    try {
        const response = yield call(getTreatmentSlotByIdApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionGetTreatmentSlotByIdSuccess(response?.data?.data));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File + '/GetTreatmentSlotByIdSaga Error:', error);
    }
}
function* UpdateTreatmentSlotSaga(action) {
    try {
        const response = yield call(UpdateTreatmentSlotApi, action.payload);
        console.log("UpdateTreatmentSlotApi", response);
        if (response?.status === 200) {

            action?.callBack(response?.data);
        }
    } catch (error) {
        console.log(File + '/UpdateTreatmentSlotSaga Error:', error);

        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
    }
}

export function* TreatmentSlotSaga() {
    [
        yield takeEvery(GET_ALL_TREATMENT_SLOT, getAllTreatmentSlotSaga),
        yield takeEvery(GET_TREATMENT_SLOT_BY_ID, GetTreatmentSlotByIdSaga),
        yield takeEvery(UPDATE_TREATMENT_SLOT, UpdateTreatmentSlotSaga),
    ]
}