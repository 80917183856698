import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { PrakurtiLogoWhite } from "../../components/imagepath";
import { Formik } from "formik";
import { LoginValidationSchema } from "../../utils/FormValidation";
import { ActionLoginUser } from "../../store/Actions/AuthActions";
import { InputFromUser } from "../../components/Input/input";





const Login = () => {
  const dispatch = useDispatch();
  // const { authUser } = useSelector((state) => state.auth)
  return (
    <>

      {/* Main Wrapper */}
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                {/* <div className="log-img">
                  <img
                    className="img-fluid"
                    src={PrakurtiLogoWhite}
                    alt="#"
                  />
                </div> */}
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/admin-dashboard">
                          <img src={PrakurtiLogoWhite} alt="#" />
                        </Link>
                      </div>
                      <h2>Login</h2>
                      <Formik
                        initialValues={{ email: '', password: '' }}
                        validationSchema={LoginValidationSchema}
                        onSubmit={(values, { setErrors, setSubmitting }) => {
                          dispatch(ActionLoginUser(values, (response) => {
                            if (response?.statusCode == 400) {
                              setErrors(response.data.errors);
                            } else if (response?.statusCode == 401) {
                              setErrors(response.data.errors);
                            } else if (response?.data?.statusCode == 200) {
                              window.location.href = "/admin-dashboard"
                            }
                          }))
                          setSubmitting(false)
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <InputFromUser
                              label="Email"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.email}
                              value={values.email}
                              touched={touched.email}
                              placeholder={'Enter Your Email'}
                            />
                            <InputFromUser
                              label="Password"
                              type="password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              error={errors.password}
                              touched={touched.password}
                              placeholder={'Enter Your Password'}
                            />

                            <div className="forgotpass">
                              <Link to="/forgot-password">Forgot Password?</Link>
                            </div>
                            <div className="form-group login-btn" >
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                disabled={isSubmitting}
                              >
                                Login
                              </button>
                            </div>

                          </form>
                        )}
                      </Formik>


                      {/* /Form */}
                      {/* <div className="next-sign">
                        <p className="account-subtitle">
                          Need an account? <Link to="/signup">Sign Up</Link>
                        </p>
                        <div className="social-login">
                          <Link to="#">
                            <img src={loginicon01} alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={loginicon02} alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={loginicon03} alt="#" />
                          </Link>
                        </div>
                       
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
