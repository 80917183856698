/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { TableListPageCom } from "../../../components/TableListPageCom";
import { useDispatch, useSelector } from "react-redux";
import { ActionGetDetoxRetreatBooking } from "../../../store/Actions/ServiceAction";
import { useEffect, useState } from "react";
import queryString from "query-string";
import { generateQueryString } from "../../../utils/Funcations";
import { Link, useLocation } from "react-router-dom";
import TableColumnSearch1 from "../../../components/Input/TableColumnSearch1";
import { Button, DatePicker } from "antd";

const DetoxRetreatBooking = () => {
    const pageSize = 10;
    const dispatch = useDispatch();
    const { DetoxRetreat, loader } = useSelector(state => state?.Service);
    const { userDetails } = useSelector(state => state?.auth);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const location = useLocation();
    const pageParams = searchParams.get("page");
    const customerNameParams = searchParams.get("customerName");
    const detoxRetreatNameParams = searchParams.get("detoxRetreatName");
    const params = queryString.parse(location.search);

    const [Query, setQuery] = useState({
        'page': pageParams,
        'detoxRetreatName': detoxRetreatNameParams,
        'customerName': customerNameParams,
        'startDate': params.startDate || '',
        'endDate': params.endDate || '',
    },);

    const Filters = generateQueryString(
        ['page', (Query.page || 1)],
        ['detoxRetreatName', (Query.detoxRetreatName)],
        ['customerName', Query.customerName],
        ['startDate', Query.startDate],
        ['endDate', Query.endDate],
    )
    const handleResetFilters = () => {
        setQuery({
            ...Query,
            startDate: '',
            endDate: '',
        });
    };
    useEffect(() => {
        dispatch(ActionGetDetoxRetreatBooking({ Query: Filters, }));
        window.history.replaceState(null, '', Filters);
    }, [Filters, Query.startDate, Query.endDate])
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            key: "Sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Customer Name",
            dataIndex: "customerName",
            render: (text, record) => (
                <Link to={`/order-history-overview/${record?.orderId}`}>
                    {record?.customerName}
                </Link>
            ),
            ...TableColumnSearch1({
                dataIndex: 'customerName',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
        },
        {
            title: "Phone No.",
            dataIndex: "phoneNo",
        },
        {
            title: "Retreat Name",
            dataIndex: "detoxRetreatData",
            render: (text, record) => (
                <span>{record?.detoxRetreatData?.[0].detoxRetreatName}</span>
            ),
            ...TableColumnSearch1({
                dataIndex: 'detoxRetreatName',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
        },
        {
            title: "Total Days",
            dataIndex: "totalDays",
        },
        {
            title: "Date",
            dataIndex: "dateRange",
            render: (text, record) => {
                const startDate = record.startDate;
                const endDate = record.endDate;
                const formattedDateRange = `${startDate} To ${endDate}`;
                return <span>{formattedDateRange}</span>;
            }
        },
        {
            title: "Care Taker",
            dataIndex: "careTakerCount",
        },

        {
            title: "Primary Guest",
            dataIndex: "parentCustomerName",
            render: (text, record) => (
                <span>{text || '---'}</span>
            ),
        },

    ];
    const BreadcrumbList = [{
        name: 'Detox-Retreat',
        linkTo: `/detox-retreat-booking?page=${params?.page || 1}`
    },
    {
        name: 'Detox-Retreat-Booking',
    }
    ]
    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            LeftChild={
                <div className="col-12 col-lg-6 col-xl-6 mt-3 mb-md-0" style={{ display: 'flex', alignItems: 'center' }}>
                    <DatePicker.RangePicker
                        onChange={(dates) => {
                            setQuery({
                                ...Query,
                                startDate: dates[0]?.format('YYYY-MM-DD') || '',
                                endDate: dates[1]?.format('YYYY-MM-DD') || '',
                            });
                        }} className="w-100"
                        allowClear={false}
                    />
                    <Button type="link" onClick={handleResetFilters} style={{ marginLeft: '10px' }}>Clear Filters</Button>
                </div>}
            TableData={DetoxRetreat?.[0]?.docs}
            TableColumn={columns}
            ShowAddButton={false}
            PageMainTitle="Detox Retreat Booking"
            CurrentPage={parseInt(params?.page) || 1}
            Tableloader={loader}
            TotalPageList={DetoxRetreat?.[0]?.totalDocs}
            dataSourceLength={DetoxRetreat?.[0]?.docs?.length}
            handlePageChange={(v) => {
                var newp = params
                newp.page = v
                window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
            }}
        />

    )
};

export default DetoxRetreatBooking;
