import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ActionGetRoomByRoomType } from '../../../store/Actions/MasterDataAction';
import moment from 'moment';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

export const RoomIndividualData = () => {

    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');


    const { roomTypeData } = useSelector(state => state.MasterData);
    const { name } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        let query = `/${name}`;
        if (startDate !== null && endDate !== null && startDate !== "null" && endDate !== "null") {
            query += `?startDate=${startDate}&endDate=${endDate}`;
        }
        dispatch(ActionGetRoomByRoomType({ Query: query }));
    }, [dispatch, name, startDate, endDate]);

    const renderRoomCards = () => {
        const roomIds = [...new Set([...(roomTypeData?.checkIn?.map(room => room._id) ?? []), ...(roomTypeData?.checkOut?.map(room => room._id) ?? [])])];
        return roomIds.map(roomId => {
            const checkInData = roomTypeData?.checkIn?.filter(room => room._id === roomId)?.[0]?.roomSlot ?? [];
            const checkOutData = roomTypeData?.checkOut?.filter(room => room._id === roomId)?.[0]?.roomSlot ?? [];

            return (
                // Parent div
                <div className="col-xxl-6 mb-3" key={roomId}>
                    <div className="card p-3" style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                        {/* Child div */}
                        <div className="card-body row justify-content-evenly align-items-start">
                            <h3 className='pt-2 pb-2 text-center' style={{ color: '#2e37a4' }}>Room Name ➛ {roomId}</h3>

                            {/* Render check-in data */}
                            {checkInData?.map(checkInSlot => (

                                <div className='p-3 col-xl-5 mb-4' key={checkInSlot._id} style={{ borderTop: "7px solid green", backgroundColor: "rgba(0, 128, 0, 0.1)", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                                    <h3 className='text-success text-center'>Check-In</h3>
                                    <p style={{ textTransform: "capitalize" }}>Name ➛ {checkInSlot.customerName}</p>
                                    <p>Check-In ➛ {moment(checkInSlot.checkInDate).format('DD-MM-YYYY')}</p>
                                    <p>Check-Out ➛ {moment(checkInSlot.checkOut).format('DD-MM-YYYY')}</p>
                                    <p>Private/Shared ➛ {checkInSlot.isPrivate ? 'Private' : 'Shared'}</p>
                                    {/* <p style={{ fontSize: "9px" }}>{checkInSlot.orderId}</p> */}

                                    {/* Render corresponding check-out data */}
                                    {checkOutData?.map(checkOutSlot => (
                                        checkOutSlot.customerId === checkInSlot.customerId && (
                                            <div className='p-3 mt-3 mt-xl-0 col-xl-12 mb-xl-4' key={checkOutSlot._id} style={{ borderTop: "7px solid red", backgroundColor: "rgba(255, 0, 0, 0.1)", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                                                <h3 className='text-danger text-center'>Check-Out</h3>
                                                <p style={{ textTransform: "capitalize" }}>Name ➛ {checkOutSlot.customerName}</p>
                                                <p>Check-In ➛ {moment(checkOutSlot.checkInDate).format('DD-MM-YYYY')}</p>
                                                <p>Check-Out ➛ {moment(checkOutSlot.checkOut).format('DD-MM-YYYY')}</p>
                                                <p>Private/Shared ➛ {checkOutSlot.isPrivate ? 'Private' : 'Shared'}</p>
                                                {/* <p style={{ fontSize: "9px" }}>{checkOutSlot.orderId}</p> */}
                                            </div>
                                        )
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <>
            <Header />
            <Sidebar />
            <div className="page-wrapper">
                <div className="content">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/room-dashboard">Room Dashboard </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <i className="feather-chevron-right">
                                <FeatherIcon icon="chevron-right" />
                            </i>
                        </li>
                        <li className="breadcrumb-item active">{name}</li>
                    </ul>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h1 style={{ color: '#2e37a4', marginBottom: '23px' }}>{name}</h1>

                        <div className="row justify-content-center">
                            {renderRoomCards()}
                        </div>
                        {(!roomTypeData?.checkIn || roomTypeData.checkIn.length === 0) && (!roomTypeData?.checkOut || roomTypeData.checkOut.length === 0) && (
                            <div className="col-xl-6 m-xl-auto">
                                <div className="card h-100 p-4" style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                                    <h3 className="text-dark text-center pt-2">
                                        No{' '}
                                        <span className="p-1" style={{ border: '2px solid green', backgroundColor: 'rgba(0, 128, 0, 0.1)', borderRadius: '10px' }}>
                                            check-In
                                        </span>{' '}
                                        or{' '}
                                        <span className="p-1" style={{ border: '2px solid red', backgroundColor: 'rgba(255, 0, 0, 0.1)', borderRadius: '10px' }}>
                                            Check-Out
                                        </span>{' '}
                                        Available For {name}
                                    </h3>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>

    );
};