import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import PropTypes from 'prop-types';
import { getBase64 } from '../../utils/Funcations';


const UploadImage = ({
    UploadImagelimit,
    handleChange,
    fileList,
    className,
    placeholder,
    multiple,
    handleRemove,
    errorMessage,
}) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const handleCancel = () => setPreviewOpen(false);

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload {placeholder}
            </div>
        </button>
    );
    return (
        <>
            <Upload
                className={className}
                multiple={multiple}
                listType="picture-card"
                customRequest={(data) => {
                    console.log('data', data);
                }}
                action={undefined}
                fileList={fileList || []}
                onPreview={async (file) => {
                    if (!file.url && !file.preview) {
                        file.preview = await getBase64(file.originFileObj);
                    }
                    setPreviewImage(file.url || file.preview);
                    setPreviewOpen(true);
                    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
                }}
                onChange={handleChange}
                accept=''
                onRemove={handleRemove}


            >
                {fileList.length >= UploadImagelimit ? null : uploadButton}
            </Upload >
            {errorMessage !== null && <div style={{ color: 'red' }}>{errorMessage}</div>}
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel} zIndex={1050}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
                {/* <Upload
                    onPreview={handlePreview}
                    onChange={handleChange}
                >
                    <Button>Edit This Image</Button>
                </Upload>
                <div>Please Upload same Image as Web Image</div> */}
            </Modal >
            {/* <div>error</div> */}
        </>
    );
};



UploadImage.propTypes = {
    handleChange: PropTypes.func,
    handleRemove: PropTypes.func,
    UploadImagelimit: PropTypes.number,
    multiple: PropTypes.boolean | undefined,
    accept: PropTypes.string,
    fileList: PropTypes.array,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
};
export default UploadImage;