import { GET_PATIENT_LIST, GET_PATIENT_LIST_SUCCESS, CREATE_PATIENT, MEDICINE_LIST_SUCCESS, MEDICINE_LIST, GET_LABORATORY_DATA, GET_LABORATORY_DATA_SUCCESS, GET_CUS_DASHBOARD_SUCCESS, GET_CUS_DASHBOARD, GET_CC_PAPER_DETAIL_SUCCESS, GET_CC_PAPER_DETAIL, GET_MEDICAL_DETAIL, GET_MEDICAL_DETAIL_SUCCESS, GET_CC_PAPER_VERIFICATION_DETAIL, GET_CC_PAPER_VERIFICATION_DETAIL_SUCCESS, GET_PATIENT_BY_ID, GET_PATIENT_BY_ID_SUCCESS, UPDATE_PATIENT, GET_CUSTOMER_BIRTHDATE_DETAIL, GET_CUSTOMER_BIRTHDATE_DETAIL_SUCCESS, GET_PATIENT_CONFIRMATION_DETAIL, GET_PATIENT_CONFIRMATION_DETAIL_SUCCESS, GET_ASSIGNED_DOCTOR_LIST, GET_ASSIGNED_DOCTOR_LIST_SUCCESS } from "../storeTypes";

const INIT_STATE = {
    loader: false,
    patientList: [],
    medicineList: [],
    CustomerDashbord: {},
    CcPaperDetail: [],
    birthdayDetail: [],
    CcPaperVerificationList: [],
    MedicalDetail: [],
    laboratory: null,
    patient: {},
    AssignedDoctor: [],
    customerConfirmation: [],
};

function patientReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case GET_PATIENT_LIST: {
            return {
                ...state,
                patientList: [],
                loader: true,
            };
        }
        case GET_PATIENT_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                patientList: action.payload,
            };
        }
        case GET_PATIENT_BY_ID: {
            return {
                ...state,
                patient: {},
                loader: true,
            };
        }
        case GET_PATIENT_BY_ID_SUCCESS: {
            return {
                ...state,
                loader: false,
                patient: action.payload,
            };
        }
        case GET_LABORATORY_DATA: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_LABORATORY_DATA_SUCCESS: {
            return {
                ...state,
                loader: false,
                laboratory: action.payload,
            };
        }
        case MEDICINE_LIST: {
            return {
                ...state,
                loader: true,
            };
        }
        case MEDICINE_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                medicineList: action.payload,
            };
        }
        case GET_CC_PAPER_DETAIL: {
            return {
                ...state,
                CcPaperDetail: [],
                loader: true,
            };
        }
        case GET_CC_PAPER_DETAIL_SUCCESS: {
            return {
                ...state,
                loader: false,
                CcPaperDetail: action.payload,
            };
        }
        case GET_CUSTOMER_BIRTHDATE_DETAIL: {
            return {
                ...state,
                birthdayDetail: [],
                loader: true,
            };
        }
        case GET_CUSTOMER_BIRTHDATE_DETAIL_SUCCESS: {
            return {
                ...state,
                loader: false,
                birthdayDetail: action.payload,
            };
        }
        case GET_CC_PAPER_VERIFICATION_DETAIL: {
            return {
                ...state,
                CcPaperVerificationList: [],
                loader: true,
            };
        }
        case GET_CC_PAPER_VERIFICATION_DETAIL_SUCCESS: {
            return {
                ...state,
                loader: false,
                CcPaperVerificationList: action.payload,
            };
        }
        case GET_CUS_DASHBOARD: {
            return {
                ...state,
                CustomerDashbord: {},
                loader: true,
            };
        }
        case GET_CUS_DASHBOARD_SUCCESS: {
            return {
                ...state,
                loader: false,
                CustomerDashbord: action.payload,
            };
        }
        case GET_MEDICAL_DETAIL: {
            return {
                ...state,
                MedicalDetail: [],
                loader: true,
            };
        }
        case GET_MEDICAL_DETAIL_SUCCESS: {
            return {
                ...state,
                MedicalDetail: action.payload,
            };
        }

        case CREATE_PATIENT: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_PATIENT: {
            return {
                ...state,
                loader: true,
            }
        }
        case GET_PATIENT_CONFIRMATION_DETAIL: {
            return {
                ...state,
                customerConfirmation: [],
                loader: true,
            };
        }
        case GET_PATIENT_CONFIRMATION_DETAIL_SUCCESS: {
            return {
                ...state,
                loader: false,
                customerConfirmation: action.payload,
            };
        }
        case GET_ASSIGNED_DOCTOR_LIST: {
            return {
                ...state,
                loader: true,
                AssignedDoctor: [],
            }
        }
        case GET_ASSIGNED_DOCTOR_LIST_SUCCESS: {
            return {
                ...state,
                loader: true,
                AssignedDoctor: action.payload,
            }
        }
        default: {
            return state;
        }
    }
}

export default patientReducer;
