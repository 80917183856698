import { GET_ROLE_LIST_SUCCESS, GET_ROLE_LIST, GET_ROLE_LIST_PAGINATION, GET_ROLE_LIST_PAGINATION_SUCCESS, CREATE_ROLES } from "../storeTypes";




export const GetRoleListAction = (payload) => {
    return {
        type: GET_ROLE_LIST,
        payload
    };
};
export const GetRoleListSuccess = (payload) => {
    return {
        type: GET_ROLE_LIST_SUCCESS,
        payload
    };
};


export const GetRoleListWithPaginationAction = (payload) => {
    return {
        type: GET_ROLE_LIST_PAGINATION,
        payload
    };
};
export const GetRoleListWithPaginationSuccess = (payload) => {
    return {
        type: GET_ROLE_LIST_PAGINATION_SUCCESS,
        payload
    };
};

export const ActionCreateRoles = (payload, callBack) => {
    return {
        type: CREATE_ROLES,
        payload,
        callBack
    };
};
