import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ActionGetRoomSlot } from '../../store/Actions/MasterDataAction';
import { Button, DatePicker } from 'antd';
import { Check, X } from 'react-feather';
import { matchPermissions } from '../../utils/Permission';
import { TableListPageCom } from '../../components/TableListPageCom';
import moment from 'moment';
import { InputFromUser } from '../../components/Input/input';
import { generateQueryString } from '../../utils/Funcations';
import TableColumnSearch1 from '../../components/Input/TableColumnSearch1';

const RoomSlot = () => {
    const pageSize = 10;
    const dispatch = useDispatch();
    const [Refresh, setRefresh] = useState(false);
    const { userDetails } = useSelector(state => state?.auth);
    const { loader, roomSlot } = useSelector(state => state?.MasterData);
    const params = queryString.parse(location.search);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const pageParams = searchParams.get("page");
    const customerNameParams = searchParams.get("customerName");
    const roomNameParams = searchParams.get("roomName");
    const roomTypeParams = searchParams.get("roomType");
    const statusParams = searchParams.get("status");
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('room-slot.update');

    const [Query, setQuery] = useState({
        'page': pageParams,
        'customerName': customerNameParams,
        'roomName': roomNameParams,
        'roomType': roomTypeParams,
        'status': statusParams || 'Upcoming',
        'startDate': params.startDate || '',
        'endDate': params.endDate || '',
    },);

    const Filters = generateQueryString(
        ['page', (Query.page || 1)],
        ['customerName', (Query.customerName)],
        ['roomName', (Query.roomName),],
        ['roomType', (Query.roomType),],
        ['status', Query.status],
        ['startDate', Query.startDate],
        ['endDate', Query.endDate],
        // ['sort', 'roomName:-1'],
    )

    const handleResetFilters = () => {
        setQuery({
            ...Query,
            startDate: '',
            endDate: '',
        });
    };

    const formatDate = (dateString) => {
        if (!dateString) return '---';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';
        return date.toISOString().split('T')[0];
    };
    // const currentDate = moment().format('YYYY-MM-DD');

    const isCheckInToday = (dateString) => {
        const checkInDate = moment(dateString).format('YYYY-MM-DD');
        const previousDayDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
        const currentDayDate = moment().format('YYYY-MM-DD');
        return checkInDate === previousDayDate || checkInDate === currentDayDate;
    };

    // const sort = encodeURIComponent("roomName:-1");
    // console.log(typeof sort, "type");

    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Patient",
            dataIndex: "customerName",
            sorter: (a, b) => a.customerName.length - b.customerName.length,
            ...TableColumnSearch1({
                dataIndex: 'customerName',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
            render: (text, record) => (
                <Link to={`/order-history-overview/${record?.orderId?._id}`}>
                    {record?.customerName}
                </Link>
            ),
        },
        {
            title: "Room Name",
            dataIndex: "roomName",
            ...TableColumnSearch1({
                dataIndex: 'roomName',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
            // sorter: (a, b) => a.roomId.roomName.en.length - b.roomId.roomName.en.length,
            render: (text, record) => (
                <span>{record?.roomId?.roomName?.en || '---'}</span>
            ),
        },
        {
            title: "Room Type",
            dataIndex: "roomType",
            ...TableColumnSearch1({
                dataIndex: 'roomType',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
            // sorter: (a, b) => a.roomId.roomType.length - b.roomId.roomType.length,
            render: (text, record) => (
                <span>{record?.roomId?.roomType || '---'}</span>
            ),
        },
        {
            title: "Is Shared",
            dataIndex: "isShared",
            render: (text, record) => {
                if (record.isShared) {
                    return <Check color="green" size={24} />;
                } else {
                    return <X color="red" size={24} />;
                }
            },
            sorter: (a, b) => (a.isShared ? 1 : 0) - (b.isShared ? 1 : 0),
        },
        {
            title: "Is Private",
            dataIndex: "isPrivate",
            render: (text, record) => {
                if (record.isPrivate) {
                    return <Check color="green" size={24} />;
                } else {
                    return <X color="red" size={24} />;
                }
            },
            sorter: (a, b) => (a.isPrivate ? 1 : 0) - (b.isPrivate ? 1 : 0),
        },
        {
            title: "Check In",
            dataIndex: "checkIn",
            // ...TableColumnSearch1({
            //     dataIndex: 'checkIn',
            //     queryValue: Query,
            //     onChange: (v) => setQuery(v)
            // }),
            render: (text, record) => formatDate(record?.checkInDate),
            sorter: (a, b) => new Date(a.checkInDate) - new Date(b.checkInDate),

        },
        {
            title: "Check Out",
            dataIndex: "checkOut",
            // ...TableColumnSearch1({
            //     dataIndex: 'checkOut',
            //     queryValue: Query,
            //     onChange: (v) => setQuery(v)
            // }),
            render: (text, record) => formatDate(record?.checkOut),
            sorter: (a, b) => new Date(a.checkOut) - new Date(b.checkOut),
        },
        {
            title: "Actual Check In",
            dataIndex: "actualCheckInDate",
            render: (text, record) => formatDate(record?.actualCheckInDate),
        },
        {
            title: "Actual Check Out",
            dataIndex: "actualCheckOut",
            render: (text, record) => formatDate(record?.actualCheckOut),
        },
        {
            title: !canUpdate ? null : "Actions",
            dataIndex: "",
            render: (text, record) => {
                if (!canUpdate) {
                    return null;
                } else {
                    const isDisabled = record?.actualCheckInDate || !isCheckInToday(record?.checkInDate);
                    return (
                        <Button
                            type="primary"
                            disabled={isDisabled}
                            onClick={() => {
                                window.location.href = `/updatecheckinform?update=true&&page=${1}&&id=${record?._id}`;
                            }}
                        >
                            Check In
                        </Button>
                    );
                }
            },
        },
        {
            title: !canUpdate ? null : "Actions",
            dataIndex: "",
            render: (text, record) => {
                if (!canUpdate) {
                    return null;
                } else {
                    const isCheckInDone = !!record?.actualCheckInDate; // Check if actualCheckInDate is not null or undefined
                    const isCheckOutDone = !!record?.actualCheckOut; return (
                        <Button
                            type="primary"
                            disabled={!isCheckInDone || isCheckOutDone}
                            onClick={() => {
                                window.location.href = `/updatecheckoutform?update=true&&page=${1}&&id=${record?._id}`;
                            }}
                        >
                            Check Out
                        </Button>
                    );
                }
            },
        },
    ]

    const handleSearch1 = (searchTerm) => {
        var newp = params
        newp.page = searchTerm
        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
    }

    const BreadcrumbList = [{
        name: 'Room Slot Booking',
        linkTo: `/room-slot?page=${params?.page || 1}`
    },
    {
        name: 'Room Slot List',
    }
    ]

    useEffect(() => {
        // dispatch(ActionGetRoomSlot({ Query: `${Filters}&sort=${sort}` }));
        dispatch(ActionGetRoomSlot({ Query: Filters }));
        window.history.replaceState(null, '', Filters);
        setRefresh(false);
    }, [Refresh, Filters, Query.startDate, Query.endDate])
    return (

        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={roomSlot?.[0]?.docs}
            TableColumn={columns}
            ShowAddButton={false}
            LeftChild={
                <div className="col-12 col-lg-6 col-xl-6 mt-3 mb-md-0" style={{ display: 'flex', alignItems: 'center' }}>
                    <DatePicker.RangePicker
                        onChange={(dates) => {
                            setQuery({
                                ...Query,
                                startDate: dates[0]?.format('YYYY-MM-DD') || '',
                                endDate: dates[1]?.format('YYYY-MM-DD') || '',
                            });
                        }}
                        allowClear={false}
                    />
                    <Button type="link" onClick={handleResetFilters} style={{ marginLeft: '10px' }}>Clear Filters</Button>
                </div>
            }
            RightChild={
                <div className='col d-flex flex-row-reverse'>

                    {/* <div className='row col-xl-5 col-md-10'> */}
                    <div className={`dropdown-color row col-xl-5 col-md-10 ${Query.status === 'Completed' ? 'Completed' : Query.status === 'Cancelled' ? 'Cancelled' : 'Upcoming'}`}>

                        <h4 className='col-xl-4 col-md-12 my-4' >Status</h4>
                        <InputFromUser
                            placeholder={" Select any Value"}
                            type="singleSelect"
                            FormGroupClassName={'col-xl-5 col-md-12 m-0 p-0'}
                            FormControlClassName={'m-0 p-0'}
                            name="status"
                            onChange={(v) => setQuery({ ...Query, status: v })}
                            LabelClassName={'col m-0 p-0'}
                            Dropdown_ClassName={'col m-0 p-0'}
                            ComponentStyle={{ height: '5%' }}
                            options={["Upcoming", "Completed", "Cancelled", "InProgress"]}
                            value={Query.status}
                        />
                    </div>
                </div>
            }
            PageMainTitle="Room Slot List"
            CurrentPage={parseInt(params?.page) || 1}
            Tableloader={loader}
            TotalPageList={roomSlot?.[0]?.totalDocs}
            dataSourceLength={roomSlot?.docs?.length}
            handlePageChange={(v) => handleSearch1(v)}
        />

    )
}

export default RoomSlot