import { combineReducers } from 'redux';
import authReducer from './authReducer';
import appConfigReducer from './appConfigReducer';
import patientReducer from './patientReducer';
import doctorReducer from './docotrReducer';
import RoleReducer from './RolesReducer';
import StaffReducer from './StaffReducer';
import PermissionReducer from './PermissionsReducer';
import AppointmentReducer from './AppointmentReducer';
import personalDetilsReducer from './PersonalDetails.Reducer';
import serviceReducer from './ServiceReducer';
import MasterDataReducer from './MasterDataReducer';
import CommonReducer from './CommonReducer';
import serviceSlotReducer from './ServiceSlotReducer';
import PriceReducer from './PriceReducer';
import celebrityReducer from './CelebrityReducer';
import MedicalStoreReducer from './MedicalStoreReducer';
import OrderHistoryReducer from './OrderHistoryReducer';
import MedicalHistoryReducer from './MedicalHistoryReducer';
import faqReducer from './FaqReducer';
import prakrutiStandardReducer from './PrakrutistandardReducer';
import TreatmentSlotReducer from './TreatmentSlotReducer';
import SolutionSlotReducer from './SolutionSlotReducer';
import TestimonialLinkReducer from './TestimonialLinkReducer';
import TherapistReducer from './ThearapistAllocationReducer';
export const rootReducer = combineReducers({
    auth: authReducer,
    appConfig: appConfigReducer,
    patient: patientReducer,
    doctor: doctorReducer,
    Role: RoleReducer,
    Staff: StaffReducer,
    Permission: PermissionReducer,
    Appointment: AppointmentReducer,
    Service: serviceReducer,
    Celebrity: celebrityReducer,
    TestimonialLink: TestimonialLinkReducer,
    personmalDetail: personalDetilsReducer,
    MasterData: MasterDataReducer,
    Common: CommonReducer,
    ServiceSlotData: serviceSlotReducer,
    PriceTable: PriceReducer,
    MedicalStore: MedicalStoreReducer,
    OrderHistory: OrderHistoryReducer,
    MedicalHistory: MedicalHistoryReducer,
    Faq: faqReducer,
    PrakrutiStandard: prakrutiStandardReducer,
    TreatmentSlotBooking: TreatmentSlotReducer,
    SolutionSlotBooking: SolutionSlotReducer,
    TherapistHistory: TherapistReducer,
});
