import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import { plusicon, refreshicon } from '../../../components/imagepath';
import { CustomTable } from '../../../components/Table';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { useDispatch, useSelector } from 'react-redux';
import { ActionGetFoodpriceList } from '../../../store/Actions/StaffAction';
import { Button, Popover } from 'antd';
import { matchPermissions } from '../../../utils/Permission';

const Caretakerfoodpricelist = () => {
    const { foodpriceList } = useSelector(state => state?.Staff);
    const { userDetails } = useSelector(state => state?.auth);
    const [Refresh, setRefresh] = useState(false);
    const pageSize = 10;
    const dispatch = useDispatch();
    const location = useLocation();
    const params = queryString.parse(location.search);
    useEffect(() => {
        dispatch(ActionGetFoodpriceList({ Query: `?page=${params?.page || 1}` }));
        setRefresh(false);
    }, [dispatch, params?.page, Refresh])
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('care-tacker-food-price.update');
    const canCerate = matchPermissionsForUser('care-tacker-food-price.create');
    const content = (item) => (
        <div className="row col">
            {canUpdate &&
                <Button className="col m-2" onClick={() => { window.location.href = `/add-caretakerfoodprice?update=true&&page=${1}&&id=${item?.id}&&price=${item.price}` }}>Edit </Button>
            }
        </div>
    );
    const column = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Food Price",
            dataIndex: "price",


        },
        {
            title: !canUpdate ? null : "Action",
            dataIndex: "",
            render: (text, record) => {
                if (!canUpdate) {
                    return null
                }
                else {
                    return (
                        <Popover placement="left" title={record?.title} content={() => content(record)} >
                            <Button type="text">• • •</Button>
                        </Popover>
                    )
                }

            },
        },
    ]
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="">Caretaker Food Price</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Caretaker Food Price List</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Caretaker Food Price List</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">
                                                                    {canCerate &&
                                                                        <Link
                                                                            to="/add-caretakerfoodprice"
                                                                            className="btn btn-primary add-pluss ms-2"
                                                                        >
                                                                            <img src={plusicon} alt="#" />
                                                                        </Link>
                                                                    }

                                                                    <Link
                                                                        onClick={() => window.location.reload()}
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* /Table Header */}
                                            <CustomTable
                                                CurrentPageNum={parseInt(params?.page) || 1}
                                                // OnChange={(v) => {
                                                //     handleSearch1(v)
                                                // }}
                                                // TotalPageList={medicine?.totalDocs}
                                                column={column}
                                                dataSource={foodpriceList}
                                                dataSourceLength={foodpriceList.length}
                                                // pageSize={10}
                                                // loader={loader}
                                                ShowHeader={true}
                                                Header={<div className="staff-search-table">
                                                </div>}
                                                key={"hi"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default Caretakerfoodpricelist