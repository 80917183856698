export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const GET_ADMIN_USER_DETAILS = 'GET_ADMIN_USER_DETAILS';
export const GET_ADMIN_USER_DETAILS_SUCCESS = 'GET_ADMIN_USER_DETAILS_SUCCESS';
export const GET_APPCONFIG_LIST = 'GET_APPCONFIG_LIST';
export const APPCONFIG_LIST_SUCCESS = 'APPCONFIG_LIST_SUCCESS';
export const CREATE_APPCONFIG = 'CREATE_APPCONFIG';
export const DELETE_APPCONFIG = 'DELETE_APPCONFIG';
export const UPDATE_APPCONFIG = 'UPDATE_APPCONFIG';
export const GET_PATIENT_PLAN_LIST = 'GET_PATIENT_PLAN_LIST';
export const GET_PATIENT_LIST_PLAN_SUCCESS = 'GET_PATIENT_LIST_PLAN_SUCCESS';
export const GET_DISCHARGE_FORM = 'GET_DISCHARGE_FORM';
export const GET_DISCHARGE_FORM_SUCCESS = 'GET_DISCHARGE_FORM_SUCCESS';
export const GET_ADMIN_USER_DETAILS_LIST = 'GET_ADMIN_USER_DETAILS_LIST';
export const GET_ADMIN_USER_DETAILS_LIST_SUCCESS = 'GET_ADMIN_USER_DETAILS_LIST_SUCCESS';

// patient
export const CREATE_PATIENT = 'CREATE_PATIENT';
export const MEDICINE_LIST_SUCCESS = 'MEDICINE_LIST_SUCCESS';
export const MEDICINE_LIST = 'MEDICINE_LIST';
export const GET_LABORATORY_DATA = 'GET_LABORATORY_DATA';
export const GET_LABORATORY_DATA_SUCCESS = 'GET_LABORATORY_DATA_SUCCESS';
export const GET_PATIENT_LIST = 'GET_PATIENT_LIST';
export const GET_PATIENT_LIST_SUCCESS = 'GET_PATIENT_LIST_SUCCESS';
export const GET_CC_PAPER_DETAIL = 'GET_CC_PAPER_DETAIL';
export const GET_CC_PAPER_DETAIL_SUCCESS = 'GET_CC_PAPER_DETAIL_SUCCESS';
export const GET_CUSTOMER_BIRTHDATE_DETAIL = 'GET_CUSTOMER_BIRTHDATE_DETAIL';
export const GET_CUSTOMER_BIRTHDATE_DETAIL_SUCCESS = 'GET_CUSTOMER_BIRTHDATE_DETAIL_SUCCESS';
export const GET_CC_PAPER_VERIFICATION_DETAIL = 'GET_CC_PAPER_VERIFICATION_DETAIL';
export const GET_CC_PAPER_VERIFICATION_DETAIL_SUCCESS = 'GET_CC_PAPER_VERIFICATION_DETAIL_SUCCESS';
export const GET_CUS_DASHBOARD = 'GET_CUS_DASHBOARD';
export const GET_CUS_DASHBOARD_SUCCESS = 'GET_CUS_DASHBOARD_SUCCESS';
export const GET_MEDICAL_DETAIL = 'GET_MEDICAL_DETAIL';
export const GET_MEDICAL_DETAIL_SUCCESS = 'GET_MEDICAL_DETAIL_SUCCESS';
export const GET_PATIENT_BY_ID = 'GET_PATIENT_BY_ID';
export const GET_PATIENT_BY_ID_SUCCESS = 'GET_PATIENT_BY_ID_SUCCESS';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const GET_PATIENT_CONFIRMATION_DETAIL = 'GET_PATIENT_CONFIRMATION_DETAIL';
export const GET_PATIENT_CONFIRMATION_DETAIL_SUCCESS = 'GET_PATIENT_CONFIRMATION_DETAIL_SUCCESS';
// schedule
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const GET_ALL_SCHEDULE_LIST = 'GET_ALL_SCHEDULE_LIST';
export const GET_ALL_SCHEDULE_LIST_SUCESS = 'GET_ALL_SCHEDULE_LIST_SUCESS';

// role
export const GET_ROLE_LIST = 'GET_ROLE_LIST';
export const CREATE_ROLES = 'CREATE_ROLES';
export const GET_ROLE_LIST_SUCCESS = 'GET_ROLE_LIST_SUCCESS';
export const GET_ROLE_LIST_PAGINATION = 'GET_ROLE_LIST_PAGINATION';
export const GET_ROLE_LIST_PAGINATION_SUCCESS = 'GET_ROLE_LIST_PAGINATION_SUCCESS';

// COMMON-USAGE
export const UPDATE_BY_ID = 'UPDATE_BY_ID';
export const DELETE_BY_ID = 'DELETE_BY_ID';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const CREATE_BY_URL = 'CREATE_BY_URL';

export const GET_ALL_GALLERY = 'GET_ALL_GALLERY';
export const GET_ALL_GALLERY_SUCCESS = 'GET_ALL_GALLERY_SUCCESS';

export const GET_ALL_DASHBOARD_DATA = 'GET_ALL_DASHBOARD_DATA';
export const GET_ALL_DASHBOARD_DATA_SUCCESS = 'GET_ALL_DASHBOARD_DATA_SUCCESS';

// CREATE SERVICE
export const CREATE_SERVICE = 'CREATE_SERVICE';
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';
export const GET_ALL_SERVICE = 'GET_ALL_SERVICE';
export const GET_ALL_SERVICE_SUCCESS = 'GET_ALL_SERVICE_SUCCESS';

//TREATMENTS
export const CREATE_TREATMENT = 'CREATE_TREATMENT';
export const CREATE_TREATMENT_SUCCESS = 'CREATE_TREATMENT_SUCCESS';
export const GET_ALL_TREATMENT = 'GET_ALL_TREATMENT';
export const GET_ALL_TREATMENT_SUCCESS = 'GET_ALL_TREATMENT_SUCCESS';
//PROGRAMMES
export const GET_ALL_PROGRAMMES = 'GET_ALL_PROGRAMMES';
export const GET_ALL_PROGRAMMES_SUCCESS = 'GET_ALL_PROGRAMMES_SUCCESS';
export const GET_ALL_PROGRAMMES_LIST = 'GET_ALL_PROGRAMMES_LIST';
export const GET_ALL_PROGRAMMES_LIST_SUCCESS = 'GET_ALL_PROGRAMMES_LIST_SUCCESS';
export const GET_PROGRAMME_BOOKING = 'GET_PROGRAMME_BOOKING';
export const GET_PROGRAMME_BOOKING_SUCCESS = 'GET_PROGRAMME_BOOKING_SUCCESS';
export const UPDATE_PROGRAMME_SLOT = 'UPDATE_PROGRAMME_SLOT';

//SOLUTIONS

export const CREATE_SOLUTION = 'CREATE_SOLUTION';
export const CREATE_SOLUTION_SUCCESS = 'CREATE_SOLUTION_SUCCESS';
export const GET_ALL_SOLUTION = 'GET_ALL_SOLUTION';
export const GET_ALL_SOLUTION_SUCCESS = 'GET_ALL_SOLUTION_SUCCESS';

//CELEBRITIES
export const CREATE_CELEBRITY = 'CREATE_CELEBRITY';
export const CREATE_CELEBRITY_SUCCESS = 'CREATE_CELEBRITY_SUCCESS';
export const GET_ALL_CELEBRITY = 'GET_ALL_CELEBRITY';
export const GET_ALL_CELEBRITY_SUCCESS = 'GET_ALL_CELEBRITY_SUCCESS';

//TESTIMONIAL LINKS
export const CREATE_TESTIMONIAL_LINK = 'CREATE_TESTIMONIAL_LINK';
export const CREATE_TESTIMONIAL_LINK_SUCCESS = 'CREATE_TESTIMONIAL_LINK_SUCCESS';
export const GET_ALL_TESTIMONIAL_LINK = 'GET_ALL_TESTIMONIAL_LINK';
export const GET_ALL_TESTIMONIAL_LINK_SUCCESS = 'GET_ALL_TESTIMONIAL_LINK_SUCCESS';

//PATIENT TESTIMONIAL
export const CREATE_PATIENT_TESTIMONIAL = 'CREATE_PATIENT_TESTIMONIAL';
export const CREATE_PATIENT_TESTIMONIAL_SUCCESS = 'CREATE_PATIENT_TESTIMONIAL_SUCCESS';
export const GET_ALL_PATIENT_TESTIMONIAL = 'GET_ALL_PATIENT_TESTIMONIAL';
export const GET_ALL_PATIENT_TESTIMONIAL_SUCCESS = 'GET_ALL_PATIENT_TESTIMONIAL_SUCCESS';

//FAQS
export const CREATE_FAQ = 'CREATE_FAQ';
export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS';
export const GET_ALL_FAQ = 'GET_ALL_FAQ';
export const GET_ALL_FAQ_SUCCESS = 'GET_ALL_FAQ_SUCCESS';
//PRAKRUTI STANDARD
export const CREATE_PRAKRUTI_STANDARD = 'CREATE_PRAKRUTI_STANDARD';
export const CREATE_PRAKRUTI_STANDARD_SUCCESS = 'CREATE_PRAKRUTI_STANDARD_SUCCESS';
export const GET_ALL_PRAKRUTI_STANDARD = 'GET_ALL_PRAKRUTI_STANDARD';
export const GET_ALL_PRAKRUTI_STANDARD_SUCCESS = 'GET_ALL_PRAKRUTI_STANDARD_SUCCESS';

//TREATMENT-PROTOCOL
export const CREATE_TREATMENT_PROTOCOL = 'CREATE_TREATMENT_PROTOCOL';
export const CREATE_TREATMENT_PROTOCOL_SUCCESS = 'CREATE_TREATMENT_PROTOCOL_SUCCESS';
export const GET_ALL_TREATMENT_PROTOCOL = 'GET_ALL_TREATMENT_PROTOCOL';
export const GET_ALL_TREATMENT_PROTOCOL_SUCCESS = 'GET_ALL_TREATMENT_PROTOCOL_SUCCESS';
export const GET_ALL_TREATMENT_SERVICE_DATA = 'GET_ALL_TREATMENT_SERVICE_DATA';
export const GET_ALL_TREATMENT_SERVICE_DATA_SUCCESS = 'GET_ALL_TREATMENT_SERVICE_DATA_SUCCESS';

//DETOX-RETREAT
export const GET_ALL_DETOX_RETREAT = 'GET_ALL_DETOX_RETREAT';
export const GET_ALL_DETOX_RETREAT_SUCCESS = 'GET_ALL_DETOX_RETREAT_SUCCESS';
export const CREATE_DETOX_RETREAT = 'CREATE_DETOX_RETREAT';
export const CREATE_DETOX_RETREAT_SUCCESS = 'CREATE_DETOX_RETREAT_SUCCESS';
export const GET_DETOX_RETREAT_BOOKING = 'GET_DETOX_RETREAT_BOOKING';
export const GET_DETOX_RETREAT_BOOKING_SUCCESS = 'GET_DETOX_RETREAT_BOOKING_SUCCESS';
//SERVICE SLOT

export const GET_ALL_SERVICE_SLOT = 'GET_ALL_SERVICE_SLOT';
export const GET_ALL_SERVICE_SLOT_SUCCESS = 'GET_ALL_SERVICE_SLOT_SUCCESS';
export const UPDATE_SERVICE_SLOT = 'UPDATE_SERVICE_SLOT';
export const GET_SERVICE_SLOT_BY_ID = 'GET_SERVICE_SLOT_BY_ID';
export const GET_SERVICE_SLOT_BY_ID_SUCCESS = 'GET_SERVICE_SLOT_BY_ID_SUCCESS';

// staff
export const CREATE_STAFF = 'CREATE_STAFF';
export const GET_STAFF_LIST = 'GET_STAFF_LIST';
export const GET_STAFF_LIST_SUCCESS = 'GET_STAFF_LIST_SUCCESS';
export const GET_STAFF_PROFILE = 'GET_STAFF_PROFILE';
export const GET_STAFF_PROFILE_SUCCESS = 'GET_STAFF_PROFILE_SUCCESS';
export const UPDATE_STAFF_PROFILE = 'UPDATE_STAFF_PROFILE';

// PERMISSIONS
export const GET_PERMISSIONS_LIST = 'GET_PERMISSIONS_LIST';
export const GET_PERMISSIONS_LIST_SUCCESS = 'GET_PERMISSIONS_LIST_SUCCESS';
export const CREATE_PERMISSIONS = 'CREATE_PERMISSIONS';
export const GET_PAGINATED_PERMISSIONS_LIST = 'GET_PAGINATED_PERMISSIONS_LIST';
export const GET_PAGINATED_PERMISSIONS_LIST_SUCCESS = 'GET_PAGINATED_PERMISSIONS_LIST_SUCCESS';

// ,

export const GET_ADMIN_USER = 'GET_ADMIN_USER';
export const GET_ADMIN_USER_SUCCESS = 'GET_ADMIN_USER_SUCCESS';
export const GET_ALL_CONSULTATION = 'GET_ALL_CONSULTATION';
export const GET_ALL_CONSULTATION_SUCCESS = 'GET_ALL_CONSULTATION_SUCCESS';
export const GET_ALL_APPOINMENT = 'GET_ALL_APPOINMENT';
export const GET_ALL_MEMBERSHIP = 'GET_ALL_MEMBERSHIP';
export const GET_ALL_APPOINMENT_SUCCESS = 'GET_ALL_APPOINMENT_SUCCESS';
export const GET_DR_AVAILABILITY = 'GET_DR_AVAILABILITY';
export const GET_DR_AVAILABILITY_SUCCESS = 'GET_DR_AVAILABILITY_SUCCESS';
export const GET_DR_SLOT_AVAILABILITY = 'GET_DR_SLOT_AVAILABILITY';
export const GET_DR_SLOT_AVAILABILITY_SUCCESS = 'GET_DR_SLOT_AVAILABILITY_SUCCESS';
export const CONSULTATION_MASTERDATA = 'CONSULTATION_MASTERDATA';
export const CONSULTATION_MASTERDATA_SUCCESS = 'CONSULTATION_MASTERDATA_SUCCESS';
export const CREATE_MEDICAL_DETAILS = 'CREATE_MEDICAL_DETAILS';
export const CREATE_MEDICAL_DETAILS_SUCCESS = 'CREATE_MEDICAL_DETAILS_SUCCESS';
export const GET_ASSIGNED_DOCTOR_LIST = 'GET_ASSIGNED_DOCTOR_LIST';
export const GET_ASSIGNED_DOCTOR_LIST_SUCCESS = 'GET_ASSIGNED_DOCTOR_LIST_SUCCESS';

//LEAVE
export const CREATE_LEAVE = 'CREATE_LEAVE';
export const CREATE_LEAVE_SUCCESS = 'CREATE_LEAVE_SUCCESS';
export const GET_LEAVE_REQUEST = 'GET_LEAVE_REQUEST';
export const GET_LEAVE_REQUEST_SUCCESS = 'GET_LEAVE_REQUEST_SUCCESS';

//HOLIDAYS

export const CREATE_HOLIDAY = 'CREATE_HOLIDAY';
export const GET_HOLIDAY_LIST = 'GET_HOLIDAY_LIST';
export const HOLIDAY_LIST_SUCCESS = 'HOLIDAY_LIST_SUCCESS';
export const UPDATE_HOLIDAY = 'UPDATE_HOLIDAY';
export const GET_HOLIDAY_BY_ID = 'GET_HOLIDAY_BY_ID';
export const GET_HOLIDAY_BY_ID_SUCCESS = 'GET_HOLIDAY_BY_ID_SUCCESS';
export const DELETE_HOLIDAY = 'DELETE_HOLIDAY';

//THERAPIST
export const GET_THERAPIST_SCHEDULE = 'GET_THERAPIST_SCHEDULE';
export const GET_THERAPIST_SCHEDULE_SUCCESS = 'GET_THERAPIST_SCHEDULE_SUCCESS';
export const GET_THERAPIST_SHIFTS = 'GET_THERAPIST_SHIFTS';
export const GET_THERAPIST_SHIFTS_SUCCESS = 'GET_THERAPIST_SHIFTS_SUCCESS';
export const GET_LEAVES = 'GET_LEAVES';
export const GET_LEAVES_SUCCESS = 'GET_LEAVES_SUCCESS';
export const GET_THERAPIST_COUNT = 'GET_THERAPIST_COUNT';
export const GET_THERAPIST_COUNT_SUCCESS = 'GET_THERAPIST_COUNT_SUCCESS';

//
export const UPDATE_PERSONAL_DETAILS = "PERSONAL_DETAILS";
export const UPDATE_PERSONAL_DETAILS_SUCCESS = "PERSONAL_DETAILS_SUCCESS";

//DO_AND_DONTS

export const GET_DO_AND_DONTS_LIST = 'GET_DO_AND_DONTS_LIST';
export const DO_AND_DONTS_LIST_SUCCESS = 'DO_AND_DONTS_LIST_SUCCESS';
export const CREATE_DO_AND_DONTS = 'CREATE_DO_AND_DONTS';
export const DELETE_DO_AND_DONTS = 'DELETE_DO_AND_DONTS';
export const UPDATE_DO_AND_DONTS = 'UPDATE_DO_AND_DONTS';

//EXERCISE

export const GET_EXERCISE_LIST = 'GET_EXERCISE_LIST';
export const EXERCISE_LIST_SUCCESS = 'EXERCISE_LIST_SUCCESS';
export const CREATE_EXERCISE = 'CREATE_EXERCISE';
export const DELETE_EXERCISE = 'DELETE_EXERCISE';
export const UPDATE_EXERCISE = 'UPDATE_EXERCISE';

//VIRECHAN

export const GET_VIRECHAN_LIST = 'GET_VIRECHAN_LIST';
export const VIRECHAN_LIST_SUCCESS = 'VIRECHAN_LIST_SUCCESS';
export const CREATE_VIRECHAN = 'CREATE_VIRECHAN';
export const UPDATE_VIRECHAN = 'UPDATE_VIRECHAN';
export const DELETE_VIRECHAN = 'DELETE_VIRECHAN';

//MEMBERSHIP

export const GET_MEMBERSHIP_LIST = 'GET_MEMBERSHIP_LIST';
export const MEMBERSHIP_LIST_SUCCESS = 'MEMBERSHIP_LIST_SUCCESS';
export const GET_ALL_MEMBERSHIP_LIST = 'GET_ALL_MEMBERSHIP_LIST';
export const GET_ALL_MEMBERSHIP_LIST_SUCCESS = 'GET_ALL_MEMBERSHIP_LIST_SUCCESS';
export const GET_ALL_MEMBER_VERIFY_LIST = 'GET_ALL_MEMBER_VERIFY_LIST';
export const GET_ALL_MEMBER_VERIFY_LIST_SUCCESS = 'GET_ALL_MEMBER_VERIFY_LIST_SUCCESS';
export const CREATE_MEMBERSHIP = 'CREATE_MEMBERSHIP';
export const UPDATE_MEMBERSHIP = 'UPDATE_MEMBERSHIP';
export const DELETE_MEMBERSHIP = 'DELETE_MEMBERSHIP';
export const UPDATE_CUSTOMER_MEMBERSHIP = 'UPDATE_CUSTOMER_MEMBERSHIP';
export const UPDATE_CUSTOMER_MEMBERSHIP_SUCCESS = 'UPDATE_CUSTOMER_MEMBERSHIP_SUCCESS';

// MEDICINE

export const GET_MEDICINE_LIST = 'GET_MEDICINE_LIST';
export const GET_MEDICINE_LIST_SUCCESS = 'GET_MEDICINE_LIST_SUCCESS';
export const CREATE_MEDICINE = 'CREATE_MEDICINE';
export const UPDATE_MEDICINE = 'UPDATE_MEDICINE';
export const GET_MEDICINE_BY_ID = 'GET_MEDICINE_BY_ID';
export const GET_MEDICINE_BY_ID_SUCCESS = 'GET_MEDICINE_BY_ID_SUCCESS';
export const DELETE_MEDICINE = 'DELETE_MEDICINE';

//ROOMS

export const GET_ROOM_LIST = 'GET_ROOM_LIST';
export const GET_ROOM_LIST_SUCCESS = 'GET_ROOM_LIST_SUCCESS';
export const CREATE_ROOM = 'CREATE_ROOM';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const GET_ROOM_BY_ID = 'GET_ROOM_BY_ID';
export const GET_ROOM_BY_ID_SUCCESS = 'GET_ROOM_BY_ID_SUCCESS';
export const DELETE_ROOM = 'DELETE_ROOM';
export const GET_ROOM_COUNT = 'GET_ROOM_COUNT';
export const GET_ROOM_COUNT_SUCCESS = 'GET_ROOM_COUNT_SUCCESS';
export const GET_ROOM_SLOT = 'GET_ROOM_SLOT';
export const GET_ROOM_SLOT_SUCCESS = 'GET_ROOM_SLOT_SUCCESS';
export const GET_ROOM_SLOT_BY_ID = 'GET_ROOM_SLOT_BY_ID';
export const GET_ROOM_SLOT_BY_ID_SUCCESS = 'GET_ROOM_SLOT_BY_ID_SUCCESS';
export const GET_ROOM_DASHBOARD_DATA = 'GET_ROOM_DASHBOARD_DATA';
export const GET_ROOM_DASHBOARD_DATA_SUCCESS = 'GET_ROOM_DASHBOARD_DATA_SUCCESS';
export const GET_ROOM_BY_ROOM_TYPE = "GET_ROOM_BY_ROOM_TYPE";
export const GET_ROOM_BY_ROOM_TYPE_SUCCESS = "GET_ROOM_BY_ROOM_TYPE_SUCCESS";

//Price Table

export const GET_ALL_PRICE_LIST = 'GET_ALL_PRICE_LIST';
export const GET_ALL_PRICE_LIST_SUCCESS = 'GET_ALL_PRICE_LIST_SUCCESS';
export const CREATE_PRICE = 'CREATE_PRICE';
export const UPDATE_PRICE = 'UPDATE_PRICE';
export const GET_PRICE_BY_ID = 'GET_PRICE_BY_ID';
export const GET_PRICE_BY_ID_SUCCESS = 'GET_PRICE_BY_ID_SUCCESS';
export const DELETE_PRICE = 'DELETE_PRICE';

//Medical Store

export const GET_ALL_MEDICAL_DETAILS = 'GET_ALL_MEDICAL_DETAILS';
export const GET_ALL_MEDICAL_DETAILS_SUCCESS = 'GET_ALL_MEDICAL_DETAILS_SUCCESS';
export const UPDATE_MEDICAL_DETAILS = 'UPDATE_MEDICAL_DETAILS';
export const GET_MEDICAL_DETAILS_BY_ID = 'GET_MEDICAL_DETAILS_BY_ID';
export const GET_MEDICAL_DETAILS_BY_ID_SUCCESS = 'GET_MEDICAL_DETAILS_BY_ID_SUCCESS';

//Medical History
export const GET_ALL_MEDICAL_HISTORY = 'GET_ALL_MEDICAL_HISTORY';
export const GET_ALL_MEDICAL_HISTORY_SUCCESS = 'GET_ALL_MEDICAL_HISTORY_SUCCESS';

//Order & HIstory

export const GET_ALL_ORDER_LIST = 'GET_ALL_ORDER_LIST';
export const GET_ALL_ORDER_LIST_SUCCESS = 'GET_ALL_ORDER_LIST_SUCCESS';
export const GET_ALL_PENDING_ORDER_LIST = 'GET_ALL_PENDING_ORDER_LIST';
export const GET_ALL_PENDING_ORDER_LIST_SUCCESS = 'GET_ALL_PENDING_ORDER_LIST_SUCCESS';
export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS';

//TREATMENT SLOT

export const GET_ALL_TREATMENT_SLOT = 'GET_ALL_TREATMENT_SLOT';
export const GET_ALL_TREATMENT_SLOT_SUCCESS = 'GET_ALL_TREATMENT_SLOT_SUCCESS';
export const GET_TREATMENT_SLOT_BY_ID = 'GET_TREATMENT_SLOT_BY_ID';
export const GET_TREATMENT_SLOT_BY_ID_SUCCESS = 'GET_TREATMENT_SLOT_BY_ID_SUCCESS';
export const UPDATE_TREATMENT_SLOT = 'UPDATE_TREATMENT_SLOT';

//SOLUTION SLOT

export const GET_ALL_SOLUTION_SLOT = 'GET_ALL_SOLUTION_SLOT';
export const GET_ALL_SOLUTION_SLOT_SUCCESS = 'GET_ALL_SOLUTION_SLOT_SUCCESS';
export const GET_SOLUTION_SLOT_BY_ID = 'GET_SOLUTION_SLOT_BY_ID';
export const GET_SOLUTION_SLOT_BY_ID_SUCCESS = 'GET_SOLUTION_SLOT_BY_ID_SUCCESS';
export const UPDATE_SOLUTION_SLOT = 'UPDATE_SOLUTION_SLOT';

//CARE TAKER FOODPRICE
export const GET_ALL_FOODPRICE_LIST = 'GET_ALL_FOODPRICE_LIST';
export const GET_ALL_FOODPRICE_LIST_SUCCESS = 'GET_ALL_FOODPRICE_LIST_SUCCESS';

//THERAPIST ALLOCATION
export const GET_ALL_THERAPIST_HISTORY = 'GET_ALL_THERAPIST_HISTORY';
export const GET_ALL_THERAPIST_HISTORY_SUCCESS = 'GET_ALL_THERAPIST_HISTORY_SUCCESS';
export const GET_ALL_THERAPIST_HISTORY_BY_ID = 'GET_ALL_THERAPIST_HISTORY_BY_ID';
export const GET_ALL_THERAPIST_HISTORY_BY_ID_SUCCESS = 'GET_ALL_THERAPIST_HISTORY_BY_ID_SUCCESS';
export const GET_THERAPIST_CSV_DATA = 'GET_THERAPIST_CSV_DATA';
export const GET_THERAPIST_CSV_DATA_SUCCESS = 'GET_THERAPIST_CSV_DATA_SUCCESS';

