/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect, useMemo } from "react";

import { Button, Modal, Popover } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import queryString from 'query-string';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ResponsiveComponent } from "../../utils/Funcations";
import Sidebar from "../../components/Sidebar";
import { CustomTable } from "../../components/Table";
import { refreshicon } from "../../components/imagepath";
import Header from "../../components/Header";
import { ActionGetAllMedicalDetail } from "../../store/Actions/MedicalStoreAction";
import { matchPermissions } from "../../utils/Permission";
const { confirm } = Modal;

const MedicalStore = () => {
    const { loader, medicalStore } = useSelector(state => state?.MedicalStore);
    const { userDetails } = useSelector(state => state?.auth);
    const [showArrow, setShowArrow] = useState(true);
    const [Refresh, setRefresh] = useState(false);
    const [arrowAtCenter, setArrowAtCenter] = useState(false);
    const [IsSearching, setIsSearching] = useState(false);
    const pageSize = 10;
    const dispatch = useDispatch();
    const location = useLocation();
    const params = queryString.parse(location.search);
    const Size = ResponsiveComponent()
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [SearchList, setSearchList] = useState([]);
    const mergedArrow = useMemo(() => {
        if (arrowAtCenter)
            return {
                pointAtCenter: true,
            };
        return showArrow;
    }, [showArrow, arrowAtCenter]);
    const handleSearch = (selectedKeys, dataIndex) => {
        setSearchText(selectedKeys?.[0]);
        setSearchedColumn(dataIndex);

        if (params[dataIndex]) {
            window.location.replace(`/medical-store?page=1&filterlist=true&${dataIndex}=${selectedKeys?.[0]}`)
        } else {
            window.location.replace(`/medical-store?${location.search}&${dataIndex}=${selectedKeys?.[0]}`)
        }

    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        window.location.replace(`/medical-store`)
    }
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('medical.update');
    const content = (item) => (
        <div className="text-center">
            {canUpdate &&
                <Button className="m-2" onClick={() => EditList(item?.id)}>Update</Button>
            }
        </div>
    );
    const column = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Patient",
            dataIndex: "name",
            render: (text, record) => (
                <span>{record?.customerId?.name}</span>
            ),
        },
        {
            title: "Consultation Type",
            dataIndex: "consultationType",
            render: (text, record) => (
                <span>{record?.appointmentId?.consultationType}</span>
            ),
            // ...TableColumnSearch({ dataIndex: 'docs.roomType', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },
        {
            title: "OldCasePaperNo.",
            dataIndex: "oldCasePaperNo",
            render: (text, record) => (
                <span>{record?.customerId?.oldCasePaperNo}</span>
            ),
        },
        {
            title: "NewCasePaperNo.",
            dataIndex: "registrationNo",
            render: (text, record) => (
                <span>{record?.customerId?.registrationNo}</span>
            ),
        },
        {
            title: "Recomended Medicines",
            dataIndex: "recomendedMedicines",
            render: (text, record) => (
                <span>{record?.recomendedMedicines[0]?.medicineName}</span>
            ),
        },
        {
            title: "Days",
            dataIndex: "days",
            render: (text, record) => (
                <span>{record?.recomendedMedicines[0]?.days}</span>
            ),
        },
        {
            title: "When to Take",
            dataIndex: "whenToTake",
            render: (text, record) => (
                <span>{record?.recomendedMedicines[0]?.whenToTake.join("-")}</span>
            ),
        }, {
            title: !canUpdate ? null : "Action",
            dataIndex: "id",
            key: "action",
            render: (text, record, num) => {
                if (canUpdate) {
                    return (
                        <Popover placement="left" title={record.title} content={() => content(record)} arrow={mergedArrow}>
                            <Button type="dashed">• • •</Button>
                        </Popover>
                    )
                }

            },
        },
    ];
    useEffect(() => {
        dispatch(ActionGetAllMedicalDetail({ Query: `?page=${params?.page || 1}` }));
        setRefresh(false);
    }, [Refresh])
    const handleSearch1 = (searchTerm) => {
        var newp = params
        newp.page = searchTerm
        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
    }
    const EditList = (searchTerm) => {
        window.location.href = `/medical-store/edit-medical-store?update=true&&id=${searchTerm}&&page=${parseInt(params.page)}`
    }
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/medical-store">Medical Store</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Medical Store List</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Medical Store List</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">

                                                                    <Link
                                                                        onClick={() => window.location.reload()}
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* /Table Header */}
                                            <CustomTable
                                                CurrentPageNum={parseInt(params?.page) || 1}
                                                OnChange={(v) => {
                                                    handleSearch1(v)
                                                }}
                                                TotalPageList={medicalStore?.totalDocs}
                                                column={column}
                                                dataSource={medicalStore?.docs}
                                                dataSourceLength={medicalStore?.docs?.length}
                                                pageSize={10}
                                                loader={loader}
                                                ShowHeader={true}
                                                Header={<div className="staff-search-table">
                                                </div>}
                                                key={"hi"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};

export default MedicalStore;