import { CREATE_BY_URL, DELETE_BY_ID, GET_ALL_DASHBOARD_DATA, GET_ALL_DASHBOARD_DATA_SUCCESS, GET_ALL_GALLERY, GET_ALL_GALLERY_SUCCESS, UPDATE_BY_ID, UPLOAD_IMAGE } from "../storeTypes";

const INIT_STATE = {
    loader: false,
    galleryList: {},
    getDashboardData: {},
};

function CommonReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case CREATE_BY_URL: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_BY_ID: {
            return {
                ...state,
                loader: true,
            };
        }
        case DELETE_BY_ID: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_GALLERY: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_GALLERY_SUCCESS: {
            return {
                ...state,
                loader: false,
                galleryList: action?.payload
            };
        }
        case GET_ALL_DASHBOARD_DATA: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_DASHBOARD_DATA_SUCCESS: {
            return {
                ...state,
                loader: false,
                getDashboardData: action?.payload
            };
        }
        case UPLOAD_IMAGE: {
            return {
                ...state,
                loader: true,
            };
        }
        default: {
            return state;
        }
    }
}

export default CommonReducer;
