import { GET_ADMIN_USER_DETAILS, GET_ADMIN_USER_DETAILS_LIST, GET_ADMIN_USER_DETAILS_LIST_SUCCESS, GET_ADMIN_USER_DETAILS_SUCCESS, USER_LOGIN, USER_LOGIN_SUCCESS } from "../storeTypes";

const INIT_STATE = {
  loader: false,
  authUser: null,
  userDetails: null,
  userDetailList: null,
};

function authReducer(state = INIT_STATE, action) {

  switch (action.type) {


    case USER_LOGIN: {
      return {
        ...state,
        loader: true,
      };
    }
    case USER_LOGIN_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case GET_ADMIN_USER_DETAILS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_ADMIN_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        userDetails: action?.payload,
      };
    }
    case GET_ADMIN_USER_DETAILS_LIST: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_ADMIN_USER_DETAILS_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        userDetailList: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export default authReducer;
