import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionLoginUserSuccess, GetAdminUserDetailsListSuccess, getAdminuUerDetailsSuccess } from '../Actions/AuthActions';
import { GET_ADMIN_USER_DETAILS, GET_ADMIN_USER_DETAILS_LIST, USER_LOGIN } from '../storeTypes';
import { GetAdminUserDetailsListApi, getAdminuserdetailsApi, handleLoginApi } from '../../utils/api';
import { handleApiResponse } from '../../utils/Funcations';

const File = 'authSaga '
function* userloginSaga(action) {
  try {
    const response = yield call(handleLoginApi, action.payload);
    if (response?.status == 200) {
      localStorage.setItem('token', response?.data?.data?.[1]?.access?.token);
      yield put(ActionLoginUserSuccess(response?.data?.data));

      action?.callBack(response);
    } else {
      action?.callBack(response)
    }
  } catch (error) {
    console.log(File + 'userloginSaga Error :- ', error);
    handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
  }
}
function* getAdminuUerDetailsSaga(action) {
  try {
    const response = yield call(getAdminuserdetailsApi, action.payload);
    if (response?.status == 200) {
      yield put(getAdminuUerDetailsSuccess(response?.data?.data));
    } else {
      action?.callBack(response)
    }
  } catch (error) {
    console.log(File + 'getAdminuUerDetailsSaga Error :- ', error);
    handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
  }
}

function* GetAdminUserDetailsListSaga(action) {
  try {
    const response = yield call(GetAdminUserDetailsListApi, action?.payload);
    if (response?.status == 200) {
      yield put(GetAdminUserDetailsListSuccess(response?.data?.data));
    } else {
      action?.callBack(response)
    }
  } catch (error) {
    console.log(File + 'GetAdminUserDetailsListSaga Error :- ', error);
    handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
  }
}

export function* authSaga() {
  [
    yield takeEvery(USER_LOGIN, userloginSaga),
    yield takeEvery(GET_ADMIN_USER_DETAILS, getAdminuUerDetailsSaga),
    yield takeEvery(GET_ADMIN_USER_DETAILS_LIST, GetAdminUserDetailsListSaga),
  ];
}
