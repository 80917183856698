/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { Modal } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { ActionCreateSchedule } from "../../store/Actions/DoctorAction";
import { Options } from "../../utils/Data";
import { CreateScheduleValidationSchema } from "../../utils/FormValidation";
import { SingleMultiSelectDropDown } from "../../components/Input/InputText";
// eslint-disable-next-line react/prop-types
const CalanderModal = ({ IsOpen = false, Isclose, staffId, value = [] }) => {
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const [Loader, setLoader] = useState(false);
    const { StaffProfile, loader } = useSelector(state => state?.Staff);

    const addRow = () => {
        const newRow = {
            id: rows.length + 1,
        };
        setRows([...rows, newRow]);
    };
    const removeRow = (id) => {
        const updatedRows = rows.filter((row) => row.id !== id);
        setRows(updatedRows);
    };
    return (
        <>
            <div>
                <div className="page-wrapper">
                    <div className="content">
                        <div className="row">
                            <div className="col-sm-12">
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        schedule: value.length != 0 ? value : [{
                                            days: "",
                                            spentTime: "",
                                            EndTime: "",
                                            type: "",
                                        }] || [{
                                            days: "",
                                            spentTime: "",
                                            EndTime: "",
                                            type: "",
                                        }]
                                    }}
                                    validationSchema={CreateScheduleValidationSchema}
                                    onSubmit={(values, { setSubmitting, setErrors }) => {
                                        dispatch(ActionCreateSchedule(
                                            {
                                                obj: { "schedule": values?.schedule },
                                                Query: staffId
                                            },
                                            (Res) => {
                                                if (Res?.statusCode === 200) {
                                                    setLoader(true)
                                                    window.location.href = `/staff-list/staff-profile/${staffId}`

                                                } else if (Res?.statusCode === 400) {
                                                    setLoader(false)
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth',
                                                    });
                                                    setErrors(Res?.data?.errors);
                                                }
                                            }));
                                        setSubmitting(false);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        setFieldValue,
                                        setFieldTouched,
                                        setFieldError,
                                        isSubmitting,
                                    }) =>
                                    (
                                        <div>
                                            <Modal
                                                open={IsOpen}
                                                closable={true}
                                                className='calander-modal'
                                                forceRender={true}
                                                zIndex={1040}
                                                onOk={() => handleSubmit()}
                                                styles={{
                                                    body: {
                                                        overflow: "hidden",
                                                    },
                                                    mask: {
                                                        backdropFilter: 'blur(10px)',
                                                    },
                                                    content: {
                                                        borderRadius: 40,
                                                    },
                                                }}
                                                okText={'Submit'}
                                                centered
                                                confirmLoading={Loader}
                                                onCancel={Isclose}
                                                closeIcon={true}
                                            >

                                                <div className="row" >
                                                    <div className="col-12">
                                                        <div className="invoice-add-table">
                                                            <h4>Doctor Schedule</h4>
                                                            <div className="table-responsive">
                                                                <table className="table table-center add-table-items">
                                                                    <thead>
                                                                        <tr className="col-xl-3 col-md-12">
                                                                            <th>Days</th>
                                                                            <th>Start Time</th>
                                                                            <th>End Time</th>
                                                                            <th>Type</th>
                                                                            <th>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    {values?.schedule?.map((itm, index) => {
                                                                        return (
                                                                            <>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className="col-xl-3">
                                                                                            <SingleMultiSelectDropDown
                                                                                                mode={""}
                                                                                                options={Options.AllDayData}
                                                                                                error={errors?.schedule?.[index]?.days}
                                                                                                touched={touched?.schedule?.[index]?.days}
                                                                                                value={itm?.days}
                                                                                                onChange={(v) => {
                                                                                                    const updateArry = Object.assign([], values.schedule)
                                                                                                    updateArry[index] = {
                                                                                                        ...updateArry[index],
                                                                                                        ...{ days: v }
                                                                                                    }
                                                                                                    setFieldValue("schedule", updateArry, true)
                                                                                                }}
                                                                                                onBlur={() => {
                                                                                                    const updateArry = Object.assign([], touched.schedule)
                                                                                                    updateArry[index] = {
                                                                                                        ...updateArry[index],
                                                                                                        ...{ days: true }
                                                                                                    }
                                                                                                    setFieldTouched("schedule", updateArry, true)
                                                                                                }}
                                                                                            />

                                                                                        </td>
                                                                                        <td className="col-xl-3">
                                                                                            <SingleMultiSelectDropDown
                                                                                                mode={""}
                                                                                                FormControlClassName={"align-items-center"}
                                                                                                options={Options.AllDayTimeData}
                                                                                                error={errors?.schedule?.[index]?.spentTime}
                                                                                                touched={touched?.schedule?.[index]?.spentTime}
                                                                                                value={itm?.spentTime}
                                                                                                onChange={(v) => {
                                                                                                    const updateArry = Object.assign([], values.schedule)
                                                                                                    updateArry[index] = {
                                                                                                        ...updateArry[index],
                                                                                                        ...{ spentTime: v }
                                                                                                    }
                                                                                                    setFieldValue("schedule", updateArry, true)
                                                                                                }}
                                                                                                onBlur={() => {
                                                                                                    const updateArry = Object.assign([], touched.schedule)
                                                                                                    updateArry[index] = {
                                                                                                        ...updateArry[index],
                                                                                                        ...{ spentTime: true }
                                                                                                    }
                                                                                                    setFieldTouched("schedule", updateArry, true)
                                                                                                }}

                                                                                            />
                                                                                        </td>
                                                                                        <td className="col-xl-3">
                                                                                            <SingleMultiSelectDropDown
                                                                                                mode={""}
                                                                                                FormControlClassName={"align-items-center"}
                                                                                                options={Options.AllDayTimeData}
                                                                                                error={errors?.schedule?.[index]?.EndTime}
                                                                                                touched={touched?.schedule?.[index]?.EndTime}
                                                                                                value={itm?.EndTime}
                                                                                                onChange={(v) => {
                                                                                                    const updateArry = Object.assign([], values.schedule)
                                                                                                    updateArry[index] = {
                                                                                                        ...updateArry[index],
                                                                                                        ...{ EndTime: v }
                                                                                                    }
                                                                                                    setFieldValue("schedule", updateArry, true)
                                                                                                }}
                                                                                                onBlur={() => {
                                                                                                    const updateArry = Object.assign([], touched.schedule)
                                                                                                    updateArry[index] = {
                                                                                                        ...updateArry[index],
                                                                                                        ...{ EndTime: true }
                                                                                                    }
                                                                                                    setFieldTouched("schedule", updateArry, true)
                                                                                                }} />
                                                                                        </td>
                                                                                        <td className="col-xl-2">
                                                                                            <SingleMultiSelectDropDown
                                                                                                mode={""}
                                                                                                options={["Tele-Consultation", "IN-Person", "First shift (Therapist)", "Secound shift (Therapist)"]}
                                                                                                error={errors?.schedule?.[index]?.type}
                                                                                                touched={touched?.schedule?.[index]?.type}
                                                                                                value={itm?.type}
                                                                                                onChange={(v) => {
                                                                                                    const updateArry = Object.assign([], values.schedule)
                                                                                                    updateArry[index] = {
                                                                                                        ...updateArry[index],
                                                                                                        ...{ type: v }
                                                                                                    }
                                                                                                    setFieldValue("schedule", updateArry, true)
                                                                                                }}
                                                                                                onBlur={() => {
                                                                                                    const updateArry = Object.assign([], touched.schedule)
                                                                                                    updateArry[index] = {
                                                                                                        ...updateArry[index],
                                                                                                        ...{ type: true }
                                                                                                    }
                                                                                                    setFieldTouched("schedule", updateArry, true)
                                                                                                }}

                                                                                            />
                                                                                        </td>
                                                                                        <td className="add-remove text-end align-items-center" style={{ padding: "" }} key={"td6" + index}>
                                                                                            <Link
                                                                                                to="#"
                                                                                                className="add-btn me-3"
                                                                                                onClick={() => {
                                                                                                    const newValue = { "days": "", "spentTime": "", "EndTime": "", "type": "" };
                                                                                                    setFieldValue("schedule", [...values.schedule, newValue])
                                                                                                }}
                                                                                            >
                                                                                                <i className="fas fa-plus-circle" />
                                                                                            </Link>
                                                                                            <Link
                                                                                                to="#"
                                                                                                className="remove-btn"
                                                                                                onClick={() => {
                                                                                                    if (index !== 0) {
                                                                                                        const updatedschedule = [...values.schedule];
                                                                                                        updatedschedule.splice(index, 1);
                                                                                                        setFieldValue("schedule", updatedschedule);
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <i className="fe fe-trash-2">
                                                                                                    <FeatherIcon icon="trash-2" className={'mb-1'} />
                                                                                                </i>
                                                                                            </Link>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </>
                                                                        )
                                                                    })}

                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </div>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div >
                </div>
            </div >
        </>
    );
};

export default CalanderModal;
