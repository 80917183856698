import { call, put, takeEvery } from 'redux-saga/effects';
import { CreateScheduleApi, GetAllScheduleListApi } from '../../utils/api';
import { CREATE_SCHEDULE, GET_ALL_SCHEDULE_LIST } from '../storeTypes';
import { GetAllScheduleListSucess } from '../Actions/DoctorAction';

const File = 'PatientSaga '

function* CreateScheduleSaga(action) {
    try {
        const response = yield call(CreateScheduleApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'CreatePatientSaga Error :- ', error);
    }
}
function* GetAllScheduleListSaga(action) {
    try {
        const response = yield call(GetAllScheduleListApi, action.payload);
        if (response?.status === 200) {
            yield put(GetAllScheduleListSucess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'CreatePatientSaga Error :- ', error);
    }
}

export function* DoctorSaga() {
    [
        yield takeEvery(CREATE_SCHEDULE, CreateScheduleSaga),
        yield takeEvery(GET_ALL_SCHEDULE_LIST, GetAllScheduleListSaga),
    ];
}
