/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Formik, useFormik } from 'formik';
import { InputFromUser } from '../../../components/Input/input';
import { Card, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { ActionGetRoomSlotById } from '../../../store/Actions/MasterDataAction';
import { ActionCreateByUrl, ActionUpdateById } from '../../../store/Actions/CommonAction';
import { CheckOutRoomValidationSchema, collectCashSchema } from '../../../utils/FormValidation';

const UpdateCheckOut = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const updateValue = searchParams.get("update");
    const pageValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const idValue = searchParams.get("id");
    const { roomSlot } = useSelector(state => state?.MasterData);
    const { userDetails } = useSelector(state => state?.auth);
    const [Refresh, setRefresh] = useState(false);
    useEffect(() => {
        if (idValue) {
            dispatch(ActionGetRoomSlotById({ Query: `/${idValue}` }));
        }
    }, [idValue, dispatch, Refresh])
    const [IsCollectModal, setCollectModal] = useState(null);
    const completedPayment = roomSlot?.[0]?.orderId?.additionalInfo?.completedPayment;
    const totalAmount = roomSlot?.[0]?.orderId?.amount
    const IsPendingAmount = !(roomSlot?.[0]?.orderId?.additionalInfo?.completedPayment === '100%')
    const completedPercentage = parseFloat(completedPayment) / 100;
    const amount = parseFloat(totalAmount);
    const amountPaid = amount * completedPercentage;
    const pendingAmount = amount - amountPaid;
    const isAppoinmentOrService = (IsCollectModal?.bookingFor === "Appointment" || IsCollectModal?.bookingFor === "Service")
    const PendingAmt = isAppoinmentOrService ?
        IsCollectModal?.amount : pendingAmount

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            paymentMode: '',
            updatedPaidAmount: PendingAmt || '',
            transactionId: '',
        },
        validationSchema: collectCashSchema,
        onSubmit: (values, { setErrors }) => {
            const payload = {
                updatedBy: IsCollectModal?.updatedBy,
                updateByName: IsCollectModal?.updateByName,
                paymentMode: values.paymentMode,
                transactionId: values?.transactionId,
                paidAmount: roomSlot?.[0]?.orderId?.amount,
                updatedPaidAmount: pendingAmount,
                completedPayment: `100%`,
                paymentStatus: 'Completed',
            }
            dispatch(ActionCreateByUrl({ Query: `admin/order/order_update/${IsCollectModal?.orderId}`, obj: payload }
                , (Res) => {
                    if (Res?.statusCode === 200) {
                        setCollectModal(null)
                        setRefresh(true);
                    } else if (Res?.statusCode === 400) {
                        setErrors(Res?.data?.errors)
                    }
                }));
        },
    });
    const ListItems = (title, value) => {
        return (
            <p><strong>{title + ' : '}</strong>
                {value || '---'}</p>)
    }
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/room-slot">Room-slot</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Upate Room-slot</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card p-3">
                                            <div className="card-body">
                                                <Formik
                                                    enableReinitialize={updateValue}
                                                    initialValues={{
                                                        customerName: roomSlot?.[0]?.customerName || '-',
                                                        registrationNo: roomSlot?.[0]?.customerId?.registrationNo || '-',
                                                        phoneNo: roomSlot?.[0]?.customerId?.phoneNo || '-',
                                                        email: roomSlot?.[0]?.customerId?.email || '-',
                                                        roomNo: roomSlot?.[0]?.roomId?.roomNo || '-',
                                                        roomType: roomSlot?.[0]?.roomType || '-',
                                                        checkOut: moment().format('YYYY-MM-DD'),
                                                        checkOutTime: moment().format('HH:mm:ss'),
                                                        paymentMode: roomSlot?.[0]?.orderId?.paymentMode || '',
                                                        amount: roomSlot?.[0]?.orderId?.amount || '',
                                                        orderId: roomSlot?.[0]?.orderId?._id,
                                                        paymentStatus: roomSlot?.[0]?.orderId?.paymentStatus,
                                                        orderStatus: roomSlot?.[0]?.orderId?.orderStatus,
                                                    }}
                                                    validationSchema={CheckOutRoomValidationSchema}
                                                    onSubmit={(values, { setSubmitting, setErrors }) => {
                                                        if (updateValue && idValue != null) {
                                                            const updatedValues = {
                                                                ...values,
                                                                isCheckOut: true,
                                                                roomSlotId: idValue,
                                                                orderId: values?.orderId,
                                                                paymentStatus: values?.paymentStatus,
                                                                paymentMode: values?.paymentMode,
                                                                orderStatus: values?.orderStatus,
                                                                amount: values?.amount,
                                                                actualCheckOut: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                                                            };
                                                            dispatch(ActionUpdateById({ Query: `admin/room-slot/${idValue}`, obj: updatedValues }
                                                                , (Res) => {
                                                                    if (Res?.statusCode === 200) {
                                                                        window.location.href = `/room-slot?page=${pageValue}`
                                                                    } else if (Res?.statusCode === 400) {
                                                                        setErrors(Res?.data?.errors);
                                                                    }
                                                                    setSubmitting(false);
                                                                }));
                                                        }
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        // setFieldValue,
                                                        isSubmitting,
                                                    }) => {


                                                        return (
                                                            <div >
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-heading">
                                                                            <h4>Update Check Out Data</h4>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mt-4">
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Customer Name"
                                                                            value={values?.customerName}
                                                                            disabled
                                                                            name="customerName"
                                                                            onBlur={handleBlur}
                                                                        />
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            type='number'
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Mobile No"
                                                                            value={values?.phoneNo}
                                                                            disabled
                                                                            name="phoneNo"

                                                                            onBlur={handleBlur}

                                                                        />
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Email"
                                                                            value={values?.email}
                                                                            disabled
                                                                            name="email"

                                                                            onBlur={handleBlur}

                                                                        />

                                                                    </div>

                                                                    <div className="row mt-4">
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}

                                                                            FormControlClassName={'form-control '}
                                                                            label="Registration No"
                                                                            name="registrationNo"
                                                                            value={values?.registrationNo}
                                                                            disabled

                                                                            onBlur={handleBlur}

                                                                        />
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}

                                                                            FormControlClassName={'form-control '}
                                                                            label="Room Name"
                                                                            value={values?.roomNo}
                                                                            disabled
                                                                            name="roomName"

                                                                            onBlur={handleBlur}

                                                                        />
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Room Type"
                                                                            value={values?.roomType}
                                                                            disabled
                                                                            name="roomType"
                                                                            onBlur={handleBlur}

                                                                        />
                                                                    </div>
                                                                    <div className='row mt-4'>

                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Check-Out Date"
                                                                            value={values?.checkOut}
                                                                            disabled
                                                                            name="checkOut"
                                                                            onBlur={handleBlur}

                                                                        />
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Check-Out Time"
                                                                            value={values?.checkOutTime}
                                                                            disabled
                                                                            name="checkOut"
                                                                            onBlur={handleBlur}

                                                                        />
                                                                        <InputFromUser
                                                                            type='number'
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Total Amount"
                                                                            value={values?.amount}
                                                                            name="amount"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            disabled
                                                                            error={errors.amount}
                                                                            touched={touched.amount}
                                                                        />

                                                                        <InputFromUser
                                                                            type='number'
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Pending Amount"
                                                                            value={pendingAmount}
                                                                            name="amount"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            disabled
                                                                            error={errors.amount}
                                                                            touched={touched.amount}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-4 d-flex justify-content-center">
                                                                    <Card title='Calculations' style={{ width: '400px' }} >

                                                                        {ListItems('No.of Guests', roomSlot?.[0]?.orderId?.additionalInfo?.guestDetails?.length?.toString())}
                                                                        {ListItems('Total Amount', roomSlot?.[0]?.orderId?.amount)}
                                                                        {ListItems('Payment Status', roomSlot?.[0]?.orderId?.paymentStatus)}
                                                                        {ListItems('Order Status', roomSlot?.[0]?.orderId?.orderStatus)}
                                                                        {ListItems('Completed Payment', roomSlot?.[0]?.orderId?.additionalInfo?.completedPayment)}
                                                                        {ListItems('Order Type ', roomSlot?.[0]?.orderId?.orderType)}
                                                                        {ListItems('Payment Mode', roomSlot?.[0]?.orderId?.paymentMode)}
                                                                        {ListItems('Booking For', roomSlot?.[0]?.orderId?.bookingFor)}
                                                                        {/* <div className="card-body" >
                                                                            <p  ><span>{roomSlot?.[0]?.orderId?.additionalInfo?.guestDetails.length}</span></p>
                                                                            <h5 >:<span>{roomSlot?.[0]?.orderId?.amount}</span></h5>
                                                                        </div> */}
                                                                    </Card>

                                                                </div>
                                                                <div className="col-12 mt-4">
                                                                    <div className="doctor-submit text-end">
                                                                        <button
                                                                            type="submit"
                                                                            onClick={() => {
                                                                                if (IsPendingAmount) {
                                                                                    const CollectCashPayload = {
                                                                                        amount: roomSlot?.[0]?.orderId?.amount,
                                                                                        orderId: roomSlot?.[0]?.orderId?._id,
                                                                                        updatedBy: userDetails?.[0]?._id,
                                                                                        updateByName: userDetails?.[0]?.name,
                                                                                        updatedPaymentMode: '',
                                                                                        transactionId: '',
                                                                                        updatedPaidAmount: '',
                                                                                        completedPayment: "100%",
                                                                                        paymentStatus: 'Completed',
                                                                                    }
                                                                                    setCollectModal(CollectCashPayload)
                                                                                } else {
                                                                                    handleSubmit();
                                                                                }
                                                                            }}
                                                                            className="btn btn-primary submit-form me-2"
                                                                            disabled={isSubmitting}
                                                                        >
                                                                            {IsPendingAmount ? "Pay Full Amount" : "Submit"}
                                                                        </button>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        )

                                                    }}

                                                </Formik>
                                                <Modal
                                                    open={IsCollectModal?.orderId}
                                                    closable={true}
                                                    forceRender={true}
                                                    zIndex={1050}
                                                    title={IsCollectModal?.additionalInfo?.customerName}
                                                    style={{ margin: 15, padding: 15 }}
                                                    onOk={() => {
                                                        formik.handleSubmit()
                                                    }}
                                                    styles={{
                                                        body: {
                                                            overflow: "hidden",
                                                        },
                                                        mask: {
                                                            backdropFilter: 'blur(10px)',
                                                        },
                                                        content: {
                                                            borderRadius: 40,
                                                        },
                                                    }}
                                                    okText={'Submit'}
                                                    centered
                                                    onCancel={() => {
                                                        setCollectModal(null)
                                                    }}
                                                    closeIcon={true}
                                                >
                                                    <div>
                                                        <InputFromUser
                                                            FormGroupClassName={"col-xl-12"}
                                                            LabelClassName={"col-form-label"}
                                                            type='singleSelect'
                                                            FormControlClassName={'form-control '}
                                                            label="Payment Mode"
                                                            options={['Cash', 'Card', 'Cheque', 'UPI']}
                                                            value={formik.values?.paymentMode}
                                                            name="paymentMode"
                                                            error={formik?.errors?.paymentMode}
                                                            touched={formik.touched.paymentMode}
                                                            onBlur={() =>
                                                                formik.setFieldTouched('paymentMode', true)
                                                            }
                                                            onChange={(value) => {
                                                                formik.setFieldValue('paymentMode', value);
                                                            }} />
                                                        <InputFromUser
                                                            FormGroupClassName={"col-xl-12"}
                                                            LabelClassName={"col-form-label"}
                                                            placeholder={"Enter Amount"}
                                                            disabled
                                                            FormControlClassName={'form-control '}
                                                            label={`Amount (Pending - ${PendingAmt})`}
                                                            type={'number'}
                                                            name="amount"
                                                            error={formik?.errors?.updatedPaidAmount}
                                                            value={formik.values?.updatedPaidAmount}
                                                            touched={formik.touched.updatedPaidAmount}
                                                            onBlur={() =>
                                                                formik.setFieldTouched('updatedPaidAmount', true)
                                                            }
                                                            onChange={(e) => {
                                                                formik.setFieldValue('updatedPaidAmount', e?.target?.value);
                                                            }}
                                                        />

                                                        <InputFromUser
                                                            FormGroupClassName={"col-xl-12"}
                                                            LabelClassName={"col-form-label"}
                                                            placeholder={"Enter Transaction ID"}
                                                            FormControlClassName={'form-control '}
                                                            label="Transaction ID"
                                                            type={'text'}
                                                            name="transactionId"
                                                            error={formik?.errors?.transactionId}
                                                            value={formik.values?.transactionId}
                                                            touched={formik.touched.transactionId}
                                                            onBlur={() =>
                                                                formik.setFieldTouched('transactionId', true)
                                                            }
                                                            onChange={(e) => {
                                                                formik.setFieldValue('transactionId', e?.target?.value);
                                                            }}
                                                        />
                                                    </div>
                                                </Modal>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        </>
    )
}

export default UpdateCheckOut