/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { Button, DatePicker, Modal, Select } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import queryString from 'query-string';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ResponsiveComponent, generateQueryString } from "../../utils/Funcations";
// import Sidebar from "../../../components/Sidebar";
import { CustomTable } from "../../components/Table";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { plusicon, refreshicon } from "../../components/imagepath";
import TableColumnSearch from "../../components/Input/TableColumnSearch";
import { ActionUpdateServiceSlot, GetAllServiceSlot } from "../../store/Actions/ServiceSlotAction";
import { matchPermissions } from "../../utils/Permission";
import { InputFromUser } from "../../components/Input/input";
import AssignDoctorModal from "../../components/Modal/AssignDoctorModal";
import dayjs from 'dayjs';

const { confirm } = Modal;

const ServiceSlot = () => {
    const { loader, ServiceSlot } = useSelector(state => state?.ServiceSlotData);
    const { userDetails } = useSelector(state => state?.auth);
    const [modal, SetModal] = useState({ is: false, id: "" });

    const [showArrow, setShowArrow] = useState(true);
    const [Refresh, setRefresh] = useState(false);
    const [arrowAtCenter, setArrowAtCenter] = useState(false);
    const [IsSearching, setIsSearching] = useState(false);

    const pageSize = 10;
    const dispatch = useDispatch();
    const location = useLocation();
    const params = queryString.parse(location.search);
    const Size = ResponsiveComponent()
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [currentView, setCurrentView] = useState(localStorage.getItem('currentView'));
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortOn, setSortOn] = useState('date');
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const page = searchParams.get("page");
    const customerName = searchParams.get("customerName");
    const phoneNo = searchParams.get("phoneNo");
    const registrationNo = searchParams.get("registrationNo");
    const serviceName = searchParams.get("serviceName");
    const date = searchParams.get("date");
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const statusParams = searchParams.get("status");

    const { Option } = Select;

    const handleSearch = (selectedKeys, dataIndex) => {
        setSearchText(selectedKeys?.[0]);
        setSearchedColumn(dataIndex);

        const queryString1 = generateQueryString(
            // ['page', page],
            ['customerName', dataIndex === 'customerName' ? selectedKeys?.[0] : customerName],
            ['serviceName', dataIndex === 'serviceName' ? selectedKeys?.[0] : serviceName],
            ['phoneNo', dataIndex === 'phoneNo' ? selectedKeys?.[0] : phoneNo],
            ['registrationNo', dataIndex === 'registrationNo' ? selectedKeys?.[0] : registrationNo],
        );
        window.location.replace(`${queryString1}`)
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        window.location.replace(`?page=1`)
    }

    const handleViewChange = (view) => {
        setCurrentView(view);
        localStorage.setItem('currentView', view)

        switch (view) {
            case 'Upcoming':
                setSortOrder('asc');
                setSortOn('date');
                break;
            case 'Completed':
                setSortOrder('desc');
                setSortOn('date');
                break;
            case 'Cancelled':
            case 'InProgress':
                setSortOrder('asc');
                setSortOn('date');
                break;
            default:
                setSortOrder('asc');
                setSortOn('date');
                break;
        }
    };
    const [Query, setQuery] = useState({
        'page': page,
        'customerName': customerName,
        'serviceName': serviceName,
        'phoneNo': phoneNo,
        'registrationNo': registrationNo,
        'status': statusParams || "Upcoming",
        'date': params.date || null,
    },);
    const query = generateQueryString(
        ['page', (Query.page || 1)],
        ['customerName', (Query.customerName)],
        ['serviceName', (Query.serviceName)],
        ['phoneNo', (Query.phoneNo)],
        ['registrationNo', (Query.registrationNo)],
        ['sort', sortOrder],
        ['sortOn', sortOn],
        ['date', (Query.date)],
        ['status', currentView],

    );

    const handleDateChange = (date, dateString) => {
        const newQuery = { ...Query, 'date': dateString };
        setQuery(newQuery);
        setSearchText('');
        setSearchedColumn('');
        window.history.replaceState(null, '', `?${generateQueryString(...Object.entries(newQuery))}`);
    };
    const handleStatusUpdate = (recordId, newStatus) => {
        dispatch(ActionUpdateServiceSlot({
            Query: recordId, obj: {
                "status": newStatus,
            }

        }, () => setRefresh(true)));
    };

    useEffect(() => {

        dispatch(GetAllServiceSlot({ Query: query }));

        setRefresh(false);
    }, [Refresh, searchText, query, selectedDate]);
    const clearDateFilters = () => {
        setSelectedDate(dayjs()); // Reset selectedDate to current date
        const newQuery = { ...Query, 'date': null }; // Set date parameter to null in query
        setQuery(newQuery); // Update query state
        window.history.replaceState(null, '', `?${generateQueryString(...Object.entries(newQuery))}`);
        dispatch(GetAllServiceSlot());

    };

    // const clearDateFilters = () => {
    //     setSelectedDate(moment());
    //     dispatch(GetAllServiceSlot());
    // }

    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('service-slot.update');
    const column = [
        {
            title: "Sr",
            dataIndex: "sr",
            // key: "Sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Customer Name",
            dataIndex: "customerName",
            render: (text, record) => (
                <Link to={`/order-history-overview/${record?.orderId}`}>
                    {record?.customerName}
                </Link>
            ),
            ...TableColumnSearch({ dataIndex: 'customerName', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },
        {
            title: "Phone No.",
            dataIndex: "phoneNo",
        },
        {
            title: "Medi-Day care Name",
            dataIndex: "serviceName",
            render: (text, record) => (
                <div>
                    {record?.serviceData?.map((service, index) => (
                        <div key={index}>
                            <span>{index + 1}. {service?.name}</span>
                        </div>
                    ))}
                </div>
            ),
            // ...TableColumnSearch({ dataIndex: 'serviceName', setSearchText, setSearchedColumn, handleReset, handleSearch }),

        },
        {
            title: "Date",
            dataIndex: "date",
            render: (text, record) => (
                <span>{record?.date || "---"}</span>
            ),
        },
        {
            title: "Arrival time",
            dataIndex: "startTime",
            render: (text, record) => (
                <span>{record?.startTime || "---"}</span>
            ),
        }, {
            title: "Primary Customer",
            dataIndex: "parentCustomerName",
            render: (text, record) => (

                <span>{record?.parentCustomerName || "---"}</span>
            ),
        },
        {
            title: "Assigned Doctor",
            dataIndex: "doctorName",
            render: (text, record) => (
                <span>{record?.doctorName}</span>
            ),
        },
        {
            title: 'Update Service Status',
            dataIndex: 'status',
            render: (text, record) => (
                <>
                    {canUpdate &&
                        <div style={{ marginTop: "-5" }}>

                            <InputFromUser
                                placeholder={""}
                                type="singleSelect"
                                name="status"
                                mode={''}
                                onChange={(newStatus) => handleStatusUpdate(record.id, newStatus)}
                                options={["Upcoming", "Completed"]}
                                FormGroupClassName={"col-xl-7 col-md-12 cursor-pointer"}
                                LabelClassName={"col-form-label cursor-pointer"}
                                value={record.status}
                                disabled={record?.status === "Completed"}
                                label=""
                            />
                        </div>
                    }
                </>
            ),
        },
        {
            title: "Actions",
            dataIndex: "",
            render: (record) => {
                return <Button type="primary" onClick={() => SetModal({ is: true, id: record?._id })}>Assign Doctor</Button>
            },
        },

    ];

    const handleSearch1 = (searchTerm) => {
        var newp = params
        newp.page = searchTerm
        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
    }
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/service-slot?page=1">Medi-Daycare Rituals Booking</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Medi-Daycare Rituals Booking List</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center justify-content-between">
                                                    <div className="col-12 col-md-8">
                                                        <div className="doctor-table-blk">
                                                            <h3>Medi-Daycare Rituals Booking List</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">
                                                                    {matchPermissionsForUser('service-slot.create') &&
                                                                        <Link
                                                                            to="/service-slot/add-service-slot"
                                                                            className="btn btn-primary add-pluss ms-2"
                                                                        >
                                                                            <img src={plusicon} alt="#" />
                                                                        </Link>
                                                                    }

                                                                    <Link
                                                                        to="/service-slot?page=1"
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 col-xl-6 mt-3 mb-md-0" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <DatePicker
                                                                onChange={handleDateChange}
                                                                // defaultValue={moment(selectedDate)}

                                                                className="w-100"
                                                                allowClear={false}
                                                            />
                                                            <Button type="link" onClick={clearDateFilters} style={{ marginLeft: '10px' }}>Clear Filters</Button>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-12 col-md-3">
                                                        <Button type={currentView === 'Upcoming' ? 'primary' : 'default'} onClick={() => handleViewChange('Upcoming')}>
                                                            Upcoming
                                                        </Button>
                                                        <Button className='ms-3' type={currentView === 'Completed' ? 'primary' : 'default'} onClick={() => handleViewChange('Completed')}>
                                                            Completed
                                                        </Button>
                                                    </div> */}

                                                    <div className={`dropdown-color col-12 col-md-4 ${currentView === 'Completed' ? 'Completed' : currentView === 'Cancelled' ? 'Cancelled' : 'Upcoming'}`}>

                                                        {/* <span className='text-capitalize' style={{ fontWeight: "600" }}>Service Booking Status</span> */}
                                                        <div style={{ marginTop: "-5", marginBottom: "-5" }}>
                                                            <InputFromUser
                                                                placeholder={""}
                                                                type="singleSelect"
                                                                name="status"
                                                                mode={''}
                                                                onChange={handleViewChange}
                                                                options={["Upcoming", "Completed", "Cancelled"]}
                                                                FormGroupClassName={"col-xl-6 col-md-12 cursor-pointer m-0 p-0"}
                                                                LabelClassName={"col-form-label cursor-pointer"}
                                                                value={currentView}
                                                                label="Booking Status"
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <CustomTable
                                                CurrentPageNum={parseInt(params?.page) || 1}
                                                OnChange={(v) => { handleSearch1(v) }}
                                                TotalPageList={ServiceSlot?.[0]?.totalDocs}
                                                column={column}
                                                dataSource={ServiceSlot?.[0]?.docs}
                                                dataSourceLength={ServiceSlot?.[0]?.docs?.length}
                                                pageSize={10}
                                                loader={loader}
                                                ShowHeader={true}
                                                Header={<div className="staff-search-table"></div>}
                                                key={"hi"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <AssignDoctorModal
                IsOpen={modal}
                slotType={'service'}
                initialValues={{
                    doctorId: ServiceSlot?.[0]?.docs?.doctorId || '',
                    doctorName: ServiceSlot?.[0]?.docs?.doctorName || '',
                }}
                Isclose={() => SetModal({ is: false, id: "" })} />
        </>
    );
};

export default ServiceSlot;