import React, { useState } from 'react';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { refreshicon } from '../../components/imagepath';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ActionGetCCPaperVerificationDetail } from '../../store/Actions/PatientAction';
import queryString from 'query-string';
import { CustomTable } from '../../components/Table';
import VerifyCaseModal from '../../components/Modal/VerifyCaseModal';

const VerifiedCase = () => {
    const pageSize = 10;
    const dispatch = useDispatch();
    const params = queryString.parse(location.search);
    const [Refresh, setRefresh] = useState(false);
    const [modal, SetModal] = useState({ is: false, id: "" });
    const { CcPaperVerificationList, loader } = useSelector(state => state?.patient);
    useEffect(() => {
        dispatch(ActionGetCCPaperVerificationDetail({ Query: `?page=${params?.page || 1}` }));
        setRefresh(false);
    }, [dispatch, Refresh])

    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Patient Name",
            dataIndex: "name",
            render: (text, record) => {

                return <span>{record.name}</span>;
            },
        },
        {
            title: "Phone No",
            dataIndex: "phoneNo",
            render: (text, record) => {
                return <span>{record.phoneNo}</span>;
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            render: (text, record) => {
                return <span>{record.email}</span>;
            },
        },
        {
            title: "Doctor Name",
            dataIndex: "doctorName",
            render: (text, record) => {
                return <span>{record?.appointmentSchema?.doctorName}</span>;
            },
        },
        {
            title: "Appointment Date",
            dataIndex: "date",
            render: (text, record) => {
                return <span>{record?.appointmentSchema?.date}</span>;
            },
        },
        {
            title: "Old Case No",
            dataIndex: "oldCasePaperNo",
            render: (text, record) => {
                return <span>{record.oldCasePaperNo}</span>;
            },
        },
        {
            title: "New Case No",
            dataIndex: "registrationNo",
            render: (text, record) => {
                return <span>{record.registrationNo}</span>;
            },
        },
        {
            title: "Actions",
            dataIndex: "",
            render: (record) => {
                return <Button type="primary" onClick={() => SetModal({ is: true, id: record._id })}  >Verified</Button>
            },
        },
    ]


    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="expenses.html">Verified Page</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Verified Case List</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Verified Case List</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">

                                                                    <Link
                                                                        onClick={() => window.location.reload()}
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <CustomTable
                                                    CurrentPageNum={CcPaperVerificationList?.page}
                                                    OnChange={(v) => {
                                                        var newp = params
                                                        newp.page = v
                                                        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
                                                    }}
                                                    TotalPageList={CcPaperVerificationList?.totalDocs}
                                                    column={columns}
                                                    dataSource={CcPaperVerificationList?.docs}

                                                    dataSourceLength={CcPaperVerificationList?.docs?.length}
                                                    pageSize={10}
                                                    loader={loader}
                                                    ShowHeader={true}
                                                    Header={<div className="staff-search-table">

                                                    </div>}
                                                    key={"hi"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <VerifyCaseModal IsOpen={modal} Isclose={() => SetModal({ is: false, id: "" })} />
        </>
    )
}

export default VerifiedCase