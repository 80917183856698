import { Modal } from 'antd';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputFromUser } from '../Input/input';
import { getAllMemberShip, getAllMemberShipVerifyList } from '../../store/Actions/MasterDataAction';
import { ExpendableTable } from '../Table/expendableTable';
import { GetAllProgrammeList } from '../../store/Actions/ServiceAction';
import { AddMemberShipValidationSchema } from '../../utils/FormValidation';
import PropTypes from 'prop-types';
import { ActionUpdateById } from '../../store/Actions/CommonAction';
import dayjs from 'dayjs';

// eslint-disable-next-line react/prop-types
const MemberShipModel = ({ IsOpen = {}, Isclose, record = {} }) => {
    const dispatch = useDispatch();
    const { memberShipData } = useSelector(state => state?.MasterData);
    const { programmeData } = useSelector(state => state?.Service);
    useEffect(() => {
        dispatch(getAllMemberShip())
        dispatch(GetAllProgrammeList())
    }, []);
    const membershipTypes = memberShipData?.[0]?.map(item => ({ memberShipType: item?.memberShipType, memberShipId: item?._id })) || [];
    const programmeInfo = programmeData?.[0]?.docs;
    const programmeName = programmeInfo?.map((option) => option?.name?.en);
    const memberShipInfo = record?.memberShipDetails;
    return (
        <>
            <div>
                <div className="page-wrapper">
                    <div className="content">
                        <div className="row">
                            <div className="col-sm-12">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        memberShipType: memberShipInfo?.[0].memberShipType || "",
                                        memberShipId: memberShipInfo?.[0]?.memberShipId || "",
                                        totalMemberShipDays: record?.totalMemberShipDays || "",
                                        memberShipDays: memberShipInfo?.[0].memberShipDays || "",
                                        startDate: memberShipInfo?.[0]?.startDate && dayjs(memberShipInfo?.[0]?.startDate) || null,
                                        endDate: memberShipInfo?.[0]?.endDate && dayjs(memberShipInfo?.[0]?.endDate) || null,
                                        programme: memberShipInfo?.[0]?.programme || "",
                                        programmeId: memberShipInfo?.[0]?.programmeId || "",
                                        roomType: memberShipInfo?.[0]?.roomType || "",
                                        type: memberShipInfo?.[0]?.type || "",
                                        price: memberShipInfo?.[0]?.price || "",
                                        financialYear: memberShipInfo?.[0]?.financialYear?.map(item => ({
                                            "year": Object.keys(item)[0], "days": item[Object.keys(item)[0]]

                                        })) || [{ "year": "", "days": "" }]

                                    }}
                                    validationSchema={AddMemberShipValidationSchema}
                                    onSubmit={(values, { setErrors, setSubmitting }) => {
                                        let formattedValues = {
                                            ...values,
                                            isMemberShipVerified: false,
                                            startDate: values?.startDate ? (values?.startDate) : null,
                                            endDate: values?.endDate ? (values?.endDate) : null,
                                            financialYear: values?.financialYear?.map(item => ({
                                                [item?.year]: item?.days
                                            }))
                                        };
                                        if (record?._id) {
                                            formattedValues = {
                                                ...formattedValues,
                                                isMemberShipVerified: true
                                            };
                                        }
                                        let query;
                                        if (record?._id) {
                                            query = `admin/customers/update_membership/${record?._id}`;
                                        } else {
                                            query = `admin/customers/update_membership/${IsOpen.id}`;
                                        }
                                        dispatch(ActionUpdateById({ Query: query, obj: formattedValues }
                                            , (Res) => {
                                                if (Res?.statusCode === 200) {
                                                    if (record?._id) {
                                                        dispatch(getAllMemberShipVerifyList());
                                                        window.location.href = `/verify-member?page=1`
                                                    } else {
                                                        Isclose();
                                                    }
                                                } else if (Res?.statusCode === 400) {
                                                    setErrors(Res?.data?.errors);
                                                }
                                                setSubmitting(false);
                                            }));
                                    }
                                    }

                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        setFieldValue,
                                        setFieldTouched,
                                    }) => {
                                        return (
                                            <div >
                                                <Modal
                                                    open={IsOpen?.is}
                                                    closable={true}

                                                    forceRender={true}
                                                    zIndex={1040}
                                                    onOk={() => handleSubmit()}
                                                    styles={{
                                                        body: {
                                                            overflow: "hidden",
                                                        },
                                                        mask: {
                                                            backdropFilter: 'blur(10px)',
                                                        },
                                                        content: {
                                                            borderRadius: 40,
                                                        },
                                                    }}
                                                    okText={'Submit'}
                                                    centered

                                                    onCancel={Isclose}
                                                    closeIcon={true}
                                                >
                                                    <div className="row" >
                                                        <div className="col-12 px-5">
                                                            <div className='invoice-add-table'>
                                                                <h4>Add MemberShip</h4>
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <InputFromUser
                                                                            label="Membership Type"
                                                                            type="singleSelect"
                                                                            name="memberShipType"
                                                                            onChange={(value) => {
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: 'memberShipType',
                                                                                        value,
                                                                                    },
                                                                                });
                                                                                const selectedMemberShip = memberShipData?.[0]?.find(type => type?.memberShipType === value);
                                                                                const selectedMemberShipId = selectedMemberShip?._id;
                                                                                setFieldValue("memberShipId", selectedMemberShipId, true);
                                                                            }}
                                                                            onBlur={() => {
                                                                                handleBlur({
                                                                                    target: {
                                                                                        name: 'memberShipType',
                                                                                        value: true,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            FormControlClassName={'form-control'}
                                                                            FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                            LabelClassName={"col-form-label"}
                                                                            value={values?.memberShipType}
                                                                            error={errors?.memberShipType}
                                                                            options={membershipTypes?.map(type => type?.memberShipType) || []}
                                                                            touched={touched?.memberShipType}
                                                                            placeholder={'Membership Type'}
                                                                        />
                                                                        <InputFromUser
                                                                            label=" Total Membership Days Remaining"
                                                                            type="number"
                                                                            name="totalMemberShipDays"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            FormControlClassName={'form-control'}
                                                                            FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                            LabelClassName={"col-form-label"}
                                                                            value={values?.totalMemberShipDays}
                                                                            error={errors?.totalMemberShipDays}
                                                                            touched={touched?.totalMemberShipDays}
                                                                            placeholder={'Enter No Of Days'}

                                                                        />
                                                                        <InputFromUser
                                                                            label="  Membership Days(Only for LM)"
                                                                            type="text"
                                                                            name="memberShipDays"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            FormControlClassName={'form-control'}
                                                                            FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                            LabelClassName={"col-form-label"}
                                                                            value={values?.memberShipDays}
                                                                            error={errors?.memberShipDays}
                                                                            touched={touched?.memberShipDays}
                                                                            placeholder={'Enter No Of Days'}

                                                                        />
                                                                    </div>
                                                                    <div className="row">
                                                                        <InputFromUser
                                                                            label="Start"
                                                                            type="date"
                                                                            name="startDate"
                                                                            onChange={(v) => {
                                                                                setFieldValue("startDate", v, true)

                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            FormControlClassName={'form-control'}
                                                                            FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                            LabelClassName={"col-form-label"}
                                                                            value={values?.startDate}
                                                                            error={errors?.startDate}
                                                                            touched={touched?.startDate}
                                                                            placeholder={'Select From Date '}
                                                                        />
                                                                        <InputFromUser
                                                                            label="End"
                                                                            type="date"
                                                                            name="endDate"
                                                                            onChange={(v) => {
                                                                                setFieldValue("endDate", v, true)

                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            FormControlClassName={'form-control'}
                                                                            FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                            LabelClassName={"col-form-label"}
                                                                            value={values?.endDate}
                                                                            error={errors?.endDate}
                                                                            touched={touched?.endDate}
                                                                            placeholder={'Select End Date '}
                                                                        />
                                                                    </div>
                                                                    <div className="row">
                                                                        <InputFromUser
                                                                            label="Programme"
                                                                            type="singleSelect"
                                                                            name="programme"
                                                                            onChange={(value) => {
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: 'programme',
                                                                                        value,
                                                                                    },
                                                                                });
                                                                                const selectedProgramme = programmeInfo?.find(programme => programme?.name?.en === value);
                                                                                const selectedProgrammeId = selectedProgramme?._id;
                                                                                setFieldValue("programmeId", selectedProgrammeId, true);
                                                                            }}
                                                                            onBlur={() => {
                                                                                handleBlur({
                                                                                    target: {
                                                                                        name: 'programme',
                                                                                        value: true,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            FormControlClassName={'form-control'}
                                                                            FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                            LabelClassName={"col-form-label"}
                                                                            value={values?.programme}
                                                                            error={errors?.programme}
                                                                            options={programmeName || []}
                                                                            touched={touched?.programme}
                                                                            placeholder={'Programme'}
                                                                        />
                                                                        <InputFromUser
                                                                            label="Room Type"
                                                                            type="singleSelect"
                                                                            name="type"
                                                                            onChange={(value) => {
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: 'type',
                                                                                        value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            onBlur={() => {
                                                                                handleBlur({
                                                                                    target: {
                                                                                        name: 'type',
                                                                                        value: true,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            FormControlClassName={'form-control'}
                                                                            FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                            LabelClassName={"col-form-label"}
                                                                            value={values?.type}
                                                                            error={errors?.type}
                                                                            options={['Shared', 'Private']}
                                                                            touched={touched?.type}
                                                                            placeholder={'Room Type'}

                                                                        />
                                                                        <InputFromUser
                                                                            label="Room Category"
                                                                            type="singleSelect"
                                                                            name="roomType"
                                                                            onChange={(value) => {
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: 'roomType',
                                                                                        value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            onBlur={() => {
                                                                                handleBlur({
                                                                                    target: {
                                                                                        name: 'roomType',
                                                                                        value: true,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            FormControlClassName={'form-control'}
                                                                            FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                            LabelClassName={"col-form-label"}
                                                                            value={values?.roomType}
                                                                            error={errors?.roomType}
                                                                            options={['General Ward', 'Basic Room', 'Semi Deluxe', 'Deluxe Room', 'Executive Room', 'Royal Room']}
                                                                            touched={touched?.roomType}
                                                                            placeholder={'Room Category'}

                                                                        />
                                                                        <InputFromUser
                                                                            label=" Price"
                                                                            type="text"
                                                                            name="price"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            FormControlClassName={'form-control'}
                                                                            FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                            LabelClassName={"col-form-label"}
                                                                            value={values?.price}
                                                                            // error={errors.noOfDays}
                                                                            // touched={touched.noOfDays}
                                                                            placeholder={'Enter Price'}

                                                                        />
                                                                    </div>
                                                                    {values?.memberShipType === 'LifeTime' || values?.memberShipType === 'Life Time' ? (
                                                                        <ExpendableTable
                                                                            Title={"Financial Year"}
                                                                            emptyDataForCreatingField={{ "year": "", "days": "" }}
                                                                            Data={values?.financialYear || []}
                                                                            placeholder={"Enter year (2023-24)"}
                                                                            Columns={[
                                                                                { name: 'year', type: 'text', label: 'Year(yyyy-yyyy)' },
                                                                                { name: 'days', type: 'number', label: 'Days' },
                                                                                { name: 'Action', type: 'action', label: 'Action' },
                                                                            ]}
                                                                            onChange={(v) => {
                                                                                // Format the financialYear array
                                                                                const formattedFinancialYear = v.map(item => ({
                                                                                    year: String(item?.year),
                                                                                    days: parseInt(item?.days),
                                                                                }));
                                                                                setFieldValue("financialYear", formattedFinancialYear, true);
                                                                            }}
                                                                            onBlur={(v) => setFieldTouched("financialYear", v, true)}
                                                                            // errors={errors?.wordsOfGratitude}
                                                                            touched={touched?.financialYear}
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal >
                                            </div>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )


}
MemberShipModel.propTypes = {
    IsOpen: PropTypes.object,
    Isclose: PropTypes.func,
    record: PropTypes.shape({
        memberShipType: PropTypes.string,
        memberShipId: PropTypes.string,
        totalMemberShipDays: PropTypes.number,
        memberShipDays: PropTypes.number,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        programme: PropTypes.string,
        programmeId: PropTypes.string,
        type: PropTypes.string,
        roomType: PropTypes.string,
        price: PropTypes.string,
        financialYear: PropTypes.arrayOf(
            PropTypes.shape({
                year: PropTypes.string,
                days: PropTypes.number
            })
        )
    })
};
export default MemberShipModel