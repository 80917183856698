/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */

import { Link } from "react-router-dom";
import { ResponsiveComponent } from "../utils/Funcations";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { CustomTable } from "./Table";
import { plusicon, refreshicon } from './imagepath';
import { Breadcrumb } from "./Card/Cards";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { Button } from "antd";

export const TableListPageCom = ({
    Tableloader = false,
    refreshOnClick = () => { },
    TableData = [],
    TableColumn = [],
    PageMainTitle = '',
    CurrentPage = 1,
    pageSize = 10,
    BreadcrumbList = [],
    TotalPageList = [],
    dataSourceLength = [],
    ShowAddButton = true,
    bordered = false,
    RightChild,
    LeftChild,
    CreateButtonLink = './',
    handlePageChange = () => { }
}) => {
    const { userDetails } = useSelector(state => state?.auth);
    const Size = ResponsiveComponent()
    return (
        <div>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <Breadcrumb page={BreadcrumbList} />
                            {/* /Page Header */}
                            <div className="row ">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>{PageMainTitle}</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">
                                                                    {ShowAddButton &&
                                                                        <Link
                                                                            to={CreateButtonLink}
                                                                            className="btn btn-primary add-pluss ms-2"
                                                                        >
                                                                            <img src={plusicon} alt="#" />
                                                                        </Link>}
                                                                    <Button
                                                                        onClick={refreshOnClick}
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            {/* </div> */}
                                                        </div>
                                                        {LeftChild}
                                                    </div>
                                                    {RightChild}
                                                </div>
                                            </div>
                                            <div className="w-100 position-static">
                                                <CustomTable
                                                    CurrentPageNum={CurrentPage}
                                                    OnChange={(v) => {
                                                        handlePageChange(v)
                                                    }}
                                                    TotalPageList={TotalPageList}
                                                    column={TableColumn}
                                                    dataSource={TableData}
                                                    dataSourceLength={dataSourceLength}
                                                    pageSize={pageSize}
                                                    bordered={bordered}
                                                    loader={Tableloader}
                                                    ShowHeader={true}
                                                    Header={""}
                                                    key={"hi"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>)
}
TableListPageCom.propTypes = {
    CurrentPage: PropTypes.number,
    Tableloader: PropTypes.bool,
    ShowAddButton: PropTypes.bool,
    bordered: PropTypes.bool,
    TableColumn: PropTypes.array,
    TableData: PropTypes.array,
    BreadcrumbList: PropTypes.array,
    handlePageChange: PropTypes.func,
    TotalPageList: PropTypes.number,
    pageSize: PropTypes.number,
    dataSourceLength: PropTypes.number,
    CreateButtonLink: PropTypes.string,
    PageMainTitle: PropTypes.string,
    Iscreate: PropTypes.string,
    refreshOnClick: PropTypes.func,
    RightChild: PropTypes.node,
    LeftChild: PropTypes.node,
};
