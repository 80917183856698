import { Button, DatePicker } from "antd";
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import moment from "moment";
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { CustomTable } from '../../components/Table';
import { refreshicon } from '../../components/imagepath';
import { ActionGetAllMedicalHistory } from '../../store/Actions/MedicalHistoryAction';
import { generateQueryString } from "../../utils/Funcations";

const MedicalHistory = () => {
    const { loader, medicalHistory } = useSelector(state => state?.MedicalHistory);
    // const [showArrow, setShowArrow] = useState(true);
    const [Refresh, setRefresh] = useState(false);
    const pageSize = 10;
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const page = searchParams.get("page");
    const dispatch = useDispatch();
    const location = useLocation();
    const params = queryString.parse(location.search);
    const [sortOrder] = useState('asc');
    const [sortOn] = useState('createdAt');
    const [dateRange, setDateRange] = useState([]);

    // const Size = ResponsiveComponent()

    const column = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Patient",
            dataIndex: "name",
            render: (text, record) => (
                <span>{record?.customerId?.name}</span>
            ),
        }, {
            title: "Phone No",
            dataIndex: "phoneNo",
            render: (text, record) => (
                <span>{record?.customerId?.phoneNo}</span>
            ),
        },
        {
            title: "Consultation Type",
            dataIndex: "consultationType",
            render: (text, record) => (
                <span>{record?.appointmentId?.consultationType}</span>
            ),

        },
        {
            title: "OldCasePaperNo.",
            dataIndex: "oldCasePaperNo",
            render: (text, record) => (
                <span>{record?.customerId?.oldCasePaperNo}</span>
            ),
        },
        {
            title: "NewCasePaperNo.",
            dataIndex: "registrationNo",
            render: (text, record) => (
                <span>{record?.customerId?.registrationNo}</span>
            ),
        },
        {
            title: "Purchased Medicines",
            dataIndex: "purchasedMedicines",
            render: (text, record) => (
                <div>
                    {record?.purchasedMedicines?.map((medicine, index) => (
                        <div key={index}>
                            <span>{medicine?.medicineName}</span>

                        </div>
                    ))}
                </div>
            ),
        },
        {
            title: "Days",
            dataIndex: "days",
            render: (text, record) => (
                <div>
                    {record?.purchasedMedicines?.map((medicine, index) => (
                        <div key={index}>
                            <span>{medicine?.days}</span>
                        </div>
                    ))}
                </div>
            ),
        },
        {
            title: "When to Take",
            dataIndex: "whenToTake",
            render: (text, record) => (
                <div>
                    {record?.purchasedMedicines?.map((medicine, index) => (
                        <div key={index}>
                            <span>{medicine?.whenToTake.join("-")}</span>
                        </div>
                    ))}
                </div>
            ),
        },
        {
            title: "Consultation date",
            dataIndex: "dateOfMedicalStore",
            render: (text, record) => (
                <span>{moment(record?.dateOfMedicalStore).format("YYYY-MM-DD")}</span>
            ),
        },
        // {
        //     title: "Action",
        //     dataIndex: "id",
        //     key: "action",
        //     render: (text, record, num) => {
        //         return (
        //             <Popover placement="left" title={record.title} content={() => content(record)} arrow={mergedArrow}>
        //                 <Button type="dashed">• • •</Button>
        //             </Popover>
        //         )
        //     },
        // },
    ];

    useEffect(() => {
        const newQuery = generateQueryString(
            ['page', page || 1],
            ['sort', sortOrder],
            ['sortOn', sortOn],
            ['consultationStartDate', params.consultationStartDate || ''],
            ['consultationEndDate', params.consultationEndDate || ''],
        );
        dispatch(ActionGetAllMedicalHistory({ Query: newQuery, }));
        setRefresh(false);
    }, [Refresh, dateRange, page, sortOrder, sortOn])

    const handleDateRangeChange = (dates) => {
        setDateRange(dates);
        const newQueryParams = {
            ...params,
            consultationStartDate: dates[0]?.format('YYYY-MM-DD'),
            consultationEndDate: dates[1]?.format('YYYY-MM-DD'),
        };
        const queryString1 = queryString.stringify(newQueryParams);
        window.location.search = `?${queryString1}`;
    };
    const clearDateFilters = () => {
        setDateRange([]);
        window.location.href = window.location.pathname + `?page=${params?.page || 1}`;
        dispatch(ActionGetAllMedicalHistory({ Query: `?page=${params?.page || 1}` }));
    }
    const handleSearch1 = (searchTerm) => {
        var newp = params
        newp.page = searchTerm
        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
    }

    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/medical-history">Medical History</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Medical History List</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Medical History List</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">
                                                                    <Link
                                                                        onClick={() => window.location.reload()}
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 col-xl-6 mt-3 mb-md-0" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <DatePicker.RangePicker
                                                                onChange={handleDateRangeChange}
                                                                className="w-100"
                                                                allowClear={false}
                                                            />
                                                            <Button type="link" onClick={clearDateFilters} style={{ marginLeft: '10px' }}>Clear Filters</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* /Table Header */}
                                            <CustomTable
                                                CurrentPageNum={parseInt(params?.page) || 1}
                                                OnChange={(v) => {
                                                    handleSearch1(v)
                                                }}
                                                TotalPageList={medicalHistory?.totalDocs}
                                                column={column}
                                                dataSource={medicalHistory?.docs}
                                                dataSourceLength={medicalHistory?.docs?.length}
                                                pageSize={10}
                                                loader={loader}
                                                ShowHeader={true}
                                                Header={<div className="staff-search-table">
                                                </div>}
                                                key={"hi"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default MedicalHistory