import { GET_ALL_MEDICAL_DETAILS, GET_ALL_MEDICAL_DETAILS_SUCCESS, GET_MEDICAL_DETAILS_BY_ID, GET_MEDICAL_DETAILS_BY_ID_SUCCESS, UPDATE_MEDICAL_DETAILS } from "../storeTypes"

export const ActionGetAllMedicalDetail = (payload) => {
    return {
        type: GET_ALL_MEDICAL_DETAILS,
        payload
    }
}

export const ActionGetAllMedicalDetailSuccess = (payload) => {
    return {
        type: GET_ALL_MEDICAL_DETAILS_SUCCESS,
        payload
    }
}

export const ActionUpdateMedicalDetail = (payload, callBack) => {
    return {
        type: UPDATE_MEDICAL_DETAILS,
        payload,
        callBack
    };
};

export const ActionGetMedicalDetailByID = (payload, callBack) => {
    return {
        type: GET_MEDICAL_DETAILS_BY_ID,
        payload,
        callBack
    };
};

export const ActionGetMedicalDetailByIDSuccess = (payload) => {
    return {
        type: GET_MEDICAL_DETAILS_BY_ID_SUCCESS,
        payload
    };
};