import { CREATE_CELEBRITY, CREATE_CELEBRITY_SUCCESS, GET_ALL_CELEBRITY, GET_ALL_CELEBRITY_SUCCESS } from "../storeTypes";

const INIT_STATE = {
    loader: false,
    Celebritylist: {},

};

function celebrityReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case CREATE_CELEBRITY: {
            return {
                ...state,
                loader: true,
            };
        }
        case CREATE_CELEBRITY_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }
        case GET_ALL_CELEBRITY: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_CELEBRITY_SUCCESS: {
            return {
                ...state,
                loader: false,
                Celebritylist: action?.payload
            };
        }

        default: {
            return state;
        }
    }

}

export default celebrityReducer;