import {
    CREATE_DETOX_RETREAT,
    GET_DETOX_RETREAT_BOOKING_SUCCESS,
    CREATE_DETOX_RETREAT_SUCCESS,
    CREATE_SERVICE,
    CREATE_SERVICE_SUCCESS,
    CREATE_SOLUTION,
    CREATE_SOLUTION_SUCCESS,
    CREATE_TREATMENT,
    CREATE_TREATMENT_SUCCESS,
    GET_ALL_SERVICE,
    GET_ALL_SERVICE_SUCCESS,
    GET_ALL_SOLUTION,
    GET_ALL_SOLUTION_SUCCESS,
    GET_ALL_TREATMENT,
    GET_ALL_TREATMENT_SUCCESS,
    GET_DETOX_RETREAT_BOOKING,
    GET_ALL_PROGRAMMES,
    GET_ALL_PROGRAMMES_SUCCESS,
    GET_ALL_PROGRAMMES_LIST,
    GET_ALL_PROGRAMMES_LIST_SUCCESS,
    GET_PROGRAMME_BOOKING,
    GET_PROGRAMME_BOOKING_SUCCESS,
    UPDATE_PROGRAMME_SLOT,
} from "../storeTypes";

const INIT_STATE = {
    loader: false,
    Servicelist: {},
    treatMentList: {},
    solutionList: {},
    DetoxRetreat: [],
    programmeList: {},
    programmeData: {},
    programmeBooking: {},
};

function serviceReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case CREATE_SERVICE: {
            return {
                ...state,
                loader: true,
            };
        }
        case CREATE_SERVICE_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }
        case GET_ALL_SERVICE: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_SERVICE_SUCCESS: {
            return {
                ...state,
                loader: false,
                Servicelist: action?.payload
            };
        }
        case CREATE_SOLUTION: {
            return {
                ...state,
                loader: true,
            };
        }
        case CREATE_SOLUTION_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }
        case GET_ALL_SOLUTION: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_SOLUTION_SUCCESS: {
            return {
                ...state,
                loader: false,
                solutionList: action?.payload
            };
        }
        case CREATE_TREATMENT: {
            return {
                ...state,
                loader: true,
            };
        }
        case CREATE_TREATMENT_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }

        case GET_ALL_TREATMENT: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_TREATMENT_SUCCESS: {
            return {
                ...state,
                loader: false,
                treatMentList: action?.payload
            };
        }
        case GET_ALL_PROGRAMMES: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_PROGRAMMES_SUCCESS: {
            return {
                ...state,
                loader: false,
                programmeList: action?.payload
            };
        }
        case GET_ALL_PROGRAMMES_LIST: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_PROGRAMMES_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                programmeData: action?.payload
            };
        }
        case GET_PROGRAMME_BOOKING: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_PROGRAMME_BOOKING_SUCCESS: {
            return {
                ...state,
                loader: false,
                programmeBooking: action?.payload
            }
        }
        case UPDATE_PROGRAMME_SLOT: {
            return {
                ...state,
                loader: true,
            }
        }
        case CREATE_DETOX_RETREAT: {
            return {
                ...state,
                loader: true,
            };
        }
        case CREATE_DETOX_RETREAT_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }

        // //service slot

        case GET_DETOX_RETREAT_BOOKING: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_DETOX_RETREAT_BOOKING_SUCCESS: {
            return {
                ...state,
                loader: false,
                DetoxRetreat: action?.payload
            }
        }

        default: {
            return state;
        }
    }
}

export default serviceReducer;
