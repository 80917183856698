/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect, useMemo } from "react";

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Popover } from "antd";
import queryString from 'query-string';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ResponsiveComponent } from "../../../utils/Funcations";
import TableColumnSearch from '../../../components/Input/TableColumnSearch';
import { ActionDeleteDoAndDonts, ActionGetDoAndDontsList } from "../../../store/Actions/MasterDataAction";
import { matchPermissions } from "../../../utils/Permission";
import { TableListPageCom } from "../../../components/TableListPageCom";
const { confirm } = Modal;

const DoAndDonts = () => {
    const { loader, doAndDonts } = useSelector(state => state?.MasterData);
    const { userDetails } = useSelector(state => state?.auth);
    const [showArrow, setShowArrow] = useState(true);
    const [Refresh, setRefresh] = useState(false);
    const [arrowAtCenter, setArrowAtCenter] = useState(false);
    const [IsSearching, setIsSearching] = useState(false);
    // const [KeyValue, SearchKey] = useState({ key: '', value: '' });
    const pageSize = 10;
    const dispatch = useDispatch();
    const location = useLocation();
    const params = queryString.parse(location.search);
    const Size = ResponsiveComponent()
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [SearchList, setSearchList] = useState([]);
    const mergedArrow = useMemo(() => {
        if (arrowAtCenter)
            return {
                pointAtCenter: true,
            };
        return showArrow;
    }, [showArrow, arrowAtCenter]);
    const handleSearch = (selectedKeys, dataIndex) => {
        setSearchText(selectedKeys?.[0]);
        setSearchedColumn(dataIndex);

        if (params[dataIndex]) {
            window.location.replace(`/do-and-donts/?page=1&filterlist=true&${dataIndex}=${selectedKeys?.[0]}`)
        } else {
            window.location.replace(`/do-and-donts?${location.search}&${dataIndex}=${selectedKeys?.[0]}`)
        }

    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        window.location.replace(`?page=1`)
    }

    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('do-and-donts.update');
    const canDelete = matchPermissionsForUser('do-and-donts.delete');
    const canCerate = matchPermissionsForUser('do-and-donts.create');
    const content = (item) => (
        <div className="">
            {canUpdate &&
                <Button className="m-2" onClick={() => EditList(item?.id)}>Edit</Button>
            }
            {canDelete &&
                <Button onClick={() => showDeleteConfirm(item)}>Delete</Button>
            }
        </div>
    );
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            // key: "Sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Order Index",
            dataIndex: "orderIndex",
            render: (text, record) => (
                <span>{record.orderIndex}</span>
            ),
            ...TableColumnSearch({ dataIndex: 'orderIndex', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },
        {
            title: "Title",
            dataIndex: "title",
            render: (text, record) => (
                <span>{record.title}</span>
            ),
            ...TableColumnSearch({ dataIndex: 'title', setSearchText, setSearchedColumn, handleReset, handleSearch, filtered: params?.title }),
            sorter: (a, b) => a.title.length - b.title.length,

        },
        {
            title: !canUpdate && !canDelete ? null : "Action",
            dataIndex: "id",
            key: "action",
            render: (text, record, num) => {
                if (!canUpdate && !canDelete) {
                    return null
                } else {
                    return (
                        <Popover placement="left" title={record.title} content={() => content(record)} arrow={mergedArrow}>
                            <Button type="dashed">• • •</Button>
                        </Popover>
                    )
                }
            },
        },
    ];
    // const OrderIndex = params?.orderIndex ? `&orderIndex=${params?.orderIndex}` : ''
    // const Title = params?.title ? `&title=${params?.title}` : ''
    // const Final = OrderIndex + Title || ''

    useEffect(() => {
        dispatch(ActionGetDoAndDontsList({ Query: `?page=${params?.page || 1}` }));
        setRefresh(false);
    }, [dispatch, params?.page, Refresh])

    const handleSearch1 = (searchTerm) => {
        var newp = params
        newp.page = searchTerm
        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
    }

    const EditList = (searchTerm) => {

        window.location.href = `/do-and-donts/add-do-and-donts?update=true&&id=${searchTerm}&&page=${parseInt(params.page)}`
    }

    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.id}?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteDoAndDonts("/" + item?.id, (Res) => {
                    if (Res === 200) {
                        setRefresh(true);
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };
    const BreadcrumbList = [{
        name: 'Do and Donts',
        linkTo: `/do-and-donts?page=${params?.page || 1}`
    },
    {
        name: 'Do and Donts List',
    }
    ]
    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={doAndDonts?.docs}
            TableColumn={columns}
            ShowAddButton={true}
            Iscreate="Do and Donts.create"
            CreateButtonLink={`/do-and-donts/add-do-and-donts?update=false&&page=${params?.page || 1}`}
            PageMainTitle="Do and Donts List"
            CurrentPage={doAndDonts?.page || 1}
            Tableloader={loader}
            TotalPageList={doAndDonts?.totalDocs}
            dataSourceLength={doAndDonts?.docs?.length}
            handlePageChange={(v) => {
                var newp = params
                newp.page = v
                window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
            }}
        />
        // <>
        //     <Header />
        //     <Sidebar />
        //     <>
        //         <div>
        //             <div className="page-wrapper">
        //                 <div className="content">
        //                     {/* Page Header */}
        //                     <div className="page-header">
        //                         <div className="row">
        //                             <div className="col-sm-12">
        //                                 <ul className="breadcrumb">
        //                                     <li className="breadcrumb-item">
        //                                         <Link to="expenses.html">Do and Donts</Link>
        //                                     </li>
        //                                     <li className="breadcrumb-item">
        //                                         <i className="feather-chevron-right">
        //                                             <FeatherIcon icon="chevron-right" />
        //                                         </i>
        //                                     </li>
        //                                     <li className="breadcrumb-item active">Do and Donts List</li>
        //                                 </ul>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     {/* /Page Header */}
        //                     <div className="row">
        //                         <div className="col-sm-12">
        //                             <div className="card card-table show-entire">
        //                                 <div className="card-body">
        //                                     {/* Table Header */}
        //                                     <div className="page-table-header mb-2">
        //                                         <div className="row align-items-center">
        //                                             <div className="col">
        //                                                 <div className="doctor-table-blk">
        //                                                     <h3>Do and Donts List</h3>
        //                                                     <div className="doctor-search-blk">
        //                                                         <div className="add-group">
        //                                                             {canCerate &&
        //                                                                 <Link
        //                                                                     to="/do-and-donts/add-do-and-donts"
        //                                                                     className="btn btn-primary add-pluss ms-2"
        //                                                                 >
        //                                                                     <img src={plusicon} alt="#" />
        //                                                                 </Link>
        //                                                             }

        //                                                             <Link
        //                                                                 onClick={() => window.location.reload()}
        //                                                                 className="btn btn-primary doctor-refresh ms-2"
        //                                                             >
        //                                                                 <img src={refreshicon} alt="#" />
        //                                                             </Link>
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     {/* /Table Header */}
        //                                     <CustomTable
        //                                         CurrentPageNum={parseInt(params?.page) || 1}
        //                                         OnChange={(v) => {
        //                                             handleSearch1(v)
        //                                         }}
        //                                         TotalPageList={doAndDonts?.totalDocs}
        //                                         column={column}
        //                                         dataSource={doAndDonts?.docs}
        //                                         dataSourceLength={doAndDonts?.docs?.length}
        //                                         pageSize={10}
        //                                         loader={loader}
        //                                         ShowHeader={true}
        //                                         Header={<div className="staff-search-table">
        //                                         </div>}
        //                                         key={"hi"} />
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </>
        // </>
    );
};

export default DoAndDonts;
