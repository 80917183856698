import { BASE_URL } from './globals.js';
import NetworkClient from './NetworkClient.js';

export async function handleLoginApi(obj) {
    return NetworkClient.post(`${BASE_URL}adminUser/login`, obj);
}
export async function GetAppconfigApi(Query) {
    return NetworkClient.get(`${BASE_URL}admin/app-config${Query || ""}`,);
}
export async function GetPatientPlanApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/patient-plan/get_patient_plan${obj?.Query || ""}`,);
}
export async function GetPatientDischargeApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/patient-plan/get_discharge_form${obj?.Query || ""}`,);
}
export async function CreateAppconfigApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/app-config`, obj);
}
export async function UpdateAppconfigApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/app-config${obj?.Query || ''}`, obj?.obj);
}
export async function DeleteAppconfigApi(obj) {
    return NetworkClient.delete(`${BASE_URL}admin/app-config${obj || ""}`,);
}
export async function CreatePatientApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/customers${obj?.Query || ''}`, obj?.obj);
}
export async function CreateScheduleApi(obj) {
    return NetworkClient.patch(`${BASE_URL}adminUser/${obj?.Query || ''}`, obj?.obj);
}
export async function GetAllScheduleListApi(obj) {
    return NetworkClient.get(`${BASE_URL}adminUser/${obj?.Query || ''}`, obj?.obj);
}
export async function GetPatientApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/customers${obj?.Query || ''}`, obj?.obj);
}
export async function GetCustomerBirthDateDetailApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/customers/get_birthdate${obj?.Query || ''}`, obj?.obj);
}
export async function GetCCPaperDetailApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/customers/get_case_paper${obj?.Query || ''}`, obj?.obj);
}
export async function GetCCPaperVerificationDetailApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/customers/get_case_paper_verification${obj?.Query || ''}`);
}
export async function GetCustomerDashboardApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/customers${obj?.Query || ''}`, obj?.obj);
}
export async function GetCustomerConfirmationApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/confirmation-detail${obj?.Query || ''}`, obj?.obj);
}
export async function GetMedicineListApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/medicine/get_all_medicines${obj?.Query || ''}`, obj?.obj);
}
export async function GetlaboratoryApi() {
    return NetworkClient.get(`${BASE_URL}admin/laboratory/get_all_laboratory`);
}
export async function GetAssignedDoctorApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/treatment-slot/get_assign_doctor_list${obj?.Query || ''}`, obj?.obj);
}
export async function GetAppoinmentApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/appointment${obj?.Query || ''}`, obj?.obj);
}
export async function GetRoleListApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/roles${obj?.Query || ''}`, obj?.obj);
}
export async function CreateStaffApi(obj) {
    return NetworkClient.post(`${BASE_URL}adminUser${obj?.Query || ''}`, obj?.obj);
}
export async function GetStaffListApi(obj) {
    return NetworkClient.get(`${BASE_URL}adminUser${obj?.Query || ''}`,);
}
export async function GetAdminUserDetailsListApi(obj) {
    return NetworkClient.get(`${BASE_URL}adminUser/get_admin_user_detail${obj?.Query || ''}`,);
}
export async function CreateRoleApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/roles${obj?.Query || ''}`, obj?.obj);
}
export async function GetPermissionApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/permissions/get_all_permissions${obj?.Query || ''}`,);
}
export async function CreatePermissionApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/permissions${obj?.Query || ''}`, obj?.obj);
}
export async function GetPaginatedPermissionApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/permissions${obj?.Query || ''}`, obj?.obj);
}
export async function GetStaffProfileApi(obj) {
    return NetworkClient.get(`${BASE_URL}adminUser/${obj?.Query || ''}`, obj?.obj);
}
export async function GetHolidayListApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/holiday${obj?.Query || ''}`, obj?.obj);
}
export async function GetTreatmentListApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/treatment${obj?.Query || ''}`, obj?.obj);
}
export async function GetProgrammeListApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/programme${obj?.Query || ''}`, obj?.obj);
}
export async function GetAllProgrammeApi(obj) {
    return NetworkClient.get(`${BASE_URL}programme/get_all_programme${obj?.Query || ''}`, obj?.obj);
}
export async function GetProgramBookingApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/programme-slot${obj?.Query || ''}`);
}
export async function UpdateProgrammeSlotApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/programme-slot/${obj?.Query || ''}`, obj?.obj);
}
export async function GetSolutionListApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/solution${obj?.Query || ""}`, obj?.obj);
}
export async function CreateHolidayApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/holiday${obj?.Query || ''}`, obj?.obj);
}
export async function UpdateHolidayApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/holiday${obj?.Query || ''}`, obj?.obj);
}
export async function DeleteHolidayApi(obj) {
    return NetworkClient.delete(`${BASE_URL}admin/holiday${obj || ""}`,);
}
export async function GetHolidayByIdApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/holiday${obj?.Query || ''}`, obj?.obj);
}
export async function UpdateStaffProfileApi(obj) {
    return NetworkClient.patch(`${BASE_URL}adminUser/${obj?.Query || ''}`, obj?.obj);
}
export async function DeleteByIdApi(obj) {
    return NetworkClient.delete(`${BASE_URL}${obj?.Query || ''}`, obj?.obj);
}
export async function CreateServiceApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/service`, obj);
}
export async function TherapistScheduleApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/therapistSchedule/byDate`, obj);
}
export async function TherapistShiftApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/therapistShift${obj?.Query || ''}`, obj?.obj);
}
export async function TherapistHistoryApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/therapist-history${obj?.Query || ''}`, obj?.obj);
}
export async function TherapistHistoryByIdApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/therapist-history${obj?.Query || ''}`, obj?.obj);
}
export async function TherapistCsvDataApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/therapist-history/get_csv_report${obj?.Query || ''}`, obj?.obj);
}
export async function getTherapistCountApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/therapist-count${obj?.Query || ''}`, obj?.obj);
}
export async function getLeavesApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/leaves${obj?.Query || ''}`, obj?.obj);
}
export async function getAllGalleryApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/gallery${obj?.Query || ''}`, obj?.obj,);
}
export async function getAllDashboardDataApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/DashboardData${obj?.Query || ''}`, obj?.obj,);
}
export async function CreateTreatmentApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/treatment`, obj);
}

export async function CreateSolutionApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/solution`, obj);
}
export async function CreateByUrlApi(obj) {
    return NetworkClient.post(`${BASE_URL}${obj?.Query || ''}`, obj?.obj);
}
export async function UploadDocApi(obj) {
    return NetworkClient.post(`${BASE_URL}upload-doc${obj?.Query || ''}`, obj?.obj, { 'Content-Type': 'multipart/form-data' });
}
export async function getServiceApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/service${obj?.Query || ''}`, obj?.obj);
}
export async function getAdminuserdetailsApi() {
    return NetworkClient.get(`${BASE_URL}adminUser/get_profile`,);
}
export async function UpdateByIdApi(obj) {
    return NetworkClient.patch(`${BASE_URL}${obj?.Query || ''}`, obj?.obj);
}
export async function getAllConsultationApi(obj) {
    return NetworkClient.get(`${BASE_URL}consultation/get_all_consultation${obj?.Query || ''}`, obj?.obj);
}
export async function getAdminUserApi(obj) {
    return NetworkClient.get(`${BASE_URL}adminUser/get_admin_user/${obj?.Query || ''}`, obj?.obj);
}
export async function CreateLeaveRequestApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/leaves`, obj);
}
export async function getDrAvailabilityApi(obj) {
    return NetworkClient.get(`${BASE_URL}appointment/check_doctor_availability${obj?.Query || ''}`, obj?.obj);
}
export async function UpdateByCustomerIdApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/customers/${obj?.customerId || ""}`, obj?.obj);
}
export async function getDrSlotAvailabilityApi(obj) {
    return NetworkClient.post(`${BASE_URL}appointment/check_available_slots${obj?.Query || ''}`, obj?.obj);
}
export async function GetMedicalDetailApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/medical${obj?.Query || ''}`, obj?.obj);
}

// Do and Donts API

export async function GetDoAndDontsApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/do-and-donts${obj?.Query || ''}`, obj?.obj);
}
export async function CreateDoAndDontsApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/do-and-donts`, obj);
}
export async function UpdateDoAndDontsApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/do-and-donts${obj?.Query || ''}`, obj?.obj);
}
export async function DeleteDoAndDontsApi(obj) {
    return NetworkClient.delete(`${BASE_URL}admin/do-and-donts${obj || ''}`,);
}
export async function ConsultationMasterDataApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/consultation-master-data${obj?.Query || ''}`, obj?.obj);
}
export async function CreateConsultationMedicalDetailsApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/medical/consultation`, obj?.obj);
}
// Exercise API 

export async function GetExerciseApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/exercise${obj?.Query || ''}`, obj?.obj);
}
export async function CreateExerciseApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/exercise`, obj);
}
export async function UpdateExerciseApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/exercise${obj?.Query || ''}`, obj?.obj);
}
export async function DeleteExerciseApi(obj) {
    return NetworkClient.delete(`${BASE_URL}admin/exercise${obj || ''}`,);
}

// Virechan API 

export async function GetVirechanApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/virechan${obj?.Query || ''}`, obj?.obj);
}
export async function CreateVirechanApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/virechan`, obj);
}
export async function UpdateVirechanApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/virechan${obj?.Query || ''}`, obj?.obj);
}
export async function DeleteVirechanApi(obj) {
    return NetworkClient.delete(`${BASE_URL}admin/virechan${obj || ''}`,);
}

// MemberShip API 

export async function GetMemberShipApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/member-ship${obj?.Query || ''}`, obj?.obj);
}
export async function CreateMemberShipApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/member-ship`, obj);
}
export async function UpdateMemberShipApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/member-ship${obj?.Query || ''}`, obj?.obj);
}
export async function DeleteMemberShipApi(obj) {
    return NetworkClient.delete(`${BASE_URL}admin/member-ship${obj || ''}`,);
}
export async function getAllMemberShipApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/member-ship/get_all_memberShips/${obj?.Query || ''}`, obj?.obj);
}
export async function getAllMemberShipVerificationApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/customers/get_membership_verification${obj?.Query || ''}`, obj?.obj);
}
export async function UpdateCustomerMemberShipApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/customers/update_membership/${obj?.Query || ''}`, obj?.obj);
}
// Medicine API 

export async function GetMedicineApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/medicine${obj?.Query || ''}`);
}
export async function CreateMedicineApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/medicine`, obj);
}
export async function UpdateMedicineApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/medicine${obj?.Query || ''}`, obj?.obj);
}
export async function DeleteMedicineApi(obj) {
    return NetworkClient.delete(`${BASE_URL}admin/medicine${obj || ''}`,);
}
export async function GetMedicineByIdApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/medicine${obj?.Query || ''}`, obj?.obj);
}

// Rooms API 

export async function GetRoomApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/rooms${obj?.Query || ''}`);
}
export async function CreateRoomApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/rooms`, obj);
}
export async function UpdateRoomApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/rooms${obj?.Query || ''}`, obj?.obj);
}
export async function DeleteRoomApi(obj) {
    return NetworkClient.delete(`${BASE_URL}admin/rooms${obj || ''}`);
}
export async function GetRoomByIdApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/rooms${obj?.Query || ''}`, obj?.obj);
}
export async function GetRoomCountApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/room-slot/get_room_count${obj?.Query || ''}`);
}
export async function GetRoomDashboardApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/room-slot/get_room_dashboard${obj?.Query || ''}`);
}
export async function GetRoomSlotApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/room-slot${obj?.Query || ''}`, obj?.obj);
}
export async function GetRoomSlotByIdApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/room-slot${obj?.Query || ''}`, obj?.obj);
}
export async function GetRoomByRoomTypeApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/room-slot/get_room_by_roomType${obj?.Query || ''}`);
}
//Celebrities API
export async function GetCelebrityApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/celebrity${obj?.Query || ''}`);
}
export async function CreateCelebrityApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/celebrity`, obj);
}

//Faqs API
export async function GetFaqApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/faqs${obj?.Query || ''}`);
}
export async function CreateFaqApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/faqs`, obj);
}

//Prakruti Standard API
export async function GetPrakrutiStandardApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/prakruti-standard${obj?.Query || ''}`);
}
export async function CreatePrakrutiStandardApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/prakruti-standard`, obj);
}

//Treatment Protocol API
export async function GetTreatmentProtocolApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/treatment-protocol${obj?.Query || ''}`);
}
export async function CreateTreatmentProtocolApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/treatment-protocol`, obj);
}
export async function GetTreatmentServiceDataApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/treatment-protocol/get_treatment_service_data${obj?.Query || ''}`);
}
//Service Slot Api

export async function getAllServiceSlotApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/service-slot${obj?.Query || ''}`, obj?.obj);
}
export async function getServiceSlotByIdApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/service-slot${obj?.Query || ''}`, obj?.obj);
}
export async function UpdateServiceSlotApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/service-slot/${obj?.Query || ''}`, obj?.obj);
}
export async function GetServiceSlotByIdApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/service-slot${obj?.Query || ''}`, obj?.obj);
}

//Price of Consultation API

export async function GetAllPriceApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/consultation${obj?.Query || ""}`);
}
export async function CreatePriceApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/consultation`, obj);
}
export async function UpdatePriceApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/consultation${obj?.Query || ""}`, obj?.obj);
}
export async function DeletePriceApi(obj) {
    return NetworkClient.delete(`${BASE_URL}admin/consultation${obj || ""}`,);
}
export async function GetPriceByIdApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/consultation${obj?.Query || ""}`, obj?.obj);
}

//Medical Store API

export async function GetAllMedicalDetailsApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/medical/get_recomended_medicines${obj?.Query}`);
}

export async function UpdateMedicalDetailApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/medical/update_recommond_medicine${obj?.Query || ''}`, obj?.obj);
}

export async function GetMedicalDetailByIdApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/medical${obj?.Query || ''}`, obj?.obj);
}

export async function GetMedicalHistoryDetailsApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/medical/get_medical_history${obj?.Query}`);
}
//Order Api

export async function GetAllOrderApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/order${obj?.Query}`);
}
export async function CreateOrderApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/order`, obj);
}

export async function GetOrderByIdApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/order${obj?.Query}`, obj?.obj);
}

export async function getAllTreatmentSlotApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/treatment-slot${obj?.Query || ''}`);
}
export async function getTreatmentSlotByIdApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/treatment-slot${obj?.Query || ''}`, obj?.obj);
}
export async function UpdateTreatmentSlotApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/treatment-slot/${obj?.Query || ''}`, obj?.obj);
}

export async function getAllSolutionSlotApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/solution-slot${obj?.Query || ''}`);
}
export async function getSolutionSlotByIdApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/solution-slot${obj?.Query || ''}`, obj?.obj);
}
export async function UpdateSolutionSlotApi(obj) {
    return NetworkClient.patch(`${BASE_URL}admin/solution-slot/${obj?.Query || ''}`, obj?.obj);
}
export async function getAllCaretakerFoodPriceApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/caretacker-foodprice${obj?.Query || ''}`);
}

//Detox-retreat API
export async function GetDetoxRetreatApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/detox-retreat${obj?.Query || ''}`);
}
export async function GetDetoxRetreatBookingApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/detox-retreat-slot${obj?.Query || ''}`);
}

export async function CreateDetoxRetreatApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/detox-retreat`, obj);
}

//Testimonial Link API
export async function GetTestimonialLinkApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/patient-testimonial-Link${obj?.Query || ''}`,);
}
export async function CreateTestimonialLinkApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/patient-testimonial-Link`, obj);
}

//Patient Testimonial API
export async function GetPatientTestimonialApi(obj) {
    return NetworkClient.get(`${BASE_URL}admin/patient-testimonial${obj?.Query || ''}`,);
}
export async function CreatePatientTestimonialApi(obj) {
    return NetworkClient.post(`${BASE_URL}admin/patient-testimonial`, obj);
}