import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import queryString from 'query-string';
// import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { CustomTable } from "../../components/Table";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { plusicon, refreshicon } from "../../components/imagepath";
import { useDispatch, useSelector } from "react-redux";
import { ActionUpdateSolutionSlot, GetAllSolutionSlot } from "../../store/Actions/SolutionSlotAction";
import { useEffect, useState } from "react";
import { matchPermissions } from "../../utils/Permission";
import { generateQueryString } from "../../utils/Funcations";
import TableColumnSearch from '../../components/Input/TableColumnSearch';
import { InputFromUser } from "../../components/Input/input";
import { Button, DatePicker } from "antd";
import AssignDoctorModal from "../../components/Modal/AssignDoctorModal";

// const { confirm } = Modal;

const SolutionSlot = () => {
    const { SolutionSlot } = useSelector(state => state?.SolutionSlotBooking);
    const { userDetails } = useSelector(state => state?.auth);
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('solution-slot.update');
    const [modal, SetModal] = useState({ is: false, id: "" });

    // const [showArrow, setShowArrow] = useState(true);
    const [Refresh, setRefresh] = useState(false);
    // const [arrowAtCenter, setArrowAtCenter] = useState(false);
    // const [IsSearching, setIsSearching] = useState(false);

    const pageSize = 10;
    const dispatch = useDispatch();
    const location = useLocation();
    const params = queryString.parse(location.search);
    // const Size = ResponsiveComponent()

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const page = searchParams.get("page");
    const customerName = searchParams.get("customerName");
    const phoneNo = searchParams.get("phoneNo");

    console.log('searchedColumn', searchedColumn);
    const [currentView, setCurrentView] = useState(localStorage.getItem('currentView'));
    // const currentView = localStorage.getItem('currentView');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortOn, setSortOn] = useState('date');
    const [dateRange, setDateRange] = useState([]);

    const handleSearch = (selectedKeys, dataIndex) => {
        setSearchText(selectedKeys?.[0]);
        setSearchedColumn(dataIndex);

        const queryString1 = generateQueryString(['page', page],
            ['customerName', dataIndex === 'customerName' ? selectedKeys?.[0] : customerName],
            //   ['doctorName', dataIndex === 'doctorName' ? selectedKeys?.[0] : doctorName],
            ['phoneNo', dataIndex === 'phoneNo' ? selectedKeys?.[0] : phoneNo],
            //   ['registrationNo', dataIndex === 'registrationNo' ? selectedKeys?.[0] : registrationNo],
            //   ['consultationType', dataIndex === 'consultationType' ? selectedKeys?.[0] : consultationType]
        );
        window.location.replace(`${queryString1}`)
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        window.location.replace(`/treatment-slot?page=1`)
    }

    const handleViewChange = (view) => {
        setCurrentView(view);
        localStorage.setItem('currentView', view)
        //   setSortOrder(view === 'Upcoming' ? 'asc' : 'desc');
        //   setSortOn(view === 'Upcoming' ? 'date' : 'date');
        // };

        switch (view) {
            case 'Upcoming':
                setSortOrder('asc');
                setSortOn('date');
                break;
            case 'Completed':
                setSortOrder('desc');
                setSortOn('date');
                break;
            case 'Cancelled':
            case 'InProgress':
                setSortOrder('asc');
                setSortOn('date');
                break;
            default:
                setSortOrder('asc');
                setSortOn('date');
                break;
        }
    };

    const handleStatusUpdate = (recordId, newStatus) => {
        dispatch(ActionUpdateSolutionSlot({
            Query: recordId, obj: {
                "status": newStatus,
            }

        }, () => setRefresh(true)));
    };
    useEffect(() => {
        const newQuery = generateQueryString(
            ['page', page || 1],
            ['customerName', customerName],
            // ['doctorName', doctorName],
            // ['consultationType', consultationType],
            ['phoneNo', phoneNo],
            // ['registrationNo', registrationNo],
            ['sort', sortOrder],
            ['sortOn', sortOn],
            ['status', currentView],
            ['startDate', params.startDate || ''],
            ['endDate', params.endDate || ''],
        );


        dispatch(GetAllSolutionSlot({ Query: newQuery, }));
        setRefresh(false);
    }, [Refresh, searchText, currentView, sortOrder, sortOn, page, customerName, phoneNo, dateRange])

    const handleDateRangeChange = (dates) => {
        setDateRange(dates);
        const newQueryParams = {
            ...params,
            startDate: dates[0]?.format('YYYY-MM-DD'),
            endDate: dates[1]?.format('YYYY-MM-DD'),
        };
        const queryString1 = queryString.stringify(newQueryParams);
        window.location.search = `?${queryString1}`;
    };
    const clearDateFilters = () => {
        setDateRange([]);
        dispatch(GetAllSolutionSlot());
    }
    const column = [
        {
            title: "Sr",
            dataIndex: "sr",
            // key: "Sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Customer Name",
            dataIndex: "customerName",
            render: (text, record) => (
                <Link to={`/order-history-overview/${record?.orderId}`}>
                    {record?.customerName}
                </Link>
            ),
            ...TableColumnSearch({ dataIndex: 'customerName', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },
        {
            title: "Phone No.",
            dataIndex: "phoneNo",
        },
        {
            title: "Detox Program Name",
            dataIndex: "solutionData",
            render: (solutionData) => (
                <div>
                    {solutionData.map((solution, index) => (
                        <div key={index}>
                            <span> {solution.solutionName}</span>
                        </div>
                    ))}
                </div>
            ),
        },
        {
            title: "Total Days",
            dataIndex: "totalDays",
            // render: (text, record) => (
            //     <span>{record?.date}</span>
            // ),
        },
        {
            title: "Date",
            dataIndex: "dateRange",
            render: (text, record) => {
                const startDate = record.startDate;
                const endDate = record.endDate;
                const formattedDateRange = `${startDate} To ${endDate}`;
                return <span>{formattedDateRange}</span>;
            }
        },
        {
            title: "Care Taker",
            dataIndex: "careTakerCount",

        },
        {
            title: "Assigned Doctor",
            dataIndex: "doctorName",
            render: (text, record) => (
                <span>{record?.doctorName}</span>
            ),
        },
        {
            title: "Primary Customer",
            dataIndex: "parentCustomerName",
            render: (text, record) => (

                <span>{record?.parentCustomerName}</span>
            ),
        },
        {
            title: 'Update Solution Status',
            dataIndex: 'status',
            render: (text, record) => (
                <>
                    {canUpdate &&
                        <div style={{ marginTop: "-5" }}>

                            <InputFromUser
                                placeholder={""}
                                type="singleSelect"
                                name="status"
                                mode={''}
                                onChange={(newStatus) => handleStatusUpdate(record.id, newStatus)}
                                options={["Upcoming", "Completed"]}
                                FormGroupClassName={"col-xl-8 col-md-12 cursor-pointer"}
                                LabelClassName={"col-form-label cursor-pointer"}
                                value={record.status}
                                disabled={record?.status === "Completed"}
                                label=""
                            />
                        </div>
                    }
                </>
            ),
        },
        {
            title: "Actions",
            dataIndex: "",
            render: (record) => (
                <Button
                    type="primary"
                    onClick={() => SetModal({ is: true, id: record?._id })}
                    disabled={record.status === "Completed"}
                >
                    Assign Doctor
                </Button>
            ),
        },

    ];

    const handleSearch1 = (searchTerm) => {
        var newp = params
        newp.page = searchTerm
        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
    }

    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/solution-slot?page=1">Detox Programs Booking</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Detox Programs List</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center justify-content-between">
                                                    <div className="col-12 col-md-8">
                                                        <div className="doctor-table-blk">
                                                            <h3>Detox Programs List</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">
                                                                    {matchPermissionsForUser('solution-slot.create') &&
                                                                        <Link
                                                                            to="/solution-slot/add-solution-slot"
                                                                            className="btn btn-primary add-pluss ms-2"
                                                                        >
                                                                            <img src={plusicon} alt="#" />
                                                                        </Link>
                                                                    }

                                                                    <Link
                                                                        to="/solution-slot?page=1"
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 col-xl-6 mt-3 mb-md-0" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <DatePicker.RangePicker
                                                                onChange={handleDateRangeChange}
                                                                className="w-100"
                                                                allowClear={false}
                                                            />
                                                            <Button type="link" onClick={clearDateFilters} style={{ marginLeft: '10px' }}>Clear Filters</Button>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-12 col-md-3"> */}
                                                    <div className={`dropdown-color col-12 col-md-4 ${currentView === 'Completed' ? 'Completed' : currentView === 'Cancelled' ? 'Cancelled' : 'Upcoming'}`}>

                                                        <div style={{ marginTop: "-5", marginBottom: "-5" }}>
                                                            <InputFromUser
                                                                placeholder={""}
                                                                type="singleSelect"
                                                                name="status"
                                                                mode={''}
                                                                onChange={handleViewChange}
                                                                options={["Upcoming", "Completed", "Cancelled", "InProgress"]}
                                                                FormGroupClassName={"col-xl-6 col-md-12 cursor-pointer m-0 p-0"}
                                                                LabelClassName={"col-form-label cursor-pointer"}
                                                                value={currentView}
                                                                label="Booking Status"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <CustomTable
                                                CurrentPageNum={parseInt(params?.page) || 1}
                                                OnChange={(v) => {
                                                    handleSearch1(v)
                                                }}
                                                TotalPageList={SolutionSlot?.[0]?.totalDocs}
                                                column={column}
                                                dataSource={SolutionSlot?.[0]?.docs}
                                                dataSourceLength={SolutionSlot?.[0]?.docs?.length}
                                                pageSize={10}
                                                // loader={loader}
                                                ShowHeader={true}
                                                Header={<div className="staff-search-table">
                                                </div>}
                                                key={"hi"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <AssignDoctorModal
                IsOpen={modal}
                slotType={'solution'}
                initialValues={{
                    doctorId: SolutionSlot?.[0]?.docs?.doctorId || '',
                    doctorName: SolutionSlot?.[0]?.docs?.doctorName || '',
                }}
                Isclose={() => SetModal({ is: false, id: "", })} />
        </>
    );
};

export default SolutionSlot;