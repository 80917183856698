import React, { useEffect } from 'react'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { CelebrityValidationSchema } from '../../utils/FormValidation'
import { InputFromUser, TextEditor } from '../../components/Input/input'
import { Divider } from 'antd'
import { CreateCelebrity, GetAllcelebrity } from '../../store/Actions/CelebrityAction'
import { getAdminuUerDetails } from '../../store/Actions/AuthActions'
import { ActionUpdateById, ActionUploadImage } from '../../store/Actions/CommonAction'
import UploadImage from '../../components/Upload/Upload'
import { DOCS_URL } from '../../utils/globals'

const AddEditCelebrity = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const { userDetails } = useSelector(state => state?.auth);
    const { Celebritylist } = useSelector(state => state?.Celebrity);
    const userId = userDetails?.[0]?._id
    const idValue = searchParams.get("id");
    const updateValue = searchParams.get("update");
    useEffect(() => {
        if (idValue) {
            dispatch(GetAllcelebrity({ Query: '/' + idValue }));
        }
    }, [idValue])
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/celebrity-list">Celebrity</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Celebrity</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue || false}
                                                initialValues={{
                                                    celebrityName: {
                                                        en: Celebritylist?.[0]?.celebrityName?.en || "",
                                                        hi: Celebritylist?.[0]?.celebrityName?.hi || "",
                                                        mr: Celebritylist?.[0]?.celebrityName?.mr || ""
                                                    },
                                                    celebrityShortDescription: {
                                                        en: Celebritylist?.[0]?.celebrityShortDescription?.en || "",
                                                        hi: Celebritylist?.[0]?.celebrityShortDescription?.hi || "",
                                                        mr: Celebritylist?.[0]?.celebrityShortDescription?.mr || ""
                                                    },
                                                    celebrityReview: {
                                                        en: Celebritylist?.[0]?.celebrityReview?.en || "",
                                                        hi: Celebritylist?.[0]?.celebrityReview?.hi || "",
                                                        mr: Celebritylist?.[0]?.celebrityReview?.mr || ""
                                                    },
                                                    knownFor: Celebritylist?.[0]?.knownFor || "",
                                                    orderIndex: Celebritylist?.[0]?.orderIndex || "",
                                                    videoIframes: Celebritylist?.[0]?.videoIframes || "",
                                                    docs: Celebritylist?.[0]?.docs || []
                                                }}
                                                validationSchema={CelebrityValidationSchema}
                                                onSubmit={(values, { setErrors, setSubmitting }) => {
                                                    if (updateValue && idValue != null) {
                                                        dispatch(ActionUpdateById({ Query: `admin/celebrity/${idValue}`, obj: values }
                                                            , (Res) => {
                                                                if (Res?.statusCode === 200) {
                                                                    window.location.href = `/celebrity-list?page=1`
                                                                } else if (Res?.statusCode === 400) {
                                                                    setErrors(Res?.data?.errors);
                                                                }
                                                                setSubmitting(false);
                                                            }));
                                                    } else {
                                                        //CreateCelebrity
                                                        dispatch(CreateCelebrity(values, (Response) => {
                                                            if (Response?.statusCode === 200) {
                                                                window.location.href = "/celebrity-list?page=1"
                                                            }
                                                            setSubmitting(false);
                                                        }))
                                                    }
                                                }}

                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue,
                                                    isSubmitting,
                                                }) => {
                                                    return (
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="form-heading">
                                                                    <h4>Add Celebrity Data</h4>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-4">
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Celebrity Name"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Celebrity Name"
                                                                    value={values?.celebrityName?.en}
                                                                    name="celebrityName"
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.celebrityName
                                                                        UpdateValue.en = v?.target?.value
                                                                        setFieldValue("celebrityName", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.celebrityName?.en}
                                                                    touched={touched?.celebrityName?.en}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Marathi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Celebrity Name(*Marathi)"
                                                                    name="celebrityName.mr"
                                                                    value={values?.celebrityName?.mr}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.celebrityName
                                                                        UpdateValue.mr = v?.target?.value
                                                                        setFieldValue("celebrityName", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.celebrityName?.mr}
                                                                    touched={touched?.celebrityName?.mr}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Hindi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Celebrity Name (*Hindi)"
                                                                    value={values?.celebrityName?.hi}
                                                                    name="celebrityName.hi"
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.celebrityName
                                                                        UpdateValue.hi = v?.target?.value
                                                                        setFieldValue("celebrityName", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.celebrityName?.hi}
                                                                    touched={touched?.celebrityName?.hi}
                                                                />
                                                            </div>

                                                            <div className="row mt-4">
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Celebrity Description"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Celebrity Short Description"
                                                                    value={values?.celebrityShortDescription?.en}
                                                                    name="celebrityShortDescription"
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.celebrityShortDescription
                                                                        UpdateValue.en = v?.target?.value
                                                                        setFieldValue("celebrityShortDescription", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.celebrityShortDescription?.en}
                                                                    touched={touched?.celebrityShortDescription?.en}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Marathi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Celebrity Short Description (*Marathi)"
                                                                    name="celebrityShortDescription.mr"
                                                                    value={values?.celebrityShortDescription?.mr}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.celebrityShortDescription
                                                                        UpdateValue.mr = v?.target?.value
                                                                        setFieldValue("celebrityShortDescription", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.celebrityShortDescription?.mr}
                                                                    touched={touched?.celebrityShortDescription?.mr}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Hindi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Celebrity Short Description (*Hindi)"
                                                                    value={values?.celebrityShortDescription?.hi}
                                                                    name="celebrityShortDescription"
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.celebrityShortDescription
                                                                        UpdateValue.hi = v?.target?.value
                                                                        setFieldValue("celebrityShortDescription", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.celebrityShortDescription?.hi}
                                                                    touched={touched?.celebrityShortDescription?.hi}
                                                                />
                                                            </div>

                                                            <div className="row mt-4">
                                                                <InputFromUser
                                                                    placeholder={"Past Your YouTube Video Link"}
                                                                    type="url"
                                                                    name="videoIframes"
                                                                    onChange={(v) => setFieldValue("videoIframes", v?.target?.value, true)}
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values.videoIframes}
                                                                    label="Iframe Link"
                                                                    error={errors?.videoIframes}
                                                                    touched={touched.videoIframes}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Known For"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Known For"
                                                                    value={values?.knownFor}
                                                                    name="knownFor"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.knownFor}
                                                                    touched={touched?.knownFor}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Order Index"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Order Index"
                                                                    value={values?.orderIndex}
                                                                    name="orderIndex"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.orderIndex}
                                                                    touched={touched?.orderIndex}
                                                                />
                                                                {/* <div className="d-flex justify-content-evenly align-items-center col-6 "> */}
                                                                <label className="col-form-label">
                                                                    {"Upload Photo :-"}
                                                                </label>
                                                                <div>
                                                                    <UploadImage
                                                                        className={"img-fluid"}
                                                                        fileList={!(values.docs.length === 0) ? values.docs.map((val, ind) => ({
                                                                            uid: ind + 'docs',
                                                                            name: (DOCS_URL + values?.docs?.[ind]).split('/')?.[(DOCS_URL + values?.docs?.[ind]).split('/').length - 1],
                                                                            status: 'done',
                                                                            url: DOCS_URL + values?.docs?.[ind],
                                                                        })) : []}

                                                                        handleChange={(v) => {
                                                                            if (v.file && v.file.status === 'uploading') {
                                                                                const formData = new FormData();
                                                                                formData.append("isCelebrity", true);
                                                                                formData.append("attachment", v?.file?.originFileObj);
                                                                                dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                    if (typeof res === 'object') {
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: 'docs',
                                                                                                value: [...(values?.docs || []), res[0]],
                                                                                            },
                                                                                        });
                                                                                    }
                                                                                }))
                                                                            } else if (v.file && v.file.status === 'removed') {
                                                                                const filteredFiles = values?.docs.filter((url) => (DOCS_URL + url) != v?.file?.url);
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: 'docs',
                                                                                        value: filteredFiles,
                                                                                    },
                                                                                });
                                                                            }
                                                                        }}
                                                                        UploadImagelimit={7} />
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Celebrity Review</Divider></div>
                                                                <div id="editor" >
                                                                    <TextEditor
                                                                        error={errors?.celebrityReview?.en}
                                                                        value={values?.celebrityReview?.en}
                                                                        onChange={(event, editor) => {
                                                                            const UpdateValue = values?.celebrityReview
                                                                            UpdateValue.en = editor.getData();
                                                                            setFieldValue("celebrityReview", UpdateValue, true)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Celebrity Review (Marathi)</Divider></div>
                                                                <div id="editor" >
                                                                    <TextEditor
                                                                        value={values?.celebrityReview?.mr}
                                                                        onChange={(event, editor) => {
                                                                            const UpdateValue = values?.celebrityReview
                                                                            UpdateValue.mr = editor.getData();
                                                                            setFieldValue("celebrityReview", UpdateValue, true)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Celebrity Review (Hindi)</Divider></div>
                                                                <div id="editor" >
                                                                    <TextEditor
                                                                        value={values?.celebrityReview?.hi}
                                                                        onChange={(event, editor) => {
                                                                            const UpdateValue = values?.celebrityReview
                                                                            UpdateValue.hi = editor.getData();
                                                                            setFieldValue("celebrityReview", UpdateValue, true)
                                                                        }}
                                                                    />
                                                                </div>

                                                            </div>

                                                            <div className="col-12 mt-4">
                                                                <div className="doctor-submit text-end">
                                                                    <button
                                                                        type="submit"
                                                                        onClick={() => {
                                                                            handleSubmit()
                                                                        }}
                                                                        className="btn btn-primary submit-form me-2"
                                                                        disabled={isSubmitting}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary cancel-form"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Formik>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div>

            </>

        </>
    )
}

export default AddEditCelebrity