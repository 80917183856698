import { GET_PATIENT_LIST, GET_PATIENT_LIST_SUCCESS, CREATE_PATIENT, MEDICINE_LIST, MEDICINE_LIST_SUCCESS, GET_LABORATORY_DATA, GET_LABORATORY_DATA_SUCCESS, GET_CC_PAPER_DETAIL, GET_CC_PAPER_DETAIL_SUCCESS, GET_CUS_DASHBOARD, GET_CUS_DASHBOARD_SUCCESS, GET_MEDICAL_DETAIL, GET_MEDICAL_DETAIL_SUCCESS, GET_CC_PAPER_VERIFICATION_DETAIL, GET_CC_PAPER_VERIFICATION_DETAIL_SUCCESS, GET_PATIENT_BY_ID, GET_PATIENT_BY_ID_SUCCESS, UPDATE_PATIENT, GET_CUSTOMER_BIRTHDATE_DETAIL, GET_CUSTOMER_BIRTHDATE_DETAIL_SUCCESS, GET_PATIENT_CONFIRMATION_DETAIL, GET_PATIENT_CONFIRMATION_DETAIL_SUCCESS, GET_ASSIGNED_DOCTOR_LIST, GET_ASSIGNED_DOCTOR_LIST_SUCCESS } from "../storeTypes";

// /customers/Patient/

export const ActionGetPatientList = (payload) => {
    return {
        type: GET_PATIENT_LIST,
        payload
    };
};
export const ActionGetPatientListSuccess = (payload) => {
    return {
        type: GET_PATIENT_LIST_SUCCESS,
        payload
    };
};

export const ActionGetPatientById = (payload, callBack) => {
    return {
        type: GET_PATIENT_BY_ID,
        payload,
        callBack
    };
};
export const ActionGetPatientByIdSuccess = (payload) => {
    return {
        type: GET_PATIENT_BY_ID_SUCCESS,
        payload
    };
};

export const ActionGetlaboratory = (payload) => {
    return {
        type: GET_LABORATORY_DATA,
        payload
    };
};
export const ActionGetlaboratorySuccess = (payload) => {
    return {
        type: GET_LABORATORY_DATA_SUCCESS,
        payload
    };
};

export const ActionCreatePatient = (payload, callBack) => {
    return {
        type: CREATE_PATIENT,
        payload,
        callBack
    };
};

export const ActionUpdatePatient = (payload, callBack) => {
    return {
        type: UPDATE_PATIENT,
        payload,
        callBack
    };
};
export const ActionGetMedicineList = (payload) => {
    return {
        type: MEDICINE_LIST,
        payload
    };
};
export const ActionGetMedicineListSuccess = (payload) => {
    return {
        type: MEDICINE_LIST_SUCCESS,
        payload
    };
};
export const ActionGetCCPaperDetail = (payload) => {
    return {
        type: GET_CC_PAPER_DETAIL,
        payload
    };
};
export const ActionGetCCPaperDetailSuccess = (payload) => {
    return {
        type: GET_CC_PAPER_DETAIL_SUCCESS,
        payload
    };
};
export const ActionGetCustomerBirthdateDetail = (payload) => {
    return {
        type: GET_CUSTOMER_BIRTHDATE_DETAIL,
        payload
    };
};
export const ActionGetCustomerBirthdateDetailSuccess = (payload) => {
    return {
        type: GET_CUSTOMER_BIRTHDATE_DETAIL_SUCCESS,
        payload
    };
};
export const ActionGetCCPaperVerificationDetail = (payload) => {
    return {
        type: GET_CC_PAPER_VERIFICATION_DETAIL,
        payload
    };
};
export const ActionGetCCPaperVerificationDetailSuccess = (payload) => {
    return {
        type: GET_CC_PAPER_VERIFICATION_DETAIL_SUCCESS,
        payload
    };
};
export const GetCustomerDashboard = (payload) => {
    return {
        type: GET_CUS_DASHBOARD,
        payload
    };
};
export const GetCustomerDashboardSuccess = (payload) => {
    return {
        type: GET_CUS_DASHBOARD_SUCCESS,
        payload
    };
};
export const GetMedicalDetailAction = (payload) => {
    return {
        type: GET_MEDICAL_DETAIL,
        payload
    };
};

export const GetMedicalDetailSuccess = (payload) => {
    return {
        type: GET_MEDICAL_DETAIL_SUCCESS,
        payload
    };
};
export const GetPatientConfirmationDetail = (payload) => {
    return {
        type: GET_PATIENT_CONFIRMATION_DETAIL,
        payload
    };
};

export const GetPatientConfirmationDetailSuccess = (payload) => {
    return {
        type: GET_PATIENT_CONFIRMATION_DETAIL_SUCCESS,
        payload
    };
};

export const GetAssignedDoctorList = (payload) => {
    return {
        type: GET_ASSIGNED_DOCTOR_LIST,
        payload
    };
};

export const GetAssignedDoctorListSuccess = (payload) => {
    return {
        type: GET_ASSIGNED_DOCTOR_LIST_SUCCESS,
        payload
    };
};