import { GET_ALL_TREATMENT_SLOT, GET_ALL_TREATMENT_SLOT_SUCCESS, GET_TREATMENT_SLOT_BY_ID, GET_TREATMENT_SLOT_BY_ID_SUCCESS, UPDATE_TREATMENT_SLOT } from "../storeTypes";

const INIT_STATE = {
    loader: false,
    TreatmentSlot: []
}

function TreatmentSlotReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case GET_ALL_TREATMENT_SLOT: {
            return {
                ...state,
                TreatmentSlot: [],
                loader: true,
            };
        }
        case UPDATE_TREATMENT_SLOT: {
            return {
                ...state,
                loader: true,
            }
        }
        case GET_ALL_TREATMENT_SLOT_SUCCESS: {
            return {
                ...state,
                loader: false,
                TreatmentSlot: action?.payload
            }
        }
        case GET_TREATMENT_SLOT_BY_ID: {
            return {
                ...state,
                TreatmentSlot: [],
                loader: true,
            };
        }
        case GET_TREATMENT_SLOT_BY_ID_SUCCESS: {
            return {
                ...state,
                loader: false,
                TreatmentSlot: action.payload,
            };
        }
        default: {
            return state;
        }
    }
}

export default TreatmentSlotReducer;