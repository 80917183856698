import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { CustomTable } from "../../components/Table";
import { Button, Checkbox } from "antd";





const PermissionsTable = ({ data, value, OnChange, error, touched }) => {

    // Extract unique roles and permissions
    const uniqueModules = [...new Set(data.map(item => item.moduleName))];

    // Function to generate columns for a specific module
    const generateColumns = (moduleName) => {
        const moduleData = data.filter(item => item.moduleName === moduleName);

        const uniquePermissions = [...new Set(moduleData.map(item => item.name))];

        return [
            {
                title: 'Permission',
                dataIndex: 'Role',
                key: 'Role',
            },
            ...uniquePermissions.map(permission => ({
                title: permission,
                dataIndex: permission,
                key: permission,
                render: () => {
                    // const hasPermission = moduleData.some(item => item.title === permission && item.moduleName === record.Role);
                    return (
                        <Checkbox
                            checked={value.includes(permission)}
                            onChange={() => handleCheckboxChange(permission)} />
                    );
                },
            })),
            {
                title: 'Action',
                dataIndex: 'selectAll',
                key: 'selectAll',
                render: () => (
                    <Button onClick={() => {
                        OnChange(value.concat(uniquePermissions))
                    }}>select All</Button>
                ),
            },
        ];
    };

    // Function to transform data for a specific module
    const transformData = (moduleName) => {
        const moduleData = data.filter(item => item.moduleName === moduleName);
        const uniqueRoles = [...new Set(moduleData.map(item => item.moduleName))];

        return uniqueRoles.map(role => {
            const rowData = {
                key: role,
                Role: role,
            };
            moduleData.forEach(item => {
                rowData[item.name] = item.isActive;
            });
            return rowData;
        });
    };
    const handleCheckboxChange = (permission) => {
        const permissionIndex = value.indexOf(permission);

        if (permissionIndex === -1) {
            // If not present, add it
            OnChange([...value, permission]);
        } else {
            // If present, remove it
            const updatedPermissions = [...value];
            updatedPermissions.splice(permissionIndex, 1);
            OnChange(updatedPermissions);
        }
    };
    // error && touched &&
    return uniqueModules.map(moduleName => {
        return (
            <div key={moduleName} style={{ padding: 1, borderRadius: 10, marginBottom: 15 }} className={`border ${error && touched ? 'border-danger' : 'border-1'}`}>
                <CustomTable
                    CurrentPageNum={1}
                    OnChange={() => undefined}
                    TotalPageList={2}
                    column={generateColumns(moduleName)}
                    dataSource={transformData(moduleName)}
                    pageSize={30}
                    ShowHeader={true}
                    Header={<div className="staff-search-table">
                        {<label style={{ color: error && touched && 'red' }}  >
                            {moduleName}
                            {error && <span className="login-danger"> *</span>}
                        </label>}
                    </div>}
                    key={"hi"} />
            </div>
        )
    })
};
export default PermissionsTable;
