/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from "query-string";
import { getAllAppoinment } from '../../../store/Actions/AppointmentAction';
import { Button, DatePicker } from 'antd';
import { generateQueryString } from '../../../utils/Funcations';
import { matchPermissions } from '../../../utils/Permission';
import TableColumnSearch1 from '../../../components/Input/TableColumnSearch1';
import { TableListPageCom } from '../../../components/TableListPageCom';
import { InputFromUser } from '../../../components/Input/input';
import { Link } from 'react-router-dom';

const AppoinmentListBooking = () => {
    const pageSize = 10;
    const dispatch = useDispatch();
    const [Refresh, setRefresh] = useState(false);
    const { AllAppoinment, loader } = useSelector(state => state?.Appointment);
    const { userDetails } = useSelector(state => state?.auth);
    const params = queryString.parse(location.search);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const pageParams = searchParams.get("page");
    const customerNameParams = searchParams.get("customerName");
    const doctorNameParams = searchParams.get("doctorName");
    const phoneNoParams = searchParams.get("phoneNo");
    const registrationNoParams = searchParams.get("registrationNo");
    const consultationTypeParams = searchParams.get("consultationType");
    const statusParams = searchParams.get("status");
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canCerate = matchPermissionsForUser('appointment.create');
    const canUpdate = matchPermissionsForUser('medical.create');
    const [Query, setQuery] = useState({
        'page': pageParams,
        'customerName': customerNameParams,
        'doctorName': doctorNameParams,
        'phoneNo': phoneNoParams,
        'registrationNo': registrationNoParams,
        'consultationType': consultationTypeParams,
        'status': statusParams || "Upcoming",
        'date': params.date || null,
    },);

    const Filters = generateQueryString(
        ['page', (Query.page || 1)],
        ['customerName', (Query.customerName)],
        ['doctorName', Query.doctorName],
        ['phoneNo', Query.phoneNo],
        ['registrationNo', Query.registrationNo],
        ['consultationType', Query.consultationType],
        ['status', Query.status],
        ['date', Query.date],
    )
    const handleResetFilters = () => {
        setQuery({
            ...Query,
            date: '',
            datePicker: null
        });
    };
    useEffect(() => {
        dispatch(getAllAppoinment({ Query: Filters, }));
        window.history.replaceState(null, '', Filters);
        setRefresh(false);
    }, [Refresh, Filters,]);
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Patient Name",
            dataIndex: "customerName",
            render: (text, record, index) => {
                return (
                    <Link to={`/patient-list/patient-profile?patientId=${record?.customerId?._id}&page=${params?.page}`}>{text}</Link>
                )
            },
            ...TableColumnSearch1({
                dataIndex: 'customerName',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
            sorter: (a, b) => a.customerName.length - b.customerName.length,
        },
        {
            title: "Doctor Name",
            dataIndex: "doctorName",
            ...TableColumnSearch1({
                dataIndex: 'doctorName',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
        },
        {
            title: "Consultation Type",
            dataIndex: "consultationType",
            sorter: (a, b) => a.consultationType.length - b.consultationType.length,
            ...TableColumnSearch1({
                dataIndex: 'consultationType',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
        },
        {
            title: "Consulting For",
            dataIndex: "treatment",
        },
        {
            title: "Phone No",
            dataIndex: "phoneNo",
            ...TableColumnSearch1({
                dataIndex: 'phoneNo',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
        },
        {
            title: "CasePaperID",
            dataIndex: "registrationNo",
            ...TableColumnSearch1({
                dataIndex: 'registrationNo',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
        }, {
            title: "Date",
            dataIndex: "date",
        },
        {
            title: "Time",
            dataIndex: "timeRange",
            render: (text, record) => {
                const startTime = record.startTime;
                const endTime = record.endTime;
                const formattedTimeRange = `${startTime}-${endTime}`;
                return <span>{formattedTimeRange}</span>;
            },
        },
        {
            title: !canUpdate ? null : "Actions",
            dataIndex: "",
            render: (text, record, index) => {
                if (!canUpdate) {
                    return null;
                } else {
                    const actionValue = record.status === 'Cancelled' ? 'Cancelled' : record.status === 'Completed' ? "Completed" : 'Consultation';
                    return (
                        <Button
                            type="primary"
                            disabled={record.status === 'Completed' || record.status === "Cancelled"}
                            onClick={() => {
                                window.location.href = `/patient-list/appointment-details${Filters}&appointmentId=${record?._id}&userId=${record?.customerId?._id}&consultationFor=${record?.consultingFor}`
                            }}
                        >
                            {actionValue}
                        </Button>
                    );
                }
            },
        },

    ]
    const BreadcrumbList = [{
        name: 'Appointment',
        linkTo: `/appoinment-list?page=${params?.page || 1}`
    },
    {
        name: 'Appointment List',
    }
    ]

    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={AllAppoinment?.docs}
            TableColumn={columns}
            ShowAddButton={canCerate}
            LeftChild={
                <div className="col-12 col-lg-6 col-xl-6 mt-3 mb-md-0" style={{ display: 'flex', alignItems: 'center' }}>
                    <DatePicker
                        onChange={(date, dateString) => {
                            const newQuery = { ...Query, page: null, date: dateString, datePicker: date };
                            setQuery(newQuery);
                        }}
                        value={Query.datePicker}
                        className="w-100"
                        allowClear={true}
                    />
                    <Button type="link" onClick={handleResetFilters} style={{ marginLeft: '10px' }}>Clear Filters</Button>
                </div>}
            RightChild={
                <div className='col d-flex flex-row-reverse'>
                    <div className={`dropdown-color row col-xl-5 col-md-10 ${Query.status === 'Completed' ? 'Completed' : Query.status === 'Cancelled' ? 'Cancelled' : 'Upcoming'}`}>
                        <h4 className='col-xl-4 col-md-12 my-4' >Status</h4>
                        <InputFromUser
                            placeholder={" Select any Value"}
                            type="singleSelect"
                            FormGroupClassName={'col-xl-5 col-md-12 m-0 p-0'}
                            FormControlClassName={'m-0 p-0'}
                            name="status"
                            onChange={(v) => setQuery({ ...Query, status: v })}
                            LabelClassName={'col m-0 p-0'}
                            Dropdown_ClassName={'col m-0 p-0'}
                            ComponentStyle={{ height: '5%' }}
                            options={["Upcoming", "Completed", "Cancelled"]}
                            value={Query.status}
                        />
                    </div>
                </div>
            }
            CreateButtonLink='/add-appoinment?step=1'
            PageMainTitle="Appointment List"
            CurrentPage={Query.page || 1}
            Tableloader={loader}
            TotalPageList={AllAppoinment?.totalDocs}
            dataSourceLength={AllAppoinment?.docs?.length}
            handlePageChange={(v) => setQuery({ ...Query, page: v })}
        />
    )
}

export default AppoinmentListBooking;
