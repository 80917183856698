import { call, put, takeEvery } from "redux-saga/effects";
import { CreateOrderApi, GetAllOrderApi, GetOrderByIdApi } from "../../utils/api";
import { handleApiResponse } from "../../utils/Funcations";
import { CREATE_ORDER, GET_ALL_ORDER_LIST, GET_ALL_PENDING_ORDER_LIST, GET_ORDER_BY_ID } from "../storeTypes";
import { ActionGetAllOrderListSuccess, ActionGetAllPendingOrderListSuccess, ActionGetOrderByIDSuccess } from "../Actions/OrderHistoryAction";

const File = "OrderHistorySaga"

function* GetAllOrderSaga(action) {
    try {
        const response = yield call(GetAllOrderApi, action.payload);
        console.log("GetAllOrderApi", response?.data?.data[0]);
        if (response?.status === 200) {
            yield put(ActionGetAllOrderListSuccess(response?.data?.data[0]));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callback);
        console.log(File + "GetAllOrderSaga", error);
    }
}
function* GetAllPendingOrderSaga(action) {
    try {
        const response = yield call(GetAllOrderApi, action.payload);
        console.log('responsePending', response?.data?.data?.[0]);
        if (response?.status === 200) {
            yield put(ActionGetAllPendingOrderListSuccess(response?.data?.data?.[0]));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callback);
        console.log(File + "GetAllOrderSaga", error);
    }
}

function* CreateOrderSaga(action) {
    try {
        const response = yield call(CreateOrderApi, action.payload);
        console.log("CreateOrderApi", response?.data);
        if (response) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + '/CreateOrderSaga Error:', error);
    }
}

function* GetOrderByIDSaga(action) {
    try {
        const response = yield call(GetOrderByIdApi, action.payload);
        console.log("GetOrderByIdApi", response?.data?.data[0]);
        if (response?.status === 200) {
            yield put(ActionGetOrderByIDSuccess(response?.data?.data[0]));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File + '/GetOrderByIDSaga Error:', error);
    }
}

export function* OrderHistorySaga() {
    yield takeEvery(GET_ALL_ORDER_LIST, GetAllOrderSaga)
    yield takeEvery(GET_ALL_PENDING_ORDER_LIST, GetAllPendingOrderSaga)
    yield takeEvery(CREATE_ORDER, CreateOrderSaga)
    yield takeEvery(GET_ORDER_BY_ID, GetOrderByIDSaga)
}