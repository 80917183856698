import {
    CREATE_DETOX_RETREAT,
    GET_DETOX_RETREAT_BOOKING,
    GET_DETOX_RETREAT_BOOKING_SUCCESS,
    CREATE_DETOX_RETREAT_SUCCESS,
    CREATE_SERVICE,
    CREATE_SERVICE_SUCCESS,
    CREATE_SOLUTION,
    CREATE_SOLUTION_SUCCESS,
    CREATE_TREATMENT,
    CREATE_TREATMENT_SUCCESS,
    GET_ALL_SERVICE,
    GET_ALL_SERVICE_SUCCESS,
    GET_ALL_SOLUTION,
    GET_ALL_SOLUTION_SUCCESS,
    GET_ALL_TREATMENT,
    GET_ALL_TREATMENT_SUCCESS,
    GET_ALL_PROGRAMMES,
    GET_ALL_PROGRAMMES_SUCCESS,
    GET_ALL_PROGRAMMES_LIST,
    GET_ALL_PROGRAMMES_LIST_SUCCESS,
    GET_PROGRAMME_BOOKING,
    GET_PROGRAMME_BOOKING_SUCCESS,
    UPDATE_PROGRAMME_SLOT,
} from "../storeTypes";

//Services
export const CreateService = (payload, callBack) => {
    return {
        type: CREATE_SERVICE,
        payload,
        callBack
    };
};
export const CreateServiceSuccess = (payload) => {
    return {
        type: CREATE_SERVICE_SUCCESS,
        payload
    };
};
export const GetAllservice = (payload) => {
    return {
        type: GET_ALL_SERVICE,
        payload,
    };
};
export const GetAllserviceSuccess = (payload) => {
    return {
        type: GET_ALL_SERVICE_SUCCESS,
        payload
    };
};

//Treatments
export const CreateTreatment = (payload, callBack) => {
    return {
        type: CREATE_TREATMENT,
        payload,
        callBack
    };
};
export const CreateTreatmentSuccess = (payload) => {
    return {
        type: CREATE_TREATMENT_SUCCESS,
        payload
    };
};

export const GetAllTreatment = (payload) => {
    return {
        type: GET_ALL_TREATMENT,
        payload,
    };
};
export const GetAllTreatmentSuccess = (payload) => {
    return {
        type: GET_ALL_TREATMENT_SUCCESS,
        payload
    };
};
//Programmes
export const GetAllProgramme = (payload) => {
    return {
        type: GET_ALL_PROGRAMMES,
        payload,
    };
};
export const GetAllProgrammeSuccess = (payload) => {
    return {
        type: GET_ALL_PROGRAMMES_SUCCESS,
        payload
    };
};
export const GetAllProgrammeList = (payload) => {
    return {
        type: GET_ALL_PROGRAMMES_LIST,
        payload,
    };
};
export const GetAllProgrammeListSuccess = (payload) => {
    return {
        type: GET_ALL_PROGRAMMES_LIST_SUCCESS,
        payload
    };
};
export const ActionGetProgrammeBooking = (payload, callBack) => {
    return {
        type: GET_PROGRAMME_BOOKING,
        payload,
        callBack
    };
};
export const ActionGetProgrammeBookingSuccess = (payload) => {
    return {
        type: GET_PROGRAMME_BOOKING_SUCCESS,
        payload
    };
};
export const ActionUpdateProgrammeSlot = (payload, callBack) => {
    return {
        type: UPDATE_PROGRAMME_SLOT,
        payload,
        callBack
    };
};
//Solutions
export const CreateSolution = (payload, callBack) => {
    return {
        type: CREATE_SOLUTION,
        payload,
        callBack
    };
};
export const CreateSolutionSuccess = (payload) => {
    return {
        type: CREATE_SOLUTION_SUCCESS,
        payload
    };
};

export const GetAllsolution = (payload) => {
    return {
        type: GET_ALL_SOLUTION,
        payload,
    };
};
export const GetAllsolutionSuccess = (payload) => {
    return {
        type: GET_ALL_SOLUTION_SUCCESS,
        payload
    };
};

//Detox Retreat
export const CreateDetoxRetreat = (payload, callBack) => {
    return {
        type: CREATE_DETOX_RETREAT,
        payload,
        callBack
    };
};
export const CreateDetoxRetreatSuccess = (payload) => {
    return {
        type: CREATE_DETOX_RETREAT_SUCCESS,
        payload
    };
};
export const ActionGetDetoxRetreatBooking = (payload, callBack) => {
    return {
        type: GET_DETOX_RETREAT_BOOKING,
        payload,
        callBack
    };
};
export const ActionGetDetoxRetreatBookingSuccess = (payload) => {
    return {
        type: GET_DETOX_RETREAT_BOOKING_SUCCESS,
        payload
    };
};