/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Formik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Header from "../../../../components/Header";
import { InputFromUser } from "../../../../components/Input/input";
import Sidebar from "../../../../components/Sidebar";
import { ActionGetMemberShipList, ActionUpdateMemberShip } from "../../../../store/Actions/MasterDataAction";
import { MemberShipValidationSchema } from "../../../../utils/FormValidation";
import { ActionCreateByUrl } from "../../../../store/Actions/CommonAction";

const Add_MemberShip = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const updateValue = searchParams.get("update");
    const pageValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const idValue = searchParams.get("id");

    useEffect(() => {
        if (idValue) {
            dispatch(ActionGetMemberShipList({ Query: `/${idValue}` }));
        }
    }, [dispatch])

    const { memberShip } = useSelector(state => state?.MasterData);
    return (
        <>
            {/* <Loader IsModal={loader} /> */}
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/member-ship">Member Ship</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Member Ship</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue}
                                                initialValues={{
                                                    orderIndex: memberShip?.orderIndex || "",
                                                    memberShipType: memberShip?.memberShipType || "",
                                                }}
                                                validationSchema={MemberShipValidationSchema}
                                                onSubmit={(values, { setSubmitting, setErrors }) => {
                                                    if (updateValue === "false") {
                                                        dispatch(ActionCreateByUrl({ Query: `admin/member-ship`, obj: values }
                                                            , (Response) => {
                                                                if (Response?.statusCode === 200) {
                                                                    window.location.href = "/member-ship?page=1"
                                                                }
                                                            }))
                                                        // dispatch(ActionCreateMemberShip({
                                                        //     "orderIndex": values.orderIndex,
                                                        //     "memberShipType": values.memberShipType
                                                        // },
                                                        //     (Res) => {
                                                        //         setSubmitting(false);
                                                        //         if (Res?.statusCode === 200) {
                                                        //             window.location.href = `/member-ship?page=${pageValue}`;
                                                        //         } else if (Res?.statusCode === 400) {
                                                        //             setErrors(Res?.data?.errors);
                                                        //         } else {
                                                        //             // window.location.reload();
                                                        //         }
                                                        //     }))
                                                    } else {
                                                        dispatch(ActionUpdateMemberShip({
                                                            Query: "/" + idValue,
                                                            obj: {
                                                                "orderIndex": values.orderIndex,
                                                                "memberShipType": values.memberShipType
                                                            }
                                                        }, (Res) => {
                                                            setSubmitting(false);
                                                            if (Res?.statusCode === 200) {
                                                                window.location.href = `/member-ship?page=${pageValue}`;
                                                            } else if (Res?.statusCode === 400) {
                                                                setErrors(Res?.data?.errors);
                                                            } else {
                                                                window.location.reload();
                                                            }
                                                        }))

                                                    }
                                                }}

                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    // setFieldValue,
                                                    isSubmitting,
                                                }) => {
                                                    console.log('errors', errors);
                                                    return (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-heading">
                                                                        <h4>Add Member Ship</h4>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex">
                                                                    <InputFromUser
                                                                        placeholder={"Order Index"}
                                                                        type="number"
                                                                        name="orderIndex"
                                                                        onChange={handleChange}
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={handleBlur}
                                                                        value={values.orderIndex}
                                                                        label="Order Index"
                                                                        error={errors?.orderIndex}
                                                                        touched={touched.orderIndex}
                                                                    />
                                                                </div>
                                                                <InputFromUser
                                                                    placeholder={"memberShipType"}
                                                                    type="text"
                                                                    name="memberShipType"
                                                                    onChange={handleChange}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values.memberShipType}
                                                                    label="memberShip Type"
                                                                    error={errors?.memberShipType}
                                                                    touched={touched.memberShipType}
                                                                />

                                                                <div className="col-12">
                                                                    <div className="doctor-submit text-end">
                                                                        <button
                                                                            type="submit"
                                                                            onClick={() => {
                                                                                handleSubmit()
                                                                            }}
                                                                            className="btn btn-primary submit-form me-2"
                                                                        // disabled={isSubmitting}
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary cancel-form"
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Formik>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div >
            </>
        </>
    );
};

export default Add_MemberShip;
