import { Modal } from 'antd';
import './loader.css';
import { PrakurtiCirculerLogo } from '../imagepath';
import PropTypes from 'prop-types';

const Loader = ({ IsModal = false }) => {
    return (
        <Modal
            open={IsModal}
            footer={false}
            centered
            closable={false}
            className='ant-modal'
            forceRender={true}
            zIndex={1100}
            styles={{
                mask: {
                    backdropFilter: 'blur(10px)',
                },
                content: {
                    borderRadius: 40,
                },
            }}
            confirmLoading={false}
            closeIcon={false}
        ><div className="loader" />
            <img src={PrakurtiCirculerLogo} alt="" style={{
                alignSelf: "center",
                position: 'absolute',
                top: 33, left: 37
            }} />
        </Modal>
    )
}

export default Loader;

Loader.propTypes = {
    IsModal: PropTypes.bool.isRequired
}