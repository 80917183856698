/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from "react";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Popover } from "antd";
import queryString from 'query-string';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ActionDeleteAppConfig, ActionGetAppConfigList } from "../../store/Actions/AppConfigAction";
import { ResponsiveComponent, generateQueryString, trimText } from "../../utils/Funcations";
import TableColumnSearch from '../../components/Input/TableColumnSearch';
import { matchPermissions } from "../../utils/Permission";
import { TableListPageCom } from "../../components/TableListPageCom";
const { confirm } = Modal;

const AppConfig = () => {
  const { appConfig, loader } = useSelector(state => state?.appConfig);
  const [showArrow, setShowArrow] = useState(true);
  const [Refresh, setRefresh] = useState(false);
  const [arrowAtCenter, setArrowAtCenter] = useState(false);
  const [IsSearching, setIsSearching] = useState(false);
  const [KeyValue, SearchKey] = useState({ key: '', value: '' });
  const pageSize = 10;
  const dispatch = useDispatch();
  const location = useLocation();
  const { userDetails } = useSelector(state => state?.auth);
  const params = queryString.parse(location.search);
  const Size = ResponsiveComponent()
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [SearchList, setSearchList] = useState([]);
  const mergedArrow = useMemo(() => {
    if (arrowAtCenter)
      return {
        pointAtCenter: true,
      };
    return showArrow;
  }, [showArrow, arrowAtCenter]);
  const handleSearch = (selectedKeys, dataIndex) => {
    setSearchText(selectedKeys?.[0]);
    setSearchedColumn(dataIndex);
    const queryString1 = generateQueryString(['page', parseInt(params?.page) || 1], ['value', dataIndex === 'value' ? selectedKeys?.[0] : params?.value], ['key', dataIndex === 'key' ? selectedKeys?.[0] : params?.key]);
    if (queryString1) {
      window.location.replace(`/app-config${queryString1}`)
    }
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    window.location.replace(`/app-config`)
  }
  const matchPermissionsForUser = matchPermissions(userDetails);
  const canUpdate = matchPermissionsForUser('app-config.update');
  const canDelete = matchPermissionsForUser('app-config.delete');
  const canCerate = matchPermissionsForUser('app-config.create');
  const content = (item) => (
    <div className="">
      {canUpdate &&
        <Button className="m-2" onClick={() => EditList(item?.id)}>Edit</Button>
      }
      {canDelete &&
        <Button onClick={() => showDeleteConfirm(item)}>Delete</Button>
      }
    </div>
  );
  const column = [
    {
      title: "Sr",
      dataIndex: "sr",
      key: "Sr",
      render: (text, record, index) => {
        const currentPage = parseInt(params?.page) || 1;
        const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
        return <span>{calculatedIndex}</span>;
      },
    },
    {
      title: "Keys",
      dataIndex: "key",
      render: (text, record) => (
        <span>{text}</span>
      ),
      ...TableColumnSearch({ dataIndex: 'key', setSearchText, setSearchedColumn, handleReset, handleSearch, filtered: params?.key }),
      sorter: (a, b) => a.key.length - b.key.length,

    }, {
      title: "Values",
      dataIndex: "value",
      render: (text, record) => (
        <div className="app-config-boldText">{trimText(text, Size === "phone" || Size === "tablet" ? 20 : 100)}</div>
      ),
      ...TableColumnSearch({ dataIndex: 'value', setSearchText, setSearchedColumn, handleReset, handleSearch }),
    }, {
      title: !canUpdate && !canDelete ? null : "Action",
      dataIndex: "key",
      key: "key",
      render: (text, record, num) => {
        if (!canUpdate && !canDelete) {
          return null
        } else {
          return (
            <Popover placement="left" title={text} content={() => content(record)} arrow={mergedArrow}>
              <Button type="dashed">• • •</Button>
            </Popover>
          )
        }

      },
    },
  ];
  const Kyes = params?.registrationNo ? `&key=${params?.registrationNo}` : ''
  const Values = params?.value ? `&value=${params?.value}` : ''
  const Final = Kyes + Values || ''
  useEffect(() => {
    const queryString1 = generateQueryString(['page', parseInt(params?.page) || 1], ['value', params?.value], ['key', params?.key]);

    dispatch(ActionGetAppConfigList(queryString1));
    setRefresh(false);
  }, [Refresh, IsSearching])
  const handleSearch1 = (searchTerm) => {
    var newp = params
    newp.page = searchTerm
    window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
  }
  const EditList = (searchTerm) => {
    window.location.href = `/app-config/add-appconfig?update=true&&id=${searchTerm}&&page=${parseInt(params?.page)}`
  }
  const showDeleteConfirm = (item) => {
    confirm({
      title: `Are you sure delete this ${item?.key} Key?`,
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      zIndex: 1200,
      centered: true,
      onOk() {
        dispatch(ActionDeleteAppConfig("/" + item?.id, (Res) => {
          if (Res?.statusCode === 200) {
            setRefresh(true);
          }
        }))
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };
  const BreadcrumbList = [{
    name: 'App Config',
    linkTo: `/app-config?page=${params?.page || 1}`
  },
  {
    name: 'Add Config',
  }
  ]
  return (
    <TableListPageCom
      BreadcrumbList={BreadcrumbList}
      TableData={appConfig?.docs}
      TableColumn={column}
      CreateButtonLink="/app-config/add-appconfig"
      ShowAddButton={canCerate}
      PageMainTitle="App Configration List"
      CurrentPage={parseInt(params?.page) || 1}
      Tableloader={loader}
      refreshOnClick={() => { window.location.replace(`/app-config`) }}
      TotalPageList={appConfig?.totalDocs}
      dataSourceLength={appConfig?.docs?.length}
      handlePageChange={(v) => handleSearch1(v)}
    />);
};

export default AppConfig;
