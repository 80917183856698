/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { FormInput, SingleMultiSelectDropDown } from "../../../components/Input/InputText";
import { useDispatch, useSelector } from "react-redux";
import { AddEducationValidationSchema } from "../../../utils/FormValidation";
import { GetStaffProfileAction, UpdateStaffProfileAction } from "../../../store/Actions/StaffAction";
import { TextEditor } from "../../../components/Input/input";
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Space, Button } from 'antd';
const AddEducation = () => {
    let index = 0;

    const dispatch = useDispatch();
    const { RoleList } = useSelector(state => state?.Role);
    const url = new URL(window.location.href);
    const { pathname, searchParams } = url;
    const idValue = pathname.split('/').pop();
    const pageValue = searchParams.get("page");

    const { StaffProfile, loader } = useSelector(state => state?.Staff);
    const [items, setItems] = useState(['Add New One']);
    const [name, setName] = useState('');
    const inputRef = useRef(null);
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        setItems([...items, name || `New expertise ${index++}`]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };
    useEffect(() => {
        if (idValue) {
            dispatch(GetStaffProfileAction({ Query: idValue }));
        }
    }, [idValue]);
    return (
        <div>
            <Header />
            <Sidebar id="menu-item1" id1="menu-items1" />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/staff-list?page=1">Staff</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/staff-list?page=1">Staff List</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to={`/staff-list/staff-profile/${idValue}?page=${pageValue || 1}`}>Staff Profile</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Add Education</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* /Page Header */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Formik
                                            initialValues={{
                                                education: StaffProfile?.[0]?.education || "",
                                                expierence: StaffProfile?.[0]?.expierence || "",
                                                position: StaffProfile?.[0]?.position || "",
                                                carrer: StaffProfile?.[0]?.carrer || "",
                                                expertise: StaffProfile?.[0]?.expertise || [],
                                                description: StaffProfile?.[0]?.description || "",
                                            }}
                                            validationSchema={AddEducationValidationSchema}
                                            onSubmit={(values, { setErrors, setSubmitting }) => {
                                                dispatch(UpdateStaffProfileAction({
                                                    Query: idValue, obj: values
                                                }, (Res) => {
                                                    if (Res?.statusCode === 200) {
                                                        window.location.href = `/staff-list/staff-profile/${idValue}?page=1`
                                                    } else if (Res?.statusCode === 400) {
                                                        setErrors(Res?.data?.errors);
                                                    }
                                                    setSubmitting(false);
                                                }))
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                setFieldValue,
                                                setFieldTouched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                setValues,
                                                setSubmitting,
                                                isSubmitting,
                                                /* and other goodies */
                                            }) => {
                                                return (
                                                    <Form>
                                                        <div className="row">
                                                            <FormInput
                                                                label="Education"
                                                                type="education"
                                                                FormGroupClassName={"col-xl-6 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                name="education"
                                                                onChange={handleChange}
                                                                FormControlClassName={'form-control '}
                                                                onBlur={handleBlur}
                                                                error={errors?.education}
                                                                value={values?.education}
                                                                touched={touched?.education}
                                                                placeholder={'Enter Your Degree'}
                                                            />
                                                            <FormInput
                                                                label="Years of Experience"
                                                                type="text"
                                                                FormGroupClassName={"col-xl-6 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                name="expierence"
                                                                onChange={handleChange}
                                                                FormControlClassName={'form-control '}
                                                                onBlur={handleBlur}
                                                                error={errors?.expierence}
                                                                value={values?.expierence}
                                                                touched={touched?.expierence}
                                                                placeholder={'Enter Your Years of Experience'}
                                                            />

                                                            <FormInput
                                                                label="Position at Prakruti"
                                                                type="text"
                                                                FormGroupClassName={"col-xl-6 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                name="position"
                                                                onChange={handleChange}
                                                                FormControlClassName={'form-control '}
                                                                onBlur={handleBlur}
                                                                error={errors?.position}
                                                                value={values?.position}
                                                                touched={touched?.position}
                                                                placeholder={'Enter Your Position at Prakruti'}
                                                            />

                                                            <SingleMultiSelectDropDown
                                                                label="Expertise"
                                                                type="text"
                                                                Showlabel={true}
                                                                mode={'multiple'}
                                                                name="expertise"
                                                                onChange={(v) => { setFieldValue("expertise", v, true) }}
                                                                FormControlClassName={'form-control'}
                                                                FormGroupClassName={"col-xl-6 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                value={values?.expertise}
                                                                onBlur={() => { setFieldTouched("expertise", true, true) }}
                                                                error={errors?.expertise}
                                                                touched={touched?.expertise}
                                                                dropdownRender={(menu) => (
                                                                    <>
                                                                        {menu}
                                                                        <Divider
                                                                            style={{
                                                                                margin: '8px 0',
                                                                            }}
                                                                        />
                                                                        <Space
                                                                            style={{
                                                                                padding: '0 8px 4px',
                                                                            }}
                                                                        >
                                                                            <Input
                                                                                placeholder="Please enter expertise"
                                                                                ref={inputRef}
                                                                                value={name}
                                                                                onChange={onNameChange}
                                                                                onKeyDown={(e) => e.stopPropagation()}
                                                                            />
                                                                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                                                Add item
                                                                            </Button>
                                                                        </Space>
                                                                    </>
                                                                )}
                                                                onSearchLoading={false}
                                                                options={items}
                                                                placeholder={'Add Your Expertise'}
                                                            />

                                                            <div id="editor " >
                                                                <h5>carrer Highlights</h5>
                                                                <TextEditor
                                                                    value={values?.carrer}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        setFieldValue("carrer", data, true)
                                                                    }}
                                                                />
                                                            </div>
                                                            <div id="editor1" className="mt-4" >
                                                                <h5>Doctor Description</h5>
                                                                <TextEditor
                                                                    value={values?.description}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        if (data) {
                                                                            setFieldValue(`description`, data)
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-12 mt-5">
                                                                <div className="text-end">
                                                                    <Button
                                                                        onClick={() => {
                                                                            handleSubmit()
                                                                        }}
                                                                        type="primary"
                                                                        disabled={isSubmitting}
                                                                        className="pe-3"
                                                                    >
                                                                        Submit
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>)
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div >
    );
};

export default AddEducation;
