import { CREATE_PRAKRUTI_STANDARD, CREATE_PRAKRUTI_STANDARD_SUCCESS, CREATE_TREATMENT_PROTOCOL, CREATE_TREATMENT_PROTOCOL_SUCCESS, GET_ALL_PRAKRUTI_STANDARD, GET_ALL_PRAKRUTI_STANDARD_SUCCESS, GET_ALL_TREATMENT_PROTOCOL, GET_ALL_TREATMENT_PROTOCOL_SUCCESS, GET_ALL_TREATMENT_SERVICE_DATA, GET_ALL_TREATMENT_SERVICE_DATA_SUCCESS } from "../storeTypes";


const INIT_STATE = {
    loader: false,
    Prakrutistandardlist: {},
    Treatmentprotocollist: {},
    Treatmentservicelist: {},

};

function prakrutiStandardReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case CREATE_PRAKRUTI_STANDARD: {
            return {
                ...state,
                loader: true,
            };
        }
        case CREATE_PRAKRUTI_STANDARD_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }
        case GET_ALL_PRAKRUTI_STANDARD: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_PRAKRUTI_STANDARD_SUCCESS: {
            return {
                ...state,
                loader: false,
                Prakrutistandardlist: action?.payload
            };
        }
        case CREATE_TREATMENT_PROTOCOL: {
            return {
                ...state,
                loader: true,
            };
        }
        case CREATE_TREATMENT_PROTOCOL_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }
        case GET_ALL_TREATMENT_PROTOCOL: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_TREATMENT_PROTOCOL_SUCCESS: {
            return {
                ...state,
                loader: false,
                Treatmentprotocollist: action?.payload
            };
        }
        case GET_ALL_TREATMENT_SERVICE_DATA: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_TREATMENT_SERVICE_DATA_SUCCESS: {
            return {
                ...state,
                loader: false,
                Treatmentservicelist: action?.payload
            };
        }
        default: {
            return state;
        }
    }

}

export default prakrutiStandardReducer;