/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { FormInput } from "../../components/Input/InputText";
import { useDispatch, useSelector } from "react-redux";
import { PatientDetailsValidationSchema, appointmentDetailsValidationSchema } from "../../utils/FormValidation";
import { Button, Divider, DatePicker } from "antd";
import { profileAddSvg, profileSvg } from "../../components/imagepath";
import { ActionCreatePatient } from "../../store/Actions/PatientAction";
import { getAdminUser, getAllConsultation, getDrAvailabilityAction, getDrSlotAvailabilityAction } from "../../store/Actions/AppointmentAction";
import { InputFromUser } from "../../components/Input/input";
import moment from 'moment';
const { RangePicker } = DatePicker;

const AddAppointment = () => {

  const [userType, setUserType] = useState("new");
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const { AdminUser, AllConsultation, DrSlotAvailability, DrAvailability } = useSelector(state => state?.Appointment);
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  const userId = searchParams.get("userId");
  const stepvalue = searchParams.get("step");
  useEffect(() => {
    if (stepvalue === '2') {
      dispatch(getAdminUser({ Query: 'Doctor' }))
      dispatch(getAllConsultation({ Query: '' }))
    }
  }, [stepvalue])

  const disabledDate = current => {
    const today = moment();
    const endDate = moment().add(30, 'days');
    if (current && (current < today || current > endDate)) {
      return true;
    }
    return DrAvailability?.[0]?.disabledDates.includes(current.format("YYYY-MM-DD"));
  };
  return (
    <div>
      <Header />
      <Sidebar id="menu-item1" id1="menu-items1" activeClassName="add-staff" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/appoinment-list?page=1">Appointment </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Appointment</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="tab-content-set">
                      <ul className="nav">
                        <li>
                          <Link className={userType === "new" ? "active" : ''} onClick={(e) => {
                            e.preventDefault();
                            setUserType("new")
                          }}>
                            <span className="set-about-icon me-2">
                              <img src={profileAddSvg} alt />
                            </span>New Patient</Link>
                        </li>
                        <li>
                          <Link className={userType === "old" ? "active" : ''} onClick={(e) => {
                            e.preventDefault();
                            setUserType("old")
                          }}>
                            <span className="set-about-icon me-2">
                              <img src={profileSvg} alt />
                            </span>Old Patient</Link>
                        </li>
                      </ul>
                    </div>

                    {stepvalue === '1' ?
                      <Formik
                        initialValues={{
                          name: "",
                          email: "",
                          phoneNo: "",
                          isLogin: false,
                        }}
                        validationSchema={PatientDetailsValidationSchema}
                        onSubmit={(values, { setErrors, setSubmitting }) => {
                          dispatch(ActionCreatePatient({
                            Query: '', obj: values
                          }, (Res) => {
                            if (Res?.statusCode === 400) {
                              setErrors(Res?.data?.errors);
                            } else if (Res?.status === 200) {
                              window.location.replace(`/add-appoinment?page=1&step=${2}&userId=${Res?.data?.customer.data?.[0]?.id}`)
                            }

                            setSubmitting(false);
                          }))
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setValues,
                          setSubmitting,
                          isSubmitting,
                          /* and other goodies */
                        }) => {


                          return (
                            <Form>
                              <div className="row">
                                <div className="my-2" >
                                  <Divider style={{ fontWeight: "600" }}>Patient Details</Divider>
                                </div>
                                {(userType === 'old') && <FormInput
                                  label="Case Paper No."
                                  type="casePaperNo"
                                  FormGroupClassName={"col-xl-6 col-md-12"}
                                  LabelClassName={"col-form-label"}
                                  name="casePaperNo"
                                  onChange={handleChange}
                                  FormControlClassName={'form-control '}
                                  onBlur={handleBlur}
                                  error={errors.casePaperNo}
                                  value={values.casePaperNo}
                                  touched={touched.casePaperNo}
                                  placeholder={'Enter Your Case Paper No.'}
                                />}

                                <FormInput
                                  label="Name"
                                  type="name"
                                  FormGroupClassName={"col-xl-6 col-md-12"}
                                  LabelClassName={"col-form-label"}
                                  name="name"
                                  onChange={handleChange}
                                  FormControlClassName={'form-control '}
                                  onBlur={handleBlur}
                                  error={errors.name}
                                  value={values.name}
                                  touched={touched.name}
                                  placeholder={'Enter Your name'}
                                />

                                <FormInput
                                  label="email"
                                  type="email"
                                  FormGroupClassName={"col-xl-6 col-md-12"}
                                  LabelClassName={"col-form-label"}
                                  name="email"
                                  onChange={handleChange}
                                  FormControlClassName={'form-control '}
                                  onBlur={handleBlur}
                                  error={errors.email}
                                  value={values.email}
                                  touched={touched.email}
                                  placeholder={'Enter Your email'}
                                />

                                <FormInput
                                  label="Phone No"
                                  type="phoneNo"
                                  FormGroupClassName={"col-xl-6 col-md-12"}
                                  LabelClassName={"col-form-label"}
                                  name="phoneNo"
                                  onChange={handleChange}
                                  FormControlClassName={'form-control '}
                                  onBlur={handleBlur}
                                  error={errors.phoneNo}
                                  value={values.phoneNo}
                                  touched={touched.phoneNo}
                                  placeholder={'Enter Your phoneNo'}
                                />

                                <div className="col-12">
                                  <div className="text-end">
                                    <Button
                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                      type="primary"
                                      disabled={isSubmitting}
                                      className="pb-4"
                                    >Next</Button>
                                  </div>
                                </div>
                              </div>
                            </Form>)
                        }}
                      </Formik> :
                      // steps 2
                      <Formik
                        initialValues={{
                          casePaperNo: "23_12_2023",
                          consultationType: "",
                          selectYourDoctor: "",
                          consultingFor: "",
                          Time: "",
                          appointmentDate: "",
                          amount: "",
                        }}
                        validationSchema={appointmentDetailsValidationSchema}
                        onSubmit={(values, { setErrors, setSubmitting }) => {
                          console.log('values', {
                            "customerId": "string",
                            "treatment": "string",
                            "paymentStatus": "string",
                            "orderStatus": "string",
                            "paymentMode": "string",
                            "amount": "string",
                            "orderType": "string",
                            "type": "string",
                          });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setValues,
                          setSubmitting,
                          isSubmitting,
                          /* and other goodies */
                        }) => {
                          const drId = AdminUser?.[0]?.filter((doctor) => doctor.name === values.selectYourDoctor)?.[0]?._id
                          return (
                            <Form>
                              <div className="row">
                                <div className="my-4" >
                                  <Divider style={{ fontWeight: "600" }}>Appointment Details</Divider></div>
                                <InputFromUser
                                  type={'radio'}
                                  label={"Consultation Type"}
                                  options={[
                                    { label: 'Tele-Consultation', value: 'Tele-Consultation' },
                                    { label: 'In-Person', value: 'IN-Person' },
                                  ]}
                                  FormGroupClassName={"col-xl-6 col-md-12"}
                                  LabelClassName={"col-form-label"}
                                  onBlur={(v) => handleBlur({
                                    target: {
                                      name: 'consultationType',
                                      value: true,
                                    },
                                  })}
                                  onChange={(v) => {
                                    const DrId = AdminUser?.[0]?.filter((doctor) => doctor.name === values?.selectYourDoctor)?.[0]?._id
                                    if (DrId) {
                                      dispatch(getDrAvailabilityAction({ Query: `?doctor=${DrId}&consultationType=${v}`, obj: null }))
                                    }
                                    handleChange({
                                      target: {
                                        name: 'consultationType',
                                        value: v,
                                      },
                                    })
                                    if (((typeof values.appointmentDate === 'object') && (values?.consultingFor.length !== 0))) {
                                      dispatch(getDrSlotAvailabilityAction({
                                        Query: ``, obj: {
                                          doctor: drId,
                                          consultationType: values.consultationType,
                                          bookingDate: values.appointmentDate,
                                          consultationFor: values?.consultingFor
                                        }
                                      }))
                                    }
                                  }}
                                  error={errors.consultationType}
                                  selectedValue={values.consultationType}
                                  touched={touched.consultationType}
                                />

                                <InputFromUser
                                  label="Select Your Doctor"
                                  Showlabel={true}
                                  mode={''}
                                  type={'singleSelect'}
                                  name="selectYourDoctor"
                                  disabled={(values?.consultationType.length === 0)}
                                  onChange={(v) => {
                                    const DrId = AdminUser?.[0]?.filter((doctor) => doctor.name === v)?.[0]?._id
                                    setFieldValue("selectYourDoctor", v, true);
                                    dispatch(getDrAvailabilityAction({ Query: `?doctor=${DrId}&consultationType=${values.consultationType}`, obj: null }))
                                    if (((typeof values.appointmentDate === 'object') && (values?.consultingFor.length !== 0))) {
                                      dispatch(getDrSlotAvailabilityAction({
                                        Query: ``, obj: {
                                          doctor: drId,
                                          consultationType: values.consultationType,
                                          bookingDate: values.appointmentDate,
                                          consultationFor: values?.consultingFor
                                        }
                                      }))
                                    }
                                  }}
                                  FormControlClassName={'form-control'}
                                  FormGroupClassName={"col-xl-6 col-md-12"}
                                  LabelClassName={"col-form-label"}
                                  value={values?.selectYourDoctor}
                                  onBlur={() => { setFieldTouched("selectYourDoctor", true, true) }}
                                  error={errors?.selectYourDoctor}
                                  touched={touched?.selectYourDoctor}
                                  onSearchLoading={false}
                                  options={AdminUser?.[0] ? AdminUser?.[0]?.map((v, i) => v.name) : []}
                                  placeholder={'Select Your Doctor'}
                                />

                                <InputFromUser
                                  label="Consulting for"
                                  type="singleSelect"
                                  Showlabel={true}
                                  mode={''}
                                  name="consultingFor"
                                  onChange={(v) => {
                                    // setFieldValue("casePaperNo", "23_12_2023", true)
                                    setFieldValue("consultingFor", v, true)
                                    setFieldValue("amount", AllConsultation?.[0]?.filter((v) => v.consultationType === values.consultingFor)?.[0]?.price, true)
                                    if (((typeof values.appointmentDate === 'object') && (values?.consultingFor.length !== 0))) {
                                      dispatch(getDrSlotAvailabilityAction({
                                        Query: ``, obj: {
                                          doctor: drId,
                                          consultationType: values.consultationType,
                                          bookingDate: values.appointmentDate,
                                          consultationFor: values?.consultingFor
                                        }
                                      }))
                                    }
                                    if (((typeof values.appointmentDate === 'object') && (values?.consultingFor.length !== 0))) {
                                      dispatch(getDrSlotAvailabilityAction({
                                        Query: ``, obj: {
                                          doctor: drId,
                                          consultationType: values.consultationType,
                                          bookingDate: values.appointmentDate,
                                          consultationFor: values?.consultingFor
                                        }
                                      }))
                                    }
                                  }}
                                  FormControlClassName={'form-control'}
                                  FormGroupClassName={"col-xl-6 col-md-12"}
                                  LabelClassName={"col-form-label"}
                                  value={values?.consultingFor}
                                  onBlur={() => { setFieldTouched("consultingFor", true, true) }}
                                  error={errors?.consultingFor}
                                  touched={touched?.consultingFor}
                                  onSearchLoading={false}
                                  options={AllConsultation?.[0] ? AllConsultation?.[0]?.map((v, i) => v.consultationType) : []}
                                  placeholder={'Select Consulting For'}
                                />



                                <InputFromUser
                                  label="Date of Appointment"
                                  type="date"
                                  Showlabel={true}
                                  disabledDate={disabledDate}
                                  disabled={(typeof DrAvailability?.[0]?.disabledDates === 'object') ? (DrAvailability?.[0]?.disabledDates?.length === 0) : true}
                                  name="appointmentDate"
                                  onChange={(v) => {
                                    setFieldValue("appointmentDate", v, true)
                                    if (((typeof values.appointmentDate === 'object') && (values?.consultingFor.length !== 0))) {
                                      dispatch(getDrSlotAvailabilityAction({
                                        Query: ``, obj: {
                                          doctor: drId,
                                          consultationType: values.consultationType,
                                          bookingDate: values.appointmentDate,
                                          consultationFor: values?.consultingFor
                                        }
                                      }))
                                    }
                                  }}
                                  FormControlClassName={'form-control'}
                                  FormGroupClassName={"col-xl-6 col-md-12"}
                                  LabelClassName={"col-form-label"}
                                  value={values?.appointmentDate}
                                  onBlur={() => { setFieldTouched("appointmentDate", true, true) }}
                                  error={errors?.appointmentDate}
                                  touched={touched?.appointmentDate}
                                  onSearchLoading={false}
                                  placeholder={((typeof DrAvailability?.[0]?.disabledDates === 'object') ? (DrAvailability?.[0]?.disabledDates?.length === 0) : true) ?
                                    values.consultationType === "Tele-Consultation" ?
                                      'Doctor is Not available for Tele-Consultation consultation!!'
                                      : 'Doctor is Not available for IN-Person consultation!!'
                                    :
                                    'Select Date of Appointment'}
                                />
                                <InputFromUser
                                  label="Time Slot"
                                  Showlabel={true}
                                  mode={''}
                                  disabled={!((typeof values.appointmentDate === 'object') && !(values?.consultingFor.length === 0) &&
                                    !(Array.isArray(DrSlotAvailability) ? DrSlotAvailability.length === 0 : true))}
                                  type={'singleSelect'}
                                  name="appointmentDate"
                                  onChange={(v) => { setFieldValue("Time", v, true) }}
                                  FormControlClassName={'form-control'}
                                  FormGroupClassName={"col-xl-6 col-md-12"}
                                  LabelClassName={"col-form-label"}
                                  value={values?.Time}
                                  options={Array.isArray(DrSlotAvailability) ? DrSlotAvailability?.filter((slot) => !slot?.isBooked)?.map((slot) => slot?.startTime) : []}
                                  onBlur={() => { setFieldTouched("Time", true, true) }}
                                  error={errors?.Time}
                                  touched={touched?.Time}
                                  onSearchLoading={false}
                                  placeholder={'Select Consulting For'}
                                />

                                <InputFromUser
                                  type="text"
                                  Showlabel={true}
                                  label="Amount"
                                  FormControlClassName={'form-control'}
                                  FormGroupClassName={"col-xl-6 col-md-12"}
                                  LabelClassName={"col-form-label"}
                                  name="amount"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.amount}
                                  value={values.amount}
                                  disabled={true}
                                  touched={touched.amount}
                                  placeholder={''}
                                />

                                <div className="col-12">
                                  <div className="text-end">
                                    <Button
                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                      type="primary"
                                      disabled={isSubmitting}
                                      className="pb-4"
                                    >Pay</Button>
                                  </div>
                                </div>
                              </div>
                            </Form>)
                        }}
                      </Formik>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div >
  );
};

export default AddAppointment;
