import { GET_ALL_MEDICAL_HISTORY, GET_ALL_MEDICAL_HISTORY_SUCCESS } from "../storeTypes";


export const ActionGetAllMedicalHistory = (payload) => {

    return {
        type: GET_ALL_MEDICAL_HISTORY,
        payload
    }
}

export const ActionGetAllMedicalHistorySuccess = (payload) => {
    return {
        type: GET_ALL_MEDICAL_HISTORY_SUCCESS,
        payload
    }
}