import {
    CREATE_ORDER,
    GET_ALL_ORDER_LIST,
    GET_ALL_ORDER_LIST_SUCCESS,
    GET_ALL_PENDING_ORDER_LIST,
    GET_ALL_PENDING_ORDER_LIST_SUCCESS,
    GET_ORDER_BY_ID,
    GET_ORDER_BY_ID_SUCCESS,
} from "../storeTypes";

export const ActionGetAllOrderList = (payload) => {
    return {
        type: GET_ALL_ORDER_LIST,
        payload
    }
}
export const ActionGetAllOrderListSuccess = (payload) => {
    return {
        type: GET_ALL_ORDER_LIST_SUCCESS,
        payload
    }
}
export const ActionGetAllPendingOrderList = (payload) => {
    return {
        type: GET_ALL_PENDING_ORDER_LIST,
        payload
    }
}
export const ActionGetAllPendingOrderListSuccess = (payload) => {
    return {
        type: GET_ALL_PENDING_ORDER_LIST_SUCCESS,
        payload
    }
}
export const ActionCreateOrder = (payload, callBack) => {
    return {
        type: CREATE_ORDER,
        payload,
        callBack
    };
};

export const ActionGetOrderByID = (payload, callBack) => {
    return {
        type: GET_ORDER_BY_ID,
        payload,
        callBack
    };
};

export const ActionGetOrderByIDSuccess = (payload) => {
    return {
        type: GET_ORDER_BY_ID_SUCCESS,
        payload
    };
};
export const CancelOreder = (payload) => {
    return {
        type: GET_ORDER_BY_ID_SUCCESS,
        payload
    };
};