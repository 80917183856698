
import {
    GET_ALL_SERVICE_SLOT,
    GET_ALL_SERVICE_SLOT_SUCCESS,
    GET_SERVICE_SLOT_BY_ID,
    GET_SERVICE_SLOT_BY_ID_SUCCESS,
    UPDATE_SERVICE_SLOT,
} from "../storeTypes";

//service slot

export const GetAllServiceSlot = (payload) => {
    return {
        type: GET_ALL_SERVICE_SLOT,
        payload,
    }
};

export const GetAllServiceSlotSuccess = (payload) => {
    return {
        type: GET_ALL_SERVICE_SLOT_SUCCESS,
        payload,
    }
};

export const ActionUpdateServiceSlot = (payload, callBack) => {
    return {
        type: UPDATE_SERVICE_SLOT,
        payload,
        callBack
    };
};

export const ActionGetServiceSlotById = (payload, callBack) => {
    return {
        type: GET_SERVICE_SLOT_BY_ID,
        payload,
        callBack
    };
};

export const ActionGetServiceSlotByIdSuccess = (payload) => {
    return {
        type: GET_SERVICE_SLOT_BY_ID_SUCCESS,
        payload
    };
};
