import { call, put, takeEvery } from 'redux-saga/effects';
import { CreateHolidayApi, CreateStaffApi, DeleteHolidayApi, GetHolidayByIdApi, GetHolidayListApi, GetStaffListApi, GetStaffProfileApi, TherapistScheduleApi, TherapistShiftApi, UpdateHolidayApi, UpdateStaffProfileApi, getAllCaretakerFoodPriceApi, getLeavesApi } from '../../utils/api';
import { ActionGetFoodpriceListSuccess, ActionGetHolidayByIDSuccess, ActionGetLeavesListSuccess, ActionGetShiftsListSuccess, GetHolidayListSuccess, GetStaffListSuccess, GetStaffProfileActionSuccess, GetTherapistScheduleActionSuccess } from '../Actions/StaffAction';
import { CREATE_HOLIDAY, CREATE_STAFF, DELETE_HOLIDAY, GET_ALL_FOODPRICE_LIST, GET_HOLIDAY_BY_ID, GET_HOLIDAY_LIST, GET_LEAVES, GET_STAFF_LIST, GET_STAFF_PROFILE, GET_THERAPIST_SCHEDULE, GET_THERAPIST_SHIFTS, UPDATE_HOLIDAY, UPDATE_STAFF_PROFILE } from '../storeTypes';
import { handleApiResponse } from '../../utils/Funcations';

const File = 'StaffSaga '

function* CreateStaffSaga(action) {

    try {
        const response = yield call(CreateStaffApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'CreateStaffSaga Error :- ', error);
    }
}

function* GetStaffListSaga(action) {
    try {
        const response = yield call(GetStaffListApi, action.payload);
        if (response?.status === 200) {
            yield put(GetStaffListSuccess(response?.data?.data));
        }
    } catch (error) {
        // if (error?.response?.status === 400) {
        //     action?.callBack(error?.response?.data);
        // }
        console.log(File + 'GetStaffListSaga Error :- ', error);
    }
}

function* GetStaffProfileSaga(action) {
    try {
        const response = yield call(GetStaffProfileApi, action.payload);
        if (response?.status === 200) {
            yield put(GetStaffProfileActionSuccess(response?.data?.data));
        }
    } catch (error) {
        // if (error?.response?.status === 400) {
        //     action?.callBack(error?.response?.data);
        // }
        console.log(File + 'GetStaffProfileSaga Error :- ', error);
    }
}

function* UpdateStaffProfileSaga(action) {
    try {
        const response = yield call(UpdateStaffProfileApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'GetStaffProfileSaga Error :- ', error);
    }
}

function* GetHolidayListSaga(action) {
    try {
        const response = yield call(GetHolidayListApi, action.payload);
        if (response?.status === 200) {
            yield put(GetHolidayListSuccess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'GetHolidayListSaga Error :- ', error);
    }
}
function* CreateHolidaySaga(action) {
    try {
        const response = yield call(CreateHolidayApi, action.payload);
        if (response?.status === 200) {
            action?.callback(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callback(error?.response?.data);
        }
        console.log(File + 'CreateHolidaySaga Error :- ', error);
    }
}

function* UpdateHolidaySaga(action) {
    try {
        const response = yield call(UpdateHolidayApi, action.payload);
        if (response?.status === 200) {

            action?.callBack(response?.data);
        }
    } catch (error) {
        console.log(File + '/UpdateHolidaySaga Error:', error);

        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
    }
}

function* GetHolidayByIDSaga(action) {
    try {
        const response = yield call(GetHolidayByIdApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetHolidayByIDSuccess(response?.data?.data?.[0]));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File + '/GetHolidayByIDSaga Error:', error);
    }
}

function* DeleteHolidaySaga(action) {
    try {
        const response = yield call(DeleteHolidayApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(200);
        }
    } catch (error) {
        console.log(File + '/DeleteHolidaySaga Error:', error);
    }
}
function* TherapistScheduleSaga(action) {
    try {
        const response = yield call(TherapistScheduleApi, action.payload);
        if (response?.status === 200) {
            yield put(GetTherapistScheduleActionSuccess(response?.data?.data));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
    }
}
function* GetShiftsListSaga(action) {
    try {
        const response = yield call(TherapistShiftApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetShiftsListSuccess(response?.data?.data));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
    }
}
function* GetLeavesListSaga(action) {
    try {
        const response = yield call(getLeavesApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetLeavesListSuccess(response?.data?.data));
        } else {
            action?.callBack(response?.data?.data);
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
    }
}
function* GetFoodpriceListSaga(action) {
    try {
        const response = yield call(getAllCaretakerFoodPriceApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetFoodpriceListSuccess(response?.data?.data));
        }
    } catch (error) {
        // if (error?.response?.status === 400) {
        //     action?.callBack(error?.response?.data);
        // }
        console.log(File + 'GetFoodpriceListSaga Error :- ', error);
    }
}

export function* StaffSaga() {
    [
        yield takeEvery(CREATE_STAFF, CreateStaffSaga),
        yield takeEvery(GET_STAFF_LIST, GetStaffListSaga),
        yield takeEvery(GET_STAFF_PROFILE, GetStaffProfileSaga),
        yield takeEvery(UPDATE_STAFF_PROFILE, UpdateStaffProfileSaga),
        yield takeEvery(GET_HOLIDAY_LIST, GetHolidayListSaga),
        yield takeEvery(CREATE_HOLIDAY, CreateHolidaySaga),
        yield takeEvery(UPDATE_HOLIDAY, UpdateHolidaySaga),
        yield takeEvery(GET_HOLIDAY_BY_ID, GetHolidayByIDSaga),
        yield takeEvery(DELETE_HOLIDAY, DeleteHolidaySaga),
        yield takeEvery(GET_THERAPIST_SCHEDULE, TherapistScheduleSaga),
        yield takeEvery(GET_THERAPIST_SHIFTS, GetShiftsListSaga),
        yield takeEvery(GET_LEAVES, GetLeavesListSaga),
        yield takeEvery(GET_ALL_FOODPRICE_LIST, GetFoodpriceListSaga),
    ];
}
