/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ActionGetPatientList } from "../../store/Actions/PatientAction";
import queryString from "query-string";
import moment from 'moment';
import { Button } from "antd";
import TableColumnSearch from '../../components/Input/TableColumnSearch';
import { QueryParams } from "../../utils/Funcations";
import { TableListPageCom } from "../../components/TableListPageCom";
import { Check, X } from "react-feather";

const PatientList = () => {
    const pageSize = 10;
    const dispatch = useDispatch();
    const QueryParamsVlaue = QueryParams()
    const [Refresh, setRefresh] = useState(false);
    const { patientList, loader } = useSelector(state => state?.patient);
    const { userDetails } = useSelector(state => state?.auth);
    const params = queryString.parse(location.search);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const handleSearch = (selectedKeys, dataIndex) => {
        setSearchText(selectedKeys?.[0]);
        setSearchedColumn(dataIndex);
        if (params[dataIndex]) {
            window.location.replace(`patient-list/?page=1&filterlist=true&${dataIndex}=${selectedKeys?.[0]}`)
        } else {
            window.location.replace(`patient-list?${location.search}&${dataIndex}=${selectedKeys?.[0]}`)
        }
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        window.location.replace(`?page=1`)
    }
    const registrationNo = params?.registrationNo ? `&registrationNo=${params?.registrationNo}` : ''
    const name = params?.name ? `&name=${params?.name}` : ''
    const phoneNo = params?.phoneNo ? `&phoneNo=${params?.phoneNo}` : ''
    const Final = registrationNo + name + phoneNo || ''
    useEffect(() => {
        dispatch(ActionGetPatientList({ Query: `?page=${params?.page || 1}${Final}`, }));
        setRefresh(false);
    }, [Refresh, searchText])

    // const matchPermissionsForUser = matchPermissions(userDetails);
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "New Case Paper",
            dataIndex: "registrationNo",
            ...TableColumnSearch({ dataIndex: 'registrationNo', setSearchText, setSearchedColumn, handleReset, handleSearch }),
            sorter: (a, b) => a.registrationNo.length - b.registrationNo.length,
            render: (text, record, index) => {
                return (
                    <Link to={`/patient-list/patient-profile?patientId=${record?._id}&page=${params?.page}`}>{text}</Link>
                )
            }
        }, {
            title: "Old Case Paper",
            dataIndex: "oldCasePaperNo",
            ...TableColumnSearch({ dataIndex: 'oldCasePaperNo', setSearchText, setSearchedColumn, handleReset, handleSearch }),
            sorter: (a, b) => a.oldCasePaperNo.length - b.oldCasePaperNo.length,
            render: (text, record, index) => {
                return (
                    <Link to={`/patient-list/patient-profile?patientId=${record?._id}&page=${params?.page}`}>{text || '---'}</Link>
                )
            }
        },
        {
            title: "Is MemberShip",
            dataIndex: "isMemberShip",
            render: (text, record, index) => {
                return text ? <Check color="green" size={24} /> : <X color="red" size={24} />;
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            ...TableColumnSearch({ dataIndex: 'name', setSearchText, setSearchedColumn, handleReset, handleSearch }),
            sorter: (a, b) => a.name.length - b.name.length,
        },

        {
            title: "Age",
            dataIndex: "age",
        },
        {
            title: "Phone No",
            dataIndex: "phoneNo",
            ...TableColumnSearch({ dataIndex: 'phoneNo', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },
        {
            title: "Registration Date",
            dataIndex: "registrationDate",
            render: (text, record, index) => {
                return <span>{(text && moment(text).format("DD-MM-YYYY") || '---')}</span>;
            },
        },
        {
            title: "Actions",
            dataIndex: "",
            render: (text, record, index) => {
                return <Button type="primary" onClick={() => {
                    window.location.href = `/20minform?id=${record.id}&&update=true`
                }}>Fill Form</Button>
            },
        },

    ]


    const BreadcrumbList = [{
        name: 'Patient',
        linkTo: `/patient-list?page=${params?.page || 1}`
    },
    {
        name: 'Patient List',
    }
    ]
    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={patientList ? patientList?.docs : []}
            TableColumn={columns}
            ShowAddButton={true}
            Iscreate="patient.create"
            CreateButtonLink={`/patient-list/create-patient?update=false&&page=${params?.page || 1}`}
            PageMainTitle="Patient List"
            CurrentPage={patientList?.page || 1}
            Tableloader={loader}
            TotalPageList={patientList?.totalDocs}
            dataSourceLength={patientList?.docs?.length}
            handlePageChange={(v) => {
                var newp = params
                newp.page = v
                window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
            }}
        />
    )
};

export default PatientList;
