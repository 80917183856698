/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useLocation } from 'react-router-dom';
import { Formik } from "formik";
import { SingleMultiSelectDropDown } from "../../../components/Input/InputText";
import { Button, Checkbox, DatePicker, Divider, InputNumber, Modal, Select } from "antd";
import { ActionCreatePatient, ActionGetMedicineList, ActionGetlaboratory } from "../../../store/Actions/PatientAction";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { Options } from "../../../utils/Data";
import { LabInvestigationComponent } from "../../LaboratoryInvestigation";
import { InputFromUser } from "../../../components/Input/input";
import PhoneCode from "../../../assets/json/CountryDialCode.json";
import { ConsultationMasterDataAction } from "../../../store/Actions/AppointmentAction";
import { PatientValidationSchema } from "../../../utils/FormValidation";
const CheckboxGroup = Checkbox.Group;

const AddPatient = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = queryString.parse(location.search);
    const { medicineList, loader, laboratory } = useSelector(state => state?.patient);

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const { ConsultationMasterData, AllAppoinment } = useSelector(state => state?.Appointment);
    const { Option } = Select;
    const selectBefore = (onChange) => {
        return (
            <Select
                defaultValue="+91"
                showSearch
                onChange={onChange}
                style={{
                    width: 110,
                }}
            >{PhoneCode.map((value, index) => {
                return (<Option key={index + 'code'} value={value.dial_code}>{value.emoji} {value.dial_code}</Option>)
            })}
            </Select>
        )
    };
    useEffect(() => {
        dispatch(ActionGetMedicineList({ Query: '' }))
        dispatch(ActionGetlaboratory())
        dispatch(ConsultationMasterDataAction({ Query: '' }))
    }, [])
    const [modal, SetModal] = useState(false);

    const [isDaysSet, setDaysSet] = useState(false);
    const [courseDuration, setCourseDuration] = useState(false);
    return <>
        <Header />
        <Sidebar />
        <>
            <div>
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to={`/patient-list?update=false&&page=${params?.page || 1}`}>Patient</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to={`/patient-list?update=false&&page=${params?.page || 1}`}>Patient List</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Add Patient</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* /Page Header */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card p-3">
                                    <div className="card-body">
                                        <Formik
                                            initialValues={{
                                                name: "",
                                                email: "",
                                                phoneNo: "",
                                                countryCode: "+91",
                                                oldCasePaperNo: "",
                                                doctorNote: "",
                                                laboratoryNote: "",
                                                gender: "",
                                                age: 0,
                                                birthDate: null,
                                                isOldCasePaperVerifiedis: true,
                                                nextConsultationDate: null,
                                                medicines: [
                                                    { "medicineName": "", "days": null, "whenToTake": [], 'anupan': '', 'howMuch': '' }
                                                ],
                                                rootCause: [],
                                                diagnosis: [
                                                    { "diagnosis": "", "other": "" }
                                                ],
                                                jivha: [],
                                                laboratory: [],
                                                naad: [
                                                    { "Yakrut": [] },
                                                    { "Pliha": [] }
                                                ],
                                                sparshaSahatva: [
                                                    { "yakrut": [] },
                                                    { "pleeha": [] },
                                                    { "dakshiVruka": [] },
                                                    { "vaamVruka": [] },
                                                    { "apanKaksha": [] },
                                                    { "dakshiVruka": [] }
                                                ],

                                                treatment: [
                                                    { "treatmentName": "", "other": "", "day": "" }
                                                ],
                                                solutions: [
                                                    { "solutionName": "", "other": "", "day": "" }
                                                ],
                                                do: [],
                                                dont: [],
                                                virechan: [],
                                                exercise: [],
                                                upshay: "",
                                                anupshay: "",
                                            }}

                                            validationSchema={PatientValidationSchema}
                                            onSubmit={(values, { setSubmitting, setErrors }) => {
                                                function calculateAge(birthDate) {
                                                    const today = new Date();
                                                    const dob = new Date(birthDate);
                                                    let age = today.getFullYear() - dob.getFullYear();
                                                    const monthDiff = today.getMonth() - dob.getMonth();
                                                    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
                                                        age--;
                                                    }
                                                    return age;
                                                }
                                                function cleanObject(obj) {
                                                    for (const key in obj) {
                                                        if (obj[key] === "" || obj[key] === null || obj[key] === undefined || (Array.isArray(obj[key]) && obj[key].length === 0)) {
                                                            delete obj[key];
                                                        } else if (typeof obj[key] === "object") {
                                                            cleanObject(obj[key]); // Recursive call for nested objects

                                                            // Check if the object is an array of objects
                                                            if (Array.isArray(obj[key])) {
                                                                obj[key] = obj[key].filter(item => Object.keys(item).length > 0);
                                                                if (obj[key].length === 0) {
                                                                    delete obj[key];
                                                                }
                                                            }
                                                        }
                                                    }
                                                    return obj;
                                                }
                                                const age = calculateAge(values?.birthDate);
                                                let cleanedData = cleanObject(values);
                                                let WithIdValues = { ...cleanedData, "age": age }
                                                if (cleanedData?.do) {
                                                    const dosWithId = (ConsultationMasterData?.doAndDonts || [])
                                                        .filter(item => Array.isArray(cleanedData?.do) && cleanedData.do.includes(item.title))
                                                        .map(({ id, title }) => ({ id, title }));
                                                    WithIdValues = { ...WithIdValues, do: dosWithId }
                                                }
                                                if (cleanedData?.exercise) {
                                                    const exercisesWithId = (ConsultationMasterData?.exercises || [])
                                                        .filter(item => Array.isArray(cleanedData?.exercise) && cleanedData.exercise.includes(item.title))
                                                        .map(({ id, title }) => ({ id, title }));
                                                    WithIdValues = { ...WithIdValues, exercise: exercisesWithId }

                                                }

                                                if (cleanedData?.virechan) {
                                                    const virechansWithId = (ConsultationMasterData?.virechans || [])
                                                        .filter(item => Array.isArray(cleanedData?.virechan) && cleanedData.virechan.includes(item.title))
                                                        .map(({ id, title }) => ({ id, title }));
                                                    WithIdValues = { ...WithIdValues, virechan: virechansWithId }

                                                }
                                                if (cleanedData?.dont) {
                                                    const dontsWithId = (ConsultationMasterData?.doAndDonts || [])
                                                        .filter(item => Array.isArray(cleanedData?.dont) && cleanedData.dont.includes(item.title))
                                                        .map(({ id, title }) => ({ id, title }));
                                                    WithIdValues = { ...WithIdValues, dont: dontsWithId }

                                                }
                                                dispatch(ActionCreatePatient({
                                                    Query: '/register_patient', obj: WithIdValues,
                                                }, (Res) => {
                                                    if (Res?.data?.statusCode === 200) {
                                                        window.location.href = `/patient-list?update=false&&page=${params?.page || 1}`
                                                    } else if (Res?.statusCode === 400) {
                                                        window.scrollTo({
                                                            top: 10,
                                                            behavior: 'smooth',
                                                        });
                                                        setErrors(Res?.data?.errors);
                                                    }
                                                    setSubmitting(false);

                                                }));
                                            }
                                            }

                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                setFieldValue,
                                                setFieldTouched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                setValues,
                                                isSubmitting,
                                            }) => {
                                                return (
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="row card-box">
                                                                    <InputFromUser
                                                                        label="Case No"
                                                                        type="text"
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        name="oldCasePaperNo"
                                                                        onChange={handleChange}
                                                                        FormControlClassName={'form-control'}
                                                                        onBlur={handleBlur}
                                                                        error={errors.oldCasePaperNo}
                                                                        value={values.oldCasePaperNo}
                                                                        touched={touched.oldCasePaperNo}
                                                                        placeholder={'Enter Case paper No (like :- 00/00/00)'}
                                                                    />
                                                                    <InputFromUser
                                                                        label="Name"
                                                                        type="text"
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        name="name"
                                                                        onChange={handleChange}
                                                                        FormControlClassName={'form-control '}
                                                                        onBlur={handleBlur}
                                                                        error={errors.name}
                                                                        value={values.name}
                                                                        touched={touched.name}
                                                                        placeholder={'Enter Your name'}
                                                                    />
                                                                    <InputFromUser
                                                                        label="Email"
                                                                        type="email"
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        FormControlClassName={'form-control'}
                                                                        name="email"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={errors.email}
                                                                        value={values.email}
                                                                        touched={touched.email}
                                                                        placeholder={'Enter Your email'}
                                                                    />

                                                                    <div className="col-xl-6 col-md-12 " style={{ marginBottom: 5 }}>
                                                                        <label className="col-form-label" style={{ color: errors.phoneNo && touched.phoneNo && 'red' }} >
                                                                            Phone No
                                                                            {errors.phoneNo && <span className="login-danger"> *</span>}
                                                                        </label>
                                                                        <div className="mb-3">
                                                                            <InputNumber
                                                                                size="large"
                                                                                addonBefore={selectBefore((v) => {
                                                                                    setFieldValue('countryCode', v, true)
                                                                                })}
                                                                                className="d-flex "
                                                                                onChange={(value) => {
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'phoneNo',
                                                                                            value,
                                                                                        },
                                                                                    });
                                                                                }}
                                                                                onBlur={(value) => {
                                                                                    handleBlur({
                                                                                        target: {
                                                                                            name: 'phoneNo',
                                                                                            value: true,
                                                                                        },
                                                                                    });
                                                                                }}
                                                                                min={10}
                                                                                value={values.phoneNo}
                                                                                style={{
                                                                                    height: 43, width: '100%', flex: 1
                                                                                }}
                                                                                status={errors.phoneNo && touched.phoneNo && 'error'}
                                                                                placeholder={'Enter Your Phone No'}
                                                                            />
                                                                            {errors.phoneNo && touched.phoneNo && < div className="text-danger m-1 ">{errors.phoneNo}</div>}
                                                                        </div>
                                                                    </div>




                                                                    <InputFromUser
                                                                        label="Gender"
                                                                        type="radio"
                                                                        name="gender"
                                                                        onChange={(value) => {
                                                                            handleChange({
                                                                                target: {
                                                                                    name: 'gender',
                                                                                    value,
                                                                                },
                                                                            });
                                                                        }}
                                                                        onBlur={(value) => {
                                                                            handleBlur({
                                                                                target: {
                                                                                    name: 'gender',
                                                                                    value: true,
                                                                                },
                                                                            });
                                                                        }}
                                                                        FormControlClassName={'form-control'}
                                                                        FormGroupClassName={"col-xl-6 col-md-12 "}
                                                                        LabelClassName={"col-form-label"}
                                                                        value={values.gender}
                                                                        selectedValue={values.gender}
                                                                        error={errors.gender}
                                                                        touched={touched.gender}
                                                                        options={[
                                                                            { label: 'Male', value: 'Male' },
                                                                            { label: 'Female', value: 'Female' },
                                                                            { label: 'Others', value: 'Others' },
                                                                        ]}
                                                                        placeholder={'Gender'}
                                                                    />

                                                                    <InputFromUser
                                                                        label="Birth Date"
                                                                        type="date"
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        FormControlClassName={'form-control'}
                                                                        name="birthDate"
                                                                        onChange={(v) => handleChange({
                                                                            target: {
                                                                                name: "birthDate",
                                                                                value: v
                                                                            }
                                                                        })} onBlur={handleBlur}
                                                                        error={errors.birthDate}
                                                                        value={values.birthDate}
                                                                        touched={touched.birthDate}
                                                                        placeholder={'Enter Your birth Date'}
                                                                    />

                                                                    {/* Upshay Anupshay */}
                                                                    <Divider />
                                                                    <InputFromUser
                                                                        placeholder={"Enter Your Upshay"}
                                                                        type="singleSelect"
                                                                        options={Options.Upshay}
                                                                        name="upshay"
                                                                        onChange={(v) => handleChange({
                                                                            target: {
                                                                                name: "upshay",
                                                                                value: v
                                                                            }
                                                                        })}
                                                                        value={values.upshay}
                                                                        FormGroupClassName={"col-xl-6 col-md-7 "}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={handleBlur}
                                                                        label="Upshay"
                                                                        error={errors.upshay}
                                                                        touched={touched.upshay}
                                                                    />
                                                                    <InputFromUser
                                                                        placeholder={"Enter Your Anupshay"}
                                                                        type="singleSelect"
                                                                        options={Options.Anupshay}
                                                                        name="anupshay"
                                                                        onChange={(v) => handleChange({
                                                                            target: {
                                                                                name: "anupshay",
                                                                                value: v
                                                                            }
                                                                        })}
                                                                        value={values.anupshay}
                                                                        FormGroupClassName={"col-xl-6 col-md-7"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={handleBlur}
                                                                        label="Anupshay"
                                                                        error={errors.anupshay}
                                                                        touched={touched.anupshay}
                                                                    />
                                                                    {/* Upshay Anupshay end*/}

                                                                    {/* Examination  */}
                                                                    <Divider>
                                                                        <div className="m-3">
                                                                            <h3 className="">Examination</h3>
                                                                        </div></Divider>
                                                                    <InputFromUser
                                                                        placeholder={"Enter Your Jiva"}
                                                                        type="multiple"
                                                                        name="jivha"
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Jiva"
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        onChange={(v) => handleChange({
                                                                            target: {
                                                                                name: "jivha",
                                                                                value: v
                                                                            }
                                                                        })}
                                                                        value={values.jivha}
                                                                        FormControlClassName={'form-control'}
                                                                        options={Options.Jivha}
                                                                        error={errors.jivha}
                                                                        touched={touched.jivha}
                                                                    />
                                                                    <InputFromUser
                                                                        placeholder={"Enter Your Nails"}
                                                                        type="singleSelect"
                                                                        name="nails"
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Nails"
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        onChange={(v) => handleChange({
                                                                            target: {
                                                                                name: "nails",
                                                                                value: v
                                                                            }
                                                                        })}
                                                                        value={values.nails}
                                                                        FormControlClassName={'form-control'}
                                                                        options={Options.Nails}
                                                                        error={errors.nails}
                                                                        touched={touched.nails}
                                                                    />
                                                                    <InputFromUser
                                                                        placeholder={"Enter Your Eyes"}
                                                                        type="singleSelect"
                                                                        name="eyes"
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Eyes"
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        onChange={(v) => handleChange({
                                                                            target: {
                                                                                name: "eyes",
                                                                                value: v
                                                                            }
                                                                        })}
                                                                        value={values.eyes}
                                                                        FormControlClassName={'form-control'}
                                                                        options={Options.Eyes}
                                                                        error={errors.eyes}
                                                                        touched={touched.eyes}
                                                                    />
                                                                    <InputFromUser
                                                                        placeholder={"Enter Your Prakruti"}
                                                                        type="multiple"
                                                                        name="prakruti"
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Prakruti"
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        onChange={(v) => handleChange({
                                                                            target: {
                                                                                name: "prakruti",
                                                                                value: v
                                                                            }
                                                                        })}
                                                                        value={values.prakruti}
                                                                        FormControlClassName={'form-control'}
                                                                        options={Options.Prakruti}
                                                                        error={errors.prakruti}
                                                                        touched={touched.prakruti}
                                                                    />
                                                                    <InputFromUser
                                                                        placeholder={"Enter Your Nadi"}
                                                                        type="multiple"
                                                                        name="nadi"
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Nadi"
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        onChange={(v) => handleChange({
                                                                            target: {
                                                                                name: "nadi",
                                                                                value: v
                                                                            }
                                                                        })} value={values.nadi}
                                                                        FormControlClassName={'form-control'}
                                                                        options={Options.Nadi}
                                                                        error={errors.nadi}
                                                                        touched={touched.nadi}
                                                                    />
                                                                    {/* Examination  end */}

                                                                    {/* Nada */}
                                                                    <Divider orientation="center">
                                                                        <h3 className="m-4">Nada</h3>
                                                                    </Divider>
                                                                    {values?.naad?.map((item, i) => {
                                                                        let NewValue = { ...values };
                                                                        const NameV = Object.keys(item)?.[0];
                                                                        return (
                                                                            <SingleMultiSelectDropDown
                                                                                key={i + NameV}
                                                                                label={Object.keys(item)?.[0]}
                                                                                type="yakrut"
                                                                                FormGroupClassName={"col-xl-6 col-md-12"}
                                                                                LabelClassName={"col-form-label"}
                                                                                name={'yakrut'}
                                                                                onChange={(value) => {
                                                                                    NewValue.naad[i] = {
                                                                                        [NameV]: value
                                                                                    };
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: "naad",
                                                                                            value: NewValue.naad
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                FormControlClassName={'form-control'}
                                                                                onBlur={(value) => { }}
                                                                                error={typeof errors.naad === "string" ? errors.naad :
                                                                                    errors.naad?.find((e) => e?.[Object.keys(item)?.[0]])?.[Object.keys(item)?.[0]] || ""}
                                                                                value={item?.[NameV]}
                                                                                options={NameV === 'Yakrut' ? Options.Nada.Yakrut : Options.Nada.Pleeha}
                                                                                touched={true}
                                                                                placeholder={`Enter Your ${NameV === 'Yakrut' ? "Yakrut" : "Pliha"}`} />
                                                                        )
                                                                    })}
                                                                    {/* Nada end */}

                                                                    {/* Sparsha Sahatva */}
                                                                    <Divider orientation="center">
                                                                        <h3 className="m-4">Sparsha Sahatva</h3>
                                                                    </Divider>
                                                                    {values?.sparshaSahatva?.map((item, i) => {
                                                                        let NewValue = { ...values };
                                                                        const NameV = Object.keys(item)?.[0];
                                                                        return (
                                                                            <InputFromUser
                                                                                key={i + NameV}
                                                                                label={NameV}
                                                                                type="singleSelect"
                                                                                FormGroupClassName={"col-xl-6 col-md-12"}
                                                                                LabelClassName={"col-form-label"}
                                                                                name={'sparshaSahatva'}
                                                                                onChange={(value) => {
                                                                                    NewValue.sparshaSahatva[i] = {
                                                                                        [NameV]: value
                                                                                    };
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: "sparshaSahatva",
                                                                                            value: NewValue.sparshaSahatva
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                FormControlClassName={'form-control'}
                                                                                onBlur={(value) => { }}
                                                                                error={typeof errors.sparshaSahatva === "string" ? errors.sparshaSahatva :
                                                                                    errors.sparshaSahatva?.find((e) => e?.[Object.keys(item)?.[0]])?.[Object.keys(item)?.[0]] || ""}
                                                                                value={item?.[NameV]}
                                                                                options={NameV === 'yakrut' ? Options.SparshasahatvaSubOptions.Yakrut :
                                                                                    NameV === 'pleeha' ? Options.SparshasahatvaSubOptions.Pleeha :
                                                                                        NameV === 'dakshiVruka' ? Options.SparshasahatvaSubOptions.DakshiVruka :
                                                                                            NameV === 'vaamVruka' ? Options.SparshasahatvaSubOptions.VaamVruka :
                                                                                                NameV === 'apanKaksha' ? Options.SparshasahatvaSubOptions.ApanKaksha : []}
                                                                                touched={true}
                                                                                placeholder={`Enter Your ${NameV}`} />
                                                                        )
                                                                    })}
                                                                    {/* Sparsha Sahatva end */}

                                                                    {/* Laboratory  */}
                                                                    <div className="col-xl-12 col-md-12">
                                                                        <div className="form-group" >
                                                                            <label className="col-form-label" style={{ color: errors.doctorNote && touched.doctorNote && 'red' }} >
                                                                            </label>
                                                                            <Button className="form-control"
                                                                                onClick={() => SetModal(true)}>Laboratory Investigations</Button>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-12">
                                                                        <Modal
                                                                            open={modal}
                                                                            closable={true}
                                                                            className='ant-modal'
                                                                            forceRender={true}
                                                                            zIndex={1050}
                                                                            style={{ margin: 10 }}
                                                                            onOk={() => SetModal(false)}
                                                                            styles={{
                                                                                body: {
                                                                                    overflow: "hidden",
                                                                                },
                                                                                mask: {
                                                                                    backdropFilter: 'blur(10px)',
                                                                                },
                                                                                content: {
                                                                                    borderRadius: 40,
                                                                                },
                                                                            }}
                                                                            okText={'Submit'}
                                                                            centered
                                                                            confirmLoading={false}
                                                                            onCancel={() => SetModal(false)}
                                                                            closeIcon={true}
                                                                        >
                                                                            <LabInvestigationComponent
                                                                                errors={errors}
                                                                                touched={touched}
                                                                                values={values}
                                                                                name={'laboratory'}
                                                                                laboratory={laboratory || []}
                                                                                setValues={setValues}
                                                                                handleChange={handleChange}
                                                                                handleBlur={handleBlur}
                                                                            />

                                                                        </Modal>
                                                                    </div>

                                                                    {/* Laboratory  end*/}

                                                                    {/* Hetu /Diagnosis */}
                                                                    <div className="col-xl-6">
                                                                        <SingleMultiSelectDropDown
                                                                            label="Hetu"
                                                                            type="rootCause"
                                                                            FormGroupClassName={"col-xl-12 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            name="rootCause"
                                                                            onChange={(value) => {
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: 'rootCause',
                                                                                        value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            FormControlClassName={'form-control'}
                                                                            onBlur={(value) => {
                                                                                handleBlur({
                                                                                    target: {
                                                                                        name: 'rootCause',
                                                                                        value: true,
                                                                                    },
                                                                                });
                                                                            }} error={errors.rootCause}
                                                                            value={values.rootCause}
                                                                            options={Options.Hetu}
                                                                            touched={touched.rootCause}
                                                                            placeholder={'Enter Your Hetu (Root Cause)'} />
                                                                    </div>
                                                                    {values?.diagnosis?.map((item, i) => {
                                                                        return (
                                                                            <div key={i + 'diagno'}>
                                                                                <InputFromUser
                                                                                    error={errors.diagnosis?.[i]?.diagnosis}
                                                                                    touched={true}
                                                                                    placeholder={"Enter Your Diagnosis"}
                                                                                    type="singleSelect"
                                                                                    options={Options.Diagnosis}
                                                                                    name="diagnosis"
                                                                                    onChange={(v) => {
                                                                                        const newValue = [...values.diagnosis]
                                                                                        newValue[i].diagnosis = v
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: "diagnosis",
                                                                                                value: newValue
                                                                                            }
                                                                                        });
                                                                                    }}
                                                                                    value={values.diagnosis?.[i].diagnosis}
                                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                                    LabelClassName={"col-form-label"}
                                                                                    onBlur={undefined}
                                                                                    label="Diagnosis"
                                                                                />
                                                                                <InputFromUser
                                                                                    error={errors.diagnosis?.[i].other}
                                                                                    touched={true}
                                                                                    placeholder={"Enter others"}
                                                                                    type="text"
                                                                                    name="diagnosis"
                                                                                    onChange={(v) => {
                                                                                        const newValue = [...values.diagnosis]
                                                                                        newValue[i].other = v.target.value
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: "diagnosis",
                                                                                                value: newValue
                                                                                            }
                                                                                        });
                                                                                    }} value={values.diagnosis?.[i].other}
                                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                                    LabelClassName={"col-form-label"}
                                                                                    onBlur={undefined}
                                                                                    label="Others"
                                                                                />

                                                                                <Divider dashed />

                                                                            </div>

                                                                        )
                                                                    })}

                                                                    {/* Hetu /Diagnosis end*/}

                                                                    {/* Treatment Recommendation */}
                                                                    <Divider>
                                                                        <div className="m-3">
                                                                            <h3 className="">Treatment Recommendation</h3>
                                                                        </div></Divider>
                                                                    {values?.treatment?.map((item, i) => {
                                                                        return (
                                                                            <div key={i + 'treatment'}>
                                                                                <InputFromUser
                                                                                    error={errors.treatment?.[i]?.treatmentName}
                                                                                    touched={true}
                                                                                    placeholder={"Enter Your Treatment"}
                                                                                    type="singleSelect"
                                                                                    options={ConsultationMasterData?.treatments && ConsultationMasterData?.treatments?.map((v) => v.name) || []}
                                                                                    name="treatments"
                                                                                    onChange={(v) => {
                                                                                        const newValue = [...values.treatment]
                                                                                        newValue[i].treatmentName = v
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: "treatment",
                                                                                                value: newValue
                                                                                            }
                                                                                        });
                                                                                    }}
                                                                                    value={values.treatment?.[i].treatmentName}
                                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                                    LabelClassName={"col-form-label"}
                                                                                    onBlur={undefined}
                                                                                    label="Treatment"
                                                                                />
                                                                                <InputFromUser
                                                                                    error={errors.treatment?.[i].other}
                                                                                    touched={true}
                                                                                    placeholder={"Enter others"}
                                                                                    type="text"
                                                                                    name="treatment"
                                                                                    onChange={(v) => {
                                                                                        const newValue = [...values.treatment]
                                                                                        newValue[i].other = v.target.value
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: "treatment",
                                                                                                value: newValue
                                                                                            }
                                                                                        });
                                                                                    }} value={values.treatment?.[i].other}
                                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                                    LabelClassName={"col-form-label"}
                                                                                    onBlur={undefined}
                                                                                    label="Others"
                                                                                />
                                                                                <InputFromUser
                                                                                    error={errors.treatment?.[i].treatmentsDay}
                                                                                    touched={true}
                                                                                    placeholder={"Enter Your Days"}
                                                                                    type="singleSelect"
                                                                                    options={Array.from({ length: 30 }, (_, index) => index + 1)}
                                                                                    name="treatment"
                                                                                    onChange={(v) => {
                                                                                        const newValue = [...values.treatment]
                                                                                        newValue[i].treatmentsDay = v
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: "treatment",
                                                                                                value: newValue
                                                                                            }
                                                                                        });
                                                                                    }}
                                                                                    value={values.treatment?.[i]?.treatmentsDay}
                                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                                    LabelClassName={"col-form-label"}
                                                                                    onBlur={undefined}
                                                                                    label="Days"
                                                                                />
                                                                                <Divider dashed />

                                                                            </div>

                                                                        )
                                                                    })}
                                                                    {/* Treatment Recommendation end */}

                                                                    {/* Solution Recommendation  */}
                                                                    <Divider>
                                                                        <div className="m-3">
                                                                            <h3 className="">Solution Recommendation</h3>
                                                                        </div></Divider>
                                                                    {values?.solutions?.map((item, i) => {
                                                                        return (
                                                                            <div key={i + 'solutions'}>
                                                                                <InputFromUser
                                                                                    error={errors.solutions?.[i]?.treatmentName}
                                                                                    touched={true}
                                                                                    placeholder={"Enter Your solutions"}
                                                                                    type="singleSelect"
                                                                                    options={ConsultationMasterData?.solution && ConsultationMasterData?.solution?.map((v) => v.name) || []}
                                                                                    name="treatments"
                                                                                    onChange={(v) => {
                                                                                        const newValue = [...values.solutions]
                                                                                        newValue[i].treatmentName = v
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: "solutions",
                                                                                                value: newValue
                                                                                            }
                                                                                        });
                                                                                    }}
                                                                                    value={values.solutions?.[i].treatmentName}
                                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                                    LabelClassName={"col-form-label"}
                                                                                    onBlur={undefined}
                                                                                    label="Solutions"
                                                                                />
                                                                                <InputFromUser
                                                                                    error={errors.solutions?.[i].other}
                                                                                    touched={true}
                                                                                    placeholder={"Enter others"}
                                                                                    type="text"
                                                                                    name="solutions"
                                                                                    onChange={(v) => {
                                                                                        const newValue = [...values.solutions]
                                                                                        newValue[i].other = v.target.value
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: "solutions",
                                                                                                value: newValue
                                                                                            }
                                                                                        });
                                                                                    }} value={values.solutions?.[i].other}
                                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                                    LabelClassName={"col-form-label"}
                                                                                    onBlur={undefined}
                                                                                    label="Others"
                                                                                />
                                                                                <InputFromUser
                                                                                    error={errors.solutions?.[i].treatmentsDay}
                                                                                    touched={true}
                                                                                    placeholder={"Enter Your Days"}
                                                                                    type="singleSelect"
                                                                                    options={[5, 15, 25]}
                                                                                    name="solutions"
                                                                                    onChange={(v) => {
                                                                                        const newValue = [...values.solutions]
                                                                                        newValue[i].treatmentsDay = v
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: "solutions",
                                                                                                value: newValue
                                                                                            }
                                                                                        });
                                                                                    }}
                                                                                    value={values.solutions?.[i]?.treatmentsDay}
                                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                                    LabelClassName={"col-form-label"}
                                                                                    onBlur={undefined}
                                                                                    label="Days"
                                                                                />
                                                                                <Divider dashed />

                                                                            </div>

                                                                        )
                                                                    })}
                                                                    {/* Solution Recommendation end */}

                                                                    {/* Medicine Prescriptions  */}
                                                                    <div className="invoice-add-table form-group " >
                                                                        <div className="table-responsive " >
                                                                            <table className="table table-center add-table-items" >
                                                                                <thead >
                                                                                    <tr >
                                                                                        <th >Medicine</th>
                                                                                        <th>when to Take</th>
                                                                                        <th>Quantity</th>
                                                                                        <th>Course Duration</th>
                                                                                        <th>Anupan</th>
                                                                                        <th>Days</th>
                                                                                        <th className="text-end">Actions</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {values?.medicines?.map((item, index) => {
                                                                                        return (
                                                                                            <tr key={"Medicines1" + index + 'tr'}>
                                                                                                <td className="col-xl-3 col-md-10" key={"Medicines" + index + 'td'} style={{ borderColor: errors?.medicines?.[index]?.medicineName && touched.medicines?.[index]?.medicineName && 'red' }}>
                                                                                                    {<InputFromUser
                                                                                                        type="singleSelect"
                                                                                                        label=""
                                                                                                        key={"MedicinesName1" + index}
                                                                                                        Showlabel={false}
                                                                                                        mode={''}
                                                                                                        name="medicines"
                                                                                                        onChange={(v) => {
                                                                                                            const updateArry = Object.assign([], values.medicines)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ medicineName: v }
                                                                                                            }
                                                                                                            setFieldValue("medicines", updateArry, true)
                                                                                                        }}
                                                                                                        FormControlClassName={'form-control'}
                                                                                                        value={item?.medicineName}
                                                                                                        onSearch={(search) => {
                                                                                                            if (search.length >= 2) {
                                                                                                                dispatch(ActionGetMedicineList({ Query: `?name=${search}` }))
                                                                                                            } else if (search.length === 0) {
                                                                                                                dispatch(ActionGetMedicineList({ Query: `` }))
                                                                                                            }
                                                                                                        }}
                                                                                                        onBlur={() => {
                                                                                                            const updateArry = Object.assign([], touched.medicines)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ medicineName: true }
                                                                                                            }
                                                                                                            setFieldTouched("medicines", updateArry, true)
                                                                                                        }}
                                                                                                        error={errors?.medicines?.[index]?.medicineName}
                                                                                                        touched={touched?.medicines?.[index]?.medicineName}
                                                                                                        onSearchLoading={loader}
                                                                                                        options={medicineList.length != 0 ? medicineList?.map(item => item?.name) : []}
                                                                                                        placeholder={'Enter Your Medicines'} />
                                                                                                    }
                                                                                                </td>
                                                                                                <td className="col-xl-2 col-md-10" key={"td2" + index}>
                                                                                                    {<InputFromUser
                                                                                                        type="multiple"
                                                                                                        label=""
                                                                                                        Showlabel={false}
                                                                                                        mode={''}
                                                                                                        name="whenToTake"
                                                                                                        onChange={(v) => {
                                                                                                            const updateArry = Object.assign([], values.medicines)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ whenToTake: v }
                                                                                                            }
                                                                                                            setFieldValue("medicines", updateArry, true)
                                                                                                        }}
                                                                                                        FormControlClassName={'form-control'}
                                                                                                        value={item?.whenToTake}
                                                                                                        onSearch={(search) => {
                                                                                                            if (search.length >= 3) {
                                                                                                                dispatch(ActionGetMedicineList({ Query: `?name=${search}` }))
                                                                                                            } else if (search.length === 0) {
                                                                                                                dispatch(ActionGetMedicineList({ Query: `` }))
                                                                                                            }
                                                                                                        }}
                                                                                                        onBlur={() => {
                                                                                                            const updateArry = Object.assign([], touched.medicines)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ whenToTake: true }
                                                                                                            }
                                                                                                            setFieldTouched("medicines", updateArry, true)
                                                                                                        }}
                                                                                                        error={errors?.medicines?.[index]?.whenToTake}
                                                                                                        touched={touched?.medicines?.[index]?.whenToTake}
                                                                                                        onSearchLoading={loader}
                                                                                                        options={Options.WhenToTakeMedicine}
                                                                                                        placeholder={'Enter When to Take'} />
                                                                                                    }
                                                                                                </td>
                                                                                                <td className="col-xl-2 col-md-10" key={"td3" + index}>

                                                                                                    <div>
                                                                                                        <InputFromUser
                                                                                                            type="text"
                                                                                                            label=""
                                                                                                            Showlabel={false}
                                                                                                            mode={''}
                                                                                                            name="howMuch"
                                                                                                            onChange={(v) => {
                                                                                                                const updateArry = Object.assign([], values.medicines)
                                                                                                                updateArry[index] = {
                                                                                                                    ...updateArry[index],
                                                                                                                    ...{ howMuch: v?.target?.value }
                                                                                                                }
                                                                                                                setFieldValue("medicines", updateArry, true)
                                                                                                            }}
                                                                                                            FormControlClassName={'form-control'}
                                                                                                            value={item?.howMuch}
                                                                                                            error={errors?.medicines?.[index]?.howMuch}
                                                                                                            touched={touched?.medicines?.[index]?.howMuch}
                                                                                                            onSearchLoading={loader}
                                                                                                            onBlur={() => {
                                                                                                                const updateArry = Object.assign([], touched.medicines)
                                                                                                                updateArry[index] = {
                                                                                                                    ...updateArry[index],
                                                                                                                    ...{ howMuch: true }
                                                                                                                }
                                                                                                                setFieldTouched("medicines", updateArry, true)
                                                                                                            }}
                                                                                                            placeholder={'Enter How Much'} />
                                                                                                    </div>

                                                                                                </td>
                                                                                                <td className="col-xl-2 col-md-10" key={"td4" + index}>
                                                                                                    {<InputFromUser
                                                                                                        type="singleSelect"
                                                                                                        label=""
                                                                                                        Showlabel={false}
                                                                                                        mode={''}
                                                                                                        // disabled={IsFilledForm}
                                                                                                        name="course"
                                                                                                        onChange={(v) => {
                                                                                                            const updateArry = Object.assign([], values.medicines)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ course: v }
                                                                                                            }
                                                                                                            setFieldValue("medicines", updateArry, true)
                                                                                                            setCourseDuration(true);

                                                                                                        }}
                                                                                                        FormControlClassName={'form-control'}
                                                                                                        value={(index === 0 || (courseDuration && index !== 0)) ? values.medicines[0]?.course : item?.course}
                                                                                                        onSearch={(search) => {
                                                                                                            if (search.length >= 3) {
                                                                                                                dispatch(ActionGetMedicineList({ Query: `?name=${search}` }))
                                                                                                            } else if (search.length === 0) {
                                                                                                                dispatch(ActionGetMedicineList({ Query: `` }))
                                                                                                            }
                                                                                                        }}
                                                                                                        onBlur={() => {
                                                                                                            const updateArry = Object.assign([], touched.medicines)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ course: true }
                                                                                                            }
                                                                                                            setFieldTouched("medicines", updateArry, true)
                                                                                                        }}
                                                                                                        error={errors?.medicines?.[index]?.course}
                                                                                                        touched={touched?.medicines?.[index]?.course}
                                                                                                        onSearchLoading={loader}
                                                                                                        options={Options.CourseDuration}
                                                                                                        placeholder={'Enter Course Duration'} />
                                                                                                    }
                                                                                                </td>
                                                                                                <td className="col-xl-2 col-md-10" key={"td5" + index} >
                                                                                                    {<InputFromUser
                                                                                                        type="singleSelect"
                                                                                                        label=""
                                                                                                        Showlabel={false}
                                                                                                        mode={''}
                                                                                                        name="anupan"
                                                                                                        onChange={(v) => {
                                                                                                            const updateArry = Object.assign([], values.medicines)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ anupan: v }
                                                                                                            }
                                                                                                            setFieldValue("medicines", updateArry, true)
                                                                                                        }}
                                                                                                        FormControlClassName={'form-control'}
                                                                                                        value={item?.anupan}
                                                                                                        error={errors?.medicines?.[index]?.anupan}
                                                                                                        touched={touched?.medicines?.[index]?.anupan}
                                                                                                        onSearchLoading={loader}
                                                                                                        onBlur={() => {
                                                                                                            const updateArry = Object.assign([], touched.medicines)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ anupan: true }
                                                                                                            }
                                                                                                            setFieldTouched("medicines", updateArry, true)
                                                                                                        }}
                                                                                                        options={Options.Anupana}
                                                                                                        placeholder={'Enter Your anupan'} />
                                                                                                    }
                                                                                                </td>
                                                                                                <td className="col-xl-2 col-md-10" key={"td6" + index}>
                                                                                                    {<InputFromUser
                                                                                                        type="number"
                                                                                                        label=""
                                                                                                        Showlabel={false}
                                                                                                        mode={''}
                                                                                                        name="days"
                                                                                                        onChange={(v) => {
                                                                                                            const updateArry = Object.assign([], values.medicines);
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ days: v?.target?.value }
                                                                                                            };
                                                                                                            setFieldValue("medicines", updateArry, true);
                                                                                                            setDaysSet(true);
                                                                                                        }}
                                                                                                        FormControlClassName={'form-control'}
                                                                                                        value={item?.days}
                                                                                                        error={errors?.medicines?.[index]?.days}
                                                                                                        touched={touched?.medicines?.[index]?.days}
                                                                                                        onSearchLoading={loader}
                                                                                                        onBlur={() => {
                                                                                                            const updateArry = Object.assign([], touched.medicines);
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ days: true }
                                                                                                            };
                                                                                                            setFieldTouched("medicines", updateArry, true);
                                                                                                        }}
                                                                                                        placeholder={'Enter Days'}
                                                                                                    />}
                                                                                                </td>

                                                                                                <td className="add-remove text-center align-items-center" key={"td6BTn" + index}>
                                                                                                    <Button
                                                                                                        className="add-btn border-0 p-2"
                                                                                                        onClick={() => {
                                                                                                            const newValue = { "medicineName": "", "days": item?.days, "whenToTake": [], 'anupan': '', 'howMuch': '', };
                                                                                                            setFieldValue("medicines", [...values.medicines, newValue])
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="fas fa-plus-circle" />
                                                                                                    </Button>
                                                                                                    <Button
                                                                                                        className="remove-btn border-0 p-2"
                                                                                                        onClick={() => {
                                                                                                            if (index !== 0) {
                                                                                                                const updatedMedicines = [...values.medicines];
                                                                                                                updatedMedicines.splice(index, 1);
                                                                                                                setFieldValue("medicines", updatedMedicines);
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="fe fe-trash-2">
                                                                                                            <FeatherIcon icon="trash-2" className={'mb-1'} />
                                                                                                        </i>
                                                                                                    </Button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    {/* Medicine Prescriptions end*/}

                                                                    {/* DO /DONTS*/}
                                                                    <InputFromUser
                                                                        placeholder={"Select Do"}
                                                                        type="multiple"
                                                                        options={ConsultationMasterData?.doAndDonts && ConsultationMasterData?.doAndDonts?.map((v) => v.title) || []}
                                                                        name="do"
                                                                        onChange={(v) => {
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "do",
                                                                                    value: v
                                                                                }
                                                                            });
                                                                        }}
                                                                        value={values.do}
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Do"
                                                                    />
                                                                    <InputFromUser
                                                                        placeholder={"Select Don't"}
                                                                        type="multiple"
                                                                        options={ConsultationMasterData?.doAndDonts && ConsultationMasterData?.doAndDonts?.map((v) => v.title) || []}
                                                                        name="dont"
                                                                        onChange={(v) => {
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "dont",
                                                                                    value: v
                                                                                }
                                                                            });
                                                                        }}
                                                                        value={values.dont}
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Don't"
                                                                    />
                                                                    {/* DO /DONTS END*/}

                                                                    {/*Virechan/Exercise*/}
                                                                    <InputFromUser
                                                                        placeholder={"Enter Your Virechan"}
                                                                        type="multiple"
                                                                        options={ConsultationMasterData?.virechans && ConsultationMasterData?.virechans?.map((v) => v.title) || []}
                                                                        name="virechan"
                                                                        onChange={(v) => {
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "virechan",
                                                                                    value: v
                                                                                }
                                                                            });
                                                                        }}
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Virechan"
                                                                        value={values.virechan}
                                                                    />
                                                                    <InputFromUser
                                                                        placeholder={"Enter Your Exercise"}
                                                                        type="multiple"
                                                                        options={ConsultationMasterData?.exercises && ConsultationMasterData?.exercises?.map((v) => v.title) || []}
                                                                        name="exercise"
                                                                        onChange={(v) => {
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "exercise",
                                                                                    value: v
                                                                                }
                                                                            });
                                                                        }}
                                                                        value={values.exercise}
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Exercise"
                                                                    />
                                                                    {/*Virechan/Exercise END*/}

                                                                    <div className="col-xl-6 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label" style={{ color: errors.doctorNote && touched.doctorNote && 'red' }} >
                                                                                Doctor Notes:
                                                                                {errors.doctorNote && <span className="login-danger"> *</span>}
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values.doctorNote}
                                                                                onBlur={(value) => {
                                                                                    handleBlur({
                                                                                        target: {
                                                                                            name: 'doctorNote',
                                                                                            value: true,
                                                                                        },
                                                                                    });
                                                                                }} onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'doctorNote',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />
                                                                            {errors.doctorNote && touched.doctorNote && < div className="text-danger m-1 ">{errors.doctorNote}</div>}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Hetu /Diagnosis end*/}

                                                                {/* ROW 10 */}
                                                                <div className="col-xl-6 col-md-12 " >
                                                                    <label className="col-form-label" style={{ color: errors.nextConsultationDate && touched.nextConsultationDate && 'red' }} >
                                                                        Next Appointment Date
                                                                        {errors.nextConsultationDate && <span className="login-danger"> *</span>}
                                                                    </label>
                                                                    <div className="mb-3">
                                                                        <DatePicker
                                                                            className="d-flex p-2"
                                                                            status={errors.nextConsultationDate && touched.nextConsultationDate && 'error'}
                                                                            value={values.nextConsultationDate}
                                                                            picker="date"
                                                                            format={dateFormatList} placeholder="Select Next Appointment Date"
                                                                            onChange={(date, dateString) => {
                                                                                const selectedDate = new Date(date);
                                                                                const today = new Date();
                                                                                let age = today.getFullYear() - selectedDate.getFullYear();
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: 'age',
                                                                                        value: age,
                                                                                    },
                                                                                });
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: 'nextConsultationDate',
                                                                                        value: date,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            onBlur={(v) => handleBlur({
                                                                                target: {
                                                                                    name: 'nextConsultationDate',
                                                                                    value: true,
                                                                                },
                                                                            })}
                                                                        />
                                                                        {errors.nextConsultationDate && touched.nextConsultationDate && < div className="text-danger m-1 ">{errors.nextConsultationDate}</div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="doctor-submit text-end">
                                                            <Button
                                                                disabled={isSubmitting}
                                                                loading={isSubmitting}
                                                                type="primary"
                                                                shape="round"
                                                                // className="btn btn-primary submit-form me-2"
                                                                onClick={() => handleSubmit()}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >

                </div >
            </div >
        </>
    </>;
};

export default AddPatient;
