import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Formik } from 'formik';
import { AddTreatmentProtocolValidationSchema } from '../../utils/FormValidation';
import { CreateTreatmentprotocol, GetAllTreatmentprotocol, GetAllTreatmentservice } from '../../store/Actions/PrakrutistandardAction';
import { ActionUpdateById } from '../../store/Actions/CommonAction';
import { getAdminuUerDetails } from '../../store/Actions/AuthActions';
import { SingleMultiSelectDropDown } from '../../components/Input/InputText';
import { Button, Divider, Input, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { InputFromUser } from '../../components/Input/input';

const AddEditTreatmentProtocol = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const { Treatmentprotocollist } = useSelector(state => state?.PrakrutiStandard);
    const { Treatmentservicelist } = useSelector(state => state?.PrakrutiStandard);
    const TreatmentList = Treatmentservicelist?.[0]?.treatment || [];
    const ServiceList = Treatmentservicelist?.[0]?.service || [];
    const SolutionList = Treatmentservicelist?.[0]?.solution || [];
    let AllTreatmentList = [...TreatmentList, ...ServiceList, ...SolutionList] || []
    const { userDetails } = useSelector(state => state?.auth);
    const userId = userDetails?.[0]?._id;
    const idValue = searchParams.get("id");
    const updateValue = searchParams.get("update");
    useEffect(() => {
        if (idValue) {
            dispatch(GetAllTreatmentprotocol({ Query: '/' + idValue }));
        }
    }, [idValue])
    useEffect(() => {
        dispatch(GetAllTreatmentservice());
    }, [])
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])
    let index = 0;
    const [items, setItems] = useState(['']);
    const [name1, setName] = useState('');
    const inputRef = useRef(null);
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        setItems([...items, name1 || `New item ${index++}`]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const [items2, setItems2] = useState(['']);
    const [name2, setName2] = useState('');
    const inputRef2 = useRef(null);
    const onNameChange2 = (event) => {
        setName2(event.target.value);
    };
    const addItem2 = (e) => {
        e.preventDefault();
        setItems2([...items2, name2 || `New item ${index++}`]);
        setName2('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };
    const [items3, setItems3] = useState(['']);
    const [name3, setName3] = useState('');
    const inputRef3 = useRef(null);
    const onNameChange3 = (event) => {
        setName3(event.target.value);
    };
    const addItem3 = (e) => {
        e.preventDefault();
        setItems3([...items3, name3 || `New item ${index++}`]);
        setName3('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/treatmentprotocol-list?page=1">Treatment Protocol</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Treatment Protocol</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue || false}
                                                initialValues={{
                                                    treatmentName: Treatmentprotocollist?.[0]?.treatmentName || "",
                                                    treatmentId: '',
                                                    services: Treatmentprotocollist?.[0]?.services ||
                                                        [{ "serviceName": '', "serviceId": '', "startTime": "", "endTime": "", "totalMinutes": "", "amladharmi": [], "samadharmi": [], "kshardharmi": [] }]


                                                }}

                                                validationSchema={AddTreatmentProtocolValidationSchema}
                                                onSubmit={(values, { setErrors, setSubmitting }) => {
                                                    if (updateValue && idValue != null) {
                                                        dispatch(ActionUpdateById({ Query: `admin/treatment-protocol/${idValue}`, obj: values }
                                                            , (Res) => {
                                                                if (Res?.statusCode === 200) {
                                                                    window.location.href = `/treatmentprotocol-list?page=1`
                                                                } else if (Res?.statusCode === 400) {
                                                                    setErrors(Res?.data?.errors);
                                                                }
                                                                setSubmitting(false);

                                                            }));
                                                    } else {
                                                        //CreateFaq
                                                        dispatch(CreateTreatmentprotocol(values, (Response) => {
                                                            if (Response?.statusCode === 200) {
                                                                window.location.href = "/treatmentprotocol-list?page=1"
                                                            }
                                                            setSubmitting(false);

                                                        }))
                                                    }
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    // handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue,
                                                    setFieldTouched,
                                                    isSubmitting,
                                                }) => {
                                                    return (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-heading">
                                                                        <h4>Add Treatment Protocol Data</h4>
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-4">

                                                                    <SingleMultiSelectDropDown
                                                                        label=" Treatment Name"
                                                                        type="treatmentName"
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        name="treatmentName"
                                                                        mode={""}

                                                                        onChange={(selectedTreatmentName) => {
                                                                            const selectedTreatment = AllTreatmentList.find(
                                                                                (treatment) => treatment.name === selectedTreatmentName
                                                                            );
                                                                            setFieldValue('treatmentName', selectedTreatmentName);
                                                                            setFieldValue('treatmentId', selectedTreatment?.id || '');
                                                                        }}
                                                                        FormControlClassName={'form-control'}
                                                                        onBlur={(value) => {
                                                                            handleBlur({
                                                                                target: {
                                                                                    name: 'treatmentName',
                                                                                    value,
                                                                                },
                                                                            });
                                                                        }}
                                                                        error={errors?.treatmentName}
                                                                        value={values?.treatmentName}
                                                                        options={AllTreatmentList?.map((treatment) => treatment?.name)}
                                                                        touched={touched?.treatmentName}
                                                                        placeholder={'Select Your Treatment'} />

                                                                </div>
                                                                <Divider style={{ fontWeight: "600", }}>Service Details</Divider>
                                                                <div className="invoice-add-table form-group " >
                                                                    <div className="table-responsive " >
                                                                        <table className="table table-center add-table-items" >
                                                                            <thead >
                                                                                <tr >
                                                                                    <th>Service Name</th>
                                                                                    <th>Start Time</th>
                                                                                    <th>End Time</th>
                                                                                    <th>Total Minutes</th>
                                                                                    <th>Amladharmi</th>
                                                                                    <th>Samadharmi</th>
                                                                                    <th>Kshardharmi</th>
                                                                                    <th className="text-end">Actions</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {values?.services?.map((item, index) => {
                                                                                    return (
                                                                                        <tr key={"services" + index + 'tr'}>
                                                                                            <td className="col-xl-2 col-md-10" key={"td3" + index}>

                                                                                                <div>
                                                                                                    <InputFromUser
                                                                                                        type="text"
                                                                                                        label=""
                                                                                                        Showlabel={false}
                                                                                                        mode={''}
                                                                                                        key={"ServiceName" + index}
                                                                                                        name="serviceName"
                                                                                                        onChange={(v) => {
                                                                                                            const updateArry = Object.assign([], values.services)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ serviceName: v?.target?.value }
                                                                                                            }
                                                                                                            setFieldValue("services", updateArry, true)
                                                                                                        }}
                                                                                                        FormControlClassName={'form-control'}
                                                                                                        value={item?.serviceName}
                                                                                                        error={errors?.services?.[index]?.serviceName}
                                                                                                        touched={touched?.serviceName}
                                                                                                        // onSearchLoading={loader}
                                                                                                        onBlur={() => {
                                                                                                            const updateArry = Object.assign([], touched.services)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ serviceName: true }
                                                                                                            }
                                                                                                            setFieldTouched("services", updateArry, true)
                                                                                                        }}
                                                                                                        placeholder={'Enter Service Name'} />

                                                                                                </div>

                                                                                            </td>
                                                                                            <td className="col-xl-1 col-md-10" key={"td4" + index}>

                                                                                                <div>
                                                                                                    <InputFromUser
                                                                                                        type="text"
                                                                                                        label=""
                                                                                                        Showlabel={false}
                                                                                                        mode={''}
                                                                                                        name="startTime"
                                                                                                        onChange={(v) => {
                                                                                                            const updateArry = Object.assign([], values.services)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ startTime: v?.target?.value }
                                                                                                            }
                                                                                                            setFieldValue("services", updateArry, true)
                                                                                                        }}
                                                                                                        FormControlClassName={'form-control'}
                                                                                                        value={item?.startTime}
                                                                                                        error={errors?.services?.[index]?.startTime}
                                                                                                        touched={touched?.startTime}
                                                                                                        // onSearchLoading={loader}
                                                                                                        onBlur={() => {
                                                                                                            const updateArry = Object.assign([], touched.services)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ startTime: true }
                                                                                                            }
                                                                                                            setFieldTouched("services", updateArry, true)
                                                                                                        }}
                                                                                                        placeholder={' Start Time (0:00Am/Pm)'} />

                                                                                                </div>

                                                                                            </td>
                                                                                            <td className="col-xl-1 col-md-10" key={"td5" + index}>

                                                                                                <div>
                                                                                                    <InputFromUser
                                                                                                        type="text"
                                                                                                        label=""

                                                                                                        Showlabel={false}
                                                                                                        mode={''}
                                                                                                        name="endTime"
                                                                                                        onChange={(v) => {
                                                                                                            const updateArry = Object.assign([], values.services)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ endTime: v?.target?.value }
                                                                                                            }
                                                                                                            setFieldValue("services", updateArry, true)
                                                                                                        }}
                                                                                                        FormControlClassName={'form-control'}
                                                                                                        value={item?.endTime}
                                                                                                        error={errors?.services?.[index]?.endTime}
                                                                                                        touched={touched?.endTime}
                                                                                                        // onSearchLoading={loader}
                                                                                                        onBlur={() => {
                                                                                                            const updateArry = Object.assign([], touched.services)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ endTime: true }
                                                                                                            }
                                                                                                            setFieldTouched("services", updateArry, true)
                                                                                                        }}
                                                                                                        placeholder={'End Time (0:00Am/Pm)'} />

                                                                                                </div>

                                                                                            </td>
                                                                                            <td className="col-xl-1 col-md-10" key={"td6" + index}>

                                                                                                <div>
                                                                                                    <InputFromUser
                                                                                                        type="text"
                                                                                                        label=""

                                                                                                        Showlabel={false}
                                                                                                        mode={''}
                                                                                                        name="totalMinutes"
                                                                                                        onChange={(v) => {
                                                                                                            const updateArry = Object.assign([], values.services)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ totalMinutes: v?.target?.value }
                                                                                                            }
                                                                                                            setFieldValue("services", updateArry, true)
                                                                                                        }}
                                                                                                        FormControlClassName={'form-control'}
                                                                                                        value={item?.totalMinutes}
                                                                                                        error={errors?.services?.[index]?.totalMinutes}
                                                                                                        touched={touched?.totalMinutes}
                                                                                                        // onSearchLoading={loader}
                                                                                                        onBlur={() => {
                                                                                                            const updateArry = Object.assign([], touched.services)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ totalMinutes: true }
                                                                                                            }
                                                                                                            setFieldTouched("services", updateArry, true)
                                                                                                        }}
                                                                                                        placeholder={'Total Minutes'} />

                                                                                                </div>

                                                                                            </td>
                                                                                            <td className="col-xl-2 col-md-10" key={"td7" + index}>
                                                                                                {<InputFromUser
                                                                                                    type="multiple"
                                                                                                    label=""
                                                                                                    Showlabel={false}
                                                                                                    mode={''}
                                                                                                    name="amladharmi"
                                                                                                    onChange={(v) => {
                                                                                                        const updatedServices = [...values.services];
                                                                                                        updatedServices[index] = {
                                                                                                            ...updatedServices[index],
                                                                                                            amladharmi: v
                                                                                                        };
                                                                                                        setFieldValue("services", updatedServices);
                                                                                                    }}
                                                                                                    FormControlClassName={'form-control'}
                                                                                                    value={item?.amladharmi}
                                                                                                    dropdownRender={(menu) => (
                                                                                                        <>
                                                                                                            {menu}
                                                                                                            <Divider
                                                                                                                style={{
                                                                                                                    margin: '8px 0',
                                                                                                                }}
                                                                                                            />
                                                                                                            <Space
                                                                                                                style={{
                                                                                                                    padding: '0 8px 4px',
                                                                                                                }}
                                                                                                            >
                                                                                                                <Input
                                                                                                                    placeholder="Please enter item"
                                                                                                                    ref={inputRef}
                                                                                                                    value={name1}
                                                                                                                    onChange={onNameChange}
                                                                                                                    onKeyDown={(e) => e.stopPropagation()}
                                                                                                                />
                                                                                                                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                                                                                    Add item
                                                                                                                </Button>
                                                                                                            </Space>
                                                                                                        </>
                                                                                                    )}
                                                                                                    onBlur={() => {
                                                                                                        const updateArry = Object.assign([], touched.services)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ amladharmi: true }
                                                                                                        }
                                                                                                        setFieldTouched("services", updateArry, true)
                                                                                                    }}
                                                                                                    error={errors?.services?.[index]?.amladharmi}
                                                                                                    touched={errors?.services?.[index]?.amladharmi}
                                                                                                    options={items}
                                                                                                    placeholder={'Select Amladharmi'} />
                                                                                                }
                                                                                            </td>
                                                                                            <td className="col-xl-2 col-md-10" key={"td8" + index}>
                                                                                                {<InputFromUser
                                                                                                    type="multiple"
                                                                                                    label=""
                                                                                                    Showlabel={false}
                                                                                                    mode={''}
                                                                                                    name="samadharmi"
                                                                                                    onChange={(v) => {
                                                                                                        const updateArry = Object.assign([], values.services)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            samadharmi: v
                                                                                                        }
                                                                                                        setFieldValue("services", updateArry, true)
                                                                                                    }}
                                                                                                    FormControlClassName={'form-control'}
                                                                                                    value={item?.samadharmi}
                                                                                                    dropdownRender={(menu) => (
                                                                                                        <>
                                                                                                            {menu}
                                                                                                            <Divider
                                                                                                                style={{
                                                                                                                    margin: '8px 0',
                                                                                                                }}
                                                                                                            />
                                                                                                            <Space
                                                                                                                style={{
                                                                                                                    padding: '0 8px 4px',
                                                                                                                }}
                                                                                                            >
                                                                                                                <Input
                                                                                                                    placeholder="Please enter item"
                                                                                                                    ref={inputRef2}
                                                                                                                    value={name2}
                                                                                                                    onChange={onNameChange2}
                                                                                                                    onKeyDown={(e) => e.stopPropagation()}
                                                                                                                />
                                                                                                                <Button type="text" icon={<PlusOutlined />} onClick={addItem2}>
                                                                                                                    Add item
                                                                                                                </Button>
                                                                                                            </Space>
                                                                                                        </>
                                                                                                    )}

                                                                                                    onBlur={() => {
                                                                                                        const updateArry = Object.assign([], touched.services)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ samadharmi: true }
                                                                                                        }
                                                                                                        setFieldTouched("services", updateArry, true)
                                                                                                    }}
                                                                                                    error={errors?.services?.[index]?.samadharmi}
                                                                                                    touched={errors?.services?.[index]?.samadharmi}
                                                                                                    options={items2}
                                                                                                    placeholder={'Select Samadharmi'} />
                                                                                                }
                                                                                            </td>
                                                                                            <td className="col-xl-2 col-md-10" key={"td9" + index}>
                                                                                                {<InputFromUser
                                                                                                    type="multiple"
                                                                                                    label=""
                                                                                                    Showlabel={false} y
                                                                                                    mode={''}
                                                                                                    name="kshardharmi"
                                                                                                    onChange={(v) => {
                                                                                                        const updateArry = Object.assign([], values.services)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            kshardharmi: v
                                                                                                        }
                                                                                                        setFieldValue("services", updateArry, true)
                                                                                                    }}
                                                                                                    FormControlClassName={'form-control'}
                                                                                                    value={item?.kshardharmi}
                                                                                                    dropdownRender={(menu) => (
                                                                                                        <>
                                                                                                            {menu}
                                                                                                            <Divider
                                                                                                                style={{
                                                                                                                    margin: '8px 0',
                                                                                                                }}
                                                                                                            />
                                                                                                            <Space
                                                                                                                style={{
                                                                                                                    padding: '0 8px 4px',
                                                                                                                }}
                                                                                                            >
                                                                                                                <Input
                                                                                                                    placeholder="Please enter item"
                                                                                                                    ref={inputRef3}
                                                                                                                    value={name3}
                                                                                                                    onChange={onNameChange3}
                                                                                                                    onKeyDown={(e) => e.stopPropagation()}
                                                                                                                />
                                                                                                                <Button type="text" icon={<PlusOutlined />} onClick={addItem3}>
                                                                                                                    Add item
                                                                                                                </Button>
                                                                                                            </Space>
                                                                                                        </>
                                                                                                    )}
                                                                                                    onBlur={() => {
                                                                                                        const updateArry = Object.assign([], touched.services)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ kshardharmi: true }
                                                                                                        }
                                                                                                        setFieldTouched("services", updateArry, true)
                                                                                                    }}
                                                                                                    error={errors?.services?.[index]?.kshardharmi}
                                                                                                    touched={errors?.services?.[index]?.kshardharmi}

                                                                                                    options={items3}
                                                                                                    placeholder={'Select Kshardharmi'} />
                                                                                                }
                                                                                            </td>
                                                                                            <td className="col-2 add-remove text-end align-items-center" key={"td6price"}>
                                                                                                <div className='d-flex '>
                                                                                                    <div>
                                                                                                        <Button
                                                                                                            type=""
                                                                                                            className="add-btn "
                                                                                                            onClick={() => {
                                                                                                                const newValue = { "serviceName": "", "serviceId": "", "startTime": "", "endTime": "", "totalMinutes": "", "amladharmi": "", "samadharmi": "", "kshardharmi": "" };
                                                                                                                setFieldValue("services", [...values.services, newValue])
                                                                                                            }}
                                                                                                        >
                                                                                                            <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <Button
                                                                                                            style={{ border: 'none' }}
                                                                                                            className="remove-btn "
                                                                                                            onClick={() => {
                                                                                                                if (index !== 0) {
                                                                                                                    const updatedBenefits = [...values.services];
                                                                                                                    updatedBenefits.splice(index, 1);
                                                                                                                    setFieldValue("services", updatedBenefits);
                                                                                                                }
                                                                                                            }}
                                                                                                        >
                                                                                                            <i className="fe fe-trash-2">
                                                                                                                <FeatherIcon icon="trash-2" className={'mb-2'} />
                                                                                                            </i>
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 mt-4">
                                                                    <div className="doctor-submit text-end">
                                                                        <button
                                                                            type="submit"
                                                                            onClick={() => {
                                                                                handleSubmit()
                                                                            }}
                                                                            className="btn btn-primary submit-form me-2"
                                                                            disabled={isSubmitting}
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary cancel-form"
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Formik>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default AddEditTreatmentProtocol