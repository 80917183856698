/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactPasswordToggleIcon from 'react-password-toggle-icon';
import FeatherIcon from "feather-icons-react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { DatePicker, Select, Spin, TimePicker } from 'antd';
import { dateFormatList } from '../../utils/Data';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const InputFromUser = (props) => {
    const {
        touched = false,
        disabled = false,
        label,
        name,
        value,
        onChange,
        disabledDate,
        onSearch,
        onSearchLoading,
        dropdownRender,
        onBlur,
        FormGroupClassName,
        error,
        FormControlClassName,
        LabelClassName,
        InputConatiner,
        placeholder = '',
        placement = '',
        Dropdown_ClassName = '',
        type = 'text',
        ShowRightIcon = false,
        options,
        ComponentStyle = {},
        selectedValue
    } = props;
    let inputRef = useRef();
    const showIcon = () => <i className={`feather feather-eye  ${error && touched ? 'mb-4' : ''}`} >
        <FeatherIcon icon="eye" />
    </i>;
    const hideIcon = () => <i className={`feather feather-eye-slash ${error && touched ? 'mb-4' : ''}`}>
        <FeatherIcon icon="eye-off" />
    </i>
    const IsInputText = type === 'text' || type === 'color' || type === 'email' || type === 'number' || type === 'password' || type === 'search' || type === 'url' || type === 'tel'
    const ShowPassTogleIcon = (type === 'password' && IsInputText)
    const ShowDropDown = type === 'multiple' || type === 'singleSelect'
    const ShowRadio = type === 'radio'
    const ShowTimePicker = type === 'TimePicker'
    return (
        <>
            <div className={`form-group ${FormGroupClassName}`}>
                <label style={{ color: error && touched && 'red' }} className={LabelClassName}>
                    {label}
                    {error && <span className="login-danger"> *</span>}
                </label>
                <div className={`${InputConatiner} ${ShowRadio && 'form-control d-flex justify-content-between'}`} style={{ borderColor: error && touched && 'red', borderWidth: 1 }}>
                    {IsInputText &&
                        <input
                            ref={inputRef}
                            className={`pass-input form-control ${error && touched ? 'error' : ''}${FormControlClassName}`}
                            placeholder={placeholder}
                            aria-describedby="sizing-addon3"
                            type={type}
                            style={{ borderColor: error && touched && 'red', borderWidth: 1, }}
                            name={name}
                            disabled={disabled}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                        />}

                    {ShowPassTogleIcon &&
                        <ReactPasswordToggleIcon
                            inputRef={inputRef}
                            showIcon={showIcon}
                            hideIcon={hideIcon}
                        />}



                    {ShowDropDown &&
                        <Select
                            showSearch
                            style={{
                                height: 43, width: '100%', flex: 1,
                                ...ComponentStyle
                            }}
                            disabled={disabled}
                            className={Dropdown_ClassName}
                            mode={type}
                            maxTagCount={'responsive'}
                            value={value}
                            optionFilterProp="children"
                            onChange={onChange}
                            onBlur={onBlur}

                            dropdownRender={dropdownRender}
                            // filterOption={filterOption}
                            // filterOption={(inputValue, option) =>
                            //     option && option.children && option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                            // }
                            placement={placement}
                            notFoundContent={onSearchLoading ? <Spin size="small" /> : null}
                            status={error && touched && 'error'}
                            placeholder={placeholder}
                            options={options.map((item) => ({
                                label: item,
                                value: item,
                            }))}
                        />}

                    {ShowRadio &&
                        options?.map((option, index) => (
                            <div className="radio col-xl col-md" key={index}>
                                <label>
                                    <input
                                        type="radio"
                                        name={name}
                                        disabled={disabled}
                                        value={option.value}
                                        checked={selectedValue === option.value}
                                        onChange={() => onChange(option.value)}
                                        onBlur={onBlur}
                                    /> {option.label}
                                </label>
                            </div>
                        ))
                    }
                    {type === "date" &&
                        <DatePicker
                            className="d-flex p-2"
                            status={error && touched && 'error'}
                            value={value}
                            picker="date"
                            format={dateFormatList}
                            placement={placement}
                            disabled={disabled}
                            disabledDate={disabledDate}
                            placeholder={placeholder}
                            onChange={onChange}
                            size='large'
                            onBlur={onBlur}
                        />}
                    {ShowTimePicker &&
                        <TimePicker
                            className="d-flex p-2"
                            status={error && touched && 'error'}
                            value={value}
                            picker="date"
                            placeholder={placeholder}
                            placement={placement}
                            onChange={onChange}
                            size='large'
                            disabled={disabled}
                            onBlur={onBlur}
                            minuteStep={15}
                            format="h:mm a"
                            use12Hours
                        />}
                    {error && touched && !ShowRadio && <div className="text-danger m-1 ">{error}</div>}
                </div>
                {ShowRadio && error && touched && <div className="text-danger m-1 ">{error}</div>}
            </div>
        </>
    );
};





InputFromUser.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any]),
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    disabledDate: PropTypes.func,
    onSearchLoading: PropTypes.bool,
    onBlur: PropTypes.func,
    error: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
    FormGroupClassName: PropTypes.string,
    type: 'text' || 'checkbox' || 'color' || 'email' || 'number' || 'password' || 'search' || 'url' || 'tel' || "multiple" || "tags" || undefined,
    placeholder: PropTypes.string,
    placement: PropTypes.string,
    dropdownRender: PropTypes.func,
    InputConatiner: PropTypes.string,
    LabelClassName: PropTypes.string,
    FormControlClassName: PropTypes.string,
    Dropdown_ClassName: PropTypes.string,
    touched: PropTypes.oneOfType(PropTypes.string, PropTypes.bool),
    disabled: PropTypes.bool,
    ShowRightIcon: PropTypes.bool,
    ComponentStyle: PropTypes.object,
    selectedValue: PropTypes.string,
    options: PropTypes.array,
};



export const TextEditor = ({ onFocus, onChange, onBlur, name, value, errorMessage }) => {
    return (
        <div>
            <CKEditor

                editor={ClassicEditor}
                name={name}
                // onReady={(editor) => {
                //   // You can store the "editor" and use when it is needed.

                // }}
                // onChange={(event, editor) => {
                //   const data = editor.getData();
                //   console.log({ event, editor, data });
                // }}
                // onBlur={(event, editor) => {
                //   console.log("Blur.", editor);
                // }}
                // onFocus={(event, editor) => {
                //   console.log("Focus.", editor);
                // }}

                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                data={value}
            />
            {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

        </div>
    )
}

TextEditor.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    onFocus: PropTypes.func,
    name: PropTypes.func,
    errorMessage: PropTypes.string,
}
