import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';
import PropTypes from 'prop-types';

const DonutChart = ({ series, lables }) => {
  useEffect(() => {
    if (document.querySelector('#donut-chart-dash') && series && lables) {
      const donutChartOptions = {
        chart: {
          height: 290,
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: series,
        labels: lables,
        responsive: [
          {
            breakpoint: 280,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'right',
              },
            },
          },
        ],
        legend: {
          position: 'right',
        },
      };

      const donut = new ApexCharts(
        document.querySelector('#donut-chart-dash'),
        donutChartOptions
      );
      donut.render();
    }
  }, [series, lables]);

  return <div id="donut-chart-dash"></div>;
};
DonutChart.propTypes = {
  series: PropTypes.array.isRequired,
  lables: PropTypes.array.isRequired,
};
export default DonutChart;