/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
// import Header from "../../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { InputFromUser } from "../../../components/Input/input";
import { MedicalStoreValidationSchema } from "../../../utils/FormValidation";
import Header from "../../../components/Header";
import { ActionGetMedicalDetailByID, ActionUpdateMedicalDetail } from "../../../store/Actions/MedicalStoreAction";
import { Button, Divider } from "antd";
import { ActionGetMedicineList } from "../../../store/Actions/PatientAction";
import { Options } from "../../../utils/Data";
import dayjs from 'dayjs';

const EditMedicalStoreDetails = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const updateValue = searchParams.get("update");
    const pageValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const idValue = searchParams.get("id");
    const { medicalStore } = useSelector(state => state?.MedicalStore);
    const { medicineList, loader } = useSelector(state => state?.patient);
    useEffect(() => {
        if (idValue) {
            dispatch(ActionGetMedicalDetailByID({ Query: `/${idValue}` }));
        }
    }, [idValue, dispatch])

    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/medical-store">Medical Store</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Edit Medical Store Details</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue}
                                                initialValues={{
                                                    dateOfMedicalStore: dayjs(medicalStore?.nextConsultationDate) || null,
                                                    name: medicalStore?.customerId?.name || "",
                                                    recomendedMedicines: medicalStore?.recomendedMedicines || [],
                                                }}
                                                validationSchema={MedicalStoreValidationSchema}
                                                onSubmit={(values, { setSubmitting, setErrors }) => {
                                                    dispatch(ActionUpdateMedicalDetail({
                                                        Query: "/" + idValue,
                                                        obj: {
                                                            isPurchased: true,
                                                            dateOfMedicalStore: values?.dateOfMedicalStore,
                                                            purchasedMedicines: values.recomendedMedicines
                                                        }
                                                    }, (Res) => {
                                                        setSubmitting(false);
                                                        if (Res?.statusCode === 200) {
                                                            window.location.href = `/medical-store?page=${pageValue}`;
                                                            // window.location.reload();

                                                        } else if (Res?.statusCode === 400) {
                                                            setErrors(Res?.data?.errors);
                                                        } else {
                                                            // window.location.reload();
                                                        }
                                                    }))
                                                }}

                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue,
                                                    setFieldTouched,
                                                    isSubmitting,
                                                }) => {
                                                    return (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-heading">
                                                                        <h4>Edit Medical Store Details</h4>
                                                                    </div>
                                                                </div>
                                                                <Divider style={{ fontWeight: "600", }}>{medicalStore?.customerId?.name || '--'}</Divider>
                                                                <Divider style={{ fontWeight: "600", }}>Purchased Medical Details</Divider>
                                                                <div className="invoice-add-table form-group " >
                                                                    <div className="table-responsive " >
                                                                        <table className="table table-center add-table-items" >
                                                                            <thead >
                                                                                <tr >
                                                                                    <th >Medicine</th>
                                                                                    <th>When to Take</th>
                                                                                    <th>Quantity</th>
                                                                                    <th>Anupan</th>
                                                                                    <th>Days</th>
                                                                                    <th className="text-end">Actions</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {values?.recomendedMedicines?.map((item, index) => {
                                                                                    return (
                                                                                        <tr key={"recomendedMedicines" + index + 'tr'}>
                                                                                            <td className="col-xl-3 col-md-10" key={"recomendedMedicines" + index + 'td'} style={{ borderColor: errors?.recomendedMedicines?.[index]?.medicineName && touched.recomendedMedicines?.[index]?.medicineName && 'red' }}>
                                                                                                {<InputFromUser
                                                                                                    type="singleSelect"
                                                                                                    label=""
                                                                                                    key={"MedicinesName" + index}
                                                                                                    Showlabel={false}
                                                                                                    mode={''}
                                                                                                    disabled
                                                                                                    name="recomendedMedicines"
                                                                                                    onChange={(v) => {
                                                                                                        const updateArry = Object.assign([], values.recomendedMedicines)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ medicineName: v }
                                                                                                        }
                                                                                                        setFieldValue("recomendedMedicines", updateArry, true)
                                                                                                    }}
                                                                                                    FormControlClassName={'form-control'}
                                                                                                    value={item?.medicineName}
                                                                                                    onSearch={(search) => {
                                                                                                        if (search.length >= 2) {
                                                                                                            dispatch(ActionGetMedicineList({ Query: `?name=${search}` }))
                                                                                                        } else if (search.length === 0) {
                                                                                                            dispatch(ActionGetMedicineList({ Query: `` }))
                                                                                                        }
                                                                                                    }}
                                                                                                    onBlur={() => {
                                                                                                        const updateArry = Object.assign([], touched.recomendedMedicines)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ medicineName: true }
                                                                                                        }
                                                                                                        setFieldTouched("recomendedMedicines", updateArry, true)
                                                                                                    }}
                                                                                                    error={errors?.recomendedMedicines?.[index]?.medicineName}
                                                                                                    touched={touched?.recomendedMedicines?.[index]?.medicineName}
                                                                                                    onSearchLoading={loader}
                                                                                                    options={medicineList.length != 0 ? medicineList?.map(item => item?.name) : []}
                                                                                                    placeholder={'Select Your recomendedMedicines'} />
                                                                                                }
                                                                                            </td>
                                                                                            <td className="col-xl-2 col-md-10" key={"td2" + index}>
                                                                                                {<InputFromUser
                                                                                                    type="multiple"
                                                                                                    label=""
                                                                                                    Showlabel={false} y
                                                                                                    mode={''}
                                                                                                    disabled

                                                                                                    name="whenToTake"
                                                                                                    onChange={(v) => {
                                                                                                        const updateArry = Object.assign([], values.recomendedMedicines)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ whenToTake: v }
                                                                                                        }
                                                                                                        setFieldValue("recomendedMedicines", updateArry, true)
                                                                                                    }}
                                                                                                    FormControlClassName={'form-control'}
                                                                                                    value={item?.whenToTake}
                                                                                                    onSearch={(search) => {
                                                                                                        if (search.length >= 3) {
                                                                                                            dispatch(ActionGetMedicineList({ Query: `?name=${search}` }))
                                                                                                        } else if (search.length === 0) {
                                                                                                            dispatch(ActionGetMedicineList({ Query: `` }))
                                                                                                        }
                                                                                                    }}
                                                                                                    onBlur={() => {
                                                                                                        const updateArry = Object.assign([], touched.recomendedMedicines)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ whenToTake: true }
                                                                                                        }
                                                                                                        setFieldTouched("recomendedMedicines", updateArry, true)
                                                                                                    }}
                                                                                                    error={errors?.recomendedMedicines?.[index]?.whenToTake}
                                                                                                    touched={touched?.recomendedMedicines?.[index]?.whenToTake}
                                                                                                    onSearchLoading={loader}
                                                                                                    options={[6, 6, 10, 2, 'A', 'S', "VN", "SW", "Per Hourly"]}
                                                                                                    placeholder={'Select When to Take'} />
                                                                                                }
                                                                                            </td>
                                                                                            <td className="col-xl-2 col-md-10" key={"td3" + index}>

                                                                                                <div>
                                                                                                    <InputFromUser
                                                                                                        type="text"
                                                                                                        label=""
                                                                                                        disabled
                                                                                                        Showlabel={false}
                                                                                                        mode={''}
                                                                                                        name="quantity"
                                                                                                        onChange={(v) => {
                                                                                                            const updateArry = Object.assign([], values.recomendedMedicines)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ quantity: v?.target?.value }
                                                                                                            }
                                                                                                            setFieldValue("recomendedMedicines", updateArry, true)
                                                                                                        }}
                                                                                                        FormControlClassName={'form-control'}
                                                                                                        value={item?.quantity}
                                                                                                        error={errors?.recomendedMedicines?.[index]?.quantity}
                                                                                                        touched={touched?.recomendedMedicines?.[index]?.quantity}
                                                                                                        onSearchLoading={loader}
                                                                                                        onBlur={() => {
                                                                                                            const updateArry = Object.assign([], touched.recomendedMedicines)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ quantity: true }
                                                                                                            }
                                                                                                            setFieldTouched("recomendedMedicines", updateArry, true)
                                                                                                        }}
                                                                                                        placeholder={'Enter Quantity'} />

                                                                                                </div>

                                                                                            </td>
                                                                                            <td className="col-xl-2 col-md-10" key={"td4" + index} >
                                                                                                {<InputFromUser
                                                                                                    type="singleSelect"
                                                                                                    label=""

                                                                                                    Showlabel={false}
                                                                                                    mode={''}
                                                                                                    disabled
                                                                                                    name="anupan"
                                                                                                    onChange={(v) => {
                                                                                                        const updateArry = Object.assign([], values.recomendedMedicines)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ anupan: v }
                                                                                                        }
                                                                                                        setFieldValue("recomendedMedicines", updateArry, true)
                                                                                                    }}
                                                                                                    FormControlClassName={'form-control'}
                                                                                                    value={item?.anupan}
                                                                                                    error={errors?.recomendedMedicines?.[index]?.anupan}
                                                                                                    touched={touched?.recomendedMedicines?.[index]?.anupan}
                                                                                                    onSearchLoading={loader}
                                                                                                    onBlur={() => {
                                                                                                        const updateArry = Object.assign([], touched.recomendedMedicines)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ anupan: true }
                                                                                                        }
                                                                                                        setFieldTouched("recomendedMedicines", updateArry, true)
                                                                                                    }}
                                                                                                    options={Options.Anupana}
                                                                                                    placeholder={'Enter Your anupan'} />
                                                                                                }
                                                                                            </td>
                                                                                            <td className="col-xl-2 col-md-10" key={"td5" + index}>
                                                                                                {<InputFromUser
                                                                                                    type="text"
                                                                                                    label=""

                                                                                                    Showlabel={false}
                                                                                                    mode={''}
                                                                                                    name="days"
                                                                                                    onChange={(v) => {
                                                                                                        const updateArry = Object.assign([], values.recomendedMedicines)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ days: v?.target?.value }
                                                                                                        }
                                                                                                        setFieldValue("recomendedMedicines", updateArry, true)
                                                                                                    }}
                                                                                                    FormControlClassName={'form-control'}
                                                                                                    value={item?.days}
                                                                                                    error={errors?.recomendedMedicines?.[index]?.days}
                                                                                                    touched={touched?.recomendedMedicines?.[index]?.days}
                                                                                                    onSearchLoading={loader}
                                                                                                    onBlur={() => {
                                                                                                        const updateArry = Object.assign([], touched.recomendedMedicines)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ days: true }
                                                                                                        }
                                                                                                        setFieldTouched("recomendedMedicines", updateArry, true)
                                                                                                    }}
                                                                                                    placeholder={'Enter Days'} />
                                                                                                }
                                                                                            </td>
                                                                                            <td className="add-remove text-end align-items-center" key={"td6" + index}>
                                                                                                <Button
                                                                                                    className="add-btn me-3 border-0"
                                                                                                    onClick={() => {
                                                                                                        const newValue = { "medicineName": "", "days": "", "whenToTake": '', 'anupan': '', 'quantity': '', };
                                                                                                        setFieldValue("recomendedMedicines", [...values.recomendedMedicines, newValue])
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="fas fa-plus-circle" />
                                                                                                </Button>

                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <Divider>
                                                                                    <div className="m-3">
                                                                                        <h3 className="">∬ Change consultation Date ∬</h3>
                                                                                    </div></Divider>
                                                                                <InputFromUser
                                                                                    placeholder={"Enter Date"}
                                                                                    type="date"
                                                                                    name="dateOfMedicalStore"
                                                                                    LabelClassName={"col-form-label"}
                                                                                    label="Change Consultation Date"
                                                                                    FormGroupClassName={"col-6"}
                                                                                    error={errors?.dateOfMedicalStore}
                                                                                    value={values?.dateOfMedicalStore}
                                                                                    onChange={(v) => {
                                                                                        setFieldValue('dateOfMedicalStore', v, true)
                                                                                    }}
                                                                                    FormControlClassName={'form-control'}
                                                                                    touched={touched?.dateOfMedicalStore}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="doctor-submit text-end">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-primary submit-form me-2"
                                                                        onClick={() => handleSubmit()}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Formik>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div >
            </>
        </>
    );
};

export default EditMedicalStoreDetails;