import { call, put, takeEvery } from 'redux-saga/effects';
import { handleApiResponse } from '../../utils/Funcations';
import { CreateByUrlApi, DeleteByIdApi, UpdateByIdApi, UploadDocApi, getAllDashboardDataApi, getAllGalleryApi } from '../../utils/api';
import { ActionGetDashboardDataSuccess, ActionGetGalleryListSuccess } from '../Actions/CommonAction';
import { CREATE_BY_URL, DELETE_BY_ID, GET_ALL_DASHBOARD_DATA, GET_ALL_GALLERY, UPDATE_BY_ID, UPLOAD_IMAGE } from '../storeTypes';

const File = 'commonSaga'
function* UpdateByIdSaga(action) {
    try {
        const response = yield call(UpdateByIdApi, action.payload);
        handleApiResponse(response.status, response?.data, action.callBack, true)
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action.callBack, true)
        console.log(File + 'UpdateByIdSaga Error :- ', error);
    }
}
function* DeleteByIdSaga(action) {
    try {
        const response = yield call(DeleteByIdApi, action.payload);
        handleApiResponse(response.status, response?.data, action?.callBack, true)
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
        console.log(File + 'DeleteByIdSaga Error :- ', error);
    }
}
function* CreateByUrlSaga(action) {
    try {
        const response = yield call(CreateByUrlApi, action.payload);
        handleApiResponse(response.status, response?.data, action?.callBack, true)
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
        console.log(File + 'CreateByUrlSaga Error :- ', error);
    }
}
function* UploadImageSaga(action) {
    try {
        const response = yield call(UploadDocApi, action.payload);
        handleApiResponse(response.status, response?.data, action?.callBack, true)
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
        console.log(File + 'UploadImageSaga Error :- ', error);
    }
}
function* GetGallerySaga(action) {
    try {
        const response = yield call(getAllGalleryApi, action.payload);
        console.log("getAllGalleryApi", response?.data?.data);
        if (response?.status === 200) {
            yield put(ActionGetGalleryListSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getAllGalleryApi Error :- ', error);
    }
}
function* GetDashboardDataSaga(action) {
    try {
        const response = yield call(getAllDashboardDataApi, action.payload);
        console.log("getAllGalleryApi", response?.data?.data);
        if (response?.status === 200) {
            yield put(ActionGetDashboardDataSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'GetDashboardDataSaga Error :- ', error);
    }
}

export function* commonSaga() {
    [
        yield takeEvery(UPDATE_BY_ID, UpdateByIdSaga),
        yield takeEvery(DELETE_BY_ID, DeleteByIdSaga),
        yield takeEvery(CREATE_BY_URL, CreateByUrlSaga),
        yield takeEvery(UPLOAD_IMAGE, UploadImageSaga),
        yield takeEvery(GET_ALL_GALLERY, GetGallerySaga),
        yield takeEvery(GET_ALL_DASHBOARD_DATA, GetDashboardDataSaga),
    ];
}
