/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import { StarIcon, downloadimage, eyeIc, settingicon01 } from '../imagepath';
import { CommonHeadingTxt } from '../Text/Text';
import CalanderModal from '../Modal/CalanderModal';
import { Link } from 'react-router-dom';
import { PrivetKey } from '../../utils/Data';
import moment from 'moment';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { FilesIconByType, ShowFileInTab, downloadFile, getStatus } from '../../utils/Funcations';
import { DOCS_URL } from '../../utils/globals';
import CountUp from "react-countup";
import { Tag } from 'antd';

export const ReportCard = ({
    Heading = '',
    Children,
    Data = [],
    style
}) => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const pageValue = searchParams.get("page");
    return (
        <>
            <div className="card top-departments">
                <div className="card-header align-items-center d-flex justify-content-between">
                    <h4 className="card-title d-inline-block"  >{Heading}</h4>
                    {Children}
                </div>
                <div className="card-body">
                    {Data?.map((itm, key) => {
                        return (
                            <div className="activity-top p-2 align-items-center d-flex justify-content-between" style={style} key={key}>
                                {/* <div className="activity-boxs comman-flex-center" style={{ background: "none" }}>
                                    <img src={itm?.icon} alt="#" />
                                </div> */}
                                {/* <div className="align-items-center d-flex justify-content-between" style={{ backgroundColor: 'saddlebrown' }} > */}
                                <Link to={`/patient-list/appointment-details?IsFor=${PrivetKey.DISABLE}&consultationId=${itm?._id}&page=${pageValue}`} className='col'>{itm?.customerId?.name || itm?.customerName}</Link>
                                <h4 className='col'>{(itm?.createdAt && moment(itm?.createdAt).format("DD-MM-YYYY") || '---') || '---'}</h4>
                                {/* </div> */}
                            </div>

                        )
                    })}
                </div>
            </div >
        </>
    )
}
ReportCard.propTypes = {
    Heading: PropTypes.string,
    Children: PropTypes.node,
    Data: PropTypes.array,
    style: PropTypes.object,
};

export const MedicalHistoryCard = ({
    Heading = '',
    Children,
    Data = [],
    style
}) => {

    return (
        <div>
            < div className="card top-departments" >
                <div className="card-header align-items-center d-flex justify-content-between">
                    <h4 className="card-title d-inline-block">{Heading}</h4>
                    {Children}
                </div>
                <div className="card-body scroll-indicator-none" style={{ maxHeight: '500px', overflowY: 'auto', scrollbarWidth: 'none' }}>
                    {Data?.map((itm, key) => {
                        const fileType = itm.split('.').pop()
                        return (
                            <div className="activity-top" style={{ background: "none", ...style, justifyContent: 'space-between' }} key={key}>
                                <div className='departments-list'>
                                    <div className="activity-boxs comman-flex-center" style={{ background: "none" }}>
                                        {FilesIconByType(fileType)}
                                    </div>
                                    <p className=''>{itm.substring(itm.lastIndexOf("/") + 1)}</p>
                                </div>
                                <div className="activity-boxs comman-flex-end " style={{ background: "none" }} >
                                    <img src={downloadimage} className='mx-2' style={{ cursor: 'pointer' }} onClick={() => {
                                        const url = DOCS_URL + itm
                                        downloadFile(url)
                                    }} />
                                    <img src={eyeIc} className='mx-2' style={{ cursor: 'context-menu' }} onClick={() => ShowFileInTab(itm, itm.substring(itm.lastIndexOf("/") + 1))} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div >
        </div >
    )
}
MedicalHistoryCard.propTypes = {
    Heading: PropTypes.string,
    Children: PropTypes.node,
    Data: PropTypes.array,
    style: PropTypes.object,
};

export const FeedBackCard = ({
    Heading = '',
    Children,
    Data = [],
    style
}) => {
    const [modal, SetModal] = useState(false);
    return (
        <>
            <div className="widget post-widget">
                <CommonHeadingTxt Text="Customer’s Feedback"
                    Children={
                        <h3 onClick={() => SetModal(true)} className="patient-views" style={{ color: "#2E37A4", cursor: "pointer" }}>
                            Schedule
                        </h3>
                    } />
                <ul className="latest-posts">
                    <li>
                        <div className="p-3">
                            <div className="date-posts">
                                <h5>Name</h5>
                                <img className="ms-2" src={StarIcon} />
                            </div>
                            <p> {" "}
                                Replace this text with your feedback.  The note’s height will automatically fit what you type.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="p-3">
                            <div className="date-posts">
                                <h5>Name</h5>
                                <img className="ms-2" src={StarIcon} />
                            </div>
                            <p> {" "}
                                Replace this text with your feedback.  The note’s height will automatically fit what you type.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="p-3">
                            <div className="date-posts">
                                <h5>Name</h5>
                                <img className="ms-2" src={StarIcon} />
                            </div>
                            <p> {" "}
                                Replace this text with your feedback.  The note’s height will automatically fit what you type.
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <CalanderModal IsOpen={modal} Isclose={() => SetModal(false)} />
        </>
    )
}
FeedBackCard.propTypes = {
    Heading: PropTypes.string,
    Children: PropTypes.node,
    Data: PropTypes.array,
    style: PropTypes.object,
};

export const PatientProfileCard = ({
    Heading = '',
    Children,
    Data = [],
    style
}) => {

    return (
        <>
            <div className=" d-flex justify-content-evenly">
                <div className="card patient-structure">
                </div>
            </div>

        </>
    )
}
PatientProfileCard.propTypes = {
    Heading: PropTypes.string,
    Children: PropTypes.node,
    Data: PropTypes.array,
    style: PropTypes.object,
};

export const RoundProfileIcon = ({ username, firstName }) => {
    const canvasRef = useRef(null);
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = 'white';
        ctx.beginPath();
        ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2, 0, 2 * Math.PI);
        ctx.fill();
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2 - 1.5, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.font = '20px Arial';
        ctx.fillStyle = 'black';
        const text = `${firstName[0].toUpperCase()}${username[0].toUpperCase()}`;
        const textMetrics = ctx.measureText(text);
        const textX = (canvas.width - textMetrics.width) / 2;
        const textY = (canvas.height + 14) / 2;
        ctx.fillText(text, textX, textY);
    }, [username, firstName]);

    return <canvas ref={canvasRef} width={40} height={40} style={{ border: '1px solid #ccc', borderRadius: '50%', }} />;
};

RoundProfileIcon.propTypes = {
    username: PropTypes.string,
    firstName: PropTypes.string,

};



export const Breadcrumb = ({
    page = [],
}) => {

    return (
        <div className="page-header">
            <div className="row">
                <div className="col-sm-12">
                    <ul className="breadcrumb">
                        {page.map((value, index) => {
                            return (
                                <>
                                    {!((page.length - 1) === index) && <>
                                        <li className="breadcrumb-item" key={index + 'Breadcrumb'}>
                                            <Link to={value?.linkTo}>{value?.name}</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                    </>
                                    }
                                    {((page.length - 1) === index) && <li className="breadcrumb-item active">{value?.name}</li>
                                    }
                                </>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}
Breadcrumb.propTypes = {
    page: PropTypes.array,
};
export const DashboardCard = ({
    src,
    Counts,
    Title,
    delay,
    duration
}) => {

    return (
        <div className="col-md-6 col-sm-6 col-lg-4">
            <div className="dash-widget">
                <div className="d-flex align-items-center ">
                    <div className="dash-boxs comman-flex-center">
                        <img src={settingicon01} alt="#" />
                    </div>
                    <div className="dashboard-count ms-4 mt-3">
                        <CountUp
                            delay={delay}
                            end={Counts}
                            duration={duration} />
                        <h3 style={{ fontSize: 17 }} >
                            {Title}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

DashboardCard.propTypes = {
    src: PropTypes.string,
    Counts: PropTypes.number,
    delay: PropTypes.number,
    duration: PropTypes.number,
    Title: PropTypes.string,
};

export const PaymentStatusTag = ({ paymentStatus, amount, orderStatus }) => {
    if (!paymentStatus) {
        return null;
    }

    return (
        paymentStatus && <Tag color={getStatus(paymentStatus)}><h5>{(amount + '/-') || '--'}</h5></Tag>
    );
};

PaymentStatusTag.propTypes = {
    amount: PropTypes.number,
    orderStatus: PropTypes.string,
    paymentStatus: PropTypes.string,

};

export const ListItems = ({ title, value }) => {
    return (
        <p><strong>{title + ' : '}</strong>
            {value || '---'}</p>)
}
ListItems.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,

};