import { call, put, takeEvery } from "redux-saga/effects";
import { handleApiResponse } from "../../utils/Funcations";
import { ActionGetTherapistCsvDataSuccess, ActionGetTherapistHistoryByIdSuccess, ActionGetTherapistHistorySuccess } from "../Actions/TherapistAllocationAction";
import { TherapistCsvDataApi, TherapistHistoryApi, TherapistHistoryByIdApi } from "../../utils/api";
import { GET_ALL_THERAPIST_HISTORY, GET_ALL_THERAPIST_HISTORY_BY_ID, GET_THERAPIST_CSV_DATA } from "../storeTypes";


function* GetTherapistHistoryListSaga(action) {
    try {
        const response = yield call(TherapistHistoryApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetTherapistHistorySuccess(response?.data?.data));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
    }
}
function* GetTherapistHistoryByIdSaga(action) {
    try {
        const response = yield call(TherapistHistoryByIdApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetTherapistHistoryByIdSuccess(response?.data?.data));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
    }
}
function* GetTherapistCsvDataSaga(action) {
    try {
        const response = yield call(TherapistCsvDataApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetTherapistCsvDataSuccess(response?.data?.data));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
    }
}

export function* TherapistHistoryListSaga() {
    [
        yield takeEvery(GET_ALL_THERAPIST_HISTORY, GetTherapistHistoryListSaga),
        yield takeEvery(GET_ALL_THERAPIST_HISTORY_BY_ID, GetTherapistHistoryByIdSaga),
        yield takeEvery(GET_THERAPIST_CSV_DATA, GetTherapistCsvDataSaga),

    ];
}