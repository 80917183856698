/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { Button } from 'antd';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import moment from 'moment';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { MedicalHistoryCard, PatientProfileCard, ReportCard } from "../../../components/Card/Cards";
import Header from "../../../components/Header";
import MemberShipModel from '../../../components/Modal/MemberShipModel';
import Sidebar from "../../../components/Sidebar";
import { CustomTable } from "../../../components/Table";
import { CommonHeadingTxt } from "../../../components/Text/Text";
import { ActionGetCCPaperDetail, GetCustomerDashboard } from "../../../store/Actions/PatientAction";
import { ReportCardData } from "../../../utils/Data";
import { AppointmentHistory, Medicitionscolumn } from "../../../utils/TabelData";

const PatientProfile = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const patientId = searchParams.get("patientId");
    const pageValue = searchParams.get("page");
    const { CustomerDashbord, CcPaperDetail, loader } = useSelector(state => state?.patient);
    const [modal, SetModal] = useState({ is: false, id: "" });

    useEffect(() => {
        if (patientId) {
            dispatch(ActionGetCCPaperDetail({ Query: `/${patientId}`, obj: undefined }))
            dispatch(GetCustomerDashboard({ Query: `/${patientId}`, obj: undefined }))
        }
    }, [])

    const column = [
        {
            title: "Name",
            dataIndex: "doctor",
            render: (text, record) => (
                <span>{text?.name}</span>
            ),
        },
        {
            title: "Start Time",
            dataIndex: "startTime",

        },
        {
            title: "Consulting For",
            dataIndex: "consultingFor",

        },
        {
            title: "Date",
            dataIndex: "date",
        },
        {
            dataIndex: "key",
            key: "key",
            render: (text, record, num) => {
                return (
                    <div className="dropdown dropdown-action">
                        <Link
                            to="#"
                            className="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="fa fa-ellipsis-v" />
                        </Link>
                        <div className="dropdown-menu dropdown-menu-end">
                            <Link
                                className="dropdown-item"
                            // to="/appoinmentlist"
                            >
                                <i className="fa-solid fa-pen-to-square m-r-5" />{" "}
                                Edit
                            </Link>
                            <Link
                                className="dropdown-item"
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#delete_patient"
                            >
                                <i className="fa fa-trash-alt m-r-5"></i> Delete
                            </Link>
                        </div>
                    </div>
                )
            },
        },
    ];
    const membershipColumn = [
        {
            title: "Start Date",
            dataIndex: "startDate",
            render: (text, record) => (
                <span>{moment(record?.startDate).format('YYYY-MM-DD')}</span>
            ),
        },
        {
            title: "End Date",
            dataIndex: "endDate",
            render: (text, record) => (
                <span>{moment(record?.endDate).format('YYYY-MM-DD')}</span>
            ),
        },
        {
            title: "Membership Type",
            dataIndex: "memberShipType",

        },
        {
            title: "Membership Days",
            dataIndex: "memberShipDays",

        },
        {
            title: " TotalMembership Days",
            dataIndex: "totalMemberShipDays",
            render: (text, record) => (
                <span>{CustomerDashbord?.[0]?.customer?.totalMemberShipDays}</span>
            ),

        },
        {
            title: "Room Type",
            dataIndex: "roomType",

        },

        {
            title: " Type",
            dataIndex: "type",

        },
        {
            title: " Programme",
            dataIndex: "programme",

        },
        {
            title: "Financial Year",
            dataIndex: "financialYear",
            render: (financialYearData) => {
                const fyData = Object.keys(financialYearData[0]);
                return fyData.join(", ");
            },
        },

    ]
    const bookingHistory = CustomerDashbord?.[0]?.bookingsHistory;
    const allBookingsHistory = [...bookingHistory?.treatments || [],
    ...bookingHistory?.services || [],
    ...bookingHistory?.solutions || [],
    ...bookingHistory?.detoxRetreat || []
    ]

    const BookingHistory = [
        {
            title: "Customer Name",
            dataIndex: "customerName",

        },
        {
            title: "Medi day-care Name",
            dataIndex: "serviceName",
            render: (text, record, index) => {
                return allBookingsHistory[index]?.serviceData?.map((service) => service?.name).join(",") || "-"
            },
        },
        {
            title: "Treatment",
            dataIndex: "treatmentName",
            render: (text, record, index) => {
                const customerId = record?.id;
                return (
                    <Link to={`/indoor-casepaperDetails/${customerId}?slotId=${customerId}&bookingfor=${record?.orderId?.bookingFor}`}>
                        {allBookingsHistory[index]?.treatmentData?.[0]?.treatmentName || "-"}
                    </Link>
                );
            },
        },
        {
            title: "Detox-program Name",
            dataIndex: "solutionName",
            render: (text, record, index) => {
                const customerId = record?.id;
                return (
                    <Link to={`/indoor-casepaperDetails/${customerId}?slotId=${customerId}&bookingfor=${record?.orderId?.bookingFor}`}>
                        {allBookingsHistory[index]?.solutionData?.[0]?.solutionName || "-"}
                    </Link>
                );
            },
        },
        {
            title: "Detox-Retreat",
            dataIndex: "detoxRetreatName",
            render: (text, record, index) => {
                return allBookingsHistory[index]?.detoxRetreatData?.[0]?.detoxRetreatName || "-"
            },
        },
        {
            title: "Date(Check-in/Check-out)",
            dataIndex: "dateRange",
            render: (text, record) => {
                const startDate = record?.startDate;
                const endDate = record?.endDate;
                const date = record?.date;
                let formattedDateRange = "";
                if (startDate && endDate) {
                    formattedDateRange = `${startDate} To ${endDate}`;
                } else if (date) {
                    formattedDateRange = date;
                } else {
                    formattedDateRange = "-";
                }
                return <span>{formattedDateRange}</span>;
            }
        }

    ];
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        <div className="row">
                            <div className="col-sm-7 col-6">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={`/patient-list?page=${pageValue || 1}`}>Patient</Link></li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right">
                                            <FeatherIcon icon="chevron-right" />
                                        </i>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={`/patient-list?page=${pageValue || 1}`}>Patient List</Link></li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right">
                                            <FeatherIcon icon="chevron-right" />
                                        </i>
                                    </li>
                                    <li className="breadcrumb-item active">Patient Profile</li>
                                </ul>
                            </div>
                        </div>
                        <div className="card-box profile-header">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="profile-view">
                                        {/* <div className="profile-img-wrap">
                                            <div className="profile-img">
                                                <Link to="#"><img className="avatar" style={{ width: "100%", height: "100%" }} src={profile} alt="#" /></Link>
                                            </div>
                                        </div> */}
                                        <div className="profile-basic">
                                            <div className="row">
                                                <div className="col-md-5 col-xxl-4">
                                                    <div className="profile-info-left">
                                                        <h3 className="user-name m-t-0 mb-0">{CcPaperDetail?.name}</h3>
                                                        <small className="text-muted">Age:{CcPaperDetail?.age}</small>
                                                        <div className="staff-msg"><Link to={`/20minform?id=${patientId}&&for=125&&update=false`} className="btn btn-primary p-md-3">View Case paper</Link></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <ul className="personal-info">
                                                        <li>
                                                            <span className="title">Phone:</span>
                                                            <span className="text"><Link to>{CcPaperDetail?.phoneNo}</Link></span>
                                                        </li>
                                                        <li>
                                                            <span className="title">Email:</span>
                                                            <span className="text"><Link to>{CcPaperDetail?.email}</Link></span>
                                                        </li>
                                                        <li>
                                                            <span className="title">Birthday:</span>
                                                            <span className="text">{(CcPaperDetail?.birthDate && moment(CcPaperDetail?.birthDate).format('DD/MM/YYYY') || '---')}</span>
                                                        </li>
                                                        {/* <li>
                                                            <span className="title">Address:</span>
                                                            <span className="text">{CcPaperDetail?.}</span>
                                                        </li> */}
                                                        <li>
                                                            <span className="title">Gender:</span>
                                                            <span className="text">{CcPaperDetail?.gender}</span>
                                                        </li>
                                                    </ul>
                                                </div>


                                                <div className="col-md-2 col-xxl-3">
                                                    <div className="mail-view-action">
                                                        <div className="staff-msg"><Button className='btn btn-primary ' onClick={() => SetModal({ is: true, id: patientId })}>Add MemberShip</Button>
                                                            {/* <Link to={`/memberShip?id=${patientId}&&for=125&&update=false`} onClick={() => SetModal({ is: true, id: patientId })} className="btn btn-primary p-md-3">Add MemberShip</Link> */}</div>
                                                    </div>
                                                </div>

                                                <div className="d-flex">
                                                    <PatientProfileCard />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-md-4 my-5">
                            <div className="col-12 col-md-12  col-xl-12 my-3">
                                <div className="card h-100 mb-3">
                                    <CommonHeadingTxt Text="Membership Details" />
                                    <CustomTable
                                        column={membershipColumn}
                                        dataSource={CustomerDashbord?.[0]?.customer?.memberShipDetails || []}
                                        CurrentPageNum={1}
                                        loader={loader}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12  col-xl-6 ">
                                <div className="card h-100 mb-3">
                                    <CommonHeadingTxt Text="Upcoming Appointments" />
                                    <CustomTable
                                        column={column}
                                        dataSource={CustomerDashbord?.[0]?.upComingAppointment}
                                        CurrentPageNum={1}
                                        loader={loader}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12  col-xl-6">
                                <div className="card h-100 mt-lg-0 mt-3">
                                    <CommonHeadingTxt Text="Current Medications" />
                                    <CustomTable
                                        column={Medicitionscolumn}
                                        dataSource={CustomerDashbord?.[0]?.medicals?.[0]?.purchasedMedicines}
                                        CurrentPageNum={1}
                                        loader={loader}

                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row my-md-4 my-5">
                            <div className="col-12 col-md-12  col-xl-4">
                                <div className="card h-100 mb-3">
                                    <CommonHeadingTxt Text="Appointment History" />
                                    <CustomTable
                                        column={AppointmentHistory}
                                        dataSource={CustomerDashbord?.[0]?.appointmentHistory}
                                        CurrentPageNum={1}
                                        loader={loader}

                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12  col-xl-8">
                                <div className="card h-100 mt-lg-0 mt-3">
                                    <CommonHeadingTxt Text="Bookings History" />
                                    <CustomTable
                                        column={BookingHistory}
                                        dataSource={allBookingsHistory}
                                        showPagination={false}
                                        loader={loader}
                                    // Header={<Link to='/indor-casepaper'> Indoor-casepaper</Link>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row my-lg-4 my-5">
                            <div className="col-12 col-md-12  col-xl-4">
                                <ReportCard
                                    Heading="Consultation"
                                    Data={CustomerDashbord?.[0]?.consultation}
                                    style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#dee2e6", borderRadius: 8 }}
                                />
                            </div>
                            <div className="col-12 col-md-12  col-xl-4">
                                <MedicalHistoryCard
                                    Heading="Medical Document"
                                    style={{ borderStyle: "solid", borderWidth: 1, borderColor: "#dee2e6", borderRadius: 8, padding: 10 }}
                                    Data={CustomerDashbord?.[0]?.customer?.laboratoryDocs}
                                    Children={null} />
                            </div>
                            <div className="col-12 col-md-12  col-xl-4">
                                <ReportCard
                                    Heading="Reports"
                                    Data={ReportCardData}
                                />
                            </div>
                        </div>

                    </div>

                </div>

            </>
            <MemberShipModel IsOpen={modal} Isclose={() => SetModal({ is: false, id: "" })} />
        </>

    )
};

export default PatientProfile;
