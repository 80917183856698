/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Formik } from "formik";
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, DatePicker } from "antd";
import { InputFromUser } from "../../components/Input/input";
import UploadImage from "../../components/Upload/Upload";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { CreateService, GetAllservice } from "../../store/Actions/ServiceAction";
import { getAdminuUerDetails } from "../../store/Actions/AuthActions";
import { ActionUpdateById, ActionUploadImage } from "../../store/Actions/CommonAction";
import { ServiceValidationSchema } from "../../utils/FormValidation";
import { ExpendableTable } from "../../components/Table/expendableTable";
import { isNullOrUndef } from "../../utils/Funcations";
import { DOCS_URL } from "../../utils/globals";
const { RangePicker } = DatePicker;

const AddEditService = () => {
    const dispatch = useDispatch();
    const { userDetails } = useSelector(state => state?.auth);
    const { Servicelist, loader } = useSelector(state => state?.Service);

    const userId = userDetails?.[0]?._id
    const url = new URL(window.location.href);
    const searchParams = url.searchParams
    const idValue = searchParams.get("id");
    const idValue2 = searchParams.get("page");
    const updateValue = searchParams.get("update");
    useEffect(() => {
        dispatch(GetAllservice({ Query: '/' + idValue }));
    }, [idValue])
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])
    return (
        <div>
            <Header />
            <Sidebar id="menu-item1" id1="menu-items1" activeClassName="add-staff" />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/service-list?page=1">Services </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Add Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* /Page Header */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Formik
                                            enableReinitialize={updateValue || true}
                                            initialValues={{
                                                name: Servicelist?.[0]?.name
                                                    || {
                                                    en: "",
                                                    hi: "",
                                                    mr: ""
                                                },
                                                description: Servicelist?.[0]?.description || {
                                                    en: "",
                                                    hi: "",
                                                    mr: ""
                                                },
                                                createdBy: userId,
                                                whoIsThisFor: Servicelist?.[0]?.whoIsThisFor || [''],

                                                listingViewImage: Servicelist?.[0]?.listingViewImage || [""],
                                                price: Servicelist?.[0]?.price || "",
                                                shortDescription: Servicelist?.[0]?.shortDescription || {
                                                    en: "",
                                                    hi: "",
                                                    mr: ""
                                                },
                                                type: "hello",
                                                orderIndex: Servicelist?.[0]?.orderIndex || "",
                                                totalMinute: "30 Minute",
                                                timeSlot: "30",
                                                benefitsOfService: Servicelist?.[0]?.benefitsOfService || [{ "title": "", "description": "", "orderIndex": '' }],
                                                howServiceWorks: Servicelist?.[0]?.howServiceWorks || [{ "title": "", "description": "", "icon": '', "orderIndex": '' }],
                                                preparationAndAftercare: Servicelist?.[0]?.preparationAndAftercare || [{ "title": "", "description": "", "icon": '', "orderIndex": '' }],
                                                serviceFaqs: Servicelist?.[0]?.serviceFaqs || [{ "title": "", "description": "", "orderIndex": '' }],
                                                serviceFor: Servicelist?.[0]?.serviceFor || [""],
                                                preparationAndAftercareDescription: Servicelist?.[0]?.preparationAndAftercareDescription || "",
                                                preparationAndAftercareImage: Servicelist?.[0]?.preparationAndAftercareImage || [],
                                            }}
                                            validationSchema={ServiceValidationSchema}
                                            onSubmit={(values, { setErrors, setSubmitting }) => {
                                                if (updateValue && idValue != null) {
                                                    dispatch(ActionUpdateById({ Query: `admin/service/${idValue}`, obj: values }
                                                        , (Res) => {
                                                            if (Res?.statusCode === 200) {
                                                                window.location.href = `/service-list?page=1`
                                                            } else if (Res?.statusCode === 400) {
                                                                setErrors(Res?.data?.errors);
                                                            }
                                                        }));
                                                    setSubmitting(false);
                                                } else {

                                                    dispatch(CreateService(values, (Response) => {
                                                        if (Response?.statusCode === 200) {
                                                            window.location.href = "/service-list?page=1"
                                                        }

                                                    }))
                                                    setSubmitting(false);
                                                }
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                setFieldValue,
                                                setFieldTouched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                setValues,
                                                setSubmitting,
                                                isSubmitting,
                                                /* and other goodies */
                                            }) => {
                                                return (
                                                    <div>
                                                        <div className="row">
                                                            <div className="my-4" >
                                                                <Divider style={{ fontWeight: "600" }}>Services</Divider></div>
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Enter Your Service Name"}
                                                                FormControlClassName={'form-control '}
                                                                label="Service Name"
                                                                name="name?.en"
                                                                error={errors?.name?.en}
                                                                touched={touched?.name?.en}
                                                                value={values?.name?.en}
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.name
                                                                    UpdateValue.en = v?.target?.value
                                                                    setFieldValue("name", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}

                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Marathi"}
                                                                FormControlClassName={'form-control '}
                                                                label="*Marathi"
                                                                name="name.mr"
                                                                value={values?.name?.mr}
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.name
                                                                    UpdateValue.mr = v?.target?.value
                                                                    setFieldValue("name", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.name?.mr}
                                                                touched={touched?.name?.mr}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Hindi"}
                                                                FormControlClassName={'form-control '}
                                                                label="*Hindi"
                                                                name="name.hi"
                                                                value={values?.name?.hi}
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.name
                                                                    UpdateValue.hi = v?.target?.value
                                                                    setFieldValue("name", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.name?.hi}
                                                                touched={touched?.name?.hi}

                                                            />

                                                            <div className="row mt-4">
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Your Service Descriptions"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Description"
                                                                    name="description.en"
                                                                    value={values?.description?.en}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.description
                                                                        UpdateValue.en = v?.target?.value
                                                                        setFieldValue("description", UpdateValue, true)

                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.description?.en}
                                                                    touched={touched?.description?.en}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Marathi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Marathi"
                                                                    name="description.mr"
                                                                    value={values?.description?.mr}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.description
                                                                        UpdateValue.mr = v?.target?.value
                                                                        setFieldValue("description", UpdateValue, true)

                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.description?.mr}
                                                                    touched={touched?.description?.mr}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Hindi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Hindi"
                                                                    name="description.hi"
                                                                    value={values?.description?.hi}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.description
                                                                        UpdateValue.hi = v?.target?.value
                                                                        setFieldValue("description", UpdateValue, true)

                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.description?.hi}
                                                                    touched={touched?.description?.hi}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Service Price"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Service Price"
                                                                    name="price"
                                                                    onChange={handleChange}
                                                                    value={values?.price}
                                                                    onBlur={handleBlur}
                                                                    type={"number"}
                                                                    error={errors?.price}
                                                                    touched={touched?.price}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Order Index"}
                                                                    FormControlClassName={'form-control'}
                                                                    onChange={handleChange}
                                                                    value={values?.orderIndex}
                                                                    onBlur={handleBlur}
                                                                    label="Order Index"
                                                                    type={"number"}
                                                                    name="orderIndex"
                                                                    error={errors?.orderIndex}
                                                                    touched={touched?.orderIndex}
                                                                />
                                                            </div>

                                                            <div className="row mt-4">
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Your Service Descriptions"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Short Description"
                                                                    name="shortDescriptionEn"
                                                                    value={values?.shortDescription?.en}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.shortDescription
                                                                        UpdateValue.en = v?.target?.value
                                                                        setFieldValue("shortDescription", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.shortDescription?.en}
                                                                    touched={touched?.shortDescription?.en}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Marathi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Marathi"
                                                                    name="shortDescriptionMarathi"
                                                                    value={values?.shortDescription?.mr}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.shortDescription
                                                                        UpdateValue.mr = v?.target?.value
                                                                        setFieldValue("shortDescription", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.shortDescription?.mr}
                                                                    touched={touched?.shortDescription?.mr}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Hindi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Hindi"
                                                                    name="shortDescriptionHindi"
                                                                    value={values?.shortDescription?.hi}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.shortDescription
                                                                        UpdateValue.hi = v?.target?.value
                                                                        setFieldValue("shortDescription", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.shortDescription?.hi}
                                                                    touched={touched?.shortDescription?.hi}
                                                                />

                                                            </div>
                                                            <div className="col-xl-4 col-md-12">
                                                                <div className="d-flex justify-content-evenly align-items-center ">
                                                                    <label className="col-form-label">
                                                                        {"Upload Service Image:-"}
                                                                    </label>
                                                                    <div>
                                                                        <UploadImage
                                                                            className={"img-fluid"}
                                                                            fileList={!isNullOrUndef(values?.listingViewImage) && [{
                                                                                uid: 1 + values?.listingViewImage,
                                                                                name: values?.listingViewImage,
                                                                                status: 'done',
                                                                                url: DOCS_URL + values?.listingViewImage,
                                                                            }] || []}
                                                                            handleChange={(v) => {
                                                                                if (v.file && v.file.status === 'uploading') {
                                                                                    const formData = new FormData();
                                                                                    formData.append("isService", true);
                                                                                    formData.append("attachment", v?.file?.originFileObj);
                                                                                    dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                        if (typeof res === 'object') {
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: 'listingViewImage',
                                                                                                    value: [res?.[0]],
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    }))
                                                                                } else if (v.file && v.file.status === 'removed') {
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'listingViewImage',
                                                                                            value: '',
                                                                                        },
                                                                                    });
                                                                                }
                                                                            }}
                                                                            // errorMessage={errors?.listingViewImage}
                                                                            UploadImagelimit={1} />
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <ExpendableTable
                                                                Title={"Benefits"}
                                                                emptyDataForCreatingField={{ "title": "", "description": "", "orderIndex": '', "icon": '' }}
                                                                Data={values?.benefitsOfService}
                                                                Columns={[
                                                                    { name: 'title', type: 'text', label: 'Title' },
                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'icon', type: 'upload', label: 'Icon' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("benefitsOfService", v, true)}
                                                                onBlur={(v) => setFieldTouched("benefitsOfService", v, true)}
                                                                errors={errors?.benefitsOfService}
                                                                touched={touched?.benefitsOfService}
                                                            />
                                                            <ExpendableTable
                                                                Title={"How Service Works"}
                                                                emptyDataForCreatingField={{ "title": "", "description": "", "icon": '', "orderIndex": '' }}
                                                                Data={values?.howServiceWorks}
                                                                Columns={[
                                                                    { name: 'title', type: 'text', label: 'Title' },
                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'icon', type: 'upload', label: 'Icon' },
                                                                    { name: 'Action', type: 'action', label: 'Icon' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("howServiceWorks", v, true)}
                                                                onBlur={(v) => setFieldTouched("howServiceWorks", v, true)}
                                                                errors={errors?.howServiceWorks}
                                                                UploadImageFlag={'isService'}
                                                                touched={touched?.howServiceWorks}
                                                            />

                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Who Is This For</Divider></div>
                                                                {values?.whoIsThisFor?.map((item, index) => {
                                                                    return (
                                                                        <tr key={"whoIsThisFor" + index + 'tr'}>
                                                                            <div className="d-flex">
                                                                                <td className="col-xl-6 col-md-10" key={"whoIsThisFor" + index + 'td'}>
                                                                                    {
                                                                                        <InputFromUser
                                                                                            placeholder={'Enter whoIsThisFor'}
                                                                                            error={errors?.whoIsThisFor}
                                                                                            value={item}
                                                                                            onChange={(v) => {
                                                                                                const updatedProgrammeFor = [...(values?.whoIsThisFor || [])];
                                                                                                updatedProgrammeFor[index] = v?.target?.value;
                                                                                                setFieldValue("whoIsThisFor", updatedProgrammeFor, true)
                                                                                            }}
                                                                                            touched={touched?.whoIsThisFor}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                                <td className="col-2 add-remove text-end align-items-center" key={"td1" + index}>
                                                                                    <Button
                                                                                        type=""
                                                                                        className="add-btn "
                                                                                        onClick={() => {
                                                                                            const newValue = [''];
                                                                                            setFieldValue("whoIsThisFor", [...values.whoIsThisFor, newValue])
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                    </Button>
                                                                                    <Button
                                                                                        style={{ border: 'none' }}
                                                                                        className="remove-btn"
                                                                                        onClick={() => {
                                                                                            if (index !== 0) {
                                                                                                const updatedBenefits = [...values.whoIsThisFor];
                                                                                                updatedBenefits.splice(index, 1);
                                                                                                setFieldValue("whoIsThisFor", updatedBenefits);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <i className="fe fe-trash-2">
                                                                                            <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                        </i>
                                                                                    </Button>
                                                                                </td>
                                                                            </div>

                                                                        </tr>
                                                                    )

                                                                })}
                                                            </div>
                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Service For</Divider></div>
                                                                <div className="invoice-add-table form-group " >
                                                                    <div className="table-responsive " >
                                                                        <table className="table table-center add-table-items" >
                                                                            <tbody>
                                                                                {values?.serviceFor?.map((item, index) => {
                                                                                    return (
                                                                                        <tr key={"serviceFor" + index + 'tr'}>
                                                                                            <div className="d-flex">
                                                                                                <td className="col-xl-6 col-md-10" key={"serviceFor" + index + 'td'} style={{ borderColor: errors?.medicines?.[index]?.medicineName && touched.medicines?.[index]?.medicineName && 'red' }}>
                                                                                                    {
                                                                                                        <InputFromUser
                                                                                                            placeholder={'Enter Name'}
                                                                                                            value={item}
                                                                                                            onChange={(v) => {
                                                                                                                const updatedServiceFor = [...(values?.serviceFor || [])];
                                                                                                                updatedServiceFor[index] = v?.target?.value;
                                                                                                                setFieldValue("serviceFor", updatedServiceFor, true)
                                                                                                            }}
                                                                                                            touched={touched?.serviceFor}
                                                                                                            error={errors?.serviceFor}
                                                                                                        />
                                                                                                    }
                                                                                                </td>
                                                                                                <td className="col-2 add-remove text-end align-items-center" key={"td6" + index}>
                                                                                                    <Button
                                                                                                        type=""
                                                                                                        className="add-btn "
                                                                                                        onClick={() => {
                                                                                                            const newValue = [''];
                                                                                                            setFieldValue("serviceFor", [...values.serviceFor, newValue])
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                                    </Button>
                                                                                                    <Button
                                                                                                        style={{ border: 'none' }}
                                                                                                        className="remove-btn"
                                                                                                        onClick={() => {
                                                                                                            if (index !== 0) {
                                                                                                                const updatedBenefits = [...values.serviceFor];
                                                                                                                updatedBenefits.splice(index, 1);
                                                                                                                setFieldValue("serviceFor", updatedBenefits);
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="fe fe-trash-2">
                                                                                                            <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                                        </i>
                                                                                                    </Button>
                                                                                                </td>
                                                                                            </div>


                                                                                        </tr>
                                                                                    )

                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <ExpendableTable
                                                                Title={"Preparation And Aftercare"}
                                                                emptyDataForCreatingField={{ "title": "", "description": "", "icon": '', "orderIndex": '' }}
                                                                Data={values?.preparationAndAftercare}
                                                                Columns={[
                                                                    { name: 'title', type: 'text', label: 'Title' },
                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'icon', type: 'upload', label: 'Icon' },
                                                                    { name: 'Action', type: 'action', label: 'Icon' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("preparationAndAftercare", v, true)}
                                                                onBlur={(v) => setFieldTouched("preparationAndAftercare", v, true)}
                                                                errors={errors?.preparationAndAftercare}
                                                                touched={touched?.preparationAndAftercare}
                                                                UploadImageFlag={'isService'}
                                                                HeaderComponent={<div className="row">
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Enter Description"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="Preparation And Aftercare Descriptions"
                                                                        name="preparationAndAftercareDescription"
                                                                        touched={touched?.preparationAndAftercareDescription}
                                                                        error={errors?.preparationAndAftercareDescription}
                                                                        value={values?.preparationAndAftercareDescription}
                                                                        onChange={(v) => {
                                                                            const updateValue = v?.target?.value
                                                                            setFieldValue("preparationAndAftercareDescription", updateValue, true)
                                                                        }}
                                                                    />
                                                                    <div className="col-xl-4 col-md-12">
                                                                        <div className="d-flex justify-content-evenly align-items-center ">
                                                                            <label className="col-form-label">
                                                                                {"Upload Preparation And After Care Image:-"}
                                                                            </label>
                                                                            <div>
                                                                                <UploadImage
                                                                                    className={"img-fluid"}
                                                                                    fileList={!isNullOrUndef(values?.preparationAndAftercareImage) && [{
                                                                                        uid: 1 + values?.preparationAndAftercareImage,
                                                                                        name: values?.preparationAndAftercareImage,
                                                                                        status: 'done',
                                                                                        url: DOCS_URL + values?.preparationAndAftercareImage,
                                                                                    }] || []}
                                                                                    handleChange={(v) => {
                                                                                        if (v.file && v.file.status === 'uploading') {
                                                                                            const formData = new FormData();
                                                                                            formData.append("isService", true);
                                                                                            formData.append("attachment", v?.file?.originFileObj);
                                                                                            dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                                if (typeof res === 'object') {
                                                                                                    handleChange({
                                                                                                        target: {
                                                                                                            name: 'preparationAndAftercareImage',
                                                                                                            value: [res?.[0]],
                                                                                                        },
                                                                                                    });
                                                                                                }
                                                                                            }))
                                                                                        } else if (v.file && v.file.status === 'removed') {
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: 'preparationAndAftercareImage',
                                                                                                    value: '',
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                    //  errorMessage={errors?.listingViewImage}
                                                                                    UploadImagelimit={1} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            />


                                                            <ExpendableTable
                                                                Title={"Service Faqs"}
                                                                emptyDataForCreatingField={{ "title": "", "description": "", "orderIndex": '' }}
                                                                Data={values?.serviceFaqs}
                                                                Columns={[
                                                                    { name: 'title', type: 'text', label: 'Title' },
                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'Action', type: 'action', label: 'Icon' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("serviceFaqs", v, true)}
                                                                onBlur={(v) => setFieldTouched("serviceFaqs", v, true)}
                                                                errors={errors?.serviceFaqs}
                                                                touched={touched?.serviceFaqs}
                                                            />

                                                            <div className="col-12">
                                                                <div className="text-end">
                                                                    <Button
                                                                        onClick={() => {
                                                                            handleSubmit();
                                                                        }}
                                                                        type="primary"
                                                                        //disabled={isSubmitting}
                                                                        className="pb-4"
                                                                    >Submit</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div >
    );
};

export default AddEditService;
