/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { getAdminuUerDetails } from "../store/Actions/AuthActions";
import { matchPermissions } from '../utils/Permission';
import {
  Assets,
  booking,
  dashboard,
  hr, medicalstore,
  order,
  patient,
  report
} from './imagepath';
const Sidebar = (props) => {
  const [sidebar, setSidebar] = useState("");
  const dispatch = useDispatch()
  const { userDetails } = useSelector(state => state?.auth);
  const handleClick = (e, item, item1) => {
    const div = document.getElementById(item);
    const ulDiv = document.querySelector(`.${item1}`);

    if (ulDiv) {
      ulDiv.style.display = ulDiv.style.display === 'none' ? 'block' : 'none';
      div.classList.toggle('subdrop');
    }
  };

  useEffect(() => {
    if (props?.id && props?.id1) {
      const ele = document.getElementById(`${props?.id}`);
      handleClick(ele, props?.id, props?.id1);
    }
  }, [])
  useEffect(() => {
    dispatch(getAdminuUerDetails())
  }, [dispatch])
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };

  const matchPermissionsForUser = matchPermissions(userDetails);
  return (
    <>

      <div className="sidebar" id="sidebar">
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="90vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu"
              onMouseLeave={expandMenu}
              onMouseOver={expandMenuOpen}
            >
              <ul>

                {/* Dashboard  */}

                <li className="submenu" >
                  <Link to="#" id="menu-item14" onClick={(e) => {

                    handleClick(e, "menu-item14", "menu-item_14")
                  }}>
                    <span className="menu-side">
                      <img src={dashboard} alt="" />
                    </span>{" "}
                    <span>Dashboard</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-item_14'>

                    {matchPermissionsForUser('room.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'admin-dashboard' ? 'active' : ''} to="/room-dashboard">Rooms</Link>
                      </li>
                    }
                    <li>
                      <Link className={props?.activeClassName === 'admin-dashboard' ? 'active' : ''} to="/admin-dashboard">Dashboard</Link>
                    </li>
                    {/* <li>
                      <Link className={props?.activeClassName === 'admin-dashboard' ? 'active' : ''} to="/doctor-dashboard">Doctor Dashboard</Link>
                    </li> */}
                  </ul>
                </li>

                {/* Bookings */}
                <li className="submenu" >
                  <Link to="#" id="menu-item18" onClick={(e) => {
                    handleClick(e, "menu-item18", "menu-item_18")
                  }}>
                    <span className="menu-side">
                      <img src={booking} alt="" />
                    </span>{" "}
                    <span>Bookings</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-item_18'>

                    {matchPermissionsForUser('appointment.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'admin-dashboard' ? 'active' : ''} to="/appoinment-list?page=1">Doctor Consultations</Link>
                      </li>
                    }
                    {matchPermissionsForUser('treatment-slot.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/treatment-slot?page=1">Treatment</Link>
                      </li>
                    }
                    {matchPermissionsForUser('solution-slot.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/solution-slot?page=1"> Detox Programs</Link>
                      </li>
                    }
                    {matchPermissionsForUser('service-slot.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/service-slot?page=1">Medi Daycare Rituals</Link>
                      </li>
                    }

                    {matchPermissionsForUser('room-slot.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/room-slot?page=1">Room slot Booking</Link>
                      </li>
                    }
                    {!matchPermissionsForUser('retreat-slot.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/detox-retreat-booking?page=1">Retreat</Link>
                      </li>
                    }
                    {!matchPermissionsForUser('program-slot.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/program-booking?page=1">Programme</Link>
                      </li>
                    }
                  </ul>
                </li>

                {/* Order Management */}
                <li className="submenu" >
                  <Link to="#" id="menu-item19" onClick={(e) => {
                    handleClick(e, "menu-item19", "menu-item_19")
                  }}>
                    <span className="menu-side">
                      <img src={order} alt="" />
                    </span>{" "}
                    <span>Order Management</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-item_19'>

                    {matchPermissionsForUser('order.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/order-history?page=1">Order & History</Link>
                      </li>
                    }
                    {!matchPermissionsForUser('pending.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/pending-history?page=1">Pending & History</Link>
                      </li>
                    }
                  </ul>
                </li>

                {/* Patient Management */}
                <li className="submenu" >
                  <Link to="#" id="menu-item20" onClick={(e) => {
                    handleClick(e, "menu-item20", "menu-item_20")
                  }}>
                    <span className="menu-side">
                      <img src={patient} alt="" />
                    </span>{" "}
                    <span>Patient Management</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-item_20'>

                    {matchPermissionsForUser('patient.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/patient-list?page=1">Patients Masterdata</Link>
                      </li>
                    }
                    {!matchPermissionsForUser('pending.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/verify-case">Patients Verification</Link>
                      </li>
                    }
                    {!matchPermissionsForUser('pending.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/verify-member">Members Verification</Link>
                      </li>
                    }
                    {!matchPermissionsForUser('pending.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/patientconfirmation">Patients Confirmation</Link>
                      </li>
                    }
                    {!matchPermissionsForUser('pending.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/assigneddoctor">Assigned Doctor</Link>
                      </li>
                    }
                  </ul>
                </li>

                {/*Medical Store */}

                <li className="submenu" >
                  <Link to="#" id="menu-item21" onClick={(e) => {

                    handleClick(e, "menu-item21", "menu-item_21")
                  }}>
                    <span className="menu-side">
                      <img src={medicalstore} alt="" />
                    </span>{" "}
                    <span>Medical Store</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-item_21'>
                    {matchPermissionsForUser('medical.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'admin-dashboard' ? 'active' : ''} to="/medical-store?page=1">Dispense Medicines</Link>
                      </li>
                    }
                    {matchPermissionsForUser('medical-history.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/medical-history?page=1">Medicines Dispensing History</Link>
                      </li>
                    }

                  </ul>
                </li>

                {/* HR  */}

                <li className="submenu" >
                  <Link to="#" id="menu-item22" onClick={(e) => {
                    handleClick(e, "menu-item22", "menu-item_22")
                  }}>
                    <span className="menu-side">
                      <img src={hr} alt="" />
                    </span>{" "}
                    <span>HR</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-item_22'>

                    {matchPermissionsForUser('leave.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'admin-dashboard' ? 'active' : ''} to="/leaves?page=1">Leave Management</Link>
                      </li>
                    }
                    {matchPermissionsForUser('holiday.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/holidays?page=1">Prakruti Holidays</Link>
                      </li>
                    }

                  </ul>
                </li>

                {/* Public Relations  */}

                <li className="submenu" >
                  <Link to="#" id="menu-item23" onClick={(e) => {
                    handleClick(e, "menu-item23", "menu-item_23")
                  }}>
                    <span className="menu-side">
                      <img src={booking} alt="" />
                    </span>{" "}
                    <span>Public Relations</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-item_23'>

                    {matchPermissionsForUser('leave.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'admin-dashboard' ? 'active' : ''} to="/birthday-list">Today&#39;s Birthdays</Link>

                      </li>
                    }
                    {matchPermissionsForUser('treatment-protocol.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'patient-dashboard' ? 'active' : ''} to="/treatmentprotocol-list">Treatment Protocol</Link>
                      </li>
                    }
                    <li>
                      <Link className={props?.activeClassName === 'patient-dashboard' ? 'active' : ''} to="/gallery-listing">Gallery List</Link>
                    </li>

                    {/* <li>
                      <Link className={props?.activeClassName === 'patient-dashboard' ? 'active' : ''} to="/room-count">Room Count</Link>
                    </li> */}
                    <li>
                      <Link className={props?.activeClassName === 'patient-dashboard' ? 'active' : ''} to="/detoxretreat-list?page=1">Detox Retreat</Link>
                    </li>
                  </ul>
                </li>

                {/* Reports  */}

                <li className="submenu" >
                  <Link to="#" id="menu-item24" onClick={(e) => {
                    handleClick(e, "menu-item24", "menu-item_24")
                  }}>
                    <span className="menu-side">
                      <img src={report} alt="" />
                    </span>{" "}
                    <span>Reports</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-item_24'>


                    {/* {matchPermissionsForUser('leave.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'admin-dashboard' ? 'active' : ''} to="/birthday-list">Today&#39;s Birthdays</Link>

                      </li>
                    } */}
                  </ul>
                </li>

                <li className="submenu" >
                  <Link to="#" id="menu-item30" onClick={(e) => {
                    handleClick(e, "menu-item30", "menu-item_30")
                  }}>
                    <span className="menu-side">
                      <img src={report} alt="" />
                    </span>{" "}
                    <span>Therapist Allocation</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-item_30'>
                    {/* {matchPermissionsForUser('therapist-history.index') &&

                      <li>
                        <Link className={props?.activeClassName === 'admin-dashboard' ? 'active' : ''} to="/therapist-allocation">Therapist Allocation Table</Link>
                      </li>
                    } */}
                    {matchPermissionsForUser('therapist-history.index') &&

                      <li>
                        <Link className={props?.activeClassName === 'admin-dashboard' ? 'active' : ''} to="/therapist-history">Therapist History</Link>
                      </li>
                    }
                    {/* {matchPermissionsForUser('leave.index') &&
                      <li>
                        <Link className={props?.activeClassName === 'admin-dashboard' ? 'active' : ''} to="/birthday-list">Today&#39;s Birthdays</Link>

                      </li>
                    } */}
                  </ul>
                </li>
                {/* Software Management */}

                <li className="submenu" >
                  <Link to="#" id="menu-item25" onClick={(e) => {
                    handleClick(e, "menu-item25", "menu-item_25")
                  }}>
                    <span className="menu-side">
                      <img src={hr} alt="" />
                    </span>{" "}
                    <span>Software Management</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-item_25'>
                    {/*------- Manage Access ------- */}

                    <li className="submenu" >
                      <Link to="#" id="menu-item26" onClick={(e) => {
                        handleClick(e, "menu-item26", "menu-item_26")
                      }}>
                        <span>Manage Access</span> <span className="menu-arrow" />
                      </Link>
                      <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-item_26'>
                        {matchPermissionsForUser('admin-user.index') &&
                          <li>
                            <Link className={props?.activeClassName === 'admin-dashboard' ? 'active' : ''} to="/staff-list?page=1">Staff</Link>
                          </li>
                        }
                        {matchPermissionsForUser('role.index') &&
                          <li>
                            <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/roles?page=1">Roles</Link>
                          </li>
                        }
                        {matchPermissionsForUser('permission.index') &&
                          <li>
                            <Link className={props?.activeClassName === 'patient-dashboard' ? 'active' : ''} to="/permissions?page=1">Permissions</Link>
                          </li>
                        }
                        {/* <li>
                      <Link className={props?.activeClassName === 'patient-dashboard' ? 'active' : ''} to="/medicine?page=1">Solutions</Link>
                    </li> */}
                      </ul>
                    </li>


                    {/* -------  Master Data  ------- */}

                    <li className="submenu" >
                      <Link to="#" id="menu-item27" onClick={(e) => {
                        handleClick(e, "menu-item27", "menu-item_27")
                      }}>
                        <span>Master Data</span> <span className="menu-arrow" />
                      </Link>
                      <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-item_27'>

                        {/*-----  Frontend Master Data ----- */}

                        <li className="submenu" >
                          <Link to="#" id="menu-item28" onClick={(e) => {
                            handleClick(e, "menu-item28", "menu-item_28")
                          }}>
                            <div className='d-flex align-items-center'>
                              <span className="menu-side" style={{ marginLeft: '-40px', marginRight: '12px' }}>
                                <img src={Assets} alt="" />{" "}
                              </span>
                              <span>Frontend</span>
                              <span className="menu-arrow" style={{ top: 16 }} />
                            </div>
                          </Link>
                          <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-item_28'>
                            {matchPermissionsForUser('room.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/room?page=1">Room Details</Link>
                              </li>
                            }

                            {matchPermissionsForUser('celebrity.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/celebrity-list?page=1">Celebrities</Link>
                              </li>
                            }

                            {matchPermissionsForUser('patient-testimonial.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/patient-testimonial-list?page=1">Patient Testimonial</Link>
                              </li>
                            }

                            {matchPermissionsForUser('patient-testimonial-link.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/testimonial-link-list?page=1">Testimonial Links</Link>
                              </li>
                            }

                            {matchPermissionsForUser('treatment.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/treatment-list?page=1">Treatments</Link>
                              </li>
                            }
                            {matchPermissionsForUser('programme.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/programme-list?page=1">Programmes</Link>
                              </li>
                            }
                            {matchPermissionsForUser('solution.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/solution-list?page=1">Detox Programs</Link>
                              </li>
                            }

                            {matchPermissionsForUser('service.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'admin-dashboard' ? 'active' : ''} to="/service-list?page=1">Medi Daycare Rituals List</Link>
                              </li>
                            }
                            <li>
                              <Link className={props?.activeClassName === 'patient-dashboard' ? 'active' : ''} to="/gallery-listing">Gallery List</Link>
                            </li>

                            <li>
                              <Link className={props?.activeClassName === 'patient-dashboard' ? 'active' : ''} to="/detoxretreat-list?page=1">Detox Retreat</Link>
                            </li>
                          </ul>
                        </li>

                        {/* ----- Backend Master Data ----- */}

                        <li className="submenu" >
                          <Link to="#" id="menu-item29" onClick={(e) => {
                            handleClick(e, "menu-item29", "menu-item_29")
                          }}>
                            <div className='d-flex align-items-center'>
                              <span className="menu-side" style={{ marginLeft: '-40px', marginRight: '12px' }}>
                                <img src={Assets} alt="" />{" "}
                              </span>
                              <span>Backend</span>
                              <span className="menu-arrow" style={{ top: 16 }} />
                            </div>
                          </Link>
                          <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-item_29'>
                            {matchPermissionsForUser('do-and-donts.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'admin-dashboard' ? 'active' : ''} to="/do-and-donts?page=1">Do & Donts</Link>
                              </li>
                            }

                            {matchPermissionsForUser('virechan.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/virechan?page=1">Virechan</Link>
                              </li>
                            }
                            {matchPermissionsForUser('therapist-shift.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'doctor-dashboard' ? 'active' : ''} to="/shifts?page=1">Therapist Shifts</Link>
                              </li>
                            }

                            {matchPermissionsForUser('exercise.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'patient-dashboard' ? 'active' : ''} to="/exercise?page=1">Exercise</Link>
                              </li>
                            }

                            {matchPermissionsForUser('memberShip.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'patient-dashboard' ? 'active' : ''} to="/member-ship?page=1">Member Ship</Link>
                              </li>
                            }

                            {matchPermissionsForUser('medicine.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'patient-dashboard' ? 'active' : ''} to="/medicine?page=1">Medicine</Link>
                              </li>
                            }

                            {matchPermissionsForUser('consultation.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'patient-dashboard' ? 'active' : ''} to="/price-table?page=1">Price Table</Link>
                              </li>
                            }
                            {matchPermissionsForUser('app-config.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'patient-dashboard' ? 'active' : ''} to="/app-config?page=1">App Config</Link>
                              </li>
                            }
                            {matchPermissionsForUser('therapist-count.index') &&
                              <li>
                                <Link className={props?.activeClassName === 'patient-dashboard' ? 'active' : ''} to="/therapist-count">Therapist Count</Link>
                              </li>
                            }

                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>

                </li>


              </ul>
            </div>
          </div>
        </Scrollbars >
      </div >
    </>
  )
}
export default Sidebar
