import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { GetAssignedDoctorList } from '../../store/Actions/PatientAction'
import { generateQueryString } from '../../utils/Funcations'
import TableColumnSearch1 from "../../components/Input/TableColumnSearch1"
import { TableListPageCom } from '../../components/TableListPageCom'
import { InputFromUser } from '../../components/Input/input'
import { Button, DatePicker } from 'antd'


const AssignedDoctorList = () => {
    const { AssignedDoctor, } = useSelector(state => state.patient);
    const assignedDoctorData = [
        ...AssignedDoctor?.[0]?.treatment || [],
        ...AssignedDoctor?.[0]?.solution || [],
        ...AssignedDoctor?.[0]?.service || []
    ];
    const [Refresh, setRefresh] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const params = queryString.parse(location.search);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const ascParams = searchParams.get("asc");
    const descParams = searchParams.get("desc");
    const phoneNoParams = searchParams.get("phoneNo");
    const statusParams = searchParams.get("status");
    const customerNameParams = searchParams.get("customerName");
    const doctorNameParams = searchParams.get("doctorName");
    const startDateParams = searchParams.get("startDate");
    const endDateParams = searchParams.get("endDate");
    const sortOnParams = searchParams.get("sortOn");
    const sortParams = searchParams.get("sort");
    const date = searchParams.get("date");

    const [Query, setQuery] = useState({
        'asc': ascParams,
        'desc': descParams,
        'phoneNo': phoneNoParams,
        'status': statusParams || 'Upcoming',
        'customerName': customerNameParams,
        'doctorName': doctorNameParams,
        'startDate': startDateParams,
        'endDate': endDateParams,
        'sortOn': sortOnParams,
        'sort': sortParams,
        'date': date || null,
    },);

    const Filters = generateQueryString(
        ['asc', (Query.asc)],
        ['desc', Query.desc],
        ['phoneNo', Query.phoneNo],
        ['status', Query.status],
        ['customerName', Query.customerName],
        ['doctorName', Query.doctorName],
        ['startDate', Query.startDate],
        ['endDate', Query.endDate],
        ['sortOn', Query.sortOn],
        ['sort', Query.sort],
        ['date', Query.date],

    )
    useEffect(() => {
        dispatch(GetAssignedDoctorList({ Query: Filters }));
        window.history.replaceState(null, '', Filters)
        setRefresh(false);
    }, [Refresh, Filters, Query.startDate, Query.endDate])

    const handleDateRangeChange = (dates) => {
        console.log('date', dates);
        setQuery({
            ...Query,
            RangePicker: dates,
            startDate: dates[0]?.format('YYYY-MM-DD'),
            endDate: dates[1]?.format('YYYY-MM-DD'),
            page: null
        })
    };
    const handleResetFilters = () => {
        setQuery({
            ...Query,
            RangePicker: null,
            startDate: '',
            endDate: '',
        });
    };
    const columns = [

        {
            title: "Patient",
            dataIndex: "customerName",

            sorter: (a, b) => a.customerName.length - b.customerName.length,
            ...TableColumnSearch1({
                dataIndex: 'customerName',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
            render: (text, record) => {
                return (
                    <Link to={`/patient-list/patient-profile?patientId=${record?.customerId}&page=${params?.page}`}>{text}</Link>
                )
            },

        },
        {
            title: "Phone No.",
            dataIndex: "phoneNo",
            ...TableColumnSearch1({
                dataIndex: 'phoneNo',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
        },
        {
            title: "Treatment Name",
            dataIndex: "treatmentName",
            render: (text, record, index) => {
                return assignedDoctorData[index]?.treatmentData?.[0]?.treatmentName || "-"
            },
        },
        {
            title: "Detox-program Name",
            dataIndex: "solutionName",
            render: (text, record, index) => {
                return assignedDoctorData[index]?.solutionData?.[0]?.solutionName || "-"
            },
        },
        {
            title: "Medi day-care Name",
            dataIndex: "serviceName",
            render: (text, record, index) => {
                return assignedDoctorData[index]?.serviceData?.map((service) => service?.name).join(",") || "-"
            },
        },
        {
            title: "Total Days",
            dataIndex: "totalDays",

        },
        {
            title: "Date",
            dataIndex: "dateRange",
            render: (text, record) => {
                const startDate = record?.startDate;
                const endDate = record?.endDate;
                const date = record?.date;
                let formattedDateRange = "";
                if (startDate && endDate) {
                    formattedDateRange = `${startDate} To ${endDate}`;
                } else if (date) {
                    formattedDateRange = date;
                } else {
                    formattedDateRange = "-";
                }
                return <span>{formattedDateRange}</span>;
            }
        },

        {
            title: "Assigned Doctor",
            dataIndex: "doctorName",
            sorter: (a, b) => a.doctorName.length - b.doctorName.length,
            ...TableColumnSearch1({
                dataIndex: 'doctorName',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
        },
    ];
    const BreadcrumbList = [{
        name: 'Assigned Doctor',
        linkTo: `/assigneddoctor?page=${params?.page || 1}`
    },
    {
        name: 'Assigned Doctor List',
    }
    ]
    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={assignedDoctorData}
            TableColumn={columns}
            ShowAddButton={false}
            PageMainTitle="Assigned Doctor List"
            CurrentPage={1}
            bordered={true}
            Tableloader={false}
            pageSize={90}
            LeftChild={
                <div className="col-12 col-lg-6 col-xl-6 m-3 mb-md-0" style={{ display: 'flex', alignItems: 'center' }}>
                    <DatePicker.RangePicker
                        onChange={handleDateRangeChange}
                        className="w-100"
                        value={Query.RangePicker}
                        allowClear={true}
                    />
                    <Button type="link" onClick={handleResetFilters} style={{ marginLeft: '10px' }}>Clear Filters</Button>
                </div>
            }
            RightChild={
                <div className={`dropdown-color col-12 col-md-4 ${Query.status === 'Completed' ? 'Completed' : Query.status === 'Cancelled' ? 'Cancelled' : 'Upcoming'}`}>
                    <InputFromUser
                        placeholder={""}
                        type="singleSelect"
                        name="status"
                        mode={''}
                        onChange={(v) => setQuery({ ...Query, status: v })}
                        options={["Upcoming", "Completed", "Cancelled", "InProgress"]}
                        FormGroupClassName={"col-xl-6 col-md-12 cursor-pointer m-0 p-0"}
                        LabelClassName={"col-form-label cursor-pointer"}
                        value={Query?.status}
                        label="Booking Status"
                    />
                </div>
            }
            refreshOnClick={() => { window.location.replace(`/assigneddoctor`) }}
        />);
}

export default AssignedDoctorList