import {
    GET_APPCONFIG_LIST,
    APPCONFIG_LIST_SUCCESS,
    CREATE_APPCONFIG,
    UPDATE_APPCONFIG,
    DELETE_APPCONFIG,
    GET_PATIENT_PLAN_LIST,
    GET_PATIENT_LIST_PLAN_SUCCESS,
    GET_DISCHARGE_FORM,
    GET_DISCHARGE_FORM_SUCCESS,
} from "../storeTypes";

export const ActionGetAppConfigList = (payload) => {
    return {
        type: GET_APPCONFIG_LIST,
        payload
    };
};
export const ActionAppConfigListSuccess = (payload) => {
    return {
        type: APPCONFIG_LIST_SUCCESS,
        payload
    };
};

export const ActionCreateAppConfig = (payload, callBack) => {
    return {
        type: CREATE_APPCONFIG,
        payload,
        callBack
    };
};
export const ActionUpdateAppConfig = (payload, callBack) => {
    return {
        type: UPDATE_APPCONFIG,
        payload,
        callBack
    };
};
export const ActionDeleteAppConfig = (payload, callBack) => {
    return {
        type: DELETE_APPCONFIG,
        payload,
        callBack
    };
};

export const ActionGetPatientPlanList = (payload) => {
    return {
        type: GET_PATIENT_PLAN_LIST,
        payload
    };
};

export const ActionGetPatientPlanSuccess = (payload) => {
    return {
        type: GET_PATIENT_LIST_PLAN_SUCCESS,
        payload
    };
};

export const ActionGetPatientDischargeForm = (payload) => {
    return {
        type: GET_DISCHARGE_FORM,
        payload
    };
};

export const ActionGetPatientDischargeFormSuccess = (payload) => {
    return {
        type: GET_DISCHARGE_FORM_SUCCESS,
        payload
    };
};
