/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { Formik } from "formik";
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Button, Divider, DatePicker } from "antd";
import { InputFromUser } from "../../components/Input/input";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { DOCS_URL } from "../../utils/globals";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreateByUrl, ActionGetGalleryList, ActionUpdateById, ActionUploadImage } from "../../store/Actions/CommonAction";
import UploadImage from "../../components/Upload/Upload";
import { AddGalleryValidationSchema } from "../../utils/FormValidation";
import { useEffect } from "react";
import { getAdminuUerDetails } from "../../store/Actions/AuthActions";
const { RangePicker } = DatePicker;

const AddGallery = () => {
    const dispatch = useDispatch()
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const { userDetails } = useSelector(state => state?.auth);
    const { galleryList, loader } = useSelector(state => state?.Common);
    const userId = userDetails?.[0]?._id
    const idValue = searchParams.get("id");
    const page = searchParams.get("page");
    const updateValue = searchParams.get("update");
    useEffect(() => {
        // if (updateValue) {
        dispatch(ActionGetGalleryList({ Query: '/' + idValue }));
        // }
    }, [idValue])
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])
    const Gallerylist = galleryList?.[0]
    return (
        <div>
            <Header />
            <Sidebar id="menu-item1" id1="menu-items1" activeClassName="add-staff" />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/solution-list?page=1">Gallery </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Add Gallery</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* /Page Header */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Formik
                                            enableReinitialize={!updateValue ? false : true}
                                            initialValues={{
                                                keys: Gallerylist?.keys || '',
                                                description: Gallerylist?.description || '',
                                                docs: Gallerylist?.docs || []
                                            }}
                                            validationSchema={AddGalleryValidationSchema}
                                            onSubmit={(values, { setErrors, setSubmitting }) => {
                                                if (updateValue && idValue != null) {
                                                    dispatch(ActionUpdateById({ Query: `admin/gallery/${idValue}`, obj: values }
                                                        , (Respons) => {
                                                            if (Respons?.statusCode === 200) {
                                                                window.location.href = `/gallery-listing`
                                                            } else if (Respons?.statusCode === 400) {
                                                                setErrors(Respons?.data?.errors);
                                                            }
                                                        }));
                                                    setSubmitting(false);
                                                } else {
                                                    dispatch(ActionCreateByUrl({
                                                        Query: `admin/gallery`, obj: values
                                                    }, (Respons) => {
                                                        if (Respons?.statusCode === 200) {
                                                            window.location.href = './gallery-listing'
                                                        }
                                                        else if (Respons?.statusCode === 400) {
                                                            setErrors(Respons?.data?.errors);
                                                        }

                                                    }))
                                                    setSubmitting(false);
                                                }
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                setFieldValue,
                                                setFieldTouched,
                                                handleChange,
                                                handleBlur,
                                                isValid,
                                                handleSubmit,
                                                setValues,
                                                setSubmitting,
                                                isSubmitting,
                                            }) => {
                                                return (
                                                    <div>
                                                        <div className="row">
                                                            <div className="row">
                                                                <Divider style={{ fontWeight: "600" }}>Gallery</Divider></div>
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-6 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Enter Your Gallery Name"}
                                                                FormControlClassName={'form-control '}
                                                                label="Gallery Name"
                                                                name="keys"
                                                                value={values?.keys}
                                                                error={errors?.keys}
                                                                touched={touched?.keys}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-6 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Description"}
                                                                FormControlClassName={'form-control '}
                                                                label="*Description"
                                                                name="description"
                                                                value={values?.description}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors?.description}
                                                                touched={touched?.description}
                                                            />
                                                            <div>
                                                                <UploadImage
                                                                    className={"img-fluid"}
                                                                    multiple={true}
                                                                    fileList={
                                                                        updateValue === 'true' &&
                                                                            Array.isArray(values?.docs)
                                                                            ? values?.docs?.map((image, index) => ({
                                                                                uid: index,
                                                                                name: image,
                                                                                status: 'done',
                                                                                url: DOCS_URL + image,
                                                                            })) :
                                                                            updateValue === 'false' &&
                                                                                (Array.isArray(values?.docs))
                                                                                ? values.docs.map((image, index) => ({
                                                                                    uid: index,
                                                                                    name: image,
                                                                                    status: 'done',
                                                                                    url: DOCS_URL + image,
                                                                                })) : []
                                                                    }
                                                                    handleChange={(info) => {
                                                                        const { file, fileList } = info;
                                                                        if (file?.status === 'uploading') {
                                                                            const formData = new FormData();
                                                                            formData?.append("isGallery", true);
                                                                            formData?.append("attachment", file?.originFileObj);
                                                                            dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                if (typeof res === 'object') {
                                                                                    const updatedFileList = fileList.map(item => ({
                                                                                        uid: item.uid,
                                                                                        name: item.name,
                                                                                        status: item.status,
                                                                                        url: item.url
                                                                                    }));
                                                                                    updatedFileList?.forEach((item) => {
                                                                                        if (item.uid === file.uid) {
                                                                                            item.name = res[0];
                                                                                            item.url = DOCS_URL + res[0];
                                                                                        }
                                                                                    });
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'docs',
                                                                                            value: updatedFileList.map((item) => item?.name),
                                                                                        },
                                                                                    });
                                                                                }
                                                                            }))
                                                                        } else if (file?.status === 'removed') {
                                                                            const updatedFileList = fileList.filter((item) => item?.uid !== file?.uid);
                                                                            handleChange({
                                                                                target: {
                                                                                    name: 'docs',
                                                                                    value: updatedFileList?.map((item) => item?.name),
                                                                                },
                                                                            });
                                                                        }
                                                                    }}

                                                                    UploadImagelimit={100} />

                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="text-end">
                                                                <Button
                                                                    onClick={() => {
                                                                        handleSubmit()
                                                                    }}
                                                                    type="primary"
                                                                    disabled={isSubmitting}
                                                                    className="pb-4"
                                                                >Submit</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div >
    );
};

export default AddGallery;