import { call, put, takeEvery } from 'redux-saga/effects';
import { CREATE_SERVICE, CREATE_SOLUTION, CREATE_TREATMENT, GET_ALL_PROGRAMMES, GET_ALL_PROGRAMMES_LIST, GET_ALL_SERVICE, GET_ALL_SOLUTION, GET_ALL_TREATMENT, GET_PROGRAMME_BOOKING, UPDATE_PROGRAMME_SLOT } from '../storeTypes';
import { CreateServiceApi, CreateSolutionApi, CreateTreatmentApi, GetAllProgrammeApi, GetProgramBookingApi, GetProgrammeListApi, GetSolutionListApi, GetTreatmentListApi, UpdateProgrammeSlotApi, getServiceApi } from '../../utils/api';
import { ActionGetProgrammeBookingSuccess, GetAllProgrammeListSuccess, GetAllProgrammeSuccess, GetAllTreatmentSuccess, GetAllserviceSuccess, GetAllsolutionSuccess } from '../Actions/ServiceAction';
import { handleApiResponse } from '../../utils/Funcations';

const File = 'ServiceSaga '
function* createServiceSaga(action) {
    try {
        const response = yield call(CreateServiceApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'createServiceSaga Error :- ', error);
    }
}

function* getServicelistSaga(action) {
    try {
        const response = yield call(getServiceApi, action?.payload);
        if (response?.status === 200) {
            yield put(GetAllserviceSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getAllConsultationSaga Error :- ', error);
        //handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}
function* createTreatmentSaga(action) {
    try {
        const response = yield call(CreateTreatmentApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'CreateTreatmentSaga Error :- ', error);
    }
}
function* getAlltreatmentListSaga(action) {
    try {
        const response = yield call(GetTreatmentListApi, action?.payload);
        if (response?.status === 200) {
            yield put(GetAllTreatmentSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'GetAllTreatmentSuccess Error :- ', error);
        //handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}
//Programmes
function* getAllProgrammeListSaga(action) {
    try {
        const response = yield call(GetProgrammeListApi, action?.payload);
        if (response?.status === 200) {
            yield put(GetAllProgrammeSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getAllProgrammeListSaga Error :- ', error);
        //handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}
function* getAllProgrammeSaga(action) {
    try {
        const response = yield call(GetAllProgrammeApi, action?.payload);
        if (response?.status === 200) {
            yield put(GetAllProgrammeListSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getAllProgrammeSaga Error :- ', error);
        //handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}
function* createSolutionSaga(action) {
    try {
        const response = yield call(CreateSolutionApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'CreateTreatmentSaga Error :- ', error);
    }
}

function* getAllsolutionListSaga(action) {
    try {
        const response = yield call(GetSolutionListApi, action?.payload);
        if (response?.status === 200) {
            yield put(GetAllsolutionSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getAllsolutionListSaga Error :- ', error);
        //handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}
function* GetProgrammeSlotByIdSaga(action) {
    try {
        const response = yield call(GetProgramBookingApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionGetProgrammeBookingSuccess(response?.data?.data));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File + '/GetProgrammeSlotByIdSaga Error:', error);
    }
}
function* UpdateProgrammeSlotSaga(action) {
    try {
        const response = yield call(UpdateProgrammeSlotApi, action.payload);
        console.log("UpdateProgrammeSlotSaga", response);
        if (response?.status === 200) {

            action?.callBack(response?.data);
        }
    } catch (error) {
        console.log(File + '/UpdateProgrammeSlotSaga Error:', error);

        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
    }
}
export function* ServiceSaga() {
    [
        yield takeEvery(CREATE_SERVICE, createServiceSaga),
        yield takeEvery(GET_ALL_SERVICE, getServicelistSaga),
        yield takeEvery(CREATE_TREATMENT, createTreatmentSaga),
        yield takeEvery(GET_ALL_TREATMENT, getAlltreatmentListSaga),
        yield takeEvery(GET_ALL_PROGRAMMES, getAllProgrammeListSaga),
        yield takeEvery(GET_ALL_PROGRAMMES_LIST, getAllProgrammeSaga),
        yield takeEvery(GET_PROGRAMME_BOOKING, GetProgrammeSlotByIdSaga),
        yield takeEvery(UPDATE_PROGRAMME_SLOT, UpdateProgrammeSlotSaga),

        yield takeEvery(CREATE_SOLUTION, createSolutionSaga),
        yield takeEvery(GET_ALL_SOLUTION, getAllsolutionListSaga),
    ]
}