import { CREATE_HOLIDAY, CREATE_STAFF, DELETE_HOLIDAY, GET_ALL_FOODPRICE_LIST, GET_ALL_FOODPRICE_LIST_SUCCESS, GET_HOLIDAY_BY_ID, GET_HOLIDAY_BY_ID_SUCCESS, GET_HOLIDAY_LIST, GET_LEAVES, GET_LEAVES_SUCCESS, GET_STAFF_LIST, GET_STAFF_LIST_SUCCESS, GET_STAFF_PROFILE, GET_STAFF_PROFILE_SUCCESS, GET_THERAPIST_SCHEDULE, GET_THERAPIST_SCHEDULE_SUCCESS, GET_THERAPIST_SHIFTS, GET_THERAPIST_SHIFTS_SUCCESS, HOLIDAY_LIST_SUCCESS, UPDATE_HOLIDAY, UPDATE_STAFF_PROFILE } from "../storeTypes";

const INIT_STATE = {
    loader: false,
    StaffList: [],
    StaffProfile: [],
    therapistSchedule: [],
    holidayList: [],
    therapistShift: [],
    leavesList: [],
    foodpriceList: [],
};

function StaffReducer(state = INIT_STATE, action) {

    switch (action.type) {

        case CREATE_STAFF: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_STAFF_LIST: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_STAFF_PROFILE: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_STAFF_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                StaffList: action.payload,
            };
        }
        case GET_STAFF_PROFILE: {
            return {
                ...state,
                loader: true,
                StaffProfile: []
            };
        }
        case GET_STAFF_PROFILE_SUCCESS: {
            return {
                ...state,
                loader: false,
                StaffProfile: action.payload,
            };
        }
        case CREATE_HOLIDAY: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_HOLIDAY_LIST: {
            return {
                ...state,
                loader: true,
                holidayList: []
            };
        }
        case HOLIDAY_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                holidayList: action.payload,
            };
        }
        case UPDATE_HOLIDAY: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_HOLIDAY_BY_ID: {
            return {
                ...state,
                holidayList: {},
                loader: true,
            };
        }
        case GET_HOLIDAY_BY_ID_SUCCESS: {
            return {
                ...state,
                loader: false,
                holidayList: action.payload,
            };
        }
        case GET_THERAPIST_SCHEDULE: {
            return {
                ...state,
                therapistSchedule: [],
                loader: true,
            };
        }
        case GET_THERAPIST_SCHEDULE_SUCCESS: {
            return {
                ...state,
                loader: false,
                therapistSchedule: action.payload,
            };
        }
        case GET_THERAPIST_SHIFTS: {
            return {
                ...state,
                therapistShift: [],
                loader: true,
            };
        }
        case GET_THERAPIST_SHIFTS_SUCCESS: {
            return {
                ...state,
                loader: false,
                therapistShift: action.payload,
            };
        }
        case GET_LEAVES: {
            return {
                ...state,
                leavesList: [],
                loader: true,
            };
        }
        case GET_LEAVES_SUCCESS: {
            return {
                ...state,
                loader: false,
                leavesList: action.payload,
            };
        }
        case DELETE_HOLIDAY: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_FOODPRICE_LIST: {
            return {
                ...state,
                foodpriceList: [],
                loader: true,
            };
        }
        case GET_ALL_FOODPRICE_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                foodpriceList: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}

export default StaffReducer;
