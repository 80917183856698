/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React from "react";
import { Button, Divider } from "antd";
import { InputFromUser } from "../Input/input";
import PropTypes from 'prop-types';
import UploadImage from "../Upload/Upload";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { ActionUploadImage } from "../../store/Actions/CommonAction";
import { useDispatch, useSelector } from "react-redux";
import { isNullOrUndef } from "../../utils/Funcations";
import { DOCS_URL } from "../../utils/globals";
import { ActionGetMedicineList } from "../../store/Actions/PatientAction";




export const ExpendableTable = ({
    touched,
    errors,
    onChange,
    onBlur,
    emptyDataForCreatingField,
    Data,
    Columns,
    HeaderComponent,
    UploadImageFlag,
    Title
}) => {
    const { medicineList, loader } = useSelector(state => state?.patient);
    const dispatch = useDispatch();
    function onSearch(search) {
        if (search.length >= 2) {
            dispatch(ActionGetMedicineList({ Query: `?name=${search}` }))
        } else if (search.length === 0) {
            dispatch(ActionGetMedicineList({ Query: `` }))
        }
    }
    return (
        <div className="row">
            <div className="my-4" >
                <Divider style={{ fontWeight: "600" }}>{Title}</Divider>
                {HeaderComponent}
            </div>
            <div className="invoice-add-table form-group">
                <div className="table-responsive">
                    <table className="table table-center add-table-items">
                        <thead>
                            <tr>
                                {Columns.map((value, index) => (
                                    <th key={"thTitle" + index}>{value.label}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Data?.map((item, index) => (
                                <React.Fragment key={"fragment" + index}>
                                    <tr key={"trtag" + index}>
                                        {Columns?.map((value, fieldIndex) => {
                                            const ObjOrValue = (value?.type === 'text' || value?.type === 'color' || value?.type === 'email' || value?.type === 'number' || value?.type === 'password' || value?.type === 'search' || value?.type === 'url' || value?.type === 'tel')
                                            return (
                                                <td className="col-xl-1 col-md-12" key={value?.name + fieldIndex + "td" + index}>
                                                    {value.type === "upload" ? (
                                                        <UploadImage
                                                            fileList={!isNullOrUndef(item?.[value?.name]) && [{
                                                                uid: 1 + item?.[value?.name],
                                                                name: item?.[value?.name],
                                                                status: 'done',
                                                                url: DOCS_URL + item?.[value?.name],
                                                            }] || []}
                                                            handleChange={(v) => {
                                                                if (v.file && v.file.status === 'uploading') {
                                                                    const formData = new FormData();
                                                                    formData.append(UploadImageFlag || 'Enter', true);
                                                                    formData.append("attachment", v?.file?.originFileObj);
                                                                    dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                        if (typeof res === 'object') {
                                                                            const updateArry = [...Data];
                                                                            const updatedObject = {
                                                                                ...updateArry[index],
                                                                                [value?.name]: res[0],
                                                                            };
                                                                            updateArry[index] = updatedObject;
                                                                            onChange(updateArry);
                                                                        }
                                                                    }))
                                                                } else if (v.file && v.file.status === 'removed') {
                                                                    const updateArry = [...Data];
                                                                    const updatedObject = {
                                                                        ...updateArry[index],
                                                                        [value?.name]: '',
                                                                    };
                                                                    updateArry[index] = updatedObject;
                                                                    onChange(updateArry);
                                                                }
                                                            }}
                                                            // errors={errors?.[index]?.[value?.name]}
                                                            UploadImagelimit={1} />
                                                    ) : value.type === "action" ? (
                                                        <div className="mt-3" >
                                                            {(Data.length === index + 1) &&
                                                                < Button
                                                                    style={{ border: 'none' }}
                                                                    // disabled={true}
                                                                    // type="dashed"
                                                                    className="add-btn"
                                                                    onClick={() => {
                                                                        const newValue = emptyDataForCreatingField;
                                                                        onChange([...Data, newValue])
                                                                    }}>
                                                                    <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                </Button>}
                                                            {((Data.length === index + 1) ? (Data.length === index + 1) : index !== 0) &&
                                                                <Button
                                                                    style={{ border: 'none' }}
                                                                    // disabled={true}
                                                                    className="remove-btn"
                                                                    onClick={() => {
                                                                        if (index !== 0) {
                                                                            const updatedBenefits = [...Data];
                                                                            updatedBenefits.splice(index, 1);
                                                                            onChange(updatedBenefits);
                                                                        }
                                                                    }}>
                                                                    <i className="fe fe-trash-2">
                                                                        <FeatherIcon icon="trash-2" className={'mb-1'} />
                                                                    </i>
                                                                </Button>}
                                                        </div>
                                                    ) : value.type === "medicinePicker" ? (
                                                        <InputFromUser
                                                            type="singleSelect"
                                                            label=""
                                                            key={"medicinePicker" + index}
                                                            Showlabel={false}
                                                            mode={''}
                                                            name="recomendedMedicines"
                                                            onChange={(v) => {
                                                                const updateArry = [...Data];
                                                                const updatedObject = {
                                                                    ...updateArry[index],
                                                                    [value?.name]: v,
                                                                };
                                                                updateArry[index] = updatedObject;
                                                                onChange(updateArry);
                                                            }}
                                                            value={item?.[value?.name]}
                                                            onSearch={(search) => { onSearch(search) }}
                                                            onBlur={() => {
                                                                const updateArry = { ...(touched || {}) };
                                                                const updatedObject = {
                                                                    ...updateArry[index],
                                                                    [value?.name || ""]: true,
                                                                };
                                                                updateArry[index] = {
                                                                    ...updateArry[index],
                                                                    ...updatedObject,
                                                                };
                                                                onBlur(updateArry);
                                                            }}
                                                            error={errors?.[index]?.[value?.name]}
                                                            disabled={value?.idDisable}
                                                            touched={
                                                                typeof touched === "object"
                                                                    ? touched?.[index]?.[value?.name]
                                                                    : false
                                                            }
                                                            onSearchLoading={loader}
                                                            options={medicineList.length != 0 ? medicineList?.map(item => item?.name) : []}
                                                            placeholder={'Enter Your Medicines'} />
                                                    ) : value.type === "CustomUi" ? value?.CustomUi : (
                                                        <InputFromUser
                                                            type={value?.type}
                                                            label=""
                                                            key={"medicinePicker" + index}
                                                            Showlabel={false}
                                                            mode={''}
                                                            name="recomendedMedicines"
                                                            touched={
                                                                typeof touched === "object"
                                                                    ? touched?.[index]?.[value?.name]
                                                                    : false
                                                            }
                                                            error={errors?.[index]?.[value?.name]}
                                                            value={item?.[value?.name]}
                                                            onChange={(v) => {
                                                                const updateArry = [...Data];
                                                                const updatedObject = {
                                                                    ...updateArry[index],
                                                                    [value?.name]: (ObjOrValue ? v.target.value : v),
                                                                };
                                                                updateArry[index] = updatedObject;
                                                                onChange(updateArry);
                                                            }}
                                                            disabled={value?.idDisable}
                                                            onBlur={() => {
                                                                const updateArry = { ...(touched || {}) };
                                                                const updatedObject = {
                                                                    ...updateArry[index],
                                                                    [value?.name || ""]: true,
                                                                };
                                                                updateArry[index] = {
                                                                    ...updateArry[index],
                                                                    ...updatedObject,
                                                                };
                                                                onBlur(updateArry);
                                                            }}
                                                            options={value?.options}
                                                            placeholder={"Enter " + value?.label + " " + (value?.placeholder || '')}
                                                        />
                                                    )}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div >
    );
};


ExpendableTable.propTypes = {


    errors: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
    Title: PropTypes.string,
    UploadImageFlag: PropTypes.string,
    touched: PropTypes.oneOfType(PropTypes.string, PropTypes.bool),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    emptyDataForCreatingField: PropTypes.object,
    Columns: PropTypes.array,
    Data: PropTypes.array,
    HeaderComponent: PropTypes.element,
};