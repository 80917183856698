import { Button, Result } from "antd";
import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import AddAppoinments from "./components/appoinments/AddAppoinments";
import TwentyMinForm from "./pages/20minform";
import Dashboard from "./pages/admin-dashboard";
import AppConfig from "./pages/app-config";
import Add_AppConfig from "./pages/app-config/add-appconfig";
import AddHoliday from "./pages/holidays/add-holiday";
import Holidays from "./pages/holidays/holiday";
import AddLeave from "./pages/leaves/add-leave";
import Leave from "./pages/leaves/leave";
import Login from "./pages/login";
import PatientList from "./pages/patient-list";
import AddPatient from "./pages/patient-list/create-patient";
import PatientProfile from "./pages/patient-list/patient-profile";
import Permissions from "./pages/permissions";
import AddPermissions from "./pages/permissions/create-permissions";
import Roles from "./pages/roles";
import CreateRoles from "./pages/roles/create-roles";
import StaffList from "./pages/staff-list";
import AddEducation from "./pages/staff-list/add-education";
import AddStaff from "./pages/staff-list/add-staff";
import StaffProfile from "./pages/staff-list/staff-profile";
import EditStaffProfile from "./pages/staff-list/staff-profile/edit-profile/EditProfile";
import Servicelist from "./pages/Servicelist/Servicelis";
import AppointmentDetails from "./pages/appoinment-list/appointment-details";
import DoAndDonts from "./pages/master-data/do-and-donts";
import Add_DoAndDonts from "./pages/master-data/do-and-donts/add-do-and-donts";
import Exercise from "./pages/master-data/exercise";
import Add_Exercise from "./pages/master-data/exercise/add-exercise";
import Medicine from "./pages/master-data/medicine";
import Add_Medicine from "./pages/master-data/medicine/add-medicine";
import MemberShip from './pages/master-data/member-ship';
import Add_MemberShip from "./pages/master-data/member-ship/add-member-ship";
import Virechan from "./pages/master-data/virechan";
import Add_Virechan from "./pages/master-data/virechan/add-virechan";
import Rooms from "./pages/rooms/room";
import RoomDashboard from "./pages/rooms/room-dashboard";
import { RoomIndividualData } from "./pages/rooms/room-individual-data";
import Add_Rooms from "./pages/rooms/room/add-room";
import ServiceSlot from "./pages/service-slot";
import VerifiedCase from "./pages/verify-case";
import Add_ServiceSlot from "./pages/service-slot/add-service-slot";
import Treatmentlist from "./pages/treatment-listing/Treatment-listing";
import Solutionlist from "./pages/solution-listing/solution-listing";
import PriceTable from "./pages/price-table";
import AddPrice from "./pages/price-table/add-price";
import OrderHistory from "./components/pages/order-history";
import OrderHistoryOverView from "./components/pages/order-history/order-history-overview";
import Birthdayfolks from "./pages/BirthdayFolks/birthdayfolks";
import AddGallery from "./pages/Gallery/AddGallery";
import Gallerylist from "./pages/Gallery/Gallery-listing";
import AddEditService from "./pages/Servicelist/AddService";
import AppoinmentListBooking from "./pages/bookings/appointment-booking";
import DetoxRetreatBooking from "./pages/bookings/detox-retreat-booking";
import AddEditCelebrity from "./pages/celebrities/AddCelebrity";
import CelebrityList from "./pages/celebrities/celebrity-listing";
import DetoxretreatList from "./pages/detoxRetreat/DetoxretreatList";
import AddEditDetoxRetreat from "./pages/detoxRetreat/add-deroxRetreat/AddEditDetoxRetreat";
import FaqList from "./pages/faq-list";
import AddEditFaq from "./pages/faq-list/AddFaq";
import ResetPassword from "./pages/login/ChangePassword";
import ForgotPassword from "./pages/login/ForgotPassword";
import AddEditFoodprice from "./pages/master-data/caretaker-foodprice/add-foodprice/AddEditFoodprice";
import Caretakerfoodpricelist from "./pages/master-data/caretaker-foodprice/caretakerfoodpricelist";
import RoomCount from "./pages/master-data/room-count";
import Shifts from "./pages/master-data/shifts";
import Add_Shifts from "./pages/master-data/shifts/add-shifts";
import TherapistCount from "./pages/master-data/therapist-count";
import MedicalHistory from "./pages/medical-history";
import MedicalStore from "./pages/medical-store";
import EditMedicalStoreDetails from "./pages/medical-store/edit-medical-store";
import AddEditPatientTestimonial from "./pages/patient-testimonial/AddPatientTestimonial";
import PatientTestimonialList from "./pages/patient-testimonial/patient-testimonial-list";
import Patientconfirmation from "./pages/patientConfirmation/patientconfirmation";
import PendingHistory from "./pages/pending-history/pending-history";
import AddEditPrakrutistandard from "./pages/prakruti-standards/AddEditPrakrutistandard";
import PrakrutiStandardList from "./pages/prakruti-standards/prakrutistandard-listing";
import RoomSlot from "./pages/room-slot";
import UpdateCheckIn from "./pages/room-slot/updatecheckin/updateCheckIn";
import UpdateCheckOut from "./pages/room-slot/updatecheckin/updateCheckOut";
import AddEditSolution from "./pages/solution-listing/AddSolutions";
import SolutionSlot from "./pages/solution-slot";
import Add_SolutionSlot from "./pages/solution-slot/add-solution-slot";
import AddEditTestimonialLink from "./pages/testimonial-link/AddTestimonialLink";
import TestimonialLinkList from "./pages/testimonial-link/testimonial-link-list";
import AddEditTreatment from "./pages/treatment-listing/AddTreatment";
import AddEditTreatmentProtocol from "./pages/treatment-protocol/AddEditTreatmentProtocol";
import TreatmentProtocolList from "./pages/treatment-protocol/treatmentProtocol-listing";
import TreatmentSlot from "./pages/treatment-slot";
import Add_TreatmentSlot from "./pages/treatment-slot/add-treatment-slot";
import ProgrammeList from "./pages/programmes/Programme-list";
import AddEditProgramme from "./pages/programmes/AddProgramme";
import IndorCase from "./pages/indor-casepaper";
import MemberVerification from "./pages/memberVerification/memberVerification";
import AssignedDoctorList from "./pages/assigned-doctor/AssignedDoctorList";
import DoctorDashboard from "./pages/doctor-dashboard";
import IndoorCasepaper from "./pages/indor-casepaper/IndoorCasepaper";
import ProgramSlotBooking from "./pages/bookings/program-booking/index";
import YogaConsultation from "./pages/yoga-consultation/YogaConsultation";
import DischargeForm from "./pages/discharge-form/DischargeForm";
import TherapistHistory from "./pages/therapist-Allocation/TherapistHistory";

const Private = ({ children }) => {
    const isAuthenticated = localStorage.getItem('token');
    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }
    return children;
};
Private.propTypes = {
    children: PropTypes.element,
};
const Protected = ({ children }) => {
    const isAuthenticated = localStorage.getItem('token');
    if (isAuthenticated) {
        return <Navigate to="/admin-dashboard" replace />;
    }
    return children;
};
Protected.propTypes = {
    children: PropTypes.element,
};
const AppContainer = () => {

    return (
        <Layout>
            <Routes>
                <Route path="/" element={<Protected><Login /></Protected>} />
                <Route path="/forgot-password" element={<Protected><ForgotPassword /></Protected>} />
                <Route path="/reset-password" element={<Protected><ResetPassword /></Protected>} />
                <Route path="/admin-dashboard" element={<Private ><Dashboard /></Private>} />
                <Route path="/doctor-dashboard" element={<Private ><DoctorDashboard /></Private>} />

                <Route path="/do-and-donts" element={<Private ><DoAndDonts /></Private>} />
                <Route path="/do-and-donts/add-do-and-donts" element={<Private ><Add_DoAndDonts /></Private>} />
                <Route path="/exercise" element={<Private ><Exercise /></Private>} />
                <Route path="/exercise/add-exercise" element={<Private ><Add_Exercise /></Private>} />
                <Route path="/shifts" element={<Private ><Shifts /></Private>} />
                <Route path="/shifts/add-shifts" element={<Private ><Add_Shifts /></Private>} />
                <Route path="/virechan" element={<Private ><Virechan /></Private>} />
                <Route path="/indor-casepaper/" element={<Private ><IndorCase /></Private>} />
                <Route path="/indoor-casepaperDetails/:id" element={<Private ><IndoorCasepaper /></Private>} />
                <Route path="/yoga-consultation" element={<Private ><YogaConsultation /></Private>} />
                <Route path="/discharge-form" element={<Private ><DischargeForm /></Private>} />
                <Route path="/member-ship" element={<Private ><MemberShip /></Private>} />
                <Route path="/gallery-listing" element={<Private ><Gallerylist /></Private>} />
                <Route path="/add-gallery" element={<Private ><AddGallery /></Private>} />
                <Route path="/virechan/add-virechan" element={<Private ><Add_Virechan /></Private>} />
                <Route path="/member-ship/add-member-ship" element={<Private ><Add_MemberShip /></Private>} />
                <Route path="/medicine" element={<Private ><Medicine /></Private>} />
                <Route path="/medicine/add-medicine" element={<Private ><Add_Medicine /></Private>} />
                <Route path="/room-dashboard" element={<Private ><RoomDashboard /></Private>} />
                <Route path="/room-individual-data/:name" element={<Private ><RoomIndividualData /></Private>} />
                <Route path="/service-slot" element={<Private ><ServiceSlot /></Private>} />
                <Route path="/service-slot/add-service-slot" element={<Private ><Add_ServiceSlot /></Private>} />
                <Route path="/treatment-slot" element={<Private ><TreatmentSlot /></Private>} />
                <Route path="/treatment-slot/add-treatment-slot" element={<Private ><Add_TreatmentSlot /></Private>} />
                <Route path="/solution-slot" element={<Private ><SolutionSlot /></Private>} />
                <Route path="/room-slot" element={<Private ><RoomSlot /></Private>} />
                <Route path="/order-history-overview/:id" element={<Private ><OrderHistoryOverView /></Private>} />
                <Route path="/updatecheckinform" element={<Private ><UpdateCheckIn /></Private>} />
                <Route path="/updatecheckoutform" element={<Private ><UpdateCheckOut /></Private>} />
                <Route path="/solution-slot/add-solution-slot" element={<Private ><Add_SolutionSlot /></Private>} />
                <Route path="/medical-store" element={<Private ><MedicalStore /></Private>} />
                <Route path="/medical-store/edit-medical-store" element={<Private ><EditMedicalStoreDetails /></Private>} />
                <Route path="/medical-history" element={<Private><MedicalHistory /></Private>} />
                <Route path="/room" element={<Private ><Rooms /></Private>} />
                <Route path="/room/add-room" element={<Private ><Add_Rooms /></Private>} />
                <Route path="/app-config" element={<Private ><AppConfig /></Private>} />
                <Route path="/patient-list/appointment-details" element={<Private ><AppointmentDetails /></Private>} />
                <Route path="/service-list" element={<Private ><Servicelist /></Private>} />
                <Route path="/app-config/add-appconfig" element={<Private ><Add_AppConfig /></Private>} />
                <Route path="/patient-list/create-patient" element={<Private ><AddPatient /></Private>} />
                <Route path="/staff-list" element={<Private ><StaffList /></Private>} />
                <Route path="/treatment-list" element={<Private ><Treatmentlist /></Private>} />
                <Route path="/roles" element={<Private ><Roles /></Private>} />
                <Route path="/roles/create-roles" element={<Private ><CreateRoles /></Private>} />
                <Route path="/permissions" element={<Private ><Permissions /></Private>} />
                <Route path="/permissions/create-permissions" element={<Private ><AddPermissions /></Private>} />
                <Route path="/patient-list" element={<Private ><PatientList /></Private>} />
                <Route path="/patient-list/patient-profile" element={<Private ><PatientProfile /></Private>} />
                <Route path="/staff-list/add-staff" element={<Private ><AddStaff /></Private>} />
                <Route path="/staff-list/staff-profile/:id" element={<Private ><StaffProfile /></Private>} />
                <Route path="/order-history/order-history-overview/:id" element={<Private ><OrderHistoryOverView /></Private>} />
                <Route path="/staff-list/staff-profile/edit-profile/:id" element={<Private ><EditStaffProfile /></Private>} />
                <Route path="/staff-list/staff-profile/add-education/:id" element={<Private ><AddEducation /></Private>} />
                <Route path="/appoinment-list" element={<Private ><AppoinmentListBooking /></Private>} />
                <Route path="/add-appoinment" element={<Private ><AddAppoinments /></Private>} />
                <Route path="/add-service" element={<Private ><AddEditService /></Private>} />
                <Route path="/add-solution" element={<Private ><AddEditSolution /></Private>} />
                <Route path="/solution-list" element={<Private ><Solutionlist /></Private>} />
                <Route path="/add-celebrity" element={<Private ><AddEditCelebrity /></Private>} />
                <Route path="/celebrity-list" element={<Private ><CelebrityList /></Private>} />
                <Route path="/add-faq" element={<Private ><AddEditFaq /></Private>} />
                <Route path="/faq-list" element={<Private ><FaqList /></Private>} />
                <Route path="/treatmentprotocol-list" element={<Private ><TreatmentProtocolList /></Private>} />
                <Route path="/caretakerfoodprice-list" element={<Private ><Caretakerfoodpricelist /></Private>} />
                <Route path="/add-caretakerfoodprice" element={<Private ><AddEditFoodprice /></Private>} />
                <Route path="/birthday-list" element={<Private ><Birthdayfolks /></Private>} />
                <Route path="/add-treatmentprotocol" element={<Private ><AddEditTreatmentProtocol /></Private>} />
                <Route path="/prakrutistandard-list" element={<Private ><PrakrutiStandardList /></Private>} />
                <Route path="/add-prakrutistandard" element={<Private ><AddEditPrakrutistandard /></Private>} />
                <Route path="/add-programme" element={<Private ><AddEditProgramme /></Private>} />
                <Route path="/programme-list" element={<Private ><ProgrammeList /></Private>} />
                <Route path="/program-booking" element={<Private ><ProgramSlotBooking /></Private>} />
                <Route path="/add-treatment" element={<Private ><AddEditTreatment /></Private>} />
                <Route path="/order-history" element={<Private ><OrderHistory /></Private>} />
                <Route path="/add-service" element={<Private ><AddEditService /></Private>} />
                <Route path="/add-solution" element={<Private ><AddEditSolution /></Private>} />
                <Route path="/solution-list" element={<Private ><Solutionlist /></Private>} />
                <Route path="/add-celebrity" element={<Private ><AddEditCelebrity /></Private>} />
                <Route path="/celebrity-list" element={<Private ><CelebrityList /></Private>} />
                <Route path="/testimonial-link-list" element={<Private ><TestimonialLinkList /></Private>} />
                <Route path="/add-testimonial-link" element={<Private ><AddEditTestimonialLink /></Private>} />
                <Route path="/patient-testimonial-list" element={<Private ><PatientTestimonialList /></Private>} />
                <Route path="/add-patient-testimonial" element={<Private ><AddEditPatientTestimonial /></Private>} />
                <Route path="/add-faq" element={<Private ><AddEditFaq /></Private>} />
                <Route path="/faq-list" element={<Private ><FaqList /></Private>} />
                <Route path="/add-treatment" element={<Private ><AddEditTreatment /></Private>} />
                <Route path="/order-history" element={<Private ><OrderHistory /></Private>} />
                <Route path="/pending-history" element={<Private ><PendingHistory /></Private>} />
                <Route path="/verify-case" element={<Private ><VerifiedCase /></Private>} />
                <Route path="/patientconfirmation" element={<Private ><Patientconfirmation /></Private>} />
                <Route path="/verify-member" element={<Private ><MemberVerification /></Private>} />
                <Route path="/assigneddoctor" element={<Private><AssignedDoctorList /></Private>} />
                <Route path="/leaves" element={<Private ><Leave /></Private>} />
                <Route path="/therapist-count" element={<Private ><TherapistCount /></Private>} />
                <Route path="/therapist-history" element={<Private ><TherapistHistory /></Private>} />

                <Route path="/room-count" element={<Private ><RoomCount /></Private>} />
                <Route path="/add-leave" element={<Private ><AddLeave /></Private>} />
                <Route path="/holidays" element={<Private ><Holidays /></Private>} />
                <Route path="/holidays/add-holiday" element={<Private ><AddHoliday /></Private>} />
                <Route path="/detoxretreat-list" element={<Private ><DetoxretreatList /></Private>} />
                <Route path="/detox-retreat-booking" element={<Private ><DetoxRetreatBooking /></Private>} />
                <Route path="/add-detoxretreat" element={<Private ><AddEditDetoxRetreat /></Private>} />
                <Route path="/20minform" element={<Private ><TwentyMinForm /></Private>} />
                <Route path="/patient-list/appointment-details" element={<Private ><AppointmentDetails /></Private>} />
                <Route path="*" element={<Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={<Button type="primary" onClick={() => window.location.href = '/'}>Back Home</Button>}
                />} />
                <Route path="/price-table" element={<Private ><PriceTable /></Private>} />
                <Route path="/price-table/add-price" element={<Private ><AddPrice /></Private>} />

            </Routes>
        </Layout>
    );
};
export default AppContainer;
