import { call, put, takeEvery } from 'redux-saga/effects';
import { handleApiResponse } from '../../utils/Funcations';
import {
    CreateDetoxRetreatApi,
    CreateDoAndDontsApi,
    CreateExerciseApi,
    CreateMedicineApi, CreateMemberShipApi, CreateRoomApi,
    CreateVirechanApi,
    DeleteDoAndDontsApi,
    DeleteExerciseApi,
    DeleteMedicineApi, DeleteMemberShipApi, DeleteRoomApi,
    DeleteVirechanApi,
    GetDetoxRetreatApi,
    GetDetoxRetreatBookingApi,
    GetDoAndDontsApi,
    GetExerciseApi,
    GetMedicineApi,
    GetMedicineByIdApi,
    GetMemberShipApi,
    GetRoomApi,
    GetRoomByIdApi,
    GetRoomByRoomTypeApi,
    GetRoomCountApi,
    GetRoomDashboardApi,
    GetRoomSlotApi,
    GetRoomSlotByIdApi,
    GetVirechanApi,
    UpdateCustomerMemberShipApi,
    UpdateDoAndDontsApi,
    UpdateExerciseApi,
    UpdateMedicineApi, UpdateMemberShipApi, UpdateRoomApi,
    UpdateVirechanApi,
    getAllMemberShipApi,
    getAllMemberShipVerificationApi,
    getTherapistCountApi
} from '../../utils/api';
import { ActionDoAndDontsListSuccess, ActionExerciseListSuccess, ActionGetDetoxRetreatSuccess, ActionGetMedicineByIDSuccess, ActionGetRoomByIDSuccess, ActionGetRoomByRoomTypeSuccess, ActionGetRoomCountSuccess, ActionGetRoomDashboardDataSuccess, ActionGetRoomSlotByIdSuccess, ActionGetRoomSlotSuccess, ActionGetTherapistCountSuccess, ActionMedicineListSuccess, ActionMemberShipListSuccess, ActionRoomListSuccess, ActionVirechanListSuccess, getAllMemberShipSuccess, getAllMemberShipVerifyListSuccess } from '../Actions/MasterDataAction';
import { ActionGetDetoxRetreatBookingSuccess } from '../Actions/ServiceAction';
import {
    CREATE_DETOX_RETREAT,
    CREATE_DO_AND_DONTS,
    CREATE_EXERCISE,
    CREATE_MEDICINE, CREATE_MEMBERSHIP, CREATE_ROOM,
    CREATE_VIRECHAN,
    DELETE_DO_AND_DONTS,
    DELETE_EXERCISE,
    DELETE_MEDICINE, DELETE_MEMBERSHIP, DELETE_ROOM,
    DELETE_VIRECHAN,
    GET_ALL_DETOX_RETREAT, GET_ALL_MEMBERSHIP_LIST,
    GET_ALL_MEMBER_VERIFY_LIST,
    GET_DETOX_RETREAT_BOOKING,
    GET_DO_AND_DONTS_LIST,
    GET_EXERCISE_LIST,
    GET_MEDICINE_BY_ID,
    GET_MEDICINE_LIST,
    GET_MEMBERSHIP_LIST,
    GET_ROOM_BY_ID,
    GET_ROOM_BY_ROOM_TYPE,
    GET_ROOM_COUNT,
    GET_ROOM_DASHBOARD_DATA,
    GET_ROOM_LIST,
    GET_ROOM_SLOT,
    GET_ROOM_SLOT_BY_ID,
    GET_THERAPIST_COUNT,
    GET_VIRECHAN_LIST,
    UPDATE_CUSTOMER_MEMBERSHIP,
    UPDATE_DO_AND_DONTS,
    UPDATE_EXERCISE,
    UPDATE_MEDICINE, UPDATE_MEMBERSHIP, UPDATE_ROOM,
    UPDATE_VIRECHAN
} from '../storeTypes';

//Do and Donts

const File1 = 'DoAndDontsSaga';

function* GetDoAndDontsSaga(action) {
    try {
        const response = yield call(GetDoAndDontsApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionDoAndDontsListSuccess(response?.data?.data[0]));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File1 + '/GetDoAndDontsSaga Error:', error);
    }
}

function* CreateDoAndDontsSaga(action) {
    try {
        const response = yield call(CreateDoAndDontsApi, action.payload);
        if (response) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File1 + '/CreateDoAndDontsSaga Error:', error);
    }
}

function* UpdateDoAndDontsSaga(action) {
    try {
        const response = yield call(UpdateDoAndDontsApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File1 + '/UpdateDoAndDontsSaga Error:', error);
    }
}

function* DeleteDoAndDontsSaga(action) {
    try {
        const response = yield call(DeleteDoAndDontsApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(200);
        }
    } catch (error) {
        console.log(File1 + '/DeleteDoAndDontsSaga Error:', error);
    }
}

//Exercise

const File2 = 'ExerciseSaga';

function* GetExerciseSaga(action) {
    try {
        const response = yield call(GetExerciseApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionExerciseListSuccess(response?.data?.data[0]));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File2 + '/GetExerciseSaga Error:', error);
    }
}

function* CreateExerciseSaga(action) {
    try {
        const response = yield call(CreateExerciseApi, action.payload);
        if (response) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File2 + '/CreateExerciseSaga Error:', error);
    }
}

function* UpdateExerciseSaga(action) {
    try {
        const response = yield call(UpdateExerciseApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File2 + '/UpdateExerciseSaga Error:', error);
    }
}

function* DeleteExerciseSaga(action) {
    try {
        const response = yield call(DeleteExerciseApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(200);
        }
    } catch (error) {
        console.log(File2 + '/DeleteExerciseSaga Error:', error);
    }
}

//Virechan

const File3 = 'VirechanSaga';

function* GetVirechanSaga(action) {
    try {
        const response = yield call(GetVirechanApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionVirechanListSuccess(response?.data?.data[0]));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File3 + '/GetVirechanSaga Error:', error);
    }
}

function* CreateVirechanSaga(action) {
    try {
        const response = yield call(CreateVirechanApi, action.payload);
        if (response) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File3 + '/CreateVirechanSaga Error:', error);
    }
}

function* UpdateVirechanSaga(action) {
    try {
        const response = yield call(UpdateVirechanApi, action.payload);
        console.log(File3 + '/check50ResponseVirechan:', response);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File3 + '/UpdateVirechanSaga Error:', error);
    }
}

function* DeleteVirechanSaga(action) {
    try {
        const response = yield call(DeleteVirechanApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(200);
        }
    } catch (error) {
        console.log(File3 + '/DeleteVirechanSaga Error:', error);
    }
}

const File6 = 'MemberShipSaga';

function* GetMemberShipSaga(action) {
    try {
        const response = yield call(GetMemberShipApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionMemberShipListSuccess(response?.data?.data[0]));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File6 + '/GetMemberShipSaga Error:', error);
    }
}
function* getAllMemberShipSaga(action) {
    try {
        const response = yield call(getAllMemberShipApi, action.payload);
        if (response?.status === 200) {
            yield put(getAllMemberShipSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getAllMemberShipSaga Error :- ', error);
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
    }
}

function* getAllMemberShipVerifySaga(action) {
    try {
        const response = yield call(getAllMemberShipVerificationApi, action.payload);
        if (response?.status === 200) {
            yield put(getAllMemberShipVerifyListSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getAllMemberShipVerifySaga Error :- ', error);
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
    }
}
function* CreateMemberShipSaga(action) {
    try {
        const response = yield call(CreateMemberShipApi, action.payload);
        if (response) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File6 + '/CreateMemberShipSaga Error:', error);
    }
}

function* UpdateMemberShipSaga(action) {
    try {
        const response = yield call(UpdateMemberShipApi, action.payload);
        console.log(File6 + '/check51ResponseMemberShip:', response);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File6 + '/UpdateMemberShipSaga Error:', error);
    }
}
function* UpdateCustomerMembershipSaga(action) {

    try {
        const response = yield call(UpdateCustomerMemberShipApi, action.payload);
        if (response?.status === 200) {

            action?.callBack(response?.data);
        }
    } catch (error) {
        console.log(File + '/UpdateCustomerMembershipSaga Error:', error);

        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
    }

}

function* DeleteMemberShipSaga(action) {
    try {
        const response = yield call(DeleteMemberShipApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(200);
        }
    } catch (error) {
        console.log(File6 + '/DeleteMemberShipSaga Error:', error);
    }
}


//Medicine

const File4 = 'MedicineSaga';

function* GetMedicineSaga(action) {
    try {
        const response = yield call(GetMedicineApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionMedicineListSuccess(response?.data?.data));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File4 + '/GetMedicineSaga Error:', error);
    }
}

function* CreateMedicineSaga(action) {
    try {
        const response = yield call(CreateMedicineApi, action.payload);
        if (response) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File4 + '/CreateMedicineSaga Error:', error);
    }
}

function* UpdateMedicineSaga(action) {
    try {
        const response = yield call(UpdateMedicineApi, action.payload);
        if (response?.status === 200) {

            action?.callBack(response?.data);
        }
    } catch (error) {
        console.log(File4 + '/UpdateMedicineSaga Error:', error);

        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
    }
}

function* GetMedicineByIDSaga(action) {
    try {
        const response = yield call(GetMedicineByIdApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetMedicineByIDSuccess(response?.data?.data[0]));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File4 + '/GetMedicineByIDSaga Error:', error);
    }
}

function* DeleteMedicineSaga(action) {
    try {
        const response = yield call(DeleteMedicineApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(200);
        }
    } catch (error) {
        console.log(File4 + '/DeleteMedicineSaga Error:', error);
    }
}

//Rooms

const File5 = 'RoomSaga';

function* GetRoomSaga(action) {
    try {
        const response = yield call(GetRoomApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionRoomListSuccess(response?.data?.data[0]));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File5 + '/GetRoomSaga Error:', error);
    }
}

function* CreateRoomSaga(action) {
    try {
        const response = yield call(CreateRoomApi, action.payload);
        if (response) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File5 + '/CreateRoomSaga Error:', error);
    }
}

function* UpdateRoomSaga(action) {
    try {
        const response = yield call(UpdateRoomApi, action.payload);
        if (response?.status === 200) {

            action?.callBack(response?.data);
        }
    } catch (error) {
        console.log(File5 + '/UpdateRoomSaga Error:', error);

        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
    }
}

function* GetRoomByIDSaga(action) {
    try {
        const response = yield call(GetRoomByIdApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetRoomByIDSuccess(response?.data?.data[0]));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File5 + '/GetRoomByIDSaga Error:', error);
    }
}

function* DeleteRoomSaga(action) {
    try {
        const response = yield call(DeleteRoomApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(200);
        }
    } catch (error) {
        console.log(File5 + '/DeleteRoomSaga Error:', error);
    }
}

function* GetRoomCountSaga(action) {
    try {
        const response = yield call(GetRoomCountApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetRoomCountSuccess(response?.data?.data));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
    }
}

function* GetRoomByRoomTypeSaga(action) {
    try {
        const response = yield call(GetRoomByRoomTypeApi, action.payload);
        console.log("ActionGetRoomByRoomTypeSuccess", response?.data?.data[0]);

        if (response?.status === 200) {
            yield put(ActionGetRoomByRoomTypeSuccess(response?.data?.data[0]));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File5 + '/GetRoomByRoomTypeSaga Error:', error);
    }
}

function* GetTherapistCountSaga(action) {
    try {
        const response = yield call(getTherapistCountApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetTherapistCountSuccess(response?.data?.data));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
    }
}

function* GetRoomDashboardSaga(action) {
    try {
        const response = yield call(GetRoomDashboardApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetRoomDashboardDataSuccess(response?.data?.data));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
    }
}
function* GetRoomSlotSaga(action) {
    try {
        const response = yield call(GetRoomSlotApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionGetRoomSlotSuccess(response?.data?.data));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File5 + '/GetRoomSlotSaga Error:', error);
    }
}
function* GetRoomSlotByIdSaga(action) {
    try {
        const response = yield call(GetRoomSlotByIdApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionGetRoomSlotByIdSuccess(response?.data?.data));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File5 + '/GetRoomSlotByIdSaga Error:', error);
    }
}

function* GetDetoxRetreatSaga(action) {
    try {
        const response = yield call(GetDetoxRetreatApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionGetDetoxRetreatSuccess(response?.data?.data));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File5 + '/GetDetoxRetreatSaga Error:', error);
    }
}

function* GetDetoxRetreatBookingSaga(action) {
    try {
        const response = yield call(GetDetoxRetreatBookingApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionGetDetoxRetreatBookingSuccess(response?.data?.data));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File5 + '/GetDetoxRetreatBookingSaga Error:', error);
    }
}

function* createDetoxRetreatSaga(action) {
    try {
        const response = yield call(CreateDetoxRetreatApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'createDetoxRetreatSaga Error :- ', error);
    }
}

export function* MasterDataSaga() {
    yield takeEvery(GET_DO_AND_DONTS_LIST, GetDoAndDontsSaga);
    yield takeEvery(CREATE_DO_AND_DONTS, CreateDoAndDontsSaga);
    yield takeEvery(UPDATE_DO_AND_DONTS, UpdateDoAndDontsSaga);
    yield takeEvery(DELETE_DO_AND_DONTS, DeleteDoAndDontsSaga);

    yield takeEvery(GET_EXERCISE_LIST, GetExerciseSaga);
    yield takeEvery(CREATE_EXERCISE, CreateExerciseSaga);
    yield takeEvery(UPDATE_EXERCISE, UpdateExerciseSaga);
    yield takeEvery(DELETE_EXERCISE, DeleteExerciseSaga);

    yield takeEvery(GET_VIRECHAN_LIST, GetVirechanSaga);
    yield takeEvery(CREATE_VIRECHAN, CreateVirechanSaga);
    yield takeEvery(UPDATE_VIRECHAN, UpdateVirechanSaga);
    yield takeEvery(DELETE_VIRECHAN, DeleteVirechanSaga);

    yield takeEvery(GET_MEMBERSHIP_LIST, GetMemberShipSaga);
    yield takeEvery(CREATE_MEMBERSHIP, CreateMemberShipSaga);
    yield takeEvery(UPDATE_MEMBERSHIP, UpdateMemberShipSaga);
    yield takeEvery(DELETE_MEMBERSHIP, DeleteMemberShipSaga);
    yield takeEvery(GET_ALL_MEMBERSHIP_LIST, getAllMemberShipSaga);
    yield takeEvery(GET_ALL_MEMBER_VERIFY_LIST, getAllMemberShipVerifySaga);
    yield takeEvery(UPDATE_CUSTOMER_MEMBERSHIP, UpdateCustomerMembershipSaga);


    yield takeEvery(GET_MEDICINE_LIST, GetMedicineSaga);
    yield takeEvery(CREATE_MEDICINE, CreateMedicineSaga);
    yield takeEvery(UPDATE_MEDICINE, UpdateMedicineSaga);
    yield takeEvery(GET_MEDICINE_BY_ID, GetMedicineByIDSaga);
    yield takeEvery(DELETE_MEDICINE, DeleteMedicineSaga);

    yield takeEvery(GET_ROOM_LIST, GetRoomSaga);
    yield takeEvery(CREATE_ROOM, CreateRoomSaga);
    yield takeEvery(UPDATE_ROOM, UpdateRoomSaga);
    yield takeEvery(GET_ROOM_BY_ID, GetRoomByIDSaga);
    yield takeEvery(GET_ROOM_COUNT, GetRoomCountSaga);
    yield takeEvery(GET_ROOM_DASHBOARD_DATA, GetRoomDashboardSaga);
    yield takeEvery(GET_ROOM_SLOT, GetRoomSlotSaga,);
    yield takeEvery(GET_ROOM_SLOT_BY_ID, GetRoomSlotByIdSaga)
    yield takeEvery(GET_THERAPIST_COUNT, GetTherapistCountSaga);
    yield takeEvery(DELETE_ROOM, DeleteRoomSaga);
    yield takeEvery(GET_ROOM_BY_ROOM_TYPE, GetRoomByRoomTypeSaga);

    yield takeEvery(GET_ALL_DETOX_RETREAT, GetDetoxRetreatSaga);
    yield takeEvery(CREATE_DETOX_RETREAT, createDetoxRetreatSaga)
    yield takeEvery(GET_DETOX_RETREAT_BOOKING, GetDetoxRetreatBookingSaga)
}