import React, { useEffect } from 'react'
import queryString from 'query-string'
import { Button, Modal, Popover } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { ActionGetDetoxRetreat } from '../../store/Actions/MasterDataAction'
import { ActionDeleteById } from '../../store/Actions/CommonAction'
import { matchPermissions } from '../../utils/Permission'
import { TableListPageCom } from '../../components/TableListPageCom'
import { ResponsiveComponent, trimText } from '../../utils/Funcations'
import { DOCS_URL } from '../../utils/globals'

const DetoxretreatList = () => {
    const { confirm } = Modal;
    const Size = ResponsiveComponent()

    const params = queryString.parse(location.search);
    const pageSize = 10;
    const dispatch = useDispatch();
    const { userDetails } = useSelector(state => state?.auth);

    const { detoxRetreat, loader } = useSelector(state => state?.MasterData);
    // const detoxRetreatData = detoxRetreat?.[0]?.docs;
    useEffect(() => {
        dispatch(ActionGetDetoxRetreat())
    }, [])
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('detox-retreat.update');
    const canDelete = matchPermissionsForUser('detox-retreat.delete');
    // const canCerate = matchPermissionsForUser('detox-retreat.create');
    const content = (item) => (
        <div className="row col">
            {canUpdate &&
                <Button className="col m-2" onClick={() => { window.location.href = `/add-detoxretreat?update=true&&page=${1}&&id=${item?.id}` }}>Edit</Button>
            }
            {canDelete &&
                <Button className="col m-2" onClick={() => showDeleteConfirm(item)}>Delete</Button>
            }
        </div>
    );
    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.title} detox retreat?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteById({ Query: `admin/detox-retreat/${item?.id}` }, (Res) => {
                    if (Res?.statusCode === 200) {
                        //setRefresh(true)
                        window.location.reload()
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };


    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Start Date",
            dataIndex: "startDate",
            render: (text, record) => {
                const StartDate = new Date(record?.startDate);
                return StartDate.toISOString().split('T')[0];
            },
        },
        {
            title: "End Date",
            dataIndex: "endDate",
            render: (text, record) => {
                const EndDate = new Date(record?.endDate);
                return EndDate.toISOString().split('T')[0];
            },
        },
        {
            title: 'Title',
            dataIndex: "title",
        },
        {
            title: "Main Description",
            dataIndex: "description",
            render: (text) => (
                <div className="app-config-boldText">{trimText(text, Size === "phone" || Size === "tablet" ? 20 : 50)}</div>
            ),
        },
        {
            title: "Image",
            dataIndex: "bannerImage",
            render: (text, record) => (
                <img src={DOCS_URL + record.bannerImage} alt={record.name} style={{ width: 60, height: 60 }} />
            ),
        },
        {
            title: !canUpdate && !canDelete ? null : "Action",
            dataIndex: "roleName",
            render: (text, record) => {
                if (!canUpdate && !canDelete) {
                    return null
                } else {
                    return <Popover placement="top" title={text} content={() => content(record)}>
                        <Button type="text">• • •</Button>
                    </Popover >
                }
            },
        },

    ]
    const BreadcrumbList = [{
        name: 'Detox Retreat',
        linkTo: `/detoxretreat-list?page=${params?.page || 1}`
    },
    {
        name: 'Detox Retreat List',
    }
    ]
    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={detoxRetreat?.[0]?.docs}
            TableColumn={columns}
            ShowAddButton={true}
            Iscreate="detox-retreat.create"
            CreateButtonLink={`/add-detoxretreat?update=false&&page=${params?.page || 1}`}
            PageMainTitle="Detox Retreat List"
            CurrentPage={detoxRetreat?.page || 1}
            Tableloader={loader}
            TotalPageList={detoxRetreat?.[0]?.totalDocs}
            dataSourceLength={detoxRetreat?.docs?.length}
            handlePageChange={(v) => {
                var newp = params
                newp.page = v
                window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
            }}
        />

    )
}

export default DetoxretreatList