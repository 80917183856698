import { all } from 'redux-saga/effects';
import { authSaga } from './authSaga';
import { appConfigSaga } from './appConfigSaga';
import { PatientSaga } from './patientSaga';
import { DoctorSaga } from './doctorSaga';
import { RoleSaga } from './RoleSaga';
import { StaffSaga } from './StaffSaga';
import { PermissionSaga } from './PermissionSaga';
import { AppointmentSaga } from './appointmentSaga';
import { UpdatePersonalDetailsWatchSaga } from './PersonalDetails.Saga';
import { ServiceSaga } from './ServiceSaga';
import { MasterDataSaga } from './MasterDataSaga';
import { commonSaga } from './CommonSaga';
import { ServiceSlotSaga } from './ServiceSlotSaga';
import { PriceSaga } from './priceSaga';
import { CelebritySaga } from './celebritySaga';
import { MedicalDetailsSaga } from './MedicalSaga';
import { OrderHistorySaga } from './OrderHistorySaga';
import { MedicalHistorySaga } from './MedicalHistorySaga';
import { FaqSaga } from './faqSaga';
import { PrakrutiStandardSaga } from './prakrutiStandardSaga';
import { TreatmentSlotSaga } from './TreatmentSlotSaga';
import { SolutionSlotSaga } from './SolutionSlotSaga';
import { TestimonialLinkSaga } from './TestimonialLinkSaga';
import { TherapistHistoryListSaga } from './therapistHistorySaga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    appConfigSaga(),
    PatientSaga(),
    DoctorSaga(),
    StaffSaga(),
    RoleSaga(),
    PermissionSaga(),
    AppointmentSaga(),
    ServiceSaga(),
    UpdatePersonalDetailsWatchSaga(),
    MasterDataSaga(),
    commonSaga(),
    ServiceSlotSaga(),
    PriceSaga(),
    CelebritySaga(),
    TestimonialLinkSaga(),
    MedicalDetailsSaga(),
    MedicalHistorySaga(),
    OrderHistorySaga(),
    FaqSaga(),
    PrakrutiStandardSaga(),
    TreatmentSlotSaga(),
    SolutionSlotSaga(),
    TherapistHistoryListSaga(),
  ]);
}
