import React, { useState } from 'react';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Calendar from 'react-calendar';
import { confetti, confettiBg } from '../../components/imagepath';
import 'react-calendar/dist/Calendar.css';
import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ActionGetCustomerBirthdateDetail } from '../../store/Actions/PatientAction';
import Link from 'antd/es/typography/Link';
const Birthdayfolks = () => {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const { birthdayDetail } = useSelector((state) => state.patient);
    const dispatch = useDispatch();



    const toggleCalendar = () => {
        setIsCalendarOpen(prevState => !prevState);
    };


    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setIsCalendarOpen(false);
        const formattedDate = formatDate(date);
        dispatch(ActionGetCustomerBirthdateDetail({ Query: `/${formattedDate} ` }));
    };



    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        <div className='py-3' style={{ textAlign: 'center', boxShadow: '0px 0px 14px rgba(204, 204, 204, 0.25)', background: '#fff', position: 'relative' }}>

                            <i className="fa fa-calendar"
                                style={{ fontSize: '28px', cursor: 'pointer', color: '#00000020' }}
                                onClick={toggleCalendar}>
                            </i>
                            {isCalendarOpen && (
                                <div

                                    style={{
                                        position: 'absolute',
                                        top: '50px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        zIndex: 9999
                                    }}>

                                    <Calendar
                                        onChange={handleDateChange}
                                        value={selectedDate}
                                    />
                                </div>
                            )}
                            {selectedDate && (
                                <span className='ps-2'>{`${selectedDate.getDate()}-${selectedDate.toLocaleString('default', { month: 'short' })}-${selectedDate.getFullYear()}`}</span>
                            )}
                        </div>

                        <div className="mt-4 row">
                            {birthdayDetail.length === 0 ? (
                                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center mt-5" style={{ minHeight: '200px' }}>
                                    <img src={confetti} alt="Confetti" style={{ width: '100px', marginBottom: '20px' }} />
                                    <h4 style={{ color: 'gray' }}>No Birthday Buddies Found</h4>
                                </div>
                            ) : (
                                birthdayDetail.map((item, index) => {
                                    const selectedYear = selectedDate.getFullYear();

                                    const birthDate = new Date(item.birthDate);
                                    const formattedDate = birthDate.getUTCDate() + ' ' + birthDate.toLocaleString('default', { month: 'short' }) + ' ' + selectedYear;
                                    const currentDate = new Date();
                                    const modifiedBirthDate = new Date(selectedDate.getFullYear(), birthDate.getMonth(), birthDate.getDate());
                                    if (currentDate.getFullYear() <= modifiedBirthDate.getFullYear()) {
                                        return (
                                            <div key={index} className="col-md-3 mb-4">
                                                <Card>
                                                    <div className="card-body" style={{ backgroundImage: `url(${confettiBg})`, backgroundSize: 'cover', backgroundPosition: 'center', color: 'white' }}>
                                                        <p className='text-center' style={{ color: 'grey' }}>{formattedDate}</p>
                                                        <h5 className='text-center' style={{ textTransform: 'capitalize', fontWeight: 'bold', color: 'black' }}>{item.name}</h5>
                                                        <p className='text-center' style={{ color: 'grey' }}>{item.phoneNo}</p>
                                                        <Link><p className='text-center'>{item.email}</p></Link>
                                                    </div>
                                                </Card>
                                            </div>
                                        );
                                    }
                                })
                            )}
                        </div>
                    </div>
                </div>
            </>


        </>
    )
}

export default Birthdayfolks