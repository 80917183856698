import { CONSULTATION_MASTERDATA, CONSULTATION_MASTERDATA_SUCCESS, CREATE_MEDICAL_DETAILS, GET_ADMIN_USER, GET_ADMIN_USER_SUCCESS, GET_ALL_APPOINMENT, GET_ALL_APPOINMENT_SUCCESS, GET_ALL_CONSULTATION, GET_ALL_CONSULTATION_SUCCESS, GET_DR_AVAILABILITY, GET_DR_AVAILABILITY_SUCCESS, GET_DR_SLOT_AVAILABILITY, GET_DR_SLOT_AVAILABILITY_SUCCESS } from "../storeTypes";



export const getAdminUser = (payload, callBack) => {
    return {
        type: GET_ADMIN_USER,
        payload,
        callBack
    };
};
export const getAdminUserSuccess = (payload) => {
    return {
        type: GET_ADMIN_USER_SUCCESS,
        payload,

    };
};
export const getAllConsultation = (payload, callBack) => {
    return {
        type: GET_ALL_CONSULTATION,
        callBack,
        payload,
    };
};
export const getAllConsultationSuccess = (payload) => {
    return {
        type: GET_ALL_CONSULTATION_SUCCESS,
        payload,
    };
};

export const getAllAppoinment = (payload) => {
    return {
        type: GET_ALL_APPOINMENT,
        payload,
    };
};
export const getAllAppoinmentSuccess = (payload) => {
    return {
        type: GET_ALL_APPOINMENT_SUCCESS,
        payload,
    };
};

export const getDrAvailabilityAction = (payload, callBack) => {
    return {
        type: GET_DR_AVAILABILITY,
        payload,
        callBack
    };
};
export const getDrAvailabilitySuccess = (payload) => {
    return {
        type: GET_DR_AVAILABILITY_SUCCESS,
        payload,
    };
};

export const getDrSlotAvailabilityAction = (payload, callBack) => {
    return {
        type: GET_DR_SLOT_AVAILABILITY,
        payload,
        callBack
    };
};
export const getDrSlotAvailabilitySuccess = (payload) => {
    return {
        type: GET_DR_SLOT_AVAILABILITY_SUCCESS,
        payload,
    };
};


export const ConsultationMasterDataAction = (payload, callBack) => {
    return {
        type: CONSULTATION_MASTERDATA,
        payload,
        callBack
    };
};
export const ConsultationMasterDataSuccess = (payload) => {
    return {
        type: CONSULTATION_MASTERDATA_SUCCESS,
        payload,
    };
};
export const CreateConsultationMedicalDetailsAction = (payload, callBack) => {
    return {
        type: CREATE_MEDICAL_DETAILS,
        payload,
        callBack
    };
};