import { GET_ALL_SOLUTION_SLOT, GET_ALL_SOLUTION_SLOT_SUCCESS, GET_SOLUTION_SLOT_BY_ID, GET_SOLUTION_SLOT_BY_ID_SUCCESS, UPDATE_SOLUTION_SLOT } from "../storeTypes";

const INIT_STATE = {
    loader: false,
    SolutionSlot: []
}

function SolutionSlotReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case GET_ALL_SOLUTION_SLOT: {
            return {
                ...state,
                SolutionSlot: [],
                loader: true,
            };
        }
        case GET_ALL_SOLUTION_SLOT_SUCCESS: {
            return {
                ...state,
                loader: false,
                SolutionSlot: action?.payload
            }
        }
        case GET_SOLUTION_SLOT_BY_ID: {
            return {
                ...state,
                SolutionSlot: [],
                loader: true,
            };
        }
        case GET_SOLUTION_SLOT_BY_ID_SUCCESS: {
            return {
                ...state,
                loader: false,
                SolutionSlot: action.payload,
            };
        }
        case UPDATE_SOLUTION_SLOT: {
            return {
                ...state,
                loader: true,
            }
        }
        default: {
            return state;
        }
    }
}

export default SolutionSlotReducer;