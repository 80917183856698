import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateQueryString } from '../../utils/Funcations';
import queryString from 'query-string';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Popover, Modal } from 'antd';
import { ActionDeleteById } from '../../store/Actions/CommonAction';
import { matchPermissions } from '../../utils/Permission';
import { TableListPageCom } from '../../components/TableListPageCom';
import { GetAllTestimonialLink } from '../../store/Actions/TestimonialLinkAction';

const TestimonialLinkList = () => {
    const { confirm } = Modal;
    const pageSize = 10;
    const dispatch = useDispatch();
    const { TestimonialLinkList, loader } = useSelector(state => state?.TestimonialLink);
    const { userDetails } = useSelector(state => state?.auth);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const page = searchParams.get("page");
    const patientName = searchParams.get("patientName");
    const Final = generateQueryString(['page', page || 1],
        ['patientName', patientName],
    );
    const params = queryString.parse(location.search);
    useEffect(() => {
        dispatch(GetAllTestimonialLink({ Query: Final, }));
    }, [])

    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('patient-testimonial-link.update');
    const canDelete = matchPermissionsForUser('patient-testimonial-link.delete');

    const content = (item) => (
        <div className="row col">
            {canUpdate &&
                <Button className="col m-2" onClick={() => { window.location.href = `/add-testimonial-link?update=true&&page=${1}&&id=${item?.id}` }}>Edit Testimonial Link</Button>
            }
            {canDelete &&
                <Button className="col m-2" onClick={() => showDeleteConfirm(item)}>Delete Testimonial Link</Button>
            }
        </div>
    );

    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.patientName}?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteById({ Query: `admin/patient-testimonial-Link/${item?._id}` }, (Res) => {
                    if (Res?.statusCode === 200) {
                        //setRefresh(true)
                        window.location.reload()
                    }
                }))
            },
            onCancel() {
            },
        });
    };
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Patient Name",
            dataIndex: "patientName",
        },

        {
            title: "Treatments",
            dataIndex: "treatment",
            render: (treatment) => {
                if (treatment && treatment.length > 0) {
                    return treatment.map((item) => item.treatmentName).join(', ');
                }
                return null;
            }
        },
        {
            title: "Testimonial Link",
            dataIndex: "link",
        },
        {
            title: !canUpdate && !canDelete ? null : "Action",
            dataIndex: "roleName",
            render: (text, record) => {
                if (!canUpdate && !canDelete) {
                    return null
                } else {
                    return <Popover placement="top" title={text} content={() => content(record)}>
                        <Button type="text">• • •</Button>
                    </Popover >
                }
            },
        },
    ];

    const BreadcrumbList = [{
        name: 'Testimonial Link',
        linkTo: `/testimonial-link-list?page=${params?.page || 1}`
    },
    {
        name: 'Testimonial Link List',
    }
    ]
    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={TestimonialLinkList?.docs}
            TableColumn={columns}
            ShowAddButton={matchPermissions(userDetails)}
            Iscreate="patient-testimonial-link.create"
            CreateButtonLink={`/add-testimonial-link?update=false&&page=${params?.page || 1}`}
            PageMainTitle="Testimonial Link List"
            CurrentPage={TestimonialLinkList?.page || 1}
            Tableloader={loader}
            TotalPageList={TestimonialLinkList?.totalDocs}
            dataSourceLength={TestimonialLinkList?.docs?.length}
            handlePageChange={(v) => {
                var newp = params
                newp.page = v
                window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
            }}
        />

    )
}

export default TestimonialLinkList