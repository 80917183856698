import { CREATE_CELEBRITY, CREATE_CELEBRITY_SUCCESS, GET_ALL_CELEBRITY, GET_ALL_CELEBRITY_SUCCESS } from "../storeTypes";


export const CreateCelebrity = (payload, callBack) => {
    return {
        type: CREATE_CELEBRITY,
        payload,
        callBack
    };
};
export const CreateCelebritySuccess = (payload) => {
    return {
        type: CREATE_CELEBRITY_SUCCESS,
        payload
    };
};
export const GetAllcelebrity = (payload) => {
    return {
        type: GET_ALL_CELEBRITY,
        payload,
    };
};
export const GetAllcelebritySuccess = (payload) => {
    return {
        type: GET_ALL_CELEBRITY_SUCCESS,
        payload
    };
};
