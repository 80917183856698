import { UPDATE_PERSONAL_DETAILS, UPDATE_PERSONAL_DETAILS_SUCCESS } from "../storeTypes";


export const PersonalDetail = (payload, callBack) => {
    return {
        type: UPDATE_PERSONAL_DETAILS,
        payload,
        callBack
    };
};
export const PersonalDetailsuccess = (payload) => {
    return {
        type: UPDATE_PERSONAL_DETAILS_SUCCESS,
        payload
    };
};
