import {
    CREATE_PATIENT_TESTIMONIAL,
    CREATE_PATIENT_TESTIMONIAL_SUCCESS,
    CREATE_TESTIMONIAL_LINK,
    CREATE_TESTIMONIAL_LINK_SUCCESS,
    GET_ALL_PATIENT_TESTIMONIAL,
    GET_ALL_PATIENT_TESTIMONIAL_SUCCESS,
    GET_ALL_TESTIMONIAL_LINK,
    GET_ALL_TESTIMONIAL_LINK_SUCCESS,
} from "../storeTypes";

const INIT_STATE = {
    loader: false,
    TestimonialLinkList: [],
    PatientTestimonialList: [],
};

function TestimonialLinkReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case CREATE_TESTIMONIAL_LINK: {
            return {
                ...state,
                loader: true,
            };
        }
        case CREATE_TESTIMONIAL_LINK_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }
        case GET_ALL_TESTIMONIAL_LINK: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_TESTIMONIAL_LINK_SUCCESS: {
            return {
                ...state,
                loader: false,
                TestimonialLinkList: action?.payload
            };
        }

        // ------------------ Patinet Testimonial ----------------

        case CREATE_PATIENT_TESTIMONIAL: {
            return {
                ...state,
                loader: true,
            };
        }
        case CREATE_PATIENT_TESTIMONIAL_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }
        case GET_ALL_PATIENT_TESTIMONIAL: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_PATIENT_TESTIMONIAL_SUCCESS: {
            return {
                ...state,
                loader: false,
                PatientTestimonialList: action?.payload
            };
        }

        default: {
            return state;
        }
    }

}

export default TestimonialLinkReducer;