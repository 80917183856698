import { GET_ALL_SOLUTION_SLOT, GET_ALL_SOLUTION_SLOT_SUCCESS, GET_SOLUTION_SLOT_BY_ID, GET_SOLUTION_SLOT_BY_ID_SUCCESS, UPDATE_SOLUTION_SLOT } from "../storeTypes";

export const GetAllSolutionSlot = (payload) => {
    return {
        type: GET_ALL_SOLUTION_SLOT,
        payload,
    }
};

export const GetAllSolutionSlotSuccess = (payload) => {
    return {
        type: GET_ALL_SOLUTION_SLOT_SUCCESS,
        payload,
    }
};
export const ActionGetSolutionSlotById = (payload, callBack) => {
    return {
        type: GET_SOLUTION_SLOT_BY_ID,
        payload,
        callBack
    };
};
export const ActionGetSolutionSlotByIdSuccess = (payload) => {
    return {
        type: GET_SOLUTION_SLOT_BY_ID_SUCCESS,
        payload
    };
};
export const ActionUpdateSolutionSlot = (payload, callBack) => {
    return {
        type: UPDATE_SOLUTION_SLOT,
        payload,
        callBack
    };
};