/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { InputFromUser } from "../../../../components/Input/input";
import { getAdminuUerDetails } from "../../../../store/Actions/AuthActions";
import { ActionCreateByUrl, ActionUpdateById } from "../../../../store/Actions/CommonAction";
import { ActionGetShiftsList } from "../../../../store/Actions/StaffAction";
import dayjs from 'dayjs';
const Add_Shifts = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const updateValue = searchParams.get("update");
    const pageValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const idValue = searchParams.get("id");
    const { userDetails } = useSelector(state => state?.auth);
    const userId = userDetails?.[0]?._id
    const { therapistShift } = useSelector(state => state?.Staff);
    useEffect(() => {
        dispatch(getAdminuUerDetails())
        if (idValue) {
            dispatch(ActionGetShiftsList({ Query: `/${idValue}` }));
        }
    }, [pageValue, dispatch, idValue, userId])
    return (
        <>
            {/* <Loader IsModal={loader} /> */}
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/shifts">Shifts</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Shifts</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={{
                                                    startTime: therapistShift?.[0]?.startTime && dayjs(therapistShift?.[0]?.startTime) || null,
                                                    endTime: therapistShift?.[0]?.endTime && dayjs(therapistShift?.[0]?.endTime) || null,
                                                    title: therapistShift?.[0]?.title || "",
                                                    createdBy: userId,

                                                }}
                                                // validationSchema={ExerciseValidationSchema}
                                                onSubmit={(values, { setSubmitting, setErrors }) => {

                                                    if (!updateValue) {
                                                        dispatch(ActionCreateByUrl({ Query: 'admin/therapistShift', obj: values }, (Res) => {
                                                            setSubmitting(false);
                                                            if (Res?.statusCode === 200) {
                                                                window.location.href = `/shifts?page=${pageValue}`;
                                                            } else if (Res?.statusCode === 400) {
                                                                setErrors(Res?.data?.errors);
                                                            } else {
                                                                // window.location.reload();
                                                            }
                                                        }))
                                                    } else {

                                                        dispatch(ActionUpdateById({ Query: `admin/therapistShift/${idValue}`, obj: values }
                                                            , (Res) => {
                                                                setSubmitting(false);
                                                                if (Res?.statusCode === 200) {
                                                                    window.location.href = `/shifts?page=${pageValue}`;
                                                                } else if (Res?.statusCode === 400) {
                                                                    setErrors(Res?.data?.errors);
                                                                } else {
                                                                    window.location.reload();
                                                                }
                                                            }))

                                                    }
                                                }}

                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    // setFieldValue,
                                                    isSubmitting,
                                                }) => {
                                                    return (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-heading">
                                                                        <h4>Add Shifts</h4>
                                                                    </div>
                                                                </div>
                                                                <InputFromUser
                                                                    key={'shift'}
                                                                    placeholder={"Shift Name"}
                                                                    type="text"
                                                                    name="title"
                                                                    onChange={handleChange}
                                                                    FormGroupClassName={"col-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values.title}
                                                                    label="Shift Name"
                                                                    error={errors?.title}
                                                                    touched={touched.title}
                                                                />
                                                                <InputFromUser
                                                                    key={'timeS'}
                                                                    placeholder={"Pick Your Start Time"}
                                                                    type="TimePicker"
                                                                    name="startTime"
                                                                    onChange={(v) => {
                                                                        handleChange({
                                                                            target: {
                                                                                name: 'startTime',
                                                                                value: v,
                                                                            },
                                                                        })
                                                                    }}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={() => {
                                                                        handleBlur({
                                                                            target: {
                                                                                name: 'startTime',
                                                                                value: true,
                                                                            },
                                                                        })
                                                                    }} value={values.startTime}
                                                                    label="Start Time"
                                                                    error={errors?.startTime}
                                                                    touched={touched.startTime}
                                                                />
                                                                <InputFromUser
                                                                    key={'timeE'}
                                                                    placeholder={"Pick Your End Time"}
                                                                    type="TimePicker"
                                                                    name="endTime"
                                                                    onChange={(v) => {
                                                                        handleChange({
                                                                            target: {
                                                                                name: 'endTime',
                                                                                value: v,
                                                                            },
                                                                        })
                                                                    }}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={() => {
                                                                        handleBlur({
                                                                            target: {
                                                                                name: 'endTime',
                                                                                value: true,
                                                                            },
                                                                        })
                                                                    }}
                                                                    value={values.endTime}
                                                                    label="End Time"
                                                                    error={errors?.endTime}
                                                                    touched={touched.endTime}
                                                                />

                                                                <div className="col-12">
                                                                    <div className="doctor-submit text-end">
                                                                        <button
                                                                            type="submit"
                                                                            onClick={() => {
                                                                                handleSubmit()
                                                                            }}
                                                                            className="btn btn-primary submit-form me-2"
                                                                            disabled={isSubmitting}
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary cancel-form"
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Formik>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div >
            </>
        </>
    );
};

export default Add_Shifts;
