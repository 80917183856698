import { GET_ALL_TREATMENT_SLOT, GET_ALL_TREATMENT_SLOT_SUCCESS, GET_TREATMENT_SLOT_BY_ID, GET_TREATMENT_SLOT_BY_ID_SUCCESS, UPDATE_TREATMENT_SLOT } from "../storeTypes";

export const GetAllTreatmentSlot = (payload) => {
    return {
        type: GET_ALL_TREATMENT_SLOT,
        payload,
    }
};

export const GetAllTreatmentSlotSuccess = (payload) => {
    return {
        type: GET_ALL_TREATMENT_SLOT_SUCCESS,
        payload,
    }
};
export const ActionUpdateTreatmentSlot = (payload, callBack) => {
    return {
        type: UPDATE_TREATMENT_SLOT,
        payload,
        callBack
    };
};

export const ActionGetTreatmentSlotById = (payload, callBack) => {
    return {
        type: GET_TREATMENT_SLOT_BY_ID,
        payload,
        callBack
    };
};
export const ActionGetTreatmentSlotByIdSuccess = (payload) => {
    return {
        type: GET_TREATMENT_SLOT_BY_ID_SUCCESS,
        payload
    };
};