import { CREATE_PRICE, DELETE_PRICE, GET_ALL_PRICE_LIST, GET_ALL_PRICE_LIST_SUCCESS, GET_PRICE_BY_ID, GET_PRICE_BY_ID_SUCCESS, UPDATE_PRICE } from "../storeTypes"

export const ActionGetAllPriceList = (payload) => {
    return {
        type: GET_ALL_PRICE_LIST,
        payload
    }
}
export const ActionGetAllPriceListSuccess = (payload) => {
    return {
        type: GET_ALL_PRICE_LIST_SUCCESS,
        payload
    }
}
export const ActionCreatePrice = (payload, callBack) => {
    return {
        type: CREATE_PRICE,
        payload,
        callBack
    };
};

export const ActionUpdatePrice = (payload, callBack) => {
    return {
        type: UPDATE_PRICE,
        payload,
        callBack
    };
};

export const ActionGetPriceByID = (payload, callBack) => {
    return {
        type: GET_PRICE_BY_ID,
        payload,
        callBack
    };
};

export const ActionGetPriceByIDSuccess = (payload) => {
    return {
        type: GET_PRICE_BY_ID_SUCCESS,
        payload
    };
};

export const ActionDeletePrice = (payload, callBack) => {
    return {
        type: DELETE_PRICE,
        payload,
        callBack
    };
};