/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
// import Header from "../../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { InputFromUser } from "../../../components/Input/input";
import { ActionCreatePrice, ActionGetPriceByID, ActionUpdatePrice } from "../../../store/Actions/PriceAction";
import { PriceValidationSchema } from "../../../utils/FormValidation";
import Header from "../../../components/Header";

const AddPrice = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const updateValue = searchParams.get("update");
    const pageValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const idValue = searchParams.get("id");

    useEffect(() => {
        if (idValue) {
            dispatch(ActionGetPriceByID({ Query: `/${idValue}` }));
        }
    }, [idValue, dispatch])
    const { consultationPrice } = useSelector(state => state?.PriceTable);
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/price-table">Price</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Price</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue}
                                                initialValues={{
                                                    consultationType: consultationPrice?.consultationType || "",
                                                    totalMinute: consultationPrice?.totalMinute || "",
                                                    price: consultationPrice?.price || "",
                                                }}
                                                validationSchema={PriceValidationSchema}
                                                onSubmit={(values, { setSubmitting, setErrors }) => {
                                                    if (!updateValue) {
                                                        dispatch(ActionCreatePrice({
                                                            "consultationType": values.consultationType,
                                                            "totalMinute": values.totalMinute,
                                                            "price": values.price,
                                                        },
                                                            (Res) => {
                                                                setSubmitting(false);
                                                                if (Res?.statusCode === 200) {
                                                                    window.location.href = `/price-table?page=${pageValue}`;
                                                                } else if (Res?.statusCode === 400) {
                                                                    setErrors(Res?.data?.errors);
                                                                } else {
                                                                    window.location.reload();
                                                                }
                                                            }))
                                                    } else {
                                                        dispatch(ActionUpdatePrice({
                                                            Query: "/" + idValue,
                                                            obj: {
                                                                "consultationType": values.consultationType,
                                                                "totalMinute": values.totalMinute,
                                                                "price": values.price,
                                                            }
                                                        }, (Res) => {
                                                            setSubmitting(false);
                                                            if (Res?.statusCode === 200) {
                                                                window.location.href = `/price-table?page=${pageValue}`;
                                                            } else if (Res?.statusCode === 400) {
                                                                setErrors(Res?.data?.errors);
                                                            } else {
                                                                window.location.reload();
                                                            }
                                                        }))

                                                    }
                                                }}

                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue,
                                                    isSubmitting,
                                                }) => {
                                                    return (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-heading">
                                                                        <h4>Add Price Data</h4>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex">
                                                                    <InputFromUser
                                                                        placeholder={"Consultation Type"}
                                                                        type="text"
                                                                        name="consultationType"
                                                                        onChange={handleChange}
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={handleBlur}
                                                                        value={values.consultationType}
                                                                        label="Consultation Type"
                                                                        error={errors?.consultationType}
                                                                        touched={touched.consultationType}
                                                                    />
                                                                </div>

                                                                <div className="d-flex">
                                                                    <InputFromUser
                                                                        placeholder={"Total Minute"}
                                                                        type="text"
                                                                        name="totalMinute"
                                                                        onChange={handleChange}
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={handleBlur}
                                                                        value={values.totalMinute}
                                                                        label="Total Minute"
                                                                        error={errors?.totalMinute}
                                                                        touched={touched.totalMinute}
                                                                    />
                                                                </div>

                                                                <div className="d-flex">
                                                                    <InputFromUser
                                                                        placeholder={"Price"}
                                                                        type="text"
                                                                        name="price"
                                                                        onChange={handleChange}
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={handleBlur}
                                                                        value={values.price}
                                                                        label="Price"
                                                                        error={errors?.price}
                                                                        touched={touched.price}
                                                                    />
                                                                </div>

                                                                <div className="col-12 mt-4">
                                                                    <div className="doctor-submit text-end">
                                                                        <button
                                                                            type="submit"
                                                                            onClick={() => {
                                                                                handleSubmit()
                                                                            }}
                                                                            className="btn btn-primary submit-form me-2"
                                                                            disabled={isSubmitting}
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary cancel-form"
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Formik>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div >
            </>
        </>
    );
};

export default AddPrice;