/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { GetAllTreatment } from "../../store/Actions/ServiceAction";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { ResponsiveComponent, generateQueryString, trimText } from "../../utils/Funcations";
import { Button, Modal, Popover } from "antd";
import { ActionDeleteById } from "../../store/Actions/CommonAction";
import { matchPermissions } from "../../utils/Permission";
import { TableListPageCom } from "../../components/TableListPageCom";
import { getAdminuUerDetails } from "../../store/Actions/AuthActions";
import { DOCS_URL } from "../../utils/globals";

const Treatmentlist = () => {
    const { confirm } = Modal;
    const Size = ResponsiveComponent()
    const pageSize = 10;
    const dispatch = useDispatch();
    const { treatMentList, loader } = useSelector(state => state?.Service);
    const { userDetails } = useSelector(state => state?.auth);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const page = searchParams.get("page");
    const userId = userDetails?.[0]?._id

    const TreatmentName = searchParams.get("TreatmentName");
    const sorting = 'createdAt:-1';
    const Final = generateQueryString(['page', page || 1],
        ['name', TreatmentName], ['sort', sorting],
    );
    const params = queryString.parse(location.search);
    useEffect(() => {
        dispatch(GetAllTreatment({ Query: Final, }));
    }, [])
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('treatment.update');
    const canDelete = matchPermissionsForUser('treatment.delete');
    // const canCerate = matchPermissionsForUser('treatment.create');
    const content = (item) => (
        <div className="row col">
            {canUpdate &&
                <Button className="col m-2" onClick={() => { window.location.href = `/add-treatment?update=true&&page=${1}&&id=${item?.id}` }}>Edit Treatment</Button>
            }
            {canDelete &&
                <Button className="col m-2" onClick={() => showDeleteConfirm(item)}>Delete Treatment</Button>
            }
        </div>
    );
    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.name} Treatment?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteById({ Query: `admin/treatment/${item?._id}` }, (Res) => {
                    if (Res?.statusCode === 200) {
                        //setRefresh(true)
                        window.location.reload()
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Treatment Name",
            dataIndex: "name",
            //...TableColumnSearch({ dataIndex: 'name', setSearchText, setSearchedColumn, handleReset, handleSearch }),
            //sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Treatment Descriptions",
            dataIndex: "shortDescription",
            render: (text, record) => (
                <div className="app-config-boldText">{trimText(text, Size === "phone" || Size === "tablet" ? 20 : 50)}</div>
            ),
            //...TableColumnSearch({ dataIndex: 'name', setSearchText, setSearchedColumn, handleReset, handleSearch }),
            //sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Per day Price",
            dataIndex: "perDayPrice",
            // render: (text, record) => (
            //     <div className="app-config-boldText">{trimText(text, Size === "phone" || Size === "tablet" ? 20 : 50)}</div>
            // ),
            //...TableColumnSearch({ dataIndex: 'name', setSearchText, setSearchedColumn, handleReset, handleSearch }),
            //sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Treatment rating",
            dataIndex: "rating",
            //...TableColumnSearch({ dataIndex: 'name', setSearchText, setSearchedColumn, handleReset, handleSearch }),
            //sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Image",
            dataIndex: "listingViewImage",
            render: (text, record) => (
                <img src={DOCS_URL + record.listingViewImage} alt={record.name} style={{ width: 60, height: 60 }} />
            ),
        },
        {
            title: !canUpdate && !canDelete ? null : "Action",
            dataIndex: "roleName",
            render: (text, record,) => {
                if (!canUpdate && !canDelete) {
                    return null
                } else {
                    return <Popover placement="top" title={text} content={() => content(record)}>
                        <Button type="text">• • •</Button>
                    </Popover>
                }
            },
        },
    ]
    const BreadcrumbList = [{
        name: 'Treatment',
        linkTo: `/treatment-list?page=${params?.page || 1}`
    },
    {
        name: 'Treatment List',
    }
    ]
    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={treatMentList?.[0]?.docs}
            TableColumn={columns}
            ShowAddButton={true}
            Iscreate="treatment.create"
            CreateButtonLink={`/add-treatment?update=false&&page=${params?.page || 1}`}
            PageMainTitle="Treatment List"
            CurrentPage={treatMentList?.[0]?.page || 1}
            Tableloader={loader}
            TotalPageList={treatMentList?.[0]?.totalDocs}
            dataSourceLength={treatMentList?.[0]?.docs?.length}
            handlePageChange={(v) => {
                var newp = params
                newp.page = v
                window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
            }}
        />

    )
};

export default Treatmentlist;
