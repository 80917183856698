import { call, put, takeEvery } from "redux-saga/effects";
import { ActionGetAllMedicalHistorySuccess } from "../Actions/MedicalHistoryAction";
import { GET_ALL_MEDICAL_HISTORY } from "../storeTypes";
import { GetMedicalHistoryDetailsApi } from "../../utils/api";
import { handleApiResponse } from "../../utils/Funcations";


const File = "MedicalHistorySaga"

function* GetAllMedicalHistoryDetailsSaga(action) {
    try {
        const response = yield call(GetMedicalHistoryDetailsApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetAllMedicalHistorySuccess(response?.data?.data[0]));
        }
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callback);
        console.log(File + "MedicalHistorySaga", error);
    }
}

export function* MedicalHistorySaga() {
    yield takeEvery(GET_ALL_MEDICAL_HISTORY, GetAllMedicalHistoryDetailsSaga)

}