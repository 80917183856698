/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { Button, Divider } from "antd";
import Header from "../../components/Header";
import { InputFromUser } from "../../components/Input/input";
import Sidebar from "../../components/Sidebar";
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ActionGetCCPaperDetail } from "../../store/Actions/PatientAction";
import { useEffect } from "react";
import { PersonalDetail } from "../../store/Actions/personalDetails.actions";
import { calculateAge } from "../../utils/Funcations";
import dayjs from 'dayjs';

const TwentyMinForm = () => {
    const { personalDetils } = useSelector(state => state?.personmalDetail);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const idValue = searchParams.get("id");
    const forValue = searchParams.get("for");
    const updateValue = searchParams.get("update");
    const dispatch = useDispatch();
    useEffect(() => {
        if (idValue) {
            dispatch(ActionGetCCPaperDetail({ Query: `/${idValue}`, obj: undefined }))
        }
    }, [])
    const { CustomerDashbord, CcPaperDetail } = useSelector(state => state?.patient);
    const first8 = [
        {
            value: (CcPaperDetail.birthDate && dayjs(CcPaperDetail.birthDate) || null), title: "Date of Birth", personalDetilsKey: 'dob'
            , type: 'date'
        }, // 1 index
        {
            value: CcPaperDetail.age || '', title: "Age", personalDetilsKey: 'age'
            , type: 'text'
        }, // 2 index
        {
            value: CcPaperDetail.gender || '', title: "Gender", personalDetilsKey: 'gender',
            Option: [
                { title: 'Male', isChecked: false },
                { title: 'Female', isChecked: false },
            ]
            , type: 'radio'

        },// 3 index
        {
            value: CcPaperDetail.height || '', title: "Height", personalDetilsKey: 'height'
            , type: 'text'
        },// 4 index
        {
            value: CcPaperDetail.weight || '', title: "Weight", personalDetilsKey: 'weight'
            , type: 'text'
        },// 5 index
        {
            value: CcPaperDetail.address || '', title: "Enter Your Full Address", personalDetilsKey: 'address'
            , type: 'text'
        },// 6 index
        {
            value: CcPaperDetail.education || '', title: "Enter Your Highest Education", personalDetilsKey: 'Education'
            , type: 'text'
        },// 7 index

        ...(CcPaperDetail?.personalDetails || []).slice(8, 59),
    ]

    return (
        <>
            <Header />
            <Sidebar id='menu-item15' id1='menu-items15' activeClassName='vertical-form' />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="#">20 Minutes Form </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* /Page Header */}
                        <div className="row">
                            <div className="col-md-12 m-auto">
                                <div className="card-box">
                                    <Formik
                                        enableReinitialize={forValue === '125'}
                                        initialValues={{
                                            personalDetils: forValue === '125' ? first8 : personalDetils,
                                        }}
                                        //validationSchema={PersonalDetailsValidationSchema}
                                        onSubmit={(values, { setErrors }) => {
                                            const selectedPersonalDetils = values.personalDetils.slice(7);
                                            dispatch(
                                                PersonalDetail({
                                                    customerId: idValue,
                                                    obj: JSON.stringify({
                                                        age: values.personalDetils?.[1].value,
                                                        dateOfBirth: values.personalDetils?.[0].value,
                                                        gender: values.personalDetils?.[2].value,
                                                        weight: values.personalDetils?.[4].value,
                                                        height: values.personalDetils?.[3].value,
                                                        address: values.personalDetils?.[5].value,
                                                        education: values.personalDetils?.[6].value,
                                                        personalDetails: selectedPersonalDetils
                                                    })
                                                }, (Res) => {
                                                    if (Res?.statusCode === 200) {
                                                        forValue === '125' ? window.location.href = `/patient-list/patient-profile?patientId=${idValue}` : window.location.href = `/patient-list?page=1`;
                                                    } else if (Res?.statusCode === 400) {
                                                        window.scrollTo({
                                                            top: 0,
                                                            behavior: 'smooth',
                                                        });
                                                        setErrors(Res?.data?.errors);
                                                    }
                                                })
                                            );
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            setValues,
                                            setFieldValue,
                                            setSubmitting
                                        }) => {

                                            return (
                                                <div>
                                                    <div className="row">
                                                        {values?.personalDetils && values?.personalDetils.map((v, k) => {
                                                            const sweat = values?.personalDetils?.[k]?.personalDetilsKey === "sweat_all_round"
                                                            const Personal = values?.personalDetils?.[k]?.personalDetilsKey === "dob"
                                                            const MentalHealth = values?.personalDetils?.[k]?.personalDetilsKey === "did_you_mentalTrauma"
                                                            const LifeStyleAnalysis = values?.personalDetils?.[k]?.personalDetilsKey === "natureOfwork"
                                                            const BodyAnalysisPregnancy = values?.personalDetils?.[k]?.personalDetilsKey === "pregnant"
                                                            const Menstruation = values?.personalDetils?.[k]?.personalDetilsKey === "what_age_get_your_Period"
                                                            const Stool = values?.personalDetils?.[k]?.personalDetilsKey === "Do_u_have_bowel_movement_everday"
                                                            const Urine = values?.personalDetils?.[k]?.personalDetilsKey === "_in_day_you_pass_urine"
                                                            return (
                                                                <>
                                                                    {
                                                                        (sweat || Personal || MentalHealth || LifeStyleAnalysis || BodyAnalysisPregnancy || Menstruation || Stool || Urine)
                                                                        && <Divider style={{ fontWeight: "600" }}>{
                                                                            Personal ? 'Personal Details' :
                                                                                sweat ? 'Body Analysis - Sweat' :
                                                                                    MentalHealth ? 'Mental Health' :
                                                                                        LifeStyleAnalysis ? 'Life Style Analysis' :
                                                                                            Menstruation ? 'Body Analysis - Menstruation' :
                                                                                                Stool ? 'Body Analysis - Stool' :
                                                                                                    Urine ? 'Body Analysis - Urine' :
                                                                                                        BodyAnalysisPregnancy ? 'Body Analysis - Pregnancy'
                                                                                                            : ''}</Divider>
                                                                    }
                                                                    <InputFromUser
                                                                        key={k + "input"}
                                                                        label={values?.personalDetils?.[k]?.title}
                                                                        type={values?.personalDetils?.[k]?.type}
                                                                        name={values?.personalDetils?.[k]?.personalDetilsKey}
                                                                        onChange={(v) => {
                                                                            const FilterdValue = values?.personalDetils?.[k]?.type === 'text' ? v?.target?.value : v
                                                                            const personalDetails = values?.personalDetils || [];
                                                                            const nameValue = personalDetails?.[k] || { value: '' };
                                                                            nameValue.value = FilterdValue
                                                                            const newList = [...(personalDetails || [])];
                                                                            newList[k] = nameValue;
                                                                            setFieldValue("personalDetils", newList, true);

                                                                            if (values?.personalDetils?.[k]?.personalDetilsKey === 'dob') {
                                                                                const FilterdValue = calculateAge(values?.personalDetils?.[k]?.value)
                                                                                const personalDetails = values?.personalDetils || [];
                                                                                const nameValue = personalDetails?.[1] || { value: '' };
                                                                                nameValue.value = FilterdValue
                                                                                const newList = [...(personalDetails || [])];
                                                                                newList[1] = nameValue;
                                                                                setFieldValue("personalDetils", newList, true);
                                                                            }
                                                                        }}
                                                                        selectedValue={values?.personalDetils?.[k]?.value}
                                                                        onBlur={handleBlur}
                                                                        FormControlClassName={'form-control'}
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        value={values?.personalDetils?.[k]?.value}
                                                                        error={errors?.name}
                                                                        disabled={values?.personalDetils?.[k]?.personalDetilsKey === 'age'}
                                                                        touched={touched.name}
                                                                        options={values?.personalDetils?.[k]?.type === "radio" ? values?.personalDetils?.[k]?.Option?.map((v) => ({
                                                                            label: v.title,
                                                                            value: v.title,
                                                                        })) : values?.personalDetils?.[k]?.Option}
                                                                        placeholder={'Please fill ' + values?.personalDetils?.[k]?.title}
                                                                    />
                                                                </>

                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-12 mt-5">

                                                        <div className="text-center">

                                                            <Button
                                                                onClick={() => {
                                                                    !updateValue === 'true' ?
                                                                        handleSubmit()
                                                                        :
                                                                        window.location.href = `./patient-list/patient-profile?patientId=${idValue}&page=1`
                                                                }}
                                                                type="primary"
                                                                // disabled={isSubmitting && true}
                                                                className="me-2"
                                                            >
                                                                {updateValue === 'true' ? 'Submit' : 'Close'}
                                                            </Button>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        }}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};

export default TwentyMinForm;