import { GET_ALL_THERAPIST_HISTORY, GET_ALL_THERAPIST_HISTORY_BY_ID, GET_ALL_THERAPIST_HISTORY_BY_ID_SUCCESS, GET_ALL_THERAPIST_HISTORY_SUCCESS, GET_THERAPIST_CSV_DATA, GET_THERAPIST_CSV_DATA_SUCCESS } from "../storeTypes";

const INIT_STATE = {
    loader: false,
    therapistHistoryList: [],
    therapistCsvData: []
};

function TherapistReducer(state = INIT_STATE, action) {

    switch (action.type) {

        case GET_ALL_THERAPIST_HISTORY: {
            return {
                ...state,
                therapistHistoryList: [],
                loader: true,
            };
        }
        case GET_ALL_THERAPIST_HISTORY_SUCCESS: {
            return {
                ...state,
                loader: false,
                therapistHistoryList: action.payload,
            };
        }
        case GET_ALL_THERAPIST_HISTORY_BY_ID: {
            return {
                ...state,
                therapistHistoryList: [],
                loader: true,
            };
        }
        case GET_ALL_THERAPIST_HISTORY_BY_ID_SUCCESS: {
            return {
                ...state,
                loader: false,
                therapistHistoryList: action.payload,
            };
        }
        case GET_THERAPIST_CSV_DATA: {
            return {
                ...state,
                therapistCsvData: [],
                loader: true,
            };
        }
        case GET_THERAPIST_CSV_DATA_SUCCESS: {
            return {
                ...state,
                loader: false,
                therapistCsvData: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}
export default TherapistReducer;