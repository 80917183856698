import { call, put, takeEvery } from 'redux-saga/effects';
import { CREATE_PATIENT, GET_ASSIGNED_DOCTOR_LIST, GET_CC_PAPER_DETAIL, GET_CC_PAPER_VERIFICATION_DETAIL, GET_CUSTOMER_BIRTHDATE_DETAIL, GET_CUS_DASHBOARD, GET_LABORATORY_DATA, GET_MEDICAL_DETAIL, GET_PATIENT_BY_ID, GET_PATIENT_CONFIRMATION_DETAIL, GET_PATIENT_LIST, MEDICINE_LIST, UPDATE_PATIENT } from '../storeTypes';
import { CreatePatientApi, GetAssignedDoctorApi, GetCCPaperDetailApi, GetCCPaperVerificationDetailApi, GetCustomerBirthDateDetailApi, GetCustomerConfirmationApi, GetCustomerDashboardApi, GetMedicalDetailApi, GetMedicineListApi, GetPatientApi, GetlaboratoryApi, UpdateByCustomerIdApi } from '../../utils/api';
import { ActionGetCCPaperDetailSuccess, ActionGetCCPaperVerificationDetailSuccess, ActionGetCustomerBirthdateDetailSuccess, ActionGetMedicineListSuccess, ActionGetPatientByIdSuccess, ActionGetPatientListSuccess, ActionGetlaboratorySuccess, GetAssignedDoctorListSuccess, GetCustomerDashboardSuccess, GetMedicalDetailSuccess, GetPatientConfirmationDetailSuccess } from '../Actions/PatientAction';
import { handleApiResponse } from '../../utils/Funcations';

const File = 'PatientSaga '
function* GetPatientSaga(action) {
    try {
        const response = yield call(GetPatientApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionGetPatientListSuccess(response?.data?.data));
        }
    } catch (error) {
        // yield put(handleError());
        console.log(File + '/GetPatientSaga Error :- ', error);
    }
}
function* GetPatientByIdSaga(action) {
    try {
        const response = yield call(GetPatientApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionGetPatientByIdSuccess(response?.data?.data));
        }
    } catch (error) {
        // yield put(handleError());
        console.log(File + '/GetPatientByIdSaga Error :- ', error);
    }
}
function* CreatePatientSaga(action) {
    try {
        const response = yield call(CreatePatientApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response);
        }
    } catch (error) {
        console.log(File + 'CreatePatientSaga Error :- ', error);
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}
function* UpdatePatientSaga(action) {

    try {
        const response = yield call(UpdateByCustomerIdApi, action.payload);
        if (response?.status === 200) {

            action?.callBack(response?.data);
        }
    } catch (error) {
        console.log(File + '/UpdatePatientSaga Error:', error);

        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
    }

}

function* GetlaboratorySaga(action) {
    try {
        const response = yield call(GetlaboratoryApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetlaboratorySuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'GetlaboratorySaga Error :- ', error);
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack,)
    }
}

function* GetMedicineListSaga(action) {
    try {
        const response = yield call(GetMedicineListApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetMedicineListSuccess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'GetMedicineListSaga Error :- ', error);
    }
}
function* GetCCPaperDetailSaga(action) {
    try {
        const response = yield call(GetCCPaperDetailApi, action.payload);
        console.log("GetCCPaperDetailApi", response?.data?.data);
        if (response?.status === 200) {
            yield put(ActionGetCCPaperDetailSuccess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'GetCCPaperDetailSaga Error :- ', error);
    }
}
function* GetCustomerBirthdateDetailSaga(action) {
    try {
        const response = yield call(GetCustomerBirthDateDetailApi, action.payload);
        if (response?.status === 200) {
            yield put(ActionGetCustomerBirthdateDetailSuccess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'GetCustomerBirthdateDetailSaga Error :- ', error);
    }
}
function* GetCCPaperVerificationDetailSaga(action) {
    try {
        const response = yield call(GetCCPaperVerificationDetailApi, action.payload);
        // console.log("GetCCPaperVerificationDetailApi", response?.data);
        if (response?.status === 200) {
            yield put(ActionGetCCPaperVerificationDetailSuccess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'GetCCPaperVerificationDetailSaga Error :- ', error);
    }
}
function* GetCustomerDashboardSaga(action) {
    try {
        const response = yield call(GetCustomerDashboardApi, action.payload);
        if (response?.status === 200) {
            yield put(GetCustomerDashboardSuccess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'GetCustomerDashboardSaga Error :- ', error);
    }
}
function* GetCustomerConfirmationSaga(action) {
    try {
        const response = yield call(GetCustomerConfirmationApi, action.payload);
        if (response?.status === 200) {
            yield put(GetPatientConfirmationDetailSuccess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'GetCustomerConfirmationSaga Error :- ', error);
    }
}
function* GetMedicalDetailSaga(action) {
    try {
        console.log("Before API Call");
        const response = yield call(GetMedicalDetailApi, action.payload);
        console.log("After API Call");
        if (response?.status === 200) {
            yield put(GetMedicalDetailSuccess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'GetMedicalDetailSaga Error :- ', error);
    }
}

function* GetAssignedDoctorSaga(action) {
    try {
        const response = yield call(GetAssignedDoctorApi, action.payload);
        if (response?.status === 200) {
            yield put(GetAssignedDoctorListSuccess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'GetAssignedDoctorSaga Error :- ', error);
    }
}
export function* PatientSaga() {
    console.log("sagaworking");
    [
        yield takeEvery(GET_PATIENT_LIST, GetPatientSaga),
        yield takeEvery(GET_PATIENT_BY_ID, GetPatientByIdSaga),
        yield takeEvery(UPDATE_PATIENT, UpdatePatientSaga),
        yield takeEvery(CREATE_PATIENT, CreatePatientSaga),
        yield takeEvery(MEDICINE_LIST, GetMedicineListSaga),
        yield takeEvery(GET_LABORATORY_DATA, GetlaboratorySaga),
        yield takeEvery(GET_CC_PAPER_DETAIL, GetCCPaperDetailSaga),
        yield takeEvery(GET_CUS_DASHBOARD, GetCustomerDashboardSaga),
        yield takeEvery(GET_PATIENT_CONFIRMATION_DETAIL, GetCustomerConfirmationSaga),
        yield takeEvery(GET_MEDICAL_DETAIL, GetMedicalDetailSaga),
        yield takeEvery(GET_CUSTOMER_BIRTHDATE_DETAIL, GetCustomerBirthdateDetailSaga),
        yield takeEvery(GET_CC_PAPER_VERIFICATION_DETAIL, GetCCPaperVerificationDetailSaga),
        yield takeEvery(GET_ASSIGNED_DOCTOR_LIST, GetAssignedDoctorSaga),
    ];
}
