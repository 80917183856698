import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import TableColumnSearch from '../../components/Input/TableColumnSearch';

import { TableListPageCom } from '../../components/TableListPageCom';
import MemberShipModel from '../../components/Modal/MemberShipModel';
import { getAllMemberShipVerifyList } from '../../store/Actions/MasterDataAction';

const MemberVerification = () => {
    const pageSize = 10;
    const dispatch = useDispatch();
    const { memberShipVerifyData } = useSelector(state => state?.MasterData);
    const [modal, SetModal] = useState({ is: false, record: null });
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const params = queryString.parse(location.search);
    // const { userDetails } = useSelector(state => state?.auth);
    const name = params?.name ? `&name=${params?.name}` : ''
    const phoneNo = params?.phoneNo ? `&phoneNo=${params?.phoneNo}` : ''
    const memberShipType = params?.memberShipType ? `&memberShipType=${params?.memberShipType}` : ''
    const Final = name + phoneNo + memberShipType || ''
    console.log('searchedColumn', searchedColumn);
    const [Refresh, setRefresh] = useState(false);
    const handleSearch = (selectedKeys, dataIndex) => {
        setSearchText(selectedKeys?.[0]);
        setSearchedColumn(dataIndex);
        if (params[dataIndex]) {
            window.location.replace(`verify-member/?page=1&filterlist=true&${dataIndex}=${selectedKeys?.[0]}`)
        } else {
            window.location.replace(`verify-member?${location.search}&${dataIndex}=${selectedKeys?.[0]}`)
        }
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        window.location.replace(`?page=1`)
    }
    useEffect(() => {
        dispatch(getAllMemberShipVerifyList({ Query: `?page=${params?.page || 1}${Final}`, }));
        setRefresh(false);
    }, [Refresh, searchText])
    const Column = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Customer Name",
            dataIndex: "name",
            ...TableColumnSearch({ dataIndex: 'name', setSearchText, setSearchedColumn, handleReset, handleSearch }),
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Email",
            dataIndex: "email",

        },
        {
            title: "Phone No",
            dataIndex: "phoneNo",
            ...TableColumnSearch({ dataIndex: 'phoneNo', setSearchText, setSearchedColumn, handleReset, handleSearch }),

        },
        {
            title: "Membership Type",
            dataIndex: "memberShipDetails",
            render: (memberShipDetails) => memberShipDetails?.map((item) => item?.memberShipType).join(", ") || "---",
        },
        {
            title: "Membership Days",
            dataIndex: "memberShipDetails",
            render: (memberShipDetails) => memberShipDetails?.map((item) => item?.memberShipDays).join(", ") || "---",
        },
        {
            title: "Actions",
            dataIndex: "",
            render: (record) => {
                return <Button type="primary" onClick={() => SetModal({ is: true, record: record })}>Verified</Button>
            },
        },

    ]
    const BreadcrumbList = [{
        name: 'Member',
        linkTo: `/verify-member?page=${params?.page || 1}`
    },
    {
        name: 'Member verification List',
    }
    ]

    return (
        <>
            <TableListPageCom
                BreadcrumbList={BreadcrumbList}
                TableData={memberShipVerifyData ? memberShipVerifyData?.docs : []}
                TableColumn={Column}
                ShowAddButton={false}
                PageMainTitle="Member Verification List"
                CurrentPage={memberShipVerifyData?.page || 1}
                // Tableloader={loader}
                TotalPageList={memberShipVerifyData?.totalDocs}
                dataSourceLength={memberShipVerifyData?.docs?.length}
                handlePageChange={(v) => {
                    var newp = params
                    newp.page = v
                    window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
                }}
            />
            <MemberShipModel IsOpen={modal} record={modal.record} Isclose={() => SetModal({ is: false, record: null })} />

        </>
    )
}

export default MemberVerification