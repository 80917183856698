/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { VirechanValidationSchema } from "../../../../utils/FormValidation";
import { useDispatch, useSelector } from "react-redux";
import { InputFromUser } from "../../../../components/Input/input";
import { TextEditor } from "../../../../components/Input/input";
import { ActionCreateVirechan, ActionGetVirechanList, ActionUpdateVirechan } from "../../../../store/Actions/MasterDataAction";

const Add_Virechan = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const updateValue = searchParams.get("update");
    const pageValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const idValue = searchParams.get("id");
    useEffect(() => {
        if (idValue) {
            dispatch(ActionGetVirechanList({ Query: `/${idValue}` }));
        }
    }, [pageValue, dispatch])

    const { virechan } = useSelector(state => state?.MasterData);

    return (
        <>
            {/* <Loader IsModal={loader} /> */}
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/virechan">Virechan</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Virechan</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue}
                                                initialValues={{
                                                    orderIndex: virechan?.orderIndex || "",
                                                    title: virechan?.title || "",
                                                    description: virechan?.description || "",
                                                }}
                                                validationSchema={VirechanValidationSchema}
                                                onSubmit={(values, { setSubmitting, setErrors }) => {
                                                    if (!updateValue) {
                                                        dispatch(ActionCreateVirechan({
                                                            "orderIndex": values.orderIndex,
                                                            "title": values.title,
                                                            "description": values.description,
                                                        },
                                                            (Res) => {
                                                                setSubmitting(false);
                                                                if (Res?.statusCode === 200) {
                                                                    window.location.href = `/virechan?page=${pageValue}`;
                                                                } else if (Res?.statusCode === 400) {
                                                                    setErrors(Res?.data?.errors);
                                                                } else {
                                                                    // window.location.reload();
                                                                }
                                                            }))
                                                    } else {
                                                        dispatch(ActionUpdateVirechan({
                                                            Query: "/" + idValue,
                                                            obj: {
                                                                "orderIndex": values.orderIndex,
                                                                "title": values.title,
                                                                "description": values.description,
                                                            }
                                                        }, (Res) => {
                                                            setSubmitting(false);
                                                            if (Res?.statusCode === 200) {
                                                                window.location.href = `/virechan?page=${pageValue}`;
                                                            } else if (Res?.statusCode === 400) {
                                                                setErrors(Res?.data?.errors);
                                                            } else {
                                                                window.location.reload();
                                                            }
                                                        }))

                                                    }
                                                }}

                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue,
                                                    isSubmitting,
                                                }) => {
                                                    return (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-heading">
                                                                        <h4>Add Virechan Data</h4>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex">
                                                                    <InputFromUser
                                                                        placeholder={"Order Index"}
                                                                        type="number"
                                                                        name="orderIndex"
                                                                        onChange={handleChange}
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={handleBlur}
                                                                        value={values.orderIndex}
                                                                        label="Order Index"
                                                                        error={errors?.orderIndex}
                                                                        touched={touched.orderIndex}
                                                                    />
                                                                </div>
                                                                <InputFromUser
                                                                    placeholder={"Title"}
                                                                    type="text"
                                                                    name="title"
                                                                    onChange={handleChange}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values.title}
                                                                    label="Title"
                                                                    error={errors?.title}
                                                                    touched={touched.title}
                                                                />

                                                                <div id="editor" >
                                                                    <h5>Description</h5>
                                                                    <TextEditor
                                                                        name="description"
                                                                        value={values?.description}
                                                                        onChange={(event, editor) => {
                                                                            const data = editor.getData();
                                                                            setFieldValue("description", data, true)
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className="col-12 mt-4">
                                                                    <div className="doctor-submit text-end">
                                                                        <button
                                                                            type="submit"
                                                                            onClick={() => {
                                                                                handleSubmit()
                                                                            }}
                                                                            className="btn btn-primary submit-form me-2"
                                                                            disabled={isSubmitting}
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary cancel-form"
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Formik>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div >
            </>
        </>
    );
};

export default Add_Virechan;