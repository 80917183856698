/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { Formik, Form } from "formik";
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, DatePicker } from "antd";
import { InputFromUser, TextEditor } from "../../components/Input/input";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { TreatmentValidationSchema } from "../../utils/FormValidation";
import { getAdminuUerDetails } from "../../store/Actions/AuthActions";
import { CreateTreatment, GetAllTreatment } from "../../store/Actions/ServiceAction";
import { ExpendableTable } from "../../components/Table/expendableTable";
import { ActionUpdateById, ActionUploadImage } from "../../store/Actions/CommonAction";
import UploadImage from "../../components/Upload/Upload";
import { DOCS_URL } from "../../utils/globals";
import { isNullOrUndef } from "../../utils/Funcations";
const { RangePicker } = DatePicker;

const AddEditTreatment = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const { userDetails } = useSelector(state => state?.auth);
    const { treatMentList, loader } = useSelector(state => state?.Service);
    const userId = userDetails?.[0]?._id
    const idValue = searchParams.get("id");
    const idValue2 = searchParams.get("page");
    const updateValue = searchParams.get("update");
    useEffect(() => {
        if (idValue) {
            dispatch(GetAllTreatment({ Query: '/' + idValue }));
        }
    }, [idValue])
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])
    return (
        <div>
            <Header />
            <Sidebar id="menu-item1" id1="menu-items1" activeClassName="add-staff" />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/treatment-list?page=1">Treatment </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Add Treatment</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* /Page Header */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">

                                        <Formik
                                            enableReinitialize={updateValue || false}
                                            initialValues={{
                                                name: treatMentList?.[0]?.name || {
                                                    en: "",
                                                    hi: "",
                                                    mr: ""
                                                },
                                                description: treatMentList?.[0]?.description || {
                                                    en: "",
                                                    hi: "",
                                                    mr: ""
                                                },
                                                shortDescription: treatMentList?.[0]?.shortDescription || {
                                                    en: "",
                                                    hi: "",
                                                    mr: ""
                                                },
                                                perDayPrice: treatMentList?.[0]?.perDayPrice || "",
                                                rating: treatMentList?.[0]?.rating || "",
                                                successRate: treatMentList?.[0]?.successRate || "",
                                                mainTitle: treatMentList?.[0]?.mainTitle || {
                                                    en: "",
                                                    hi: "",
                                                    mr: ""
                                                },
                                                quote: treatMentList?.[0]?.quote || {
                                                    en: "",
                                                    hi: "",
                                                    mr: ""
                                                },
                                                facilities: treatMentList?.[0]?.facilities || [''],
                                                statics: treatMentList?.[0]?.statics || [{ "data": '', "name": '' }],
                                                rootCause: treatMentList?.[0]?.rootCause || "",
                                                symptoms: treatMentList?.[0]?.symptoms || "",
                                                whyMe: treatMentList?.[0]?.whyMe || { title: "", description: "", boxs: [{ "description": "", "icon": '', "orderIndex": '' }] },
                                                makeUsDifferent: treatMentList?.[0]?.makeUsDifferent || [{ "title": "", "description": "", "icon": '', "orderIndex": '' }],
                                                ourProcess: treatMentList?.[0]?.ourProcess || [{ "mainTitle": "", "title": '', "description": "", "sideImage": '', "orderIndex": '' }],
                                                benefits: treatMentList?.[0]?.benefits || [{ "title": "", "description": "", "icon": '', "orderIndex": '' }],
                                                listingViewImage: treatMentList?.[0]?.listingViewImage || "",
                                                orderIndex: treatMentList?.[0]?.orderIndex || "",
                                                dayLookTitle: treatMentList?.[0]?.dayLookTitle || '',
                                                dayLookDescription: treatMentList?.[0]?.dayLookDescription || '',
                                                dayLookMainImage: treatMentList?.[0]?.dayLookMainImage || '',
                                                dayLook: treatMentList?.[0]?.dayLook || [{ "startTime": "", "endTime": '', "description": '', "orderIndex": '', "bannerImage": "" }],
                                                chooseUs: treatMentList?.[0]?.chooseUs || [{ "aspect": "", "otherHealthResort": '', "naturopathyCenters": '', "prakrutisAyurvedicTreatment": "", "orderIndex": "" }],
                                                whoAllCanBenefit: treatMentList?.[0]?.whoAllCanBenefit || [{ "title": "", "description": "", "icon": '', "orderIndex": '' }],

                                                days: treatMentList?.[0]?.days || [""],
                                                price: treatMentList?.[0]?.price || [""],
                                                createdBy: userId,
                                                treatmentFaqs: treatMentList?.[0]?.treatmentFaqs || [{ "title": "", "description": "", "orderIndex": '' }],
                                                videoIframe: treatMentList?.[0]?.videoIframe || "",
                                                type: "hello",

                                            }}
                                            validationSchema={TreatmentValidationSchema}
                                            onSubmit={(values, { setErrors, setSubmitting }) => {
                                                const formattedValues = {
                                                    ...values,
                                                    videoIframe: values.videoIframe
                                                };
                                                if (updateValue && idValue != null) {
                                                    dispatch(ActionUpdateById({ Query: `admin/treatment/${idValue}`, obj: formattedValues }
                                                        , (Res) => {
                                                            if (Res?.statusCode === 200) {
                                                                window.location.href = `/treatment-list?page=1`
                                                            } else if (Res?.statusCode === 400) {
                                                                setErrors(Res?.data?.errors);
                                                            }
                                                        }));
                                                    setSubmitting(false);
                                                } else {
                                                    dispatch(CreateTreatment(values, (Response) => {
                                                        if (Response?.statusCode === 200) {
                                                            window.location.href = "/treatment-list?page=1"
                                                        }
                                                    }))
                                                    setSubmitting(false);
                                                }
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                setFieldValue,
                                                setFieldTouched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                setValues,
                                                setSubmitting,
                                                isSubmitting,
                                                /* and other goodies */

                                            }) => {
                                                return (
                                                    <Form>
                                                        <div className="row">
                                                            <div className="my-4" >
                                                                <Divider style={{ fontWeight: "600" }}>Treatment</Divider></div>
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Enter Your Treatment Name"}
                                                                FormControlClassName={'form-control '}
                                                                label="Treatment Name"
                                                                name="name.en"
                                                                error={errors?.name?.en}
                                                                touched={touched?.name?.en}
                                                                value={values?.name?.en}
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.name
                                                                    UpdateValue.en = v?.target?.value
                                                                    setFieldValue("name", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Marathi"}
                                                                FormControlClassName={'form-control '}
                                                                label="*Marathi"
                                                                name="name.mr"
                                                                value={values?.name?.mr}
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.name
                                                                    UpdateValue.mr = v?.target?.value
                                                                    setFieldValue("name", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.name?.mr}
                                                                touched={touched?.name?.mr}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Hindi"}
                                                                FormControlClassName={'form-control '}
                                                                value={values?.name?.hi}
                                                                label="*Hindi"
                                                                name="name.hi"
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.name
                                                                    UpdateValue.hi = v?.target?.value
                                                                    setFieldValue("name", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.name?.hi}
                                                                touched={touched?.name?.hi}
                                                            />
                                                            <div className="row mt-4">
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Your Service Descriptions"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Short Description"
                                                                    name="shortDescriptionEn"
                                                                    value={values?.shortDescription?.en}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.shortDescription
                                                                        UpdateValue.en = v?.target?.value
                                                                        setFieldValue("shortDescription", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.shortDescription?.en}
                                                                    touched={touched?.shortDescription?.en}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Marathi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Marathi"
                                                                    name="shortDescriptionMarathi"
                                                                    value={values?.shortDescription?.mr}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.shortDescription
                                                                        UpdateValue.mr = v?.target?.value
                                                                        setFieldValue("shortDescription", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.shortDescription?.mr}
                                                                    touched={touched?.shortDescription?.mr}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Hindi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Hindi"
                                                                    name="shortDescriptionHindi"
                                                                    value={values?.shortDescription?.hi}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.shortDescription
                                                                        UpdateValue.hi = v?.target?.value
                                                                        setFieldValue("shortDescription", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.shortDescription?.hi}
                                                                    touched={touched?.shortDescription?.hi}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Success Rate"}
                                                                    FormControlClassName={'form-control'}
                                                                    onChange={handleChange}
                                                                    value={values?.successRate}
                                                                    onBlur={handleBlur}
                                                                    label="Success Rate"
                                                                    name="successRate"
                                                                    error={errors?.successRate}
                                                                    touched={touched?.successRate}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Treatment Rating"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Treatment Rating"
                                                                    name="rating"
                                                                    onChange={handleChange}
                                                                    value={values?.rating}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.rating}
                                                                    touched={touched?.rating}
                                                                />
                                                                <InputFromUser
                                                                    type={"number"}
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Per Day Price"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Per Day Price"
                                                                    name="perDayPrice"
                                                                    onChange={handleChange}
                                                                    value={values?.perDayPrice}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.perDayPrice}
                                                                    touched={touched?.perDayPrice}
                                                                />
                                                                <div className="d-flex justify-content-evenly align-items-center col">
                                                                    <label className="col-form-label">
                                                                        {"Upload Treatment Image:-"}
                                                                    </label>
                                                                    <div>
                                                                        <UploadImage
                                                                            className={"img-fluid"}
                                                                            errorMessage={errors?.listingViewImage}
                                                                            touched={touched?.listingViewImage}
                                                                            fileList={!isNullOrUndef(values?.listingViewImage) && [{
                                                                                uid: 1 + values?.listingViewImage,
                                                                                name: values?.listingViewImage,
                                                                                status: 'done',
                                                                                url: DOCS_URL + values?.listingViewImage,
                                                                            }] || []}
                                                                            handleChange={(v) => {
                                                                                if (v.file && v.file.status === 'uploading') {
                                                                                    const formData = new FormData();
                                                                                    formData.append("isTreatment", true);
                                                                                    formData.append("attachment", v?.file?.originFileObj);
                                                                                    dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                        if (typeof res === 'object') {
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: 'listingViewImage',
                                                                                                    value: res?.[0],
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    }))
                                                                                } else if (v.file && v.file.status === 'removed') {
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'listingViewImage',
                                                                                            value: '',
                                                                                        },
                                                                                    });
                                                                                }
                                                                            }}
                                                                            UploadImagelimit={1} />
                                                                    </div>
                                                                </div>
                                                                <InputFromUser
                                                                    placeholder={"Paste Your YouTube Video Link"}
                                                                    type="url"
                                                                    name="videoIframe"
                                                                    onChange={(v) => {
                                                                        setFieldValue("videoIframe", [v?.target?.value], true);
                                                                    }}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values?.videoIframe?.[0] || ""}  // Display the value at the 0th index
                                                                    label="Iframe Link"
                                                                    error={errors?.videoIframe}
                                                                    touched={touched.videoIframe}
                                                                />
                                                            </div>
                                                            <div className="row mt-4">
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Your mainTitle"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Main Title"
                                                                    name="mainTitle?.en"
                                                                    error={errors?.mainTitle?.en}
                                                                    touched={touched?.mainTitle?.en}
                                                                    value={values?.mainTitle?.en}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.mainTitle
                                                                        UpdateValue.en = v?.target?.value
                                                                        setFieldValue("mainTitle", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Marathi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Marathi"
                                                                    name="mainTitle.mr"
                                                                    value={values?.mainTitle?.mr}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.mainTitle
                                                                        UpdateValue.mr = v?.target?.value
                                                                        setFieldValue("mainTitle", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.mainTitle?.mr}
                                                                    touched={touched?.mainTitle?.mr}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Hindi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Hindi"
                                                                    value={values?.mainTitle?.hi}
                                                                    name="mainTitle.hi"
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.mainTitle
                                                                        UpdateValue.hi = v?.target?.value
                                                                        setFieldValue("mainTitle", UpdateValue, true)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.mainTitle?.hi}
                                                                    touched={touched?.mainTitle?.hi}
                                                                />
                                                            </div>
                                                            <div className="row mt-4">
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Your Quote"}
                                                                    FormControlClassName={'form-control'}
                                                                    label="Quote"
                                                                    name="quote?.en"
                                                                    error={errors?.quote?.en}
                                                                    touched={touched?.quote?.en}
                                                                    value={values?.quote?.en}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.quote
                                                                        UpdateValue.en = v?.target?.value
                                                                        setFieldValue("quote", UpdateValue, true)

                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Marathi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Marathi"
                                                                    value={values?.quote?.mr}
                                                                    name="quote.mr"
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.quote
                                                                        UpdateValue.mr = v?.target?.value
                                                                        setFieldValue("quote", UpdateValue, true)

                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.quote?.mr}
                                                                    touched={touched?.quote?.mr}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Hindi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Hindi"
                                                                    name="quote.hi"
                                                                    value={values?.quote?.hi}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.quote
                                                                        UpdateValue.hi = v?.target?.value
                                                                        setFieldValue("quote", UpdateValue, true)

                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.quote?.hi}
                                                                    touched={touched?.quote?.hi}
                                                                />

                                                            </div>
                                                            <div className="row mt-4">
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Your Service Descriptions"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Description"
                                                                    value={values?.description?.en}
                                                                    name="descriptionsEn"
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.description
                                                                        UpdateValue.en = v?.target?.value
                                                                        setFieldValue("description", UpdateValue, true)

                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.description?.en}
                                                                    touched={touched?.description?.en}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Marathi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Marathi"
                                                                    name="descriptionMr"
                                                                    value={values?.description?.mr}
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.description
                                                                        UpdateValue.mr = v?.target?.value
                                                                        setFieldValue("description", UpdateValue, true)

                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.description?.mr}
                                                                    touched={touched?.description?.mr}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-4 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Hindi"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="*Hindi"
                                                                    value={values?.description?.hi}
                                                                    name="descriptionHi"
                                                                    onChange={(v) => {
                                                                        const UpdateValue = values?.description
                                                                        UpdateValue.hi = v?.target?.value
                                                                        setFieldValue("description", UpdateValue, true)

                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.description?.hi}
                                                                    touched={touched?.description?.hi}
                                                                />
                                                            </div>
                                                            <div className="row mt-4">
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Order Index"}
                                                                    FormControlClassName={'form-control'}
                                                                    onChange={handleChange}
                                                                    value={values?.orderIndex}
                                                                    onBlur={handleBlur}
                                                                    label="Order Index"
                                                                    type={"number"}
                                                                    name="orderIndex"
                                                                    error={errors?.orderIndex}
                                                                    touched={touched?.orderIndex}
                                                                />
                                                            </div>

                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Facilities</Divider></div>
                                                                {values?.facilities?.map((item, index) => {
                                                                    return (
                                                                        <tr key={"facilities" + index + 'tr'}>
                                                                            <div className="d-flex">
                                                                                <td className="col-xl-6 col-md-10" key={"facilities" + index + 'td'}>
                                                                                    {
                                                                                        <InputFromUser
                                                                                            placeholder={'Enter Facilities'}
                                                                                            error={errors?.facilities}
                                                                                            value={item}
                                                                                            onChange={(v) => {
                                                                                                const updatedServiceFor = [...(values?.facilities || [])];
                                                                                                updatedServiceFor[index] = v?.target?.value;
                                                                                                setFieldValue("facilities", updatedServiceFor, true)
                                                                                            }}
                                                                                            touched={touched?.facilities}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                                <td className="col-2 add-remove text-end align-items-center" key={"td6" + index}>
                                                                                    <Button
                                                                                        type=""
                                                                                        className="add-btn "
                                                                                        onClick={() => {
                                                                                            const newValue = [''];
                                                                                            setFieldValue("facilities", [...values.facilities, newValue])
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                    </Button>
                                                                                    <Button
                                                                                        style={{ border: 'none' }}
                                                                                        className="remove-btn"
                                                                                        onClick={() => {
                                                                                            if (index !== 0) {
                                                                                                const updatedBenefits = [...values.facilities];
                                                                                                updatedBenefits.splice(index, 1);
                                                                                                setFieldValue("facilities", updatedBenefits);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <i className="fe fe-trash-2">
                                                                                            <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                        </i>
                                                                                    </Button>
                                                                                </td>
                                                                            </div>

                                                                        </tr>
                                                                    )

                                                                })}
                                                            </div>

                                                            <ExpendableTable
                                                                Title={"Treatment Details Count"}
                                                                emptyDataForCreatingField={{ "data": '', "name": '' }}
                                                                Data={values?.statics}
                                                                Columns={[
                                                                    { name: 'name', type: 'text', label: 'Counts' },
                                                                    { name: 'data', type: 'text', label: 'Description' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("statics", v, true)}
                                                                onBlur={(v) => setFieldTouched("statics", v, true)}
                                                                errors={errors?.statics}
                                                                touched={touched?.statics}
                                                            />
                                                            {/* <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>About Treatment</Divider></div>
                                                                <div id="editor" >
                                                                    <TextEditor
                                                                        value={values?.rootCause}
                                                                        onChange={(event, editor) => {
                                                                            const data = editor.getData();
                                                                            setFieldValue("rootCause", data, true)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div> */}
                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Root Causes</Divider></div>
                                                                <div id="editor" >
                                                                    <TextEditor
                                                                        value={values?.rootCause}
                                                                        error={errors?.rootCause}
                                                                        errorMessage={errors?.rootCause}
                                                                        onChange={(event, editor) => {
                                                                            const data = editor.getData();
                                                                            setFieldValue("rootCause", data, true)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Symptoms</Divider></div>
                                                                <div id="editor" >
                                                                    <TextEditor
                                                                        value={values?.symptoms}
                                                                        errorMessage={errors?.symptoms}

                                                                        onChange={(event, editor) => {
                                                                            const data = editor.getData();
                                                                            setFieldValue("symptoms", data, true)
                                                                        }}
                                                                    />
                                                                </div>

                                                            </div>

                                                            <ExpendableTable
                                                                Title={"But Why Me?"}
                                                                emptyDataForCreatingField={{ "description": "", "icon": '', "orderIndex": '' }}
                                                                Data={values?.whyMe?.boxs}
                                                                Columns={[
                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'icon', type: 'upload', label: 'Icon' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}

                                                                onChange={(v) => setFieldValue("whyMe", { ...values?.whyMe, boxs: v }, true)}
                                                                onBlur={(v) => setFieldTouched("whyMe", { ...touched?.whyMe, boxs: v }, true)}
                                                                errors={errors?.whyMe?.boxs}
                                                                touched={touched?.whyMe?.boxs}
                                                                UploadImageFlag={'isTreatment'}
                                                                HeaderComponent={<div className="row">
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-5 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Title"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="Title"
                                                                        name="whyMe"
                                                                        value={values?.whyMe?.title}
                                                                        onChange={(v) => {
                                                                            const updatedWhyMe = { ...values?.whyMe, title: v?.target?.value };
                                                                            setFieldValue("whyMe", updatedWhyMe, true);
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.whyMe?.title}
                                                                        touched={touched?.whyMe?.title}
                                                                    />
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-5 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Description"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="Description"
                                                                        name="whyMe"
                                                                        value={values?.whyMe?.description}
                                                                        onChange={(v) => {
                                                                            const updatedWhyMe = { ...values?.whyMe, description: v?.target?.value };
                                                                            setFieldValue("whyMe", updatedWhyMe, true);
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.whyMe?.description}
                                                                        touched={touched?.whyMe?.description}
                                                                    />
                                                                </div>}
                                                            />

                                                            <ExpendableTable
                                                                Title={"What Makes Us Different"}
                                                                emptyDataForCreatingField={{ "title": "", "description": "", "icon": '', "orderIndex": '' }}
                                                                Data={values?.makeUsDifferent}
                                                                Columns={[
                                                                    { name: 'title', type: 'text', label: 'Title' },
                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'icon', type: 'upload', label: 'Icon' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}
                                                                UploadImageFlag={'isTreatment'}
                                                                onChange={(v) => setFieldValue("makeUsDifferent", v, true)}
                                                                onBlur={(v) => setFieldTouched("makeUsDifferent", v, true)}
                                                                errors={errors?.makeUsDifferent}
                                                                touched={touched?.makeUsDifferent}
                                                            />

                                                            <ExpendableTable
                                                                Title={"Our Process"}
                                                                emptyDataForCreatingField={{ "mainTitle": "", "title": '', "description": "", "sideImage": '', "orderIndex": '' }}
                                                                Data={values?.ourProcess}
                                                                Columns={[
                                                                    { name: 'mainTitle', type: 'text', label: 'Main Title' },
                                                                    { name: 'title', type: 'text', label: 'Title' },
                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'sideImage', type: 'upload', label: 'Icon' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("ourProcess", v, true)}
                                                                onBlur={(v) => setFieldTouched("ourProcess", v, true)}
                                                                errors={errors?.ourProcess}
                                                                UploadImageFlag={'isTreatment'}
                                                                touched={touched?.ourProcess}
                                                            />
                                                            <ExpendableTable
                                                                Title={"Benefits"}
                                                                emptyDataForCreatingField={{ "title": "", "description": "", "orderIndex": '', "icon": '' }}
                                                                Data={values?.benefits}
                                                                Columns={[
                                                                    { name: 'title', type: 'text', label: 'Title' },
                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'icon', type: 'upload', label: 'Icon' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("benefits", v, true)}
                                                                onBlur={(v) => setFieldTouched("benefits", v, true)}
                                                                errors={errors?.benefits}
                                                                touched={touched?.benefits}
                                                            />
                                                            <ExpendableTable
                                                                Title={"How Your Day Look Like"}
                                                                emptyDataForCreatingField={[{ "startTime": "", "endTime": '', "description": '', "orderIndex": '', "bannerImage": '' }]}
                                                                Data={values?.dayLook}
                                                                Columns={[

                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'startTime', type: 'text', label: 'StartTime' },
                                                                    { name: 'endTime', type: 'text', label: 'EndTime' },
                                                                    { name: 'bannerImage', type: 'upload', label: 'Image' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("dayLook", v, true)}
                                                                onBlur={(v) => setFieldTouched("dayLook", v, true)}
                                                                errors={errors?.dayLook}
                                                                touched={touched?.dayLook}
                                                                HeaderComponent={<div className="row">
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-5 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Day Look Like Title"}
                                                                        FormControlClassName={'form-control '}
                                                                        value={values?.dayLookTitle}
                                                                        label="Title"
                                                                        name="dayLookTitle"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.dayLookTitle}
                                                                        touched={touched?.dayLookTitle}
                                                                    />
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-5 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        value={values?.dayLookDescription}
                                                                        placeholder={"Description"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="Description"
                                                                        name="dayLookDescription"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.dayLookDescription}
                                                                        touched={touched?.dayLookDescription}
                                                                    />
                                                                    <div className="d-flex justify-content-between align-items-center col-6 ">
                                                                        <label className="col-form-label">
                                                                            {"Upload Photo :-"}
                                                                        </label>
                                                                        <div>
                                                                            <UploadImage
                                                                                className={"img-fluid"}
                                                                                fileList={!isNullOrUndef(values?.dayLookMainImage) && [{
                                                                                    uid: 1 + values?.dayLookMainImage,
                                                                                    name: values?.dayLookMainImage,
                                                                                    status: 'done',
                                                                                    url: DOCS_URL + values?.dayLookMainImage,
                                                                                }] || []}
                                                                                handleChange={(v) => {
                                                                                    if (v.file && v.file.status === 'uploading') {
                                                                                        const formData = new FormData();
                                                                                        formData.append("isTreatment", true);
                                                                                        formData.append("attachment", v?.file?.originFileObj);
                                                                                        dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                            if (typeof res === 'object') {
                                                                                                handleChange({
                                                                                                    target: {
                                                                                                        name: 'dayLookMainImage',
                                                                                                        value: res?.[0],
                                                                                                    },
                                                                                                });
                                                                                            }
                                                                                        }))
                                                                                    } else if (v.file && v.file.status === 'removed') {
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: 'dayLookMainImage',
                                                                                                value: '',
                                                                                            },
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                errorMessage={errors?.dayLookMainImage}
                                                                                UploadImagelimit={1} />                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            />

                                                            <ExpendableTable
                                                                Title={"Why Choose Us"}
                                                                emptyDataForCreatingField={{ "aspect": "", "otherHealthResort": '', "naturopathyCenters": '', "prakrutisAyurvedicTreatment": "", "orderIndex": "" }}
                                                                Data={values?.chooseUs}
                                                                Columns={[
                                                                    { name: 'aspect', type: 'text', label: 'Aspect' },
                                                                    { name: 'otherHealthResort', type: 'text', label: 'OtherHealthResort' },
                                                                    { name: 'naturopathyCenters', type: 'text', label: 'Naturopathy Centers' },
                                                                    { name: 'prakrutisAyurvedicTreatment', type: 'text', label: 'Prakrutis Ayurvedic Treatment' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("chooseUs", v, true)}
                                                                onBlur={(v) => setFieldTouched("chooseUs", v, true)}
                                                                errors={errors?.chooseUs}
                                                                touched={touched?.chooseUs}
                                                            />

                                                            <ExpendableTable
                                                                Title={"Who All Can Benefit"}
                                                                emptyDataForCreatingField={{ "title": "", "icon": '', "description": '', "orderIndex": "" }}
                                                                Data={values?.whoAllCanBenefit}
                                                                Columns={[
                                                                    { name: 'title', type: 'text', label: 'Title' },
                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'icon', type: 'upload', label: 'Icon' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("whoAllCanBenefit", v, true)}
                                                                onBlur={(v) => setFieldTouched("whoAllCanBenefit", v, true)}
                                                                errors={errors?.whoAllCanBenefit}
                                                                touched={touched?.whoAllCanBenefit}
                                                            />


                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Days Details</Divider></div>
                                                                {values?.days?.map((item, index) => {
                                                                    return (
                                                                        <tr key={"days" + index + 'tr'}>
                                                                            <div className="d-flex">
                                                                                <td className="col-xl-6 col-md-10" key={"days" + index + 'td'}>
                                                                                    {
                                                                                        <InputFromUser
                                                                                            placeholder={'Enter days'}
                                                                                            value={item}
                                                                                            //error={errors?.days}
                                                                                            onChange={(v) => {
                                                                                                const updatedServiceFor = [...(values?.days || [])];
                                                                                                updatedServiceFor[index] = v?.target?.value;
                                                                                                setFieldValue("days", updatedServiceFor, true)
                                                                                            }}
                                                                                            touched={touched?.days}
                                                                                            error={errors?.days}
                                                                                        //touched={touched?.days}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                                <td className="col-2 add-remove text-end align-items-center" key={"td6" + index}>
                                                                                    <Button
                                                                                        type=""
                                                                                        className="add-btn"
                                                                                        onClick={() => {
                                                                                            const newValue = [''];
                                                                                            setFieldValue("days", [...values.days, newValue])
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                    </Button>
                                                                                    <Button
                                                                                        style={{ border: 'none' }}
                                                                                        className="remove-btn"
                                                                                        onClick={() => {
                                                                                            if (index !== 0) {
                                                                                                const updatedBenefits = [...values.days];
                                                                                                updatedBenefits.splice(index, 1);
                                                                                                setFieldValue("days", updatedBenefits);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <i className="fe fe-trash-2">
                                                                                            <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                        </i>
                                                                                    </Button>
                                                                                </td>
                                                                            </div>

                                                                        </tr>
                                                                    )

                                                                })}
                                                            </div>
                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Pricing Details</Divider></div>
                                                                {values?.price?.map((item, index) => {
                                                                    return (
                                                                        <tr key={"price" + index + 'tr'}>
                                                                            <div className="d-flex">
                                                                                <td className="col-xl-6 col-md-10" key={"price" + index + 'td'}>
                                                                                    {
                                                                                        <InputFromUser
                                                                                            placeholder={'Enter price'}
                                                                                            value={item}
                                                                                            onChange={(v) => {
                                                                                                const updatedServiceFor = [...(values?.price || [])];
                                                                                                updatedServiceFor[index] = v?.target?.value;
                                                                                                setFieldValue("price", updatedServiceFor, true)
                                                                                            }}
                                                                                            touched={touched?.price}
                                                                                            error={errors?.price}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                                <td className="col-2 add-remove text-end align-items-center" key={"td6price" + index}>
                                                                                    <Button
                                                                                        type=""
                                                                                        className="add-btn"
                                                                                        onClick={() => {
                                                                                            const newValue = [''];
                                                                                            setFieldValue("price", [...values.price, newValue])
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                    </Button>
                                                                                    <Button
                                                                                        style={{ border: 'none' }}
                                                                                        className="remove-btn"
                                                                                        onClick={() => {
                                                                                            if (index !== 0) {
                                                                                                const updatedBenefits = [...values.price];
                                                                                                updatedBenefits.splice(index, 1);
                                                                                                setFieldValue("price", updatedBenefits);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <i className="fe fe-trash-2">
                                                                                            <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                        </i>
                                                                                    </Button>
                                                                                </td>
                                                                            </div>

                                                                        </tr>
                                                                    )

                                                                })}
                                                            </div>

                                                            <ExpendableTable
                                                                Title={"Treatment Faqs"}
                                                                emptyDataForCreatingField={{ "title": "", "description": "", "orderIndex": '' }}
                                                                Data={values?.treatmentFaqs}
                                                                Columns={[
                                                                    { name: 'title', type: 'text', label: 'Title' },
                                                                    { name: 'description', type: 'text', label: 'Description' },
                                                                    { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                    { name: 'Action', type: 'action', label: 'Action' },
                                                                ]}
                                                                onChange={(v) => setFieldValue("treatmentFaqs", v, true)}
                                                                onBlur={(v) => setFieldTouched("treatmentFaqs", v, true)}
                                                                errors={errors?.treatmentFaqs}
                                                                touched={touched?.treatmentFaqs}
                                                            />

                                                            <div className="col-12">
                                                                <div className="text-end">
                                                                    <Button
                                                                        onClick={() => {
                                                                            handleSubmit();
                                                                        }}
                                                                        type="primary"
                                                                        // disabled={isSubmitting}
                                                                        className="pb-4"
                                                                    >Submit</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div >
    );
};

export default AddEditTreatment;
