import * as Yup from 'yup';

export const LoginValidationSchema = Yup.object().shape({
    email: Yup.string()
        .required('Required').email(),
    password: Yup.string().required('Required').min(8, 'Password must be at least 8 characters')
});
export const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .required('Required').email(),
});
export const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Required').min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match").required('Confirm Password is required'),
});
export const AppConfigValidationSchema = Yup.object().shape({
    key: Yup.string().required('Required'),
    selectedLang: Yup.string(),
    value: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
});
export const CreatePermissonValidationSchema = Yup.object().shape({
    moduleName: Yup.string().required('Required').min(3, 'Module Name must be at least 3 characters'),
    name: Yup.string().required('Required'),
});

export const NextDateValidationSchema = Yup.object().shape({
    dateOfMedicalStore: Yup.string().required('Required'),
});

export const PatientValidationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    email: Yup.string().required('Required').email(),
    phoneNo: Yup.string()
        .required("Mobile number is required")
        .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits'),
    // oldCasePaperNo: Yup.string().required('Required'),
    gender: Yup.string().required('Required'),
    birthDate: Yup.string().required('Required'),
    nextConsultationDate: Yup.string().required('Required'),
});
export const collectCashSchema = Yup.object().shape({
    paymentMode: Yup.string().required('Payment mode is required'),
    updatedPaidAmount: Yup.number().required('Amount is required').positive('Amount must be positive'),
    transactionId: Yup.string().when('paymentMode', {
        is: (val) => val === 'Card' || val === 'UPI',
        then: () => Yup.string().required('Transaction ID is required'),
        otherwise: () => Yup.string()
    }),
});

export const CreateScheduleValidationSchema = Yup.object().shape({
    schedule: Yup.array().of(
        Yup.object().shape({
            days: Yup.string().required('Required'),
            spentTime: Yup.string().required('Required'),
            EndTime: Yup.string().required('Required'),
            type: Yup.string().required('Required'),
        })
    ),
});
export const PatientDetailsValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phoneNo: Yup.string()
        .required("Mobile number is required")
        .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits'),
    email: Yup.string()
        .required('Required').email(),
});
export const appointmentDetailsValidationSchema = Yup.object().shape({
    consultationType: Yup.string().required("Consultation Type is required"),
    casePaperNo: Yup.string().required("Case Paper No is required"),
    selectYourDoctor: Yup.string().required("required"),
    consultingFor: Yup.string().required("required"),
    appointmentDate: Yup.string().required("Appointment Date is required"),
    amount: Yup.string().required("amount is required"),

});
export const yogaFormValidationSchema = Yup.object().shape({
    currentComplaints: Yup.string().required("Notes is required"),
    exercise: Yup.array().of(
        Yup.string().required("Exercise item is required")
    ),
    date: Yup.date().required("Date is required"),
});
export const AddStaffValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    dateOfBirth: Yup.string().required("Date Of Birth is required"),
    phoneNo: Yup.string()
        .required("Mobile number is required")
        .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits'),
    password: Yup.string()
        .required("Password is required")
        .min(8, 'Password must be at least 8 characters')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
            'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
        ),
    confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match").required('Confirm Password is required'),
    gender: Yup.string().required("Gender is required"),
    status: Yup.string().required("Status is required"),
    orderIndex: Yup.number().required("Order Index is required"),
    role: Yup.string().nullable().required("Role is required"),
    email: Yup.string()
        .required('Required').email(),
});

export const AddRolesValidationSchema = Yup.object().shape({
    roleName: Yup.string().required("Role Name is required"),
    permission: Yup.array()
        .required("Permission is required")
        .min(1, "Permission list must not be empty"),
})
export const AddGalleryValidationSchema = Yup.object().shape({
    keys: Yup.string().required("Name is required"),
    description: Yup.string().required("description is required"),
    docs: Yup.array()
        .required("required")
})

export const ServiceValidationSchema = Yup.object().shape({
    name: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    description: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    shortDescription: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    price: Yup.number().required("Price is required"),
    orderIndex: Yup.number().required("Index is required"),
    listingViewImage: Yup.array().of(
        Yup.string().required("Required")
    ),
    benefitsOfService: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            //icon: Yup.string().required('Required'),
            orderIndex: Yup.number().required('Required'),
            //type: Yup.string().required('Required'),
        })
    ),
    howServiceWorks: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            //icon: Yup.string().required('Required'),
            orderIndex: Yup.number().required('Required'),
            //type: Yup.string().required('Required'),
        })
    ),
    WhoIsThisFor: Yup.array().of(
        Yup.string().required("WhoIsThisFor are Required")
    ),
    serviceFor: Yup.array().of(
        Yup.string().required("Required")
    ),
    preparationAndAftercareImage: Yup.array().of(
        Yup.string().required("Required")),
    preparationAndAftercareDescription: Yup.string().required("required"),
    preparationAndAftercare: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            //icon: Yup.string().required('Required'),
            orderIndex: Yup.number().required('Required'),
            //type: Yup.string().required('Required'),
        })
    ),
    serviceFaqs: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            orderIndex: Yup.number().required('Required'),
        })
    ),
})
export const TreatmentValidationSchema = Yup.object().shape({
    name: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    description: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    shortDescription: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    perDayPrice: Yup.number().required("perDayPrice is required"),
    rating: Yup.string().required("rating is required"),
    listingViewImage: Yup.string().required("listingViewImage is required"),
    videoIframe: Yup.array().of(
        Yup.string().required("VideoIFrames is Required")
    ),
    successRate: Yup.string().required("successRate is required"),
    mainTitle: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    quote: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    facilities: Yup.array().of(
        Yup.string().required("facilities is Required")
    ),
    orderIndex: Yup.number().required("Index is required"),

    whyMe: Yup.object().shape({
        title: Yup.string().required('title is required'),
        description: Yup.string().required('description is required'),
        boxs: Yup.array().of(
            Yup.object().shape({
                description: Yup.string().required('description is Required'),
                orderIndex: Yup.number().required('orderIndex is Required'),
            })
        )
    }),
    makeUsDifferent: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('title is Required'),
            description: Yup.string().required('description is Required'),
            orderIndex: Yup.number().required('orderIndex is Required'),
        })
    ),
    ourProcess: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('title is Required'),
            mainTitle: Yup.string().required('Tab title is Required'),
            description: Yup.string().required('description is Required'),
            orderIndex: Yup.number().required('orderIndex is Required'),
        })
    ),
    rootCause: Yup.string().required('rootCause is Required'),
    symptoms: Yup.string().required('symptoms is Required'),
    benefits: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('title is Required'),
            description: Yup.string().required('description is Required'),
            orderIndex: Yup.number().required('orderIndex is Required'),
        })
    ),
    dayLookMainImage: Yup.string().required("dayLook MainImage is required"),
    dayLookTitle: Yup.string().required("dayLook Title is required"),
    dayLookDescription: Yup.string().required("dayLook Description is required"),
    dayLook: Yup.array().of(
        Yup.object().shape({
            startTime: Yup.string().required('startTime is Required'),
            endTime: Yup.string().required('endTime is Required'),
            description: Yup.string().required('description is Required'),
            orderIndex: Yup.number().required('orderIndex is Required'),
            bannerImage: Yup.string().required('bannerImage is Required'),
        })
    ),
    chooseUs: Yup.array().of(
        Yup.object().shape({
            aspect: Yup.string().required('aspect is Required'),
            otherHealthResort: Yup.string().required('otherHealthResort is Required'),
            naturopathyCenters: Yup.string().required('naturopathyCenters is Required'),
            prakrutisAyurvedicTreatment: Yup.string().required('prakrutisAyurvedicTreatment is Required'),
            orderIndex: Yup.number().required('orderIndex is Required'),
        })
    ),
    whoAllCanBenefit: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('title is Required'),
            icon: Yup.string().required('icon is Required'),
            description: Yup.string().required('description is Required'),
            orderIndex: Yup.number().required('orderIndex is Required'),
        })
    ),
    statics: Yup.array().of(
        Yup.object().shape({
            data: Yup.string().required('data is Required'),
            name: Yup.string().required('name is Required'),
        })
    ),
    price: Yup.array().of(
        Yup.string().required("price is Required")
    ),
    days: Yup.array().of(
        Yup.string().required("days is Required")
    ),
    treatmentFaqs: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required("title is Required"),
            description: Yup.string().required("description is Required"),
            orderIndex: Yup.number().required("orderIndex is Required"),
        })
    )
})

export const CelebrityValidationSchema = Yup.object().shape({

    celebrityName: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    celebrityShortDescription: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    celebrityReview: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    knownFor: Yup.string().required('Known for field is required'),
    orderIndex: Yup.number().required('Order index is required'),

})
export const FaqValidationSchema = Yup.object().shape({
    question: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    answer: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    })
})
export const SolutionValidationSchema = Yup.object().shape({
    name: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    description: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    shortDescription: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    rating: Yup.string().required("rating is required"),
    successRate: Yup.string().required("successRate is required"),
    orderIndex: Yup.number().required("Index is required"),
    videoIframe: Yup.string().required("Video Link is Required"),

    listingViewImage: Yup.string().required("Listing View Image is required"),
    facilities: Yup.array().of(
        Yup.string().required("facilities are Required")
    ),
    whatWeDo: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('title is Required'),
            description: Yup.string().required('description is Required'),
            orderIndex: Yup.number().required('orderIndex is Required'),
        })
    ),
    facilitiesAfterDescription: Yup.array().of(
        Yup.string().required('facilities After Description are Required'),
    ),
    dayLookTitle: Yup.string().required("dayLook Title is required"),
    perDayPrice: Yup.number().required("perDay Price is required"),
    dayLookMainImage: Yup.string().required("dayLook MainImage is required"),
    dayLookDescription: Yup.string().required("dayLook Description is required"),
    dayLook: Yup.array().of(
        Yup.object().shape({
            startTime: Yup.string().required('startTime is Required'),
            endTime: Yup.string().required('endTime is Required'),
            description: Yup.string().required('description is Required'),
            orderIndex: Yup.number().required('orderIndex is Required'),
            bannerImage: Yup.string().required('bannerImage is Required'),
        })
    ),
    statics: Yup.array().of(
        Yup.object().shape({
            data: Yup.string().required('data is Required'),
            name: Yup.string().required('name is Required'),
        })
    ),
    mainTitle: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    ourProcess: Yup.object().shape({
        title: Yup.string().required('Main title is required'),
        description: Yup.string().required('description is required'),
        processes: Yup.array().of(
            Yup.object().shape({
                sideImage: Yup.string().required('sideImage is required'),
                mainTitle: Yup.string().required('mainTitle is required'),
                title: Yup.string().required('title is required'),
                description: Yup.string().required('description is required'),
                orderIndex: Yup.number().required('Order index is required'),
            })
        ),
        facilities: Yup.array().of(
            Yup.string().required('Facilities is required'),
        ),
    }),
    benefits: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('title is Required'),
            description: Yup.string().required('description is Required'),
            orderIndex: Yup.number().required('orderIndex is Required'),
        })
    ),
    whyChooseUs: Yup.object().shape({
        mainTitle: Yup.string().required('Main title is required'),
        chooseUs: Yup.array().of(
            Yup.object().shape({
                aspect: Yup.string().required('Aspect is required'),
                otherHealthResort: Yup.string().required('Other health resort is required'),
                naturopathy: Yup.string().required('Naturopathy centers is required'),
                allopathy: Yup.string().required('Allopathy centers is required'),
                prakrutisAyurvedicTreatment: Yup.string().required('Prakrutis Ayurvedic treatment is required'),
                orderIndex: Yup.number().required('Order index is required'),
            })
        ),
        facilities: Yup.array().of(
            Yup.string().required('Facilities is required'),
        ),
    }),
    price: Yup.array().of(
        Yup.string().required("price is Required")
    ),
    days: Yup.array().of(
        Yup.string().required("days is Required")
    ),
    solutionFaqs: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Title is Required'),
            description: Yup.string().required('Description is Required'),
            orderIndex: Yup.number().required('OrderIndex is Required'),
        })
    ),
})
export const ProgrammeValidationSchema = Yup.object().shape({
    name: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    description: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    shortDescription: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    rating: Yup.string().required("rating is required"),
    successRate: Yup.string().required("successRate is required"),
    orderIndex: Yup.number().required("Index is required"),
    videoIframe: Yup.string().required("Video Link is Required"),
    perDayPrice: Yup.number().required("perDay Price is required"),

    listingViewImage: Yup.string().required("Listing View Image is required"),
    facilities: Yup.array().of(
        Yup.string().required("facilities are Required")
    ),
    WhoIsThisFor: Yup.array().of(
        Yup.string().required("WhoIsThisFor are Required")
    ),
    whatWeDo: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('title is Required'),
            description: Yup.string().required('description is Required'),
            orderIndex: Yup.number().required('orderIndex is Required'),
        })
    ),
    yoga: Yup.object().shape({
        title: Yup.string().required('title is Required'),

        value: Yup.array().of(
            Yup.string().required('value is Required'),
        ),
    }),
    yogaImage: Yup.string().required('yogaImage is Required'),
    benefits: Yup.object().shape({
        title: Yup.string().required('title is Required'),
        value: Yup.array().of(
            Yup.string().required('value is Required'),
        ),
    }),
    benefitImage: Yup.string().required('Benefit Image is Required'),
    dayLookTitle: Yup.string().required("dayLook Title is required"),
    dayLookMainImage: Yup.string().required("dayLook MainImage is required"),
    dayLookDescription: Yup.string().required("dayLook Description is required"),
    dayLook: Yup.array().of(
        Yup.object().shape({
            startTime: Yup.string().required('startTime is Required'),
            endTime: Yup.string().required('endTime is Required'),
            description: Yup.string().required('description is Required'),
            orderIndex: Yup.number().required('orderIndex is Required'),
            bannerImage: Yup.string().required('bannerImage is Required'),
        })
    ),
    statics: Yup.array().of(
        Yup.object().shape({
            data: Yup.string().required('data is Required'),
            name: Yup.string().required('name is Required'),
        })
    ),
    mainTitle: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    afterCareImage: Yup.string().required('After care image is required'),
    afterCareTitle: Yup.string().required("required"),
    afterCareoptions: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            //icon: Yup.string().required('Required'),
            orderIndex: Yup.number().required('Required'),
            //type: Yup.string().required('Required'),
        })
    ),
    ourProcess: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('title is Required'),
            mainTitle: Yup.string().required('Tab title is Required'),
            description: Yup.string().required('description is Required'),
            orderIndex: Yup.number().required('orderIndex is Required'),
        })
    ),


    chooseUs: Yup.array().of(
        Yup.object().shape({
            aspect: Yup.string().required('Aspect is required'),
            otherHealthResort: Yup.string().required('Other health resort is required'),
            naturopathyCenters: Yup.string().required('Naturopathy centers is required'),
            prakrutisAyurvedicTreatment: Yup.string().required('Prakrutis Ayurvedic treatment is required'),
            orderIndex: Yup.number().required('Order index is required'),
        })
    ),


    price: Yup.array().of(
        Yup.string().required("price is Required")
    ),
    days: Yup.array().of(
        Yup.string().required("days is Required")
    ),
    programmeFaqs: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Title is Required'),
            description: Yup.string().required('Description is Required'),
            orderIndex: Yup.number().required('OrderIndex is Required'),
        })
    ),
})
export const CheckOutRoomValidationSchema = Yup.object().shape({
    amount: Yup.number().required('Amount is required'),
    paymentMode: Yup.string().required('PaymentMode is required'),

})
export const AddDetoxRetreatValidationSchema = Yup.object().shape({
    startDate: Yup.string().required('Start Date is required'),
    endDate: Yup.string().required('End Date is required'),
    bannerImage: Yup.string().required('Banner Image is required'),
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    price: Yup.string().required('Price is required'),
    videoLinks: Yup.string().required('Video Links is required'),
    aboutHost: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Name is required'),
            description: Yup.string().required('Description is required'),
            aboutHostImage: Yup.string().required('Image is required'),
        }),),

    features: Yup.array().of(
        Yup.string().required('features is Required'),
    ),
    introduction: Yup.array().of(
        Yup.object().shape({
            decription: Yup.string().required('Description is required'),
            videoLink: Yup.string().required('VideoLink is required'),
            image: Yup.string().required('Image is required'),
        })

    ),
    wordsOfGratitude: Yup.array().of(
        Yup.object().shape({
            decription: Yup.string().required('Description is required'),
            videoLink: Yup.string().required('VideoLink is required'),
            image: Yup.string().required('Image is required'),
        })

    ),
    whoIsThisFor: Yup.array().of(
        Yup.object().shape({
            icon: Yup.string().required('Icon is required'),
            title: Yup.string().required('Title is required'),
            orderIndex: Yup.number().required('Order index is required'),
        })
    ),
    whoIsThisForImage: Yup.string().required('Image is required'),
    benefits: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Title is required'),
            description: Yup.string().required('Description is required'),
            image: Yup.string().required('Image is required'),
            orderIndex: Yup.number().required('Order Index is required'),
        })
    ),
    dayWisePlan: Yup.array().of(
        Yup.object().shape({
            dayNo: Yup.string().required('DayNo is required'),
            time: Yup.string().required('Time is required'),
            task: Yup.string().required('Task is required'),
        })
    ),
    whatIncluded: Yup.array().of(
        Yup.object().shape({
            icon: Yup.string().required('Icon is required'),
            title: Yup.string().required('Title is required'),
            orderIndex: Yup.number().required('Order Index is required'),
        })
    ),
    rulesRegulations: Yup.array().of(
        Yup.object().shape({
            icon: Yup.string().required('Icon is required'),
            title: Yup.string().required('Title is required'),
            orderIndex: Yup.number().required('Order Index is required'),
            description: Yup.string().required('Description is required'),
        })
    ),
    detoxRetreatFaqs: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Title is required'),
            description: Yup.string().required('Description is required'),
            orderIndex: Yup.number().required('Order Index is required'),
        })
    )
})
export const AddPrakrutiStandardValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    months: Yup.array().required('Required')
        .test('has-values', 'Please Select at least one value', (value) => {
            return value && value.length > 0;
        }),
})
export const AddTreatmentProtocolValidationSchema = Yup.object().shape({
    treatmentName: Yup.string().required('Treatment Name is required'),
    treatmentId: Yup.string().required('Treatment Id is required'),
    services: Yup.array()
        .of(
            Yup.object().shape({

                serviceName: Yup.string().required('Service Name is required'),

                startTime: Yup.string().required('Start Time is required'),
                endTime: Yup.string().required('End Time is required'),
                totalMinutes: Yup.string().required('Total minutes is required'),
                amladharmi: Yup.array().required('Required'),

                samadharmi: Yup.array().required('Required'),

                kshardharmi: Yup.array().required('Required'),

            }))
});

export const AddEducationValidationSchema = Yup.object().shape({
    education: Yup.string().required("Education Degree is required"),
    expierence: Yup.number().required("Year is required"),
    position: Yup.string().required("Position at Prakruti is required"),
    // career: Yup.string().required("required"),
    expertise: Yup.array()
        .required("Expertise is required")
        .min(1, "Expertise list must not be empty"),
});

export const EditProfileValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phoneNo: Yup.string()
        .required("Mobile number is required")
        .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits'),
    gender: Yup.string().required("Gender is required"),
    email: Yup.string().required('Required').email(),
    dateOfBirth: Yup.string().required('Required'),
    shortDescription: Yup.string().required('Required'),
    weeklyOfDay: Yup.string().required("Weekly Of Day is required"),
    // address: Yup.string().required("Address is required"),

});

export const CreatePriceTableValidationSchema = Yup.object().shape({
    schedule: Yup.array().of(
        Yup.object().shape({
            consultationType: Yup.string().required('Required'),
            totalMinutes: Yup.string().required('Required'),
            price: Yup.string().required('Required'),
        })
    ),
});
export const AddMemberShipValidationSchema = Yup.object().shape({
    memberShipType: Yup.string().required('MemberShipType is Required'),
    // totalMemberShipDays: Yup.number().required('TotalMemberShipDays is Required'),
    startDate: Yup.date()
        .required("Start date is required"),
    endDate: Yup.date()
        .required("End date is required"),
    programme: Yup.string().required("Programme is required"),
    type: Yup.string().required("Type is required"),
    roomType: Yup.string().required("Room type is required"),

})
export const AddLeaveValidationSchema = Yup.object().shape({
    leaveType: Yup.string().required("Leave Type is required"),
    fromDate: Yup.date()
        .required("From date is required"),
    toDate: Yup.date()
        .required("To date is required"),
    adminName: Yup.string().required('Admin Type is Required'),
    endTime: Yup.string().required('End Time is Required'),
    startTime: Yup.string().required('Start Time is  Required'),
    isHalfDay: Yup.string().required('Required'),
    reason: Yup.string().required("Reason is required"),
    noOfDays: Yup.number()
        .required('No Of Days is required')
        .min(0, 'No Of Days should be at least 0')
        .max(21, 'Maximum allowed days exceeded (21 days)'),
    status: Yup.string().required("Required"),
});

export const AddHolidayValidationSchema = Yup.object().shape({
    festivalName: Yup.string().required("Festival name is required"),
    description: Yup.string().required("Description is required"),
    day: Yup.string().required("Day is required"),
    date: Yup.string().required('Date is Required'),
});

export const PersonalDetailsValidationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    birthDate: Yup.string().required("Required"),
    age: Yup.string().required("Requiredd"),
    gender: Yup.string().required('Required'),
    weight: Yup.string().required("Required"),
    height: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    education: Yup.string().required("Required"),
    occupation: Yup.string().required("Required"),
    sufferedFromDiseases: Yup.string().required("Required"),
    diseasesRunInFamily: Yup.string().required("Required"),
    bowlMoment: Yup.string().required("Required"),
    howManyTime: Yup.string().required("Required"),
    natureOfStool: Yup.string().required("Requiredd"),
    bleedingThroughAnus: Yup.string().required('Required'),
    stinkOrFloat: Yup.string().required("Required"),
    excessiveGases: Yup.string().required("Required"),
    dysentry: Yup.string().required("Required"),
    stoolStink: Yup.string().required("Required"),
    readingHabit: Yup.string().required("Required"),
    urineTime: Yup.string().required("Required"),
    bleedingInUrine: Yup.string().required("Required"),
    urineColour: Yup.string().required("Requiredd"),
    sensation: Yup.string().required('Required'),
    getUpAtNight: Yup.string().required("Required"),
    sweatAllRoundYear: Yup.string().required("Required"),
    sweatMoreOrLess: Yup.string().required("Required"),
    sweatSmell: Yup.string().required("Requiredd"),
    palmsAndSoles: Yup.string().required('Required'),
    atWhatAge: Yup.string().required("Required"),
    regularPeriod: Yup.string().required("Required"),
    periodCycle: Yup.string().required("Requiredd"),
    periodLastingdays: Yup.string().required('Required'),
    colorOfPeriod: Yup.string().required("Required"),
    problems: Yup.string().required("Required"),
    clots: Yup.string().required("Required"),
    brstIssues: Yup.string().required("Required"),
    pregnantTime: Yup.string().required("Required"),
    deliveryType: Yup.string().required("Required"),
    abortion: Yup.string().required("Requiredd"),
    abortionTime: Yup.string().required('Required'),
    curetting: Yup.string().required("Required"),
    curettingTime: Yup.string().required("Required"),
    stomachIssues: Yup.string().required("Required"),
    natureOfwork: Yup.string().required("Required"),
    hoursOfSleep: Yup.string().required("Required"),
    sleepType: Yup.string().required("Requiredd"),
    sleepDuringDay: Yup.string().required('Required'),
    sleepIssues: Yup.string().required("Required"),
    addiction: Yup.string().required("Required"),
    mentalTrauma: Yup.string().required("Required"),
    ifYesWhat: Yup.string().required("Required"),
    temaparament: Yup.string().required("Requiredd"),
    satisfied: Yup.string().required('Required'),
    upset: Yup.string().required("Required"),
    habitOfComparing: Yup.string().required("Required"),
    afraid: Yup.string().required("Required"),
    unenergetic: Yup.string().required("Required"),
    hallucinations: Yup.string().required("Required"),
    memoryLoss: Yup.string().required("Required"),
    atmosphereInHome: Yup.string().required("Required"),
});

export const DoAndDontsValidationSchema = Yup.object().shape({
    orderIndex: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
});

export const ExerciseValidationSchema = Yup.object().shape({
    orderIndex: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
});

export const VirechanValidationSchema = Yup.object().shape({
    orderIndex: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
});

export const MemberShipValidationSchema = Yup.object().shape({
    orderIndex: Yup.string().required('Required'),
    memberShipType: Yup.string().required('Required'),
});

export const MedicineValidationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    // expiryDate: Yup.string(),
    // quantity: Yup.number().nullable(),
    // unit: Yup.string(),
    // price: Yup.number().nullable(),
    // category: Yup.string(),
    // description: Yup.string(),
});
export const CaretakerFoodPriceSchema = Yup.object().shape({
    price: Yup.number().required('Price is Required'),

})
export const RoomValidationSchema = Yup.object().shape({
    roomType: Yup.string().required("Room Type is required"),
    selectedLang: Yup.string(),
    description: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    shortDescription: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    roomNo: Yup.string().required("Room No. is required"),
    capcity: Yup.string().required("Capacity is required"),
    roomName: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    amenities: Yup.object().shape({
        en: Yup.string().required('English field is required'),
        hi: Yup.string().required('Hindi field is required'),
        mr: Yup.string().required('Marathi field is required'),
    }),
    ratings: Yup.object().shape({
        mainRating: Yup.number().required('mainRating is required'),
        mainRatingName: Yup.string().required('mainRatingName is required'),
        subRatings: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Required'),
                rating: Yup.number().required('Required'),
            })
        ),
    }),
    price: Yup.number().required("Price is required"),
    sharedPrice: Yup.number().required("Shared Price is required"),

})

export const ServiceSlotValidationSchema = Yup.object().shape({
    customerName: Yup.string().required('Required'),
    serviceName: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
    startTime: Yup.string().required('Required'),
    parentCustomerName: Yup.string().required('Required'),

});

export const PriceValidationSchema = Yup.object().shape({
    consultationType: Yup.string().required('Required'),
    totalMinute: Yup.number().required('Required'),
    price: Yup.string().required('Required'),
});

export const MedicalStoreValidationSchema = Yup.object().shape({
    // name: Yup.string().required('Required'),
    // oldCasePaperNo: Yup.string().required('Required'),
    // registrationNo: Yup.string().required('Required'),
    // medicineName: Yup.string().required('Required'),
    // days: Yup.string().required('Required'),
    // whenToTake: Yup.string().required('Required'),
    // purchasedMedicines: Yup.array().of(
    //     Yup.object().shape({
    //         medicineName: Yup.string().required('Required'),
    //         days: Yup.number().required('Required'),
    //         quantity: Yup.number().required('Required'),
    //         whenToTake: Yup.array().of(Yup.string().required('Required')),
    //         anupan: Yup.string().required('Required'),
    //     })
    // )
});

export const OrderValidationSchema = Yup.object().shape({
    paymentStatus: Yup.string().required('Required'),
    paymentMode: Yup.string().required('Required'),
    orderStatus: Yup.string().required('Required'),
    orderType: Yup.string().required('Required'),
    amount: Yup.string().required('Required'),
});