import { useEffect } from 'react';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { InputFromUser } from '../../components/Input/input';
import { getAdminuUerDetails } from '../../store/Actions/AuthActions';
import { CreateTestimonialLink, GetAllTestimonialLink } from '../../store/Actions/TestimonialLinkAction';
import { SingleMultiSelectDropDown } from '../../components/Input/InputText';
import { ActionUpdateById } from '../../store/Actions/CommonAction';
import { ConsultationMasterDataAction } from '../../store/Actions/AppointmentAction';

const AddEditTestimonialLink = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const { userDetails } = useSelector(state => state?.auth);
    const { TestimonialLinkList } = useSelector(state => state?.TestimonialLink);
    // const { Treatmentservicelist } = useSelector(state => state?.PrakrutiStandard);
    const { ConsultationMasterData, } = useSelector(state => state?.Appointment);

    // const TreatmentList = Treatmentservicelist?.[0]?.treatment;
    const userId = userDetails?.[0]?._id
    const idValue = searchParams.get("id");
    const page = searchParams.get("page");
    const updateValue = searchParams.get("update");
    useEffect(() => {
        if (idValue) {
            dispatch(GetAllTestimonialLink({ Query: '/' + idValue }));
        }

    }, [idValue])
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])
    useEffect(() => {
        dispatch(ConsultationMasterDataAction({ Query: '' }))
    }, [])
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to={`/testimonial-link-list?page=${page}`}>Testimonial Link</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Testimonial Link</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue || false}
                                                initialValues={{
                                                    patientName: TestimonialLinkList?.patientName,
                                                    orderIndex: TestimonialLinkList?.orderIndex || "",
                                                    link: TestimonialLinkList?.link || "",
                                                    "treatment": TestimonialLinkList?.treatment && TestimonialLinkList?.treatment?.map((v) => (v.treatmentName)) || [],
                                                    // treatmentName: TestimonialLinkList?.treatment?.map(t => t.treatmentName) || [],
                                                    // treatmentId: TestimonialLinkList?.treatment?.map(t => t.treatmentId) || [],
                                                }}
                                                onSubmit={(values, { setErrors, setSubmitting }) => {

                                                    const matchedArray = [];

                                                    ConsultationMasterData?.treatments?.forEach(itemA => {
                                                        if (values?.treatment.includes(itemA.name)) {
                                                            matchedArray.push({ treatmentName: itemA.name, treatmentId: itemA.id });
                                                        }
                                                    });

                                                    if (updateValue && idValue != null) {
                                                        dispatch(ActionUpdateById({ Query: `admin/patient-testimonial-Link/${idValue}`, obj: { ...values, treatment: matchedArray } }
                                                            , (Res) => {
                                                                if (Res?.statusCode === 200) {
                                                                    window.location.href = `/testimonial-link-list?page=${page}`
                                                                } else if (Res?.statusCode === 400) {
                                                                    setErrors(Res?.data?.errors);
                                                                }
                                                                setSubmitting(false);
                                                            }));
                                                    } else {
                                                        dispatch(CreateTestimonialLink({ ...values, treatment: matchedArray }, (Response) => {
                                                            if (Response?.statusCode === 200) {
                                                                window.location.href = `/testimonial-link-list?page=${page}`
                                                            }
                                                            setSubmitting(false);
                                                        }))
                                                    }
                                                }}

                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue,
                                                    isSubmitting,
                                                }) => {
                                                    return (
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="form-heading">
                                                                    <h4>Add Testimonial Link Data</h4>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-4">

                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Order Index"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Order Index"
                                                                    value={values?.orderIndex}
                                                                    name="orderIndex"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.orderIndex}
                                                                    touched={touched?.orderIndex}
                                                                />

                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Enter Patient Name"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Patient Name"
                                                                    value={values?.patientName}
                                                                    name="patientName"
                                                                    onChange={(v) => setFieldValue("patientName", v?.target?.value, true)}

                                                                    onBlur={handleBlur}
                                                                    error={errors?.patientName}
                                                                    touched={touched?.patientName}
                                                                />

                                                            </div>

                                                            <div className="row mt-4">
                                                                <InputFromUser
                                                                    placeholder={"Post Your YouTube Video Link"}
                                                                    type="url"
                                                                    name="link"
                                                                    onChange={(v) => setFieldValue("link", v?.target?.value, true)}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values?.link}
                                                                    label="Iframe Link"
                                                                    error={errors?.link}
                                                                    touched={touched.link}
                                                                />

                                                                <SingleMultiSelectDropDown
                                                                    label=" Treatment Name"
                                                                    type="text"
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    name="treatment"
                                                                    mode={"multiple"}
                                                                    // onChange={(selectedTreatmentName) => {
                                                                    //     setFieldValue('treatment', selectedTreatmentName);
                                                                    //     const selectedTreatments = selectedTreatmentName.map(name => {
                                                                    //         const treatment = TreatmentList.find(treatment => treatment.name === name);
                                                                    //         return {
                                                                    //             treatmentName: name,
                                                                    //             treatmentId: treatment?.id || ''
                                                                    //         };
                                                                    //     });
                                                                    //     setFieldValue('treatmentId', selectedTreatments.map(t => t.treatmentId));
                                                                    // }}
                                                                    onChange={(Selected) => {
                                                                        setFieldValue("treatment", Selected, true)
                                                                    }}
                                                                    FormControlClassName={'form-control'}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.treatment}
                                                                    value={values?.treatment}
                                                                    options={ConsultationMasterData?.treatments && ConsultationMasterData?.treatments?.map((v) => v.name) || []}
                                                                    touched={touched?.treatment}
                                                                    placeholder={'Select Your Treatment'} />

                                                            </div>

                                                            <div className="col-12 mt-4">
                                                                <div className="doctor-submit text-end">
                                                                    <button
                                                                        type="submit"
                                                                        onClick={() => {
                                                                            handleSubmit()
                                                                        }}
                                                                        className="btn btn-primary submit-form me-2"
                                                                        disabled={isSubmitting}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary cancel-form"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Formik>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div>

            </>

        </>
    )
}

export default AddEditTestimonialLink