import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';


const TableColumnSearch = ({ dataIndex, setSearchText, setSearchedColumn, handleReset, handleSearch, filtered }) => {
    const searchInput = useRef(null);

    // const handleSearch = (selectedKeys, dataIndex) => {
    //     setSearchText(selectedKeys?.[0]);
    //     setSearchedColumn(dataIndex);
    // };

    // const handleReset = (clearFilters) => {
    //     clearFilters();
    //     setSearchText('');
    // };

    return {
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        htmlType='submit'
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        htmlType='button'
                        onClick={() => {
                            clearFilters && handleReset(clearFilters);
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        htmlType='button'
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        filtered: filtered
    };
};
TableColumnSearch.propTypes = {
    setSearchedColumn: PropTypes.func.isRequired,
    setSearchText: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    // searchedColumn: PropTypes.string,
    dataIndex: PropTypes.string,
    filtered: PropTypes.bool,
};

export default TableColumnSearch;
