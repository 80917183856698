/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Button, Divider, Row, Modal } from "antd";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { StaffProfileIcon, downloadimage, eyeIc } from "../../../components/imagepath";
import { CustomTable } from "../../../components/Table";
import { InputFromUser } from "../../../components/Input/input";
import {
    Options, PrivetKey
} from "../../../utils/Data";
import { Formik } from "formik";
import moment from 'moment';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from "react-redux";
import { ActionGetCCPaperDetail, ActionGetMedicineList, GetMedicalDetailAction } from "../../../store/Actions/PatientAction";
import { ConsultationMasterDataAction, CreateConsultationMedicalDetailsAction, getAllAppoinment } from "../../../store/Actions/AppointmentAction";
import { LabInvestigationComponent } from "../../LaboratoryInvestigation";
import { NextDateValidationSchema } from "../../../utils/FormValidation";
import { matchPermissions } from "../../../utils/Permission";
import { FilesIconByType, ShowFileInTab, downloadFile, generateQueryString } from "../../../utils/Funcations";
import { DOCS_URL } from "../../../utils/globals";
const AppointmentDetails = () => {
    const [modal, SetModal] = useState(false);
    const url = new URL(window.location.href);
    const currentDate = new Date();
    const searchParams = url.searchParams;
    const ParamPage = searchParams.get("page");
    const ParamUserId = searchParams.get("userId");
    const ParamIsFor = searchParams.get("IsFor");
    const ParamAppointmentId = searchParams.get("appointmentId");
    const ParamConsultingFor = searchParams.get("consultationFor");
    const consultationId = searchParams.get("consultationId");
    const status = searchParams.get("status");
    const patientId = searchParams.get("userId") || '';
    const [isDaysSet, setDaysSet] = useState(false);
    const [courseDuration, setCourseDuration] = useState(false);

    const dispatch = useDispatch();
    const { medicineList, loader, MedicalDetail, CcPaperDetail } = useSelector(state => state?.patient);
    const { userDetails } = useSelector(state => state?.auth);
    const { ConsultationMasterData, AllAppoinment } = useSelector(state => state?.Appointment);
    const IsFilledForm = ParamIsFor === PrivetKey.DISABLE.toString()
    const AppointmentData = AllAppoinment?.docs?.find(item => item?._id == ParamAppointmentId);
    const Filters = generateQueryString(
        ['page', (ParamPage || 1)],
        ['status', status],
    )
    useEffect(() => {
        if (IsFilledForm) {
            dispatch(GetMedicalDetailAction({ Query: `/${consultationId}` }))
        } else if (ParamAppointmentId) {
            dispatch(getAllAppoinment({ Query: Filters }));
        }
        dispatch(ActionGetMedicineList({ Query: `` }))
        dispatch(ConsultationMasterDataAction({ Query: '' }))
        if (patientId) {
            dispatch(ActionGetCCPaperDetail({ Query: `/${patientId}` }));
        }
    }, [])

    const treatmentDetails = AppointmentData?.[0]?.treatment || (MedicalDetail?.[0]?.treatment?.[0]?.treatmentName && `${MedicalDetail?.[0]?.treatment?.[0]?.treatmentName || '---'} (Day ${MedicalDetail?.[0]?.treatment?.[0]?.treatmentsDay}`) || AppointmentData?.treatment
    const PatientName = MedicalDetail?.[0]?.customerId?.name || AppointmentData?.[0]?.customerName || AppointmentData?.customerName
    const PatientPhoneNo = MedicalDetail?.[0]?.customerId?.phoneNo || AppointmentData?.[0]?.phoneNo || AppointmentData?.phoneNo
    const PatientGender = MedicalDetail?.[0]?.customerId?.gender || AppointmentData?.[0]?.customerId?.gender || AppointmentData?.customerId?.gender
    const PatientID = MedicalDetail?.[0]?.customerId?._id || AppointmentData?.[0]?.customerId?._id || AppointmentData?.customerId?._id
    const PatientBirthDate = MedicalDetail?.[0]?.customerId?.birthDate || AppointmentData?.[0]?.customerId?.birthDate || AppointmentData?.customerId?.birthDate
    const PatientRegistrationNo = MedicalDetail?.[0]?.customerId?.registrationNo || AppointmentData?.[0]?.registrationNo || AppointmentData?.registrationNo
    const PatientDateOfConsultation = MedicalDetail?.[0]?.customerId?.dateOfConsultation || AppointmentData?.[0]?.date || AppointmentData?.date
    const PatientConsultatingDoctor = MedicalDetail?.[0]?.appointmentId?.doctor?.name || MedicalDetail?.[0]?.adminId?.name || AppointmentData?.[0]?.doctorName || AppointmentData?.doctorName
    const matchPermissionsForUser = matchPermissions(userDetails)
    return (
        <div>
            <Header />
            <Sidebar id="menu-item1" id1="menu-items1" activeClassName="add-staff" />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to={`/staff-list?page=${ParamPage}`}>Patient</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to={`/staff-list?page=${ParamPage}`}>Patient List</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to={`/staff-list?page=${ParamPage}`}>Patient Profile</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Appointment Detail</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Patient Details*/}
                        <div className="card-box profile-header my-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="profile-view">
                                        <div className="profile-img-wrap-sm">
                                            <div className="profile-img-sm">
                                                <Link to="#">
                                                    <img className="avatar" style={{ width: "100%", height: "100%", }}
                                                        src={StaffProfileIcon} alt="#" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="profile-basic" style={{ backgroundColor: '' }}>
                                            <div className="row align-items-center">

                                                <div className="col-md-3">
                                                    <div className="profile-info-left">
                                                        <h3 className="user-name m-t-0 mb-0">{PatientName || '---'}</h3>
                                                        <small className="text-muted">{'---'}</small>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 align-items-center">
                                                    <Link to={`/20minform?id=${PatientID}&for=125`}
                                                        className="btn btn-primary btn-rounded">View Case paper
                                                    </Link>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider />

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="profile-view">
                                        <div className="" style={{ backgroundColor: '' }}>
                                            <div className="row align-items-center">
                                                <div className="col-md-3">
                                                    <ul className="personal-info">
                                                        <li>
                                                            <span className="title">Phone:</span>
                                                            <span className="text"><Link to>{PatientPhoneNo || '---'}</Link></span>
                                                        </li>
                                                        <li>
                                                            <span className="title">Gender:</span>
                                                            <span className="text">{PatientGender || '---'}</span>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="col-md-4 mb-4">
                                                    <ul className="personal-info">
                                                        <li>
                                                            <span className="title">Birthdate:</span>
                                                            <span className="text">{PatientBirthDate && (moment(PatientBirthDate).format('DD/MM/YYYY')) || '---'}</span>
                                                        </li>
                                                        <li>
                                                            <span className="title">Case Paper number</span>
                                                            <span className="text"><Link to>{PatientRegistrationNo || '---'}</Link></span>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="col-md-4">
                                                    <ul className="personal-info">
                                                        <li>
                                                            <span className="title">Date of Booking:</span>
                                                            <span className="text"><Link to>{(PatientDateOfConsultation && moment(PatientDateOfConsultation).format('DD/MM/YYYY') || '---') || '---'}</Link></span>
                                                        </li>
                                                        <li>
                                                            <span className="title">Consulting Doctor :</span>
                                                            <span className="text">{PatientConsultatingDoctor || '---'}</span>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="row">
                                                    <div className="col-9">
                                                        <ul className="personal-info">
                                                            <li>
                                                                <span className="title">Treatment :</span>
                                                                <span className="text">{treatmentDetails}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <Divider />

                                                <div className="row">
                                                    <div className="col-9">
                                                        <ul className="personal-info">
                                                            <li>
                                                                <span className="title">Chief Complaint:</span>
                                                                <span className="text">{CcPaperDetail?.personalDetails?.[3]?.value}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <Formik
                        enableReinitialize={IsFilledForm}
                        initialValues={{
                            nextConsultationDate: IsFilledForm ? (MedicalDetail?.[0]?.nextConsultationDate && dayjs(MedicalDetail?.[0]?.nextConsultationDate) || null) : null || null,
                            yakrut: MedicalDetail?.[0]?.yakrut || [],
                            pleeha: MedicalDetail?.[0]?.pleeha || [],
                            sparshaSahatva: MedicalDetail?.[0]?.sparshaSahatva || [{ "Yakrut": [] }, { "Pleeha": [] }, { "Dakshi Vruka": [] }, { "Vaam Vruka": [] }, { "Apan Kaksha": [] }],
                            recomendedMedicines: IsFilledForm ? MedicalDetail?.[0]?.recomendedMedicines : [{ "medicineName": "", "days": null, "whenToTake": [], 'anupan': '', 'howMuch': [""], "course": "" }],
                            laboratory: MedicalDetail?.[0]?.laboratory || [],
                            // diagnosis: MedicalDetail?.[0]?.diagnosis || [],
                            advice: MedicalDetail?.[0]?.advice || [],
                            dateOfMedicalStore: IsFilledForm ? (MedicalDetail?.[0]?.dateOfMedicalStore && dayjs(MedicalDetail?.[0]?.dateOfMedicalStore) || null) : null || null,
                            rootCause: MedicalDetail?.[0]?.rootCause || [],
                            doctorNotes: MedicalDetail?.[0]?.doctorNotes || [''],
                            panchakrama: MedicalDetail?.[0]?.panchakrama || [],
                            jivha: MedicalDetail?.[0]?.jivha || [],
                            laboratoryNote: MedicalDetail?.[0]?.laboratoryNote || "",
                            upshay: MedicalDetail?.[0]?.upshay || "",
                            anupshay: MedicalDetail?.[0]?.anupshay || "",
                            nails: MedicalDetail?.[0]?.nails || "",
                            eyes: MedicalDetail?.[0]?.eyes || "",
                            prakruti: MedicalDetail?.[0]?.prakruti || [],
                            dos: MedicalDetail?.[0]?.dos?.map((v) => v?.title) || [],
                            donts: MedicalDetail?.[0]?.donts?.map((v) => v?.title) || [],
                            virechan: MedicalDetail?.[0]?.virechan?.map((v) => v?.title) || [],
                            exercise: MedicalDetail?.[0]?.exercise?.map((v) => v?.title) || [],

                            nadi: MedicalDetail?.[0]?.nadi || [],
                            naad: MedicalDetail?.[0]?.naad || [{ "Yakrut": [] }, { "Pliha": [] }],
                            customerId: patientId,
                            appointmentId: ParamAppointmentId,
                            status: 'Completed',
                            consultingFor: ParamConsultingFor,
                            dateOfConsultation: currentDate,
                            // dosh: MedicalDetail?.[0]?.dosh || [{ "name": "Vata", "value": [] }, { "name": "Pita", "value": [] },
                            // { "name": "Kapha", "value": [] }
                            // ],

                            // strotasDrushti: MedicalDetail?.[0]?.strotasDrushti || [],

                            diagnosis: MedicalDetail?.[0]?.diagnosis || [{
                                "diagnosis": "",
                                "other": "",
                            },],

                            treatment: MedicalDetail?.[0]?.treatment || [{
                                "treatmentName": "",
                                "other": "",
                                "day": ""
                            },],
                            solution: MedicalDetail?.[0]?.solution || [{
                                "solutionName": "",
                                "other": "",
                                "day": ""
                            }],
                        }}

                        // validationSchema={PatientValidationSchema}
                        validationSchema={NextDateValidationSchema}
                        onSubmit={(values, { setSubmitting, setErrors }) => {

                            const filteredValues = Object.fromEntries(
                                Object.entries(values).filter(([key, value]) => {
                                    if (key === "recomendedMedicines" && Array.isArray(value) && value.length > 0) {
                                        const filteredMedicines = value.filter(medicine => {
                                            if (
                                                medicine.days !== undefined &&
                                                medicine.days !== null &&
                                                medicine.days !== 0 &&
                                                parseInt(medicine.days) > 0
                                            ) {
                                                medicine.days = parseInt(medicine.days);
                                                return true;
                                            } else {
                                                const errorMessage = `recomendedMedicines: Invalid 'days' value in one or more medicines`;
                                                console.error(errorMessage);
                                                return false;
                                            }
                                        });

                                        return filteredMedicines.length > 0;
                                    } else {
                                        return Boolean(value);
                                    }
                                })
                            );

                            const formattedNextConsultationDate = filteredValues?.dateOfMedicalStore.format("YYYY-MM-DDTHH:mm:ss");
                            const formattedNextConsultationDate1 = filteredValues?.nextConsultationDate.format("YYYY-MM-DDTHH:mm:ss");

                            const dosWithId = (ConsultationMasterData?.doAndDonts || [])
                                .filter(item => Array.isArray(filteredValues?.dos) && filteredValues.dos.includes(item.title))
                                .map(({ id, title }) => ({ id, title }));

                            const exercisesWithId = (ConsultationMasterData?.exercises || [])
                                .filter(item => Array.isArray(filteredValues?.exercise) && filteredValues.exercise.includes(item.title))
                                .map(({ id, title }) => ({ id, title }));

                            const virechansWithId = (ConsultationMasterData?.virechans || [])
                                .filter(item => Array.isArray(filteredValues?.virechan) && filteredValues.virechan.includes(item.title))
                                .map(({ id, title }) => ({ id, title }));

                            const dontsWithId = (ConsultationMasterData?.doAndDonts || [])
                                .filter(item => Array.isArray(filteredValues?.donts) && filteredValues.donts.includes(item.title))
                                .map(({ id, title }) => ({ id, title }));
                            dispatch(CreateConsultationMedicalDetailsAction({
                                obj: {
                                    ...filteredValues,
                                    'dateOfMedicalStore': values?.dateOfMedicalStore,
                                    'nextConsultationDate': values?.nextConsultationDate,
                                    "dos": dosWithId,
                                    "donts": dontsWithId,
                                    "virechan": virechansWithId,
                                    "exercise": exercisesWithId,
                                }
                            }, Res => {
                                if (Res?.statusCode === 200) {
                                    window.location.href = `/appoinment-list?page=${ParamPage}`;
                                } else if (Res?.statusCode === 400) {
                                    window.scrollTo({
                                        top: 10,
                                        behavior: 'smooth',
                                    });
                                    setErrors(Res?.data?.errors);
                                }
                            }));

                            setSubmitting(false);
                        }}

                    >
                        {({
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setValues,
                            isSubmitting,
                        }) => {
                            return (
                                <div className="mx-4">

                                    {/*Document*/}
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body" >
                                                    <Divider>
                                                        <div className="m-3">
                                                            <h3 className="">∬ Document ∬</h3>
                                                        </div></Divider>

                                                    <CustomTable
                                                        CurrentPageNum={1}
                                                        TotalPageList={1}
                                                        column={[
                                                            {
                                                                title: "File Name",
                                                                dataIndex: "name",
                                                                render: (text, record) => {
                                                                    const fileType = record.split('.').pop()
                                                                    return (
                                                                        <>
                                                                            {FilesIconByType(fileType)}
                                                                            <span className="mx-3">{record.substring(record.lastIndexOf("/") + 1)}</span>
                                                                        </>
                                                                    )
                                                                },
                                                            }, {
                                                                title: "Action",
                                                                dataIndex: "name",
                                                                render: (text, record) => {
                                                                    const fileType = record.split('.').pop()
                                                                    return (
                                                                        <div className="activity-boxs comman-flex-end " style={{ background: "none" }} >
                                                                            <img src={downloadimage} className='mx-2' style={{ cursor: 'pointer' }} onClick={() => {
                                                                                const url = DOCS_URL + record
                                                                                downloadFile(url)
                                                                            }} />
                                                                            <img src={eyeIc} className='mx-2' style={{ cursor: 'context-menu' }} onClick={() => ShowFileInTab(record, record.substring(record.lastIndexOf("/") + 1))} />
                                                                        </div>
                                                                    )
                                                                },
                                                            },
                                                        ]}
                                                        dataSource={CcPaperDetail?.laboratoryDocs || []}
                                                        dataSourceLength={5}
                                                        pageSize={10}
                                                        loader={false}
                                                        ShowHeader={true}
                                                        Header={<div className="staff-search-table">
                                                        </div>}
                                                        key={"MedicalDetail"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Upshay Anupshay Examination*/}
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <Row justify={"space-around"}>
                                                        <InputFromUser
                                                            disabled={IsFilledForm}
                                                            placeholder={"Enter Your Upshay"}
                                                            type="singleSelect"
                                                            options={Options.Upshay}
                                                            name="upshay"
                                                            onChange={(v) => handleChange({
                                                                target: {
                                                                    name: "upshay",
                                                                    value: v
                                                                }
                                                            })}
                                                            value={values.upshay}
                                                            FormGroupClassName={"col-xl-4 col-md-7 "}
                                                            LabelClassName={"col-form-label"}
                                                            onBlur={handleBlur}
                                                            label="Upshay"
                                                            error={errors.upshay}
                                                            touched={touched.upshay}
                                                        />
                                                        <InputFromUser
                                                            disabled={IsFilledForm}
                                                            placeholder={"Enter Your Anupshay"}
                                                            type="singleSelect"
                                                            options={Options.Anupshay}
                                                            name="anupshay"
                                                            onChange={(v) => handleChange({
                                                                target: {
                                                                    name: "anupshay",
                                                                    value: v
                                                                }
                                                            })}
                                                            value={values.anupshay}
                                                            FormGroupClassName={"col-xl-4 col-md-7"}
                                                            LabelClassName={"col-form-label"}
                                                            onBlur={handleBlur}
                                                            label="Anupshay"
                                                            error={errors.anupshay}
                                                            touched={touched.anupshay}
                                                        />

                                                    </Row>

                                                    <Divider>
                                                        <div className="m-3">
                                                            <h3 className="">∬ Examination ∬</h3>
                                                        </div></Divider>
                                                    <div className="row">

                                                        <InputFromUser
                                                            disabled={IsFilledForm}
                                                            placeholder={"Enter Your Jiva"}
                                                            type="multiple"
                                                            name="jivha"
                                                            LabelClassName={"col-form-label"}
                                                            onBlur={undefined}
                                                            label="Jiva"
                                                            FormGroupClassName={"col-xl-3 col-md-12"}
                                                            onChange={(v) => handleChange({
                                                                target: {
                                                                    name: "jivha",
                                                                    value: v
                                                                }
                                                            })}
                                                            value={values.jivha}
                                                            FormControlClassName={'form-control'}
                                                            options={Options.Jivha}
                                                            error={errors.jivha}
                                                            touched={touched.jivha}
                                                        />
                                                        <InputFromUser
                                                            disabled={IsFilledForm}
                                                            placeholder={"Enter Your Nails"}
                                                            type="singleSelect"
                                                            name="nails"
                                                            LabelClassName={"col-form-label"}
                                                            onBlur={undefined}
                                                            label="Nails"
                                                            FormGroupClassName={"col-xl-3 col-md-12"}
                                                            onChange={(v) => handleChange({
                                                                target: {
                                                                    name: "nails",
                                                                    value: v
                                                                }
                                                            })}
                                                            value={values.nails}
                                                            FormControlClassName={'form-control'}
                                                            options={Options.Nails}
                                                            error={errors.nails}
                                                            touched={touched.nails}
                                                        />
                                                        <InputFromUser
                                                            disabled={IsFilledForm}
                                                            placeholder={"Enter Your Eyes"}
                                                            type="singleSelect"
                                                            name="eyes"
                                                            LabelClassName={"col-form-label"}
                                                            onBlur={undefined}
                                                            label="Eyes"
                                                            FormGroupClassName={"col-xl-3 col-md-12"}
                                                            onChange={(v) => handleChange({
                                                                target: {
                                                                    name: "eyes",
                                                                    value: v
                                                                }
                                                            })}
                                                            value={values.eyes}
                                                            FormControlClassName={'form-control'}
                                                            options={Options.Eyes}
                                                            error={errors.eyes}
                                                            touched={touched.eyes}
                                                        />
                                                        <InputFromUser
                                                            disabled={IsFilledForm}
                                                            placeholder={"Enter Your Prakruti"}
                                                            type="multiple"
                                                            name="prakruti"
                                                            LabelClassName={"col-form-label"}
                                                            onBlur={undefined}
                                                            label="Prakruti"
                                                            FormGroupClassName={"col-xl-3 col-md-12"}
                                                            onChange={(v) => handleChange({
                                                                target: {
                                                                    name: "prakruti",
                                                                    value: v
                                                                }
                                                            })}
                                                            value={values.prakruti}
                                                            FormControlClassName={'form-control'}
                                                            options={Options.Prakruti}
                                                            error={errors.prakruti}
                                                            touched={touched.prakruti}
                                                        />
                                                    </div>
                                                    <Divider />
                                                    <InputFromUser
                                                        disabled={IsFilledForm}
                                                        placeholder={"Enter Your Nadi"}
                                                        type="multiple"
                                                        name="nadi"
                                                        LabelClassName={"col-form-label"}
                                                        onBlur={undefined}
                                                        label="Nadi"
                                                        FormGroupClassName={"col-xl-3 col-md-12"}
                                                        onChange={(v) => handleChange({
                                                            target: {
                                                                name: "nadi",
                                                                value: v
                                                            }
                                                        })} value={values.nadi}
                                                        FormControlClassName={'form-control'}
                                                        options={Options.Nadi}
                                                        error={errors.nadi}
                                                        touched={touched.nadi}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Examination*/}
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <Divider>
                                                        <div className="m-3">
                                                            <h3 className="">∬ Nada ∬</h3>
                                                        </div></Divider>
                                                    <div className="row">
                                                        {values?.naad && values?.naad?.map((item, i) => {
                                                            let NewValue = { ...values };
                                                            const NameV = Object.keys(item)?.[0];
                                                            return (
                                                                <InputFromUser
                                                                    disabled={IsFilledForm}
                                                                    key={i}
                                                                    label={Object.keys(item)?.[0]}
                                                                    type="singleSelect"
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    name={'yakrut'}
                                                                    onChange={(value) => {
                                                                        NewValue.naad[i] = {
                                                                            [NameV]: value
                                                                        };
                                                                        handleChange({
                                                                            target: {
                                                                                name: "naad",
                                                                                value: NewValue.naad
                                                                            }
                                                                        });
                                                                    }}
                                                                    FormControlClassName={'form-control'}
                                                                    onBlur={(value) => { }}
                                                                    error={typeof errors.naad === "string" ? errors.naad :
                                                                        errors.naad?.find((e) => e?.[Object.keys(item)?.[0]])?.[Object.keys(item)?.[0]] || ""}
                                                                    value={item?.[NameV]}
                                                                    options={NameV === 'Yakrut' ? Options.Nada.Yakrut : Options.Nada.Pleeha}
                                                                    touched={true}
                                                                    placeholder={`Enter Your ${NameV === 'Yakrut' ? "Yakrut" : "Pliha"}`} />
                                                            )
                                                        })}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Sparshasahatva*/}
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <Divider>
                                                        <div className="m-3">
                                                            <h3 className="">∬ Sparshasahatva ∬</h3>
                                                        </div></Divider>
                                                    <div className="row">
                                                        {values?.sparshaSahatva?.map((item, i) => {
                                                            let NewValue = { ...values };
                                                            const NameV = Object.keys(item)?.[0];
                                                            return (
                                                                <InputFromUser
                                                                    disabled={IsFilledForm}
                                                                    type="multiple"
                                                                    key={i}
                                                                    label={Object.keys(item)?.[0]}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    name={'sparshaSahatva'}
                                                                    onChange={(value) => {
                                                                        NewValue.sparshaSahatva[i] = {
                                                                            [NameV]: value
                                                                        };
                                                                        handleChange({
                                                                            target: {
                                                                                name: "sparshaSahatva",
                                                                                value: NewValue.sparshaSahatva
                                                                            }
                                                                        });
                                                                    }}
                                                                    FormControlClassName={'form-control'}
                                                                    onBlur={(value) => { }}
                                                                    error={typeof errors.sparshaSahatva === "string" ? errors.sparshaSahatva :
                                                                        errors.sparshaSahatva?.find((e) => e?.[Object.keys(item)?.[0]])?.[Object.keys(item)?.[0]] || ""}
                                                                    value={item?.[NameV]}
                                                                    options={NameV === 'Yakrut' ? Options.SparshasahatvaSubOptions.Yakrut :
                                                                        NameV === 'Pleeha' ? Options.SparshasahatvaSubOptions.Pleeha :
                                                                            NameV === 'Dakshi Vruka' ? Options.SparshasahatvaSubOptions.DakshiVruka :
                                                                                NameV === 'Vaam Vruka' ? Options.SparshasahatvaSubOptions.VaamVruka :
                                                                                    NameV === 'Apan Kaksha' ? Options.SparshasahatvaSubOptions.ApanKaksha : []}
                                                                    touched={true}
                                                                    placeholder={`Enter Your ${NameV}`} />

                                                            )
                                                        })}

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Laboratory Examination / Findings */}
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="me-3">
                                                        <h3 className=""></h3>
                                                    </div>
                                                    <Divider>
                                                        <div className="m-3">
                                                            <h3 className="">∬ Laboratory Examination / Findings ∬</h3>
                                                        </div></Divider>
                                                    <div className="col">
                                                        <div className="form-group" >
                                                            <label className="col-form-label" style={{ color: errors.doctorNote && touched.doctorNote && 'red' }} >
                                                            </label>
                                                            <Button className="form-control"
                                                                onClick={() => SetModal(true)}>Laboratory Investigations</Button>
                                                        </div>

                                                    </div>

                                                    <Modal
                                                        open={modal}
                                                        closable={true}
                                                        className='ant-modal'
                                                        forceRender={true}
                                                        zIndex={1050}
                                                        style={{ margin: 10 }}
                                                        onOk={() => SetModal(false)}
                                                        styles={{
                                                            body: {
                                                                overflow: "hidden",
                                                            },
                                                            mask: {
                                                                backdropFilter: 'blur(10px)',
                                                            },
                                                            content: {
                                                                borderRadius: 40,
                                                            },
                                                        }}
                                                        okText={'Submit'}
                                                        centered
                                                        confirmLoading={false}
                                                        onCancel={() => SetModal(false)}
                                                        closeIcon={true}
                                                    >

                                                        <LabInvestigationComponent
                                                            errors={errors}
                                                            touched={touched}
                                                            values={values}
                                                            name={'laboratory'}
                                                            laboratory={ConsultationMasterData?.laboratories && [ConsultationMasterData?.laboratories] || []}
                                                            setValues={setValues}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                        />

                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* 3 option*/}
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className={"row"}>

                                                        <InputFromUser
                                                            disabled={IsFilledForm}

                                                            placeholder={"Enter Your rootCause"}
                                                            type="multiple"
                                                            options={Options.Hetu}
                                                            name="rootCause"
                                                            onChange={(v) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "rootCause",
                                                                        value: v
                                                                    }
                                                                });
                                                            }}
                                                            value={values.rootCause}
                                                            FormGroupClassName={"col-xl-4 col-md-7"}
                                                            LabelClassName={"col-form-label"}
                                                            onBlur={undefined}
                                                            label="rootCause"
                                                            // error={errors.rootCause}
                                                            touched={true}
                                                        />

                                                        <div className="row">
                                                            {values?.diagnosis?.map((item, i) => {
                                                                return (
                                                                    <>
                                                                        <InputFromUser
                                                                            // error={errors.diagnosis?.[i]?.diagnosis}
                                                                            touched={true}
                                                                            placeholder={"Enter Your Diagnosis"}
                                                                            type="singleSelect"
                                                                            options={Options.Diagnosis}
                                                                            name="diagnosis"
                                                                            onChange={(v) => {
                                                                                const newValue = [...values.diagnosis]
                                                                                newValue[i].diagnosis = v
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: "diagnosis",
                                                                                        value: newValue
                                                                                    }
                                                                                });
                                                                            }}
                                                                            value={values.diagnosis?.[i].diagnosis}
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            onBlur={undefined}
                                                                            label="Diagnosis"
                                                                        />
                                                                        <InputFromUser
                                                                            // error={errors.diagnosis?.[i].other}
                                                                            touched={true}
                                                                            placeholder={"Enter others"}
                                                                            type="text"
                                                                            name="diagnosis"
                                                                            onChange={(v) => {
                                                                                const newValue = [...values.diagnosis]
                                                                                newValue[i].other = v.target.value
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: "diagnosis",
                                                                                        value: newValue
                                                                                    }
                                                                                });
                                                                            }} value={values.diagnosis?.[i].other}
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            onBlur={undefined}
                                                                            label="Others"
                                                                        />

                                                                        <Divider dashed />

                                                                    </>

                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    {/* Treatment Recommendation*/}
                                                    <Divider>
                                                        <div className="m-3">
                                                            <h3 className="">∬ Treatment Recommendation ∬</h3>
                                                        </div></Divider>
                                                    <div className="row">
                                                        {values?.treatment?.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <InputFromUser
                                                                        error={errors.treatment?.[i]?.treatmentName}
                                                                        touched={true}
                                                                        placeholder={"Enter Your Treatment"}
                                                                        type="singleSelect"
                                                                        options={ConsultationMasterData?.treatments && ConsultationMasterData?.treatments?.map((v) => v.name) || []}
                                                                        name="treatments"
                                                                        onChange={(v) => {
                                                                            const newValue = [...values.treatment]
                                                                            newValue[i].treatmentName = v
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "treatment",
                                                                                    value: newValue
                                                                                }
                                                                            });
                                                                        }}
                                                                        value={values.treatment?.[i].treatmentName}
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Treatment"
                                                                    />
                                                                    <InputFromUser
                                                                        error={errors.treatment?.[i].other}
                                                                        touched={true}
                                                                        placeholder={"Enter others"}
                                                                        type="text"
                                                                        name="treatment"
                                                                        onChange={(v) => {
                                                                            const newValue = [...values.treatment]
                                                                            newValue[i].other = v.target.value
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "treatment",
                                                                                    value: newValue
                                                                                }
                                                                            });
                                                                        }} value={values.treatment?.[i].other}
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Others"
                                                                    />
                                                                    <InputFromUser
                                                                        error={errors.treatment?.[i].treatmentsDay}
                                                                        touched={true}
                                                                        placeholder={"Enter Your Days"}
                                                                        type="singleSelect"
                                                                        options={Array.from({ length: 30 }, (_, index) => index + 1)}
                                                                        name="treatment"
                                                                        onChange={(v) => {
                                                                            const newValue = [...values.treatment]
                                                                            newValue[i].treatmentsDay = v
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "treatment",
                                                                                    value: newValue
                                                                                }
                                                                            });
                                                                        }}
                                                                        value={values.treatment?.[i]?.treatmentsDay}
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Days"
                                                                    />
                                                                    <Divider dashed />

                                                                </>

                                                            )
                                                        })}
                                                        {/* Treatment Recommendation end */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    {/* Solution Recommendation  */}
                                                    <Divider>
                                                        <div className="m-3">
                                                            <h3 className="">∬ Solution Recommendation ∬</h3>
                                                        </div></Divider>
                                                    <div className="row">
                                                        {values?.solution?.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <InputFromUser
                                                                        error={errors.solution?.[i]?.solutionName}
                                                                        touched={true}
                                                                        placeholder={"Enter Your solution"}
                                                                        type="singleSelect"
                                                                        options={ConsultationMasterData?.solution && ConsultationMasterData?.solution?.map((v) => v.name) || []}
                                                                        name="treatments"
                                                                        onChange={(v) => {
                                                                            const newValue = [...values.solution]
                                                                            newValue[i].solutionName = v
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "solution",
                                                                                    value: newValue
                                                                                }
                                                                            });
                                                                        }}
                                                                        value={values.solution?.[i].solutionName}
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Solutions"
                                                                    />
                                                                    <InputFromUser
                                                                        error={errors.solution?.[i].other}
                                                                        touched={true}
                                                                        placeholder={"Enter others"}
                                                                        type="text"
                                                                        name="solution"
                                                                        onChange={(v) => {
                                                                            const newValue = [...values.solution]
                                                                            newValue[i].other = v.target.value
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "solution",
                                                                                    value: newValue
                                                                                }
                                                                            });
                                                                        }} value={values.solution?.[i].other}
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Others"
                                                                    />
                                                                    <InputFromUser
                                                                        error={errors.solution?.[i].treatmentsDay}
                                                                        touched={true}
                                                                        placeholder={"Enter Your Days"}
                                                                        type="singleSelect"
                                                                        options={[5, 15, 25]}
                                                                        name="solution"
                                                                        onChange={(v) => {
                                                                            const newValue = [...values.solution]
                                                                            newValue[i].treatmentsDay = v
                                                                            handleChange({
                                                                                target: {
                                                                                    name: "solution",
                                                                                    value: newValue
                                                                                }
                                                                            });
                                                                        }}
                                                                        value={values.solution?.[i]?.treatmentsDay}
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={undefined}
                                                                        label="Days"
                                                                    />
                                                                    <Divider dashed />
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                    {/* Solution Recommendation end */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Prescription*/}
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <Divider>
                                                        <div className="m-3">
                                                            <h3 className="">∬ Prescription ∬</h3>
                                                        </div></Divider>
                                                    <div className="invoice-add-table form-group " >
                                                        <div className="table-responsive " >
                                                            <table className="table table-center add-table-items" >
                                                                <thead >
                                                                    <tr >
                                                                        <th >Medicine</th>
                                                                        <th>when to Take</th>
                                                                        <th>Quantity</th>
                                                                        <th>Course Duration</th>
                                                                        <th>Anupan</th>
                                                                        <th>Days</th>
                                                                        <th className="text-end">Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {values?.recomendedMedicines?.map((item, index) => {
                                                                        return (
                                                                            <tr key={"Medicines" + index + 'tr'}>
                                                                                <td className="col-xl-3 col-md-10" key={"Medicines" + index + 'td'} style={{ borderColor: errors?.recomendedMedicines?.[index]?.medicineName && touched.recomendedMedicines?.[index]?.medicineName && 'red' }}>
                                                                                    {<InputFromUser
                                                                                        type="singleSelect"
                                                                                        label=""
                                                                                        key={"MedicinesName" + index}
                                                                                        Showlabel={false}
                                                                                        mode={''}
                                                                                        name="recomendedMedicines"
                                                                                        onChange={(v) => {
                                                                                            const updateArry = Object.assign([], values.recomendedMedicines)
                                                                                            updateArry[index] = {
                                                                                                ...updateArry[index],
                                                                                                ...{ medicineName: v }
                                                                                            }
                                                                                            setFieldValue("recomendedMedicines", updateArry, true)
                                                                                        }}
                                                                                        FormControlClassName={'form-control'}
                                                                                        value={item?.medicineName}
                                                                                        disabled={IsFilledForm}
                                                                                        onSearch={(search) => {
                                                                                            if (search.length >= 2) {
                                                                                                dispatch(ActionGetMedicineList({ Query: `?name=${search}` }))
                                                                                            } else if (search.length === 0) {
                                                                                                dispatch(ActionGetMedicineList({ Query: `` }))
                                                                                            }
                                                                                        }}
                                                                                        onBlur={() => {
                                                                                            const updateArry = Object.assign([], touched.recomendedMedicines)
                                                                                            updateArry[index] = {
                                                                                                ...updateArry[index],
                                                                                                ...{ medicineName: true }
                                                                                            }
                                                                                            setFieldTouched("recomendedMedicines", updateArry, true)
                                                                                        }}
                                                                                        error={errors?.recomendedMedicines?.[index]?.medicineName}
                                                                                        touched={touched?.recomendedMedicines?.[index]?.medicineName}
                                                                                        onSearchLoading={loader}
                                                                                        options={medicineList.length != 0 ? medicineList?.map(item => item?.name) : []}
                                                                                        placeholder={'Enter Your Medicines'} />
                                                                                    }
                                                                                </td>
                                                                                <td className="col-xl-2 col-md-10" key={"td2" + index}>
                                                                                    {<InputFromUser
                                                                                        type="multiple"
                                                                                        label=""
                                                                                        Showlabel={false}
                                                                                        mode={''}
                                                                                        disabled={IsFilledForm}
                                                                                        name="whenToTake"
                                                                                        onChange={(v) => {
                                                                                            const updateArry = Object.assign([], values.recomendedMedicines)
                                                                                            updateArry[index] = {
                                                                                                ...updateArry[index],
                                                                                                ...{ whenToTake: v }
                                                                                            }
                                                                                            setFieldValue("recomendedMedicines", updateArry, true)
                                                                                        }}
                                                                                        FormControlClassName={'form-control'}
                                                                                        value={item?.whenToTake}
                                                                                        onSearch={(search) => {
                                                                                            if (search.length >= 3) {
                                                                                                dispatch(ActionGetMedicineList({ Query: `?name=${search}` }))
                                                                                            } else if (search.length === 0) {
                                                                                                dispatch(ActionGetMedicineList({ Query: `` }))
                                                                                            }
                                                                                        }}
                                                                                        onBlur={() => {
                                                                                            const updateArry = Object.assign([], touched.recomendedMedicines)
                                                                                            updateArry[index] = {
                                                                                                ...updateArry[index],
                                                                                                ...{ whenToTake: true }
                                                                                            }
                                                                                            setFieldTouched("recomendedMedicines", updateArry, true)
                                                                                        }}
                                                                                        error={errors?.recomendedMedicines?.[index]?.whenToTake}
                                                                                        touched={touched?.recomendedMedicines?.[index]?.whenToTake}
                                                                                        onSearchLoading={loader}
                                                                                        options={Options.WhenToTakeMedicine}
                                                                                        placeholder={'Enter When to Take'} />
                                                                                    }
                                                                                </td>
                                                                                <td className="col-xl-2 col-md-10" key={"td3" + index}>

                                                                                    <div>
                                                                                        <InputFromUser
                                                                                            type="text"
                                                                                            label=""
                                                                                            disabled={IsFilledForm}
                                                                                            Showlabel={false}
                                                                                            mode={''}
                                                                                            name="howMuch"
                                                                                            onChange={(v) => {
                                                                                                const numericValue = parseFloat(v?.target?.value);

                                                                                                if (!isNaN(numericValue) && isFinite(numericValue)) {
                                                                                                    const updateArry = Object.assign([], values.recomendedMedicines);
                                                                                                    updateArry[index] = {
                                                                                                        ...updateArry[index],
                                                                                                        ...{ howMuch: numericValue },
                                                                                                    };
                                                                                                    setFieldValue("recomendedMedicines", updateArry, true);
                                                                                                }
                                                                                            }}

                                                                                            FormControlClassName={'form-control'}
                                                                                            value={item?.howMuch}
                                                                                            error={errors?.recomendedMedicines?.[index]?.howMuch}
                                                                                            touched={touched?.recomendedMedicines?.[index]?.howMuch}
                                                                                            onSearchLoading={loader}
                                                                                            onBlur={() => {
                                                                                                const updateArry = Object.assign([], touched.recomendedMedicines)
                                                                                                updateArry[index] = {
                                                                                                    ...updateArry[index],
                                                                                                    ...{ howMuch: true }
                                                                                                }
                                                                                                setFieldTouched("recomendedMedicines", updateArry, true)
                                                                                            }}
                                                                                            placeholder={'Enter Quantity'} />
                                                                                    </div>

                                                                                </td>

                                                                                <td className="col-xl-2 col-md-10" key={"td4" + index}>
                                                                                    {<InputFromUser
                                                                                        type="singleSelect"
                                                                                        label=""
                                                                                        Showlabel={false}
                                                                                        mode={''}
                                                                                        disabled={IsFilledForm}
                                                                                        name="course"
                                                                                        onChange={(v) => {
                                                                                            const updateArry = Object.assign([], values.recomendedMedicines)
                                                                                            updateArry[index] = {
                                                                                                ...updateArry[index],
                                                                                                ...{ course: v }
                                                                                            }
                                                                                            setFieldValue("recomendedMedicines", updateArry, true)
                                                                                            setCourseDuration(true);

                                                                                        }}
                                                                                        FormControlClassName={'form-control'}
                                                                                        value={(index === 0 || (courseDuration && index !== 0)) ? values.recomendedMedicines[0]?.course : item?.course}

                                                                                        onSearch={(search) => {
                                                                                            if (search.length >= 3) {
                                                                                                dispatch(ActionGetMedicineList({ Query: `?name=${search}` }))
                                                                                            } else if (search.length === 0) {
                                                                                                dispatch(ActionGetMedicineList({ Query: `` }))
                                                                                            }
                                                                                        }}
                                                                                        onBlur={() => {
                                                                                            const updateArry = Object.assign([], touched.recomendedMedicines)
                                                                                            updateArry[index] = {
                                                                                                ...updateArry[index],
                                                                                                ...{ course: true }
                                                                                            }
                                                                                            setFieldTouched("recomendedMedicines", updateArry, true)
                                                                                        }}
                                                                                        error={errors?.recomendedMedicines?.[index]?.course}
                                                                                        touched={touched?.recomendedMedicines?.[index]?.course}
                                                                                        onSearchLoading={loader}
                                                                                        options={Options.CourseDuration}
                                                                                        placeholder={'Enter Course Duration'} />
                                                                                    }
                                                                                </td>

                                                                                <td className="col-xl-2 col-md-10" key={"td5" + index} >
                                                                                    {<InputFromUser
                                                                                        type="singleSelect"
                                                                                        label=""
                                                                                        disabled={IsFilledForm}
                                                                                        Showlabel={false}
                                                                                        mode={''}
                                                                                        name="anupan"
                                                                                        onChange={(v) => {
                                                                                            const updateArry = Object.assign([], values.recomendedMedicines)
                                                                                            updateArry[index] = {
                                                                                                ...updateArry[index],
                                                                                                ...{ anupan: v }
                                                                                            }
                                                                                            setFieldValue("recomendedMedicines", updateArry, true)
                                                                                        }}
                                                                                        FormControlClassName={'form-control'}
                                                                                        value={item?.anupan}
                                                                                        error={errors?.recomendedMedicines?.[index]?.anupan}
                                                                                        touched={touched?.recomendedMedicines?.[index]?.anupan}
                                                                                        onSearchLoading={loader}
                                                                                        onBlur={() => {
                                                                                            const updateArry = Object.assign([], touched.recomendedMedicines)
                                                                                            updateArry[index] = {
                                                                                                ...updateArry[index],
                                                                                                ...{ anupan: true }
                                                                                            }
                                                                                            setFieldTouched("recomendedMedicines", updateArry, true)
                                                                                        }}
                                                                                        options={Options.Anupana}
                                                                                        placeholder={'Enter Your anupan'} />
                                                                                    }
                                                                                </td>
                                                                                <td className="col-xl-2 col-md-10" key={"td6" + index}>
                                                                                    {<InputFromUser
                                                                                        type="number"
                                                                                        label=""
                                                                                        Showlabel={false}
                                                                                        mode={''}
                                                                                        name="days"
                                                                                        onChange={(v) => {
                                                                                            const updateArry = Object.assign([], values.recomendedMedicines);
                                                                                            updateArry[index] = {
                                                                                                ...updateArry[index],
                                                                                                ...{ days: v?.target?.value }
                                                                                            };
                                                                                            setFieldValue("recomendedMedicines", updateArry, true);
                                                                                            setDaysSet(true);
                                                                                        }}
                                                                                        FormControlClassName={'form-control'}
                                                                                        value={item?.days}

                                                                                        touched={touched?.recomendedMedicines?.[index]?.days}
                                                                                        onSearchLoading={loader}
                                                                                        onBlur={() => {
                                                                                            const updateArry = Object.assign([], touched.recomendedMedicines);
                                                                                            updateArry[index] = {
                                                                                                ...updateArry[index],
                                                                                                ...{ days: true }
                                                                                            };
                                                                                            setFieldTouched("recomendedMedicines", updateArry, true);
                                                                                        }}
                                                                                        placeholder={'Enter Days'}
                                                                                    />}
                                                                                </td>

                                                                                <td className="add-remove text-end align-items-center" key={"td7" + index}>
                                                                                    <Button
                                                                                        // to="#"
                                                                                        className="add-btn border-0"
                                                                                        onClick={() => {
                                                                                            const newValue = { "medicineName": "", "days": item?.days, "whenToTake": '', 'anupan': '', 'howMuch': '', };
                                                                                            setFieldValue("recomendedMedicines", [...values.recomendedMedicines, newValue])
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-plus-circle" />
                                                                                    </Button>
                                                                                    <Button
                                                                                        className="remove-btn border-0"
                                                                                        onClick={() => {
                                                                                            if (index !== 0) {
                                                                                                const updatedMedicines = [...values.recomendedMedicines];
                                                                                                updatedMedicines.splice(index, 1);
                                                                                                setFieldValue("recomendedMedicines", updatedMedicines);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <i className="fe fe-trash-2">
                                                                                            <FeatherIcon icon="trash-2" className={'mb-1'} />
                                                                                        </i>
                                                                                    </Button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Do and Dont’s*/}
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <Divider>
                                                        <div className="m-3">
                                                            <h3 className="">∬ Do and Dont’s ∬</h3>
                                                        </div></Divider>
                                                    <Row justify={"space-around"}>
                                                        <InputFromUser
                                                            disabled={IsFilledForm}
                                                            placeholder={"Select Do"}
                                                            type="multiple"
                                                            options={ConsultationMasterData?.doAndDonts && ConsultationMasterData?.doAndDonts?.map((v) => v.title) || []}
                                                            name="dos"
                                                            onChange={(v) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "dos",
                                                                        value: v
                                                                    }
                                                                });
                                                            }}
                                                            value={values.dos}
                                                            FormGroupClassName={"col-xl-5 col-md-7"}
                                                            LabelClassName={"col-form-label"}
                                                            onBlur={undefined}
                                                            label="Do"
                                                        />
                                                        <InputFromUser
                                                            disabled={IsFilledForm}
                                                            placeholder={"Select Don't"}
                                                            type="multiple"
                                                            options={ConsultationMasterData?.doAndDonts && ConsultationMasterData?.doAndDonts?.map((v) => v.title) || []}
                                                            name="donts"
                                                            onChange={(v) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "donts",
                                                                        value: v
                                                                    }
                                                                });
                                                            }}
                                                            value={values.donts}
                                                            FormGroupClassName={"col-xl-5 col-md-7"}
                                                            LabelClassName={"col-form-label"}
                                                            onBlur={undefined}
                                                            label="Don't"
                                                        />
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Notes*/}
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <Divider>
                                                        <div className="m-3">
                                                            <h3 className="">∬ Notes ∬</h3>
                                                        </div></Divider>
                                                    {values.doctorNotes.map((note, index) => (
                                                        <div key={index}>
                                                            <textarea
                                                                rows={4}
                                                                disabled={IsFilledForm}
                                                                cols={5}
                                                                className="form-control my-2"
                                                                placeholder="Enter NotedateOfMedicalStores"
                                                                value={note}
                                                                onBlur={(e) => handleBlur(`doctorNotes[${index}]`)}
                                                                onChange={(e) => {
                                                                    const newNotes = [...values.doctorNotes];
                                                                    newNotes[index] = e.target.value;
                                                                    setFieldValue('doctorNotes', newNotes);
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                    <div className="row">
                                                        <Button
                                                            onClick={() => {
                                                                setFieldValue('doctorNotes', [...values.doctorNotes, '']);
                                                            }}
                                                            className="col-3 m-3"
                                                        >
                                                            Add Notes
                                                        </Button>
                                                        {values.doctorNotes.length > 1 && <Button
                                                            onClick={() => {
                                                                const newNotes = [...values.doctorNotes];
                                                                newNotes.pop();
                                                                setFieldValue('doctorNotes', newNotes);
                                                            }}
                                                            className="col-3 m-3"
                                                        >
                                                            Remove Last Notes
                                                        </Button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Next consultation Date*/}
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <Divider>
                                                        <div className="m-3">
                                                            <h3 className="">∬ Next consultation Date ∬</h3>
                                                        </div></Divider>
                                                    <InputFromUser
                                                        disabled={IsFilledForm}
                                                        placeholder={"Enter Date"}
                                                        type="date"
                                                        name="dateOfMedicalStore"
                                                        LabelClassName={"col-form-label"}
                                                        onBlur={undefined}
                                                        label="Next Consultation Date"
                                                        FormGroupClassName={"col"}
                                                        error={errors?.dateOfMedicalStore}
                                                        value={values?.dateOfMedicalStore}
                                                        onChange={(v) => {
                                                            setFieldValue('dateOfMedicalStore', v)
                                                            setFieldValue('nextConsultationDate', v)
                                                        }}
                                                        FormControlClassName={'form-control'}
                                                        touched={touched?.dateOfMedicalStore}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Virechan And Exercise*/}
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <Divider>
                                                        <div className="m-3">
                                                            <h3 className="">∬ Virechan And Exercise ∬</h3>
                                                        </div></Divider>
                                                    <Row justify={"space-around"}>
                                                        <InputFromUser
                                                            disabled={IsFilledForm}
                                                            placeholder={"Enter Your Virechan"}
                                                            type="multiple"
                                                            options={ConsultationMasterData?.virechans && ConsultationMasterData?.virechans?.map((v) => v.title) || []}
                                                            name="virechan"
                                                            onChange={(v) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "virechan",
                                                                        value: v
                                                                    }
                                                                });
                                                            }}
                                                            FormGroupClassName={"col-xl-4 col-md-7 "}
                                                            LabelClassName={"col-form-label"}
                                                            onBlur={undefined}
                                                            label="Virechan"
                                                            value={values.virechan}

                                                        />
                                                        <InputFromUser
                                                            disabled={IsFilledForm}
                                                            placeholder={"Enter Your Exercise"}
                                                            type="multiple"
                                                            options={ConsultationMasterData?.exercises && ConsultationMasterData?.exercises?.map((v) => v.title) || []}
                                                            name="exercise"
                                                            onChange={(v) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "exercise",
                                                                        value: v
                                                                    }
                                                                });
                                                            }}
                                                            value={values.exercise}
                                                            FormGroupClassName={"col-xl-4 col-md-7"}
                                                            LabelClassName={"col-form-label"}
                                                            onBlur={undefined}
                                                            label="Exercise"
                                                        />
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {matchPermissionsForUser('medical.create') &&
                                        <div className="row d-flex justify-content-end">
                                            <Button className="col-2 m-4" type="primary" style={{ alignSelf: "flex-end" }} disabled={isSubmitting} onClick={() => handleSubmit()}>Submit</Button>
                                        </div>
                                    }
                                </div>
                            )
                        }}
                    </Formik >

                </div >
                {/* </div > */}
            </>
        </div >
    );
};

export default AppointmentDetails;