import { GET_ALL_THERAPIST_HISTORY, GET_ALL_THERAPIST_HISTORY_BY_ID, GET_ALL_THERAPIST_HISTORY_BY_ID_SUCCESS, GET_ALL_THERAPIST_HISTORY_SUCCESS, GET_THERAPIST_CSV_DATA, GET_THERAPIST_CSV_DATA_SUCCESS } from "../storeTypes";

export const ActionGetTherapistHistory = (payload, callBack) => {
    return {
        type: GET_ALL_THERAPIST_HISTORY,
        payload,
        callBack
    };
};
export const ActionGetTherapistHistorySuccess = (payload) => {
    return {
        type: GET_ALL_THERAPIST_HISTORY_SUCCESS,
        payload
    };
};
export const ActionGetTherapistHistoryById = (payload, callBack) => {
    return {
        type: GET_ALL_THERAPIST_HISTORY_BY_ID,
        payload,
        callBack
    };
};
export const ActionGetTherapistHistoryByIdSuccess = (payload) => {
    return {
        type: GET_ALL_THERAPIST_HISTORY_BY_ID_SUCCESS,
        payload
    };
};
export const ActionGetTherapistCsvData = (payload, callBack) => {
    return {
        type: GET_THERAPIST_CSV_DATA,
        payload,
        callBack
    };
};
export const ActionGetTherapistCsvDataSuccess = (payload) => {
    return {
        type: GET_THERAPIST_CSV_DATA_SUCCESS,
        payload
    };
};