/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */

import { Button, Modal } from "antd";
import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import { Breadcrumb } from "../../../components/Card/Cards";
import { InputFromUser } from "../../../components/Input/input";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from 'moment';
import { ActionGetTherapistCount } from "../../../store/Actions/MasterDataAction";
import { ActionCreateByUrl } from "../../../store/Actions/CommonAction";

const { confirm } = Modal;

const TherapistCount = () => {
    const { therapistSchedule, loader } = useSelector(state => state?.Staff);
    const { therapistCount } = useSelector(state => state?.MasterData);
    const [Count, setCount] = useState({ male: 0, female: 0 });
    const currentDate = new Date()
    const dispatch = useDispatch();
    const [Refresh, SetRefresh] = useState(false)

    const [showModal, SetModal] = useState({ type: '', is: false }),
        [calenderevent, setcalenderevent] = useState(""),
        defaultEvents = therapistSchedule || [];
    useEffect(() => {
        let elements = Array.from(
            document.getElementsByClassName("react-datepicker-wrapper")
        );
        elements.map((element) => element.classList.add("width-100"));
    }, []);

    const handleEventClick = (clickInfo) => {

        if (clickInfo.event.backgroundColor === "yellow") {

            setcalenderevent(clickInfo.event);
            // console.log(clickInfo.event);
            setCount({ male: clickInfo?.event?.extendedProps?.maleCount, female: clickInfo?.event?.extendedProps?.femaleCount })
            SetModal({ type: moment(clickInfo?.event?.start).format('YYYY-MM-DD'), is: true })
        } else {

            return;
        }
    };

    const formatDate = (year, month, day) => {
        const formattedMonth = String(month + 1).padStart(2, '0');
        const formattedDay = String(day).padStart(2, '0');
        return `${year}-${formattedMonth}-${formattedDay}`;
    };

    const getYearDates = () => {
        const currentDate = new Date();
        const nextYear = currentDate.getFullYear() + 1;
        const endDate = new Date(nextYear, currentDate.getMonth(), currentDate.getDate());
        const dates = [];

        for (let date = currentDate; date <= endDate; date.setDate(date.getDate() + 1)) {
            dates.push(formatDate(date.getFullYear(), date.getMonth(), date.getDate()));
        }

        return dates;
    };
    const allYearDates = getYearDates();

    const mergedData = allYearDates?.map(date => {
        // Check if the date is already present in the data array
        const existingShift = therapistCount?.[0]?.therapistCount?.find(shift => shift.date === date);
        if (existingShift) {
            return existingShift;
        } else {
            // If the date is not present, use default counts
            return {
                date,
                maleCount: therapistCount?.[0]?.generalCount?.maleCount,
                femaleCount: therapistCount?.[0]?.generalCount?.femaleCount
            };
        }
    });

    const updatedShifts = mergedData?.map(shift => {
        const updatedShift = { ...shift };
        updatedShift.title = `Male-${shift.maleCount} Female-${shift.femaleCount}`;
        updatedShift.maleCount = shift.maleCount;
        updatedShift.femaleCount = shift.femaleCount;
        updatedShift.backgroundColor = "yellow";
        updatedShift.textColor = "black";
        return updatedShift;
    });

    const mergedData2 = allYearDates?.map(date => {
        // Check if the date is already present in the data array
        const existingShift = therapistCount?.[0]?.therapistWatcherCount?.find(shift => shift.date === date);
        if (existingShift) {
            return existingShift;
        } else {
            // If the date is not present, use default counts
            return {
                date,
                maleCount: therapistCount?.[0]?.generalCount?.maleCount,
                femaleCount: therapistCount?.[0]?.generalCount?.femaleCount,

            };
        }
    });

    const updatedShifts2 = mergedData2?.map(shift => {
        const updatedShift = { ...shift };
        updatedShift.title = `W :Male-${shift.maleCount} Female-${shift.femaleCount}`;
        updatedShift.maleCount = shift.maleCount;
        updatedShift.femaleCount = shift.femaleCount;
        updatedShift.backgroundColor = "blue";
        updatedShift.textColor = "white";

        return updatedShift;
    });
    useEffect(() => {
        dispatch(ActionGetTherapistCount())
        SetRefresh(false)
    }, [Refresh])
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <Breadcrumb page={[{
                                name: 'Master Data',
                                linkTo: '/therapist-count'
                            },
                            {
                                name: 'Therapist Count',
                                linkTo: '/therapist-count'
                            }
                            ]} />
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">

                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div id="calendar">
                                                                    <FullCalendar
                                                                        // ref={ref}
                                                                        plugins={[
                                                                            dayGridPlugin,
                                                                            timeGridPlugin,
                                                                            interactionPlugin,
                                                                        ]}
                                                                        headerToolbar={{
                                                                            left: "prev,next today",
                                                                            center: "title",
                                                                            right: "dayGridMonth,timeGridWeek,timeGridDay",
                                                                        }}
                                                                        initialView="dayGridMonth"
                                                                        editable={false}
                                                                        selectable={true}
                                                                        selectMirror={true}
                                                                        events={[...updatedShifts, ...updatedShifts2]}
                                                                        dayMaxEvents={true}
                                                                        weekends={true}
                                                                        // select={(clickInfo) => handleDateSelect(clickInfo)}
                                                                        eventClick={(clickInfo) => handleEventClick(clickInfo)}
                                                                    />
                                                                </div>
                                                                <Modal
                                                                    open={showModal?.is}
                                                                    closable={true}
                                                                    className='ant-modal'
                                                                    forceRender={true}
                                                                    zIndex={1050}
                                                                    style={{ margin: 10 }}
                                                                    onOk={() => {
                                                                        SetModal({ type: showModal?.type, is: false });
                                                                        SetRefresh(true)
                                                                    }}
                                                                    styles={{
                                                                        body: {
                                                                            overflow: "hidden",
                                                                        },
                                                                        mask: {
                                                                            backdropFilter: 'blur(10px)',
                                                                        },
                                                                        content: {
                                                                            borderRadius: 40,
                                                                        },
                                                                    }}
                                                                    okText={'Submit'}
                                                                    centered
                                                                    confirmLoading={false}
                                                                    onCancel={() => {
                                                                        SetModal({ type: showModal?.type, is: false });
                                                                        SetRefresh(true)
                                                                    }}
                                                                    closeIcon={true}
                                                                    footer={false}
                                                                    title={showModal?.type}
                                                                >
                                                                    <div >
                                                                        <div className="row">
                                                                            <InputFromUser
                                                                                type="number"
                                                                                label="Add Male Count"
                                                                                FormControlClassName={'form-control'}
                                                                                FormGroupClassName={"col-xl-12 col-md-12"}
                                                                                LabelClassName={"col-form-label"}
                                                                                Showlabel={false}
                                                                                name="Count"
                                                                                onChange={(v) => setCount({ male: v?.target?.value, female: Count.female })}
                                                                                value={Count.male}
                                                                                error={null}
                                                                                touched={null}
                                                                                placeholder={'Enter Count'} />

                                                                            <InputFromUser
                                                                                type="number"
                                                                                label="Add Female Count"
                                                                                FormControlClassName={'form-control'}
                                                                                FormGroupClassName={"col-xl-12 col-md-12"}
                                                                                LabelClassName={"col-form-label"}
                                                                                Showlabel={false}
                                                                                name="Count"
                                                                                onChange={(v) => setCount({ male: Count.male, female: v?.target?.value })}
                                                                                value={Count.female}
                                                                                error={null}
                                                                                touched={null}
                                                                                placeholder={'Enter Count'} />
                                                                            <div>
                                                                                <Button onClick={() => {
                                                                                    dispatch(ActionCreateByUrl({
                                                                                        Query: 'admin/therapist-count', obj: {
                                                                                            "date": showModal?.type,
                                                                                            "maleCount": Count.male || 0,
                                                                                            "femaleCount": Count.female || 0
                                                                                        }
                                                                                    }, (v) => {
                                                                                        if (v.statusCode === 200) {
                                                                                            SetModal({ type: showModal?.type, is: false });
                                                                                            SetRefresh(true)
                                                                                        }
                                                                                    }))
                                                                                }} className="mx-3">Submit</Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Modal>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};

export default TherapistCount;