/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect, useMemo } from "react";

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Popover } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import queryString from 'query-string';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ResponsiveComponent } from "../../utils/Funcations";
import TableColumnSearch from '../../components/Input/TableColumnSearch';
import Sidebar from "../../components/Sidebar";
import { CustomTable } from "../../components/Table";
import {
    plusicon,
    refreshicon
} from "../../components/imagepath";
import { ActionDeletePrice, ActionGetAllPriceList } from "../../store/Actions/PriceAction";
import Header from "../../components/Header";
import { matchPermissions } from "../../utils/Permission";
const { confirm } = Modal;

const PriceTable = () => {
    const { loader, consultationPrice } = useSelector(state => state?.PriceTable);
    const { userDetails } = useSelector(state => state?.auth);
    const [showArrow, setShowArrow] = useState(true);
    const [Refresh, setRefresh] = useState(false);
    const [arrowAtCenter, setArrowAtCenter] = useState(false);
    const [IsSearching, setIsSearching] = useState(false);
    const pageSize = 10;
    const dispatch = useDispatch();
    const location = useLocation();
    const params = queryString.parse(location.search);
    const Size = ResponsiveComponent()
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [SearchList, setSearchList] = useState([]);
    const mergedArrow = useMemo(() => {
        if (arrowAtCenter)
            return {
                pointAtCenter: true,
            };
        return showArrow;
    }, [showArrow, arrowAtCenter]);
    const handleSearch = (selectedKeys, dataIndex) => {
        setSearchText(selectedKeys?.[0]);
        setSearchedColumn(dataIndex);

        if (params[dataIndex]) {
            window.location.replace(`/price-table?page=1&filterlist=true&${dataIndex}=${selectedKeys?.[0]}`)
        } else {
            window.location.replace(`/price-table?${location.search}&${dataIndex}=${selectedKeys?.[0]}`)
        }

    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        window.location.replace(`/price-table`)
    }
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('consultation.update');
    const canDelete = matchPermissionsForUser('consultation.delete');
    const canCerate = matchPermissionsForUser('consultation.create');
    const content = (item) => (
        <div className="">
            {canUpdate &&
                <Button className="m-2" onClick={() => EditList(item?.id)}>Edit</Button>
            }
            {canDelete &&
                <Button onClick={() => showDeleteConfirm(item)}>Delete</Button>
            }
        </div>
    );
    const column = [
        {
            title: "Sr",
            dataIndex: "sr",
            // key: "Sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Consultation Type",
            dataIndex: "consultationType",
            render: (text, record) => (

                <span>{record.consultationType}</span>
            ),
            ...TableColumnSearch({ dataIndex: 'docs.name', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },
        {
            title: "Total Minute",
            dataIndex: "totalMinute",
            render: (text, record) => (

                <span>{record.totalMinute}</span>
            ),
            ...TableColumnSearch({ dataIndex: 'docs.name', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },
        {
            title: "Price",
            dataIndex: "price",
            render: (text, record) => (

                <span>{record.price}</span>
            ),
            ...TableColumnSearch({ dataIndex: 'docs.name', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },

        {
            title: !canUpdate && !canDelete ? null : "Action",
            dataIndex: "id",
            key: "action",
            render: (text, record, num) => {
                if (!canUpdate && !canDelete) {
                    return null
                } else {
                    return (
                        <Popover placement="left" title={record.title} content={() => content(record)} arrow={mergedArrow}>
                            <Button type="dashed">• • •</Button>
                        </Popover>
                    )
                }
            },
        },
    ];
    // const OrderIndex = params?.orderIndex ? `&orderIndex=${params?.orderIndex}` : ''
    // const Title = params?.title ? `&title=${params?.title}` : ''
    // const Final = OrderIndex + Title || ''

    useEffect(() => {
        dispatch(ActionGetAllPriceList({ Query: `?page=${params?.page || 1}` }));
        setRefresh(false);
    }, [dispatch, params?.page, Refresh])

    const handleSearch1 = (searchTerm) => {
        var newp = params
        newp.page = searchTerm
        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
    }

    const EditList = (searchTerm) => {

        window.location.href = `/price-table/add-price?update=true&&id=${searchTerm}&&page=${parseInt(params.page)}`
    }

    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.id}?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeletePrice("/" + item?.id, (Res) => {
                    if (Res === 200) {
                        setRefresh(true);
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };

    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/price-table">Price</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Price List</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Price List</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">
                                                                    {canCerate &&
                                                                        <Link
                                                                            to="/price-table/add-price"
                                                                            className="btn btn-primary add-pluss ms-2"
                                                                        >
                                                                            <img src={plusicon} alt="#" />
                                                                        </Link>
                                                                    }

                                                                    <Link
                                                                        onClick={() => window.location.reload()}
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /Table Header */}
                                            <CustomTable
                                                CurrentPageNum={parseInt(params?.page) || 1}
                                                OnChange={(v) => {
                                                    handleSearch1(v)
                                                }}
                                                TotalPageList={consultationPrice?.totalDocs}
                                                column={column}
                                                dataSource={consultationPrice?.docs}
                                                dataSourceLength={consultationPrice?.docs?.length}
                                                pageSize={10}
                                                loader={loader}
                                                ShowHeader={true}
                                                Header={<div className="staff-search-table">
                                                </div>}
                                                key={"hi"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};

export default PriceTable;