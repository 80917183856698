import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { PrakurtiLogoWhite } from "../../components/imagepath";
import { Formik } from "formik";
import { ForgotPasswordSchema } from "../../utils/FormValidation";
import { InputFromUser } from "../../components/Input/input";
import { ActionCreateByUrl } from "../../store/Actions/CommonAction";





const ForgotPassword = () => {
    const dispatch = useDispatch();
    return (
        <>
            {/* Main Wrapper */}
            <div className="main-wrapper login-body">
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-lg-6 login-wrap">
                            <div className="login-sec">

                            </div>
                        </div>
                        {/* /Login logo */}
                        {/* Login Content */}
                        <div className="col-lg-6 login-wrap-bg">
                            <div className="login-wrapper">
                                <div className="loginbox">
                                    <div className="login-right">
                                        <div className="login-right-wrap">
                                            <div className="account-logo">
                                                <Link to="/admin-dashboard">
                                                    <img src={PrakurtiLogoWhite} alt="#" />
                                                </Link>
                                            </div>
                                            <h2>Reset Password</h2>
                                            <Formik
                                                initialValues={{ email: '' }}
                                                validationSchema={ForgotPasswordSchema}
                                                onSubmit={(values, { setErrors, setSubmitting }) => {
                                                    setSubmitting(true)
                                                    dispatch(ActionCreateByUrl({
                                                        Query: 'adminUser/forgot_password', obj: {
                                                            'email': values?.email
                                                        }
                                                    }, (response) => {
                                                        if (response?.statusCode == 400) {
                                                            setErrors(response.data.errors);
                                                        } else if (response?.statusCode == 401) {
                                                            setErrors(response.data.errors);
                                                        } else if (response?.statusCode == 200) {
                                                            window.location.href = "/"
                                                        }
                                                    }))
                                                    setSubmitting(false)
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    isSubmitting,
                                                }) => (
                                                    <form onSubmit={handleSubmit}>
                                                        <InputFromUser
                                                            label="Email"
                                                            name="email"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.email}
                                                            value={values.email}
                                                            touched={touched.email}
                                                            placeholder={'Enter Your Email'}
                                                        />

                                                        <div className="form-group login-btn" >
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary btn-block"
                                                                disabled={isSubmitting}
                                                            >
                                                                Reset Password
                                                            </button>
                                                        </div>

                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default ForgotPassword;
