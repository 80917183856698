import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import { refreshicon } from '../../components/imagepath'
import { CustomTable } from '../../components/Table'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { ActionGetTherapistCsvData } from '../../store/Actions/TherapistAllocationAction'
import Paragraph from 'antd/es/typography/Paragraph'
import { CSVLink } from 'react-csv'

const TherapistHistory = () => {
    const dispatch = useDispatch();
    const [Refresh, setRefresh] = useState(false);
    const { therapistCsvData } = useSelector(state => state?.TherapistHistory);
    console.log('chk16', therapistCsvData[0]);
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        dispatch(ActionGetTherapistCsvData());
        setRefresh(false);
    }, [Refresh, dispatch])

    const columns = [

        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
            render: (customerName) => <div>{customerName || '---'}</div>,

        },
        {
            title: 'Therapist Shift',
            dataIndex: 'shift',
            key: 'shift',
            render: (shift) => <div>{shift || '---'}</div>,

        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date) => <div>{date || '---'}</div>,

        },
        {
            title: 'Room Name',
            dataIndex: 'roomName',
            key: 'roomName',
            render: (text, record) => {
                const roomName = record?.treatmentSlotId?.roomId?.roomName || record?.solutionSlotId?.roomId?.roomName || '---';
                return <div>{roomName}</div>;
            },
        },
        {
            title: 'Treatment Name',
            dataIndex: 'treatmentSlotId',
            key: 'treatmentSlotId',
            render: (treatmentSlotId) => (
                <>
                    {treatmentSlotId &&
                        treatmentSlotId?.treatmentData?.length > 0 ? (
                        treatmentSlotId?.treatmentData?.map((data) => (
                            <div key={data?._id}>{data?.treatmentName}</div>
                        ))
                    ) : (
                        <div>---</div>
                    )}
                </>
            ),
        },
        {
            title: 'Detox-Programme Name',
            dataIndex: 'solutionSlotId',
            key: 'solutionSlotId',
            render: (solutionSlotId) => (
                <>
                    {solutionSlotId &&
                        solutionSlotId?.solutionData?.length > 0 ? (
                        solutionSlotId?.solutionData?.map((data) => (
                            <div key={data?._id}>{data?.solutionName}</div>
                        ))
                    ) : (
                        <div>---</div>
                    )}
                </>
            ),
        },
        {
            title: 'Medi-Day Care Name',
            dataIndex: 'serivceSlotId',
            key: 'serivceSlotId',
            render: (serivceSlotId) => (
                <>
                    {serivceSlotId &&
                        serivceSlotId?.serviceData?.length > 0 ? (
                        serivceSlotId?.serviceData?.map((data) => (
                            <div key={data?._id}>{data?.name}</div>
                        ))
                    ) : (
                        <div>---</div>
                    )}
                </>
            ),
        },
    ];
    useEffect(() => {
        const allTherapistData = Object.values(therapistCsvData[0] || []);
        const newData = allTherapistData.flatMap(therapist => therapist);
        const therapistCsv = newData.map(item => ({
            "Therapist Name": item.therapistName || '---',
            "Customer Name": item.customerName || '---',
            "Therapist Shift": item.shift || '---',
            "Date": item.date || '---',
            "Room Name": item.treatmentSlotId?.roomId?.roomName || item.solutionSlotId?.roomId?.roomName || '---',
            "Treatment Name": item.treatmentSlotId?.treatmentData?.map(data => data?.treatmentName || '---').join(','),
            "Detox-Programme Name": item.solutionSlotId?.solutionData?.map(data => data?.solutionName || '---').join(','),
            "Medi-Day Care Name": item.serivceSlotId?.serviceData?.map(data => data?.name || '---').join(',')
        }));

        // Check for empty values and replace them with '---'
        const finalCsvData = therapistCsv.map(item => {
            for (const key in item) {
                if (!item[key] || (Array.isArray(item[key]) && item[key].length === 0)) {
                    item[key] = '---';
                }
            }
            return item;
        });

        setCsvData([...finalCsvData]);
    }, []);
    console.log('csv', csvData);
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="">Therapist Allocation</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Therapist History List</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card card-table show-entire">
                                    <div className="card-body">
                                        <div className="page-table-header mb-2">
                                            <div className="row align-items-center">
                                                <div className="col-9">
                                                    <div className="doctor-table-blk">
                                                        <h3>Therapist History List</h3>
                                                        <div className="doctor-search-blk">
                                                            <div className="add-group">

                                                                <Link
                                                                    onClick={() => window.location.reload()}
                                                                    className="btn btn-primary doctor-refresh ms-2"
                                                                >
                                                                    <img src={refreshicon} alt="#" />
                                                                </Link>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="col-3">
                                                    <Button className='btn btn-primary mx-3'>Re-Generate</Button>
                                                    <CSVLink
                                                        data={csvData}
                                                        filename={"therapist-history.csv"}
                                                        className='btn btn-primary'
                                                    >
                                                        Download CSV
                                                    </CSVLink>
                                                    {/* <Button className='btn btn-primary'>Download CSV</Button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {console.log('chk359', Data[0]?.['Therapist 3-M']?.map((values) => values?.shift))} */}
                                    {Object.keys(therapistCsvData[0] || [])?.map((therapist, index) => <div key={index} className="table-responsive mt-3">
                                        <Paragraph className='text-center mb-0 fw-bold' style={{ textTransform: 'capitalize' }}>{therapist}</Paragraph>

                                        <CustomTable
                                            key={index}
                                            showPagination={false}
                                            column={columns}
                                            dataSource={therapistCsvData[0][therapist]}
                                            ShowHeader={true}
                                            Header={<div className="staff-search-table">
                                            </div>} />
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default TherapistHistory