import { GET_PERMISSIONS_LIST_SUCCESS, GET_PERMISSIONS_LIST, CREATE_PERMISSIONS, GET_PAGINATED_PERMISSIONS_LIST_SUCCESS, GET_PAGINATED_PERMISSIONS_LIST } from "../storeTypes";

const INIT_STATE = {
    loader: false,
    PermissionsList: [],
    PaginatedPermissionsList: [],
};

function PermissionReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case GET_PERMISSIONS_LIST: {
            return {
                ...state,
                // PermissionsList: [],
                loader: true,
            };
        }
        case GET_PERMISSIONS_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                PermissionsList: action.payload,
            };
        }
        case GET_PAGINATED_PERMISSIONS_LIST: {
            return {
                ...state,
                PaginatedPermissionsList: [],
                loader: true,
            };
        }
        case GET_PAGINATED_PERMISSIONS_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                PaginatedPermissionsList: action.payload,
            };
        }
        case CREATE_PERMISSIONS: {
            return {
                ...state,
                loader: true,
            };
        }
        default: {
            return state;
        }
    }
}

export default PermissionReducer;
