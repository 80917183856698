import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { CustomTable } from '../../components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { GetPatientConfirmationDetail } from '../../store/Actions/PatientAction';
import queryString from 'query-string';
import { DOCS_URL } from '../../utils/globals';
import { pdficon, refreshicon } from '../../components/imagepath';


const Patientconfirmation = () => {
    const pageSize = 10;
    const dispatch = useDispatch();
    const params = queryString.parse(location.search);
    const { customerConfirmation, loader } = useSelector(state => state?.patient);
    useEffect(() => {
        dispatch(GetPatientConfirmationDetail({ Query: `?page=${params?.page || 1}` }))
    }, [dispatch])

    const Columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Customer Name",
            dataIndex: "customerName",

        },
        {
            title: "Contact No",
            dataIndex: "emergencyNumber",

        },
        {
            title: "Guest Name",
            dataIndex: "guestName",

        },
        {
            title: "Vehicle No",
            dataIndex: "vehicleNo",

        },
        {
            title: "Contact No",
            dataIndex: "emergencyNumber",

        },
        {
            title: "Arrival Time",
            dataIndex: "arrivalTime",

        },
        {
            title: "Arriving From",
            dataIndex: "arrivingFrom",

        },
        {
            title: "Aadhar Cards",
            dataIndex: "aadharCards",
            render: (text, record) => (
                <div>
                    {record?.aadharCards && record?.aadharCards?.map((aadharCard, i) => (
                        <div key={i} className='mb-1'>
                            <img src={pdficon} alt="#" />
                            <a href={DOCS_URL + `${aadharCard}`} >
                                {aadharCard}
                            </a>
                        </div>
                    ))}
                </div>
            ),
        },
    ]
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="">Customer Confirmation</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Customer Confirmation List</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Customer Confirmation List</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">

                                                                    <Link
                                                                        onClick={() => window.location.reload()}
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <CustomTable
                                                    CurrentPageNum={customerConfirmation?.[0]?.page}
                                                    OnChange={(v) => {
                                                        var newp = params
                                                        newp.page = v
                                                        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
                                                    }}
                                                    TotalPageList={customerConfirmation?.[0]?.totalDocs}
                                                    column={Columns}
                                                    dataSource={customerConfirmation?.[0]?.docs}

                                                    dataSourceLength={customerConfirmation?.[0]?.docs?.length}
                                                    pageSize={10}
                                                    loader={loader}
                                                    ShowHeader={true}
                                                    Header={<div className="staff-search-table">

                                                    </div>}
                                                    key={"hi"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default Patientconfirmation