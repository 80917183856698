/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { Formik } from "formik";
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, DatePicker } from "antd";
import { InputFromUser } from "../../components/Input/input";
import UploadImage from "../../components/Upload/Upload";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { SolutionValidationSchema } from "../../utils/FormValidation";
import { getAdminuUerDetails } from "../../store/Actions/AuthActions";
import { CreateSolution, GetAllsolution } from "../../store/Actions/ServiceAction";
import { ActionUpdateById, ActionUploadImage } from "../../store/Actions/CommonAction";
import { ExpendableTable } from "../../components/Table/expendableTable";
import { isNullOrUndef } from "../../utils/Funcations";
import { DOCS_URL } from "../../utils/globals";
const { RangePicker } = DatePicker;

const AddEditSolution = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const { userDetails } = useSelector(state => state?.auth);
    const { solutionList, loader } = useSelector(state => state?.Service);
    const userId = userDetails?.[0]?._id
    const idValue = searchParams.get("id");
    const idValue2 = searchParams.get("page");
    const updateValue = searchParams.get("update");
    useEffect(() => {
        if (idValue) {
            dispatch(GetAllsolution({ Query: '/' + idValue }));
        }
    }, [idValue])
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])

    return (
        <div>
            <Header />
            <Sidebar id="menu-item1" id1="menu-items1" activeClassName="add-staff" />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/solution-list?page=1">Solution </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Add Solution</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* /Page Header */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Formik
                                            enableReinitialize={updateValue || false}
                                            initialValues={{
                                                name: solutionList?.[0]?.name || {
                                                    en: "",
                                                    hi: "",
                                                    mr: ""
                                                },
                                                description: solutionList?.[0]?.description || {
                                                    en: "",
                                                    hi: "",
                                                    mr: ""
                                                },
                                                shortDescription: solutionList?.[0]?.shortDescription || {
                                                    en: "",
                                                    hi: "",
                                                    mr: ""
                                                },
                                                perDayPrice: solutionList?.[0]?.perDayPrice || "",
                                                rating: solutionList?.[0]?.rating || "",
                                                successRate: solutionList?.[0]?.successRate || "",
                                                mainTitle: solutionList?.[0]?.mainTitle || {
                                                    en: "",
                                                    hi: "",
                                                    mr: ""
                                                },
                                                facilities: solutionList?.[0]?.facilities || [''],
                                                facilitiesAfterDescription: solutionList?.[0]?.facilitiesAfterDescription || [''],
                                                statics: solutionList?.[0]?.statics || [{ "data": '', "name": '' }],
                                                whatWeDo: solutionList?.[0]?.whatWeDo || [{ "title": "", "description": "", "icon": '', "orderIndex": '' }],
                                                ourProcess: solutionList?.[0]?.ourProcess || {
                                                    title: "",
                                                    description: "",
                                                    processes: [
                                                        {
                                                            sideImage: "",
                                                            mainTitle: "",
                                                            title: "",
                                                            description: "",
                                                            orderIndex: "",
                                                        }
                                                    ]
                                                },
                                                benefits: solutionList?.[0]?.benefits || [{ "title": "", "description": "", "icon": '1232', "orderIndex": '' }],
                                                listingViewImage: solutionList?.[0]?.listingViewImage || "",
                                                orderIndex: solutionList?.[0]?.orderIndex || "",
                                                dayLookTitle: solutionList?.[0]?.dayLookTitle || '',
                                                dayLookDescription: solutionList?.[0]?.dayLookDescription || '',
                                                dayLookMainImage: solutionList?.[0]?.dayLookMainImage || '',
                                                dayLook: solutionList?.[0]?.dayLook || [{ "startTime": "", "endTime": '', "description": '', "orderIndex": '', "bannerImage": '' }],
                                                whyChooseUs: solutionList?.[0]?.whyChooseUs || {
                                                    mainTitle: "",
                                                    chooseUs: [
                                                        {
                                                            aspect: "",
                                                            otherHealthResort: "",
                                                            naturopathy: "",
                                                            allopathy: "",
                                                            prakrutisAyurvedicTreatment: "",
                                                            orderIndex: ""
                                                        }
                                                    ],
                                                    facilities: solutionList?.[0]?.whyChooseUs?.facilities || [""]
                                                },
                                                days: solutionList?.[0]?.days || [""],
                                                price: solutionList?.[0]?.price || [""],
                                                solutionFaqs: solutionList?.[0]?.solutionFaqs || [{ "title": "", "description": "", "orderIndex": '' }],

                                                createdBy: userId,
                                                videoIframe: solutionList?.[0]?.videoIframe?.[0] || "",
                                                type: "hello",
                                            }}
                                            validationSchema={SolutionValidationSchema}
                                            onSubmit={(values, { setErrors, setSubmitting, }) => {
                                                const formattedValues = {
                                                    ...values,
                                                    videoIframe: values.videoIframe ? [values?.videoIframe] : []
                                                };
                                                if (updateValue && idValue != null) {
                                                    dispatch(ActionUpdateById({ Query: `admin/solution/${idValue}`, obj: formattedValues }
                                                        , (Res) => {
                                                            if (Res?.statusCode === 200) {
                                                                window.location.href = `/solution-list?page=1`
                                                            } else if (Res?.statusCode === 400) {
                                                                setErrors(Res?.data?.errors);
                                                            }
                                                        }));
                                                    setSubmitting(false);
                                                } else {
                                                    //CreateTreatment
                                                    dispatch(CreateSolution(values, (Response) => {
                                                        if (Response?.statusCode === 200) {
                                                            window.location.href = `/solution-list?page=1`
                                                        }

                                                    }))
                                                    setSubmitting(false);
                                                }
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                setFieldValue,
                                                setFieldTouched,
                                                handleChange,
                                                handleBlur,
                                                isValid,
                                                handleSubmit,
                                                setValues,
                                                setSubmitting,
                                                isSubmitting,
                                                /* and other goodies */
                                            }) => {
                                                return (
                                                    <div>
                                                        <div className="row">
                                                            <div className="row">
                                                                <Divider style={{ fontWeight: "600" }}>Solution</Divider></div>
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Enter Your Solution Name"}
                                                                FormControlClassName={'form-control '}
                                                                label="Solution Name"
                                                                name="name.en"
                                                                error={errors?.name?.en}
                                                                touched={touched?.name?.en}
                                                                value={values?.name?.en}
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.name
                                                                    UpdateValue.en = v?.target?.value
                                                                    setFieldValue("name", UpdateValue, true)
                                                                }}
                                                                onBlur={handleBlur}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Marathi"}
                                                                FormControlClassName={'form-control '}
                                                                label="*Marathi"
                                                                name="name.mr"
                                                                value={values?.name?.mr}
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.name
                                                                    UpdateValue.mr = v?.target?.value
                                                                    setFieldValue("name", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.name?.mr}
                                                                touched={touched?.name?.mr}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Hindi"}
                                                                FormControlClassName={'form-control '}
                                                                value={values?.name?.hi}
                                                                label="*Hindi"
                                                                name="name.hi"
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.name
                                                                    UpdateValue.hi = v?.target?.value
                                                                    setFieldValue("name", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.name?.hi}
                                                                touched={touched?.name?.hi}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Enter Your Service Descriptions"}
                                                                FormControlClassName={'form-control '}
                                                                label="Description"
                                                                value={values?.description?.en}
                                                                name="descriptions.en"
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.description
                                                                    UpdateValue.en = v?.target?.value
                                                                    setFieldValue("description", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.description?.en}
                                                                touched={touched?.description?.en}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Marathi"}
                                                                FormControlClassName={'form-control '}
                                                                label="*Marathi"
                                                                name="description.mr"
                                                                value={values?.description?.mr}
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.description
                                                                    UpdateValue.mr = v?.target?.value
                                                                    setFieldValue("description", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.description?.mr}
                                                                touched={touched?.description?.mr}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Hindi"}
                                                                FormControlClassName={'form-control'}
                                                                label="*Hindi"
                                                                value={values?.description?.hi}
                                                                name="description.hi"
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.description
                                                                    UpdateValue.hi = v?.target?.value
                                                                    setFieldValue("description", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.description?.hi}
                                                                touched={touched?.description?.hi}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Enter Your Service Descriptions"}
                                                                FormControlClassName={'form-control '}
                                                                label="Short Description"
                                                                name="shortDescription.en"
                                                                value={values?.shortDescription?.en}
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.shortDescription
                                                                    UpdateValue.en = v?.target?.value
                                                                    setFieldValue("shortDescription", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.shortDescription?.en}
                                                                touched={touched?.shortDescription?.en}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Marathi"}
                                                                FormControlClassName={'form-control '}
                                                                label="*Marathi"
                                                                name="shortDescription.mr"
                                                                value={values?.shortDescription?.mr}
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.shortDescription
                                                                    UpdateValue.mr = v?.target?.value
                                                                    setFieldValue("shortDescription", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.shortDescription?.mr}
                                                                touched={touched?.shortDescription?.mr}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Hindi"}
                                                                FormControlClassName={'form-control '}
                                                                label="*Hindi"
                                                                name="shortDescription.hi"
                                                                value={values?.shortDescription?.hi}
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.shortDescription
                                                                    UpdateValue.hi = v?.target?.value
                                                                    setFieldValue("shortDescription", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.shortDescription?.hi}
                                                                touched={touched?.shortDescription?.hi}
                                                            />

                                                            <InputFromUser
                                                                type={"number"}
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Per Day Price"}
                                                                FormControlClassName={'form-control '}
                                                                label="Per Day Price"
                                                                name="perDayPrice"
                                                                onChange={handleChange}
                                                                value={values?.perDayPrice}
                                                                onBlur={handleBlur}
                                                                error={errors?.perDayPrice}
                                                                touched={touched?.perDayPrice}
                                                            />

                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Solution Rating"}
                                                                FormControlClassName={'form-control '}
                                                                label="Solution Rating"
                                                                name="rating"
                                                                onChange={handleChange}
                                                                value={values?.rating}
                                                                onBlur={handleBlur}
                                                                error={errors?.rating}
                                                                touched={touched?.rating}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Success Rate"}
                                                                FormControlClassName={'form-control'}
                                                                onChange={handleChange}
                                                                value={values?.successRate}
                                                                onBlur={handleBlur}
                                                                label="Success Rate"
                                                                name="successRate"
                                                                error={errors?.successRate}
                                                                touched={touched?.successRate}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Order Index"}
                                                                FormControlClassName={'form-control'}
                                                                onChange={handleChange}
                                                                value={values?.orderIndex}
                                                                onBlur={handleBlur}
                                                                label="Order Index"
                                                                type={"number"}
                                                                name="orderIndex"
                                                                error={errors?.orderIndex}
                                                                touched={touched?.orderIndex}
                                                            />
                                                            <InputFromUser
                                                                placeholder={"Paste Your YouTube Video Link"}
                                                                type="url"
                                                                name="videoIframe"
                                                                onChange={(v) => setFieldValue("videoIframe", v?.target?.value)}
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                onBlur={handleBlur}
                                                                value={values.videoIframe}
                                                                label="Iframe Link"
                                                                error={errors?.videoIframe}
                                                                touched={touched.videoIframe}
                                                            />
                                                            <div className="col-xl-4 col-md-12">
                                                                <div className="d-flex justify-content-evenly align-items-center ">
                                                                    <label className="col-form-label">
                                                                        {"Upload Solution Image:-"}
                                                                    </label>
                                                                    <div>
                                                                        <UploadImage
                                                                            className={"img-fluid"}
                                                                            fileList={!isNullOrUndef(values?.listingViewImage) && [{
                                                                                uid: 1 + values?.listingViewImage,
                                                                                name: values?.listingViewImage,
                                                                                status: 'done',
                                                                                url: DOCS_URL + values?.listingViewImage,
                                                                            }] || []}
                                                                            handleChange={(v) => {
                                                                                if (v.file && v.file.status === 'uploading') {
                                                                                    const formData = new FormData();
                                                                                    formData.append("isSolution", true);
                                                                                    formData.append("attachment", v?.file?.originFileObj);
                                                                                    dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                        if (typeof res === 'object') {
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: 'listingViewImage',
                                                                                                    value: res?.[0],
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    }))
                                                                                } else if (v.file && v.file.status === 'removed') {
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'listingViewImage',
                                                                                            value: '',
                                                                                        },
                                                                                    });
                                                                                }
                                                                            }}
                                                                            errorMessage={errors?.listingViewImage}
                                                                            UploadImagelimit={1} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Enter Your mainTitle"}
                                                                FormControlClassName={'form-control '}
                                                                label="Main Title"
                                                                name="mainTitle?.en"
                                                                error={errors?.mainTitle?.en}
                                                                touched={touched?.mainTitle?.en}
                                                                value={values?.mainTitle?.en}

                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.mainTitle
                                                                    UpdateValue.en = v?.target?.value
                                                                    setFieldValue("mainTitle", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                            />

                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Marathi"}
                                                                FormControlClassName={'form-control '}
                                                                label="*Marathi"
                                                                name="mainTitle.mr"
                                                                value={values?.mainTitle?.mr}
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.mainTitle
                                                                    UpdateValue.mr = v?.target?.value
                                                                    setFieldValue("mainTitle", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.mainTitle?.mr}
                                                                touched={touched?.mainTitle?.mr}
                                                            />
                                                            <InputFromUser
                                                                FormGroupClassName={"col-xl-4 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Hindi"}
                                                                FormControlClassName={'form-control '}
                                                                label="*Hindi"
                                                                value={values?.mainTitle?.hi}
                                                                name="mainTitle.hi"
                                                                onChange={(v) => {
                                                                    const UpdateValue = values?.mainTitle
                                                                    UpdateValue.hi = v?.target?.value
                                                                    setFieldValue("mainTitle", UpdateValue, true)

                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.mainTitle?.hi}
                                                                touched={touched?.mainTitle?.hi}
                                                            />
                                                        </div>

                                                        <div className="row">
                                                            <div className="my-4" >
                                                                <Divider style={{ fontWeight: "600" }}>Facilities</Divider></div>
                                                            {values?.facilities?.map((item, index) => {
                                                                return (
                                                                    <tr key={"facilities" + index + 'tr'}>
                                                                        <div className="d-flex">
                                                                            <td className="col-xl-6 col-md-10" key={"facilities" + index + 'td'}>
                                                                                {
                                                                                    <InputFromUser
                                                                                        placeholder={'Enter Facilities'}
                                                                                        error={errors?.facilities}
                                                                                        value={item}
                                                                                        onChange={(v) => {
                                                                                            const updatedSolutionFor = [...(values?.facilities || [])];
                                                                                            updatedSolutionFor[index] = v?.target?.value;
                                                                                            setFieldValue("facilities", updatedSolutionFor, true)
                                                                                        }}
                                                                                        touched={touched?.facilities}
                                                                                    />
                                                                                }
                                                                            </td>
                                                                            <td className="col-2 add-remove text-end align-items-center" key={"td6" + index}>
                                                                                <Button
                                                                                    type=""
                                                                                    className="add-btn "
                                                                                    onClick={() => {
                                                                                        const newValue = [''];
                                                                                        setFieldValue("facilities", [...values.facilities, newValue])
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                </Button>
                                                                                <Button
                                                                                    style={{ border: 'none' }}
                                                                                    className="remove-btn"
                                                                                    onClick={() => {
                                                                                        if (index !== 0) {
                                                                                            const updatedBenefits = [...values.facilities];
                                                                                            updatedBenefits.splice(index, 1);
                                                                                            setFieldValue("facilities", updatedBenefits);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <i className="fe fe-trash-2">
                                                                                        <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                    </i>
                                                                                </Button>
                                                                            </td>
                                                                        </div>

                                                                    </tr>
                                                                )

                                                            })}
                                                        </div>
                                                        <div className="row">
                                                            <div className="my-4" >
                                                                <Divider style={{ fontWeight: "600" }}>Facilities After Description</Divider></div>
                                                            {values?.facilitiesAfterDescription?.map((item, index) => {
                                                                return (
                                                                    <tr key={"facilitiesAfterDescription" + index + 'tr'}>
                                                                        <div className="d-flex">
                                                                            <td className="col-xl-6 col-md-10" key={"facilitiesAfterDescription" + index + 'td'}>
                                                                                {
                                                                                    <InputFromUser
                                                                                        placeholder={'Enter Facilities'}
                                                                                        error={errors?.facilitiesAfterDescription}
                                                                                        value={item}
                                                                                        onChange={(v) => {
                                                                                            const updatedServiceFor = [...(values?.facilitiesAfterDescription || [])];
                                                                                            updatedServiceFor[index] = v?.target?.value;
                                                                                            setFieldValue("facilitiesAfterDescription", updatedServiceFor, true)
                                                                                        }}
                                                                                        touched={touched?.facilitiesAfterDescription}
                                                                                    />
                                                                                }
                                                                            </td>
                                                                            <td className="col-2 add-remove text-end align-items-center" key={"td7" + index}>
                                                                                <Button
                                                                                    type=""
                                                                                    className="add-btn"
                                                                                    onClick={() => {
                                                                                        const newValue = [''];
                                                                                        setFieldValue("facilitiesAfterDescription", [...values.facilitiesAfterDescription, newValue])
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                </Button>
                                                                                <Button
                                                                                    style={{ border: 'none' }}
                                                                                    className="remove-btn"
                                                                                    onClick={() => {
                                                                                        if (index !== 0) {
                                                                                            const updatedBenefits = [...values.facilitiesAfterDescription];
                                                                                            updatedBenefits.splice(index, 1);
                                                                                            setFieldValue("facilitiesAfterDescription", updatedBenefits);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <i className="fe fe-trash-2">
                                                                                        <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                    </i>
                                                                                </Button>
                                                                            </td>
                                                                        </div>

                                                                    </tr>
                                                                )
                                                            })}
                                                        </div>
                                                        <ExpendableTable
                                                            Title={"Numbers Count"}
                                                            emptyDataForCreatingField={{ "data": '', "name": '' }}
                                                            Data={values?.statics}
                                                            Columns={[
                                                                { name: 'name', type: 'text', label: 'Counts' },
                                                                { name: 'data', type: 'number', label: 'Description' },
                                                                { name: 'Action', type: 'action', label: 'Icon' },
                                                            ]}
                                                            onChange={(v) => setFieldValue("statics", v, true)}
                                                            onBlur={(v) => setFieldTouched("statics", v, true)}
                                                            errors={errors?.statics}
                                                            touched={touched?.statics}
                                                        />
                                                        <ExpendableTable
                                                            Title={"What We Do"}
                                                            emptyDataForCreatingField={{ "title": "", "description": "", "icon": '', "orderIndex": '' }}
                                                            Data={values?.whatWeDo}
                                                            Columns={[
                                                                { name: 'title', type: 'text', label: 'Title' },
                                                                { name: 'description', type: 'text', label: 'Description' },
                                                                { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                { name: 'icon', type: 'upload', label: 'Icon' },
                                                                { name: 'Action', type: 'action', label: 'Icon' },
                                                            ]}
                                                            onChange={(v) => setFieldValue("whatWeDo", v, true)}
                                                            onBlur={(v) => setFieldTouched("whatWeDo", v, true)}
                                                            errors={errors?.whatWeDo}
                                                            UploadImageFlag={'isSolution'}
                                                            touched={touched?.whatWeDo}
                                                        />

                                                        <ExpendableTable
                                                            Title={"Our Process"}
                                                            emptyDataForCreatingField={{ "mainTitle": "", "title": '', "description": "", "sideImage": '', "orderIndex": '' }}
                                                            Data={values?.ourProcess?.processes}
                                                            Columns={[
                                                                { name: 'mainTitle', type: 'text', label: 'Main Title' },
                                                                { name: 'title', type: 'text', label: 'Title' },
                                                                { name: 'description', type: 'text', label: 'Description' },
                                                                { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                { name: 'sideImage', type: 'upload', label: 'Icon' },
                                                                { name: 'Action', type: 'action', label: 'Action' },
                                                            ]}
                                                            onChange={(v) => setFieldValue("ourProcess", { ...values?.ourProcess, processes: v }, true)}
                                                            onBlur={(v) => setFieldTouched("ourProcess", { ...values?.ourProcess, processes: v }, true)}
                                                            errors={errors?.ourProcess?.processes}
                                                            UploadImageFlag={'isSolution'}
                                                            HeaderComponent={
                                                                <>
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Enter Title"}
                                                                        FormControlClassName={'form-control '}
                                                                        value={values?.ourProcess?.title || ''}
                                                                        label="Title"
                                                                        name="ourProcessTitle"
                                                                        onChange={(e) => {
                                                                            const updatedValue = e.target.value;
                                                                            setFieldValue("ourProcess.title", updatedValue);
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.ourProcess?.title}
                                                                        touched={touched?.ourProcess?.title}
                                                                    />
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        value={values?.ourProcess?.description || ''}
                                                                        placeholder={"Description"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="Description"
                                                                        name="ourProcessDescription"
                                                                        onChange={(e) => {
                                                                            const updatedValue = e.target.value;
                                                                            setFieldValue("ourProcess.description", updatedValue);
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.ourProcess?.description}
                                                                        touched={touched?.ourProcess?.description}
                                                                    /></>}
                                                            touched={touched?.statics}
                                                        />


                                                        <ExpendableTable
                                                            Title={"Benefits"}
                                                            emptyDataForCreatingField={{ "title": "", "description": "", "orderIndex": '' }}
                                                            Data={values?.benefits}
                                                            Columns={[
                                                                { name: 'title', type: 'text', label: 'Title' },
                                                                { name: 'description', type: 'text', label: 'Description' },
                                                                { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                { name: 'Action', type: 'action', label: 'Icon' },
                                                            ]}
                                                            onChange={(v) => setFieldValue("benefits", v, true)}
                                                            onBlur={(v) => setFieldTouched("benefits", v, true)}
                                                            errors={errors?.benefits}
                                                            touched={touched?.benefits}
                                                        />
                                                        <ExpendableTable
                                                            Title={"How Your Day Look Like"}
                                                            emptyDataForCreatingField={{ "startTime": "", "endTime": '', "description": '', "orderIndex": '', "bannerImage": '' }}
                                                            Data={values?.dayLook}
                                                            Columns={[
                                                                { name: 'startTime', type: 'text', label: 'Start Time' },
                                                                { name: 'endTime', type: 'text', label: 'End Time' },
                                                                { name: 'description', type: 'text', label: 'Description' },
                                                                { name: 'bannerImage', type: 'upload', label: 'Image' },
                                                                { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                { name: 'Action', type: 'action', label: 'Icon' },
                                                            ]}
                                                            onChange={(v) => setFieldValue("dayLook", v, true)}
                                                            onBlur={(v) => setFieldTouched("dayLook", v, true)}
                                                            errors={errors?.dayLook}
                                                            touched={touched?.dayLook}
                                                            HeaderComponent={<div className="row">
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-5 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    placeholder={"Day Look Like Title"}
                                                                    FormControlClassName={'form-control '}
                                                                    value={values?.dayLookTitle}
                                                                    label="Title"
                                                                    name="dayLookTitle"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.dayLookTitle}
                                                                    touched={touched?.dayLookTitle}
                                                                />
                                                                <InputFromUser
                                                                    FormGroupClassName={"col-xl-5 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    value={values?.dayLookDescription}
                                                                    placeholder={"Description"}
                                                                    FormControlClassName={'form-control '}
                                                                    label="Description"
                                                                    name="dayLookDescription"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={errors?.dayLookDescription}
                                                                    touched={touched?.dayLookDescription}

                                                                />
                                                                <div className="d-flex  align-items-center col-6 ">
                                                                    <label className="col-form-label">
                                                                        {"Upload Photo :-"}
                                                                    </label>
                                                                    <div>
                                                                        <UploadImage
                                                                            className={"img-fluid"}
                                                                            fileList={!isNullOrUndef(values?.dayLookMainImage) && [{
                                                                                uid: 1 + values?.dayLookMainImage,
                                                                                name: values?.dayLookMainImage,
                                                                                status: 'done',
                                                                                url: DOCS_URL + values?.dayLookMainImage,
                                                                            }] || []}
                                                                            handleChange={(v) => {
                                                                                if (v.file && v.file.status === 'uploading') {
                                                                                    const formData = new FormData();
                                                                                    formData.append("isSolution", true);
                                                                                    formData.append("attachment", v?.file?.originFileObj);
                                                                                    dispatch(ActionUploadImage({ obj: formData }, (res) => {
                                                                                        if (typeof res === 'object') {
                                                                                            handleChange({
                                                                                                target: {
                                                                                                    name: 'dayLookMainImage',
                                                                                                    value: res?.[0],
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    }))
                                                                                } else if (v.file && v.file.status === 'removed') {
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'dayLookMainImage',
                                                                                            value: '',
                                                                                        },
                                                                                    });
                                                                                }
                                                                            }}
                                                                            errorMessage={errors?.dayLookMainImage}
                                                                            UploadImagelimit={1} />
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        />
                                                        <div className="row">
                                                            <div className="my-4" >
                                                                <Divider style={{ fontWeight: "600" }}>Why Choose US</Divider></div>
                                                            <div className="row">
                                                                <div className="my-4" >
                                                                    <Divider style={{ fontWeight: "600" }}>Facilities</Divider></div>
                                                                {values?.whyChooseUs?.facilities?.map((item, index) => {
                                                                    return (
                                                                        <tr key={"facilities" + index + 'tr'}>
                                                                            <div className="d-flex">
                                                                                <td className="col-xl-6 col-md-10" key={"facilities" + index + 'td'}>
                                                                                    {
                                                                                        <InputFromUser
                                                                                            placeholder={'Enter Facilities'}
                                                                                            error={errors?.whyChooseUs?.facilities}
                                                                                            value={item}
                                                                                            onChange={(e) => {
                                                                                                const newValue = e.target.value;
                                                                                                const updatedFacilities = [...(values?.whyChooseUs?.facilities || [])];
                                                                                                updatedFacilities[index] = newValue;
                                                                                                setFieldValue("whyChooseUs", { ...values.whyChooseUs, facilities: updatedFacilities });
                                                                                            }}
                                                                                            touched={touched?.whyChooseUs?.facilities}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                                <td className="col-2 add-remove text-end align-items-center" key={"td6" + index}>
                                                                                    <Button
                                                                                        type=""
                                                                                        className="add-btn "
                                                                                        onClick={() => {
                                                                                            const newValue = '';
                                                                                            setFieldValue("whyChooseUs", { ...(values?.whyChooseUs || {}), facilities: [...(values?.whyChooseUs?.facilities || []), newValue] })
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                    </Button>
                                                                                    <Link
                                                                                        style={{ border: 'none' }}
                                                                                        className="remove-btn"
                                                                                        onClick={() => {
                                                                                            if (index !== 0) {
                                                                                                const updatedFacilities = [...(values?.whyChooseUs?.facilities || [])];
                                                                                                updatedFacilities.splice(index, 1);
                                                                                                setFieldValue("whyChooseUs", { ...values.whyChooseUs, facilities: updatedFacilities });
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <i className="fe fe-trash-2">
                                                                                            <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                        </i>
                                                                                    </Link>
                                                                                </td>
                                                                            </div>

                                                                        </tr>
                                                                    )

                                                                })}
                                                            </div>
                                                        </div>


                                                        <ExpendableTable
                                                            Title={"Why Choose Us Table"}
                                                            emptyDataForCreatingField={{
                                                                "aspect": "",
                                                                "otherHealthResort": '',
                                                                "naturopathy": '',
                                                                "allopathy": '',
                                                                "prakrutisAyurvedicTreatment": "",
                                                                "orderIndex": ""
                                                            }}

                                                            Data={values?.whyChooseUs?.chooseUs}
                                                            Columns={[
                                                                { name: 'aspect', type: 'text', label: 'Title' },
                                                                { name: 'otherHealthResort', type: 'text', label: 'Other-Health-Resort' },
                                                                { name: 'naturopathy', type: 'text', label: 'Naturopathy Centers' },
                                                                { name: 'allopathy', type: 'text', label: 'Allopathy-Centers' },
                                                                { name: 'prakrutisAyurvedicTreatment', type: 'text', label: 'Ayurvedic-Treatment' },
                                                                { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                { name: 'Action', type: 'action', label: 'Icon' },
                                                            ]}
                                                            onChange={(v) => setFieldValue("whyChooseUs", { ...values?.whyChooseUs, chooseUs: v }, true)}
                                                            onBlur={(v) => setFieldTouched("whyChooseUs", { ...values?.whyChooseUs, chooseUs: v }, true)}
                                                            errors={errors?.whyChooseUs?.chooseUs}
                                                            touched={touched?.whyChooseUs?.chooseUs}
                                                            HeaderComponent={<InputFromUser
                                                                FormGroupClassName={"col-xl-5 col-md-12"}
                                                                LabelClassName={"col-form-label"}
                                                                placeholder={"Enter Main Title"}
                                                                FormControlClassName={'form-control '}
                                                                value={values?.whyChooseUs?.mainTitle || ''}
                                                                label="Main Title"
                                                                name="maintitle"
                                                                onChange={(e) => {
                                                                    const updatedValue = e.target.value;
                                                                    setFieldValue("whyChooseUs.mainTitle", updatedValue);
                                                                }}
                                                                onBlur={handleBlur}
                                                                error={errors?.whyChooseUs?.mainTitle}
                                                                touched={touched?.whyChooseUs?.mainTitle}
                                                            />}
                                                        />

                                                        <div className="row">
                                                            <div className="my-4" >
                                                                <Divider style={{ fontWeight: "600" }}>Days Details</Divider></div>
                                                            {values?.days?.map((item, index) => {
                                                                return (
                                                                    <tr key={"days" + index + 'tr'}>
                                                                        <div className="d-flex">
                                                                            <td className="col-xl-6 col-md-10" key={"days" + index + 'td'}>
                                                                                {
                                                                                    <InputFromUser
                                                                                        placeholder={'Enter days'}
                                                                                        value={item}
                                                                                        error={errors?.days}
                                                                                        onChange={(v) => {
                                                                                            const updatedServiceFor = [...(values?.days || [])];
                                                                                            updatedServiceFor[index] = v?.target?.value;
                                                                                            setFieldValue("days", updatedServiceFor, true)
                                                                                        }}
                                                                                    //touched={touched?.days}
                                                                                    />
                                                                                }
                                                                            </td>
                                                                            <td className="col-2 add-remove text-end align-items-center" key={"td6" + index}>
                                                                                <Button
                                                                                    type=""
                                                                                    className="add-btn"
                                                                                    onClick={() => {
                                                                                        const newValue = [''];
                                                                                        setFieldValue("days", [...values.days, newValue])
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                </Button>
                                                                                <Button
                                                                                    style={{ border: 'none' }}
                                                                                    className="remove-btn"
                                                                                    onClick={() => {
                                                                                        if (index !== 0) {
                                                                                            const updatedBenefits = [...values.days];
                                                                                            updatedBenefits.splice(index, 1);
                                                                                            setFieldValue("days", updatedBenefits);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <i className="fe fe-trash-2">
                                                                                        <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                    </i>
                                                                                </Button>
                                                                            </td>
                                                                        </div>

                                                                    </tr>
                                                                )

                                                            })}
                                                        </div>
                                                        <div className="row">
                                                            <div className="my-4" >
                                                                <Divider style={{ fontWeight: "600" }}>Pricing Details</Divider></div>
                                                            {values?.price?.map((item, index) => {
                                                                return (
                                                                    <tr key={"price" + index + 'tr'}>
                                                                        <div className="d-flex">
                                                                            <td className="col-xl-6 col-md-10" key={"days" + index + 'td'}>
                                                                                {
                                                                                    <InputFromUser
                                                                                        placeholder={'Enter price'}
                                                                                        value={item}
                                                                                        error={errors?.price}
                                                                                        onChange={(v) => {
                                                                                            const updatedServiceFor = [...(values?.price || [])];
                                                                                            updatedServiceFor[index] = v?.target?.value;
                                                                                            setFieldValue("price", updatedServiceFor, true)
                                                                                        }}
                                                                                    //touched={touched?.days}
                                                                                    />
                                                                                }
                                                                            </td>
                                                                            <td className="col-2 add-remove text-end align-items-center" key={"td6" + index}>
                                                                                <Button
                                                                                    type=""
                                                                                    className="add-btn"
                                                                                    onClick={() => {
                                                                                        const newValue = [''];
                                                                                        setFieldValue("price", [...values.price, newValue])
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-plus-circle" style={{ color: '#2e37a4' }} />
                                                                                </Button>
                                                                                <Button
                                                                                    style={{ border: 'none' }}
                                                                                    className="remove-btn"
                                                                                    onClick={() => {
                                                                                        if (index !== 0) {
                                                                                            const updatedBenefits = [...values.price];
                                                                                            updatedBenefits.splice(index, 1);
                                                                                            setFieldValue("price", updatedBenefits);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <i className="fe fe-trash-2">
                                                                                        <FeatherIcon icon="trash-2" className={'mb-1'} style={{ color: 'red' }} />
                                                                                    </i>
                                                                                </Button>
                                                                            </td>
                                                                        </div>

                                                                    </tr>
                                                                )

                                                            })}
                                                        </div>
                                                        <ExpendableTable
                                                            Title={"Solution Faqs"}
                                                            emptyDataForCreatingField={{ "title": "", "description": "", "orderIndex": '' }}
                                                            Data={values?.solutionFaqs}
                                                            Columns={[
                                                                { name: 'title', type: 'text', label: 'Title' },
                                                                { name: 'description', type: 'text', label: 'Description' },
                                                                { name: 'orderIndex', type: 'number', label: 'Order Index' },
                                                                { name: 'Action', type: 'action', label: 'Action' },
                                                            ]}
                                                            onChange={(v) => setFieldValue("solutionFaqs", v, true)}
                                                            onBlur={(v) => setFieldTouched("solutionFaqs", v, true)}
                                                            errors={errors?.solutionFaqs}
                                                            touched={touched?.solutionFaqs}
                                                        />
                                                        <div className="col-12">
                                                            <div className="text-end">
                                                                <Button
                                                                    onClick={() => {
                                                                        handleSubmit()
                                                                    }}
                                                                    type="primary"
                                                                    // disabled={isSubmitting}
                                                                    className="pb-4"
                                                                >Submit</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div >
    );
};

export default AddEditSolution;