/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { GetAllservice } from "../../store/Actions/ServiceAction";
import { ResponsiveComponent, trimText } from "../../utils/Funcations";
import { ActionDeleteById } from "../../store/Actions/CommonAction";
import { Button, Modal, Popover } from "antd";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { matchPermissions } from "../../utils/Permission";
import { TableListPageCom } from "../../components/TableListPageCom";
import { DOCS_URL } from "../../utils/globals";
const Servicelist = () => {
    const { confirm } = Modal;
    const Size = ResponsiveComponent()
    const pageSize = 10;
    const dispatch = useDispatch();
    const { Servicelist, loader } = useSelector(state => state?.Service);
    const { userDetails } = useSelector(state => state?.auth);
    const params = queryString.parse(location.search);

    useEffect(() => {
        dispatch(GetAllservice({ Query: `?page=${params?.page || 1}` }));
    }, [])

    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('service.update');
    const canDelete = matchPermissionsForUser('service.delete');
    const canCerate = matchPermissionsForUser('service.create');
    const content = (item) => (
        <div className="row col">
            {canUpdate &&
                <Button className="col m-2" onClick={() => { window.location.href = `/add-service?update=true&&page=${1}&&id=${item?.id}` }}>Edit Service</Button>

            }
            {canDelete &&
                <Button className="col m-2" onClick={() => showDeleteConfirm(item)}>Delete Service</Button>

            }
        </div>
    );
    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.name} Service?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteById({ Query: `admin/service/${item?.id}` }, (Res) => {
                    if (Res?.statusCode === 200) {
                        //setRefresh(true)
                        window.location.reload()
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Service Name",
            dataIndex: "name",

        },
        {
            title: "Service Descriptions",
            dataIndex: "shortDescription",
            render: (text, record) => (
                <div className="app-config-boldText">{trimText(text, Size === "phone" || Size === "tablet" ? 20 : 50)}</div>
            ),

        },
        {
            title: "Service Price",
            dataIndex: "price",

        },
        {
            title: "Image",
            dataIndex: "listingViewImage",
            render: (text, record) => (
                <img src={DOCS_URL + record.listingViewImage} alt={record.name} style={{ width: 60, height: 60 }} />
            ),
        },
        {
            title: "",
            dataIndex: "serviceAction",
            render: (text, record,) => {
                if (!canUpdate && !canDelete) {
                    return null
                } else {
                    return <Popover placement="top" title={text} content={() => content(record)}>
                        <Button type="text">• • •</Button>
                    </Popover>
                }

            },
        },
    ]
    const BreadcrumbList = [{
        name: 'Medi-Daycare Rituals',
        linkTo: `/add-service?page=${params?.page || 1}`
    },
    {
        name: 'Medi-Daycare Rituals List',
    }
    ]
    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={Servicelist?.[0]?.docs}
            TableColumn={columns}
            ShowAddButton={true}
            Iscreate="service.create"
            CreateButtonLink={`/add-service?update=false&&page=${params?.page || 1}`}
            PageMainTitle="Medi-Daycare Rituals List"
            CurrentPage={Servicelist?.page || 1}
            Tableloader={loader}
            TotalPageList={Servicelist?.totalDocs}
            dataSourceLength={Servicelist?.docs?.length}
            handlePageChange={(v) => {
                var newp = params
                newp.page = v
                window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
            }}
        />

    )
};

export default Servicelist;
