/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";

import { Button, DatePicker, Input, Modal, Tag } from "antd";
import queryString from 'query-string';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ActionGetAllOrderList } from "../../../store/Actions/OrderHistoryAction";
import moment from "moment";
import { TableListPageCom } from "../../TableListPageCom";
import TableColumnSearch1 from '../../../components/Input/TableColumnSearch1';
import { generateQueryString, getStatus } from "../../../utils/Funcations";
import { ActionCreateByUrl } from "../../../store/Actions/CommonAction";
const { confirm } = Modal;
const { TextArea } = Input;
const OrderHistory = () => {
    const { loader, order } = useSelector(state => state?.OrderHistory);
    const [Refresh, setRefresh] = useState(false);
    const [IsopenModal, setIsopenModal] = useState(false);
    const [Isloading, setIsloading] = useState(false);
    const [Reason, setReason] = useState('');
    const [Id, setId] = useState('');
    const pageSize = 10;
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const dispatch = useDispatch();
    const location = useLocation();
    const pageParams = searchParams.get("page");
    const nameParams = searchParams.get("parentName");
    const bookingForParams = searchParams.get("bookingFor");
    const paymentStatusParams = searchParams.get("paymentStatus");
    const amountParams = searchParams.get("amount");
    const params = queryString.parse(location.search);

    const [Query, setQuery] = useState({
        'page': pageParams,
        'bookingFor': bookingForParams,
        'parentName': nameParams,
        'paymentStatus': paymentStatusParams,
        'amount': amountParams,
        'orderStartDate': '',
        'orderEndDate': '',

    },);
    const column = [
        {
            title: "Sr",
            dataIndex: "sr",
            // key: "Sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Name",
            dataIndex: "docs.parentName",
            render: (text, record) => (
                < Link to={`/order-history/order-history-overview/${record?.id}?page=${params?.page || 1}`}>{record?.customerId?.name}</Link >
            ),
            ...TableColumnSearch1({
                dataIndex: 'parentName',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
        },
        {
            title: "Booking",
            dataIndex: "docs.gender",
            render: (text, record) => {
                return (
                    <span>{record?.bookingFor || '--'}</span>
                )
            },
            ...TableColumnSearch1({
                dataIndex: 'bookingFor',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
        },

        {
            title: "Payment",
            dataIndex: "paymentStatus",
            children: [
                {
                    title: "Status",
                    dataIndex: "paymentStatus",
                    render: (text, record, index) => {
                        const paymentStatus = record?.paymentStatus
                        const orderStatus = record?.orderStatus
                        return (
                            <div className="row col">
                                <div>
                                    <p><strong>{"Payment : "}</strong>
                                        {record?.paymentStatus && <Tag color={getStatus(paymentStatus)}>
                                            {(record?.paymentStatus) || '--'}</Tag>}
                                    </p>
                                    <p><strong>{"Order : "}</strong>
                                        {record?.orderStatus && <Tag color={getStatus(orderStatus)}>
                                            {(record?.orderStatus) || '--'}</Tag>}
                                    </p>
                                </div>
                            </div>
                        )
                    },
                    filters: ['Completed', 'Failed'],
                    ...TableColumnSearch1({
                        type: 'Dropdown',
                        dataIndex: 'paymentStatus',
                        value: Query,
                        onChange: (v) => {
                            setQuery(v)
                        }
                    }),
                },
                {
                    title: "Mode",
                    dataIndex: "paymentMode",
                    // ellipsis: {
                    //     showTitle: false,
                    // },
                    render: (text, record) => (
                        <span>{record?.paymentMode || '--'}</span>
                    ),
                },
                {
                    title: "Order Type",
                    dataIndex: "orderType",
                    render: (text, record) => (
                        <span>{record?.orderType || '--'}</span>
                    ),
                },
                {
                    title: "Amount",
                    dataIndex: "amount",
                    render: (text, record) => {
                        const status = record?.paymentStatus
                        return (
                            record?.paymentStatus &&
                            <Tag color={status === 'Failed' ? "error" : status === 'Initialize' ? "processing" : status === 'Pending' ? "warning" : "success"}>{(record?.amount + '/-') || '--'}</Tag>
                        )
                    },
                    ...TableColumnSearch1({
                        dataIndex: 'amount',
                        value: Query,
                        onChange: (v) => {
                            setQuery(v)
                        }
                    }),

                    // <h5>{(record?.amount+'/-')|| '--'}</h5>
                    // ...TableColumnSearch({ dataIndex: 'docs.name', setSearchText, setSearchedColumn, handleReset, handleSearch }),
                },
            ]
        },

        {
            title: "Created Date",
            dataIndex: "createdAt",
            render: (text, record) => {
                const formattedDate = record?.createdAt ? moment(record.createdAt).format('DD-MM-YYYY') : '';
                return <span>{formattedDate || '--'}</span>;
            },
        },
        {
            title: "Actions",
            dataIndex: "",
            render: (text, record, index) => {
                const status = record?.paymentStatus
                return (
                    <>
                        {!(status === 'Failed') && <Button type="primary"
                            onClick={() => {
                                setId(record?._id)
                                setReason(null)
                                setIsopenModal(true)
                            }}>{'Cancel Order'}</Button>}

                        <Modal
                            open={IsopenModal}
                            closable={true}
                            forceRender={true}
                            confirmLoading={Isloading}
                            zIndex={1050}
                            style={{ margin: 10 }}
                            onOk={() => {
                                setIsloading(true)
                                dispatch(ActionCreateByUrl({
                                    Query: `admin/order/order_cancel/${Id}`, obj: {
                                        'reason': Reason
                                    }
                                }, (Respons) => {
                                    if (Respons?.statusCode === 200) {
                                        setIsopenModal(false)
                                        setIsloading(false)
                                    }

                                }))
                            }}
                            styles={{
                                body: {
                                    overflow: "hidden",
                                    width: '100%'
                                },
                                mask: {
                                    backdropFilter: 'blur(3px)',
                                    backgroundColor: 'transparent'
                                },
                                content: {
                                    borderRadius: 40,
                                },
                            }}
                            okText={'Submit'}
                            centered
                            onCancel={() => {
                                setIsopenModal(false)
                            }}
                            closeIcon={true}
                        >
                            <div>
                                <label style={{ marginBottom: 10, fontSize: 18 }}>
                                    Notes:
                                </label>
                                <TextArea
                                    rows={4}
                                    cols={60}
                                    className="form-control"
                                    placeholder="Enter Reason"
                                    value={Reason}
                                    onChange={(value) => {
                                        const Value = value.target.value
                                        setReason(value.target.value)
                                    }} />
                            </div>
                        </Modal>
                    </>
                )
            },
        },

    ];


    const handleSearch1 = (searchTerm) => {
        var newp = params
        newp.page = searchTerm
        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
    }



    const Filters = generateQueryString(
        ['page', (Query.page || 1)],
        ['parentName', (Query.parentName)],
        ['paymentStatus', Query.paymentStatus],
        ['bookingFor', Query.bookingFor],
        ['amount', Query.amount],
        ['orderStartDate', Query.orderStartDate],
        ['orderEndDate', Query.orderEndDate],
    )
    const handleResetFilters = () => {
        setQuery({
            ...Query,
            orderStartDate: '',
            orderEndDate: '',
        });
    };

    const BreadcrumbList = [{
        name: 'Order & History',
        linkTo: `/order-history?page=${params?.page || 1}`
    },
    {
        name: 'Order & History List',
    }
    ]
    useEffect(() => {
        dispatch(ActionGetAllOrderList({ Query: Filters }));
        setRefresh(false);
        window.history.replaceState(null, '', Filters);
    }, [Filters, dispatch, params?.page, Refresh, Query.orderStartDate, Query.orderEndDate])
    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={order?.docs}
            LeftChild={
                <div className="col-12 col-lg-6 col-xl-6 mt-3 mb-md-0" style={{ display: 'flex', alignItems: 'center' }}>
                    <DatePicker.RangePicker
                        onChange={(dates) => {
                            setQuery({
                                ...Query,
                                orderStartDate: dates[0]?.format('YYYY-MM-DD') || '',
                                orderEndDate: dates[1]?.format('YYYY-MM-DD') || '',
                            });
                        }}
                        allowClear={false}
                    />
                    <Button type="link" onClick={handleResetFilters} style={{ marginLeft: '10px' }}>Clear Filters</Button>
                </div>
            }
            TableColumn={column}
            ShowAddButton={false}
            PageMainTitle="Order & History"
            CurrentPage={parseInt(params?.page) || 1}
            Tableloader={loader}
            TotalPageList={order?.totalDocs}
            dataSourceLength={order?.docs?.length}
            handlePageChange={(v) => handleSearch1(v)}
        />
    );
};

export default OrderHistory;