import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllTreatmentprotocol } from '../../store/Actions/PrakrutistandardAction';
import { Button, Modal, Popover } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { ActionDeleteById } from '../../store/Actions/CommonAction';
import { matchPermissions } from '../../utils/Permission';
import { TableListPageCom } from '../../components/TableListPageCom';
import { generateQueryString } from '../../utils/Funcations';

const TreatmentProtocolList = () => {
    const { confirm } = Modal;
    const dispatch = useDispatch();
    const { Treatmentprotocollist, loader } = useSelector(state => state?.PrakrutiStandard);
    const { userDetails } = useSelector(state => state?.auth);
    const params = queryString.parse(location.search);
    const pageSize = 10;
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('treatment-protocol.update');
    const canDelete = matchPermissionsForUser('treatment-protocol.delete');
    const canCerate = matchPermissionsForUser('treatment-protocol.create');
    const content = (item) => (
        <div className="row col">
            {canUpdate &&
                <Button className="col m-2" onClick={() => { window.location.href = `/add-treatmentprotocol?update=true&&page=${1}&&id=${item?.id}` }}>Edit </Button>
            }
            {canDelete &&
                <Button className="col m-2" onClick={() => showDeleteConfirm(item)}>Delete </Button>
            }
        </div>
    );
    const [Query, setQuery] = useState({
        'page': params?.page,
    },);
    const Filters = generateQueryString(
        ['page', (Query.page || 1)],
    )
    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.treatmentName} name?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteById({ Query: `admin/treatment-protocol/${item?._id}` }, (Res) => {
                    if (Res?.statusCode === 200) {
                        //setRefresh(true)
                        window.location.reload()
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "TreatmentName",
            dataIndex: "treatmentName",


        },

        {
            title: !canUpdate && !canDelete ? null : "Action",
            dataIndex: "roleName",
            render: (text, record) => {
                if (!canUpdate && !canDelete) {
                    return null
                }
                else {
                    return <Popover placement="top" title={text} content={() => content(record)}>
                        <Button type="text">• • •</Button>
                    </Popover >
                }

            },
        },
    ]
    useEffect(() => {
        dispatch(GetAllTreatmentprotocol({ Query: Filters }));
    }, [Filters])
    const BreadcrumbList = [{
        name: 'Treatment Protocol',
        linkTo: `/treatmentprotocol-list?page=${params?.page || 1}`
    },
    {
        name: 'Treatment Protocol List',
    }
    ]
    return (<TableListPageCom
        BreadcrumbList={BreadcrumbList}
        TableData={Treatmentprotocollist?.[0]?.docs}
        TableColumn={columns}
        CreateButtonLink='/add-treatmentprotocol'
        ShowAddButton={canCerate}
        PageMainTitle="Treatment Protocol List"
        CurrentPage={Query.page || 1}
        Tableloader={loader}
        TotalPageList={Treatmentprotocollist?.[0]?.totalDocs}
        dataSourceLength={Treatmentprotocollist?.[0]?.docs?.length}
        handlePageChange={(v) => setQuery({ page: v })}
    />
    )
}

export default TreatmentProtocolList