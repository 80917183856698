import { GET_ROLE_LIST_SUCCESS, GET_ROLE_LIST, GET_ROLE_LIST_PAGINATION, GET_ROLE_LIST_PAGINATION_SUCCESS, CREATE_ROLES, DELETE_BY_ID, UPDATE_BY_ID } from "../storeTypes";

const INIT_STATE = {
    loader: false,
    RoleList: [],
    RoleList_Pagination: [],
};

function RoleReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case GET_ROLE_LIST: {
            return {
                ...state,
                RoleList: [],
                loader: true,
            };
        }
        case GET_ROLE_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                RoleList: action.payload,
            };
        }
        case GET_ROLE_LIST_PAGINATION: {
            return {
                ...state,
                RoleList_Pagination: [],
                loader: true,
            };
        }
        case GET_ROLE_LIST_PAGINATION_SUCCESS: {
            return {
                ...state,
                loader: false,
                RoleList_Pagination: action.payload,
            };
        }
        case CREATE_ROLES: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_BY_ID: {
            return {
                ...state,
                loader: true,
            };
        }
        case DELETE_BY_ID: {
            return {
                ...state,
                loader: true,
            };
        }
        default: {
            return state;
        }
    }
}

export default RoleReducer;
