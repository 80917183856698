import { GET_PERMISSIONS_LIST, GET_PERMISSIONS_LIST_SUCCESS, CREATE_PERMISSIONS, GET_PAGINATED_PERMISSIONS_LIST, GET_PAGINATED_PERMISSIONS_LIST_SUCCESS } from "../storeTypes";




export const GetPermissionListAction = (payload) => {
    return {
        type: GET_PERMISSIONS_LIST,
        payload
    };
};
export const GetPermissionListSuccess = (payload) => {
    return {
        type: GET_PERMISSIONS_LIST_SUCCESS,
        payload
    };
};

export const GetPaginatedPermissionListAction = (payload) => {
    return {
        type: GET_PAGINATED_PERMISSIONS_LIST,
        payload
    };
};
export const GetPaginatedPermissionSuccess = (payload) => {
    return {
        type: GET_PAGINATED_PERMISSIONS_LIST_SUCCESS,
        payload
    };
};


export const CreatePermissionAction = (payload, callBack) => {
    return {
        type: CREATE_PERMISSIONS,
        payload,
        callBack
    };
};