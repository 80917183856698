/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Divider } from "antd";
import PropTypes from 'prop-types';
import { SingleMultiSelectDropDown } from '../../components/Input/InputText';

export const LabInvestigationComponent = ({ errors, touched, values, laboratory, setValues, handleChange, handleBlur, name }) => {
    return (
        <div className="col" >
            <div className="col-xl col-md-12" >
                <div className="form-group">
                    <label className="col-form-label" style={{ fontWeight: "bold", color: errors?.doctorNote && touched?.doctorNote && 'red' }} >
                        Laboratory Investigations:
                        {errors?.doctorNote && <span className="login-danger"> *</span>}
                    </label>
                    <div className="row">
                        <Divider orientation="left" orientationMargin="0" />
                        <div className="row">
                            <div className="col-xl-3" >
                                <h3 style={{ color: 'orangered' }}>DIGITAL X-RAY</h3>
                                {laboratory?.[0]?.filter((v) => v?.category === 'DIGITAL X-RAY')?.map((v, i) => {
                                    const NameOfField = v?.name
                                    const CategoryOfField = v?.category
                                    let laboratoryData = Object.assign([], values?.laboratory);
                                    let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                    return (
                                        <SingleMultiSelectDropDown
                                            key={i + 'multILab' + NameOfField}
                                            label={NameOfField}
                                            type={NameOfField}
                                            FormGroupClassName={""}
                                            LabelClassName={"col-form-label"}
                                            name={NameOfField}
                                            onChange={(value) => {
                                                if (isCategoryOfFieldExist) {
                                                    laboratoryData?.map((val) => {
                                                        if (val?.name === NameOfField) {
                                                            val.options = value
                                                        }
                                                    })
                                                    setValues({ ...values, laboratory: laboratoryData })

                                                } else {
                                                    handleChange({
                                                        target: {
                                                            name: name,
                                                            value: [
                                                                ...values.laboratory,
                                                                {
                                                                    "category": CategoryOfField,
                                                                    "name": NameOfField,
                                                                    "options": value,
                                                                }
                                                            ],
                                                        },
                                                    });
                                                }
                                            }}
                                            FormControlClassName={'form-control'}
                                            onBlur={(value) => {
                                                handleBlur({
                                                    target: {
                                                        name: NameOfField,
                                                        value: true,
                                                    },
                                                });
                                            }}
                                            value={
                                                values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                                || []}

                                            options={v.options}
                                            touched={touched?.NameOfField}
                                            placeholder={`Enter ${NameOfField}`} />
                                    )
                                })}
                            </div>
                            <div className="col-xl-3">
                                <h3 style={{ color: 'orangered' }}>ULTRA SONOGRAPHY</h3>
                                {laboratory?.[0]?.filter((v) => v?.category === 'ULTRA SONOGRAPHY')?.map((v, i) => {
                                    const NameOfField = v?.name
                                    const CategoryOfField = v?.category
                                    let laboratoryData = Object.assign([], values?.laboratory);
                                    let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                    return (
                                        <SingleMultiSelectDropDown
                                            key={i + NameOfField}
                                            label={NameOfField}
                                            type={NameOfField}
                                            FormGroupClassName={""}
                                            LabelClassName={"col-form-label"}
                                            name={NameOfField}
                                            onChange={(value) => {
                                                if (isCategoryOfFieldExist) {
                                                    laboratoryData?.map((val) => {
                                                        if (val?.name === NameOfField) {
                                                            val.options = value
                                                        }
                                                    })
                                                    setValues({ ...values, laboratory: laboratoryData })

                                                } else {
                                                    handleChange({
                                                        target: {
                                                            name: name,
                                                            value: [
                                                                ...values.laboratory,
                                                                {
                                                                    "category": CategoryOfField,
                                                                    "name": NameOfField,
                                                                    "options": value,
                                                                }
                                                            ],
                                                        },
                                                    });
                                                }
                                            }}
                                            FormControlClassName={'form-control'}
                                            onBlur={(value) => {
                                                handleBlur({
                                                    target: {
                                                        name: NameOfField,
                                                        value: true,
                                                    },
                                                });
                                            }}
                                            value={
                                                values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                                || []}
                                            options={v.options}
                                            touched={touched?.NameOfField}
                                            placeholder={`Enter ${NameOfField}`} />
                                    )
                                })}
                            </div>
                            <div className="col-xl-3">
                                <h3 style={{ color: 'orangered' }}>HEMATOLOGY</h3>
                                {laboratory?.[0]?.filter((v) => v?.category === 'HEMATOLOGY')?.map((v, i) => {
                                    const NameOfField = v?.name
                                    const CategoryOfField = v?.category
                                    let laboratoryData = Object.assign([], values?.laboratory);
                                    let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                    return (<SingleMultiSelectDropDown
                                        key={i + NameOfField}
                                        label={NameOfField}
                                        type={NameOfField}
                                        FormGroupClassName={""}
                                        LabelClassName={"col-form-label"}
                                        name={NameOfField}
                                        onChange={(value) => {
                                            if (isCategoryOfFieldExist) {
                                                laboratoryData?.map((val) => {
                                                    if (val?.name === NameOfField) {
                                                        val.options = value
                                                    }
                                                })
                                                setValues({ ...values, laboratory: laboratoryData })

                                            } else {
                                                handleChange({
                                                    target: {
                                                        name: name,
                                                        value: [
                                                            ...values.laboratory,
                                                            {
                                                                "category": CategoryOfField,
                                                                "name": NameOfField,
                                                                "options": value,
                                                            }
                                                        ],
                                                    },
                                                });
                                            }
                                        }}
                                        FormControlClassName={'form-control'}
                                        onBlur={(value) => {
                                            handleBlur({
                                                target: {
                                                    name: NameOfField,
                                                    value: true,
                                                },
                                            });
                                        }}
                                        value={
                                            values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                            || []}
                                        options={v.options}
                                        touched={touched?.NameOfField}
                                        placeholder={`Enter ${NameOfField}`} />
                                    )
                                })}
                            </div>
                            <div className="col-xl-3">
                                <h3 style={{ color: 'orangered' }}>CLINICAL PATHOLOGY</h3>
                                {laboratory?.[0]?.filter((v) => v?.category === 'CLINICAL PATHOLOGY')?.map((v, i) => {
                                    const NameOfField = v?.name
                                    const CategoryOfField = v?.category
                                    let laboratoryData = Object.assign([], values?.laboratory);
                                    let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                    return (<SingleMultiSelectDropDown
                                        key={i + NameOfField}
                                        label={NameOfField}
                                        type={NameOfField}
                                        FormGroupClassName={""}
                                        LabelClassName={"col-form-label"}
                                        name={NameOfField}
                                        onChange={(value) => {
                                            if (isCategoryOfFieldExist) {
                                                laboratoryData?.map((val) => {
                                                    if (val?.name === NameOfField) {
                                                        val.options = value
                                                    }
                                                })
                                                setValues({ ...values, laboratory: laboratoryData })

                                            } else {
                                                handleChange({
                                                    target: {
                                                        name: name,
                                                        value: [
                                                            ...values.laboratory,
                                                            {
                                                                "category": CategoryOfField,
                                                                "name": NameOfField,
                                                                "options": value,
                                                            }
                                                        ],
                                                    },
                                                });
                                            }
                                        }}
                                        FormControlClassName={'form-control'}
                                        onBlur={(value) => {
                                            handleBlur({
                                                target: {
                                                    name: NameOfField,
                                                    value: true,
                                                },
                                            });
                                        }}
                                        value={
                                            values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                            || []}
                                        options={v.options}
                                        touched={touched?.NameOfField}
                                        placeholder={`Enter ${NameOfField}`} />
                                    )
                                })}
                            </div>
                        </div>
                        <Divider orientation="left" orientationMargin="0" />
                        <div className="row">
                            <div className="row">
                                <div className="col-xl-4">
                                    <h3 style={{ color: 'orangered' }}>SEROLOGY AND IMMUNOLOGY</h3>
                                    {laboratory?.[0]?.filter((v) => v?.category === 'SEROLOGY AND IMMUNOLOGY')?.map((v, i) => {
                                        const NameOfField = v?.name
                                        const CategoryOfField = v?.category
                                        let laboratoryData = Object.assign([], values?.laboratory);
                                        let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                        return (<SingleMultiSelectDropDown
                                            key={i + NameOfField}
                                            label={NameOfField}
                                            type={NameOfField}
                                            FormGroupClassName={""}
                                            LabelClassName={"col-form-label"}
                                            name={NameOfField}
                                            onChange={(value) => {
                                                if (isCategoryOfFieldExist) {
                                                    laboratoryData?.map((val) => {
                                                        if (val?.name === NameOfField) {
                                                            val.options = value
                                                        }
                                                    })
                                                    setValues({ ...values, laboratory: laboratoryData })

                                                } else {
                                                    handleChange({
                                                        target: {
                                                            name: name,
                                                            value: [
                                                                ...values.laboratory,
                                                                {
                                                                    "category": CategoryOfField,
                                                                    "name": NameOfField,
                                                                    "options": value,
                                                                }
                                                            ],
                                                        },
                                                    });
                                                }
                                            }}
                                            FormControlClassName={'form-control'}
                                            onBlur={(value) => {
                                                handleBlur({
                                                    target: {
                                                        name: NameOfField,
                                                        value: true,
                                                    },
                                                });
                                            }}
                                            value={
                                                values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                                || []}
                                            options={v.options}
                                            touched={touched?.NameOfField}
                                            placeholder={`Enter ${NameOfField}`} />
                                        )
                                    })}
                                </div>
                                <div className="col-xl-4">
                                    <h3 style={{ color: 'orangered' }}>BIOCHEMISTRY</h3>
                                    {laboratory?.[0]?.filter((v) => v?.category === 'BIOCHEMISTRY')?.map((v, i) => {
                                        const NameOfField = v?.name
                                        const CategoryOfField = v?.category
                                        let laboratoryData = Object.assign([], values?.laboratory);
                                        let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                        return (<SingleMultiSelectDropDown
                                            key={i + NameOfField}
                                            label={NameOfField}
                                            type={NameOfField}
                                            FormGroupClassName={""}
                                            LabelClassName={"col-form-label"}
                                            name={NameOfField}
                                            onChange={(value) => {
                                                if (isCategoryOfFieldExist) {
                                                    laboratoryData?.map((val) => {
                                                        if (val?.name === NameOfField) {
                                                            val.options = value
                                                        }
                                                    })
                                                    setValues({ ...values, laboratory: laboratoryData })

                                                } else {
                                                    handleChange({
                                                        target: {
                                                            name: name,
                                                            value: [
                                                                ...values.laboratory,
                                                                {
                                                                    "category": CategoryOfField,
                                                                    "name": NameOfField,
                                                                    "options": value,
                                                                }
                                                            ],
                                                        },
                                                    });
                                                }
                                            }}
                                            FormControlClassName={'form-control'}
                                            onBlur={(value) => {
                                                handleBlur({
                                                    target: {
                                                        name: NameOfField,
                                                        value: true,
                                                    },
                                                });
                                            }}
                                            value={
                                                values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                                || []}
                                            options={v.options}
                                            touched={touched?.NameOfField}
                                            placeholder={`Enter ${NameOfField}`} />
                                        )
                                    })}</div>
                                <div className="col-xl-4">
                                    <h3 style={{ color: 'orangered' }}>ENDOCRINOLOGY</h3>
                                    {laboratory?.[0]?.filter((v) => v?.category === 'ENDOCRINOLOGY')?.map((v, i) => {
                                        const NameOfField = v?.name
                                        const CategoryOfField = v?.category
                                        let laboratoryData = Object.assign([], values?.laboratory);
                                        let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                        return (<SingleMultiSelectDropDown
                                            key={i + NameOfField}
                                            label={NameOfField}
                                            type={NameOfField}
                                            FormGroupClassName={""}
                                            LabelClassName={"col-form-label"}
                                            name={NameOfField}
                                            onChange={(value) => {
                                                if (isCategoryOfFieldExist) {
                                                    laboratoryData?.map((val) => {
                                                        if (val?.name === NameOfField) {
                                                            val.options = value
                                                        }
                                                    })
                                                    setValues({ ...values, laboratory: laboratoryData })

                                                } else {
                                                    handleChange({
                                                        target: {
                                                            name: name,
                                                            value: [
                                                                ...values.laboratory,
                                                                {
                                                                    "category": CategoryOfField,
                                                                    "name": NameOfField,
                                                                    "options": value,
                                                                }
                                                            ],
                                                        },
                                                    });
                                                }
                                            }}
                                            FormControlClassName={'form-control'}
                                            onBlur={(value) => {
                                                handleBlur({
                                                    target: {
                                                        name: NameOfField,
                                                        value: true,
                                                    },
                                                });
                                            }}
                                            value={
                                                values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                                || []}
                                            options={v.options}
                                            touched={touched?.NameOfField}
                                            placeholder={`Enter ${NameOfField}`} />
                                        )
                                    })}
                                </div>
                                <Divider orientation="left" orientationMargin="0" />

                                <div className='row'>
                                    <h3 style={{ color: 'orangered' }}>HIGH-END MRI STUDY</h3>
                                    {laboratory?.[0]?.filter((v) => v?.category === 'HIGH-END MRI STUDY')?.map((v, i) => {
                                        const NameOfField = v?.name
                                        const CategoryOfField = v?.category
                                        let laboratoryData = Object.assign([], values?.laboratory);
                                        let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                        return (
                                            <div className="col-xl-3" key={i + 'MRI'}>
                                                <SingleMultiSelectDropDown
                                                    key={i + NameOfField}
                                                    label={NameOfField}
                                                    type={NameOfField}
                                                    FormGroupClassName={""}
                                                    LabelClassName={"col-form-label"}
                                                    name={NameOfField}
                                                    onChange={(value) => {
                                                        if (isCategoryOfFieldExist) {
                                                            laboratoryData?.map((val) => {
                                                                if (val?.name === NameOfField) {
                                                                    val.options = value
                                                                }
                                                            })
                                                            setValues({ ...values, laboratory: laboratoryData })

                                                        } else {
                                                            handleChange({
                                                                target: {
                                                                    name: name,
                                                                    value: [
                                                                        ...values.laboratory,
                                                                        {
                                                                            "category": CategoryOfField,
                                                                            "name": NameOfField,
                                                                            "options": value,
                                                                        }
                                                                    ],
                                                                },
                                                            });
                                                        }
                                                    }}
                                                    FormControlClassName={'form-control'}
                                                    onBlur={(value) => {
                                                        handleBlur({
                                                            target: {
                                                                name: NameOfField,
                                                                value: true,
                                                            },
                                                        });
                                                    }}
                                                    value={
                                                        values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                                        || []}
                                                    options={v.options}
                                                    touched={touched?.NameOfField}
                                                    placeholder={`Enter ${NameOfField}`} />
                                            </div>)
                                    })}
                                </div>
                                <Divider orientation="left" orientationMargin="0" />
                                <h3 style={{ color: 'orangered' }}>CTSCAN</h3>
                                {laboratory?.[0]?.filter((v) => v?.category === 'CT SCAN')?.map((v, i) => {
                                    const NameOfField = v?.name
                                    const CategoryOfField = v?.category
                                    let laboratoryData = Object.assign([], values?.laboratory);
                                    let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                    return (<div className="col-xl-3" key={i + 'div' + NameOfField}>
                                        <SingleMultiSelectDropDown
                                            key={i + NameOfField}
                                            label={NameOfField}
                                            type={NameOfField}
                                            FormGroupClassName={""}
                                            LabelClassName={"col-form-label"}
                                            name={NameOfField}
                                            onChange={(value) => {
                                                if (isCategoryOfFieldExist) {
                                                    laboratoryData?.map((val) => {
                                                        if (val?.name === NameOfField) {
                                                            val.options = value
                                                        }
                                                    })
                                                    setValues({ ...values, laboratory: laboratoryData })

                                                } else {
                                                    handleChange({
                                                        target: {
                                                            name: name,
                                                            value: [
                                                                ...values.laboratory,
                                                                {
                                                                    "category": CategoryOfField,
                                                                    "name": NameOfField,
                                                                    "options": value,
                                                                }
                                                            ],
                                                        },
                                                    });
                                                }
                                            }}
                                            FormControlClassName={'form-control'}
                                            onBlur={(value) => {
                                                handleBlur({
                                                    target: {
                                                        name: NameOfField,
                                                        value: true,
                                                    },
                                                });
                                            }}

                                            value={
                                                values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                                || []}
                                            options={v.options}
                                            touched={touched?.NameOfField}
                                            placeholder={`Enter ${NameOfField}`} />
                                    </div>

                                    )
                                })}
                                <Divider orientation="left" orientationMargin="0" />
                                <div className="col-xl-3">
                                    <h3 style={{ color: 'orangered' }}>COLOUR DOPPLER STUDY</h3>
                                    {laboratory?.[0]?.filter((v) => v?.category === 'COLOUR DOPPLER STUDY')?.map((v, i) => {
                                        const NameOfField = v?.name
                                        const CategoryOfField = v?.category
                                        let laboratoryData = Object.assign([], values?.laboratory);
                                        let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                        return (
                                            <SingleMultiSelectDropDown
                                                key={i + NameOfField}
                                                label={NameOfField}
                                                type={NameOfField}
                                                FormGroupClassName={""}
                                                LabelClassName={"col-form-label"}
                                                name={NameOfField}
                                                onChange={(value) => {
                                                    if (isCategoryOfFieldExist) {
                                                        laboratoryData?.map((val) => {
                                                            if (val?.name === NameOfField) {
                                                                val.options = value
                                                            }
                                                        })
                                                        setValues({ ...values, laboratory: laboratoryData })

                                                    } else {
                                                        handleChange({
                                                            target: {
                                                                name: name,
                                                                value: [
                                                                    ...values.laboratory,
                                                                    {
                                                                        "category": CategoryOfField,
                                                                        "name": NameOfField,
                                                                        "options": value,
                                                                    }
                                                                ],
                                                            },
                                                        });
                                                    }
                                                }}
                                                FormControlClassName={'form-control'}
                                                onBlur={(value) => {
                                                    handleBlur({
                                                        target: {
                                                            name: NameOfField,
                                                            value: true,
                                                        },
                                                    });
                                                }}
                                                value={
                                                    values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                                    || []}
                                                options={v.options}
                                                touched={touched?.NameOfField}
                                                placeholder={`Enter ${NameOfField}`} />
                                        )
                                    })}
                                </div>
                                <div className="col-xl-3">
                                    <h3 style={{ color: 'orangered' }}>HEPATITIS MARKER</h3>
                                    {laboratory?.[0]?.filter((v) => v?.category === 'HEPATITIS MARKER')?.map((v, i) => {
                                        const NameOfField = v?.name
                                        const CategoryOfField = v?.category
                                        let laboratoryData = Object.assign([], values?.laboratory);
                                        let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                        return (<SingleMultiSelectDropDown
                                            key={i + NameOfField}
                                            label={NameOfField}
                                            type={NameOfField}
                                            FormGroupClassName={""}
                                            LabelClassName={"col-form-label"}
                                            name={NameOfField}
                                            onChange={(value) => {
                                                if (isCategoryOfFieldExist) {
                                                    laboratoryData?.map((val) => {
                                                        if (val?.name === NameOfField) {
                                                            val.options = value
                                                        }
                                                    })
                                                    setValues({ ...values, laboratory: laboratoryData })

                                                } else {
                                                    handleChange({
                                                        target: {
                                                            name: name,
                                                            value: [
                                                                ...values.laboratory,
                                                                {
                                                                    "category": CategoryOfField,
                                                                    "name": NameOfField,
                                                                    "options": value,
                                                                }
                                                            ],
                                                        },
                                                    });
                                                }
                                            }}
                                            FormControlClassName={'form-control'}
                                            onBlur={(value) => {
                                                handleBlur({
                                                    target: {
                                                        name: NameOfField,
                                                        value: true,
                                                    },
                                                });
                                            }}
                                            value={
                                                values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                                || []}
                                            options={v.options}
                                            touched={touched?.NameOfField}
                                            placeholder={`Enter ${NameOfField}`} />
                                        )
                                    })}
                                </div>
                                <div className="col-xl-3">
                                    <h3 style={{ color: 'orangered' }}>HISTOPATHOLOGY & CYTOLOGY</h3>
                                    {laboratory?.[0]?.filter((v) => v?.category === 'HISTOPATHOLOGY & CYTOLOGY')?.map((v, i) => {
                                        const NameOfField = v?.name
                                        const CategoryOfField = v?.category
                                        let laboratoryData = Object.assign([], values?.laboratory);
                                        let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                        return (<SingleMultiSelectDropDown
                                            key={i + NameOfField}
                                            label={NameOfField}
                                            type={NameOfField}
                                            FormGroupClassName={""}
                                            LabelClassName={"col-form-label"}
                                            name={NameOfField}
                                            onChange={(value) => {
                                                if (isCategoryOfFieldExist) {
                                                    laboratoryData?.map((val) => {
                                                        if (val?.name === NameOfField) {
                                                            val.options = value
                                                        }
                                                    })
                                                    setValues({ ...values, laboratory: laboratoryData })

                                                } else {
                                                    handleChange({
                                                        target: {
                                                            name: name,
                                                            value: [
                                                                ...values.laboratory,
                                                                {
                                                                    "category": CategoryOfField,
                                                                    "name": NameOfField,
                                                                    "options": value,
                                                                }
                                                            ],
                                                        },
                                                    });
                                                }
                                            }}
                                            FormControlClassName={'form-control'}
                                            onBlur={(value) => {
                                                handleBlur({
                                                    target: {
                                                        name: NameOfField,
                                                        value: true,
                                                    },
                                                });
                                            }}
                                            value={
                                                values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                                || []}
                                            options={v.options}
                                            touched={touched?.NameOfField}
                                            placeholder={`Enter ${NameOfField}`} />

                                        )
                                    })}
                                </div>
                                <div className="col-xl-3">
                                    <h3 style={{ color: 'orangered' }}>PANELS AND PROLIFES</h3>
                                    {laboratory?.[0]?.filter((v) => v?.category === 'PANELS AND PROLIFES')?.map((v, i) => {
                                        const NameOfField = v?.name
                                        const CategoryOfField = v?.category
                                        let laboratoryData = Object.assign([], values?.laboratory);
                                        let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                        return (<SingleMultiSelectDropDown
                                            key={i + NameOfField}
                                            label={NameOfField}
                                            type={NameOfField}
                                            FormGroupClassName={""}
                                            LabelClassName={"col-form-label"}
                                            name={NameOfField}
                                            onChange={(value) => {
                                                if (isCategoryOfFieldExist) {
                                                    laboratoryData?.map((val) => {
                                                        if (val?.name === NameOfField) {
                                                            val.options = value
                                                        }
                                                    })
                                                    setValues({ ...values, laboratory: laboratoryData })

                                                } else {
                                                    handleChange({
                                                        target: {
                                                            name: name,
                                                            value: [
                                                                ...values.laboratory,
                                                                {
                                                                    "category": CategoryOfField,
                                                                    "name": NameOfField,
                                                                    "options": value,
                                                                }
                                                            ],
                                                        },
                                                    });
                                                }
                                            }}
                                            FormControlClassName={'form-control'}
                                            onBlur={(value) => {
                                                handleBlur({
                                                    target: {
                                                        name: NameOfField,
                                                        value: true,
                                                    },
                                                });
                                            }}
                                            value={
                                                values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                                || []}
                                            options={v.options}
                                            touched={touched?.NameOfField}
                                            placeholder={`Enter ${NameOfField}`} />

                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <Divider orientation="left" orientationMargin="0" />
                        <div className="row">
                            <div className="col-xl-3">
                                <h3 style={{ color: 'orangered' }}>ONCOLOGY</h3>
                                {laboratory?.[0]?.filter((v) => v?.category === 'ONCOLOGY')?.map((v, i) => {
                                    const NameOfField = v?.name
                                    const CategoryOfField = v?.category
                                    let laboratoryData = Object.assign([], values?.laboratory);
                                    let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                    return (<SingleMultiSelectDropDown
                                        key={i + NameOfField}
                                        label={NameOfField}
                                        type={NameOfField}
                                        FormGroupClassName={""}
                                        LabelClassName={"col-form-label"}
                                        name={NameOfField}
                                        onChange={(value) => {
                                            if (isCategoryOfFieldExist) {
                                                laboratoryData?.map((val) => {
                                                    if (val?.name === NameOfField) {
                                                        val.options = value
                                                    }
                                                })
                                                setValues({ ...values, laboratory: laboratoryData })

                                            } else {
                                                handleChange({
                                                    target: {
                                                        name: name,
                                                        value: [
                                                            ...values.laboratory,
                                                            {
                                                                "category": CategoryOfField,
                                                                "name": NameOfField,
                                                                "options": value,
                                                            }
                                                        ],
                                                    },
                                                });
                                            }
                                        }} mControlClassName={'form-control'}
                                        onBlur={(value) => {
                                            handleBlur({
                                                target: {
                                                    name: NameOfField,
                                                    value: true,
                                                },
                                            });
                                        }}
                                        value={
                                            values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                            || []}
                                        options={v.options}
                                        touched={touched?.NameOfField}
                                        placeholder={`Enter ${NameOfField}`} />
                                    )
                                })}
                            </div>
                            <div className="col-xl-3">
                                <h3 style={{ color: 'orangered' }}>DRUGS & OTHERS</h3>
                                {laboratory?.[0]?.filter((v) => v?.category === 'DRUGS & OTHERS')?.map((v, i) => {
                                    const NameOfField = v?.name
                                    const CategoryOfField = v?.category
                                    let laboratoryData = Object.assign([], values?.laboratory);
                                    let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                    return (<SingleMultiSelectDropDown
                                        key={i + NameOfField}
                                        label={NameOfField}
                                        type={NameOfField}
                                        FormGroupClassName={""}
                                        LabelClassName={"col-form-label"}
                                        name={NameOfField}
                                        onChange={(value) => {
                                            if (isCategoryOfFieldExist) {
                                                laboratoryData?.map((val) => {
                                                    if (val?.name === NameOfField) {
                                                        val.options = value
                                                    }
                                                })
                                                setValues({ ...values, laboratory: laboratoryData })

                                            } else {
                                                handleChange({
                                                    target: {
                                                        name: name,
                                                        value: [
                                                            ...values.laboratory,
                                                            {
                                                                "category": CategoryOfField,
                                                                "name": NameOfField,
                                                                "options": value,
                                                            }
                                                        ],
                                                    },
                                                });
                                            }
                                        }}
                                        FormControlClassName={'form-control'}
                                        onBlur={(value) => {
                                            handleBlur({
                                                target: {
                                                    name: NameOfField,
                                                    value: true,
                                                },
                                            });
                                        }}
                                        value={
                                            values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                            || []}
                                        options={v.options}
                                        touched={touched?.NameOfField}
                                        placeholder={`Enter ${NameOfField}`} />)
                                })}
                            </div>
                            <div className="col-xl-3">
                                <h3 style={{ color: 'orangered' }}>CHROMOSOMAL STUDY</h3>
                                {laboratory?.[0]?.filter((v) => v?.category === 'CHROMOSOMAL STUDY')?.map((v, i) => {
                                    const NameOfField = v?.name
                                    const CategoryOfField = v?.category
                                    let laboratoryData = Object.assign([], values?.laboratory);
                                    let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                    return (<SingleMultiSelectDropDown
                                        key={i + NameOfField}
                                        label={NameOfField}
                                        type={NameOfField}
                                        FormGroupClassName={""}
                                        LabelClassName={"col-form-label"}
                                        name={NameOfField}
                                        onChange={(value) => {
                                            if (isCategoryOfFieldExist) {
                                                laboratoryData?.map((val) => {
                                                    if (val?.name === NameOfField) {
                                                        val.options = value
                                                    }
                                                })
                                                setValues({ ...values, laboratory: laboratoryData })

                                            } else {
                                                handleChange({
                                                    target: {
                                                        name: name,
                                                        value: [
                                                            ...values.laboratory,
                                                            {
                                                                "category": CategoryOfField,
                                                                "name": NameOfField,
                                                                "options": value,
                                                            }
                                                        ],
                                                    },
                                                });
                                            }
                                        }}
                                        FormControlClassName={'form-control'}
                                        onBlur={(value) => {
                                            handleBlur({
                                                target: {
                                                    name: NameOfField,
                                                    value: true,
                                                },
                                            });
                                        }}
                                        value={
                                            values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                            || []}
                                        options={v.options}
                                        touched={touched?.NameOfField}
                                        placeholder={`Enter ${NameOfField}`} />

                                    )
                                })}
                            </div>
                            <div className="col-xl-3">
                                <h3 style={{ color: 'orangered' }}>MICROBIOLOGY</h3>
                                {laboratory?.[0]?.filter((v) => v?.category === 'MICROBIOLOGY')?.map((v, i) => {
                                    const NameOfField = v?.name
                                    const CategoryOfField = v?.category
                                    let laboratoryData = Object.assign([], values?.laboratory);
                                    let isCategoryOfFieldExist = values?.laboratory?.find((val) => val?.name === NameOfField)
                                    return (<SingleMultiSelectDropDown
                                        key={i + NameOfField}
                                        label={NameOfField}
                                        type={NameOfField}
                                        FormGroupClassName={""}
                                        LabelClassName={"col-form-label"}
                                        name={NameOfField}
                                        onChange={(value) => {
                                            if (isCategoryOfFieldExist) {
                                                laboratoryData?.map((val) => {
                                                    if (val?.name === NameOfField) {
                                                        val.options = value
                                                    }
                                                })
                                                setValues({ ...values, laboratory: laboratoryData })

                                            } else {
                                                handleChange({
                                                    target: {
                                                        name: name,
                                                        value: [
                                                            ...values.laboratory,
                                                            {
                                                                "category": CategoryOfField,
                                                                "name": NameOfField,
                                                                "options": value,
                                                            }
                                                        ],
                                                    },
                                                });
                                            }
                                        }}
                                        FormControlClassName={'form-control'}
                                        onBlur={(value) => {
                                            handleBlur({
                                                target: {
                                                    name: NameOfField,
                                                    value: true,
                                                },
                                            });
                                        }}
                                        value={
                                            values?.laboratory?.find((e) => e.name === NameOfField)?.options
                                            || []}
                                        options={v.options}
                                        touched={touched?.NameOfField}
                                        placeholder={`Enter ${NameOfField}`} />
                                    )
                                })}
                            </div>
                        </div>
                        <Divider orientation="left" orientationMargin="0" />
                        <div className="form-group">
                            <label className="col-form-label" style={{ color: errors?.laboratoryNote && touched?.laboratoryNote && 'red' }} >
                                Notes:
                                {errors?.laboratoryNote && <span className="login-danger"> *</span>}
                            </label>
                            <textarea
                                rows={7}
                                cols={5}
                                className="form-control"
                                placeholder="Enter Notes"
                                defaultValue={""}
                                style={{ borderColor: errors?.laboratoryNote && touched?.laboratoryNote ? 'red' : '' }}
                                value={values?.laboratoryNote}
                                onBlur={(value) => {
                                    handleBlur({
                                        target: {
                                            name: 'laboratoryNote',
                                            value: true,
                                        },
                                    });
                                }} onChange={(value) => {
                                    const V = value.target.value
                                    handleChange({
                                        target: {
                                            name: 'laboratoryNote',
                                            value: V,
                                        },
                                    });
                                }} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

LabInvestigationComponent.propTypes = {
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    laboratory: PropTypes.array,
    setValues: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    values: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

};
