/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { ResponsiveComponent, generateQueryString, trimText } from "../../utils/Funcations";
import { Button, Modal, Popover } from "antd";
import { ActionDeleteById, ActionGetGalleryList } from "../../store/Actions/CommonAction";
import { matchPermissions } from "../../utils/Permission";
import { TableListPageCom } from "../../components/TableListPageCom";

const Gallerylist = () => {
    const { confirm } = Modal;
    const Size = ResponsiveComponent()
    const pageSize = 10;
    const dispatch = useDispatch();
    const { galleryList, loader } = useSelector(state => state?.Common);
    const { userDetails } = useSelector(state => state?.auth);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const page = searchParams.get("page");
    const Final = generateQueryString(['page', page || 1]);

    const params = queryString.parse(location.search);
    useEffect(() => {
        dispatch(ActionGetGalleryList({ Query: Final, }));
    }, [])
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('gallery.update');
    const canDelete = matchPermissionsForUser('gallery.delete');

    const content = (item) => (
        <div className="row col">
            {canUpdate &&
                <Button className="col m-2" onClick={() => { window.location.href = `/add-gallery?update=true&&page=${1}&&id=${item?._id}` }}>Edit Gallery</Button>
            }
            {canDelete &&
                <Button className="col m-2" onClick={() => showDeleteConfirm(item)}>Delete Gallery</Button>
            }
        </div>
    );
    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.name}?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteById({ Query: `admin/gallery/${item?._id}` }, (Res) => {
                    if (Res?.statusCode === 200) {
                        window.location.reload()
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Name",
            dataIndex: "keys",

        },
        {
            title: "Descriptions",
            dataIndex: "description",
            render: (text, record) => (
                <div className="app-config-boldText">{trimText(text, Size === "phone" || Size === "tablet" ? 20 : 50)}</div>
            ),

        },
        {
            title: "Image",
            dataIndex: "",

        },
        {
            title: !canUpdate && !canUpdate ? null : "Action",
            dataIndex: "keys",
            render: (text, record,) => {
                if (!canUpdate && !canUpdate) {
                    return null
                } else {
                    return <Popover placement="top" title={text} content={() => content(record)}>
                        <Button type="text">• • •</Button>
                    </Popover>
                }
            },
        },
    ]
    const BreadcrumbList = [{
        name: 'Gallery',
        linkTo: `/add-gallery?page=${params?.page || 1}`
    },
    {
        name: 'gallery List',
    }
    ]

    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={galleryList?.[0]?.docs}
            TableColumn={columns}
            ShowAddButton={true}
            Iscreate="gallery.create"
            CreateButtonLink={`/add-gallery?update=false&&page=${params?.page || 1}`}
            PageMainTitle="Gallery List"
            CurrentPage={galleryList?.page || 1}
            Tableloader={loader}
            TotalPageList={galleryList?.totalDocs}
            dataSourceLength={galleryList?.docs?.length}
            handlePageChange={(v) => {
                var newp = params
                newp.page = v
                window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
            }}
        />

    )
};

export default Gallerylist;
