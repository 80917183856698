/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { FormInput, RadioButton, SingleMultiSelectDropDown } from "../../../components/Input/InputText";
import { useDispatch, useSelector } from "react-redux";
import { GetRoleListAction } from "../../../store/Actions/RolesAction";
import { InputFromUser } from "../../../components/Input/input";
import { AddStaffValidationSchema } from "../../../utils/FormValidation";
import { CreateStaffAction } from "../../../store/Actions/StaffAction";
import { Button } from "antd";

const AddStaff = () => {

  const dispatch = useDispatch();
  const { RoleList, loader } = useSelector(state => state?.Role);
  useEffect(() => {
    dispatch(GetRoleListAction({ Query: '/get_all_roles' }))
  }, [])
  return (
    <div>
      <Header />
      <Sidebar id="menu-item1" id1="menu-items1" activeClassName="add-staff" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link>Staff</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/staff-list?page=1">Staff List</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Staff</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        name: "",
                        phoneNo: "",
                        password: "",
                        confirmPassword: "",
                        dateOfBirth: "",
                        gender: "",
                        status: "",
                        email: "",
                        role: "",
                        orderIndex: "",
                      }}
                      validationSchema={AddStaffValidationSchema}
                      onSubmit={(values, { setErrors, setSubmitting }) => {
                        const filteredData = RoleList?.[0]?.filter(item => item.roleName === values.role);
                        dispatch(CreateStaffAction({
                          Query: '', obj: {
                            ...values,
                            role: { roleName: filteredData?.[0]?.roleName, _id: filteredData?.[0]?.id },
                          }
                        }, (Res) => {
                          if (Res?.statusCode === 200) {
                            window.location.href = `/staff-list?page=1`
                          } else if (Res?.statusCode === 400) {
                            window.scrollTo({
                              top: 0,
                              behavior: 'smooth',
                            });
                            setErrors(Res?.data?.errors);
                          }
                          setSubmitting(false);
                        }))
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setValues,
                        setSubmitting,
                        isSubmitting,
                        /* and other goodies */
                      }) => {
                        return (
                          <Form>
                            <div className="row">
                              <FormInput
                                label="Name"
                                type="name"
                                FormGroupClassName={"col-xl-6 col-md-12"}
                                LabelClassName={"col-form-label"}
                                name="name"
                                onChange={handleChange}
                                FormControlClassName={'form-control '}
                                onBlur={handleBlur}
                                error={errors.name}
                                value={values.name}
                                touched={touched.name}
                                placeholder={'Enter Your name'}
                              />
                              <InputFromUser
                                label="Birthdate"
                                type="date"
                                name="dateOfBirth"
                                onChange={(value) => {
                                  handleChange({
                                    target: {
                                      name: 'dateOfBirth',
                                      value: value,
                                    },
                                  });
                                }}
                                onBlur={(value) => {
                                  handleBlur({
                                    target: {
                                      name: 'dateOfBirth',
                                      value: true,
                                    },
                                  });
                                }}
                                FormControlClassName={'form-control'}
                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                LabelClassName={"col-form-label"}
                                value={values.dateOfBirth}
                                error={errors.dateOfBirth}
                                touched={touched.dateOfBirth}
                                placeholder={'Select From Date '}
                              />
                              <FormInput
                                label="Phone No"
                                type="phoneNo"
                                FormGroupClassName={"col-xl-6 col-md-12"}
                                LabelClassName={"col-form-label"}
                                name="phoneNo"
                                onChange={handleChange}
                                FormControlClassName={'form-control '}
                                onBlur={handleBlur}
                                error={errors.phoneNo}
                                value={values.phoneNo}
                                touched={touched.phoneNo}
                                placeholder={'Enter Your phoneNo'}
                              />

                              <FormInput
                                label="email"
                                type="email"
                                FormGroupClassName={"col-xl-6 col-md-12"}
                                LabelClassName={"col-form-label"}
                                name="email"
                                onChange={handleChange}
                                FormControlClassName={'form-control '}
                                onBlur={handleBlur}
                                error={errors.email}
                                value={values.email}
                                touched={touched.email}
                                placeholder={'Enter Your email'}
                              />

                              <SingleMultiSelectDropDown
                                label="role"
                                type="role"
                                Showlabel={true}
                                mode={''}
                                name="role"
                                onChange={(v) => { setFieldValue("role", v, true) }}
                                FormControlClassName={'form-control'}
                                FormGroupClassName={"col-xl-6 col-md-12"}
                                LabelClassName={"col-form-label"}
                                value={values?.role}
                                onBlur={() => { setFieldTouched("role", true, true) }}
                                error={errors?.role}
                                touched={touched?.role}
                                onSearchLoading={false}
                                options={RoleList?.length != 0 ? RoleList?.[0]?.map(item => item?.roleName) : []}
                                placeholder={'Enter Your Role'} />
                              <InputFromUser
                                label="Password"
                                type="password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                FormControlClassName={'form-control'}
                                FormGroupClassName={"col-xl-6 col-md-12"}
                                LabelClassName={"col-form-label"}
                                value={values.password}
                                error={errors.password}
                                touched={touched.password}
                                placeholder={'Enter Your Password'}
                              />
                              <InputFromUser
                                label="Confirm Password"
                                type="password"
                                name="confirmPassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                FormControlClassName={'form-control'}
                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                LabelClassName={"col-form-label"}
                                value={values.confirmPassword}
                                error={errors.confirmPassword}
                                touched={touched.confirmPassword}
                                placeholder={'Enter Your Password'}
                              />
                              <RadioButton label={"Gender"} options={[
                                { label: 'Male', value: 'Male' },
                                { label: 'Female', value: 'Female' },
                              ]}
                                onBlur={(v) => handleBlur({
                                  target: {
                                    name: 'gender',
                                    value: true,
                                  },
                                })}
                                onChange={(v) => handleChange({
                                  target: {
                                    name: 'gender',
                                    value: v,
                                  },
                                })}
                                error={errors.gender}
                                selectedValue={values.gender}
                                touched={touched.gender}

                              />

                              < RadioButton label={"Status"} options={[
                                { label: 'Active', value: 'active' },
                                { label: 'Inactive', value: 'inactive' },
                              ]}
                                onBlur={(v) => handleBlur({
                                  target: {
                                    name: 'status',
                                    value: true,
                                  },
                                })} onChange={(v) => handleChange({
                                  target: {
                                    name: 'status',
                                    value: v,
                                  },
                                })}
                                error={errors.status}
                                key={'status'}
                                selectedValue={values.status}
                                touched={touched.status}

                              />
                              <InputFromUser
                                label="Order Index"
                                type="number"
                                name="orderIndex"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                FormControlClassName={'form-control'}
                                FormGroupClassName={"col-xl-6 col-md-12 "}
                                LabelClassName={"col-form-label"}
                                value={values.orderIndex}
                                error={errors.orderIndex}
                                touched={touched.orderIndex}
                                placeholder={'Enter Your Order Index'}
                              />



                              <div className="col-12 mt-5">
                                <div className="text-end">
                                  <Button
                                    onClick={() => {
                                      handleSubmit()
                                    }}
                                    type="primary"
                                    disabled={isSubmitting}
                                    className="me-2"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>)
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div >
  );
};

export default AddStaff;
