import { UPDATE_PERSONAL_DETAILS, UPDATE_PERSONAL_DETAILS_SUCCESS } from "../storeTypes";

const INIT_STATE =
{
    personalDetils: [
        {
            value: '',
            title: "Date of Birth",
            personalDetilsKey: 'dob',
            type: 'date',
            QuestionType: 'PersonalDetails'
        }, // 0 index
        {
            value: '',
            title: "Age",
            personalDetilsKey: 'age',
            type: 'text',
            QuestionType: 'PersonalDetails'
        }, // 1 index
        {
            value: '',
            title: "Gender",
            personalDetilsKey: 'gender',
            Option: [{
                title: 'Male',
                isChecked: false
            },
            {
                title: 'Female',
                isChecked: false
            },],
            type: 'radio',
            QuestionType: 'PersonalDetails'

        }, // 2 index
        {
            value: '',
            title: "Height",
            personalDetilsKey: 'height',
            type: 'text',
            QuestionType: 'PersonalDetails'
        }, // 3 index
        {
            value: '',
            title: "Weight",
            personalDetilsKey: 'weight',
            type: 'text',
            QuestionType: 'PersonalDetails'
        }, // 4 index
        {
            value: '',
            title: "Enter Your Full Address",
            personalDetilsKey: 'address',
            type: 'text',
            QuestionType: 'PersonalDetails'
        }, // 5 index
        {
            value: '',
            title: "Enter Your Highest Education",
            personalDetilsKey: 'Education',
            type: 'text',
            QuestionType: 'PersonalDetails'

        }, // 6 index
        {
            value: '',
            title: "Enter Your Occupation",
            personalDetilsKey: 'Occupation',
            type: 'text',
            QuestionType: 'PersonalDetails'

        }, // 7 index
        {
            value: [],
            title: "Have you ever suffered from any of these diseases?",
            personalDetilsKey: 'diseases',
            Option: ['Measles',
                'Chicken pox',
                'Small Pox',
                'Malaria',
                'Typhoid',
                'Jaundice',
                'Dysentry',
                'Worms',
                'Conjuntivitis',
                'Ear Problems',
                'Heart Disease',
                'Kidney Stone',
                'Acidity'
            ],
            type: 'multiple',
            QuestionType: 'PersonalDetails'
        }, // 8 index
        {
            value: [],
            title: "Do any of these diseases run in your family?",
            personalDetilsKey: 'diseases_in_your_family',
            Option: ['Blood Pressure',
                'Diabetes',
                'Leucoderma',
                'Dwarfism',
                'Asthma',
                'Cancer',
                'Heart Disease'
            ],
            type: 'multiple',
            QuestionType: 'PersonalDetails'


        },
        {
            value: '',
            title: "Chief Complaints",
            personalDetilsKey: 'ChiefComplaints',
            type: 'text',
            QuestionType: 'PersonalDetails'
        },// 9 index
        //Body Analysis - Stool
        {
            value: '',
            title: "Do you have a bowel movement everday?",
            personalDetilsKey: 'Do_u_have_bowel_movement_everday',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'BodyAnalysis'

        }, // 10 index
        {
            value: "",
            title: "How many times in a day do you have to go?",
            personalDetilsKey: 'How_many_times_in_day_do_you_have_to_go?',
            Option: ['1', '2', '3', '4',
                '5'
            ],
            type: 'singleSelect',
            QuestionType: 'BodyAnalysis'


        }, // 11 index
        {
            value: [],
            title: "Nature of Stools",
            personalDetilsKey: 'Stools',
            Option: ['Hard Like a stone',
                'Like a peeled banana',
                'Sticky',
                'Liquid',
                'Spreads everywhere'
            ],
            type: 'multiple',
            QuestionType: 'BodyAnalysis'

        }, // 12 index
        {
            value: '',
            title: "Do you have bleeding through Anus?",
            personalDetilsKey: 'Anus',
            Option: ['Yes', 'No'],
            type: 'singleSelect',
            QuestionType: 'BodyAnalysis'
        }, // 13 index
        {
            value: [],
            title: "Do the stools sink or Float in water?",
            personalDetilsKey: 'Float_in_water',
            Option: ['Stink', 'Float'],
            type: 'multiple',
            QuestionType: 'BodyAnalysis'

        }, // 14 index
        {
            value: '',
            title: "Do you suffer from excessive gases?",
            personalDetilsKey: 'excessive_gases',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'BodyAnalysis'
        }, // 15 index
        {
            value: '',
            title: "Are you having Dysentry?",
            personalDetilsKey: 'Dysentry',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'BodyAnalysis'
        }, // 16 index
        {
            value: '',
            title: "Do the stools stink a lot?",
            personalDetilsKey: 'stink_lot',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'BodyAnalysis'
        }, // 17 index
        {
            value: '',
            title: "Do you have a reading habit or use the phone when passing stools?",
            personalDetilsKey: 'passing_stools',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'BodyAnalysis'
        }, // 18 index
        //  Body Analysis - Urine
        {
            value: '',
            title: "How many times in a day do you pass urine?",
            personalDetilsKey: '_in_day_you_pass_urine',
            Option: ['1', '2', '3', '4',
                '5', '6', '7',
                '8', '9', '10',
                'more than 10'
            ],
            type: 'singleSelect',
            QuestionType: 'BodyAnalysis'


        }, // 19 index
        {
            value: [],
            title: "Is there any bleeding or pus in the urine?",
            personalDetilsKey: 'urine',
            Option: ['No', 'Bleeding',
                'Pus'
            ],
            type: 'multiple',
            QuestionType: 'BodyAnalysis'

        }, // 20 index
        {
            value: '',
            title: "Urine Colour",
            personalDetilsKey: 'UrineColour',
            Option: ["White",
                "Light Yellow",
                "Dark Yellow",
                "Turbid",
                "Reddish"
            ],
            type: 'singleSelect',
            QuestionType: 'BodyAnalysis'


        }, // 21 index
        {
            value: '',
            title: "Do you have burning sensation during Urination?",
            personalDetilsKey: 'during_Urination',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'BodyAnalysis'
        }, // 22 index
        {
            value: '',
            title: "Do you have to get up at night to urniate?",
            personalDetilsKey: 'night_to_urniate',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'BodyAnalysis'
        }, // 23 index

        //  Body Analysis - Sweat
        {
            value: '',
            title: "Do you sweat all round the year?",
            personalDetilsKey: 'sweat_all_round',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'BodyAnalysis'
        }, // 24 index
        {
            value: '',
            title: "Do you Sweat more or less as compared to others?",
            personalDetilsKey: 'sweat_compared_to_others',
            Option: ["More", "Less",
                "Average"
            ],
            type: 'singleSelect',
            QuestionType: 'BodyAnalysis'

        }, // 25 index
        {
            value: '',
            title: "Does the Sweat smell?",
            personalDetilsKey: 'Sweatsmell',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'BodyAnalysis'
        }, // 26 index
        {
            value: '',
            title: "Do your Palms and Soles also Sweat?",
            personalDetilsKey: 'Soles_also_Sweat',
            Option: ["No", "Both", "Palms",
                "Soles"
            ],
            type: 'singleSelect',
            QuestionType: 'BodyAnalysis'

        }, // 27 index
        //    Body Analysis - Menstruation
        {
            value: '',
            title: "At what Age did you get your Period?",
            personalDetilsKey: 'what_age_get_your_Period',
            type: 'text',
            for: 'Female',
            QuestionType: 'BodyAnalysis'
        }, // 28 index
        {
            value: '',
            title: "Are your Periods Regular?",
            personalDetilsKey: 'Periods_Regular',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            },

            ],
            type: 'radio',
            for: 'Female',
            QuestionType: 'BodyAnalysis'
        }, // 29 index
        {
            value: '',
            title: "After how many days do you get your Period?",
            personalDetilsKey: 'Periods_Regular',
            type: 'text',
            for: 'Female',
            QuestionType: 'BodyAnalysis'
        }, // 30 index
        {
            value: [],
            title: "For how many days does your period last?",
            personalDetilsKey: 'periodLastingdays',
            Option: ['2', '3', '4', '5',
                '6', '7'
            ],
            type: 'multiple',
            for: 'Female',
            QuestionType: 'BodyAnalysis'
        }, //31 index 
        {
            value: '',
            title: "What is the colour of the period blood?",
            personalDetilsKey: 'colorOfPeriod',
            Option: ['Red', 'Blackish'],
            type: 'singleSelect',
            for: 'Female',
            QuestionType: 'BodyAnalysis'
        }, //32 index 
        {
            value: [],
            title: "Do you face any other problems during your periods?",
            personalDetilsKey: 'problems',
            Option: ["Lower back ache",
                "Leg ache",
                "Stomach ache",
                "Vomiting"
            ],
            type: 'multiple',
            for: 'Female',
            QuestionType: 'BodyAnalysis'
        }, //33 index 
        {
            value: '',
            title: "Does the period blood contain clots?",
            personalDetilsKey: 'clots',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            for: 'Female',
            QuestionType: 'BodyAnalysis'
        }, //34 index
        //Body Analysis - Pregnancy
        {
            value: '',
            title: "How many time were you Pregnant?",
            personalDetilsKey: 'pregnant',
            type: 'text',
            for: 'Female',
            QuestionType: 'BodyAnalysis'
        }, //35 index 
        {
            value: [],
            title: "Was the delivery normal or Caesarean?",
            personalDetilsKey: 'deliveryType',
            Option: ['Normal', 'Caesarean'],
            type: 'multiple',
            for: 'Female',
            QuestionType: 'BodyAnalysis'

        }, //36 index 
        {
            value: '',
            title: "Have you undergone an Abortion?",
            personalDetilsKey: 'abortion',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            for: 'Female',
            QuestionType: 'BodyAnalysis'

        }, // 37 index
        {
            value: '',
            title: "In total how many times did you undergo an Abortion?",
            personalDetilsKey: 'abortionTime',
            for: 'Female',
            type: 'text'
        }, //38 index
        {
            value: '',
            title: "Have you ever undergone curetting",
            personalDetilsKey: 'curetting',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            for: 'Female',
            QuestionType: 'BodyAnalysis'

        }, // 39 index
        {
            value: '',
            title: "How many time have you undergone curetting?",
            personalDetilsKey: 'curettingTime',
            type: 'text',
            for: 'Female',
            QuestionType: 'BodyAnalysis'
        }, // 40 index
        {
            value: [],
            title: "Do you face any of these issues?",
            personalDetilsKey: 'stomachIssues',
            Option: ["Colitis", "Obesity",
                "Marks/Spots/Strech Marks",
                "Stomach Ache",
                "Vomiting",
                "Diarrhoea",
                "Constipation"
            ],
            type: 'multiple',
            QuestionType: 'BodyAnalysis'

        }, //41 index
        // Life Style Analysis
        {
            value: [],
            title: "Nature of Work",
            personalDetilsKey: 'natureOfwork',
            Option: ["Sitting", "Standing",
                "Hectic",
                "Mental Stress",
                "In an Air Conditioned room",
                "Intellectual"
            ],
            type: 'multiple',
            QuestionType: 'LifeStyleAnalysis'
        }, //42 INDEX
        {
            value: '',
            title: "How many hours of sleep do you get every night?",
            personalDetilsKey: 'hoursOfSleep',
            type: 'text',
            QuestionType: 'LifeStyleAnalysis'
        }, //43 index
        {
            value: '',
            title: "Is the sleep sound or disturbed?",
            personalDetilsKey: 'sleepType',
            Option: ["Sound", "Disturbed"],
            type: 'singleSelect',
            QuestionType: 'LifeStyleAnalysis'

        }, //44 index 
        {
            value: '',
            title: " Do you Sleep during the day?",
            personalDetilsKey: 'sleepDuringDay',
            Option: ["Yes-Before Lunch",
                "Yes-After Lunch",
                "No"
            ],
            type: 'singleSelect',
            QuestionType: 'LifeStyleAnalysis'

        }, //45 index 
        {
            value: '',
            title: " Do you Face any of these issues in your sleep?",
            personalDetilsKey: 'sleepIssues',
            Option: ["Biting teeth while sleeping",
                "Bed Wetting",
                "Talking While Sleeping",
                "Walking while sleeping",
                "None"
            ],
            type: 'singleSelect',
            QuestionType: 'LifeStyleAnalysis'

        }, //46 index 
        {
            value: [],
            title: " Do you have any of the following Addictions?",
            personalDetilsKey: 'addiction',
            Option: ['Beetle Nut',
                'Beetle Leaf',
                'Tobacco',
                'Bidi',
                'Cigarette',
                'Pan Parag',
                'Mawa',
                'Alcohol'
            ],
            type: 'multiple',
            QuestionType: 'LifeStyleAnalysis'

        }, //47 index 
        //  Mental Health
        {
            value: '',
            title: "Did you face any Mental Trauma before this disease occured?",
            personalDetilsKey: 'did_you_mentalTrauma',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'MentalHealth'

        }, // 49 index
        {
            value: '',
            title: "If yes, What?",
            personalDetilsKey: 'ifYesWhat',
            type: 'text',
            QuestionType: 'MentalHealth'
        }, //50 index
        {
            value: [],
            title: " How is your Temaparament?",
            personalDetilsKey: 'temaparament',
            Option: ["Sentimental", "Angry",
                "Suspicious",
                "Greedy",
                "Worrying",
                "Ambitious",
                "Thinking deeply about something that makes you Unhappy"
            ],
            type: 'multiple',
            QuestionType: 'MentalHealth'

        }, //51 index 
        {
            value: '',
            title: "Are you Satisfied with your Financial, Social, Family Status? ",
            personalDetilsKey: 'satisfied',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'MentalHealth'

        }, // 52 index
        {
            value: '',
            title: "Do you get upset if you have to compromise against your wish? ",
            personalDetilsKey: 'upset',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'MentalHealth'

        }, // 53 index
        {
            value: '',
            title: "Do you have a habit of comapring yourself with Someone else? ",
            personalDetilsKey: 'habitOfComparing',

            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'MentalHealth'

        }, // 54 index
        {
            value: '',
            title: "Are you afriad of Something? If yes please describe otherwise write No",
            personalDetilsKey: 'afraid',
            type: 'text',
            QuestionType: 'MentalHealth'
        }, //55 index
        {
            value: '',
            title: "Do you feel dull/unenergetic ",
            personalDetilsKey: 'unenergetic',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'MentalHealth'

        }, // 56 index
        {
            value: '',
            title: "Do you get Hallucinations? ",
            personalDetilsKey: 'hallucinations',
            Option: [{
                title: 'Yes',
                isChecked: false,
            },
            {
                title: 'No',
                isChecked: false,
            }],
            type: 'radio',
            QuestionType: 'MentalHealth'

        }, // 57 index
        {
            value: '',
            title: " Do you have memory loss?",
            personalDetilsKey: 'memoryLoss',
            Option: ["No", "Short Term",
                "Long Term"
            ],
            type: 'singleSelect',
            QuestionType: 'MentalHealth'
        }, //58 index
        {
            value: '',
            title: " How is the Atmosphere in your home?",
            personalDetilsKey: 'atmosphereInHome',
            Option: ["Peaceful",
                "Stressful",
                "Suspicious",
                "Hatred",
                "Jealousy",
                "Other"
            ],
            type: 'singleSelect',
            QuestionType: 'MentalHealth'

        },//59 index

    ]
}

function personalDetilsReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case UPDATE_PERSONAL_DETAILS: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_PERSONAL_DETAILS_SUCCESS: {
            return {
                ...state,
                loader: false,
                personalDetils: action.payload
            };
        }

        default: {
            return state;
        }
    }
}
export default personalDetilsReducer;
