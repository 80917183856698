import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import queryString from 'query-string';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
// import Sidebar from "../../../components/Sidebar";
import { Button, DatePicker } from "antd";
import Header from "../../components/Header";
import { InputFromUser } from "../../components/Input/input";
import AssignDoctorModal from "../../components/Modal/AssignDoctorModal";
import Sidebar from "../../components/Sidebar";
import { CustomTable } from "../../components/Table";
import { plusicon, refreshicon } from "../../components/imagepath";
import { ActionUpdateTreatmentSlot, GetAllTreatmentSlot } from "../../store/Actions/TreatmentSlotAction";
import { generateQueryString } from "../../utils/Funcations";
import { matchPermissions } from "../../utils/Permission";
import TableColumnSearch1 from "../../components/Input/TableColumnSearch1";


const TreatmentSlot = () => {
    const { loader, TreatmentSlot } = useSelector(state => state?.TreatmentSlotBooking);
    const { userDetails } = useSelector(state => state?.auth);
    const matchPermissionsForUser = matchPermissions(userDetails);
    const [Refresh, setRefresh] = useState(false);
    const [modal, SetModal] = useState({ is: false, id: "" });
    const pageSize = 10;
    const dispatch = useDispatch();
    const location = useLocation();
    const params = queryString.parse(location.search);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const pageParams = searchParams.get("page");
    const ascParams = searchParams.get("asc");
    const descParams = searchParams.get("desc");
    const phoneNoParams = searchParams.get("phoneNo");
    const statusParams = searchParams.get("status");
    const customerNameParams = searchParams.get("customerName");
    const startDateParams = searchParams.get("startDate");
    const endDateParams = searchParams.get("endDate");
    const sortOnParams = searchParams.get("sortOn");
    const sortParams = searchParams.get("sort");
    const date = searchParams.get("date");

    const [Query, setQuery] = useState({
        'page': pageParams,
        'asc': ascParams,
        'desc': descParams,
        'phoneNo': phoneNoParams,
        'status': statusParams || 'Upcoming',
        'customerName': customerNameParams,
        'startDate': startDateParams,
        'endDate': endDateParams,
        'sortOn': sortOnParams,
        'sort': sortParams,
        'date': date || null,
    },);

    const Filters = generateQueryString(
        ['page', (Query.page || 1)],
        ['asc', (Query.asc)],
        ['desc', Query.desc],
        ['phoneNo', Query.phoneNo],
        ['status', Query.status],
        ['customerName', Query.customerName],
        ['startDate', Query.startDate],
        ['endDate', Query.endDate],
        ['sortOn', Query.sortOn],
        ['sort', Query.sort],
        ['date', Query.date],

    )

    const handleStatusUpdate = (recordId, newStatus) => {
        dispatch(ActionUpdateTreatmentSlot({
            Query: recordId, obj: {
                "status": newStatus,
            }
        }, () => setRefresh(true)));
    };
    useEffect(() => {
        dispatch(GetAllTreatmentSlot({ Query: Filters }));
        window.history.replaceState(null, '', Filters);
        setRefresh(false);
    }, [Refresh, Filters])

    const handleDateRangeChange = (dates) => {
        setQuery({
            ...Query,
            startDate: dates[0]?.format('YYYY-MM-DD'),
            endDate: dates[1]?.format('YYYY-MM-DD'),
            page: null
        })
    };

    const canUpdate = matchPermissionsForUser('treatment-slot.update');

    const column = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Customer Name",
            dataIndex: "customerName",
            render: (text, record) => (
                <Link to={`/order-history-overview/${record?.orderId}`}>
                    {record?.customerName}
                </Link>
            ),
            ...TableColumnSearch1({
                dataIndex: 'customerName',
                queryValue: Query,
                onChange: (v) => setQuery(v)
            }),
        },
        {
            title: "Phone No.",
            dataIndex: "phoneNo",
        },
        {
            title: "Treatment Name",
            dataIndex: "treatmentName",
            render: (text, record) => (
                <span>{record?.treatmentData[0].treatmentName}</span>
            ),
        },
        {
            title: "Total Days",
            dataIndex: "totaldays",
            render: (text, record) => (
                <span>{record?.totalDays}</span>
            ),
        },
        {
            title: "Date",
            dataIndex: "dateRange",
            render: (text, record) => {
                const startDate = record.startDate;
                const endDate = record.endDate;
                const formattedDateRange = `${startDate} To ${endDate}`;
                return <span>{formattedDateRange}</span>;
            }
        },
        {
            title: "Care Taker",
            dataIndex: "careTakerCount",
            render: (text, record) => (
                <span>{record?.careTakerCount}</span>
            ),
        },

        {
            title: "Primary Guest",
            dataIndex: "parentCustomerName",
            render: (text, record) => (
                <span>{record?.parentCustomerName}</span>
            ),
        },
        {
            title: "Assigned Doctor",
            dataIndex: "doctorName",
            render: (text, record) => (
                <span>{record?.doctorName}</span>
            ),
        },
        {
            title: 'Update Treatment Status',
            dataIndex: 'status',
            render: (text, record) => (
                <>
                    {canUpdate &&
                        <InputFromUser
                            placeholder={""}
                            type="singleSelect"
                            name="status"
                            mode={''}
                            onChange={(newStatus) => handleStatusUpdate(record.id, newStatus)}
                            options={["Upcoming", "Completed"]}
                            FormGroupClassName={"col-xl-7 col-md-12 cursor-pointer"}
                            LabelClassName={"col-form-label cursor-pointer"}
                            value={record.status}
                            disabled={record.status === "Completed"}
                            label=""
                        />
                    }
                </>
            ),
        },
        {
            title: "Actions",
            dataIndex: "",
            render: (record) => {
                return <Button
                    type="primary"
                    onClick={() => SetModal({ is: true, id: record?._id })}
                    disabled={record.status === "Completed"}
                >
                    Assign Doctor
                </Button>
            },
        },
    ];
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/treatment-slot">Treatment Booking</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Treatment Booking List</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center justify-content-between">
                                                    <div className="col-12 col-md-8">
                                                        <div className="doctor-table-blk">
                                                            <h3>Treatment Booking List</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">
                                                                    {matchPermissionsForUser('treatment-slot.create') &&
                                                                        <Link
                                                                            to="/treatment-slot/add-treatment-slot"
                                                                            className="btn btn-primary add-pluss ms-2"
                                                                        >
                                                                            <img src={plusicon} alt="#" />
                                                                        </Link>
                                                                    }

                                                                    <Link
                                                                        onClick={() => window.location.reload()}
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 col-xl-6 mt-3 mb-md-0" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <DatePicker.RangePicker
                                                                onChange={handleDateRangeChange}
                                                                className="w-100"
                                                                allowClear={false}
                                                            />
                                                            <Button type="link" onClick={() => setQuery({
                                                                ...Query,
                                                                startDate: null,
                                                                endDate: null,
                                                                page: 1
                                                            })} style={{ marginLeft: '10px' }}>Clear Filters</Button>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-12 col-md-4"> */}
                                                    <div className={`dropdown-color col-12 col-md-4 ${Query.status === 'Completed' ? 'Completed' : Query.status === 'Cancelled' ? 'Cancelled' : 'Upcoming'}`}>

                                                        {/* <span className='pe-3 text-capitalize' style={{ fontWeight: "600" }}>Booking Status</span> */}
                                                        <InputFromUser
                                                            placeholder={""}
                                                            type="singleSelect"
                                                            name="status"
                                                            mode={''}
                                                            onChange={(v) => setQuery({ ...Query, status: v })}
                                                            options={["Upcoming", "Completed", "Cancelled", "InProgress"]}
                                                            FormGroupClassName={"col-xl-6 col-md-12 cursor-pointer m-0 p-0"}
                                                            LabelClassName={"col-form-label cursor-pointer"}
                                                            value={Query.status}
                                                            label="Booking Status"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <CustomTable
                                                CurrentPageNum={Query?.page || 1}
                                                OnChange={(v) => setQuery({ ...Query, page: v })}
                                                TotalPageList={TreatmentSlot?.[0]?.totalDocs}
                                                column={column}
                                                dataSource={TreatmentSlot?.[0]?.docs}
                                                dataSourceLength={TreatmentSlot?.[0]?.docs?.length}
                                                pageSize={10}
                                                loader={loader}
                                                ShowHeader={true}
                                                Header={<div className="staff-search-table">
                                                </div>}
                                                key={"hi"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <AssignDoctorModal
                IsOpen={modal}
                slotType={'treatment'}
                initialValues={{
                    doctorId: TreatmentSlot?.[0]?.docs?.doctorId || '',
                    doctorName: TreatmentSlot?.[0]?.docs?.doctorName || '',
                }}
                Isclose={() => SetModal({ is: false, id: "" })} />

        </>
    );
};

export default TreatmentSlot;