import { Modal } from 'antd';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { InputFromUser } from '../Input/input';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminUser } from '../../store/Actions/AppointmentAction';
import { ActionGetTreatmentSlotById } from '../../store/Actions/TreatmentSlotAction';
import { ActionUpdateById } from '../../store/Actions/CommonAction';
import { ActionGetSolutionSlotById } from '../../store/Actions/SolutionSlotAction';
import { ActionGetServiceSlotById } from '../../store/Actions/ServiceSlotAction';
// eslint-disable-next-line react/prop-types
const AssignDoctorModal = ({ IsOpen = {}, Isclose, slotType, initialValues }) => {
    const { AdminUser } = useSelector(state => state?.Appointment);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAdminUser({ Query: 'Doctor' }))
    }, [])
    useEffect(() => {
        if (IsOpen.id) {
            let action;
            if (slotType === 'treatment') {
                action = ActionGetTreatmentSlotById({ Query: `/${IsOpen.id}` });
            } else if (slotType === 'solution') {
                action = ActionGetSolutionSlotById({ Query: `/${IsOpen.id}` });
            } else if (slotType === 'service') {
                action = ActionGetServiceSlotById({ Query: `/${IsOpen.id}` });
            }
            dispatch(action);
        }
    }, [dispatch, IsOpen.id, slotType]);
    return (
        <>
            <div>
                <div className="page-wrapper">
                    <div className="content">
                        <div className="row">
                            <div className="col-sm-12">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={initialValues}
                                    onSubmit={(values, { setErrors, setSubmitting }) => {
                                        let action;
                                        if (slotType === 'treatment') {
                                            action = ActionUpdateById({ Query: `admin/treatment-slot/${IsOpen.id}`, obj: values }, (Res) => {
                                                if (Res?.statusCode === 200) {
                                                    window.location.href = `/treatment-slot?page=1`
                                                } else if (Res?.statusCode === 400) {
                                                    setErrors(Res?.data?.errors);
                                                }
                                                setSubmitting(false);
                                            });
                                        } else if (slotType === 'solution') {
                                            action = ActionUpdateById({ Query: `admin/solution-slot/${IsOpen.id}`, obj: values }, (Res) => {
                                                if (Res?.statusCode === 200) {
                                                    window.location.href = `/solution-slot?page=1`
                                                } else if (Res?.statusCode === 400) {
                                                    setErrors(Res?.data?.errors);
                                                }
                                                setSubmitting(false);
                                            });
                                        } else if (slotType === 'service') {
                                            action = ActionUpdateById({ Query: `admin/service-slot/${IsOpen.id}`, obj: values }, (Res) => {
                                                if (Res?.statusCode === 200) {
                                                    window.location.href = `/service-slot?page=1`
                                                } else if (Res?.statusCode === 400) {
                                                    setErrors(Res?.data?.errors);
                                                }
                                                setSubmitting(false);
                                            });
                                        }
                                        dispatch(action);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        setFieldValue,
                                        setFieldTouched,
                                        handleSubmit,

                                    }) => {
                                        return (
                                            <div>
                                                <Modal
                                                    open={IsOpen?.is}
                                                    closable={true}

                                                    forceRender={true}
                                                    zIndex={1040}
                                                    onOk={() => handleSubmit()}
                                                    styles={{
                                                        body: {
                                                            overflow: "hidden",
                                                        },
                                                        mask: {
                                                            backdropFilter: 'blur(10px)',
                                                        },
                                                        content: {
                                                            borderRadius: 40,
                                                        },
                                                    }}
                                                    okText={'Submit'}
                                                    centered

                                                    onCancel={Isclose}
                                                    closeIcon={true}
                                                >
                                                    <div className="row" >
                                                        <div className="col-12">
                                                            {/* <div className="invoice-add-table"> */}
                                                            <h4>Assign Doctor</h4>
                                                            <div className="p-0">
                                                                <InputFromUser
                                                                    label="Select Doctor"
                                                                    type="singleSelect"
                                                                    name="doctorName"
                                                                    onChange={(selectedDoctorName) => {
                                                                        const selectedDoctor = AdminUser?.[0]?.find((doctor) => doctor?.name === selectedDoctorName);

                                                                        setFieldValue('doctorId', selectedDoctor?._id);
                                                                        setFieldValue('doctorName', selectedDoctorName);

                                                                    }}
                                                                    onBlur={() => {
                                                                        setFieldTouched('doctorName', true);
                                                                    }}
                                                                    FormControlClassName={'form-control'}
                                                                    FormGroupClassName={"col-12 "}
                                                                    LabelClassName={"col-form-label"}
                                                                    value={values.doctorName}
                                                                    error={errors.doctorName}
                                                                    touched={touched.doctorName}
                                                                    options={AdminUser?.[0] ? AdminUser?.[0]?.map((v) => v.name) : []}
                                                                />
                                                            </div>
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                </Modal >
                                            </div>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AssignDoctorModal