import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetAllcelebrity } from '../../store/Actions/CelebrityAction'
import { ResponsiveComponent, generateQueryString, trimText } from '../../utils/Funcations'
import queryString from 'query-string'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Popover, Modal } from 'antd'
import { ActionDeleteById } from '../../store/Actions/CommonAction'
import { matchPermissions } from '../../utils/Permission'
import { TableListPageCom } from '../../components/TableListPageCom'

const CelebrityList = () => {
    const { confirm } = Modal;
    const Size = ResponsiveComponent()
    const pageSize = 10;
    const dispatch = useDispatch();
    const { Celebritylist, loader } = useSelector(state => state?.Celebrity);
    const { userDetails } = useSelector(state => state?.auth);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const page = searchParams.get("page");
    const CelebrityName = searchParams.get("CelebrityName");
    const Final = generateQueryString(['page', page || 1],
        ['celebrityName', CelebrityName],
    );
    const params = queryString.parse(location.search);
    useEffect(() => {
        dispatch(GetAllcelebrity({ Query: Final, }));
    }, [])
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('celebrity.update');
    const canDelete = matchPermissionsForUser('celebrity.delete');
    // const canCerate = matchPermissionsForUser('celebrity.create');
    const content = (item) => (
        <div className="row col">
            {canUpdate &&
                <Button className="col m-2" onClick={() => { window.location.href = `/add-celebrity?update=true&&page=${1}&&id=${item?.id}` }}>Edit Celebrity</Button>
            }
            {canDelete &&
                <Button className="col m-2" onClick={() => showDeleteConfirm(item)}>Delete Celebrity</Button>
            }
        </div>
    );

    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.celebrityName} celebrity?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteById({ Query: `admin/celebrity/${item?._id}` }, (Res) => {
                    if (Res?.statusCode === 200) {
                        //setRefresh(true)
                        window.location.reload()
                    }
                }))
            },
            onCancel() {
            },
        });
    };
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Celebrity Name",
            dataIndex: "celebrityName",

        },
        {
            title: "Celebrity Descriptions",
            dataIndex: "celebrityShortDescription",
            render: (text) => (
                <div className="app-config-boldText">{trimText(text, Size === "phone" || Size === "tablet" ? 20 : 50)}</div>
            ),

        },


        {
            title: !canUpdate && !canDelete ? null : "Action",
            dataIndex: "roleName",
            render: (text, record) => {
                if (!canUpdate && !canDelete) {
                    return null
                } else {
                    return <Popover placement="top" title={text} content={() => content(record)}>
                        <Button type="text">• • •</Button>
                    </Popover >
                }
            },
        },
    ]
    const BreadcrumbList = [{
        name: 'Celebrity',
        linkTo: `/add-celebrity?page=${params?.page || 1}`
    },
    {
        name: 'Celebrity List',
    }
    ]
    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={Celebritylist?.[0]?.docs}
            TableColumn={columns}
            ShowAddButton={true}
            Iscreate="celebrity.create"
            CreateButtonLink={`/add-celebrity?update=false&&page=${params?.page || 1}`}
            PageMainTitle="Celebrity List"
            CurrentPage={Celebritylist?.page || 1}
            Tableloader={loader}
            TotalPageList={Celebritylist?.totalDocs}
            dataSourceLength={Celebritylist?.docs?.length}
            handlePageChange={(v) => {
                var newp = params
                newp.page = v
                window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
            }}
        />

    )
}

export default CelebrityList