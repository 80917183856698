/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { AppConfigValidationSchema } from "../../../utils/FormValidation";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreateAppConfig, ActionGetAppConfigList, ActionUpdateAppConfig } from "../../../store/Actions/AppConfigAction";
import { InputFromUser } from "../../../components/Input/input";
const Add_AppConfig = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const updateValue = searchParams.get("update");
    const pageValue = searchParams.get("page");
    const idValue = searchParams.get("id");
    useEffect(() => {
        if (idValue) {
            dispatch(ActionGetAppConfigList(`/${idValue}`));
        }
    }, [pageValue, dispatch])
    const { appConfig, loader } = useSelector(state => state?.appConfig);
    const Language = ['Hindi', 'Marathi']
    return (
        <>
            {/* <Loader IsModal={loader} /> */}
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/app-config">App Config</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Configration</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue}
                                                initialValues={{
                                                    key: appConfig?.[0]?.key || "",
                                                    selectedLang: 'Hindi',
                                                    value: {
                                                        hi: appConfig?.[0]?.value?.hi || "",
                                                        en: appConfig?.[0]?.value?.en || "",
                                                        mr: appConfig?.[0]?.value?.mr || ""
                                                    },
                                                }}
                                                validationSchema={AppConfigValidationSchema}
                                                onSubmit={(values, { setSubmitting, setErrors }) => {
                                                    if (updateValue) {
                                                        dispatch(ActionUpdateAppConfig({
                                                            Query: "/" + idValue, obj: {
                                                                "value": values.value
                                                            }
                                                        }, (Res) => {
                                                            if (Res?.statusCode === 200) {
                                                                {
                                                                    window.location.href = `/app-config?page=${pageValue || 1}`
                                                                }
                                                            } else if (Res?.statusCode === 400) {
                                                                setErrors(Res?.data?.errors);
                                                            } else {
                                                                window.location.reload();
                                                            }
                                                        }))
                                                    } else {
                                                        dispatch(ActionCreateAppConfig({
                                                            "key": values.key,
                                                            "value": values.value
                                                        }, (Res) => {
                                                            if (Res?.statusCode === 200) {
                                                                window.location.href = `/app-config?page=${pageValue || 1}`
                                                            } else if (Res?.statusCode === 400) {
                                                                setErrors(Res?.data?.errors);
                                                            } else {
                                                                window.location.reload();
                                                            }
                                                        }))
                                                    }
                                                    setSubmitting(false);
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue,
                                                    isSubmitting,
                                                    /* and other goodies */
                                                }) => {
                                                    const selectedLang = values.selectedLang === 'Hindi' ? "hi" : values.selectedLang === 'Marathi' ? "mr" : "hi"

                                                    return (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-heading">
                                                                        <h4>Add App Configration</h4>
                                                                    </div>
                                                                </div>
                                                                <InputFromUser
                                                                    label="key"
                                                                    type="text"
                                                                    name="key"

                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={errors.key}
                                                                    value={values.key}
                                                                    disabled={updateValue}
                                                                    touched={touched.key}
                                                                    placeholder={'Enter Your key'}
                                                                />
                                                                <div className="d-flex justify-content-center">
                                                                    <InputFromUser
                                                                        label="Language"
                                                                        type="singleSelect"
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        name="selectedLang"
                                                                        onChange={(value) => {
                                                                            handleChange({
                                                                                target: {
                                                                                    name: 'selectedLang',
                                                                                    value,
                                                                                },
                                                                            });
                                                                        }}
                                                                        FormControlClassName={'form-control'}
                                                                        onBlur={(value) => {
                                                                            handleBlur({
                                                                                target: {
                                                                                    name: 'selectedLang',
                                                                                    value: true,
                                                                                },
                                                                            });
                                                                        }}
                                                                        mode={''}
                                                                        error={errors?.value?.mr}
                                                                        value={values.selectedLang}
                                                                        options={Language}
                                                                        touched={touched?.value?.mr}
                                                                        placeholder={'Enter Your selecte Your Language'} />
                                                                </div>
                                                                <InputFromUser
                                                                    placeholder={"Enter Your Value in English"}
                                                                    type="text"
                                                                    name="value.en"
                                                                    onChange={handleChange}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={handleBlur}
                                                                    value={values.value.en}
                                                                    label="English Value"
                                                                    error={errors?.value?.en || ""}
                                                                    touched={touched.value?.en || false}
                                                                />
                                                                <InputFromUser
                                                                    placeholder={"Enter Your Value in " + values.selectedLang}
                                                                    type="text"
                                                                    name={selectedLang}
                                                                    onChange={(e) => { setFieldValue(`value.${selectedLang}`, e.target.value); }}
                                                                    FormGroupClassName={"col-xl-6 col-md-12"}
                                                                    LabelClassName={"col-form-label"}
                                                                    onBlur={(v) => handleBlur({
                                                                        target: {
                                                                            name: `value.${selectedLang}`,
                                                                            value: true,
                                                                        }
                                                                    })}
                                                                    value={values.value[selectedLang]}
                                                                    label={values.selectedLang + " Value"}
                                                                    error={selectedLang === 'mr' ? errors?.value?.mr : ''}
                                                                    touched={selectedLang === 'mr' ? errors?.value?.mr : false}
                                                                />

                                                                <div className="col-12">
                                                                    <div className="doctor-submit text-end">
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-primary submit-form me-2"
                                                                            onClick={() => handleSubmit()}
                                                                            disabled={isSubmitting}
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-primary cancel-form"
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Formik>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div >
            </>
        </>
    );
};

export default Add_AppConfig;
