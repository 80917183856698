import { call, put, takeEvery } from "redux-saga/effects";
import { CreatePrakrutiStandardApi, CreateTreatmentProtocolApi, GetPrakrutiStandardApi, GetTreatmentProtocolApi, GetTreatmentServiceDataApi } from "../../utils/api";
import { CREATE_PRAKRUTI_STANDARD, CREATE_TREATMENT_PROTOCOL, GET_ALL_PRAKRUTI_STANDARD, GET_ALL_TREATMENT_PROTOCOL, GET_ALL_TREATMENT_SERVICE_DATA } from "../storeTypes";
import { GetAllPrakrutistandardSuccess, GetAllTreatmentprotocolSuccess, GetAllTreatmentserviceSuccess } from "../Actions/PrakrutistandardAction";


const File = 'PrakrutiStandardSaga '

function* getPrakrutistandardListSaga(action) {
    try {
        const response = yield call(GetPrakrutiStandardApi, action?.payload);

        if (response?.status === 200) {
            yield put(GetAllPrakrutistandardSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getPrakrutistandardListSaga Error :- ', error);
        //handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}
function* createPrakrutistandardSaga(action) {
    try {
        const response = yield call(CreatePrakrutiStandardApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'createFaqSaga Error :- ', error);
    }
}
function* getTreatmentprotocolListSaga(action) {
    try {
        const response = yield call(GetTreatmentProtocolApi, action?.payload);

        if (response?.status === 200) {
            yield put(GetAllTreatmentprotocolSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getTreatmentprotocolListSaga Error :- ', error);
        //handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}

function* createTreatmentprotocolSaga(action) {
    try {
        const response = yield call(CreateTreatmentProtocolApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'createTreatmentprotocolSaga Error :- ', error);
    }
}

function* getTreatmentserviceListSaga(action) {
    try {
        const response = yield call(GetTreatmentServiceDataApi, action?.payload);

        if (response?.status === 200) {
            yield put(GetAllTreatmentserviceSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getTreatmentserviceListSaga Error :- ', error);
        //handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}
export function* PrakrutiStandardSaga() {
    [

        yield takeEvery(GET_ALL_PRAKRUTI_STANDARD, getPrakrutistandardListSaga),
        yield takeEvery(CREATE_PRAKRUTI_STANDARD, createPrakrutistandardSaga),
        yield takeEvery(GET_ALL_TREATMENT_PROTOCOL, getTreatmentprotocolListSaga),
        yield takeEvery(CREATE_TREATMENT_PROTOCOL, createTreatmentprotocolSaga),
        yield takeEvery(GET_ALL_TREATMENT_SERVICE_DATA, getTreatmentserviceListSaga),

    ]
}