import { call, put, takeEvery } from "redux-saga/effects";
import { CreateFaqApi, GetFaqApi } from "../../utils/api";
import { GetAllFaqSuccess } from "../Actions/FaqAction";
import { CREATE_FAQ, GET_ALL_FAQ } from "../storeTypes";


const File = 'FaqSaga '

function* createFaqSaga(action) {
    try {
        const response = yield call(CreateFaqApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'createFaqSaga Error :- ', error);
    }
}
function* getFaqlistSaga(action) {
    try {
        const response = yield call(GetFaqApi, action?.payload);

        if (response?.status === 200) {
            yield put(GetAllFaqSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getFaqlistSaga Error :- ', error);
        //handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}

export function* FaqSaga() {
    [
        yield takeEvery(CREATE_FAQ, createFaqSaga),
        yield takeEvery(GET_ALL_FAQ, getFaqlistSaga),

    ]
}