import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateFaq, GetAllFaq } from '../../store/Actions/FaqAction';
import { getAdminuUerDetails } from '../../store/Actions/AuthActions';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { FaqValidationSchema } from '../../utils/FormValidation';
import { ActionUpdateById } from '../../store/Actions/CommonAction';
import { InputFromUser } from '../../components/Input/input';
import { Formik } from 'formik';

const AddEditFaq = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const { Faqlist } = useSelector(state => state?.Faq);
    const { userDetails } = useSelector(state => state?.auth);
    const userId = userDetails?.[0]?._id;
    const idValue = searchParams.get("id");
    const updateValue = searchParams.get("update");
    useEffect(() => {
        if (idValue) {
            dispatch(GetAllFaq({ Query: '/' + idValue }));
        }
    }, [idValue])
    useEffect(() => {
        dispatch(getAdminuUerDetails())
    }, [userId])

    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/faq-list">Faq</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Faq</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue || false}
                                                initialValues={{
                                                    question: {
                                                        en: Faqlist?.[0]?.question?.en || "",
                                                        hi: Faqlist?.[0]?.question?.hi || "",
                                                        mr: Faqlist?.[0]?.question?.mr || ""
                                                    },
                                                    answer: {
                                                        en: Faqlist?.[0]?.answer?.en || "",
                                                        hi: Faqlist?.[0]?.answer?.hi || "",
                                                        mr: Faqlist?.[0]?.answer?.mr || ""
                                                    },

                                                }}
                                                validationSchema={FaqValidationSchema}
                                                onSubmit={(values, { setErrors, setSubmitting }) => {
                                                    if (updateValue && idValue != null) {
                                                        dispatch(ActionUpdateById({ Query: `admin/faqs/${idValue}`, obj: values }
                                                            , (Res) => {
                                                                if (Res?.statusCode === 200) {
                                                                    window.location.href = `/faq-list?page=1`
                                                                } else if (Res?.statusCode === 400) {
                                                                    setErrors(Res?.data?.errors);
                                                                }
                                                                setSubmitting(false);

                                                            }));
                                                    } else {
                                                        //CreateFaq
                                                        dispatch(CreateFaq(values, (Response) => {
                                                            if (Response?.statusCode === 200) {
                                                                window.location.href = "/faq-list?page=1"
                                                            }
                                                            setSubmitting(false);

                                                        }))
                                                    }
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    // handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue,
                                                    isSubmitting,
                                                }) => {

                                                    return (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-heading">
                                                                        <h4>Add Faq Data</h4>
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-4">
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Enter Question"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="Question"
                                                                        value={values?.question?.en}
                                                                        name="question.en"
                                                                        onChange={(v) => {
                                                                            const UpdateValue = values?.question
                                                                            UpdateValue.en = v?.target?.value
                                                                            setFieldValue("question", UpdateValue, true)

                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.question?.en}
                                                                        touched={touched?.question?.en}
                                                                    />
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Marathi"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="*Marathi"
                                                                        name="question.mr"
                                                                        value={values?.question?.mr}
                                                                        onChange={(v) => {
                                                                            const UpdateValue = values?.question
                                                                            UpdateValue.mr = v?.target?.value
                                                                            setFieldValue("question", UpdateValue, true)

                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.question?.mr}
                                                                        touched={touched?.question?.mr}
                                                                    />
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Hindi"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="*Hindi"
                                                                        value={values?.question?.hi}
                                                                        name="question.hi"
                                                                        onChange={(v) => {
                                                                            const UpdateValue = values?.question
                                                                            UpdateValue.hi = v?.target?.value
                                                                            setFieldValue("question", UpdateValue, true)

                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.question?.hi}
                                                                        touched={touched?.question?.hi}
                                                                    />
                                                                </div>

                                                                <div className="row mt-4">
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Enter Answer"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="Answer"
                                                                        value={values?.answer?.en}
                                                                        name="answer.en"
                                                                        onChange={(v) => {
                                                                            const UpdateValue = values?.answer
                                                                            UpdateValue.en = v?.target?.value
                                                                            setFieldValue("answer", UpdateValue, true)

                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.answer?.en}
                                                                        touched={touched?.answer?.en}
                                                                    />
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Marathi"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="*Marathi"
                                                                        name="answer.mr"
                                                                        value={values?.answer?.mr}
                                                                        onChange={(v) => {
                                                                            const UpdateValue = values?.answer
                                                                            UpdateValue.mr = v?.target?.value
                                                                            setFieldValue("answer", UpdateValue, true)

                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.answer?.mr}
                                                                        touched={touched?.answer?.mr}
                                                                    />
                                                                    <InputFromUser
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        placeholder={"Hindi"}
                                                                        FormControlClassName={'form-control '}
                                                                        label="*Hindi"
                                                                        value={values?.answer?.hi}
                                                                        name="answer.hi"
                                                                        onChange={(v) => {
                                                                            const UpdateValue = values?.answer
                                                                            UpdateValue.hi = v?.target?.value
                                                                            setFieldValue("answer", UpdateValue, true)

                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        error={errors?.answer?.hi}
                                                                        touched={touched?.answer?.hi}
                                                                    />
                                                                </div>


                                                                <div className="col-12 mt-4">
                                                                    <div className="doctor-submit text-end">
                                                                        <button
                                                                            type="submit"
                                                                            onClick={() => {
                                                                                handleSubmit()
                                                                            }}
                                                                            className="btn btn-primary submit-form me-2"
                                                                            disabled={isSubmitting}
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary cancel-form"
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Formik>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        </>
    )
}

export default AddEditFaq