import { CREATE_SCHEDULE, GET_ALL_SCHEDULE_LIST, GET_ALL_SCHEDULE_LIST_SUCESS } from "../storeTypes";

export const ActionCreateSchedule = (payload, callBack) => {
    return {
        type: CREATE_SCHEDULE,
        payload,
        callBack
    };
};
export const GetAllScheduleList = (payload) => {
    return {
        type: GET_ALL_SCHEDULE_LIST,
        payload,
    };
};
export const GetAllScheduleListSucess = (payload) => {
    return {
        type: GET_ALL_SCHEDULE_LIST_SUCESS,
        payload,
    };
};
