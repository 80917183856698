import { Input, Modal } from 'antd';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionGetCCPaperVerificationDetail, ActionGetPatientById, ActionUpdatePatient } from '../../store/Actions/PatientAction';
// eslint-disable-next-line react/prop-types
const VerifyCaseModal = ({ IsOpen = {}, Isclose, value = '' }) => {
    const dispatch = useDispatch();
    const patient = useSelector(state => state.patient);
    const patientInfo = patient?.patient?.[0]?.customer;
    useEffect(() => {
        if (IsOpen.id) {

            dispatch(ActionGetPatientById({ Query: `/${IsOpen.id}` }));

        }
        dispatch(ActionGetCCPaperVerificationDetail());
    }, [dispatch, IsOpen.id])



    return (
        <>
            <div>
                <div className="page-wrapper">
                    <div className="content">
                        <div className="row">
                            <div className="col-sm-12">
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        oldCasePaperNo: patientInfo?.oldCasePaperNo || value.oldCasePaperNo || ''
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {

                                        dispatch(ActionUpdatePatient({
                                            customerId: IsOpen.id,
                                            obj: {
                                                oldCasePaperNo: values.oldCasePaperNo,
                                                isOldCasePaperVerified: true
                                            }

                                        }, () => {

                                            setSubmitting(false);
                                            Isclose();
                                        }));
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,

                                    }) => (
                                        <div>
                                            <Modal
                                                open={IsOpen?.is}
                                                closable={true}

                                                forceRender={true}
                                                zIndex={1040}
                                                onOk={() => handleSubmit()}
                                                styles={{
                                                    body: {
                                                        overflow: "hidden",
                                                    },
                                                    mask: {
                                                        backdropFilter: 'blur(10px)',
                                                    },
                                                    content: {
                                                        borderRadius: 40,
                                                    },
                                                }}
                                                okText={'Submit'}
                                                centered

                                                onCancel={Isclose}
                                                closeIcon={true}
                                            >
                                                <div className="row" >
                                                    <div className="col-12">
                                                        <div className="invoice-add-table">
                                                            <h4>Edit your Case Paper No</h4>
                                                            <div className="form-group">
                                                                <label htmlFor="oldCasePaperNo">Old Case Paper No</label>
                                                                <Input
                                                                    id="oldCasePaperNo"
                                                                    name="oldCasePaperNo"
                                                                    type="text"
                                                                    value={values.oldCasePaperNo}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                                {errors.oldCasePaperNo && touched.oldCasePaperNo && (
                                                                    <div className="error">{errors.oldCasePaperNo}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal >
                                        </div>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyCaseModal