import {
    CREATE_ORDER,
    GET_ALL_ORDER_LIST,
    GET_ALL_ORDER_LIST_SUCCESS,
    GET_ALL_PENDING_ORDER_LIST,
    GET_ALL_PENDING_ORDER_LIST_SUCCESS,
    GET_ORDER_BY_ID,
    GET_ORDER_BY_ID_SUCCESS,
} from "../storeTypes";

const INIT_STATE = {
    loader: false,
    order: [],
    pendingOrder: [],
}

function OrderHistoryReducer(state = INIT_STATE, action) {

    switch (action.type) {

        case GET_ALL_ORDER_LIST: {
            return {
                ...state,
                order: [],
                loader: true,
            }
        }
        case GET_ALL_ORDER_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                order: action.payload,
            }
        }
        case GET_ALL_PENDING_ORDER_LIST: {
            return {
                ...state,
                pendingOrder: [],
                loader: true,
            }
        }
        case GET_ALL_PENDING_ORDER_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                pendingOrder: action?.payload,
            }
        }

        case CREATE_ORDER: {
            return {
                ...state,
                loader: true,
            };
        }

        case GET_ORDER_BY_ID: {
            return {
                ...state,
                order: [],
                loader: true,
            };
        }
        case GET_ORDER_BY_ID_SUCCESS: {
            return {
                ...state,
                loader: false,
                order: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}

export default OrderHistoryReducer;