import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { GetAllPrakrutistandard } from '../../store/Actions/PrakrutistandardAction';
import { Button, Modal, Popover } from 'antd';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { CustomTable } from '../../components/Table';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { plusicon, refreshicon } from '../../components/imagepath';
import { Link } from 'react-router-dom';
import { ActionDeleteById } from '../../store/Actions/CommonAction';
import { matchPermissions } from '../../utils/Permission';

const PrakrutiStandardList = () => {
    const { confirm } = Modal;
    const dispatch = useDispatch();
    const { Prakrutistandardlist, loader } = useSelector(state => state?.PrakrutiStandard);
    const { userDetails } = useSelector(state => state?.auth);
    const params = queryString.parse(location.search);
    const pageSize = 10;
    useEffect(() => {
        dispatch(GetAllPrakrutistandard());
    }, [])
    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('prakruti-standard.update');
    const canDelete = matchPermissionsForUser('prakruti-standard.delete');
    const canCerate = matchPermissionsForUser('prakruti-standard.create');
    const content = (item) => (

        <div className="row col">
            {canUpdate &&
                <Button className="col m-2" onClick={() => { window.location.href = `/add-prakrutistandard/:query?update=true&&page=${1}&&id=${item?.id}` }}>Edit </Button>
            }
            {canDelete &&
                <Button className="col m-2" onClick={() => showDeleteConfirm(item)}>Delete </Button>

            }
        </div>
    );
    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.name} name?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteById({ Query: `admin/prakruti-standard/${item?._id}` }, (Res) => {
                    if (Res?.statusCode === 200) {
                        //setRefresh(true)
                        window.location.reload()
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Name",
            dataIndex: "name",


        },
        {
            title: "Months",
            dataIndex: "months",
            render: (text) => (
                <div className="app-config-boldText">{text.join(",")}</div>
            ),

        },


        {
            title: !canUpdate && !canDelete ? null : "Action",
            dataIndex: "roleName",
            render: (text, record) => {
                if (!canUpdate && !canDelete) {
                    return null
                } else {
                    return <Popover placement="top" title={text} content={() => content(record)}>
                        <Button type="text">• • •</Button>
                    </Popover >
                }

            },
        },
    ]
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/prakrutistandard-list/:slug">Prakruti Standard</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Prakruti Standard List</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Prakruti Standard List</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">
                                                                    {canCerate &&
                                                                        <Link
                                                                            to={`/add-prakrutistandard/:create`}
                                                                            className="btn btn-primary add-pluss ms-2"
                                                                        >
                                                                            <img src={plusicon} alt="#" />
                                                                        </Link>
                                                                    }

                                                                    <Link
                                                                        to="#"
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <CustomTable
                                                    TotalPageList={Prakrutistandardlist?.[0]?.totalDocs}
                                                    column={columns}
                                                    OnChange={(v) => {
                                                        var newp = params
                                                        newp.page = v
                                                        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
                                                    }}
                                                    CurrentPageNum={Prakrutistandardlist?.[0]?.page}
                                                    dataSource={Prakrutistandardlist?.[0]?.docs}
                                                    dataSourceLength={Prakrutistandardlist?.[0]?.docs?.length}
                                                    pageSize={10}
                                                    loader={loader}
                                                    ShowHeader={true}
                                                    Header={<div className="staff-search-table">
                                                    </div>}
                                                    key={"hi"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </>

        </>

    )
}

export default PrakrutiStandardList