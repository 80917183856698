/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { CreatePermissonValidationSchema } from "../../../utils/FormValidation";
import { FormInput } from "../../../components/Input/InputText";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loader";
import { CreatePermissionAction, GetPaginatedPermissionListAction } from "../../../store/Actions/PermissionsAction";
import queryString from "query-string";
import { ActionUpdateById } from "../../../store/Actions/CommonAction";
const AddPermissions = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const updateValue = searchParams.get("update");
    const pageValue = searchParams.get("page");
    const idValue = searchParams.get("id");
    const [Refresh, setRefresh] = useState(false);
    const { appConfig, loader } = useSelector(state => state?.appConfig);
    const { PaginatedPermissionsList } = useSelector(state => state?.Permission);
    const params = queryString.parse(location.search);
    useEffect(() => {
        setRefresh(false)
    }, [Refresh])
    useEffect(() => {
        if (idValue) {
            dispatch(GetPaginatedPermissionListAction({ Query: `/${idValue}`, obj: undefined }))
        }
    }, [pageValue, dispatch])
    return (
        <>
            <Loader IsModal={loader} />
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/permissions">Permissons</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <Link to="/permissions">Permissons List</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Permissons</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue}
                                                initialValues={{
                                                    name: PaginatedPermissionsList?.[0]?.name || "",
                                                    description: PaginatedPermissionsList?.[0]?.description || "",
                                                    moduleName: PaginatedPermissionsList?.[0]?.moduleName || ""
                                                }}
                                                validationSchema={CreatePermissonValidationSchema}
                                                onSubmit={(values, { setSubmitting, setErrors }) => {
                                                    if (updateValue && idValue != null) {
                                                        dispatch(ActionUpdateById({ Query: `admin/permissions/${idValue}`, obj: values }
                                                            , (Res) => {
                                                                if (Res?.statusCode === 200) {
                                                                    window.location.href = `/permissions`
                                                                } else if (Res?.statusCode === 400) {
                                                                    setErrors(Res?.data?.errors);
                                                                }
                                                            }));
                                                        setSubmitting(false);
                                                    } else {
                                                        dispatch(CreatePermissionAction({ Query: '', obj: values }, (Res) => {
                                                            if (Res?.statusCode === 200) {
                                                                window.location.href = `/permissions`
                                                            } else if (Res?.statusCode === 400) {
                                                                setErrors(Res?.data?.errors);
                                                            }
                                                        }));
                                                        setSubmitting(false)
                                                    }
                                                }}

                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    isSubmitting,
                                                    /* and other goodies */
                                                }) => (
                                                    <div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-heading">
                                                                    <h4>Add Permisson</h4>
                                                                </div>
                                                            </div>
                                                            <FormInput
                                                                label="Name"
                                                                type="text"
                                                                name="name"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.name}
                                                                value={values.name}
                                                                touched={touched.name}
                                                                placeholder={'Enter Your name'}
                                                            />
                                                            <FormInput
                                                                placeholder="Enter Your Value"
                                                                type="text"
                                                                name="description"
                                                                onChange={handleChange}
                                                                FormControlClassName={""}
                                                                FormGroupClassName={''}
                                                                onBlur={handleBlur}
                                                                value={values.description}
                                                                label="Description"
                                                                error={errors.description}
                                                                touched={touched.description}
                                                            />
                                                            <FormInput
                                                                placeholder="Enter Your Value"
                                                                type="text"
                                                                name="moduleName"
                                                                onChange={handleChange}
                                                                FormControlClassName={""}
                                                                FormGroupClassName={''}
                                                                onBlur={handleBlur}
                                                                value={values.moduleName}
                                                                label="Module Name"
                                                                error={errors.moduleName}
                                                                touched={touched.moduleName}
                                                            />
                                                            <div className="col-12">
                                                                <div className="doctor-submit text-end">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-primary submit-form me-2"
                                                                        disabled={isSubmitting}
                                                                        onClick={() => handleSubmit()}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-primary cancel-form"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Formik>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div >
            </>
        </>
    );
};

export default AddPermissions;
