import { CREATE_HOLIDAY, CREATE_STAFF, DELETE_HOLIDAY, GET_ALL_FOODPRICE_LIST, GET_ALL_FOODPRICE_LIST_SUCCESS, GET_HOLIDAY_BY_ID, GET_HOLIDAY_BY_ID_SUCCESS, GET_HOLIDAY_LIST, GET_LEAVES, GET_LEAVES_SUCCESS, GET_STAFF_LIST, GET_STAFF_LIST_SUCCESS, GET_STAFF_PROFILE, GET_STAFF_PROFILE_SUCCESS, GET_THERAPIST_SCHEDULE, GET_THERAPIST_SCHEDULE_SUCCESS, GET_THERAPIST_SHIFTS, GET_THERAPIST_SHIFTS_SUCCESS, HOLIDAY_LIST_SUCCESS, UPDATE_HOLIDAY, UPDATE_STAFF_PROFILE } from "../storeTypes";

export const CreateStaffAction = (payload, callBack) => {
    return {
        type: CREATE_STAFF,
        payload,
        callBack
    };
};

export const GetStaffListAction = (payload) => {
    return {
        type: GET_STAFF_LIST,
        payload,
    };
};
export const GetStaffListSuccess = (payload) => {
    return {
        type: GET_STAFF_LIST_SUCCESS,
        payload,
    };
};
export const GetStaffProfileAction = (payload) => {
    return {
        type: GET_STAFF_PROFILE,
        payload,
    };
};
export const GetStaffProfileActionSuccess = (payload) => {
    return {
        type: GET_STAFF_PROFILE_SUCCESS,
        payload,
    };
};
export const UpdateStaffProfileAction = (payload, callBack) => {
    return {
        type: UPDATE_STAFF_PROFILE,
        payload,
        callBack
    };
};
export const CreateHolidayAction = (payload, callback) => {
    return {
        type: CREATE_HOLIDAY,
        payload,
        callback
    };
};
export const GetHolidayListAction = (payload, callback) => {
    return {
        type: GET_HOLIDAY_LIST,
        payload,
        callback
    };
};
export const GetHolidayListSuccess = (payload) => {
    return {
        type: HOLIDAY_LIST_SUCCESS,
        payload
    }
};
export const ActionUpdateHoliday = (payload, callBack) => {
    return {
        type: UPDATE_HOLIDAY,
        payload,
        callBack
    };
};

export const ActionGetHolidayByID = (payload, callBack) => {
    return {
        type: GET_HOLIDAY_BY_ID,
        payload,
        callBack
    };
};

export const ActionGetHolidayByIDSuccess = (payload) => {
    return {
        type: GET_HOLIDAY_BY_ID_SUCCESS,
        payload
    };
};

export const ActionDeleteHoliday = (payload, callBack) => {
    return {
        type: DELETE_HOLIDAY,
        payload,
        callBack
    };
};
export const GetTherapistScheduleAction = (payload, callBack) => {
    return {
        type: GET_THERAPIST_SCHEDULE,
        payload,
        callBack
    };
};
export const GetTherapistScheduleActionSuccess = (payload) => {
    return {
        type: GET_THERAPIST_SCHEDULE_SUCCESS,
        payload,
    };
};
export const ActionGetShiftsList = (payload, callBack) => {
    return {
        type: GET_THERAPIST_SHIFTS,
        payload,
        callBack
    };
};
export const ActionGetShiftsListSuccess = (payload) => {
    return {
        type: GET_THERAPIST_SHIFTS_SUCCESS,
        payload,
    };
};
export const ActionGetLeavesList = (payload, callBack) => {
    return {
        type: GET_LEAVES,
        payload,
        callBack
    };
};
export const ActionGetLeavesListSuccess = (payload) => {
    return {
        type: GET_LEAVES_SUCCESS,
        payload,
    };
};

export const ActionGetFoodpriceList = (payload) => {

    return {
        type: GET_ALL_FOODPRICE_LIST,
        payload,
    };
};

export const ActionGetFoodpriceListSuccess = (payload) => {
    return {
        type: GET_ALL_FOODPRICE_LIST_SUCCESS,
        payload,
    };
};