import React, { useEffect } from 'react'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import { Breadcrumb } from '../../components/Card/Cards'
import { Formik } from 'formik'
import { InputFromUser } from '../../components/Input/input'
import { Button, Divider } from 'antd'
import { ActionGetPatientDischargeForm } from '../../store/Actions/AppConfigAction'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { CustomTable } from '../../components/Table'

const NameHeading = () => {
    return (
        <div style={{}}>
            <h2 style={{ fontFamily: 'sans-serif' }}>〘 Prakruti Ayurvedic Health Center & Hospital 〙</h2>
            <h4 style={{ fontFamily: 'fantasy', textAlign: 'center' }}>A Project By Prakruti Ayurvedic Health Resort ,Satara</h4>
        </div>)
}
const DischargeForm = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const patientPlanId = searchParams.get('patientPlanId');
    const { patientDischarge } = useSelector(state => state?.appConfig);

    const BreadcrumbList = [{
        name: 'Discharge Form',
        linkTo: ``
    },]
    useEffect(() => {
        dispatch(ActionGetPatientDischargeForm({ Query: `/${patientPlanId}` }))
    }, [])

    const column = [
        {
            title: "Name Of Treatment",
            dataIndex: "serviceName",
            key: 'serviceName',
            render: (_, record) => record.serviceName,
        },
        {
            title: "Type Of Medicine",
            dataIndex: 'medicine',
            key: 'medicine',
            render: (_, record) => record.medicine,

        },
        {
            title: "Days",
            dataIndex: 'days',
            key: 'days',
            render: (_, record) => record.days,

        },
    ]
    const response = patientDischarge?.[0]?.services;
    const data = response ? Object.keys(response).map((key) => ({
        key,
        serviceName: key,
        medicine: Array.isArray(response[key].medicineDetails[0].medicine) ? response[key].medicineDetails[0].medicine.join(', ') : '',
        days: response[key].count,
    })) : [];
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        <Breadcrumb page={BreadcrumbList} />
                        <div className="row">
                            <div className=''>
                                <div className="d-flex justify-content-center my-3">
                                    {NameHeading()}
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="card p-3">
                                    <div className="card-body">
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                customerName: patientDischarge?.[0]?.customerName || "",
                                                email: patientDischarge?.[0]?.customerId?.email || "",
                                                age: patientDischarge?.[0]?.customerId?.age || "",
                                                birthDate: patientDischarge?.[0]?.customerId?.birthDate && dayjs(patientDischarge?.[0]?.customerId?.birthDate) || null,
                                                gender: patientDischarge?.[0]?.customerGender || "",
                                                dischargeType: "",
                                                startDate: patientDischarge?.[0]?.solutionSlotId?.startDate && dayjs(patientDischarge?.[0]?.solutionSlotId?.startDate) || patientDischarge?.[0]?.treatmentSlotId?.startDate && dayjs(patientDischarge?.[0]?.treatmentSlotId?.startDate) || null,
                                                endDate: patientDischarge?.[0]?.solutionSlotId?.endDate && dayjs(patientDischarge?.[0]?.solutionSlotId?.endDate) || patientDischarge?.[0]?.treatmentSlotId?.endDate && dayjs(patientDischarge?.[0]?.treatmentSlotId?.endDate) || null,
                                                totalDays: patientDischarge?.[0]?.solutionSlotId?.totalDays || patientDischarge?.[0]?.treatmentSlotId?.totalDays || "",
                                                diagnosis: "",
                                                hetu: "",
                                                addiction: "",
                                                admissionComplaints: "",
                                                dischargeComplaints: "",
                                                previousHistory: "",
                                                investigationBefore: "",
                                                investigationAdmit: "",
                                                medicines: [
                                                    { "medicineName": "", "days": null, "whenToTake": [], 'anupan': '', 'howMuch': '' }
                                                ],
                                                investigationsAdviced: "",
                                                medicineAdvised: "",
                                                Virechana: "",
                                                Exercise: "",
                                                Diet: "",
                                                instructionMentalPeace: "",
                                                nextPanchakrmaAdvise: ""
                                            }}
                                            onSubmit={(values) => {
                                                console.log(values);

                                            }
                                            }

                                        >
                                            {({

                                                values, handleBlur, handleChange, touched

                                                // isSubmitting,

                                            }) => {
                                                console.log(values);
                                                return (
                                                    <form>
                                                        <div className="row">
                                                            <div className='card col-lg-12'>
                                                                <div className="row ">
                                                                    <InputFromUser
                                                                        label="Name"
                                                                        type="text"
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        name="customerName"
                                                                        onChange={handleChange}
                                                                        FormControlClassName={'form-control'}
                                                                        onBlur={handleBlur}
                                                                        // error={''}
                                                                        value={values?.customerName || ''}
                                                                        touched={touched?.customerName}
                                                                        placeholder={'Enter Name'}
                                                                    />
                                                                    <InputFromUser
                                                                        label="Email"
                                                                        type="text"
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        name="email"
                                                                        onChange={handleChange}
                                                                        FormControlClassName={'form-control'}
                                                                        onBlur={handleBlur}
                                                                        // error={''}
                                                                        value={values?.email}
                                                                        touched={touched.email}
                                                                        placeholder={'Enter Email'}
                                                                    />
                                                                    <InputFromUser
                                                                        label="Age"
                                                                        type="text"
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        name="age"
                                                                        onChange={handleChange}
                                                                        FormControlClassName={'form-control'}
                                                                        onBlur={handleBlur}
                                                                        // error={''}
                                                                        value={values?.age}
                                                                        // touched={touched.oldCasePaperNo}
                                                                        placeholder={'Enter Age'}
                                                                    />
                                                                </div>
                                                                <div className="row ">
                                                                    <InputFromUser
                                                                        label="Gender"
                                                                        type="radio"
                                                                        name="gender"
                                                                        onChange={(value) => {
                                                                            handleChange({
                                                                                target: {
                                                                                    name: 'gender',
                                                                                    value,
                                                                                },
                                                                            });
                                                                        }}
                                                                        onBlur={() => {
                                                                            handleBlur({
                                                                                target: {
                                                                                    name: 'gender',
                                                                                    value: true,
                                                                                },
                                                                            });
                                                                        }}
                                                                        FormControlClassName={'form-control'}
                                                                        FormGroupClassName={"col-xl-4 col-md-12 "}
                                                                        LabelClassName={"col-form-label"}
                                                                        value={values?.gender}
                                                                        selectedValue={values?.gender}
                                                                        // error={errors.gender}
                                                                        // touched={touched.gender}
                                                                        options={[
                                                                            { label: 'Male', value: 'Male' },
                                                                            { label: 'Female', value: 'Female' },
                                                                            { label: 'Others', value: 'Others' },
                                                                        ]}
                                                                        placeholder={'Gender'}
                                                                    />
                                                                    <InputFromUser
                                                                        label="Birth Date"
                                                                        type="date"
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        FormControlClassName={'form-control'}
                                                                        name="birthDate"
                                                                        onChange={(v) => handleChange({
                                                                            target: {
                                                                                name: "birthDate",
                                                                                value: v
                                                                            }
                                                                        })} onBlur={handleBlur}
                                                                        // error={errors.birthDate}
                                                                        value={values?.birthDate}
                                                                        // touched={touched.birthDate}
                                                                        placeholder={'Enter Your birth Date'}
                                                                    />
                                                                    <InputFromUser
                                                                        label="Discharge Type"
                                                                        type="text"
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        name="dischargeType"
                                                                        onChange={handleChange}
                                                                        FormControlClassName={'form-control'}
                                                                        onBlur={handleBlur}
                                                                        // error={''}
                                                                        value={values?.dischargeType}
                                                                        // touched={touched.oldCasePaperNo}
                                                                        placeholder={''}
                                                                    />
                                                                </div>
                                                                <div className="row ">
                                                                    <InputFromUser
                                                                        label="Start Date"
                                                                        type="date"
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        name="startDate"
                                                                        onChange={handleChange}
                                                                        FormControlClassName={'form-control'}
                                                                        onBlur={handleBlur}
                                                                        // error={''}
                                                                        value={values?.startDate}
                                                                        // touched={touched.oldCasePaperNo}
                                                                        placeholder={''}
                                                                    />
                                                                    <InputFromUser
                                                                        label="End Date"
                                                                        type="date"
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        name="endDate"
                                                                        onChange={handleChange}
                                                                        FormControlClassName={'form-control'}
                                                                        onBlur={handleBlur}
                                                                        // error={''}
                                                                        value={values?.endDate}
                                                                        // touched={touched.oldCasePaperNo}
                                                                        placeholder={''}
                                                                    />
                                                                    <InputFromUser
                                                                        label="Total Days"
                                                                        type="text"
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        name="totalDays"
                                                                        onChange={handleChange}
                                                                        FormControlClassName={'form-control'}
                                                                        onBlur={handleBlur}
                                                                        // error={''}
                                                                        value={values?.totalDays}
                                                                        // touched={touched.oldCasePaperNo}
                                                                        placeholder={''}
                                                                    />
                                                                </div>
                                                                <div className="row ">
                                                                    <InputFromUser
                                                                        label="Diagnosis"
                                                                        type="text"
                                                                        FormGroupClassName={"col-xl-4 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        name="diagnosis"
                                                                        onChange={handleChange}
                                                                        FormControlClassName={'form-control'}
                                                                        onBlur={handleBlur}
                                                                        // error={''}
                                                                        value={values?.diagnosis}
                                                                        // touched={touched.oldCasePaperNo}
                                                                        placeholder={''}
                                                                    />

                                                                    <div className="col-xl-4 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label"  >
                                                                                Hetu:
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                // style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values?.hetu}
                                                                                onBlur={() => { }}
                                                                                onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'hetu',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />

                                                                        </div>
                                                                    </div>

                                                                    <div className="col-xl-4 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label"  >
                                                                                Addiction:
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                // style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values?.addiction}
                                                                                onBlur={() => { }}
                                                                                onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'addiction',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-xl-4 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label"  >
                                                                                Admission Complaints :
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                // style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values?.admissionComplaints}
                                                                                onBlur={() => { }}
                                                                                onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'admissionComplaints',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-4 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label"  >
                                                                                Discharge Complaints :
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                // style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values?.dischargeComplaints}
                                                                                onBlur={() => { }}
                                                                                onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'dischargeComplaints',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-4 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label"  >
                                                                                Previous History :
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                // style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values?.previousHistory}
                                                                                onBlur={() => { }}
                                                                                onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'previousHistory',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-xl-6 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label"  >
                                                                                Investigation Before :
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                // style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values?.investigationBefore}
                                                                                onBlur={() => { }}
                                                                                onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'investigationBefore',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-6 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label"  >
                                                                                Investigation During Admit :
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                // style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values?.investigationAdmit}
                                                                                onBlur={() => { }}
                                                                                onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'investigationAdmit',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <Divider>
                                                                    <div className="m-3">
                                                                        <h3 className="">Medicine Given</h3>
                                                                    </div></Divider>
                                                                {/* <div className="invoice-add-table form-group " >
                                                                    <div className="table-responsive " >
                                                                        <table className="table table-center add-table-items" >
                                                                            <thead >
                                                                                <tr >
                                                                                    <th>Medicine</th>
                                                                                    <th>when to Take</th>
                                                                                    <th>Quantity</th>
                                                                                    <th>Course Duration</th>
                                                                                    <th>Anupan</th>
                                                                                    <th>Days</th>
                                                                                    <th className="text-end">Actions</th>
                                                                                </tr>
                                                                            </thead>
                                                                            {/* <tbody>
                                                                                {values?.medicines?.map((index) => {
                                                                                    return (
                                                                                        <tr key={"Medicines1" + index + 'tr'}>
                                                                                            <td className="col-xl-3 col-md-10" key={"Medicines" + index + 'td'} >
                                                                                                {<InputFromUser
                                                                                                    type="singleSelect"
                                                                                                    label=""
                                                                                                    key={"MedicinesName1" + index}
                                                                                                    Showlabel={false}
                                                                                                    mode={''}
                                                                                                    name="medicines"
                                                                                                    onChange={(v) => {
                                                                                                        const updateArry = Object.assign([], values?.medicines)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ medicineName: v }
                                                                                                        }
                                                                                                        setFieldValue("medicines", updateArry, true)
                                                                                                    }}
                                                                                                    FormControlClassName={'form-control'}
                                                                                                    value={"medicineName"}
                                                                                                    // onSearch={(search) => {
                                                                                                    //     if (search.length >= 2) {
                                                                                                    //         dispatch(ActionGetMedicineList({ Query: `?name=${search}` }))
                                                                                                    //     } else if (search.length === 0) {
                                                                                                    //         dispatch(ActionGetMedicineList({ Query: `` }))
                                                                                                    //     }
                                                                                                    // }}
                                                                                                    // onBlur={() => {
                                                                                                    //     const updateArry = Object.assign([], touched.medicines)
                                                                                                    //     updateArry[index] = {
                                                                                                    //         ...updateArry[index],
                                                                                                    //         ...{ medicineName: true }
                                                                                                    //     }
                                                                                                    //     setFieldTouched("medicines", updateArry, true)
                                                                                                    // }}
                                                                                                    // error={errors?.medicines?.[index]?.medicineName}
                                                                                                    // touched={touched?.medicines?.[index]?.medicineName}
                                                                                                    // onSearchLoading={loader}
                                                                                                    // options={medicineList.length != 0 ? medicineList?.map(item => item?.name) : []}
                                                                                                    placeholder={'Enter Your Medicines'} />
                                                                                                }
                                                                                            </td>
                                                                                            <td className="col-xl-2 col-md-10" key={"td2" + index}>
                                                                                                {<InputFromUser
                                                                                                    type="multiple"
                                                                                                    label=""
                                                                                                    Showlabel={false}
                                                                                                    mode={''}
                                                                                                    name="whenToTake"
                                                                                                    onChange={(v) => {
                                                                                                        const updateArry = Object.assign([], values?.medicines)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ whenToTake: v }
                                                                                                        }
                                                                                                        setFieldValue("medicines", updateArry, true)
                                                                                                    }}
                                                                                                    FormControlClassName={'form-control'}
                                                                                                    value={"item?.whenToTake"}
                                                                                                    // onSearch={(search) => {
                                                                                                    //     if (search.length >= 3) {
                                                                                                    //         dispatch(ActionGetMedicineList({ Query: `?name=${search}` }))
                                                                                                    //     } else if (search.length === 0) {
                                                                                                    //         dispatch(ActionGetMedicineList({ Query: `` }))
                                                                                                    //     }
                                                                                                    // }}
                                                                                                    // onBlur={() => {
                                                                                                    //     const updateArry = Object.assign([], touched.medicines)
                                                                                                    //     updateArry[index] = {
                                                                                                    //         ...updateArry[index],
                                                                                                    //         ...{ whenToTake: true }
                                                                                                    //     }
                                                                                                    //     setFieldTouched("medicines", updateArry, true)
                                                                                                    // }}
                                                                                                    // error={errors?.medicines?.[index]?.whenToTake}
                                                                                                    // touched={touched?.medicines?.[index]?.whenToTake}
                                                                                                    // onSearchLoading={loader}
                                                                                                    // options={Options.WhenToTakeMedicine}
                                                                                                    placeholder={'Enter When to Take'} />
                                                                                                }
                                                                                            </td>
                                                                                            <td className="col-xl-2 col-md-10" key={"td3" + index}>

                                                                                                <div>
                                                                                                    <InputFromUser
                                                                                                        type="text"
                                                                                                        label=""
                                                                                                        Showlabel={false}
                                                                                                        mode={''}
                                                                                                        name="howMuch"
                                                                                                        onChange={(v) => {
                                                                                                            const updateArry = Object.assign([], values?.medicines)
                                                                                                            updateArry[index] = {
                                                                                                                ...updateArry[index],
                                                                                                                ...{ howMuch: v?.target?.value }
                                                                                                            }
                                                                                                            setFieldValue("medicines", updateArry, true)
                                                                                                        }}
                                                                                                        FormControlClassName={'form-control'}
                                                                                                        value={"item?.howMuch"}
                                                                                                        // error={errors?.medicines?.[index]?.howMuch}
                                                                                                        // touched={touched?.medicines?.[index]?.howMuch}
                                                                                                        // onSearchLoading={loader}
                                                                                                        // onBlur={() => {
                                                                                                        //     const updateArry = Object.assign([], touched.medicines)
                                                                                                        //     updateArry[index] = {
                                                                                                        //         ...updateArry[index],
                                                                                                        //         ...{ howMuch: true }
                                                                                                        //     }
                                                                                                        //     setFieldTouched("medicines", updateArry, true)
                                                                                                        // }}
                                                                                                        placeholder={'Enter How Much'} />
                                                                                                </div>

                                                                                            </td>
                                                                                            <td className="col-xl-2 col-md-10" key={"td4" + index}>
                                                                                                {<InputFromUser
                                                                                                    type="singleSelect"
                                                                                                    label=""
                                                                                                    Showlabel={false}
                                                                                                    mode={''}
                                                                                                    // disabled={IsFilledForm}
                                                                                                    name="course"
                                                                                                    onChange={(v) => {
                                                                                                        const updateArry = Object.assign([], values.medicines)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ course: v }
                                                                                                        }
                                                                                                        setFieldValue("medicines", updateArry, true)
                                                                                                        // setCourseDuration(true);

                                                                                                    }}
                                                                                                    FormControlClassName={'form-control'}
                                                                                                    // value={(index === 0 || (courseDuration && index !== 0)) ? values.medicines[0]?.course : item?.course}
                                                                                                    // onSearch={(search) => {
                                                                                                    //     if (search.length >= 3) {
                                                                                                    //         dispatch(ActionGetMedicineList({ Query: `?name=${search}` }))
                                                                                                    //     } else if (search.length === 0) {
                                                                                                    //         dispatch(ActionGetMedicineList({ Query: `` }))
                                                                                                    //     }
                                                                                                    // }}
                                                                                                    // onBlur={() => {
                                                                                                    //     const updateArry = Object.assign([], touched.medicines)
                                                                                                    //     updateArry[index] = {
                                                                                                    //         ...updateArry[index],
                                                                                                    //         ...{ course: true }
                                                                                                    //     }
                                                                                                    //     setFieldTouched("medicines", updateArry, true)
                                                                                                    // }}
                                                                                                    // error={errors?.medicines?.[index]?.course}
                                                                                                    // touched={touched?.medicines?.[index]?.course}
                                                                                                    // onSearchLoading={loader}
                                                                                                    // options={Options.CourseDuration}
                                                                                                    placeholder={'Enter Course Duration'} />
                                                                                                }
                                                                                            </td>
                                                                                            <td className="col-xl-2 col-md-10" key={"td5" + index} >
                                                                                                {<InputFromUser
                                                                                                    type="singleSelect"
                                                                                                    label=""
                                                                                                    Showlabel={false}
                                                                                                    mode={''}
                                                                                                    name="anupan"
                                                                                                    onChange={(v) => {
                                                                                                        const updateArry = Object.assign([], values.medicines)
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ anupan: v }
                                                                                                        }
                                                                                                        setFieldValue("medicines", updateArry, true)
                                                                                                    }}
                                                                                                    FormControlClassName={'form-control'}
                                                                                                    value={"item?.anupan"}
                                                                                                    // error={errors?.medicines?.[index]?.anupan}
                                                                                                    // touched={touched?.medicines?.[index]?.anupan}
                                                                                                    // onSearchLoading={loader}
                                                                                                    // onBlur={() => {
                                                                                                    //     const updateArry = Object.assign([], touched.medicines)
                                                                                                    //     updateArry[index] = {
                                                                                                    //         ...updateArry[index],
                                                                                                    //         ...{ anupan: true }
                                                                                                    //     }
                                                                                                    //     setFieldTouched("medicines", updateArry, true)
                                                                                                    // }}
                                                                                                    // options={Options.Anupana}
                                                                                                    placeholder={'Enter Your anupan'} />
                                                                                                }
                                                                                            </td>
                                                                                            <td className="col-xl-2 col-md-10" key={"td6" + index}>
                                                                                                {<InputFromUser
                                                                                                    type="number"
                                                                                                    label=""
                                                                                                    Showlabel={false}
                                                                                                    mode={''}
                                                                                                    name="days"
                                                                                                    onChange={(v) => {
                                                                                                        const updateArry = Object.assign([], values.medicines);
                                                                                                        updateArry[index] = {
                                                                                                            ...updateArry[index],
                                                                                                            ...{ days: v?.target?.value }
                                                                                                        };
                                                                                                        setFieldValue("medicines", updateArry, true);
                                                                                                        // setDaysSet(true);
                                                                                                    }}
                                                                                                    FormControlClassName={'form-control'}
                                                                                                    value={""}
                                                                                                    // error={errors?.medicines?.[index]?.days}
                                                                                                    // touched={touched?.medicines?.[index]?.days}
                                                                                                    // onSearchLoading={loader}
                                                                                                    // onBlur={() => {
                                                                                                    //     const updateArry = Object.assign([], touched.medicines);
                                                                                                    //     updateArry[index] = {
                                                                                                    //         ...updateArry[index],
                                                                                                    //         ...{ days: true }
                                                                                                    //     };
                                                                                                    //     setFieldTouched("medicines", updateArry, true);
                                                                                                    // }}
                                                                                                    placeholder={'Enter Days'}
                                                                                                />}
                                                                                            </td>

                                                                                            <td className="add-remove text-center align-items-center" key={"td6BTn" + index}>
                                                                                                <Button
                                                                                                    className="add-btn border-0 p-2"
                                                                                                    onClick={() => {
                                                                                                        const newValue = { "medicineName": "", "days": "", "whenToTake": [], 'anupan': '', 'howMuch': '', };
                                                                                                        setFieldValue("medicines", [...values.medicines, newValue])
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="fas fa-plus-circle" />
                                                                                                </Button>
                                                                                                <Button
                                                                                                    className="remove-btn border-0 p-2"
                                                                                                    onClick={() => {
                                                                                                        if (index !== 0) {
                                                                                                            const updatedMedicines = [...values.medicines];
                                                                                                            updatedMedicines.splice(index, 1);
                                                                                                            setFieldValue("medicines", updatedMedicines);
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="fe fe-trash-2">
                                                                                                        <FeatherIcon icon="trash-2" className={'mb-1'} />
                                                                                                    </i>
                                                                                                </Button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody> 
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                 */}
                                                                <CustomTable
                                                                    showPagination={false}
                                                                    column={column}
                                                                    dataSource={data}
                                                                    // dataSourceLength={medicalStore?.docs?.length}
                                                                    // pageSize={10}
                                                                    // loader={loader}
                                                                    ShowHeader={true}
                                                                    Header={<div className="staff-search-table">
                                                                    </div>}
                                                                    key={"hi"} />
                                                                <Divider>
                                                                    <div className="m-3">
                                                                        <h3 className="">On Discharge Advise</h3>
                                                                    </div></Divider>
                                                                <div className="row">
                                                                    <div className="col-xl-4 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label"  >
                                                                                Investigations Adviced :
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                // style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values.admissionComplaints}
                                                                                onBlur={() => { }}
                                                                                onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'admissionComplaints',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-4 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label"  >
                                                                                Medicine Advised :
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                // style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values.dischargeComplaints}
                                                                                onBlur={() => { }}
                                                                                onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'dischargeComplaints',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-4 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label"  >
                                                                                Virechana :
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                // style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values.previousHistory}
                                                                                onBlur={() => { }}
                                                                                onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'previousHistory',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-xl-6 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label"  >
                                                                                Exercise :
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                // style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values.admissionComplaints}
                                                                                onBlur={() => { }}
                                                                                onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'admissionComplaints',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-6 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label"  >
                                                                                Diet :
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                // style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values.dischargeComplaints}
                                                                                onBlur={() => { }}
                                                                                onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'dischargeComplaints',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-xl-6 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label"  >
                                                                                Instruction Mental Peace:
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                // style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values.admissionComplaints}
                                                                                onBlur={() => { }}
                                                                                onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'admissionComplaints',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-6 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="col-form-label"  >
                                                                                Next Panchakrma Advise  :
                                                                            </label>
                                                                            <textarea
                                                                                rows={1}
                                                                                cols={5}
                                                                                className="form-control"
                                                                                placeholder="Enter Notes"
                                                                                defaultValue={""}
                                                                                // style={{ borderColor: errors.doctorNote && touched.doctorNote ? 'red' : '', borderWidth: 1 }}
                                                                                value={values.dischargeComplaints}
                                                                                onBlur={() => { }}
                                                                                onChange={(value) => {
                                                                                    const V = value.target.value
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'dischargeComplaints',
                                                                                            value: V,
                                                                                        },
                                                                                    });
                                                                                }} />

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="doctor-submit text-end">
                                                                    <Button
                                                                        // disabled={isSubmitting}
                                                                        // loading={isSubmitting}
                                                                        type="primary"
                                                                        shape="round"
                                                                    // className="btn btn-primary submit-form me-2"
                                                                    // onClick={() => handleSubmit()}
                                                                    >
                                                                        Submit
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>

                                                )

                                            }}

                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default DischargeForm