/* eslint-disable no-unused-vars */
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { plusicon, refreshicon } from '../../components/imagepath';
import { useDispatch, useSelector } from "react-redux";
import { GetStaffListAction } from "../../store/Actions/StaffAction";
import queryString from "query-string";
import { CustomTable } from "../../components/Table";
import moment from 'moment';
import { matchPermissions } from '../../utils/Permission';

const StaffList = () => {
  const { StaffList, loader } = useSelector(state => state?.Staff);
  const { userDetails } = useSelector(state => state?.auth);
  const dispatch = useDispatch();
  const params = queryString.parse(location.search)
  useEffect(() => {
    dispatch(GetStaffListAction({ Query: `?page=${params?.page || 1}`, obj: '' }))
  }, [])

  const matchPermissionsForUser = matchPermissions(userDetails)
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => {
        return (
          < Link to={`/staff-list/staff-profile/${record?.id}`} > {text}</Link >
        )
      },
    },
    {
      title: "Department",
      dataIndex: "type",
    },

    {
      title: "Mobile",
      dataIndex: "phoneNo",

    }, {
      title: "Email",
      dataIndex: "email",
    }, {
      title: "Order Index   ",
      dataIndex: "orderIndex",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (text, record) => (
        <span>{(text && moment(text).format("DD-MM-YYYY") || '---')}</span>
      ),
    },
  ]
  return (
    <>
      <Header />
      <Sidebar id='menu-item1' id1='menu-items1' />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/staff-list?page=1">Staff</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Staff List</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Staff List</h3>
                            <div className="doctor-search-blk">
                            </div>
                            <div className="add-group">
                              {matchPermissionsForUser('admin-user.create') &&
                                <Link
                                  to="/staff-list/add-staff"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                              }

                              <Link
                                onClick={() => window.location.reload()}
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <CustomTable
                          CurrentPageNum={StaffList?.[0]?.page}
                          OnChange={(v) => {
                            var newp = params
                            newp.page = v
                            window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
                          }}
                          TotalPageList={StaffList?.[0]?.totalDocs}
                          column={columns}
                          dataSource={StaffList?.[0]?.docs}
                          dataSourceLength={StaffList?.[0]?.docs?.length}
                          pageSize={10}
                          loader={loader}
                          ShowHeader={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </>
    </>

  )
}

export default StaffList;
