/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { MedicineValidationSchema } from "../../../../utils/FormValidation";
import { useDispatch, useSelector } from "react-redux";
import { InputFromUser } from "../../../../components/Input/input";
import { ActionCreateMedicine, ActionGetMedicineByID, ActionUpdateMedicine } from "../../../../store/Actions/MasterDataAction";

const Add_Medicine = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const updateValue = searchParams.get("update");
    const pageValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const idValue = searchParams.get("id");

    useEffect(() => {
        if (idValue) {
            dispatch(ActionGetMedicineByID({ Query: `/${idValue}` }));
        }
    }, [pageValue, dispatch])

    const { medicine } = useSelector(state => state?.MasterData);
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/medicine">Medicine</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Add Medicine</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <Formik
                                                enableReinitialize={updateValue}
                                                initialValues={{
                                                    name: medicine?.name || "",
                                                    // expiryDate: medicine?.expiryDate || "",
                                                    // quantity: medicine?.quantity || "",
                                                    // unit: medicine?.unit || "",
                                                    // price: medicine?.price || "",
                                                    // category: medicine?.category || "",
                                                    // description: medicine?.description || "",
                                                }}
                                                validationSchema={MedicineValidationSchema}
                                                onSubmit={(values, { setSubmitting, setErrors }) => {
                                                    if (!updateValue) {
                                                        dispatch(ActionCreateMedicine({
                                                            "name": values.name,
                                                            // "expiryDate": values.expiryDate,
                                                            // "quantity": values.quantity,
                                                            // "unit": values.unit,
                                                            // "price": values.price,
                                                            // "category": values.category,
                                                            // "description": values.description,
                                                        },
                                                            (Res) => {
                                                                setSubmitting(false);
                                                                if (Res?.statusCode === 200) {
                                                                    window.location.href = `/medicine?page=${pageValue}`;
                                                                } else if (Res?.statusCode === 400) {
                                                                    setErrors(Res?.data?.errors);
                                                                } else {
                                                                    window.location.reload();
                                                                }
                                                            }))
                                                    } else {
                                                        dispatch(ActionUpdateMedicine({
                                                            Query: "/" + idValue,
                                                            obj: {
                                                                "name": values.name,
                                                                "quantity": values.quantity,
                                                                "expiryDate": values.expiryDate,
                                                                "unit": values.unit,
                                                                "price": values.price,
                                                                "category": values.category,
                                                                "description": values.description,
                                                            }
                                                        }, (Res) => {
                                                            setSubmitting(false);
                                                            if (Res?.statusCode === 200) {
                                                                window.location.href = `/medicine?page=${pageValue}`;
                                                            } else if (Res?.statusCode === 400) {
                                                                setErrors(Res?.data?.errors);
                                                            } else {
                                                                window.location.reload();
                                                            }
                                                        }))

                                                    }
                                                }}

                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue,
                                                    isSubmitting,
                                                }) => {
                                                    // console.log('errors', errors);
                                                    return (
                                                        <form onSubmit={handleSubmit}>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-heading">
                                                                        <h4>Add Medicine Data</h4>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex">
                                                                    <InputFromUser
                                                                        placeholder={"Name"}
                                                                        type="text"
                                                                        name="name"
                                                                        onChange={handleChange}
                                                                        FormGroupClassName={"col-xl-6 col-md-12"}
                                                                        LabelClassName={"col-form-label"}
                                                                        onBlur={handleBlur}
                                                                        value={values.name}
                                                                        label="Name"
                                                                        error={errors?.name}
                                                                        touched={touched.name}
                                                                    />
                                                                </div>

                                                                <div className="col-12 mt-4">
                                                                    <div className="doctor-submit text-end">
                                                                        <button
                                                                            type="submit"
                                                                            onClick={() => {
                                                                                handleSubmit()
                                                                            }}
                                                                            className="btn btn-primary submit-form me-2"
                                                                            disabled={isSubmitting}
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary cancel-form"
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    )
                                                }}
                                            </Formik>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </div >
            </>
        </>
    );
};

export default Add_Medicine;