import { call, put, takeEvery } from "redux-saga/effects";
import { CREATE_PATIENT_TESTIMONIAL, CREATE_TESTIMONIAL_LINK, GET_ALL_PATIENT_TESTIMONIAL, GET_ALL_TESTIMONIAL_LINK } from "../storeTypes";
import { CreatePatientTestimonialApi, CreateTestimonialLinkApi, GetPatientTestimonialApi, GetTestimonialLinkApi } from "../../utils/api";
import { GetAllPatientTestimonialSuccess, GetAllTestimonialLinkSuccess } from "../Actions/TestimonialLinkAction";

const File = 'TestimonialLinkSaga '
function* createTestimonialLinkSaga(action) {
    try {
        const response = yield call(CreateTestimonialLinkApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'createTestimonialLinkSaga Error :- ', error);
    }
}

function* getTestimonialLinkSaga(action) {
    console.log("im in Saga");
    try {
        const response = yield call(GetTestimonialLinkApi, action?.payload);
        console.log("GetAllTestimonialLinkSuccess", response?.data?.data[0]);
        if (response?.status === 200) {
            yield put(GetAllTestimonialLinkSuccess(response?.data?.data[0]));
        }
    } catch (error) {
        console.log(File + 'getTestimonialLinkSaga Error :- ', error);
    }
}

// -------------------- Patient Testimonial --------------------

function* createPatientTestimonialSaga(action) {
    try {
        const response = yield call(CreatePatientTestimonialApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'createPatientTestimonialSaga Error :- ', error);
    }
}

function* getPatientTestimonialSaga(action) {
    console.log("im in Saga");
    try {
        const response = yield call(GetPatientTestimonialApi, action?.payload);
        console.log("GetAllTestimonialLinkSuccess", response?.data?.data[0]);
        if (response?.status === 200) {
            yield put(GetAllPatientTestimonialSuccess(response?.data?.data[0]));
        }
    } catch (error) {
        console.log(File + 'getPatientTestimonialSaga Error :- ', error);
    }
}

export function* TestimonialLinkSaga() {
    [
        yield takeEvery(CREATE_TESTIMONIAL_LINK, createTestimonialLinkSaga),
        yield takeEvery(GET_ALL_TESTIMONIAL_LINK, getTestimonialLinkSaga),
        yield takeEvery(CREATE_PATIENT_TESTIMONIAL, createPatientTestimonialSaga),
        yield takeEvery(GET_ALL_PATIENT_TESTIMONIAL, getPatientTestimonialSaga),

    ]
}