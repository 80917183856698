import { call, put, takeEvery } from 'redux-saga/effects';
import { UpdateSolutionSlotApi, getAllSolutionSlotApi, getSolutionSlotByIdApi } from "../../utils/api";
import { GET_ALL_SOLUTION_SLOT, GET_SOLUTION_SLOT_BY_ID, UPDATE_SOLUTION_SLOT } from "../storeTypes";
import { handleApiResponse } from '../../utils/Funcations';
import { ActionGetSolutionSlotByIdSuccess, GetAllSolutionSlotSuccess } from '../Actions/SolutionSlotAction';

function* getAllSolutionSlotSaga(action) {
    try {
        const response = yield call(getAllSolutionSlotApi, action.payload);
        if (response?.status === 200) {
            yield put(GetAllSolutionSlotSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log('getAllSolutionSlotSagaError', error);
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)

    }
}

function* GetSolutionSlotByIdSaga(action) {
    try {
        const response = yield call(getSolutionSlotByIdApi, action.payload);
        if (response?.status == 200) {
            yield put(ActionGetSolutionSlotByIdSuccess(response?.data?.data));
        }
    }
    catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack);
        console.log(File + '/GetSolutionSlotByIdSaga Error:', error);
    }
}
function* UpdateSolutionSlotSaga(action) {
    try {
        const response = yield call(UpdateSolutionSlotApi, action.payload);
        console.log("UpdateSolutionSlotSaga", response);
        if (response?.status === 200) {

            action?.callBack(response?.data);
        }
    } catch (error) {
        console.log(File + '/UpdateSolutionSlotSaga Error:', error);

        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
    }
}

export function* SolutionSlotSaga() {
    [
        yield takeEvery(GET_ALL_SOLUTION_SLOT, getAllSolutionSlotSaga),
        yield takeEvery(GET_SOLUTION_SLOT_BY_ID, GetSolutionSlotByIdSaga),

        yield takeEvery(UPDATE_SOLUTION_SLOT, UpdateSolutionSlotSaga),
    ]
}