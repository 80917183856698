import { GET_ADMIN_USER_DETAILS, GET_ADMIN_USER_DETAILS_LIST, GET_ADMIN_USER_DETAILS_LIST_SUCCESS, GET_ADMIN_USER_DETAILS_SUCCESS, USER_LOGIN, USER_LOGIN_SUCCESS } from "../storeTypes";

export const ActionLoginUser = (payload, callBack) => {
    return {
        type: USER_LOGIN,
        payload,
        callBack
    };
};
export const ActionLoginUserSuccess = (payload) => {
    return {
        type: USER_LOGIN_SUCCESS,
        payload
    };
};
export const getAdminuUerDetails = (payload) => {
    return {
        type: GET_ADMIN_USER_DETAILS,
        payload,
    };
};
export const getAdminuUerDetailsSuccess = (payload) => {
    return {
        type: GET_ADMIN_USER_DETAILS_SUCCESS,
        payload
    };
};

export const GetAdminUserDetailsList = (payload) => {
    return {
        type: GET_ADMIN_USER_DETAILS_LIST,
        payload,
    };
};
export const GetAdminUserDetailsListSuccess = (payload) => {
    return {
        type: GET_ADMIN_USER_DETAILS_LIST_SUCCESS,
        payload
    };
};