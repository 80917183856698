import { CREATE_PRAKRUTI_STANDARD, CREATE_PRAKRUTI_STANDARD_SUCCESS, CREATE_TREATMENT_PROTOCOL, CREATE_TREATMENT_PROTOCOL_SUCCESS, GET_ALL_PRAKRUTI_STANDARD, GET_ALL_PRAKRUTI_STANDARD_SUCCESS, GET_ALL_TREATMENT_PROTOCOL, GET_ALL_TREATMENT_PROTOCOL_SUCCESS, GET_ALL_TREATMENT_SERVICE_DATA, GET_ALL_TREATMENT_SERVICE_DATA_SUCCESS } from "../storeTypes";


export const GetAllPrakrutistandard = (payload) => {
    return {
        type: GET_ALL_PRAKRUTI_STANDARD,
        payload,
    };
};
export const GetAllPrakrutistandardSuccess = (payload) => {
    return {
        type: GET_ALL_PRAKRUTI_STANDARD_SUCCESS,
        payload
    };
};
export const CreatePrakrutistandard = (payload, callBack) => {
    return {
        type: CREATE_PRAKRUTI_STANDARD,
        payload,
        callBack
    };
};
export const CreatePrakrutistandardSuccess = (payload) => {
    return {
        type: CREATE_PRAKRUTI_STANDARD_SUCCESS,
        payload
    };
};
//Treatment-Protocol
export const GetAllTreatmentprotocol = (payload) => {
    return {
        type: GET_ALL_TREATMENT_PROTOCOL,
        payload,
    };
};
export const GetAllTreatmentprotocolSuccess = (payload) => {
    return {
        type: GET_ALL_TREATMENT_PROTOCOL_SUCCESS,
        payload
    };
};

export const CreateTreatmentprotocol = (payload, callBack) => {
    return {
        type: CREATE_TREATMENT_PROTOCOL,
        payload,
        callBack
    };
};
export const CreateTreatmentprotocolSuccess = (payload) => {
    return {
        type: CREATE_TREATMENT_PROTOCOL_SUCCESS,
        payload
    };
};

export const GetAllTreatmentservice = (payload) => {
    return {
        type: GET_ALL_TREATMENT_SERVICE_DATA,
        payload,
    };
};
export const GetAllTreatmentserviceSuccess = (payload) => {
    return {
        type: GET_ALL_TREATMENT_SERVICE_DATA_SUCCESS,
        payload
    };
};