import { Button, Modal, Popover } from 'antd';
import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { plusicon, refreshicon } from '../../components/imagepath';
import { CustomTable } from '../../components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllFaq } from '../../store/Actions/FaqAction';
import queryString from 'query-string';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { ResponsiveComponent, trimText } from '../../utils/Funcations';
import { ActionDeleteById } from '../../store/Actions/CommonAction';
import { matchPermissions } from '../../utils/Permission';

const FaqList = () => {
    const { confirm } = Modal;
    const dispatch = useDispatch();
    const { Faqlist, loader } = useSelector(state => state?.Faq);
    const { userDetails } = useSelector(state => state?.auth);
    const params = queryString.parse(location.search);
    const Size = ResponsiveComponent()
    const pageSize = 10;

    useEffect(() => {
        dispatch(GetAllFaq());
    }, [])

    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('faq.update');
    const canDelete = matchPermissionsForUser('faq.delete');
    const canCerate = matchPermissionsForUser('faq.create');
    const content = (item) => (
        <div className="row col">
            {canUpdate &&
                <Button className="col m-2" onClick={() => { window.location.href = `/add-faq?update=true&&page=${1}&&id=${item?.id}` }}>Edit Faq</Button>
            }
            {canDelete &&
                <Button className="col m-2" onClick={() => showDeleteConfirm(item)}>Delete Faq</Button>
            }
        </div>
    );

    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.question} question?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteById({ Query: `admin/faqs/${item?._id}` }, (Res) => {
                    if (Res?.statusCode === 200) {
                        //setRefresh(true)
                        window.location.reload()
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };
    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Question",
            dataIndex: "question",
            render: (text) => (
                <div className="app-config-boldText">{trimText(text, Size === "phone" || Size === "tablet" ? 20 : 50)}</div>
            ),

        },
        {
            title: "Answer",
            dataIndex: "answer",
            render: (text) => (
                <div className="app-config-boldText">{trimText(text, Size === "phone" || Size === "tablet" ? 20 : 50)}</div>
            ),

        },


        {
            title: !canUpdate && !canDelete ? null : "Action",
            dataIndex: "roleName",
            render: (text, record) => {
                if (!canUpdate && !canDelete) {
                    return null
                } else {
                    return <Popover placement="top" title={text} content={() => content(record)}>
                        <Button type="text">• • •</Button>
                    </Popover >
                }
            },
        },
    ]

    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/faq-list/:slug">Faq</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Faq List</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Faq List</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">
                                                                    {canCerate &&
                                                                        <Link
                                                                            to={`/add-faq`}
                                                                            className="btn btn-primary add-pluss ms-2"
                                                                        >
                                                                            <img src={plusicon} alt="#" />
                                                                        </Link>
                                                                    }

                                                                    <Link
                                                                        to="#"
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <CustomTable
                                                    TotalPageList={Faqlist?.[0]?.totalDocs}
                                                    column={columns}
                                                    OnChange={(v) => {
                                                        var newp = params
                                                        newp.page = v
                                                        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
                                                    }}
                                                    CurrentPageNum={Faqlist?.[0]?.page}
                                                    dataSource={Faqlist?.[0]?.docs}
                                                    dataSourceLength={Faqlist?.[0]?.docs?.length}
                                                    pageSize={10}
                                                    loader={loader}
                                                    ShowHeader={true}
                                                    Header={<div className="staff-search-table">
                                                    </div>}
                                                    key={"hi"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default FaqList