/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import { Breadcrumb } from '../../components/Card/Cards'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import { InputFromUser } from '../../components/Input/input'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { ConsultationMasterDataAction } from '../../store/Actions/AppointmentAction'
import { ActionGetPatientPlanList } from '../../store/Actions/AppConfigAction'
import moment from 'moment'
import { ActionUpdateById } from '../../store/Actions/CommonAction'
import { yogaFormValidationSchema } from '../../utils/FormValidation'

const UpdateApi = {
    Treatment: 'admin/treatment-slot/',
    Solution: 'admin/solution-slot/',
    Service: 'admin/service-slot/',


}
const YogaConsultation = () => {
    const { ConsultationMasterData } = useSelector(state => state?.Appointment);
    const { patientPlanList, loader } = useSelector(state => state?.appConfig);
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const treatmentSlotId = searchParams.get("slotId");
    const bookingfor = searchParams.get("bookingfor");
    const BreadcrumbList = [{
        name: 'Yoga Consultation Form',
        linkTo: ``
    },]
    const patientPlanInfo = patientPlanList?.[0]
    console.log('patientPlanList', bookingfor);
    useEffect(() => {
        dispatch(ConsultationMasterDataAction({ Query: '' }))
        dispatch(ActionGetPatientPlanList({ Query: `?treatmentId=${treatmentSlotId}` }))
    }, [dispatch, treatmentSlotId])

    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div className="page-wrapper">
                    <div className="content">
                        <Breadcrumb page={BreadcrumbList} />
                        <div className="card-box profile-header mb-5">
                            <div className="col-md-12">
                                <div className="profile-view">

                                    <div className="profile-basic">
                                        <div className="row">
                                            <div className="col-md-5 col-xxl-4">
                                                <div className="profile-info-left">
                                                    <h3 className="user-name m-t-0 mb-0">{patientPlanInfo?.customerId?.name || '---'}</h3>
                                                    <small className="text-muted">Age:{patientPlanInfo?.customerId?.age || '---'}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <ul className="personal-info">
                                                    <li>
                                                        <span className="title">Phone:</span>
                                                        <span className="text"><Link to>{patientPlanInfo?.customerId?.name || '---'}</Link></span>
                                                    </li>
                                                    <li>
                                                        <span className="title">Email:</span>
                                                        <span className="text">{patientPlanInfo?.customerId?.email || '---'}</span>
                                                    </li>
                                                    <li>
                                                        <span className="title">Birthday:</span>
                                                        <span className="text"><Link>{patientPlanInfo?.customerId?.birthDate || '---'}</Link></span>
                                                    </li>
                                                    <li>
                                                        <span className="title">Gender:</span>
                                                        <span className="text">{patientPlanInfo?.customerId?.gender || '---'}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-sm-12">
                                <div className="card p-2">
                                    <div className="card-body">
                                        <Formik
                                            initialValues={{
                                                currentComplaints: "",
                                                exercise: [],
                                                date: null,
                                            }}
                                            validationSchema={yogaFormValidationSchema}
                                            onSubmit={(values, { setErrors, setSubmitting }) => {
                                                let yogaForm = {};
                                                const exercisesWithId = (ConsultationMasterData?.exercises || [])
                                                    .filter(item => Array.isArray(values?.exercise) && values.exercise.includes(item.title))
                                                    .map(({ id, title }) => ({ id, title }));

                                                const Date = moment(values.date).format('YYYY-MM-DD')
                                                if (Date) {
                                                    let entry = {
                                                        currentComplaints: values.currentComplaints,
                                                        exercise: exercisesWithId,
                                                    };
                                                    if (!yogaForm[Date]) {
                                                        yogaForm[Date] = [];
                                                    }
                                                    yogaForm[Date].push(entry);
                                                }
                                                console.log(yogaForm);



                                                dispatch(ActionUpdateById({ Query: UpdateApi[bookingfor] + treatmentSlotId, obj: { yoga: yogaForm } }
                                                    , (Respons) => {
                                                        console.log(Respons, "<<--");
                                                        if (Respons?.statusCode === 200) {
                                                            window.location.href = `/treatment-slot?page=1`
                                                            console.log(Respons, "<<--");
                                                        } else if (Respons?.statusCode === 400) {
                                                            setErrors(Respons?.data?.errors);
                                                        }
                                                        setSubmitting(false);
                                                    }));
                                            }}
                                        >
                                            {({
                                                errors, values, handleChange, handleSubmit, isSubmitting, touched
                                            }) => {
                                                return (
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-xl-4 col-md-8">
                                                                <div className="form-group">
                                                                    <label className="col-form-label"  >
                                                                        Current Complaints:
                                                                    </label>
                                                                    <textarea
                                                                        rows={1}
                                                                        cols={5}
                                                                        className="form-control"
                                                                        placeholder="Enter Notes"
                                                                        defaultValue={""}
                                                                        style={{ borderColor: errors.currentComplaints && touched.currentComplaints ? 'red' : '', borderWidth: 1 }}
                                                                        value={values.currentComplaints}
                                                                        onBlur={() => { }}
                                                                        onChange={(value) => {
                                                                            const V = value.target.value
                                                                            handleChange({
                                                                                target: {
                                                                                    name: 'currentComplaints',
                                                                                    value: V,
                                                                                },
                                                                            });
                                                                        }} />

                                                                </div>
                                                            </div>
                                                            <InputFromUser

                                                                placeholder={"Enter Your Exercise"}
                                                                type="multiple"
                                                                options={ConsultationMasterData?.exercises && ConsultationMasterData?.exercises?.map((v) => v.title) || []}
                                                                name="exercise"
                                                                onChange={(v) => {
                                                                    handleChange({
                                                                        target: {
                                                                            name: "exercise",
                                                                            value: v
                                                                        }
                                                                    });
                                                                }}
                                                                showSearch={true}
                                                                error={errors.exercise}
                                                                value={values.exercise}
                                                                touched={touched.exercise}
                                                                FormGroupClassName={"col-xl-4 col-md-6"}
                                                                LabelClassName={"col-form-label"}
                                                                onBlur={undefined}
                                                                filterOption={true}
                                                                label="Exercise"
                                                            />
                                                            <InputFromUser
                                                                placeholder={"Enter Your Date"}
                                                                type="date"
                                                                name="date"
                                                                onChange={(v) => {
                                                                    handleChange({
                                                                        target: {
                                                                            name: "date",
                                                                            value: v
                                                                        }
                                                                    });
                                                                }}
                                                                showSearch={true}
                                                                error={errors.date}
                                                                value={values.date}
                                                                touched={touched.date}
                                                                FormGroupClassName={"col-xl-4 col-md-6"}
                                                                LabelClassName={"col-form-label"}
                                                                onBlur={undefined}
                                                                filterOption={true}
                                                                label="Date"
                                                            />
                                                            <div className="doctor-submit text-end">
                                                                <Button
                                                                    disabled={isSubmitting}
                                                                    loading={isSubmitting}
                                                                    type="primary"
                                                                    shape="round"
                                                                    // className="btn btn-primary submit-form me-2"
                                                                    onClick={() => handleSubmit()}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default YogaConsultation