import { call, put, takeEvery } from "redux-saga/effects";
import { CREATE_CELEBRITY, GET_ALL_CELEBRITY } from "../storeTypes";
import { GetAllcelebritySuccess } from "../Actions/CelebrityAction";
import { CreateCelebrityApi, GetCelebrityApi } from "../../utils/api";


const File = 'CelebritySaga '
function* createCelebritySaga(action) {
    try {
        const response = yield call(CreateCelebrityApi, action.payload);
        if (response?.status === 200) {
            action?.callBack(response?.data);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            action?.callBack(error?.response?.data);
        }
        console.log(File + 'createCelebritySaga Error :- ', error);
    }
}

function* getCelebritylistSaga(action) {
    try {
        const response = yield call(GetCelebrityApi, action?.payload);
        if (response?.status === 200) {
            yield put(GetAllcelebritySuccess(response?.data?.data));
        }
    } catch (error) {
        console.log(File + 'getCelebritylistSaga Error :- ', error);
        //handleApiResponse(error?.response.status, error?.response?.data, action?.callBack)
    }
}

export function* CelebritySaga() {
    [
        yield takeEvery(CREATE_CELEBRITY, createCelebritySaga),
        yield takeEvery(GET_ALL_CELEBRITY, getCelebritylistSaga),

    ]
}