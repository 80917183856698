/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import { useEffect, useMemo } from "react";

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Popover } from "antd";
import queryString from 'query-string';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ResponsiveComponent } from "../../../utils/Funcations";
import TableColumnSearch from '../../../components/Input/TableColumnSearch';
import { ActionDeleteRoom, ActionGetRoomList } from "../../../store/Actions/MasterDataAction";
import { matchPermissions } from "../../../utils/Permission";
import { TableListPageCom } from "../../../components/TableListPageCom";
const { confirm } = Modal;

const Rooms = () => {
    const { loader, room } = useSelector(state => state?.MasterData);
    const { userDetails } = useSelector(state => state?.auth);
    const [showArrow, setShowArrow] = useState(true);
    const [Refresh, setRefresh] = useState(false);
    const [arrowAtCenter, setArrowAtCenter] = useState(false);
    const [IsSearching, setIsSearching] = useState(false);
    const pageSize = 10;
    const dispatch = useDispatch();
    const location = useLocation();
    const params = queryString.parse(location.search);
    const Size = ResponsiveComponent()
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [SearchList, setSearchList] = useState([]);
    const mergedArrow = useMemo(() => {
        if (arrowAtCenter)
            return {
                pointAtCenter: true,
            };
        return showArrow;
    }, [showArrow, arrowAtCenter]);
    const handleSearch = (selectedKeys, dataIndex) => {
        setSearchText(selectedKeys?.[0]);
        setSearchedColumn(dataIndex);

        if (params[dataIndex]) {
            window.location.replace(`/room?page=1&filterlist=true&${dataIndex}=${selectedKeys?.[0]}`)
        } else {
            window.location.replace(`/room?${location.search}&${dataIndex}=${selectedKeys?.[0]}`)
        }

    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        window.location.replace(`/room`)
    }

    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('room.update');
    const canDelete = matchPermissionsForUser('room.delete');
    const canCerate = matchPermissionsForUser('room.create');
    const content = (item) => (
        <div className="">
            {canUpdate &&
                <Button className="m-2" onClick={() => EditList(item?.id)}>Edit</Button>
            }
            {canDelete &&
                <Button onClick={() => showDeleteConfirm(item)}>Delete</Button>
            }
        </div>
    );

    const columns = [
        {
            title: "Sr",
            dataIndex: "sr",
            // key: "Sr",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * pageSize + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "Room Type",
            dataIndex: "docs.roomType",
            render: (text, record) => (
                <span>{record.roomType}</span>
            ),
            ...TableColumnSearch({ dataIndex: 'docs.roomType', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },
        {
            title: "Room No",
            dataIndex: "roomNo",
            render: (text, record) => (

                <span>{record.roomNo}</span>
            ),
            ...TableColumnSearch({ dataIndex: 'docs.roomNo', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },
        {
            title: "Capacity",
            dataIndex: "capcity",
            render: (text, record) => (

                <span>{record.capcity}</span>
            ),
            ...TableColumnSearch({ dataIndex: 'docs.capcity', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },
        {
            title: "Room Name",
            dataIndex: "roomName",
            render: (text, record) => (

                <span>{record.roomName}</span>
            ),
            ...TableColumnSearch({ dataIndex: 'docs.roomName', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },
        // {
        //     title: "Amenities",
        //     dataIndex: "amenities",
        //     render: (text, record) => (

        //         <span>{record.amenities}</span>
        //     ),
        //     ...TableColumnSearch({ dataIndex: 'docs.amenities', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        // },
        {
            title: "price",
            dataIndex: "price",
            render: (text, record) => (

                <span>{record.price}</span>
            ),
            ...TableColumnSearch({ dataIndex: 'docs.price', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },
        {
            title: "Shared Price",
            dataIndex: "sharedPrice",
            render: (text, record) => (
                <span>{record.sharedPrice}</span>
            ),
            ...TableColumnSearch({ dataIndex: 'docs.extraPersonPrice', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },
        {
            title: "Extra Bed Capacity",
            dataIndex: "extraBedCapacity",
            render: (text, record) => (

                <span>{record.extraBedCapacity}</span>
            ),
            ...TableColumnSearch({ dataIndex: 'docs.extraBedCapacity', setSearchText, setSearchedColumn, handleReset, handleSearch }),
        },

        {
            title: !canUpdate && !canDelete ? null : "Action",
            dataIndex: "id",
            key: "action",
            render: (text, record, num) => {
                if (!canUpdate && !canDelete) {
                    return null
                } else {
                    return (
                        <Popover placement="left" title={record.title} content={() => content(record)} arrow={mergedArrow}>
                            <Button type="dashed">• • •</Button>
                        </Popover>
                    )
                }
            },
        },
    ];

    useEffect(() => {
        dispatch(ActionGetRoomList({ Query: `?page=${params?.page || 1}` }));
        setRefresh(false);
    }, [dispatch, params?.page, Refresh])

    const handleSearch1 = (searchTerm) => {
        var newp = params
        newp.page = searchTerm
        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
    }

    const EditList = (searchTerm) => {
        window.location.href = `/room/add-room?update=true&&id=${searchTerm}&&page=${parseInt(params.page)}`
    }

    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.id}?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteRoom("/" + item?.id, (Res) => {
                    if (Res === 200) {
                        setRefresh(true);
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };

    const BreadcrumbList = [{
        name: 'Rooms',
        linkTo: `/room?page=${params?.page || 1}`
    },
    {
        name: 'Rooms List',
    }
    ]
    return (
        <TableListPageCom
            BreadcrumbList={BreadcrumbList}
            TableData={room?.docs}
            TableColumn={columns}
            ShowAddButton={true}
            Iscreate="room.create"
            CreateButtonLink={`/room/add-room?update=false&&page=${params?.page || 1}`}
            PageMainTitle="Rooms List"
            CurrentPage={room?.page || 1}
            Tableloader={loader}
            TotalPageList={room?.totalDocs}
            dataSourceLength={room?.docs?.length}
            handlePageChange={(v) => {
                var newp = params
                newp.page = v
                window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
            }}
        />

    );
};

export default Rooms;