/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { CustomTable } from "../../components/Table";
import { Button, Modal, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
    plusicon,
    refreshicon
} from "../../components/imagepath";
import { GetPaginatedPermissionListAction } from "../../store/Actions/PermissionsAction";
import queryString from "query-string";
import { ActionDeleteById } from "../../store/Actions/CommonAction";
import { matchPermissions } from "../../utils/Permission";
const { confirm } = Modal;
const Permissions = () => {
    const [EditMembersModal, SetEditMembersModal] = useState(false)
    const [Refresh, setRefresh] = useState(false);
    const dispatch = useDispatch();
    const { PaginatedPermissionsList, loader } = useSelector(state => state?.Permission);
    const { userDetails } = useSelector(state => state?.auth);
    const params = queryString.parse(location.search);
    useEffect(() => {
        dispatch(GetPaginatedPermissionListAction({ Query: `?page=${params?.page || 1}`, obj: undefined }))
        setRefresh(false)
    }, [Refresh])

    const matchPermissionsForUser = matchPermissions(userDetails);
    const canUpdate = matchPermissionsForUser('permission.update');
    const canDelete = matchPermissionsForUser('permission.delete');
    const canCerate = matchPermissionsForUser('permission.create');
    const content = (item) => (
        <div className="row col">
            {canUpdate &&
                <Button className="col m-2" onClick={() => { window.location.href = `permissions/create-permissions?update=true&&page=${1}&&id=${item?.id}` }}>Edit Permission</Button>
            }
            {canDelete &&
                <Button className="col m-2" onClick={() => showDeleteConfirm(item)}>Delete Permission</Button>
            }
        </div>
    );

    const showDeleteConfirm = (item) => {
        confirm({
            title: `Are you sure delete this ${item?.moduleName} Permission?`,
            icon: <ExclamationCircleFilled />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 1200,
            centered: true,
            onOk() {
                dispatch(ActionDeleteById({ Query: `admin/permissions/${item?.id}` }, (Res) => {
                    if (Res?.statusCode === 200) {
                        setRefresh(true)
                    }
                }))
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };
    const columns = [

        {
            title: "Sr",
            dataIndex: "s",
            render: (text, record, index) => {
                const currentPage = parseInt(params?.page) || 1;
                const calculatedIndex = (currentPage - 1) * 10 + index + 1;
                return <span>{calculatedIndex}</span>;
            },
        },
        {
            title: "moduleName",
            dataIndex: "moduleName",
        },
        {
            title: "name",
            dataIndex: "name",
        },
        {
            title: "Action",
            dataIndex: "moduleName",
            render: (text, record,) => {
                if (!canUpdate && !canDelete) {
                    return null
                }
                else {
                    return <Popover placement="top" title={text} content={() => content(record)}>
                        <Button type="text">• • •</Button>
                    </Popover>
                }

            },
        },
    ]
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            {/* Page Header */}
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link>Permissons</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Permissons List</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* /Page Header */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            {/* Table Header */}
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Permissons List</h3>
                                                            <div className="doctor-search-blk">
                                                                <div className="add-group">
                                                                    {canCerate &&
                                                                        <Link
                                                                            to={`/permissions/create-permissions`}
                                                                            className="btn btn-primary add-pluss ms-2"
                                                                        >
                                                                            <img src={plusicon} alt="#" />
                                                                        </Link>
                                                                    }

                                                                    <Link
                                                                        onClick={() => window.location.reload()}
                                                                        className="btn btn-primary doctor-refresh ms-2"
                                                                    >
                                                                        <img src={refreshicon} alt="#" />
                                                                    </Link>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <CustomTable
                                                    CurrentPageNum={PaginatedPermissionsList?.[0]?.page}
                                                    OnChange={(v) => {
                                                        var newp = params
                                                        newp.page = v
                                                        window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
                                                    }}
                                                    TotalPageList={PaginatedPermissionsList?.[0]?.totalDocs}
                                                    column={columns}
                                                    dataSource={PaginatedPermissionsList ? PaginatedPermissionsList?.[0]?.docs : []}
                                                    dataSourceLength={PaginatedPermissionsList?.[0]?.docs?.length}
                                                    pageSize={10}
                                                    loader={loader}
                                                    ShowHeader={true}
                                                    Header={<div className="staff-search-table">

                                                    </div>}
                                                    key={"hi"} />

                                                <Modal
                                                    open={EditMembersModal}
                                                    closable={true}
                                                    className='ant-modal'
                                                    forceRender={true}
                                                    zIndex={900}
                                                    style={{ margin: 10 }}
                                                    onOk={() => SetEditMembersModal(false)}
                                                    styles={{
                                                        body: {
                                                            overflow: "hidden",
                                                        },
                                                        mask: {
                                                            backdropFilter: 'blur(10px)',
                                                        },
                                                        content: {
                                                            borderRadius: 40,
                                                        },
                                                    }}
                                                    okText={'Submit'}
                                                    centered
                                                    confirmLoading={false}
                                                    onCancel={() => SetEditMembersModal(false)}
                                                    closeIcon={true}
                                                >


                                                </Modal>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
};

export default Permissions;