import { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactPasswordToggleIcon from 'react-password-toggle-icon';
import FeatherIcon from "feather-icons-react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Select, Spin, } from 'antd';
// import debounce from 'lodash/debounce';

export const FormInput = (props) => {
    const { touched, label, name, value, onChange, disabled, onBlur, FormGroupClassName, error, FormControlClassName, LabelClassName, InputContainer, placeholder = '', type = 'text' || 'checkbox' || 'color' || 'email' || 'number' || 'password' || 'search' || 'url' || 'tel' } = props;
    let inputRef = useRef();
    const showIcon = () => <i className={`feather feather-eye ${error && touched ? '' : ''}`} >
        <FeatherIcon icon="eye" />
    </i>;
    const hideIcon = () => <i className={`feather feather-eye-slash ${error && touched ? '' : ''}`}>
        <FeatherIcon icon="eye-off" />
    </i>
    return (
        <div className={`form-group ${FormGroupClassName}`} >
            <label style={{ color: error && touched && 'red' }} className={LabelClassName}>
                {label}
                {error && <span className="login-danger"> *</span>}
            </label>
            <div className={`${InputContainer}`} >
                <input
                    ref={inputRef}
                    className={`pass-input form-control ${error && touched ? 'error' : ''}${FormControlClassName}`}
                    placeholder={placeholder}
                    aria-describedby="sizing-addon3"
                    type={type}
                    style={{ borderColor: error && touched && 'red', borderWidth: 1 }}
                    name={name}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                />
                {type === 'password' &&
                    <ReactPasswordToggleIcon
                        inputRef={inputRef}
                        showIcon={showIcon}
                        hideIcon={hideIcon}
                    />
                }
                {error && touched && <div className="text-danger m-1 ">{error}</div>}
            </div>
        </div>
    );
};

FormInput.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    error: PropTypes.string,
    FormGroupClassName: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    InputContainer: PropTypes.string,
    LabelClassName: PropTypes.string,
    FormControlClassName: PropTypes.string,
    touched: PropTypes.bool,
    disabled: PropTypes.bool,
};

export const SingleMultiSelectDropDown = (props) => {
    const {
        touched = false,
        onSearchLoading,
        label,
        mode = "multiple",
        Showlabel = true,
        FormGroupClassName,
        error = "",
        placeholder,
        LabelClassName,
        InputConatiner,
        onBlur,
        options,
        onChange,
        onSearch,
        dropdownRender,
        value = [],
        Dropdown_ClassName,
        placement,
        disabled
    } = props;
    return (
        <>
            <div className={`form-group ${FormGroupClassName}`}>
                {Showlabel && <label style={{ color: error && touched && 'red' }} className={LabelClassName}>
                    {label}
                    {error && <span className="login-danger"> *</span>}
                </label>}
                <div className={`${InputConatiner}`} >

                    <Select
                        showSearch
                        style={{
                            height: 43, width: '100%',
                        }}
                        placement={placement}
                        className={Dropdown_ClassName}
                        mode={mode}
                        maxTagCount={'responsive'}
                        value={value}
                        disabled={disabled}
                        optionFilterProp="children"
                        onChange={onChange}
                        dropdownRender={dropdownRender}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        // filterOption={filterOption}
                        filterOption={false}
                        notFoundContent={onSearchLoading ? <Spin size="small" /> : null}
                        status={error && touched && 'error'}
                        placeholder={placeholder}
                        options={options?.map((item) => ({
                            label: item,
                            value: item,
                        }))}
                    />
                    {error && touched && <div className="text-danger m-1 ">{error}</div>}
                </div>
            </div>
        </>
    );
};

SingleMultiSelectDropDown.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    onBlur: PropTypes.func.isRequired,
    error: PropTypes.string,
    placement: PropTypes.string,
    FormGroupClassName: PropTypes.string,
    Dropdown_ClassName: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    InputConatiner: PropTypes.string,
    mode: "multiple" | "tags" | undefined,
    LabelClassName: PropTypes.string,
    FormControlClassName: PropTypes.string,
    touched: PropTypes.bool,
    onSearchLoading: PropTypes.bool,
    Showlabel: PropTypes.bool,
    options: PropTypes.array,
    value: PropTypes.any | null,
    dropdownRender: PropTypes.node | null,
    disabled: PropTypes.bool,

};

export function RadioButton({ options, selectedValue, onBlur, touched, onChange, label, error }) {

    return (
        <div className="col-xl-6 col-md-12" style={{ marginBottom: 5 }}>
            <label className="col-form-label" style={{ color: error && touched && 'red', }} >
                {label}
                {error && <span className="login-danger"> *</span>}
            </label>
            <div className={`form-control d-flex justify-content-between ${error && touched ? 'error' : ''}`} style={{ borderColor: error && touched && 'red', borderWidth: 1 }}>
                {options?.map((option, index) => (
                    <div className="radio col-xl col-md" key={index}>
                        <label>
                            <input
                                type="radio"
                                name={label}
                                value={option.value}
                                checked={selectedValue === option.value}
                                onChange={() => onChange(option.value)}
                                onBlur={onBlur}
                            /> {option.label}
                        </label>
                    </div>
                ))}
            </div>
            {error && touched && !selectedValue && <div className="text-danger m-1 ">{error}</div>}
        </div>
    );
}

RadioButton.propTypes = {
    label: PropTypes.string.isRequired,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    selectedValue: PropTypes.string.isRequired,
    touched: PropTypes.bool,
    options: PropTypes.array.isRequired,
};

export function DebounceSelect({ fetchOptions, ...props }) {

    // const fetchRef = useRef(0);
    // const debounceFetcher = useMemo(() => {
    //     const loadOptions = (value) => {
    //         fetchRef.current += 1;
    //         const fetchId = fetchRef.current;
    //         setOptions([]);
    //         setFetching(true);
    //         fetchOptions(value).then((newOptions) => {
    //             if (fetchId !== fetchRef.current) {
    //                 // for fetch callback order
    //                 return;
    //             }
    //             setOptions(newOptions);
    //             setFetching(false);
    //         });
    //     };
    //     return debounce(loadOptions, debounceTimeout);
    // }, [fetchOptions, debounceTimeout]);
    return (
        <Select
            labelInValue
            filterOption={false}
            mode={undefined}
            // onSearch={debounceFetcher}
            // notFoundContent={false ? <Spin size="small" /> : null}
            {...props}
            options={fetchOptions}
        />
    );
}

DebounceSelect.propTypes = {
    props: PropTypes.func.isRequired,
    selectedValue: PropTypes.string.isRequired,
    fetchOptions: PropTypes.array.isRequired,
};
