import { icon1, pdf } from "../components/imagepath";
export const ReportCardData = [{
    text: "Lorem ipsum dolor sit",
    icon: pdf

},
{
    text: "Lorem ipsum dolor sit",
    icon: pdf
},
{
    text: "Lorem ipsum dolor sit",
    icon: icon1
},

];

export const DietCardData = [{
    text: "8 Cups-per day",
    icon: pdf

},
{
    text: "3 Cups-per day",
    icon: pdf
},
{
    text: "Intermittent fasting ,Intermittent fasting ,",
    icon: icon1
},
{
    text: "Tabel sugar,Daily Avg 3/6",
    icon: icon1
},
{
    text: "Lactose,Beans",
    icon: icon1
},

];

export const MedicalHistoryCardData = [{
    desc: "chronic disease",
    text: "IHD,Obesity,Chronic thyroid disorder",
    icon: pdf

},
{
    desc: "Diabetes Emergencies",
    text: "Diabetic Ketoacidosis",
    icon: pdf
},
{
    desc: "Sugery",
    text: "Liposuction",
    icon: icon1
},
{
    desc: "Family disease",
    text: "Obesity(Father)",
    icon: icon1
},
{
    desc: "Diabetes related complication",
    text: "Diabetic Ketoacidosis",
    icon: icon1
},

];
export const PrivetKey = { DISABLE: 14239, FALSE: 197510 }

export const Options = {
    "Prakruti": [
        "Amladharmi",
        "Samadharmi",
        "Kshardharmi"
    ],
    "Nadi": [
        "Vataja Nadi",
        "Pittaja Nadi",
        "Kaphaja Nadi",
        "Mandagati",
        "Madhya Gati",
        "Teekshna Gati",
    ],

    "Hetu": [
        "buddhi",
        "adhva",
        "rag",
        "dukha",
        "garbhashayanirharana",
        "atirikta tishtati karma",
        "atirikta upavishati karma",
        "bhojnottara atyambupana",
        "bhojanamadhye atyambupana",
        "pratakale atyambupana",
        "virudhaahara",
        "rajonivruti",
        "branching of thoughts",
        "Manasik Aghat"
    ],
    "Anupana": [
        "Water",
        "Lukewarm Water",
        "Honey",
        "Ghee",
        "Milk",
        "Buttermilk",
        "Curd"
    ],
    "Jivha": [
        "Samata",
        "Alpasamata",
        "Nirama"
    ],
    "StrotasDushti": [
        "Rasavaha strotas",
        "raktavaha strotas",
        "Pranavaha strotas",
        "Udakvaha strotas ",
        "Annavaha strotas",
        "Mansavaha strotas",
        "Medovaha  strotas",
        "Astivaha strotas",
        "Majjavaha strotas",
        "Shukravaha strotas",
        "Purishvaha strotas",
        "Mutravaha strotas",
        "Swedovaha strotas"
    ],
    "Upshay": [
        "samata",
        "alpasamata",
        "nirama",
        "Cold Milk",
        "Lemon Water",
        "Curd Rice",
        "Cold Water",
        "Hot Water",
        "Air conditioned room",
        "Tea",
        "Coffee",
        "Cold Fomentation",
        "Hot Foemntation",
        "Hot Water Bath",
        "Cold Water Bath",
        "Butter Milk",
        "Curd",
        "Black Raseins",
        "Hot Milk",
    ],
    "VirechanDay1toDay6": [
        "Tab Halad+Tab Hiradaghan",
        "Turmeric powder + Jaggery",
        "Pranda powder + Jaggery",
        "Amala powder + Pranda Powder",
        "Ginger Juice + Pranda Powder",
        "Dhana powder + Ghee",
        "Amala powder+ Turmeric powder",
        "Pranda powder+ Turmeric powder",
        "Jatamansi powder + Jaggery",
        "Jatamansi Ghrut",
        "Gokharu Ghrut",
        "Tikta Ghrut",
        "T.R Powder + Jaggery",
        "Jaggery + Dhana powder",
        "Jaggery + Ginger juice",
        "Tab Halad+Jaggery",
        "Tab Pranda + Jaggery"
    ],
    "Virechan8thDay": [
        "Castor Oil/ Mease tab 4/ Eranda Bhrusta Tab 1,2,3,4,",
        "Castor Oil/ Mease tab 4/ Eranda Bhrusta Tab 1,2,3,4,",
        "Castor Oil/ Mease tab 4/ Eranda Bhrusta Tab 1,2,3,4,",
        "Castor Oil/ Mease tab 4/ Eranda Bhrusta Tab 1,2,3,4,",
        "Castor Oil/ Mease tab 4/ Eranda Bhrusta Tab 1,2,3,4,",
        "Castor Oil/ Mease tab 4/ Eranda Bhrusta Tab 1,2,3,4,",
        "Castor Oil/ Mease tab 4/ Eranda Bhrusta Tab 1,2,3,4,",
        "Castor Oil/ Mease tab 4/ Eranda Bhrusta Tab 1,2,3,4,",
        "Castor Oil/ Mease tab 4/ Eranda Bhrusta Tab 1,2,3,4,",
        "Castor Oil/ Mease tab 4/ Eranda Bhrusta Tab 1,2,3,4,",
        "Castor Oil/ Mease tab 4/ Eranda Bhrusta Tab 1,2,3,4,"
    ],
    "Eyes": [
        "Aavila Netrata",
        "Samrambha Netrata",
        "Ashru",
        "Kandu",
        "Upadeha",
        "Guru",
        "Oosha",
        "Toda",
        "Raga",
        "Shula Vartma Kosheshu",
        "Shooka Poornaabham iva",
        "Roopa Hananam",
        "Kriyaa Hananam",
        "Akshikutshoth"
    ],
    "Nails": [
        "Vata (Brittle,Thin,Straited)",
        "Pitta ( Medium ,Soft,Pink)",
        "Kapha Karge,Thick.Long)",
        "Bitten or Chewed nail (Anxiety,Fear,Excess Vata)",
        "White or Blue (Delicate lungs or heart)",
        "Dystrophic nail(Bowel Cancer)",
        "Transverse growth Arrest line (Malabsorption)",
        "Poor Cuticle (Mental Depression)",
        "Longitudinal Striations (Malabsorption Deranged Vata)",
        "Yellow coloured nail ( Sensitive Liver excess Pitta)",
        "Short nail(Congenital septal defects)",
        "Very Short nail (Micronychia,Intellectually impaired Vata In Majja Dhatu)",
        "White spots (Calcium,Magnesiun,Zince Deficiency)",
        "Beading,Longitudinal ridges,no Lunula Hypothyrodism)",
        "Small Pits (Psoriasis)",
        "Marked Longitudinal ridges (Renal Disease)",
        "Brown lines(In fingers & toes) Renal Failure",
        "Trangular Lunula (Obsessiveness) Excess Pitta",
        "Pale coloured nail (Anemia)",
        "Onycholysis,poor cuticles, ink pigmentation,Auto immune disordes,Hypothyrodism,Breathlessness",
        "Dark Pink or Brown (Psoriasis,Cirrhosis)",
        "Lunala Liver Dysfunction",
        "Red Lunula (Cardiac Failure)",
        "Vertical red streaks (Capillary bleeding, Liver disorder)",
        "Purple nails (Congestion,Cerebal Hypoxia)",
        "Brown at tip of nail (Kidney Failure)",
        "Wide Squarish Nail (Thyroid or Harmonal disorder)",
        "Long and Thin(Pituitary or Harmonal deficiency)",
        "Blue spots (Circulation problem,Asthama,Emphysema)",
        "Excessive redness (Excess red blood cells)",
        "Clubbed nail(Heart & lungs weakness,Low Prana)",
        "Parrot beak nail(Chronic Kapha Disorder)",
        "Transverse groove(Chronic fever ,Kapha disorder)",
        "Koilonychia,Concave (iron deficiency)",
        "Bumps at end of the nail(chronic lung infection,Kapha Disorder)",
        "Stepped Surface(Malnutrition,Impaired agni)"
    ],
    "Anupshay": [
        "Tur Dal",
        "Curd",
        "Curd Rice",
        "Cold Water",
        "Hot Water",
        "Air conditioned room",
        "Tea",
        "Coffee",
        "Cold Fomentation",
        "Hot Foemntation",
        "Hot Water Bath",
        "nirama",
        "Cold Water Bath",
        "Butter Milk"
    ],
    "Yakrut": [
        " Cold Milk",
        "Lemon Water",
        "Curd Rice",
        "Cold Water",
        "Hot Water",
        "Air conditioned room",
        "Tea",
        "Coffee",
        "Cold Fomentation",
        "Hot Foemntation",
        "Hot Water Bath",
        "Cold Water Bath",
        "Butter Milk",
        "Curd",
        "Black Raseins",
        "Hot Milk"
    ],
    "Pleeha": [
        "ghana",
        "buddhi",
        "agneya",
        "adhava"
    ],
    "AllDayData": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ],
    "Nada": {
        "Pleeha": [
            "Ghana",
            "Buddhi",
            "Agneya",
            "Adhava"
        ],
        "Yakrut": [
            "Ghana",
            "Buddhi",
            "Agneya"
        ]
    },
    "Sparshasahatva": [
        "Yakrut",
        "Pleeha",
        "Dakshi Vruka",
        "Vaam Vruka",
        "Apan Kaksha"
    ],
    "SparshasahatvaSubOptions": {
        "Yakrut": [
            "Vedna",
            "Alpavedna",
            "Datharta",
            "Mrudu"
        ],
        "Pleeha": [
            "Vedna",
            "Alpavedna",
            "Datharta",
            "Mrudu"
        ],
        "DakshiVruka": [
            "Vedna",
            "Alpavedna",
            "Datharta",
            "Mrudu"
        ],
        "VaamVruka": [
            "Vedna",
            "Alpavedna",
            "Datharta",
            "Atimrudu"
        ],
        "ApanKaksha": [
            "Vedna",
            "Alpavedna",
            "Datharta",
            "Atimrudu"
        ]
    },
    "WhenToTakeMedicine": [
        "6AM Morning",
        "6PM Evening",
        "10 AM Morning",
        "2 PM Afternoon",
        "Before Lunch",
        "Before Dinner",
        "After Lunch",
        "After Dinner",
        "In between Both the Meals( Lunch & Dinner)",
        "Before Sleeping",
        "Every Hourly",
        "Three Times A Day (TID)",
        "Nasya - 2 Drops 3 times a day,4 Drops 3 times a day",
        "Jotishmati Oil - 2 drops on medicine to be taken before sleeping,2 drops on Medicine 6AM Morning & 6PM Evening",
        "C.cal Powder - 1 tsf with medicine which is to be taken before lunch & before dinner, 1 tsf with medicine which is to be taken After lunch & After donner, 1 tsf with medicine which is to be taken 6AM Morning & 6PM Evening.",
    ],
    "CourseDuration": [
        "1 Month",
        "2 Months",
        "3 Months",
        "4 Months",
        "5 Months",
        "6 Months",
        "Life Time",
    ],
    "Diagnosis": [
        "Pachanvikar",
        "Ajerna",
        "Grahani",
        "Jvara",
        "Kasa",
        "Kustha",
        "Madatyaya",
        "Arsha",
        "Prameha Pidakas",
        "Visarpa",
        "Atisara",
        "Gulma",
        "Pleeha",
        "Shwas",
        "Hikka",
        "Trishna",
        "Chardi",
        "Aruchi",
        "Shiroroga",
        "Hridroga",
        "Panduroga",
        "Unmada",
        "Apasmara",
        "Netra rog",
        "Karna rog ",
        "Mukha rog",
        "Mada",
        "Murcha",
        "Shosha",
        "Kilasa",
        "Raktapitta",
        "Varna",
        "Ayama",
        "Gridhrasi",
        "Kamala",
        "Ama",
        "Vatrakta",
        "Urustambha",
        "Sanyasa",
        "Mahagada",
        "Krimi rog",
        "Prameha ",
        "Yoni Vyapat",
        "Madhumeha",
        "Mankavikar",
        "Sandhivat",
        "Amavat",
        "Pittavikar",
        "Sthaoulya",
        "Krushtava",
        "Khalitya",
        "Palitya",
        "Psoriasis",
        "Mukhadukshika",
        "Jerna Pratishaya",
        "Jerna Kass",
        "Ayasen Shwas",
        "Saysen shwas",
        "Ardhangvayu",
        "Kakasandya",
        "Ankylosing Spondylitis",
    ],
    "Excercise": [
        "Hasta Sanchalan",
        "Pada Sanchalan",
        "Merudanda Sanchalan -1",
        "Merudanda Sanchalan -2",
        "Merudanda Sanchalan -3",
        "Merudanda Sanchalan -4",
        "Padangula Sanchalan",
        "Ghota Sanchalan",
        "Janu Sanchalan",
        "Ardha Titali",
        "Purna Titali",
        "Hastangula chalan",
        "Mangat V Manibandha Chalan",
        "Karpur Sanchalan",
        "Skandha Sanchalan - 1",
        "Karna Mardan",
        "Netra Sanchalan",
        "SinhaMudra",
        "Veerbhadrasan",
        "Tadasan",
        "Triyak Tadasan",
        "Trikonasan",
        "Malasan",
        "Ushtrasan",
        "Vyaghrasan",
        "Padmasan",
        "Padmasan Yogmudra Type -1",
        "Uthita Padmasan",
        "Vajrasan",
        "Vajarasan Yogmudra Type 1",
        "Vajarasan Yogmudra Type 2",
        "Pashimotanasan",
        "Vakrasan -1",
        "Vakrasan -2",
        "Ardhamatsyadarsan",
        "Supta Vajrasan",
        "Parvatasan",
        "Gomukhasan",
        "Marjarasan",
        "Aakaran Dhunrasan type -1",
        "Aakaran Dhunrasan type -2",
        "Sharnagat Mudra",
        "Yognidra",
        "Ekpad Chakra",
        "Dvipad Chakra",
        "Ekpad Cycling",
        "Divpad Cycling",
        "DIvipad Utanapadasan",
        "Markatasan 1",
        "Markatasan 2",
        "Viprit Karni",
        "Sarvangasan",
        "Halasan",
        "Matsyasan",
        "Pavanmuktasan",
        "Ardha Chakrasan",
        "Anantasan",
        "Naukasan",
        "Dhanurasan",
        "Ekpad Shalbhasan",
        "Shalbhasan",
        "Saralhasta Bhujangasan",
        "Sahajhastsa Bhujangasan",
        "Vkarahast Bhujangasan",
        "Surya Namskar",
        "Pranayam",
        "Anulom Vilom",
        "Bhramari",
        "Shitakari Pranayan",
        "Shitali Pranayam",
        "Omkar",
        "Shwasan",
        "Pranmudra",
        "Apanvayu Mudra",
        "Shunya Mudra",
        "Pruthavi Mudra",
        "Jalneti",
        "Vastra Dhauti",
        "Shankha Prakshalan",
        "Kapalbhati",
        "Agnisar",
        "Udiyan Bandha",
        "Badhakonasan",
        "Vrukshasan",
        "Jalad Shwasan",
        "Dirgha Shwasan",
        "Bhojonotar Vajrasan",
        "Shashakasan",
        "Virabhadrasan",
        "Chakrasan",
        "Nadishodhan",
        "Shirshasan",
        "Shira Sanchalan",
        "Utanapadasan",
        "Setubandhsan",
        "Uttanapadasan"
    ],
    'AllDayTimeData': [
        "12:00 AM",
        "1:00 AM",
        "2:00 AM",
        "3:00 AM",
        "4:00 AM",
        "5:00 AM",
        "6:00 AM",
        "7:00 AM",
        "8:00 AM",
        "9:00 AM",
        "10:00 AM",
        "11:00 AM",
        "12:00 PM",
        "1:00 PM",
        "2:00 PM",
        "3:00 PM",
        "4:00 PM",
        "5:00 PM",
        "6:00 PM",
        "7:00 PM",
        "8:00 PM",
        "9:00 PM",
        "10:00 PM",
        "11:00 PM",
        "12:00 PM",

    ],
    'AllMonthData': [
        "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ]
}

export const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

export const consultationTypeData = [
    "First Consultation",
    "Emegency",
    "Treatment",
];

export const totalMinutesData = [
    "10",
    "20",
    "30",
    "40",
    "50",
];

export const PriceData = [
    "120",
    "150",
    "200",
];

export const sufferedFromDiseasesData = [
    "Measles",
    "Chicken pox",
    "Small Pox",
    "Malaria",
    "Typhoid",
    "Jaundice",
    "Dysentry",
    "Worms",
    "Conjuntivitis",
    "Ear Problems",
    "Herpes",
    "Pneumonia",
    "Fractures",
    "Skin Disease",
    "Diarrhoea",
    "Heart Disease",
    "Kidney Stone",
    "Acidity"
];

export const diseasesRunInFamilyData = [
    "Blood Pressure",
    "Diabetes",
    "Leucoderma",
    "Dwarfism",
    "Asthma",
    "Cancer",
    "Heart Disease"
];

export const howManyTimeData = [
    "1",
    "2",
    "3",
    "4",
    "5"
];

export const natureOfStoolData = [
    "Hard Like a stone",
    "Like a peeled banana",
    "Sticky",
    "Liquid",
    "Spreads everywhere"
];

export const stinkOrFloatData = [
    "Stink", "Float"
];

export const urineTimeData = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "more than 10"
];

export const bleedingInUrineData = [
    "No",
    "Bleeding",
    "Pus"
];

export const urineColourData = [
    "White",
    "Light Yellow",
    "Dark Yellow",
    "Turbid",
    "Reddish"
];

export const sweatMoreOrLessData = [
    "More",
    "Less",
    "Average"
];

export const palmsAndSolesData = [
    "No",
    "Both",
    "Palms",
    "Soles"
];

export const periodLastingdaysData = [
    "2",
    "3",
    "4",
    "5",
    "6",
    "7"
];

export const colorOfPeriodData = [
    "Red",
    "Blackish"
];

export const problemsData = [
    "Lower back ache",
    "Leg ache",
    "Stomach ache",
    "Vomiting"
];

export const brstIssuesData = [
    "Tumor",
    "Pain",
    "Oozing of Milk/Blood/Pus",
    "Heaviness",
    "Small Size",
    "None"
];

export const deliveryTypeData = [
    "Normal",
    "Caesarean"
];

export const stomachIssuesData = [
    "Colitis",
    "Obesity",
    "Marks/Spots/Strech Marks",
    "Stomach Ache",
    "Vomiting",
    "Diarrhoea",
    "Constipation"
];

export const natureOfworkData = [
    "Sitting",
    "Standing",
    "Hectic",
    "Mental Stress",
    "In an Air Conditioned room",
    "Intellectual"
];

export const sleepTypeData = [
    "Sound",
    "Disturbed"
];

export const sleepDuringDayData = [
    "Yes-Before Lunch",
    "Yes-After Lunch",
    "No"
];

export const sleepIssuesData = [
    "Biting teeth while sleeping",
    "Bed Wetting",
    "Talking While Sleeping",
    "Walking while sleeping",
    "None"
];

export const addictionData = [
    "Beetle Nut",
    "Beetle Leaf",
    "Tobacco",
    "Bidi",
    "Cigarette",
    "Pan Parag",
    "Mawa",
    "Alcohol"
];

export const temaparamentData = [
    "Sentimental",
    "Angry",
    "Suspicious",
    "Greedy",
    "Worrying",
    "Ambitious",
    "Thinking deeply about something that makes you Unhappy"
];

export const memoryLossData = [
    "No",
    "Short Term",
    "Long Term"
];

export const atmosphereInHomeData = [
    "Peaceful",
    "Stressful",
    "Suspicious",
    "Hatred",
    "Jealousy",
    "Other"
];

export const roomsData = [
    {
        title: 'Royal Rooms ',
        count: 7,
        totalRoom: 15,
        countMale: 23,
        checkIn: 23,
        checkOut: 23,
        slug: 'royal-rooms',

    },
    {
        title: 'Delux ',
        count: 4,
        totalRoom: 15,
        countMale: 23,
        checkIn: 23,
        checkOut: 23,
        slug: 'delux',

    },
    {
        title: 'Semi Delux',
        count: 8,
        totalRoom: 15,
        countMale: 23,
        checkIn: 23,
        checkOut: 23,
        slug: 'semi-delux',

    },
    {
        title: 'Dormitory',
        count: 7,
        totalRoom: 15,
        countMale: 23,
        checkIn: 23,
        checkOut: 23,
        slug: 'dormitory',

    },
    {
        title: 'General ward',
        count: 3,
        totalRoom: 15,
        countMale: 23,
        checkIn: 23,
        checkOut: 23,
        slug: 'general-ward',

    },
    {
        title: 'Basic',
        count: 6,
        totalRoom: 15,
        countMale: 23,
        checkIn: 23,
        checkOut: 23,
        slug: 'basic',

    },
];

export const Permission = [
    'room.index',
    'room.create',
    'room.update',
    'room.edit',
    'room.delete',
    'boocking.index',
    'boocking.create',
    'boocking.update',
    'boocking.edit',
    'boocking.delete',
    'patient.index',
    'patient.create',
    'patient.update',
    'patient.edit',
    'patient.delete',
    'order.index',
    'order.create',
    'order.update',
    'order.edit',
    'order.delete',
    'appointment.index',
    'appointment.create',
    'appointment.update',
    'appointment.edit',
    'appointment.delete',
    'service-slot.index',
    'service-slot.create',
    'service-slot.update',
    'service-slot.edit',
    'service-slot.delete',
    'treatment-slot.index',
    'treatment-slot.create',
    'treatment-slot.update',
    'treatment-slot.edit',
    'treatment-slot.delete',
    'solution-slot.index',
    'solution-slot.create',
    'solution-slot.update',
    'solution-slot.edit',
    'solution-slot.delete',
    'room-slot.index',
    'room-slot.create',
    'room-slot.update',
    'room-slot.edit',
    'room-slot.delete',
    'admin-user.index',
    'admin-user.create',
    'admin-user.update',
    'admin-user.edit',
    'admin-user.delete',
    'role.index',
    'role.create',
    'role.update',
    'role.edit',
    'role.delete',
    'permission.index',
    'permission.create',
    'permission.update',
    'permission.edit',
    'permission.delete',
    'leave.index',
    'leave.create',
    'leave.update',
    'leave.edit',
    'leave.delete',
    'holiday.index',
    'holiday.create',
    'holiday.update',
    'holiday.edit',
    'holiday.delete',
    'celebrity.index',
    'celebrity.create',
    'celebrity.update',
    'celebrity.edit',
    'celebrity.delete',
    'testimonialLink.create',
    'testimonialLink.update',
    'testimonialLink.edit',
    'testimonialLink.delete',
    'treatment.index',
    'treatment.create',
    'treatment.update',
    'treatment.edit',
    'treatment.delete',
    'solution.index',
    'solution.create',
    'solution.update',
    'solution.edit',
    'solution.delete',
    'service.index',
    'service.create',
    'service.update',
    'service.edit',
    'service.delete',
    'do-and-donts.index',
    'do-and-donts.create',
    'do-and-donts.update',
    'do-and-donts.edit',
    'do-and-donts.delete',
    'virechan.index',
    'virechan.create',
    'virechan.update',
    'virechan.edit',
    'virechan.delete',
    'memberShip.index',
    'memberShip.create',
    'memberShip.update',
    'memberShip.edit',
    'memberShip.delete',
    'exercise.index',
    'exercise.create',
    'exercise.update',
    'exercise.edit',
    'exercise.delete',
    'medicine.index',
    'medicine.create',
    'medicine.update',
    'medicine.edit',
    'medicine.delete',
    'app-config.index',
    'app-config.create',
    'app-config.update',
    'app-config.edit',
    'app-config.delete',
    'therapist-count.index',
    'therapist-count.create',
    'therapist-count.update',
    'therapist-count.edit',
    'therapist-count.delete',
    'therapist-shift.index',
    'therapist-shift.create',
    'therapist-shift.update',
    'therapist-shift.edit',
    'therapist-shift.delete',
    'faq.index',
    'faq.create',
    'faq.update',
    'faq.edit',
    'faq.delete',
    'prakruti-standard.index',
    'prakruti-standard.create',
    'prakruti-standard.update',
    'prakruti-standard.edit',
    'prakruti-standard.delete',
    'treatment-protocol.index',
    'treatment-protocol.create',
    'treatment-protocol.update',
    'treatment-protocol.edit',
    'treatment-protocol.delete',
    'care-tacker-food-price.index',
    'care-tacker-food-price.create',
    'care-tacker-food-price.update',
    'care-tacker-food-price.edit',
    'care-tacker-food-price.delete',
    'medical.index',
    'medical.create',
    'medical.update',
    'medical.edit',
    'medical.delete',
    'medical-history.index',
    'medical-history.create',
    'medical-history.update',
    'medical-history.edit',
    'medical-history.delete',
    'consultation.index',
    'consultation.create',
    'consultation.update',
    'consultation.edit',
    'consultation.delete',
]