import React, { useEffect } from 'react'
import Header from '../../../components/Header'
import Sidebar from '../../../components/Sidebar'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import { InputFromUser } from '../../../components/Input/input'
import { Formik } from 'formik'
import { Card } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { ActionGetRoomSlotById } from '../../../store/Actions/MasterDataAction'
import { ActionUpdateById } from '../../../store/Actions/CommonAction'

const UpdateCheckIn = () => {
    const dispatch = useDispatch();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const updateValue = searchParams.get("update");
    const pageValue = searchParams.get("page") ? searchParams.get("page") : 1;
    const idValue = searchParams.get("id");
    const { roomSlot } = useSelector(state => state?.MasterData);
    useEffect(() => {
        if (idValue) {
            dispatch(ActionGetRoomSlotById({ Query: `/${idValue}` }));
        }
    }, [idValue, dispatch])

    const ListItems = (title, value) => {
        return (
            <p><strong>{title + ' : '}</strong>
                {value || '---'}</p>)
    }
    return (
        <>
            <Header />
            <Sidebar />
            <>
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/room-slot">Room-slot</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Upate Room-slot</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card p-3">
                                            <div className="card-body">
                                                <Formik
                                                    enableReinitialize={updateValue}
                                                    initialValues={{
                                                        customerName: roomSlot?.[0]?.customerName || '-',
                                                        registrationNo: roomSlot?.[0]?.customerId?.registrationNo || '-',
                                                        phoneNo: roomSlot?.[0]?.customerId?.phoneNo || '-',
                                                        email: roomSlot?.[0]?.customerId?.email || '-',
                                                        roomNo: roomSlot?.[0]?.roomId?.roomNo || '-',
                                                        roomType: roomSlot?.[0]?.roomType || '-',
                                                        checkInDate: moment().format('YYYY-MM-DD'),
                                                        checkInTime: moment().format('HH:mm:ss'),
                                                        paymentMode: roomSlot?.[0]?.orderId?.paymentMode || '-',
                                                    }}
                                                    onSubmit={(values, { setSubmitting, setErrors }) => {
                                                        if (updateValue && idValue != null) {
                                                            const updatedValues = {
                                                                // ...values,
                                                                isCheckIn: true,
                                                                roomSlotId: idValue,
                                                                actualCheckInDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                                                            };
                                                            dispatch(ActionUpdateById({ Query: `admin/room-slot/${idValue}`, obj: updatedValues }
                                                                , (Res) => {
                                                                    if (Res?.statusCode === 200) {
                                                                        window.location.href = `/room-slot?page=${pageValue}`
                                                                    } else if (Res?.statusCode === 400) {
                                                                        setErrors(Res?.data?.errors);
                                                                    }
                                                                    setSubmitting(false);
                                                                }));
                                                        }
                                                    }}
                                                >
                                                    {({
                                                        values,
                                                        // errors,
                                                        // touched,
                                                        // handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        // setFieldValue,
                                                        isSubmitting,
                                                    }) => {
                                                        return (
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="form-heading">
                                                                            <h4>Update Check In Data</h4>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mt-4">
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Customer Name"
                                                                            value={values?.customerName}
                                                                            disabled
                                                                            name="customerName"

                                                                            onBlur={handleBlur}

                                                                        />
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            type='number'
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Mobile No"
                                                                            value={values?.phoneNo}
                                                                            disabled
                                                                            name="phoneNo"

                                                                            onBlur={handleBlur}

                                                                        />
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Email"
                                                                            value={values?.email}
                                                                            disabled
                                                                            name="email"
                                                                            onBlur={handleBlur}

                                                                        />

                                                                    </div>

                                                                    <div className="row mt-4">
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}

                                                                            FormControlClassName={'form-control '}
                                                                            label="Registration No"
                                                                            name="registrationNo"
                                                                            value={values?.registrationNo}
                                                                            disabled

                                                                            onBlur={handleBlur}

                                                                        />
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Room Name"
                                                                            value={values?.roomNo}
                                                                            disabled
                                                                            name="roomNo"

                                                                            onBlur={handleBlur}

                                                                        />
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Room Type"
                                                                            value={values?.roomType}
                                                                            disabled
                                                                            name="roomType"
                                                                            onBlur={handleBlur}

                                                                        />
                                                                    </div>
                                                                    <div className='row mt-4'>

                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Check-In Date"
                                                                            value={values?.checkInDate}
                                                                            disabled
                                                                            name="checkInDate"
                                                                            onBlur={handleBlur}

                                                                        />
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}
                                                                            FormControlClassName={'form-control '}
                                                                            label="Check-In Time"
                                                                            value={values?.checkInTime}
                                                                            disabled
                                                                            name="checkInTime"
                                                                            onBlur={handleBlur}

                                                                        />
                                                                        <InputFromUser
                                                                            FormGroupClassName={"col-xl-4 col-md-12"}
                                                                            LabelClassName={"col-form-label"}

                                                                            FormControlClassName={'form-control '}
                                                                            label="Payment Mode"
                                                                            value={values?.paymentMode}
                                                                            disabled
                                                                            name="paymentMode"

                                                                            onBlur={handleBlur}

                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className="row mt-4 d-flex justify-content-center">
                                                                    <Card title='Calculations' style={{ width: '400px' }} >
                                                                        <div className="card-body" >
                                                                            {ListItems('Amount', roomSlot?.[0]?.orderId?.amount)}
                                                                            {ListItems('No.of Guests:', roomSlot?.[0]?.orderId?.additionalInfo?.guestDetails.length)}
                                                                            {ListItems('No.of Guests:', roomSlot?.[0]?.orderId?.additionalInfo?.guestDetails.length)}
                                                                            {/* <p  ><span>{}</span></p> */}
                                                                            <h5 >Total Amount:<span>{roomSlot?.[0]?.orderId?.amount}</span></h5>
                                                                        </div>
                                                                    </Card>

                                                                    {/* <Card style={gridStyle} className="col-md-4 col-xxl-3">
                                                                        <p><strong>Amount : </strong> {(order?.amount + '/-') || '---'} </p>
                                                                        <p><strong>Payment Status: </strong>
                                                                            {order?.paymentStatus && order?.paymentStatus === 'Failed' ?
                                                                                <Tag color="error"> {order?.paymentStatus}</Tag> : order?.paymentStatus === 'Initialize' ?
                                                                                    <Tag color="processing">{order?.paymentStatus}</Tag> :
                                                                                    <Tag color="success">{order?.paymentStatus}</Tag>
                                                                                    || '---'}</p>

                                                                        <p><strong>Order Status:</strong> {order?.orderStatus && order?.orderStatus === 'Failed' ?
                                                                            <Tag color="error"> {order?.orderStatus}</Tag> : order?.orderStatus === 'Initialize' ?
                                                                                <Tag color="processing">{order?.orderStatus}</Tag> :
                                                                                <Tag color="success">{order?.orderStatus}</Tag>
                                                                                || '---'}</p>

                                                                        {!(order?.bookingFor === 'Service') && <p><strong>Completed Payment:</strong>  {order?.paymentStatus && order?.paymentStatus === 'Failed' ?
                                                                            <Tag color="error"> {order?.additionalInfo?.completedPayment || '---'}</Tag> :
                                                                            <Tag color="success">{order?.additionalInfo?.completedPayment || '---'}</Tag>
                                                                            || '---'}</p>}
                                                                        {ListItems('Order Type ', (order?.orderType || '---'))}
                                                                        {ListItems('Payment Mode ', (order?.paymentMode || '---'))}
                                                                    </Card> */}
                                                                </div>
                                                                <div className="col-12 mt-4">
                                                                    <div className="doctor-submit text-end">
                                                                        <button
                                                                            type="submit"
                                                                            onClick={() => {
                                                                                handleSubmit()
                                                                            }}
                                                                            className="btn btn-primary submit-form me-2"
                                                                            disabled={isSubmitting}
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary cancel-form"
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        )

                                                    }}

                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        </>
    )
}

export default UpdateCheckIn