import { CREATE_BY_URL, DELETE_BY_ID, GET_ALL_DASHBOARD_DATA, GET_ALL_DASHBOARD_DATA_SUCCESS, GET_ALL_GALLERY, GET_ALL_GALLERY_SUCCESS, UPDATE_BY_ID, UPLOAD_IMAGE } from "../storeTypes";


export const ActionUpdateById = (payload, callBack) => {
    return {
        type: UPDATE_BY_ID,
        payload,
        callBack
    };
};
export const ActionDeleteById = (payload, callBack) => {
    return {
        type: DELETE_BY_ID,
        payload,
        callBack
    };
};
export const ActionCreateByUrl = (payload, callBack) => {
    return {
        type: CREATE_BY_URL,
        payload,
        callBack
    };
};
export const ActionUploadImage = (payload, callBack) => {
    return {
        type: UPLOAD_IMAGE,
        payload,
        callBack
    };
};

export const ActionGetGalleryList = (payload) => {
    return {
        type: GET_ALL_GALLERY,
        payload
    };
};
export const ActionGetGalleryListSuccess = (payload) => {
    return {
        type: GET_ALL_GALLERY_SUCCESS,
        payload
    };
};
export const ActionGetDashboardData = (payload) => {
    return {
        type: GET_ALL_DASHBOARD_DATA,
        payload
    };
};
export const ActionGetDashboardDataSuccess = (payload) => {
    return {
        type: GET_ALL_DASHBOARD_DATA_SUCCESS,
        payload
    };
};