import { CREATE_PRICE, DELETE_PRICE, GET_ALL_PRICE_LIST, GET_ALL_PRICE_LIST_SUCCESS, GET_PRICE_BY_ID, GET_PRICE_BY_ID_SUCCESS, UPDATE_PRICE } from "../storeTypes";

const INIT_STATE = {
    loader: false,
    consultationPrice: [],
}

function PriceReducer(state = INIT_STATE, action) {

    switch (action.type) {

        case GET_ALL_PRICE_LIST: {
            return {
                ...state,
                consultationPrice: [],
                loader: true,
            }
        }

        case GET_ALL_PRICE_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                consultationPrice: action.payload,
            }
        }

        case CREATE_PRICE: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_PRICE: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_PRICE_BY_ID: {
            return {
                ...state,
                consultationPrice: [],
                loader: true,
            };
        }
        case GET_PRICE_BY_ID_SUCCESS: {
            return {
                ...state,
                loader: false,
                consultationPrice: action.payload,
            };
        }
        case DELETE_PRICE: {
            return {
                ...state,
                loader: true,
            };
        }
        default: {
            return state;
        }
    }
}

export default PriceReducer;