/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { InputFromUser } from "../Input/input";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Modal } from "antd";
import { ActionGetShiftsList, GetTherapistScheduleAction } from "../../store/Actions/StaffAction";
import { ActionCreateByUrl, ActionDeleteById } from "../../store/Actions/CommonAction";
import { getAdminuUerDetails } from "../../store/Actions/AuthActions";
const Calender = () => {
    const refCal = useRef();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { therapistShift, therapistSchedule } = useSelector(state => state?.Staff);
    const { userDetails } = useSelector(state => state?.auth)
    const [Schedule, setSchedule] = useState(therapistSchedule || [])
    const [modalData, setModalData] = useState({
        isOpen: false,
        startDate: "",
        endDate: "",
        select: "",
        isEventclick: false,
        eventid: ""
    });
    useEffect(() => {
        dispatch(getAdminuUerDetails())
        dispatch(ActionGetShiftsList({ Query: `` }));
        dispatch(GetTherapistScheduleAction({
            "therapistId": id,
            "date": moment().format('YYYY/MM/DD')
        }))
    }, [])
    return (
        <div className="card">
            <div className="card-body">
                <div id="calendar">
                    <FullCalendar
                        ref={refCal}
                        plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                        ]}
                        headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        initialView="dayGridMonth"
                        selectable={true}
                        events={therapistSchedule}
                        initialEvents={Schedule}
                        select={(arg) => {
                            setModalData(old => {
                                return {
                                    ...old,
                                    ...{
                                        isOpen: true,
                                        startDate: moment(arg.start).toString(),
                                        endDate: moment(arg.end).subtract(1, "day").toString(),
                                    }
                                }
                            })
                        }}
                        eventClick={(clickInfo) => {
                            setModalData({
                                isOpen: true,
                                startDate: "",
                                endDate: "",
                                select: "",
                                isEventclick: true,
                                eventid: clickInfo.event.id
                            })
                        }}
                    />
                </div>
                <Modal
                    open={modalData.isOpen}
                    closable={true}
                    className='ant-modal'
                    forceRender={true}
                    zIndex={1050}
                    style={{ margin: 10 }}
                    onOk={() => {
                        // SetModal({ type: showModal?.type, is: false });
                        // SetRefresh(true)
                    }}
                    styles={{
                        body: {
                            overflow: "hidden",
                        },
                        mask: {
                            backdropFilter: 'blur(10px)',
                        },
                        content: {
                            borderRadius: 40,
                        },
                    }}
                    okText={'Submit'}
                    centered
                    confirmLoading={true}
                    onCancel={() => {
                        setModalData({
                            isOpen: false,
                            startDate: "",
                            endDate: "",
                            select: "",
                            isEventclick: false,
                            eventid: ""
                        });
                    }}
                    closeIcon={true}
                    footer={false}
                >
                    <div>
                        {!modalData.isEventclick ? (
                            <div className="row">
                                <InputFromUser
                                    type="singleSelect"
                                    label="Select Shift"
                                    FormControlClassName={'form-control'}
                                    FormGroupClassName={"col-xl-12 col-md-12"}
                                    LabelClassName={"col-form-label"}
                                    Showlabel={false}
                                    name="Shift"
                                    onChange={(v) => {
                                        const findItem = therapistShift?.find((e) => e.title === v)
                                        const ShiftStart = new Date(findItem.startTime);
                                        const Shiftend = new Date(findItem.endTime);
                                        const newstarttime = new Date(modalData.startDate)
                                        const newEndtime = new Date(modalData.endDate)
                                        setModalData(old => {
                                            return {
                                                ...old,
                                                ...{
                                                    startDate: moment(new Date(newstarttime.setHours(ShiftStart.getHours(), ShiftStart.getMinutes(), 0, 0))).toString(),
                                                    endDate: moment(new Date(newEndtime.setHours(Shiftend.getHours(), Shiftend.getMinutes(), 0, 0))).toString(),
                                                    select: v,
                                                }
                                            }
                                        })
                                    }}
                                    value={therapistShift.find((e) => e.title === modalData.select)?.title || ""}
                                    error={null}
                                    touched={null}
                                    options={therapistShift.length != 0 ? therapistShift?.map(item => item?.title) : []}
                                    placeholder={'Enter Your Shift'} />
                                <div>
                                    <Button onClick={() => {
                                        const ShiftEvent = {
                                            title: modalData.select,
                                            start: new Date(modalData.startDate),
                                            end: new Date(modalData.endDate),
                                            className: "bg-success",
                                            therapist: id,
                                            createdBy: userDetails?.[0]?._id,
                                            allDay: false,
                                        };
                                        dispatch(ActionCreateByUrl({ Query: 'admin/therapistSchedule', obj: ShiftEvent },
                                            (v) => {
                                                dispatch(GetTherapistScheduleAction({
                                                    "therapistId": id,
                                                    "date": moment().format('YYYY/MM/DD')
                                                }))
                                                setModalData({
                                                    isOpen: false,
                                                    startDate: "",
                                                    endDate: "",
                                                    select: "",
                                                    isEventclick: false,
                                                    eventid: ""
                                                })
                                            }
                                        ))
                                    }} className="mx-3">Add Shift</Button>
                                </div>
                            </div>) : (
                            <><h4>Delete This Shift</h4>
                                <Button onClick={() => {
                                    dispatch(ActionDeleteById({ Query: `admin/therapistSchedule/${modalData?.eventid}` }, (Res) => {
                                        if (Res?.statusCode === 200) {
                                            dispatch(GetTherapistScheduleAction({
                                                "therapistId": id,
                                                "date": moment().format('YYYY/MM/DD')
                                            }))
                                            setModalData({
                                                isOpen: false,
                                                startDate: "",
                                                endDate: "",
                                                select: "",
                                                isEventclick: false,
                                                eventid: ""
                                            });
                                        }
                                    }))
                                }} className="mx-3">Remove Shift</Button>
                            </>)}
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default Calender;
