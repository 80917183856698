import {
    CREATE_DETOX_RETREAT,
    CREATE_DETOX_RETREAT_SUCCESS,
    CREATE_DO_AND_DONTS,
    CREATE_EXERCISE,
    CREATE_MEDICINE, CREATE_MEMBERSHIP, CREATE_ROOM,
    CREATE_VIRECHAN,
    DELETE_DO_AND_DONTS,
    DELETE_EXERCISE,
    DELETE_MEDICINE, DELETE_MEMBERSHIP, DELETE_ROOM,
    DELETE_VIRECHAN,
    DO_AND_DONTS_LIST_SUCCESS,
    EXERCISE_LIST_SUCCESS,
    GET_ALL_DETOX_RETREAT,
    GET_ALL_DETOX_RETREAT_SUCCESS, GET_ALL_MEMBERSHIP_LIST,
    GET_ALL_MEMBERSHIP_LIST_SUCCESS,
    GET_ALL_MEMBER_VERIFY_LIST,
    GET_ALL_MEMBER_VERIFY_LIST_SUCCESS,
    GET_DO_AND_DONTS_LIST,
    GET_EXERCISE_LIST,
    GET_MEDICINE_BY_ID,
    GET_MEDICINE_BY_ID_SUCCESS,
    GET_MEDICINE_LIST,
    GET_MEDICINE_LIST_SUCCESS, GET_MEMBERSHIP_LIST, GET_ROOM_BY_ID,
    GET_ROOM_BY_ID_SUCCESS,
    GET_ROOM_BY_ROOM_TYPE,
    GET_ROOM_BY_ROOM_TYPE_SUCCESS,
    GET_ROOM_COUNT,
    GET_ROOM_COUNT_SUCCESS,
    GET_ROOM_DASHBOARD_DATA,
    GET_ROOM_DASHBOARD_DATA_SUCCESS,
    GET_ROOM_LIST,
    GET_ROOM_LIST_SUCCESS,
    GET_ROOM_SLOT,
    GET_ROOM_SLOT_BY_ID,
    GET_ROOM_SLOT_BY_ID_SUCCESS,
    GET_ROOM_SLOT_SUCCESS,
    GET_THERAPIST_COUNT,
    GET_THERAPIST_COUNT_SUCCESS,
    GET_VIRECHAN_LIST, MEMBERSHIP_LIST_SUCCESS, UPDATE_CUSTOMER_MEMBERSHIP, UPDATE_CUSTOMER_MEMBERSHIP_SUCCESS, UPDATE_DO_AND_DONTS,
    UPDATE_EXERCISE,
    UPDATE_MEDICINE, UPDATE_MEMBERSHIP, UPDATE_ROOM,
    UPDATE_VIRECHAN,
    VIRECHAN_LIST_SUCCESS
} from "../storeTypes";

//Do and Donts

const INIT_STATE = {
    loader: false,
    doAndDonts: [],
    exercise: [],
    virechan: [],
    memberShip: [],
    memberShipData: [],
    customerMemberShip: [],
    therapistCount: [],
    roomCount: [],
    roomSlot: [],
    medicine: {},
    room: {},
    roomTypeData: [],
    roomDashboard: [],
    detoxRetreat: [],
    memberShipVerifyData: [],
};

function MasterDataReducer(state = INIT_STATE, action) {

    switch (action.type) {

        //Do and Donts

        case GET_DO_AND_DONTS_LIST: {
            return {
                ...state,
                doAndDonts: [],
                loader: true,
            };
        }
        case DO_AND_DONTS_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                doAndDonts: action.payload,
            };
        }
        case CREATE_DO_AND_DONTS: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_DO_AND_DONTS: {
            return {
                ...state,
                loader: true,
            };
        }
        case DELETE_DO_AND_DONTS: {
            return {
                ...state,
                loader: true,
            };
        }

        //Exercise

        case GET_EXERCISE_LIST: {
            return {
                ...state,
                exercise: [],
                loader: true,
            };
        }
        case EXERCISE_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                exercise: action.payload,
            };
        }
        case CREATE_EXERCISE: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_EXERCISE: {
            return {
                ...state,
                loader: true,
            };
        }
        case DELETE_EXERCISE: {
            return {
                ...state,
                loader: true,
            };
        }

        //Virechan

        case GET_VIRECHAN_LIST: {
            return {
                ...state,
                virechan: [],
                loader: true,
            };
        }
        case VIRECHAN_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                virechan: action.payload,
            };
        }
        case CREATE_VIRECHAN: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_VIRECHAN: {
            return {
                ...state,
                loader: true,
            };
        }
        case DELETE_VIRECHAN: {
            return {
                ...state,
                loader: true,
            };
        }

        //Member Ship

        case GET_MEMBERSHIP_LIST: {
            return {
                ...state,
                memberShip: [],
                loader: true,
            };
        }
        case MEMBERSHIP_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                memberShip: action.payload,
            };
        }
        case GET_ALL_MEMBERSHIP_LIST: {
            return {
                ...state,
                loader: true,
                memberShipData: []
            };
        }
        case GET_ALL_MEMBERSHIP_LIST_SUCCESS: {
            return {
                ...state,
                loader: true,
                memberShipData: action?.payload
            };
        }

        case GET_ALL_MEMBER_VERIFY_LIST: {
            return {
                ...state,
                loader: true,
                memberShipVerifyData: []
            };
        }
        case GET_ALL_MEMBER_VERIFY_LIST_SUCCESS: {
            return {
                ...state,
                loader: true,
                memberShipVerifyData: action?.payload
            };
        }
        case CREATE_MEMBERSHIP: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_MEMBERSHIP: {
            return {
                ...state,
                loader: true,
            };
        }
        case DELETE_MEMBERSHIP: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_CUSTOMER_MEMBERSHIP: {
            return {
                ...state,
                customerMemberShip: [],
                loader: true,
            };
        }
        case UPDATE_CUSTOMER_MEMBERSHIP_SUCCESS: {
            return {
                ...state,
                loader: false,
                customerMemberShip: action.payload,
            };
        }

        //Medicine

        case GET_MEDICINE_LIST: {
            return {
                ...state,
                medicine: {},
                loader: true,
            };
        }
        case GET_MEDICINE_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                medicine: action.payload,
            };
        }
        case CREATE_MEDICINE: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_MEDICINE: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_MEDICINE_BY_ID: {
            return {
                ...state,
                medicine: {},
                loader: true,
            };
        }
        case GET_MEDICINE_BY_ID_SUCCESS: {
            return {
                ...state,
                loader: false,
                medicine: action.payload,
            };
        }
        case DELETE_MEDICINE: {
            return {
                ...state,
                loader: true,
            };
        }

        //Rooms

        case GET_ROOM_LIST: {
            return {
                ...state,
                room: {},
                loader: true,
            };
        }
        case GET_ROOM_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                room: action.payload,
            };
        }
        case CREATE_ROOM: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_ROOM: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ROOM_BY_ID: {
            return {
                ...state,
                room: {},
                loader: true,
            };
        }
        case GET_ROOM_BY_ID_SUCCESS: {
            return {
                ...state,
                loader: false,
                room: action.payload,
            };
        }
        case DELETE_ROOM: {
            return {
                ...state,
                loader: true,
            };
        }

        case GET_ROOM_BY_ROOM_TYPE: {
            return {
                ...state,
                roomTypeData: [],
                loader: true,
            };
        }
        case GET_ROOM_BY_ROOM_TYPE_SUCCESS: {
            return {
                ...state,
                loader: false,
                roomTypeData: action.payload,
            };
        }

        case GET_THERAPIST_COUNT: {
            return {
                ...state,
                loader: true
            };
        }
        case GET_THERAPIST_COUNT_SUCCESS: {
            return {
                ...state,
                loader: false,
                therapistCount: action.payload,
            };
        }
        case GET_ROOM_COUNT: {
            return {
                ...state,
                loader: true
            };
        }
        case GET_ROOM_COUNT_SUCCESS: {
            return {
                ...state,
                loader: false,
                roomCount: action.payload,
            };
        }
        case GET_ROOM_DASHBOARD_DATA: {
            return {
                ...state,
                loader: true
            };
        }
        case GET_ROOM_DASHBOARD_DATA_SUCCESS: {
            return {
                ...state,
                loader: false,
                roomDashboard: action.payload,
            }
        }
        case GET_ROOM_SLOT: {
            return {
                ...state,
                roomSlot: [],
                loader: true,
            };
        }
        case GET_ROOM_SLOT_SUCCESS: {
            return {
                ...state,
                loader: false,
                roomSlot: action.payload,
            };
        }
        case GET_ROOM_SLOT_BY_ID: {
            return {
                ...state,
                roomSlot: [],
                loader: true,
            };
        }
        case GET_ROOM_SLOT_BY_ID_SUCCESS: {
            return {
                ...state,
                loader: false,
                roomSlot: action.payload,
            };
        }
        case GET_ALL_DETOX_RETREAT: {
            return {
                ...state,
                detoxRetreat: [],
                loader: true,
            };
        }
        case GET_ALL_DETOX_RETREAT_SUCCESS: {
            return {
                ...state,
                loader: false,
                detoxRetreat: action.payload,
            };
        }
        case CREATE_DETOX_RETREAT: {
            return {
                ...state,
                loader: true,
            };
        }
        case CREATE_DETOX_RETREAT_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }
        default: {
            return state;
        }
    }
}

export default MasterDataReducer;
