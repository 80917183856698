import { CONSULTATION_MASTERDATA, CONSULTATION_MASTERDATA_SUCCESS, CREATE_MEDICAL_DETAILS, GET_ADMIN_USER, GET_ADMIN_USER_SUCCESS, GET_ALL_APPOINMENT, GET_ALL_APPOINMENT_SUCCESS, GET_ALL_CONSULTATION, GET_ALL_CONSULTATION_SUCCESS, GET_DR_AVAILABILITY, GET_DR_AVAILABILITY_SUCCESS, GET_DR_SLOT_AVAILABILITY, GET_DR_SLOT_AVAILABILITY_SUCCESS } from "../storeTypes";

const INIT_STATE = {
    loader: false,
    AdminUser: [],
    AllConsultation: [],
    DrAvailability: [],
    AllAppoinment: [],
    DrSlotAvailability: [],
    ConsultationMasterData: [],
};

function AppointmentReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case GET_ALL_CONSULTATION: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_CONSULTATION_SUCCESS: {
            return {
                ...state,
                loader: false,
                AllConsultation: action?.payload,
            };
        }
        case CREATE_MEDICAL_DETAILS: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_APPOINMENT: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ALL_APPOINMENT_SUCCESS: {
            return {
                ...state,
                loader: false,
                AllAppoinment: action?.payload,
            };
        }
        case GET_ADMIN_USER: {
            return {
                ...state,
                loader: true,
            };
        }
        case GET_ADMIN_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                AdminUser: action?.payload
            };
        }
        case GET_DR_AVAILABILITY: {
            return {
                ...state,
                DrAvailability: [],
                loader: true,
            };
        }
        case GET_DR_AVAILABILITY_SUCCESS: {
            return {
                ...state,
                loader: false,
                DrAvailability: action?.payload
            };
        }
        case GET_DR_SLOT_AVAILABILITY: {
            return {
                ...state,
                DrSlotAvailability: [],
                loader: true,
            };
        }
        case GET_DR_SLOT_AVAILABILITY_SUCCESS: {
            return {
                ...state,
                loader: false,
                DrSlotAvailability: action?.payload
            };
        }
        case CONSULTATION_MASTERDATA: {
            return {
                ...state,
                ConsultationMasterData: [],
                loader: true,
            };
        }
        case CONSULTATION_MASTERDATA_SUCCESS: {
            return {
                ...state,
                loader: false,
                ConsultationMasterData: action?.payload
            };
        }

        default: {
            return state;
        }
    }
}

export default AppointmentReducer;
