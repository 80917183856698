import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import ReactDOM from 'react-dom/client';
import AppContainer from './App';
import "./assets/css/bootstrap.min.css";
import "./assets/css/custom.css";
import "./assets/css/select2.min.css";
import "./assets/css/style.css";
import "./assets/js/bootstrap.bundle.min.js";
import "./assets/plugins/fontawesome/css/all.min.css";
import "./assets/plugins/fontawesome/css/fontawesome.min.css";
import { BrowserRouter } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter basename="/">
        <AppContainer />
    </BrowserRouter>
);
